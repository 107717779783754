import React from 'react'
import {Box, Text} from 'grommet'
import {SRHeading} from 'sr-react-commons'

import {IssueEntity} from '../issue.entities'
import {CardContainer} from '../../../components/CommonsCandidate/CardContainer/CardContainer'

type IssueMoreInfoBoxProps = {
	issue: IssueEntity
}

export function IssueMoreInfoBox({issue}: IssueMoreInfoBoxProps) {
	const {customProperties} = issue
	return (
		<CardContainer>
			<SRHeading level={'4'}>More information</SRHeading>
			{Object.keys(customProperties).map(key => (
				<Box key={key} pad={{bottom: 'small'}}>
					<Text weight={'bold'}>{customProperties[key].name}</Text>
					<Text>{customProperties[key].value || '-'}</Text>
				</Box>
			))}
		</CardContainer>
	)
}
