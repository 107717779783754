import {PixiComponent} from '@inlet/react-pixi'
import {Graphics, Point} from 'pixi.js'

interface Props {
	x: number
	y: number
	height: number
	width: number
	color: number
	alpha?: number
	angle: number
}

export default PixiComponent('Rectangle', {
	create: () => {
		return new Graphics()
	},
	applyProps: (instance: Graphics, oldProps, {x, y, width, height, color, angle, alpha = 1.0}: Props) => {
		instance.clear()
		instance.beginFill(color, alpha)
		instance.position = new Point(x, y)
		instance.rotation = angle * (Math.PI / 180)
		instance.drawRect(0, 0, width, height)
		instance.endFill()
	},
})
