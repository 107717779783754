import {Box, Text} from 'grommet'
import React from 'react'
import {FormSearch} from 'grommet-icons'
import {SRTextInput} from 'sr-react-commons'

export function ElementNameFilter({
	filters,
	setFilter,
}: {
	filters: {elementName: string | null}
	setFilter: (key: 'elementName', value: string | null) => void
}) {
	return (
		<Box>
			<Text weight="bold" size="small">
				Name
			</Text>
			<SRTextInput
				icon={<FormSearch />}
				placeholder={'Type element name'}
				data-testid={'filter-name-input'}
				value={filters.elementName || ''}
				onChange={event => setFilter('elementName', event.target.value === '' ? null : event.target.value)}
			/>
		</Box>
	)
}
