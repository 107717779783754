import {UserProject} from '../../../reducers/userProfile'
import {getHasValidAccess} from '../forge-integration.api'
import {useQuery} from 'react-query'
import {defaultQueryClient} from '../../../utilities/query-client.util'

export const invalidateUseUserHasValidForgeAccess = (): Promise<void> =>
	defaultQueryClient.invalidateQueries(['forge-integration', 'used-has-valid-access'])

export function useUserHasValidForgeAccess(project?: UserProject) {
	return useQuery(['forge-integration', 'used-has-valid-access', project], () => getHasValidAccess(project!._id), {
		enabled: !!project?._id,
	})
}
