import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {doAddCurrentUser, doAddUserProfile} from '../../actions/userProfile'
import {RootState} from '../../reducers'
import {UserProject} from '../../reducers/userProfile'
import {fetchCurrentUser, fetchUserProfile} from '../../api/user.api'
import {doSetUserProfileReady} from '../../actions/viewer'
import {
	identifyUser,
	initializeLogRocket,
	shouldInitializeLogRocketAndTrackSession,
} from '../../features/Tracking/logrocket'
import posthog from 'posthog-js'
import {config} from '../../config'
import {useFeatureFlagsImport} from '../../features/FeatureFlags/FeatureFlagsProvider'

type GetUserProfileFromDBProps = {
	userAttributes: {
		email: string
	}
	children: any
}

type ConnectedProps = {
	userProjects?: UserProject[]
}

type AllProps = GetUserProfileFromDBProps & ConnectedProps

// Fetch data to render props
// https://www.robinwieruch.de/react-fetching-data/
export default function GetUserProfileFromDB({userAttributes, children}: AllProps) {
	const dispatch = useDispatch()
	const userProjects = useSelector((state: RootState) => state.userProfileState.userProjects)
	const userProfileIsReady = useSelector((state: RootState) => state.viewerState.isReady.userProfile)
	const importDataToLayer = useFeatureFlagsImport()

	useEffect(() => {
		const fn = async () => {
			dispatch(doSetUserProfileReady(false))
			const user = await fetchCurrentUser()
			dispatch(doAddCurrentUser(user))
			const userProfile = await fetchUserProfile()
			dispatch(doAddUserProfile(userProfile))
			if (config.sr.activatePosthog) {
				posthog.identify(user.email, {name: `${user.firstName} ${user.lastName}`, email: user.email})
			}
			if (shouldInitializeLogRocketAndTrackSession(user.email)) {
				initializeLogRocket()
				const userName = `${user.firstName} ${user.lastName}`
				identifyUser(userName, user.email)
			}
			dispatch(doSetUserProfileReady(true))
		}
		fn().catch(e => {
			console.error('Error in GetUserProfileFromDB', e)
		})
	}, [userAttributes, dispatch, importDataToLayer])
	if (!userProjects || !userProfileIsReady) {
		return null
	} else {
		return children(userProjects)
	}
}
