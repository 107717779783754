import {Anchor, Box, Grommet, Image, Text} from 'grommet'
import React from 'react'
import {theme} from '../../theme'
import {SRHeading} from 'sr-react-commons'
import {config} from '../../config'

export function ErrorFallback() {
	return (
		<Grommet theme={theme} full>
			<Box background="light-1" fill>
				<Box
					width="550px"
					background="white"
					alignSelf="center"
					border={{
						color: 'light-3',
						size: 'xsmall',
						style: 'solid',
					}}
					round="xsmall"
					margin={{top: '15%'}}
				>
					<Box margin={'large'} alignContent={'center'} gap={'xsmall'}>
						<Box margin={'none'}>
							<Image
								height={'100%'}
								fill={false}
								alignSelf={'start'}
								src={'/images/logo-horizontal-small.svg'}
								alt={`${config.sr.companyName} Logo`}
							/>
						</Box>
						<Box margin={{left: 'small'}} gap={'xsmall'}>
							<SRHeading level={'3'} size={'small'}>
								An error has occurred
							</SRHeading>
							<Box gap={'small'}>
								<Text size={'medium'} font-weight={'400'} color={'neutral-1'}>
									We are not able to display your content at this moment.
								</Text>
								<Text size={'medium'} font-weight={'400'} color={'neutral-1'}>
									Please check your internet connection, refresh the page, and retry in a couple of minutes.
								</Text>
								<Text size={'medium'} font-weight={'400'} color={'neutral-1'}>
									If the problem persists, please contact our{' '}
									<Anchor color={'#1C62DC'} href="mailto:support@naska.ai" label="customer support" />.
								</Text>
								<Text size={'medium'} font-weight={'400'} color={'neutral-1'}>
									We apologize for the inconvenience.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Grommet>
	)
}
