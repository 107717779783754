import React from 'react'
import {Box} from 'grommet'
import {ForgeIntegrationCard} from '../../ForgeIntegration/components/ForgeIntegrationCard'

export const ManagementDashboardIntegrations = () => {
	return (
		<Box gap={'large'}>
			<Box width={'920px'}>
				<ForgeIntegrationCard />
			</Box>
		</Box>
	)
}
