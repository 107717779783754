import {VictoryLabel, VictoryPie} from 'victory'
import React from 'react'
import {WatchlistClassification, watchlistClassificationColor} from '../../watchlist.utils'
import {ProgressMonitoringData, progressMonitoringDataLabels, progressMonitoringDataTotal} from './BreakDownDataTable'
import {getDisplayValueForClassificationStatus} from '../../../Classification/classification.helpers'
import {SR_COLOR_NEUTRAL_1} from 'sr-react-commons'

function buildDonutChartData(data: ProgressMonitoringData, enableUnderConstruction: boolean) {
	const labels = progressMonitoringDataLabels(enableUnderConstruction)

	return labels.map(label => {
		return {x: getDisplayValueForClassificationStatus(label) as WatchlistClassification, y: data[label]}
	})
}

export function progressMonitoringDataProgress(data: ProgressMonitoringData): number {
	const total = progressMonitoringDataTotal(data)
	const built = data.verified + data.deviated + (data.under_construction || 0)

	if (total === 0) return 0

	return Math.round((built * 100) / total)
}

export interface BreakDownDonutDataProps {
	data: ProgressMonitoringData
	enableUnderConstruction: boolean
}

export function BreakDownDonutChart({data, enableUnderConstruction}: BreakDownDonutDataProps): JSX.Element {
	const updatedData = buildDonutChartData(data, enableUnderConstruction)

	const progress = progressMonitoringDataProgress(data)
	return (
		<svg viewBox="165 125 77 155">
			<VictoryPie
				style={{
					data: {
						fillOpacity: 0.9,
						stroke: 'none',
						strokeWidth: 0.5,
					},
					labels: {display: 'none'},
				}}
				standalone={false}
				colorScale={
					enableUnderConstruction
						? [
								watchlistClassificationColor('Verified'),
								watchlistClassificationColor('Deviated'),
								watchlistClassificationColor('Under construction'),
								watchlistClassificationColor('Missing'),
								watchlistClassificationColor('No data'),
						  ]
						: [
								watchlistClassificationColor('Verified'),
								watchlistClassificationColor('Deviated'),
								watchlistClassificationColor('Missing'),
								watchlistClassificationColor('No data'),
						  ]
				}
				data={updatedData}
				innerRadius={55}
				radius={({datum}) => (['Missing', 'No data', 'Under construction'].includes(datum.x) ? 70 : 75)}
			/>
			<VictoryLabel
				textAnchor="middle"
				style={[
					{fontSize: 24, fontWeight: 600},
					{fontSize: 12, fill: SR_COLOR_NEUTRAL_1},
				]}
				backgroundPadding={{top: 5}}
				lineHeight={[1, 2]}
				x={200}
				y={205}
				text={[`${progress}%`, 'PROGRESS']}
			/>
		</svg>
	)
}
