import React from 'react'
import {SREmptySearch, SREmptyView} from 'sr-react-commons'
import {ManagementDashboardAnalysisRequestEmptyViewAsset} from './ManagementDashboardAnalysisRequestEmptyViewAsset'

export const ManagementDashboardAnalysisRequestEmptyView = ({
	searchText,
	goToWizard,
}: {
	searchText: string
	goToWizard: () => void
}) => {
	return searchText.length ? (
		<SREmptySearch title={'No matching Analysis Requests found.'} message={'Please try again.'} />
	) : (
		<SREmptyView
			asset={ManagementDashboardAnalysisRequestEmptyViewAsset}
			title={'You haven’t requested any analyses yet'}
			message={'Requested analyses will appear here.'}
			actionText={'Request analysis'}
			onAction={goToWizard}
		/>
	)
}
