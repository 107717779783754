import {Anchor, Box, Layer, Paragraph, Text} from 'grommet'
import {Alert, CircleInformation, StatusCritical} from 'grommet-icons'
import React from 'react'
import {connect} from 'react-redux'
import {useGlobalNotifications} from './hook'
import {Notification} from './reducer'
import {truncateString} from '../../utilities/stringUtilities'
import {SRSecondaryButton} from 'sr-react-commons'

export const GlobalNotifications = connect(mapStateToProps, mapDispatchToProps)(NotificationComponent)

interface Props {
	notification: Notification
	onDismiss?: () => void
}

function mapStateToProps(state: any) {
	const {current} = state.notifications
	return {notification: current}
}

function mapDispatchToProps() {
	return {}
}

function NotificationComponent(props: Props) {
	if (!props.notification) return null

	const {level, message} = props.notification

	if (level === 'INFO') return <NotifyInfo message={message} />
	if (level === 'ALERT') return <NotifyAlert message={message} />
	if (level === 'ERROR') return <NotifyError message={message} />

	return null
}

function NotifyInfo(props: any) {
	const globalNotifications = useGlobalNotifications()

	return (
		<Layer modal={false} position="top" margin="xlarge" animate={true} animation="slide" plain={true} responsive={true}>
			<Box align="stretch" justify="between" direction="row" elevation="small">
				<Box align="center" justify="center" background={{color: 'status-ok'}} direction="row" pad="medium">
					<CircleInformation size="large" color="white" />
				</Box>
				<Box align="stretch" justify="center" direction="column" background={{color: 'light-3'}}>
					<Box align="center" justify="center" direction="row">
						<Paragraph fill size="medium" textAlign="start" margin="medium" color="black">
							{props.message}
						</Paragraph>
						<SRSecondaryButton
							label="Acknowledge"
							type="button"
							hoverIndicator={true}
							size="small"
							margin="small"
							onClick={() => globalNotifications.dismissCurrent()}
						/>
					</Box>
					<Text textAlign="center" size="xsmall">
						You can safely dismiss this notification
					</Text>
				</Box>
			</Box>
		</Layer>
	)
}

function NotifyAlert(props: any) {
	const globalNotifications = useGlobalNotifications()

	return (
		<Layer modal={false} position="top" margin="xlarge" animate={true} animation="slide" plain={true} responsive={true}>
			<Box align="stretch" justify="between" direction="row" elevation="small" responsive={true}>
				<Box align="center" justify="center" background={{color: 'status-warning'}} direction="row" pad="medium">
					<Alert size="large" color="white" />
				</Box>
				<Box align="stretch" justify="center" direction="column" background={{color: 'light-3'}}>
					<Box align="center" justify="center" direction="row">
						<Paragraph fill size="medium" textAlign="start" margin="medium" color="black">
							{truncateString(props.message, 50)}
						</Paragraph>
						<SRSecondaryButton
							label="Acknowledge"
							type="button"
							hoverIndicator={true}
							size="small"
							margin="small"
							onClick={() => globalNotifications.dismissCurrent()}
						/>
					</Box>
					<Text textAlign="center" size="xsmall">
						If the warning persists, please contact <Anchor href="mailto:support@naska.ai">support@naska.ai</Anchor>
					</Text>
				</Box>
			</Box>
		</Layer>
	)
}

function NotifyError(props: any) {
	const globalNotifications = useGlobalNotifications()

	return (
		<Layer modal={true} position="center" margin="none" animate={true} animation="slide" responsive={true}>
			<Box align="stretch" justify="between" direction="row" elevation="small">
				<Box align="center" justify="center" background={{color: 'status-error'}} direction="row" pad="medium">
					<StatusCritical size="large" color="white" />
				</Box>
				<Box align="stretch" justify="center" direction="column" background={{color: 'light-3'}}>
					<Box align="center" justify="center" direction="row">
						<Paragraph fill size="medium" textAlign="start" margin="medium" color="black">
							{props.message}
						</Paragraph>
						<SRSecondaryButton
							label="Acknowledge"
							type="button"
							hoverIndicator={true}
							size="small"
							margin="small"
							onClick={() => globalNotifications.dismissCurrent()}
						/>
					</Box>
					<Text textAlign="center" size="xsmall">
						If the error persists, please contact <Anchor href="mailto:support@naska.ai">support@naska.ai</Anchor>
					</Text>
				</Box>
			</Box>
		</Layer>
	)
}
