import {SRHeading, SRIconButton, SRPlainButton} from 'sr-react-commons'
import React, {useState} from 'react'
import {Box, Form, Layer, Text, TextArea} from 'grommet'
import {Close} from 'grommet-icons'
import DownloadQualityReportPdfButton from './DownloadQualityReportPdfButton'
import {SRCheckBoxWithDescription} from '../../../components/CommonsCandidate/CheckBox/SRCheckBoxWithDescription'
import {FileUploadButton} from '../../FileUpload/components/fileUploadButton'
import {DroppedFile} from '../../FileUpload/components/ProjectFileUploader'
import {FileUploadedTable} from '../../FileUpload/components/FileUploadedTable'

const ACCEPTED_FILE_TYPES = ['.jpeg', '.jpg', '.png']

export type ExportQualityReportAsPDFModalProps = {
	onClose: () => void
	onSubmit: (dto: ExportQualityPdfDto) => Promise<Blob>
	onError: (error: Error) => void
	fileName: string
}

export type ExportQualityPdfDto = {
	showQuality: boolean
	includeMagnitudeDistribution: boolean
	descriptionText: string
	selectedImages: DroppedFile[]
}

export function ExportQualityReportAsPDFModal({
	onClose,
	onSubmit,
	onError,
	fileName,
}: ExportQualityReportAsPDFModalProps) {
	const [showQuality, setShowQuality] = useState(false)
	const [includeMagnitudeDistribution, setIncludeMagnitudeDistribution] = useState(false)
	const [description, setDescription] = React.useState('')
	const [selectedImages, setSelectedImages] = React.useState<DroppedFile[]>([])
	const [uploading, setUploading] = React.useState<boolean>(false)
	const onFilesSelected = (files: File[]) =>
		setSelectedImages(oldS3Keys => oldS3Keys.concat(files.map(file => ({file, accepted: true}))))
	const onFilesRejected = (files: File[]) =>
		setSelectedImages(oldS3Keys => oldS3Keys.concat(files.map(file => ({file, accepted: false}))))
	const onRemove = (name: string) => setSelectedImages(images => images.filter(f => f.file.name !== name))
	const handleShowQuality = () => {
		setShowQuality(!showQuality)
	}

	const cleanDescriptionText = (description: string) => {
		//Remove duplicate newlines and trim whitespace
		return description.replace(/\n{2,}\s*/g, '\n\n').trim()
	}

	const fetchQualityReportFile = async () => {
		setUploading(true)
		try {
			const blob = await onSubmit({
				showQuality,
				includeMagnitudeDistribution,
				descriptionText: cleanDescriptionText(description),
				selectedImages,
			})
			setUploading(false)
			onClose()
			return blob
		} catch (error) {
			setUploading(false)
			onError(error)
		}
	}
	return (
		<Layer position={'top'} margin={{top: '100px'}}>
			<Form>
				<Box gap={'small'} pad={'medium'} width={'525px'}>
					<Box justify={'between'} direction={'row'}>
						<SRHeading level={4}>Export quality report</SRHeading>
						<SRIconButton icon={<Close />} onClick={onClose} alignSelf={'center'} />
					</Box>
					<Box gap="xsmall">
						<SRHeading level={5}>Add more information</SRHeading>
						<Box margin={{left: '12px'}}>
							<label>
								<SRHeading level={6}>Add description</SRHeading>
								<TextArea
									maxLength={350}
									resize={false}
									style={{height: '100px'}}
									placeholder="Add an optional descriptive text to include in the report.&#10;Max 350 characters"
									value={description}
									onChange={event => setDescription(event.target.value)}
								/>
							</label>
						</Box>
						<Box margin={{left: '12px'}} gap={'xsmall'}>
							<SRHeading level={6}>Add images (optional)</SRHeading>
							<Text>Is possible to include images in the report.</Text>
							<Box direction="row" justify={'start'} gap={'medium'}>
								<FileUploadButton
									acceptedFileTypes={ACCEPTED_FILE_TYPES}
									onFileAccepted={onFilesSelected}
									onFileRejected={onFilesRejected}
								/>
								<Box overflow="auto" width={'340px'}>
									<FileUploadedTable
										files={selectedImages}
										uploadingFiles={{}}
										onRemove={onRemove}
										canRemoveFiles={!uploading}
										onCancelUpload={() => {}}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box gap="xsmall">
						<SRHeading level={5}>Visualize quality</SRHeading>
						<SRCheckBoxWithDescription
							label={'Show quality status information'}
							description={'The report will display the quality status labels.'}
							onChange={handleShowQuality}
							value={showQuality}
						/>
						<SRCheckBoxWithDescription
							label={'Include magnitude histogram'}
							description={'A graphic that shows the distribution of overall magnitudes.'}
							onChange={() => setIncludeMagnitudeDistribution(value => !value)}
							value={includeMagnitudeDistribution}
						/>
					</Box>
					<Box direction="row" justify="between">
						<SRPlainButton onClick={onClose} label="Cancel" size={'medium'} />
						<DownloadQualityReportPdfButton fileName={fileName} fetchQualityReportFile={fetchQualityReportFile} />
					</Box>
				</Box>
			</Form>
		</Layer>
	)
}
