import {AnalysisRequestEntity} from '../../../../AnalysisRequest/analysis-request.entities'
import {
	ManagementDashboardTableCell,
	ManagementDashboardTableRowBox,
} from '../../../core/components/table/ManagementDashboardTable'
import {Anchor, Text} from 'grommet'
import {format} from 'date-fns'
import React from 'react'
import {
	ANALYSIS_REQUEST_ID_WIDTH,
	CREATED_BY_WIDTH,
	DATE_CREATED_WIDTH,
} from './ManagementDashboardAnalysisRequestListTable'

export const ManagementDashboardAnalysisRequestListTableRow = ({
	analysisRequest,
	goToAnalysisRequestViewPage,
}: {
	analysisRequest: AnalysisRequestEntity
	goToAnalysisRequestViewPage: (analysisRequestId: string) => void
}) => (
	<ManagementDashboardTableRowBox key={analysisRequest.index}>
		<ManagementDashboardTableCell flexible width={{min: ANALYSIS_REQUEST_ID_WIDTH}}>
			<Text
				title={analysisRequest.analysisName}
				truncate
				color="accent-3"
				onClick={e => goToAnalysisRequestViewPage(analysisRequest._id)}
			>
				<Anchor color={'accent-3'}>{analysisRequest.analysisName}</Anchor>
			</Text>
		</ManagementDashboardTableCell>
		<ManagementDashboardTableCell width={DATE_CREATED_WIDTH}>
			{format(new Date(analysisRequest.createdDate), 'dd/MM/yyyy HH:mm')}
		</ManagementDashboardTableCell>
		<ManagementDashboardTableCell width={CREATED_BY_WIDTH}>
			<Text alignSelf="center" title={analysisRequest.createdBy.email} truncate>
				{analysisRequest.createdBy?.email}
			</Text>
		</ManagementDashboardTableCell>
	</ManagementDashboardTableRowBox>
)
