export const CLASSIFICATIONS_SECTION_ADD_MEASUREMENT = 'CLASSIFICATIONS_SECTION_ADD_MEASUREMENT'
export const CLASSIFICATIONS_SECTION_REMOVE_MEASUREMENT = 'CLASSIFICATIONS_SECTION_REMOVE_MEASUREMENT'
export const CLASSIFICATIONS_SECTION_CLEAR_MEASUREMENTS = 'CLASSIFICATIONS_SECTION_CLEAR_MEASUREMENT'
export const CLASSIFICATION_RESET_STATE = 'CLASSIFICATION_RESET_STATE'
export const USER_PROFILE_FETCH = 'USER_PROFILE_FETCH'
export const USER_PROFILE_FETCH_ERROR = 'USER_PROFILE_FETCH_ERROR'
export const USER_PROFILE_ADD = 'USER_PROFILE_ADD'
export const USER_PROJECT_SELECTED = 'USER_PROJECT_SELECTED'
export const USER_SIGN_OUT = 'USER_SIGN_OUT'
export const CURRENT_USER_ADD = 'CURRENT_USER_ADD'
export const SELECTED_CLASSIFICATIONS_FETCH_SUCCESS = 'SELECTED_CLASSIFICATIONS_FETCH_SUCCESS'
