import {buildAxios} from './api-factory'
import {config} from '../config'
import {AxiosInstance} from 'axios'
import {Observable} from 'rxjs'
import {fetchRetry, retry, retryOnly400To500Errors} from '../utilities/retryUtilities'
import {identity} from 'lodash'
import {sleep} from '../utilities/asyncUtilities'
import {getIdToken} from './getIdToken.api'
import {ApiRequestError} from 'sr-request-commons'
import {sanitizeRSQLStringRegex} from '../utilities/rsqlUtilities'
import {ForgeHub, ForgeItem, ForgeProject} from '../features/ForgeIntegration/forge-integration.entities'

export function buildFilesProjectApi(projectId: string, fileVisibility: FileVisibility, getToken = getIdToken) {
	return buildAxios(`${config.sr.backendUrl}files/v3/projects/${projectId}/files/${fileVisibility}s`, getToken)
}

export type FileStatus = 'uploading' | 'uploaded' | 'error'

export type FileVisibility = 'internal' | 'scan' | 'model' | 'document'

export type ForgeFileSource = {
	name: 'forge'
	meta: {
		forgeHubId: ForgeHub['id']
		forgeProjectId: ForgeProject['id']
		forgeItem: ForgeItem['id']
		forgeProjectType: ForgeProject['type']
		forgeVersion: ForgeItem['version']
	}
}

export type MasvFileSource = {name: 'masv'; meta: {masvId: string}}

export type FileSource = ForgeFileSource | MasvFileSource | null

export type ProjectFileEntity = {
	_id: string
	createdDate: Date
	filename: string
	size: number
	partSize: number
	numberOfParts: number
	s3Key: string
	hash: string | null
	contentType: string
	uploadId: string | null
	originalMasv: string | null
	status: FileStatus
	visibility: FileVisibility
	createdBy: string
	createdByRef: {
		_id: string
		email: string
	}
	scope: 'project'
	scopeId: string
	fileSource: FileSource
}

export type FilePartResponseDTO = {
	file: string
	partNumber: number
	partSize: number
	signedUrl: string
}

async function uploadFilePartToS3(signedUrl: string, blob: Blob) {
	return fetchRetry(signedUrl, {method: 'put', body: blob})
}

async function signedUploadPart(filesApi: AxiosInstance, id: string): Promise<FilePartResponseDTO> {
	return retry(
		async () => {
			return filesApi
				.post(`/${id}/signed-upload-part`)
				.then(response => response.data)
				.catch(retryOnly400To500Errors)
		},
		{maxFailures: 5, doubleTime: true, resetTimeoutInMillis: 1000},
	)
}

async function completePart(
	filesApi: AxiosInstance,
	id: string,
	dto: {etag: string; partNumber: number},
): Promise<{progress: number}> {
	return retry(
		() =>
			filesApi
				.post(`/${id}/uploaded`, dto)
				.then(response => response.data)
				.catch(retryOnly400To500Errors),
		{
			maxFailures: 5,
			doubleTime: true,
			resetTimeoutInMillis: 1000,
		},
	)
}

export function uploadFileToS3(filesApi: AxiosInstance, fileEntity: ProjectFileEntity | null, file: File) {
	return new Observable<number>(observer => {
		;(async () => {
			await sleep(1) //This exist to avoid $source undefined in useFileUploader.ts
			while (true) {
				try {
					if (fileEntity === null) {
						observer.error(new Error('File Invalid'))
						break
					}
					const signedResponse = await signedUploadPart(filesApi, fileEntity._id).catch(e => {
						if (e instanceof ApiRequestError && e.response.status === 404) {
							observer.complete()
						}
						observer.error(e)
					})
					if (!signedResponse) break
					const start = (signedResponse.partNumber - 1) * fileEntity.partSize
					let end = start + fileEntity.partSize
					end = end > fileEntity.size ? fileEntity.size : end
					const s3Response = await uploadFilePartToS3(signedResponse.signedUrl, file.slice(start, end))
					if (s3Response.status > 400) {
						if (s3Response.status === 404) {
							// it seems this has been canceled
							observer.complete()
							break
						}
						observer.error(new Error('Error uploading file.'))
					}
					const dto = {etag: s3Response.headers.get('ETag') as string, partNumber: signedResponse.partNumber}
					try {
						const uploadedResponse = await completePart(filesApi, fileEntity._id, dto)
						observer.next(uploadedResponse.progress)
					} catch (e) {
						if (e instanceof ApiRequestError && e.response.status === 404) {
							observer.complete()
						} else {
							observer.error(e)
						}
					}
				} catch (e) {
					observer.error(e)
				}
			}
			observer.next(100) //no more pending parts
			observer.complete()
		})()
	})
}

export function initializeProjectFileUpload(
	filesApi: AxiosInstance,
	filename: string,
	size: number,
	hash: string,
	visibility: FileVisibility,
): Promise<ProjectFileEntity> {
	return filesApi.post(`/`, {filename, size, hash, visibility}).then(response => response.data)
}

export function cancelFileUpload(filesApi: AxiosInstance, fileId: string): Promise<void> {
	return filesApi.delete(`/${fileId}`).then(response => response.data)
}

export const fetchProjectFiles = async (
	filesApi: AxiosInstance,
	searchText?: string,
	statuses?: FileStatus[],
	sort: string = '_id',
): Promise<ProjectFileEntity[]> => {
	//Filter checks for statuses OR fileSource.name 'forge'
	const filter = [
		statuses?.length && `(status=in=("${statuses.join('","')}"),fileSource.name=="forge")`,
		searchText && searchText !== '' && `filename=~"${sanitizeRSQLStringRegex(searchText)}"=si`,
	]
		.filter(identity)
		.join(';')
	const params = {skip: 0, limit: Number.MAX_SAFE_INTEGER, sort, filter}
	return (await filesApi.get('', {params})).data.result
}

export function deleteFile(projectId: string, visibility: FileVisibility, fileId: string): Promise<void> {
	const api = buildFilesProjectApi(projectId, visibility)
	return api.delete(`/${fileId}`).then(response => response.data)
}
