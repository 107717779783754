import styled from 'styled-components'
import {Box, Text} from 'grommet'
import {useSidebarWidth} from '../../hooks/useSidebarWidth'
import {Spinner} from '../CommonsCandidate/Loading/Spinner'
import React from 'react'
import {useTrackTimeDisplayed} from '../../hooks/useTrackTimeDisplayed'

const FloatingBox = styled(Box)`
	position: absolute;
	z-index: 100;
	bottom: 17px;
	right: 15px;

	@media screen and (max-width: 1550px) {
		&.sidebar-open .classifications-spinner-text {
			display: none;
		}
	}

	@media screen and (max-width: 1150px) {
		.classifications-spinner-text {
			display: none;
		}
	}
`

export function ClassificationsLoadingSpinnerToast() {
	const sidebarWidth = useSidebarWidth()

	useTrackTimeDisplayed('ClassificationLoader')

	return (
		<FloatingBox className={sidebarWidth !== '0px' ? 'sidebar-open' : undefined}>
			<Box
				round="xsmall"
				direction="row"
				pad="13px"
				background="rgba(107, 113, 123, 0.4)"
				align="center"
				alignSelf="end"
			>
				<Spinner hideIfGlobalLoader={false} center={false} color="light" size="small" />
				<Text margin={{left: 'small'}} className="classifications-spinner-text" color="white">
					Classifications are loading
				</Text>
			</Box>
		</FloatingBox>
	)
}
