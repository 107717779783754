import {PermissionsEnum} from '../../../entities/auth'
import {ManagementDashboardPageFileMeta} from '../core/management-dashboard-page.utils'

export const MODELS_PAGE_META: ManagementDashboardPageFileMeta = {
	name: 'Models',
	singular: 'Model',
	displayVersion: true,
	permission: PermissionsEnum.ADMIN,
	emptyView: {
		title: "You haven't uploaded any models yet.",
		message: 'Uploaded models will appear here.',
		actionText: 'Upload model',
	},
}
