import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {UserProject} from '../../../reducers/userProfile'
import {
	buildFilesProjectApi,
	fetchProjectFiles,
	FileStatus,
	FileVisibility,
	ProjectFileEntity,
} from '../../../api/files.api'
import {useMemo, useState} from 'react'
import {useGlobalNotifications} from '../../GlobalNotifications'
import {
	getProjectFilesByVisibilityFullQueryKey,
	getProjectFilesByVisibilityProjectQueryKey,
} from './projectFileQueryKey.utils'
import {defaultQueryClient} from '../../../utilities/query-client.util'

export const invalidateUseProjectFilesByVisibility = async (
	project: UserProject | null,
	fileVisibility: FileVisibility,
) => defaultQueryClient.invalidateQueries(getProjectFilesByVisibilityProjectQueryKey(project?._id, fileVisibility))

export function useProjectFilesByVisibility(
	project: UserProject | null,
	fileVisibility: FileVisibility,
	searchText: string,
	statuses?: FileStatus[],
) {
	const {notifyAlert} = useGlobalNotifications()
	const api = useMemo(() => buildFilesProjectApi(project?._id || '', fileVisibility), [fileVisibility, project?._id])
	return useQuery<ProjectFileEntity[], AxiosError>(
		getProjectFilesByVisibilityFullQueryKey(project?._id, fileVisibility, searchText, statuses),
		() => fetchProjectFiles(api, searchText, statuses),
		{
			enabled: !!project,
			onError: () => notifyAlert("We couldn't display the files, please retry."),
		},
	)
}

export function useProjectFileByVisibilityWithSearch(
	project: UserProject | null,
	fileVisibility: FileVisibility,
	statuses?: FileStatus[],
) {
	const [searchText, setSearchText] = useState<string>('')
	const {data: files, isLoading, isError} = useProjectFilesByVisibility(project, fileVisibility, searchText, statuses)
	return {files, isLoading, isError, searchText, setSearchText}
}

export function useDeleteProjectFile(
	project: UserProject | null,
	fileVisibility: FileVisibility,
	searchText: string,
	statuses?: FileStatus[],
) {
	const {notifyAlert} = useGlobalNotifications()
	const api = useMemo(() => buildFilesProjectApi(project?._id || '', fileVisibility), [fileVisibility, project?._id])
	return useQuery<ProjectFileEntity[], AxiosError>(
		getProjectFilesByVisibilityFullQueryKey(project?._id, fileVisibility, searchText, statuses),
		() => fetchProjectFiles(api, searchText, statuses),
		{
			enabled: !!project,
			onError: () => notifyAlert("We couldn't display the files, please retry."),
		},
	)
}
