import {Box, CheckBox, Text} from 'grommet'
import React, {ReactNode, useEffect, useState} from 'react'
import {ColorType} from 'grommet/utils'
import {SREmptyView, SRFileIcon, SRFolderIcon, SRHeading, SRIconButton, SRSpinnerIcon} from 'sr-react-commons'
import {CaretDownFill} from 'grommet-icons'
import {format} from 'date-fns'
import {LoadingWrapper} from '../Loading/LoadingWrapper'
import {
	nodeDepth,
	selectedFileIds,
	SRFileExplorerFileNode,
	SRFileExplorerFolderNode,
	SRFileExplorerNode,
} from './SRFileExplorer.logic'
import {ForgeFSFileNode} from '../../../features/ForgeIntegration/components/ForgeFileExplorer/ForgeFileExplorer.logic'
import {difference} from 'lodash'

const SRFileExplorerRowBox = ({
	borderColor = 'light-2',
	children,
	onClick,
}: {
	borderColor?: ColorType
	children: ReactNode
	onClick?: () => void
}) => (
	<Box
		fill={'horizontal'}
		direction={'row'}
		border={{side: 'bottom', size: 'xsmall', color: borderColor}}
		pad={{vertical: 'xsmall'}}
		height={{min: '37px'}}
		onClick={onClick}
	>
		{children}
	</Box>
)

const SRFileExplorerFirstCellBox = ({depth = 0, children}: {depth?: number; children: ReactNode}) => (
	<Box
		fill={'horizontal'}
		pad={{left: `${depth * 24}px`, right: 'xxsmall'}}
		direction={'row'}
		align={'center'}
		gap={'xsmall'}
	>
		{children}
	</Box>
)

const SRFileExplorerUpdatedCellBox = ({children}: {children: ReactNode}) => (
	<Box width={'130px'} flex={{grow: 0, shrink: 0}}>
		{children}
	</Box>
)

const SRFileExplorerCheckedCellBox = ({children}: {children?: ReactNode}) => (
	<Box width={'28px'} flex={{grow: 0, shrink: 0}}>
		{children}
	</Box>
)

const SRFileExplorerLoadingRow = ({node}: {node: SRFileExplorerFolderNode}) => (
	<SRFileExplorerRowBox>
		<SRFileExplorerFirstCellBox depth={nodeDepth(node) + 1}>
			<SRSpinnerIcon size={'xsmall'} />
			<Text style={{fontStyle: 'italic'}} color={'brand'} size={'small'}>
				Loading
			</Text>
		</SRFileExplorerFirstCellBox>
	</SRFileExplorerRowBox>
)

const SRFileExplorerEmptyFolderRow = ({node}: {node: SRFileExplorerFolderNode}) => (
	<SRFileExplorerRowBox>
		<SRFileExplorerFirstCellBox depth={nodeDepth(node) + 1}>
			<Text style={{fontStyle: 'italic'}} color={'brand'} size={'small'}>
				Empty Folder
			</Text>
		</SRFileExplorerFirstCellBox>
	</SRFileExplorerRowBox>
)

const SRFileExplorerEmptyView = () => (
	<SREmptyView
		message={'This folder is empty, upload the files that you need first.'}
		title={'There are no files to show'}
	/>
)

const renderChildren = (
	children: SRFileExplorerNode[],
	toggleFileSelected: SRFileExplorerProps['toggleFileSelected'],
	toggleFolderExpanded: SRFileExplorerProps['toggleFolderExpanded'],
) =>
	children.map(child => (
		<SRFileExplorerNodeRow
			key={`file-explorer.row.${child.id}`}
			node={child}
			{...{toggleFileSelected, toggleFolderExpanded}}
		/>
	))

const SRFileExplorerNodeRow = ({
	node,
	toggleFileSelected,
	toggleFolderExpanded,
}: {
	node: SRFileExplorerNode
	toggleFileSelected: SRFileExplorerProps['toggleFileSelected']
	toggleFolderExpanded: SRFileExplorerProps['toggleFolderExpanded']
}) => {
	return (
		<>
			<SRFileExplorerRowBox onClick={() => (node.leaf ? toggleFileSelected(node) : toggleFolderExpanded(node))}>
				<SRFileExplorerFirstCellBox depth={nodeDepth(node)}>
					<Box direction={'row'} gap={'xxsmall'} align={'center'} flex={{shrink: 0, grow: 0}}>
						{node.leaf ? (
							<SRFileIcon size={'large'} />
						) : (
							<>
								<SRIconButton
									size={'small'}
									pad={'0px'}
									icon={<CaretDownFill style={{transform: `rotate(${node.expanded ? 0 : 270}deg)`}} />}
								/>
								<SRFolderIcon size={'large'} />
							</>
						)}
					</Box>
					<Text style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} title={node.name}>
						{node.name}
					</Text>
				</SRFileExplorerFirstCellBox>
				<SRFileExplorerUpdatedCellBox>{format(new Date(node.date), 'dd/MM/yyyy HH:mm')}</SRFileExplorerUpdatedCellBox>
				<SRFileExplorerCheckedCellBox>
					{!node.leaf ? null : <CheckBox checked={node.selected} />}
				</SRFileExplorerCheckedCellBox>
			</SRFileExplorerRowBox>
			{node.leaf || !node.expanded ? null : node.loading ? (
				<SRFileExplorerLoadingRow node={node} />
			) : !node.children?.length ? (
				<SRFileExplorerEmptyFolderRow node={node} />
			) : (
				renderChildren(node.children, toggleFileSelected, toggleFolderExpanded)
			)}
		</>
	)
}

export type SRFileExplorerProps = {
	rootNodes?: SRFileExplorerNode[]
	toggleFileSelected: (file: SRFileExplorerFileNode) => void
	toggleFolderExpanded: (folder: SRFileExplorerFolderNode) => void
	onSelectionChange?: (selectedFileIds: ForgeFSFileNode['id'][]) => void
	dateColumnTitle?: string
	fileNameColumnTitle?: string
}

export const SRFileExplorer = ({
	rootNodes,
	toggleFolderExpanded,
	toggleFileSelected,
	onSelectionChange,
	dateColumnTitle = 'Updated',
	fileNameColumnTitle = 'Model files',
}: SRFileExplorerProps) => {
	const [selectedIds, setSelectedIds] = useState<SRFileExplorerFileNode['id'][]>(selectedFileIds(rootNodes || []))
	useEffect(() => {
		if (!onSelectionChange) return
		const newSelectedIds = selectedFileIds(rootNodes || [])
		if ([...difference(selectedIds, newSelectedIds), ...difference(newSelectedIds, selectedIds)].length) {
			setSelectedIds(newSelectedIds)
			onSelectionChange(newSelectedIds)
		}
	}, [selectedIds, rootNodes, onSelectionChange])
	return (
		<LoadingWrapper isLoading={!rootNodes} fill spinnerText={'Loading Files'}>
			{!rootNodes ? null : !rootNodes.length ? (
				<SRFileExplorerEmptyView />
			) : (
				<Box fill>
					<SRFileExplorerRowBox borderColor="brand">
						<SRFileExplorerFirstCellBox>
							<SRHeading level={6} color="neutral-1">
								{fileNameColumnTitle}
							</SRHeading>
						</SRFileExplorerFirstCellBox>
						<SRFileExplorerUpdatedCellBox>
							<SRHeading level={6} color="neutral-1">
								{dateColumnTitle}
							</SRHeading>
						</SRFileExplorerUpdatedCellBox>
						<SRFileExplorerCheckedCellBox />
					</SRFileExplorerRowBox>
					<Box fill overflow={'auto'}>
						<Box flex={false}>{renderChildren(rootNodes, toggleFileSelected, toggleFolderExpanded)}</Box>
					</Box>
				</Box>
			)}
		</LoadingWrapper>
	)
}
