import {NonEmptyList} from '../../../types/base.types'
import React, {ReactNode} from 'react'
import {Box} from 'grommet'
import {SRDropButton, SRDropButtonButtonType, SRDropButtonProps, SRPlainButton} from 'sr-react-commons'

export type SRMultiActionDropButtonProps<BTN extends SRDropButtonButtonType> = Omit<
	SRDropButtonProps<BTN>,
	'dropContent'
> & {
	actions: NonEmptyList<{icon?: ReactNode; label: ReactNode; onClick: () => void} | {component: JSX.Element}>
	dropIfOnlyOne?: boolean
}

export const SRMultiActionDropButton = <BTN extends SRDropButtonButtonType>({
	actions,
	dropIfOnlyOne,
	buttonClass: ButtonClass,
	...dropBtnProps
}: SRMultiActionDropButtonProps<BTN>) => {
	return !dropIfOnlyOne && actions.length === 1 ? (
		'label' in actions[0] ? (
			<ButtonClass {...(dropBtnProps as any)} onClick={actions[0].onClick} />
		) : (
			actions[0].component
		)
	) : (
		<SRDropButton
			{...(dropBtnProps as any)}
			buttonClass={ButtonClass}
			dropClickCloses
			dropAlign={{top: 'bottom', right: 'right'}}
			dropContent={
				<Box flex={false} width={'250px'}>
					{actions.map(action => {
						if ('component' in action) {
							return action.component
						}
						return (
							<SRPlainButton
								key={`SRMultiActionDropButton.action.${action.label}`}
								alignSelf="stretch"
								size={'medium'}
								label={
									<Box direction={'row'} gap={'small'} align={'center'} pad={'xsmall'}>
										{action.icon || null}
										{action.label}
									</Box>
								}
								onClick={action.onClick}
							/>
						)
					})}
				</Box>
			}
		/>
	)
}
