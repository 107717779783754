import React, {useCallback, useState} from 'react'
import {SRPrimaryButton} from 'sr-react-commons'
import {PrimaryModal} from '../../../components/CommonsCandidate/Modals/PrimaryModal'
import {Box, Text} from 'grommet'
import {invalidateUseCurrentForgeIntegration} from '../hooks/UseCurrentForgeIntegration'
import {ForgeIntegrationCreateModal} from './ForgeIntegrationCreateModal'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'

export type ForgeIntegrationProjectNotConnectedModalProps = {
	onClose?: () => void
	open: boolean
}

export const ForgeIntegrationProjectNotConnectedModal = ({
	open,
	onClose,
}: ForgeIntegrationProjectNotConnectedModalProps) => {
	const [showProjectNotConnectedModal, setShowProjectNotConnectedModal] = useState(false)
	const closeProjectNotConnectedModal = useCallback(() => setShowProjectNotConnectedModal(false), [
		setShowProjectNotConnectedModal,
	])
	const onIntegrationCreated = useCallback(async () => {
		setShowProjectNotConnectedModal(false)
		await invalidateUseCurrentForgeIntegration()
	}, [setShowProjectNotConnectedModal])
	return (
		<>
			{!showProjectNotConnectedModal ? null : (
				<ForgeIntegrationCreateModal close={closeProjectNotConnectedModal} onIntegration={onIntegrationCreated} />
			)}
			<PrimaryModal
				open={open}
				onClose={onClose}
				submitElement={
					<SRPrimaryButton
						disabled={showProjectNotConnectedModal}
						label={'Connect Project'}
						onClick={() => {
							setShowProjectNotConnectedModal(true)
						}}
						icon={showProjectNotConnectedModal ? <Spinner size={'xsmall'} color={'darker'} /> : undefined}
					/>
				}
				title={'Import from Autodesk'}
				heading={'Your project is not connected to Autodesk'}
				bodyElement={
					<Box gap={'xxsmall'}>
						<Text>Connect your project to browse files.</Text>
						<Text>You many need to contact your Autodesk account admin.</Text>
					</Box>
				}
			/>
		</>
	)
}
