import React from 'react'
// @ts-ignore
import convert from 'convert-units'
import {getBestReferenceScale} from '../ScaleUtils'

interface ScaleBarProps {
	scale: number
	zoom: number
	maxWidth: number
}

export function ScaleBar({scale, zoom, maxWidth}: ScaleBarProps) {
	const bestReferenceScale = getBestReferenceScale(maxWidth, scale, zoom)
	const scaleBarWidth = bestReferenceScale / (scale / zoom)

	// @ts-ignore
	const bestConversion = convert(bestReferenceScale).from('m').toBest()

	return (
		<div className="scale-bar-container">
			<svg height="18px" width={scaleBarWidth + 'px'}>
				<g y="0">
					<rect x="0" height="3" width="10%" />
					<rect width="10%" height="3" x="10%" y="3" />
					<rect x="20%" height="3" width="20%" />
					<rect width="60%" height="3" x="40%" y="3" />
					<g style={{fontSize: '12px'}}>
						<text y="18" x="20%" textAnchor="middle">
							{bestConversion.val * 0.2}
						</text>
						<text y="18" x="40%" textAnchor="middle">
							{bestConversion.val * 0.4}
						</text>
						<text y="18" x="100%" textAnchor="end">
							{bestConversion.val} {bestConversion.unit}
						</text>
					</g>
				</g>
			</svg>
		</div>
	)
}
