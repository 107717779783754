export const SET_VISIBLE_ELEMENTS = 'SET_VISIBLE_ELEMENTS' as const
export const GET_AGGREGATE_PROPERTIES = 'GET_AGGREGATE_PROPERTIES' as const
export const SET_DB_READY = 'SET_DB_READY' as const
export const SET_USER_PROFILE_READY = 'SET_USER_PROFILE_READY' as const
export const SET_FORGE_READY = 'SET_FORGE_READY' as const
export const SET_FORGE_MAPPING_READY = 'SET_FORGE_MAPPING_READY' as const
export const THREESIXTY_VIEWSPHERE_SELECT = 'THREESIXTY_VIEWSPHERE_SELECT' as const
export const THREESIXTY_CLASSIFICATION_SELECT = 'THREESIXTY_CLASSIFICATION_SELECT' as const
export const SET_CLASSIFICATIONS_PAINTED = 'SET_CLASSIFICATIONS_PAINTED' as const
export const RESET_VIEWER_STATE = 'RESET_VIEWER_STATE' as const
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE' as const
export const SET_VIEWER = 'SET_VIEWER' as const
export const SET_VIEWER_INITIALIZED = 'SET_VIEWER_INITIALIZED' as const
export const TOGGLE_ASSETS_MODE = 'TOGGLE_THREESIXTY_MODE' as const
export const TOGGLE_THREESIXTY_OPENED = 'TOGGLE_THREESIXTY_OPENED' as const
export const TOGGLE_THREESIXTY_HIGHLIGHT = 'TOGGLE_THREESIXTY_HIGHLIGHT' as const
export const TOGGLE_THREESIXTY_LOCKED_VIEW = 'TOGGLE_THREESIXTY_LOCKED_VIEW' as const
export const TOGGLE_MANUAL_WORKFLOW = 'TOGGLE_MANUAL_WORKFLOW' as const
export const SET_APPROVED_FILTER = 'SET_APPROVED_FILTER' as const
export const SET_DISPLAY_ALL_ELEMENTS = 'SET_DISPLAY_ALL_ELEMENTS' as const
export const TOGGLE_CLASSIFICATIONS_COLORING = 'TOGGLE_CLASSIFICATIONS_COLORING' as const
