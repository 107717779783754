import {DateInput} from 'grommet'
import React from 'react'
import styled from 'styled-components'

const StyledDateInput = styled(DateInput)`
	line-height: ${props => props.theme.text.medium.height};
`

export function SRDateInput(props: {value: Date | null; onChange: (date: Date) => void}) {
	return (
		<StyledDateInput
			calendarProps={{size: 'small'}}
			inputProps={{readOnly: true}}
			format="dd/mm/yyyy"
			value={props.value?.toISOString() || ''}
			onChange={({value}) => props.onChange(new Date(value as string))}
		/>
	)
}
