import React from 'react'
import {
	ManagementDashboardPage,
	pageName,
	useIsManagementDashboardPageEnabled,
} from '../../management-dashboard-page.utils'
import {Box, Text} from 'grommet'
import {SRBrandSelectableIconButtonProps, SRLightBrandSelectableButton} from 'sr-react-commons'
import {history} from '../../../../../router/history'
import {useManagementDashboardState} from '../../management-dashboad.slice'
import {UserProject} from '../../../../../reducers/userProfile'
import {Performance} from 'grommet-icons'

const ManagementDashboardSidebarButton = ({
	page,
	currentPage,
	projectId,
	icon,
}: {
	page: ManagementDashboardPage
	currentPage: ManagementDashboardPage
	projectId: UserProject['_id']
	icon?: SRBrandSelectableIconButtonProps['icon']
}) => (
	<SRLightBrandSelectableButton
		size={'large'}
		label={
			<Box direction="row" align="center" gap="small">
				{icon}
				<Text weight={'bold'}>{pageName(page)}</Text>
			</Box>
		}
		pad="15px"
		isSelected={currentPage === page}
		onClick={() => history.push(`manage/${projectId}/${page}`)}
		bgColor="white"
		textColor="text-light"
		hoverBgColor="selected"
	/>
)

export const ManagementDashboardSidebar = () => {
	const {page, project} = useManagementDashboardState()
	const isPageEnabled = useIsManagementDashboardPageEnabled()
	const tabs: ManagementDashboardPage[] = ['analysisrequest', 'scans', 'models', 'documents']
	return (
		<Box
			fill="vertical"
			justify={'between'}
			width="255px"
			border={{color: 'rgba(107, 113, 123, 0.2)', side: 'right'}}
			pad={{horizontal: 'small', bottom: 'small', top: '120px'}}
			gap={'xsmall'}
		>
			<Box fill="vertical" gap={'xxsmall'} pad={{bottom: 'small'}} overflow={{vertical: 'auto'}}>
				{tabs.filter(isPageEnabled).map(tab => (
					<ManagementDashboardSidebarButton
						page={tab}
						currentPage={page!}
						projectId={project?._id!}
						key={`page-${tab}-tab`}
					/>
				))}
			</Box>
			{!isPageEnabled('integrations') ? null : (
				<ManagementDashboardSidebarButton
					page={'integrations'}
					currentPage={page!}
					projectId={project?._id!}
					icon={<Performance />}
				/>
			)}
		</Box>
	)
}
