import React from 'react'
import {Image, ImageProps} from 'grommet'
import {useS3ImageLoader} from '../../hooks/useS3ImageLoader'

export function ProtectedImage(
	props: {s3key: string; alt: string; plain?: boolean; spinner?: JSX.Element; publicPath?: boolean} & ImageProps &
		JSX.IntrinsicElements['img'],
) {
	const {s3key, plain, alt, spinner, publicPath, ...rest} = props

	const {status, imageSourceRef} = useS3ImageLoader(s3key, publicPath)

	if (status === 'loaded') {
		if (plain) {
			return <img alt={alt} {...rest} src={imageSourceRef.current} />
		} else {
			return <Image {...rest} src={imageSourceRef.current} />
		}
	} else {
		if (spinner) {
			return spinner
		} else {
			return null
		}
	}
}
