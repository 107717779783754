import React from 'react'
import {Anchor, Box, Text} from 'grommet'
import {IssueEntity} from '../../../features/IssueManagement/issue.entities'
import {SR_COLOR_ACCENT_3, SR_COLOR_TEXT_LIGHT, SR_COLOR_WHITE} from 'sr-react-commons'

export type SRPropertiesTooltipProps = {
	status: 'verified' | 'deviated' | 'under_construction' | 'missing' | 'no_data' | 'no_classification'
	issues: Pick<IssueEntity, '_id' | 'issueId'>[]
	name: string
	onClickIssue: (issue: Pick<IssueEntity, '_id' | 'issueId'>) => void
	magnitude?: number
}

export const SRPropertiesTooltip = ({status, issues, name, onClickIssue, magnitude}: SRPropertiesTooltipProps) => {
	const {color, name: statusLabel} =
		status === 'verified'
			? {color: 'status-verified', name: 'Verified'}
			: status === 'deviated'
			? {color: 'status-deviated', name: 'Deviated'}
			: status === 'under_construction'
			? {color: 'status-under-construction', name: 'Under construction'}
			: status === 'missing'
			? {color: 'status-missing', name: 'Missing'}
			: {color: 'status-no-data', name: 'No data'}
	return status === 'no_classification' ? null : (
		<Box width="264px" round="xlarge" elevation="small">
			<Box height="8px" background={color} />
			<Box color={SR_COLOR_TEXT_LIGHT} background={SR_COLOR_WHITE} pad={'xsmall'} gap="xxsmall">
				<Box direction="row" gap="xxsmall">
					<Text size={'small'} weight="bold">
						{statusLabel}
					</Text>
					{magnitude !== undefined && (status === 'missing' || status === 'no_data') ? null : (
						<Text size={'small'} weight="bold">
							- {magnitude}mm
						</Text>
					)}
				</Box>
				<Text size={'small'} truncate title={name}>
					{name}
				</Text>
				{issues.length > 0 ? (
					<Box direction="row" gap="xxsmall">
						<Text size={'small'}>Issues:</Text>
						<Text size={'small'} truncate>
							{issues.map((issue: Pick<IssueEntity, '_id' | 'issueId'>, index) => (
								<Anchor key={issue._id} color={SR_COLOR_ACCENT_3} onClick={() => onClickIssue(issue)}>
									<Text size={'small'} title={`#${issue.issueId}`}>
										#{issue.issueId}
										{index === issues.length - 1 ? '' : ', '}
									</Text>
								</Anchor>
							))}
						</Text>
					</Box>
				) : null}
			</Box>
		</Box>
	)
}
