import React from 'react'
import {Box} from 'grommet'
import {Checkbox, CheckboxSelected, DocumentPdf, MoreVertical} from 'grommet-icons'
import {SRDocumentBcf, SRDropButton, SRIconButton, SRPlainButton, SRSpinnerIcon} from 'sr-react-commons'
import {IssueExportFormat} from '../Export'

function IssueSelectButton({
	icon,
	label,
	disabled,
	onClick,
}: {
	icon: JSX.Element | undefined
	label: string
	disabled: boolean
	onClick: () => void
}) {
	return (
		<SRPlainButton
			alignSelf="stretch"
			size={'medium'}
			label={
				<Box direction={'row'} gap={'small'} align={'center'} pad={'xsmall'}>
					{icon}
					{label}
				</Box>
			}
			onClick={onClick}
			disabled={disabled}
		/>
	)
}

export function IssueMenuDropDown({
	onCheckAll,
	onCheckNone,
	canCheckAll,
	anyIssueChecked,
	onExport,
	onClose,
}: {
	onCheckAll: () => void
	onCheckNone: () => void
	canCheckAll: boolean
	anyIssueChecked: boolean
	onExport: (exportAs: IssueExportFormat) => Promise<void>
	onClose: () => void
}) {
	const [exportStatus, setExportStatus] = React.useState<
		'idle' | 'exportingAsBCF' | 'exportingAsPDF' | 'exportingAsCSV'
	>('idle')
	const isExporting = exportStatus !== 'idle'

	return (
		<Box flex={false} width={'250px'}>
			<Box fill>
				<IssueSelectButton
					icon={<CheckboxSelected size={'medium'} />}
					label="Select all"
					onClick={() => {
						onCheckAll()
						onClose()
					}}
					disabled={!canCheckAll || isExporting}
				/>
				<IssueSelectButton
					icon={<Checkbox size={'medium'} />}
					label="Unselect all"
					onClick={() => {
						onCheckNone()
						onClose()
					}}
					disabled={!anyIssueChecked || isExporting}
				/>
				<IssueSelectButton
					icon={
						exportStatus === 'exportingAsBCF' ? <SRSpinnerIcon size={'medium'} /> : <SRDocumentBcf size={'medium'} />
					}
					label="Export as BCF"
					onClick={async () => {
						setExportStatus('exportingAsBCF')
						await onExport(IssueExportFormat.BCF)
						setExportStatus('idle')
						onClose()
					}}
					disabled={!anyIssueChecked || isExporting}
				/>
				<IssueSelectButton
					icon={exportStatus === 'exportingAsPDF' ? <SRSpinnerIcon size={'medium'} /> : <DocumentPdf size={'medium'} />}
					label="Export as PDF"
					onClick={async () => {
						setExportStatus('exportingAsPDF')
						await onExport(IssueExportFormat.PDF)
						setExportStatus('idle')
						onClose()
					}}
					disabled={!anyIssueChecked || isExporting}
				/>
				<IssueSelectButton
					icon={exportStatus === 'exportingAsCSV' ? <SRSpinnerIcon size={'medium'} /> : <DocumentPdf size={'medium'} />}
					label="Export as CSV"
					onClick={async () => {
						setExportStatus('exportingAsCSV')
						await onExport(IssueExportFormat.CSV)
						setExportStatus('idle')
						onClose()
					}}
					disabled={!anyIssueChecked || isExporting}
				/>
			</Box>
		</Box>
	)
}

export function IssueMenu({
	issueSelection: {canCheckAll, anyIssueChecked, onCheckAll, onCheckNone},
	onExport,
}: {
	issueSelection: {onCheckAll: () => void; onCheckNone: () => void; canCheckAll: boolean; anyIssueChecked: boolean}
	onExport: (exportAs: IssueExportFormat) => Promise<void>
}) {
	const [menuOpen, setMenuOpen] = React.useState(false)
	return (
		<SRDropButton
			buttonClass={SRIconButton}
			size="large"
			icon={<MoreVertical size={'large'} />}
			dropAlign={{top: 'bottom', right: 'right'}}
			open={menuOpen}
			onOpen={() => setMenuOpen(true)}
			onClose={() => setMenuOpen(false)}
			dropContent={
				<IssueMenuDropDown
					canCheckAll={canCheckAll}
					anyIssueChecked={anyIssueChecked}
					onCheckAll={onCheckAll}
					onCheckNone={onCheckNone}
					onExport={onExport}
					onClose={() => setMenuOpen(false)}
				/>
			}
		/>
	)
}
