import {SrFeatureFlag, SRFeatureFlags} from 'sr-feature-flags'

export class SRExtension extends Autodesk.Viewing.Extension {
	protected srFeatureFlags: SRFeatureFlags | undefined
	setSrFeatureFlags(reader: SRFeatureFlags) {
		this.srFeatureFlags = reader
	}
	featureEnabled(feature: SrFeatureFlag) {
		if (!this.srFeatureFlags) {
			throw new Error('SrFeatureReader is not defined.')
		}
		return this.srFeatureFlags.featureEnabled(feature)
	}
}
