import {Box} from 'grommet'
import {Close, LinkPrevious} from 'grommet-icons'
import React, {ReactNode} from 'react'
import {useBasePath} from '../../../../hooks/useBasePath'
import {history} from '../../../../router/history'
import {SRHeading, SRIconButton, SRRightPanelPadFix} from 'sr-react-commons'

export type RightPanelHeaderProps = {
	title: string
	back?: 'issues' | 'progress/work-packages' | 'insights'
	children?: ReactNode
	gap?: string
}

export const RightPanelHeader = ({title, back, children, gap = 'small'}: RightPanelHeaderProps) => {
	const basePath = useBasePath()
	const goBack = () => history.push(basePath + back)
	const close = () => history.push(basePath)
	return (
		<SRRightPanelPadFix>
			<Box flex={false} direction="row" justify="between">
				<Box direction={'row'} gap={'medium'} alignSelf={'center'}>
					{!back ? null : <SRIconButton icon={<LinkPrevious size={'large'} />} onClick={goBack} alignSelf="center" />}
					<SRHeading size={'26px'} level={5} alignSelf={'center'}>
						{title}
					</SRHeading>
				</Box>
				<Box direction={'row'} gap={gap} alignSelf={'center'}>
					{children ? <Box alignSelf={'center'}>{children}</Box> : null}
					<SRIconButton alignSelf="center" icon={<Close size={'large'} />} onClick={close} data-testid="close-panel" />
				</Box>
			</Box>
		</SRRightPanelPadFix>
	)
}
