import React from 'react'
import {ThemeContext, CheckBox} from 'grommet'
import {useDispatch, useSelector} from 'react-redux'
import {SET_APPROVED_FILTER, TOGGLE_MANUAL_WORKFLOW} from '../../actions/viewerTypes'
import {RootState} from '../../reducers'
import {useInvalidateClassifications} from '../../features/Classification/data-management/useInvalidateClassification'

export function ToggleApproveButton() {
	const viewerState = useSelector((state: RootState) => state.viewerState)
	const dispatch = useDispatch()
	const invalidateClassification = useInvalidateClassifications()

	const onManualWorkflowChange = async (value: boolean) => {
		dispatch({type: TOGGLE_MANUAL_WORKFLOW, value})

		if (!value) {
			dispatch({type: SET_APPROVED_FILTER, value: null})
		}
		await invalidateClassification()
	}

	return (
		<ThemeContext.Extend
			value={{
				checkBox: {
					extend: `
					width: 100%; 
					padding: 13px 0px; 
					border-radius: 4px;
					font-size: 14px;
					line-height: 18px;
					font-weight: 600;
					:hover {
						background-color: #D3D5D8;
					}
					`,
					checked: {
						color: 'black',
						border: {
							color: '#000',
						},
						extend: `
							border-color: #000;
						`,
					},
					size: '15px',
					toggle: {
						width: '100%',
						size: '30px',
						radius: '20px',
						background: 'white',
						extend: `
							:checked {
								border-color: #000;
							}
							margin-left: 15px;
							`,
					},
				},
			}}
		>
			<CheckBox
				checked={viewerState.manualWorkflowActivated}
				label={'Approval workflow'}
				toggle
				onChange={event => onManualWorkflowChange(event.target.checked)}
			/>
		</ThemeContext.Extend>
	)
}
