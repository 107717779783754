import {Box, Grid, Text} from 'grommet'
import {MagnitudeSelectorFilter} from './Magnitude/MagnitudeSelectorFilter'
import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ElementTypeFilter} from './ElementType/ElementTypeFilter'
import {RootState} from '../../../../reducers'
import {StatusFilter} from './Status/StatusFilter'
import {FloorFilter} from './Floor/FloorFilter'
import {GlobalIdFilter} from './GlobalId/GlobalIdFilter'
import {ElementNameFilter} from './ElementName/ElementNameFilter'
import {FiltersEntity} from '../../entities/filter-preset.entities'
import {classificationSlice, useClassificationState} from '../../../../reducers/classification.slice'
import _, {isEmpty} from 'lodash'
import {FilterPresetsMode} from '../FilterPresets'
import {
	WatchlistElementsFilter,
	watchlistFilterElementsSlice,
} from '../../../Watchlist/slices/watchlist-filter-elements.slice'
import {useElementFloorsOptions} from './Floor/useElementFloorsOptions'
import {RootModelsFilter} from './RootModels/RootModelsFilter'
import {useElementRootModelOptions} from './RootModels/useElementRootModelOptions'
import {useElementTypeLabelsForProject} from './ElementType/useElementTypeLabelsForProject'
import {useCurrentProject} from '../../../../hooks/useCurrentProject'
import {DeviationDescriptionFilter} from './Magnitude/DeviationDescriptionFilter'
import {useFeatureEnabled} from '../../../FeatureFlags/FeatureFlagsProvider'
import {ElementsOfInterestFilter} from './ElementsOfInterest/ElementsOfInterestFilter'

export function useFilterStateForMode(mode: 'overview' | 'work-package' | undefined) {
	const dispatch = useDispatch()
	const {filter} = useSelector((state: RootState) =>
		mode === 'overview' ? state.classification : state.watchlistFilterElementsState,
	)
	const setFilter = useCallback(
		<K extends keyof FiltersEntity>(filterKey: K, value: FiltersEntity[K]) => {
			mode === 'overview'
				? dispatch(
						classificationSlice.actions.setFilter({
							filter: {
								...(filter as FiltersEntity),
								[filterKey]: filterKey === 'eoi' ? value : isEmpty(value) ? null : value,
							},
						}),
				  )
				: dispatch(
						watchlistFilterElementsSlice.actions.setFilter({
							filter: {...(filter as WatchlistElementsFilter), [filterKey]: isEmpty(value) ? null : value},
						}),
				  )
		},
		[dispatch, filter, mode],
	)
	const setPartialFilter = useCallback(
		(filter: Partial<FiltersEntity> | Partial<WatchlistElementsFilter>) => {
			if (mode === 'overview') {
				dispatch(classificationSlice.actions.setPartialFilter(filter))
			} else {
				dispatch(watchlistFilterElementsSlice.actions.setPartialFilter(filter))
			}
		},
		[dispatch, mode],
	)
	return {filter, setFilter, setPartialFilter}
}

export function Filters({mode, readonly}: {mode: FilterPresetsMode; readonly: boolean}) {
	const {filter, setFilter, setPartialFilter} = useFilterStateForMode(mode)
	const setStatusFilter = useCallback(
		(statuses: string[] | null) => {
			setFilter('statuses', statuses ? _.sortBy(statuses) : null)
		},
		[setFilter],
	)
	const selectedProject = useCurrentProject()
	const {selectedAnalysisView} = useClassificationState()
	const model = selectedAnalysisView!.model._id

	const isWatchlist = mode === 'work-package'

	const elementTypeLabelsForProject = useElementTypeLabelsForProject(selectedProject, isWatchlist, model)
	const elementFloorsOptions = useElementFloorsOptions(selectedProject, isWatchlist, model)
	const elementRootModelOptions = useElementRootModelOptions(selectedProject, isWatchlist, model)

	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const displayRootModelFilter = useFeatureEnabled('displayRootModelFilter')
	const displayMagnitudeByAxis = useFeatureEnabled('displayMagnitudeByAxis')
	const eoiEnabled = useFeatureEnabled('elementsOfInterest')

	return (
		<Grid gap="xsmall">
			{!isWatchlist ? (
				<Box gap="xsmall">
					<Box border={{side: 'bottom', size: 'small', color: 'neutral-2'}}>
						<Text alignSelf="center" color="neutral-1" weight="bold" size="xsmall">
							CLASSIFICATION FILTERS
						</Text>
					</Box>
					<StatusFilter
						filters={filter as FiltersEntity}
						setFilter={setStatusFilter}
						enableUnderConstruction={enableUnderConstruction}
					/>
					<MagnitudeSelectorFilter
						numberOfSteps={5}
						filters={filter as FiltersEntity}
						setFilter={setPartialFilter}
						displayMagnitudeByAxis={displayMagnitudeByAxis}
					/>
					<DeviationDescriptionFilter
						filters={filter as FiltersEntity}
						setFilter={setPartialFilter}
						displayMagnitudeByAxis={displayMagnitudeByAxis}
					/>
				</Box>
			) : null}
			<Box border={{side: 'bottom', size: 'small', color: 'neutral-2'}}>
				<Text alignSelf="center" color="neutral-1" weight="bold" size="xsmall">
					MODEL FILTERS
				</Text>
			</Box>
			{displayRootModelFilter && (
				<RootModelsFilter
					rootModelOptions={elementRootModelOptions.data}
					filters={filter as FiltersEntity}
					setFilter={setFilter}
					isError={elementRootModelOptions.isError}
					isLoading={elementRootModelOptions.isLoading}
					readonly={readonly}
				/>
			)}
			<ElementTypeFilter
				elementTypes={elementTypeLabelsForProject.data}
				filters={filter}
				isError={elementTypeLabelsForProject.isError}
				isLoading={elementTypeLabelsForProject.isLoading}
				setFilter={setFilter}
				readonly={readonly}
			/>
			<FloorFilter
				floorOptions={elementFloorsOptions.data}
				filters={filter}
				isError={elementFloorsOptions.isError}
				isLoading={elementFloorsOptions.isLoading}
				setFilter={setFilter}
				readonly={readonly}
			/>
			{!isWatchlist ? (
				<Box gap="xsmall">
					<GlobalIdFilter filters={filter as FiltersEntity} setFilter={setFilter} />
					<ElementNameFilter filters={filter as FiltersEntity} setFilter={setFilter} />
				</Box>
			) : null}
			{eoiEnabled && <ElementsOfInterestFilter filters={filter as FiltersEntity} setFilter={setFilter} />}
		</Grid>
	)
}
