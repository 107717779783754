///////////////////////////////////////////////////////////////////////////////
// Autodesk.ScaledRobotics.VerticalToolbar.Extension.Toolbar
// https://through-the-interface.typepad.com/through_the_interface/2016/05/creating-a-vertical-toolbar-extension-for-the-autodesk-viewer.html
///////////////////////////////////////////////////////////////////////////////

const ExtensionId = 'Viewing.Extension.VerticalToolbar'
const Autodesk = window.Autodesk
export const VERTICAL_CONTROL_GROUP = 'verticalControlGroup'

export default class VerticalToolbarExtension extends Autodesk.Viewing.Extension {
	public SRVerticalToolbar: Autodesk.Viewing.UI.ToolBar
	public static toolbar: Autodesk.Viewing.UI.ToolBar

	constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
		super(viewer, options)
		this.viewer = viewer
		this.SRVerticalToolbar = new Autodesk.Viewing.UI.ToolBar('toolbar-TtIf')
	}
	load() {
		this.createToolbar()
		return true
	}

	unload() {
		document.getElementById('divToolbar')?.remove()
		return super.unload()
	}

	createToolbar() {
		const ctrlGroup = new Autodesk.Viewing.UI.ControlGroup(VERTICAL_CONTROL_GROUP)
		ctrlGroup.addClass('toolbar-vertical-group')

		this.SRVerticalToolbar.addControl(ctrlGroup)
		const toolbarDivHtml = document.createElement('div')
		toolbarDivHtml.id = 'divToolbar'
		toolbarDivHtml.appendChild(this.SRVerticalToolbar.container)
		this.viewer.container.appendChild(toolbarDivHtml)
		VerticalToolbarExtension.toolbar = this.SRVerticalToolbar
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionId, VerticalToolbarExtension)
