import React, {useEffect, useState} from 'react'
import {Box, Form, FormField, Text, TextArea, ThemeContext} from 'grommet'
import {IssueCommentCard} from './IssueCommentCard'
import {useSelector} from 'react-redux'
import {IssueComment} from '../issue-comment.entities'
import {restoreViewerFromIssue} from '../issueUtils'
import {IssueCard} from './IssueCard'
import {User} from '../../../types/user'
import {selectorCurrentUser} from '../../../selectors/current-user.selectors'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {SRPrimaryButton} from 'sr-react-commons'
import {IssueEntity} from '../issue.entities'
import {CardContainer} from '../../../components/CommonsCandidate/CardContainer/CardContainer'
import {useClassificationState} from '../../../reducers/classification.slice'

type IssueCommentsListProps = {
	issue: IssueEntity
	comments: IssueComment[]
	removeIssueComment: (id: IssueComment['_id']) => Promise<void>
	addIssueComment: (issueComment: Partial<IssueComment>) => Promise<IssueComment>
	isCreating: boolean
}

export function IssueCommentList({
	issue,
	comments,
	removeIssueComment,
	addIssueComment,
	isCreating,
}: IssueCommentsListProps) {
	const {threeSixtyLockedView} = useSelector(selectorViewerProperties)
	const [currentComment, setCurrentComment] = useState('')
	const user = useSelector(selectorCurrentUser)!
	const issueAnalysisView = useClassificationState().analysisViews!.find(av => av._id === issue.analysisView)!
	useEffect(() => {
		!threeSixtyLockedView && restoreViewerFromIssue(issue)
	}, [issue, threeSixtyLockedView])
	const handleSubmit = async () => {
		await addIssueComment({
			tenantId: issue.tenantId,
			issue: issue,
			comment: currentComment,
			user: user._id,
		})
		setCurrentComment('')
	}
	const handleDeleteClick = (comment: IssueComment) => removeIssueComment(comment._id)
	return (
		<Box gap="small">
			<IssueCard active={false} issue={issue} showEdit={false} issueAnalysisView={issueAnalysisView} />
			<CardContainer gap={'small'}>
				{comments.map(comment => {
					return (
						<IssueCommentCard
							key={comment._id}
							comment={comment}
							handleDeleteClick={
								(comment.user as User)?._id === user._id ? () => handleDeleteClick(comment) : undefined
							}
						/>
					)
				})}
				<Box>
					<ThemeContext.Extend value={{formField: {border: false, extend: 'input,textarea { font-weight: normal; }'}}}>
						<Form
							onKeyPress={event => event.ctrlKey && event.key === 'Enter' && handleSubmit()}
							onSubmit={handleSubmit}
						>
							<Text weight="bold" size="small" margin={{bottom: 'xsmall'}}>
								{user.firstName} {user.lastName}
							</Text>
							<FormField>
								<TextArea
									placeholder="Add a comment..."
									value={currentComment}
									id="comment"
									onChange={event => setCurrentComment(event.target.value)}
								/>
							</FormField>
							{!currentComment.length ? null : (
								<Box>
									<SRPrimaryButton disabled={isCreating} size="large" alignSelf={'end'} type="submit" label="Send" />
								</Box>
							)}
						</Form>
					</ThemeContext.Extend>
				</Box>
			</CardContainer>
		</Box>
	)
}
