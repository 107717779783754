import React, {useState} from 'react'
import {Box, Text} from 'grommet'
import {ProgressAggregation} from '../../aggregations/progress.aggregations'
import {WatchlistProgressBreakDownCardPresenter} from './WatchlistProgressBreakDownCardPresenter'
import {WatchlistBurnUpCardPresenter} from './WatchlistBurnUpCardPresenter'
import {WatchlistStatusPresenter} from '../list/WatchlistListCardPresenter'
import {RightPanelHeader} from '../../../../components/CommonsCandidate/Layout/RightPanel/RightPanelHeader'
import {format} from 'date-fns'
import {DocumentCsv, Edit} from 'grommet-icons'
import {LoadingWrapper} from '../../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {
	SRCardContainer,
	SRFileDownloadButton,
	SRHeading,
	SRIconButton,
	SRRightPanelBody,
	SRRightPanelContainer,
} from 'sr-react-commons'
import {SRHasInvalidIdsIconTooltip} from '../../../../components/HasInvalidIdsIconTooltip/HasInvalidIdsIconTooltip'
import {ExportWorkPackageAsPDFModal} from '../../modals/ExportWorkPackageAsPDFModal'
import {WorkPackageDetailExportMenu} from './WorkPackageDetailExportMenu'

export function WatchlistDetailPresenter({
	progress,
	isLoading,
	isError,
	goToEdit,
	hasInvalidIds = false,
	showPDFDownload = false,
	showElementTypeBreakdown = false,
	exportToCSVProps,
	enableUnderConstruction,
}: {
	progress: ProgressAggregation | null
	isLoading: boolean
	isError: boolean
	goToEdit: (id: string) => void
	exportToCSVProps: {
		fetchFile: () => Promise<Blob>
		filename: string
	}
	hasInvalidIds?: boolean
	showPDFDownload?: boolean
	showElementTypeBreakdown: boolean
	enableUnderConstruction: boolean
}) {
	const [showDownloadPDFModal, setShowDownloadPDFModal] = useState(false)

	const top = progress && (
		<Box flex={false} gap={'xsmall'}>
			<Box direction="row" justify="between">
				<Box direction="row" align="center" gap="small">
					{hasInvalidIds ? <SRHasInvalidIdsIconTooltip /> : null}
					<SRHeading level={4}>{progress.watchlist.name}</SRHeading>
				</Box>
				{showDownloadPDFModal && (
					<ExportWorkPackageAsPDFModal
						onClose={() => setShowDownloadPDFModal(false)}
						progress={progress}
						showElementTypeBreakdown={showElementTypeBreakdown}
						enableUnderConstruction={enableUnderConstruction}
					/>
				)}
				{showPDFDownload ? (
					<WorkPackageDetailExportMenu
						exportToCSVProps={exportToCSVProps}
						onExportAsPDF={() => setShowDownloadPDFModal(true)}
					/>
				) : (
					<SRFileDownloadButton
						buttonClass={SRIconButton}
						icon={<DocumentCsv size="large" />}
						getFile={exportToCSVProps.fetchFile}
						fileName={exportToCSVProps.filename}
					/>
				)}
			</Box>
			<Box direction="row" justify="between">
				<AttrPresenter
					title={'Start - End'}
					value={`${format(new Date(progress.watchlist.startDate), 'dd/MM/yyyy')} - ${format(
						new Date(progress.watchlist.endDate),
						'dd/MM/yyyy',
					)}`}
				/>
				<WatchlistStatusPresenter progress={progress} />
			</Box>
			{!progress.watchlist.primavera.wbs ? null : (
				<AttrPresenter title={'WBS Code'} value={progress.watchlist.primavera.wbs} />
			)}
			{!progress.watchlist.primavera.activityId ? null : (
				<AttrPresenter title={'Activity ID'} value={progress.watchlist.primavera.activityId} />
			)}
		</Box>
	)
	return (
		<SRRightPanelContainer>
			<RightPanelHeader title="Work Package" back="progress/work-packages">
				{progress && (
					<SRIconButton
						title="Edit Work package"
						icon={<Edit color={'neutral-1'} size={'large'} />}
						onClick={() => progress && goToEdit(progress.watchlist._id)}
					/>
				)}
			</RightPanelHeader>
			<SRRightPanelBody {...{top}}>
				<LoadingWrapper
					{...{isLoading, isError}}
					spinnerText={'Loading work package'}
					fill
					errorMsg="We couldn't display the work package, please retry."
				>
					{progress && (
						<Box gap={'small'}>
							{!progress.watchlist.description ? null : <Text color="neutral-1">{progress.watchlist.description}</Text>}
							<SRCardContainer>
								<WatchlistProgressBreakDownCardPresenter
									progress={progress}
									enableUnderConstruction={enableUnderConstruction}
								/>
								<WatchlistBurnUpCardPresenter {...{progress}} />
							</SRCardContainer>
						</Box>
					)}
				</LoadingWrapper>
			</SRRightPanelBody>
		</SRRightPanelContainer>
	)
}

const AttrPresenter = ({title, value}: {title: string; value: string}) => (
	<Text>
		<Box direction={'row'}>
			<Box style={{width: '86px'}}>
				<strong>{title}:</strong>
			</Box>
			{value}
		</Box>
	</Text>
)
