import {scaleFromIFCtoViewer} from '../../NaskaUtilities'
import closeIcon from './images/close.svg'

export class MagnitudeTooltips {
	hoveredPoint: {classificationId: string; pointIndex: number} | undefined
	private tooltipContainer: HTMLDivElement | undefined
	private hoverTooltipElement: HTMLDivElement | undefined
	private selectedPoints: {label: HTMLElement; classificationId: string; pointIndex: number}[] = []

	constructor(
		private getHeatmapData: (classificationId: string) => {pointCloud: THREE.PointCloud; magnitudes: number[]},
		private onUpdateSelectedPoints: (classificationId: string) => void,
		private getTolerance: () => number,
		private viewer: Autodesk.Viewing.Viewer3D,
	) {}

	getSelectedPointsForClassification(classificationId: string) {
		return this.selectedPoints.filter(point => point.classificationId === classificationId)
	}

	createUI() {
		this.tooltipContainer = document.createElement('div')
		// Prepare floating magnitude tooltip (hidden by default on creation)
		const heatmapMagnitudeLabel = document.createElement('div')
		heatmapMagnitudeLabel.setAttribute('id', 'heatmapMagnitudeTooltip')
		heatmapMagnitudeLabel.classList.add('heatmapMagnitudeTooltip')
		this.hoverTooltipElement = heatmapMagnitudeLabel
		this.tooltipContainer!.appendChild(heatmapMagnitudeLabel)
		return this.tooltipContainer
	}

	addSelectedPointTooltip(classificationId: string, pointIndex: number) {
		if (
			this.selectedPoints.some(point => point.classificationId === classificationId && point.pointIndex === pointIndex)
		) {
			return
		}
		const heatmapMagnitudeLabel = document.createElement('div')
		heatmapMagnitudeLabel.classList.add('heatmapMagnitudeTooltip', 'active')
		const magnitude = this.getHeatmapData(classificationId).magnitudes[pointIndex]
		const tooltipText = document.createElement('p')
		tooltipText.innerText = createTooltipText(magnitude, this.getTolerance())
		const removeButton = document.createElement('button')
		removeButton.type = 'button'
		removeButton.innerHTML = `<img src="${closeIcon}" alt="close icon" />`
		removeButton.onclick = () => {
			this.removeSelectedPoint(classificationId, pointIndex)
		}
		heatmapMagnitudeLabel.appendChild(tooltipText)
		heatmapMagnitudeLabel.appendChild(removeButton)
		this.tooltipContainer!.appendChild(heatmapMagnitudeLabel)
		const newPoint = {
			classificationId: classificationId,
			pointIndex: pointIndex,
			label: heatmapMagnitudeLabel,
		}
		this.updateTooltipPosition(newPoint.label, {
			classificationId: newPoint.classificationId,
			pointIndex: newPoint.pointIndex,
		})
		this.selectedPoints.push(newPoint)
		this.onUpdateSelectedPoints(classificationId)
	}

	removeTooltipsForClassification(classificationId: string) {
		const pointsToBeRemoved = this.selectedPoints.filter(point => point.classificationId === classificationId)
		for (const pointToBeRemoved of pointsToBeRemoved) {
			pointToBeRemoved.label.remove()
		}
		this.selectedPoints = this.selectedPoints.filter(point => !pointsToBeRemoved.includes(point))
	}

	updateHoveredMagnitudeLabel() {
		const magnitudeLabel = document.getElementById('heatmapMagnitudeTooltip')
		if (magnitudeLabel) {
			if (this.hoveredPoint) {
				magnitudeLabel.classList.add('active')
				this.updateTooltipPosition(magnitudeLabel, this.hoveredPoint)
				this.updateTooltipMagnitudeLabel(magnitudeLabel, this.hoveredPoint)
			} else {
				magnitudeLabel.classList.remove('active')
			}
		} else {
			console.error('Magnitude label not found in DOM!')
		}
	}

	updateSelectedPointTooltipLabels() {
		for (const point of this.selectedPoints) {
			this.updateTooltipPosition(point.label, point)
		}
	}

	private updateTooltipPosition(label: HTMLElement, data: {classificationId: string; pointIndex: number}) {
		const position = this.computeTooltipScreenPosition(data.classificationId, data.pointIndex)
		updateTooltipLabelPosition(label, position)
	}

	private updateTooltipMagnitudeLabel(label: HTMLElement, data: {classificationId: string; pointIndex: number}) {
		updateMagnitudeLabel(
			label,
			this.getHeatmapData(data.classificationId).magnitudes[data.pointIndex],
			this.getTolerance(),
		)
	}

	private computeTooltipScreenPosition(classificationId: string, index: number): THREE.Vector2 {
		const pointCloud = this.getHeatmapData(classificationId).pointCloud
		const positions = ((pointCloud.geometry as any).attributes.position as THREE.BufferAttribute).array
		const projectedPoint = new THREE.Vector3(
			positions[3 * index],
			positions[3 * index + 1],
			// the tooltip should be displayed a bit above the point, that's why we add 0.04 (4cm) to the z coordinate
			positions[3 * index + 2] + 0.04 * scaleFromIFCtoViewer(this.viewer.model),
		).project(this.viewer.getCamera())
		const canvasDimensions = this.viewer.getDimensions()
		// we first need to bring the point coordinates to a coordinate system
		// where (0,0) represents the top left corner and
		// (1,1) represents the bottom right corner
		const normalizedX = (projectedPoint.x + 1) / 2
		const normalizedY = -(projectedPoint.y - 1) / 2
		// then we multiply with the canvas dimension to get the absolute pixel values
		return new THREE.Vector2(normalizedX * canvasDimensions.width, normalizedY * canvasDimensions.height)
	}

	private removeSelectedPoint(classificationId: string, pointIndex: number) {
		const pointToBeRemoved = this.selectedPoints.find(
			point => point.classificationId === classificationId && point.pointIndex === pointIndex,
		)
		if (pointToBeRemoved) {
			pointToBeRemoved.label.remove()
			this.selectedPoints = this.selectedPoints.filter(p => p !== pointToBeRemoved)
			this.onUpdateSelectedPoints(classificationId)
		}
	}
}

function createTooltipText(magnitude: number, tolerance: number) {
	// Display magnitude value if it's beyond tolerance
	const magnitudeDisplay =
		Math.abs(magnitude) >= tolerance ? Math.round(magnitude).toString() : '+/- ' + Math.round(tolerance)
	return `${magnitudeDisplay} mm`
}

function updateTooltipLabelPosition(label: HTMLElement, point: THREE.Vector2) {
	const newLeft = point.x - 10 + 'px'
	const newTop = point.y - 30 + 'px'
	if (label.style.left !== newLeft || label.style.top !== newTop) {
		label.style.left = newLeft
		label.style.top = newTop
	}
}

function updateMagnitudeLabel(magnitudeLabel: HTMLElement, magnitude: number, tolerance: number) {
	const newTooltipText = createTooltipText(magnitude, tolerance)
	const newHTML = `<p>${newTooltipText}</p>`
	if (magnitudeLabel.innerHTML !== newHTML) {
		magnitudeLabel.innerHTML = newHTML
	}
}
