import {UserProject} from '../../reducers/userProfile'
import {buildAxios} from '../../api/api-factory'
import {config} from '../../config'
import {Id} from '../../entities/value-objects'
import {AnalysisViewEntityPopulated} from './analysis-view.entities'

let currentProjectId: UserProject['_id']
export const setCurrentProjectId = (projectId: UserProject['_id']) => {
	currentProjectId = projectId
}

export const srAnalysisApiV3 = buildAxios(`${config.sr.backendUrl}analysis/v3`)

const projectPath = async (id?: Id, tail?: string) =>
	`projects/${currentProjectId}/analysis-view/${id ? `${encodeURIComponent(id)}/` : ''}${tail ? `${tail}/` : ''}`

export async function fetchActiveAnalysisViews(): Promise<AnalysisViewEntityPopulated[]> {
	return (await srAnalysisApiV3.get(await projectPath('active'))).data
}
