import React from 'react'
import './BCFButton.scss'
import {DocumentPdf} from 'grommet-icons'
import {SRFileDownloadButton, SRIconButton} from 'sr-react-commons'
import {getIssueTitle, IssueEntity} from '../../issue.entities'
import {fetchPdfByIssueById} from '../api/issue.exports.api'

function IssuePDFButton({issue}: {issue: IssueEntity}) {
	const fetchPDFFile = async () => {
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
		return fetchPdfByIssueById(issue._id, timeZone)
	}

	return (
		<SRFileDownloadButton
			buttonClass={SRIconButton}
			icon={<DocumentPdf size={'large'} />}
			fileName={`${getIssueTitle(issue)}.pdf`}
			getFile={fetchPDFFile}
		/>
	)
}

export default React.memo(IssuePDFButton)
