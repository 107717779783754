import {store} from '../../../store/store'
import {history} from '../../../router/history'
import {doSetDisplayAllElements} from '../../../actions/viewer'
import {isolateAndMaintainSelectionVisibility} from '../../Viewer/Viewer-helper'
import {SRExtension} from '../SRExtension'
import {
	approveClassificationByForgeId,
	reclassifyClassificationByForgeId,
	unapproveClassificationByForgeId,
} from '../../../features/Classification/api/classification.api'
import {ClassificationStatus} from '../../../reducers/classification'
import {createGlobalNotificationsAction as createAction} from '../../../features/GlobalNotifications'
import {invalidateClassificationQueryData} from '../../../features/Classification/data-management/useInvalidateClassification'
import {selectorSelectedClassifications} from '../../../selectors/classification.selectors'
import {getCurrentBasePath} from '../../../utilities/routeUtilities'
import {vote} from '../../../features/ElementsOfInterest/Vote/vote.api'
import {ClassificationEntityWithElementRef} from 'features/Classification/classification.entities'
import {invalidateGetNextQuery} from '../../../features/ElementsOfInterest/Suggestion/useGetNextSuggestion'

const Autodesk = window.Autodesk
const VOTE_CONTEXT_MENU_SOURCE = 'viewer-context-menu'
export const CUSTOM_CONTEXT_MENU_EXTENSION = 'Viewing.Extension.CustomContextMenu'

class SRContextMenu extends Autodesk.Viewing.UI.ObjectContextMenu {
	private viewer: Autodesk.Viewing.GuiViewer3D

	constructor(viewer: Autodesk.Viewing.GuiViewer3D) {
		super(viewer)
		this.viewer = viewer
	}

	buildMenu(event: any, status: any) {
		const menu: Autodesk.Viewing.UI.MenuItem[] = []
		if (status.hasSelected) {
			menu.push({divider: true} as any)
			menu.push({
				title: 'Isolate',
				target: () => {
					isolateAndMaintainSelectionVisibility(this.viewer, this.viewer.getSelection()).catch(e =>
						console.error('Error while isolating elements: ', e),
					)
				},
			})
			menu.push({
				title: 'Hide selected',
				target: () => {
					this.viewer.hide(this.viewer.getSelection())
				},
			})
		}
		menu.push({
			title: 'Show all elements',
			target: () => {
				store.dispatch(doSetDisplayAllElements(true))
			},
		})
		menu.push({
			title: 'Isolate filtered elements',
			target: () => {
				store.dispatch(doSetDisplayAllElements(true))
				store.dispatch(doSetDisplayAllElements(false))
			},
		})
		if (status.hasSelected) {
			menu.push({
				title: 'Clear selection',
				target: () => {
					this.viewer.clearSelection()
				},
			})
			menu.push({divider: true} as any)
			menu.push({
				title: 'Focus',
				target: () => {
					this.viewer.fitToView(this.viewer.getSelection())
				},
			})
		}
		return menu
	}
}

export default class CustomContextMenuExtension extends SRExtension {
	constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
		super(viewer, options)
		this.viewer = viewer
		this.onBuildingContextMenuItem = this.onBuildingContextMenuItem.bind(this)
	}

	buildMenuItemForReclassification(title: string, status: ClassificationStatus, dbIds: number[]) {
		return {
			title,
			target: async () => {
				const analysisViewId = store.getState().classification.selectedAnalysisView?._id
				const analysisVersionMap = store.getState().classification.analysisVersionMap
				if (!analysisViewId) return Promise.resolve()
				await Promise.all(
					dbIds.map(forgeObjectId => {
						return reclassifyClassificationByForgeId(analysisViewId, analysisVersionMap, forgeObjectId, status).catch(
							e => {
								console.log('Error trying to save reclassification', e.message)
								store.dispatch(createAction.error(`Reclassification could not be saved: ${e.message}`))
							},
						)
					}),
				)
				await invalidateClassificationQueryData()
			},
		}
	}

	// Bookmarking an element
	buildMenuItemForInterestingElements(
		title: string,
		selectedElementId: string | undefined,
		analysisViewId: string | undefined,
	) {
		return {
			title: title,
			target: async () => {
				await vote({
					elementId: selectedElementId!,
					isInteresting: true,
					analysisViewId: analysisViewId!,
					voteContext: {from: VOTE_CONTEXT_MENU_SOURCE},
				}).catch(e => {
					console.log('Error trying to bookmark an element ', e.message)
					store.dispatch(createAction.error(`Could not bookmark element, an unexpected error occurred: ${e.message}`))
				})
				await invalidateClassificationQueryData()
				await invalidateGetNextQuery(analysisViewId!)
			},
		}
	}

	// For manual classification
	buildMenuItemForCheckUncheck(
		analysisViewId: string,
		dbIds: number[],
		classifications: ClassificationEntityWithElementRef[],
	) {
		return {
			title: 'Approve/Unapprove',
			target: async () => {
				if (!analysisViewId) return
				// TODO parallel requests for approving
				for (const id of dbIds || []) {
					const classification = classifications.find(cl => cl.forgeObjectId === id)
					if (!classification) continue
					if (classification.approved) {
						await unapproveClassificationByForgeId(analysisViewId, id).catch(e => {
							console.error('Error while unapproving classification', e.message, JSON.stringify(classification))
						})
					} else {
						await approveClassificationByForgeId(analysisViewId, id).catch(e => {
							console.error('Error while approving classification', e.message, JSON.stringify(classification))
						})
					}
				}
				await invalidateClassificationQueryData()
			},
		}
	}

	onBuildingContextMenuItem(menu: Autodesk.Viewing.ContextMenuItem[]) {
		const selectedDbIds = selectorSelectedClassifications(store.getState())?.map(cl => cl.forgeObjectId)
		const selectedElementId = selectorSelectedClassifications(store.getState())?.map(cl => cl.element._id)[0]
		const analysisViewId = store.getState().classification.selectedAnalysisView?._id
		const classifications = store.getState().classificationState.classificationsForSelectedElements
		const manualWorkflowActivated = store.getState().viewerState.manualWorkflowActivated
		const eoiEnabled = this.featureEnabled('elementsOfInterest')
		const underConstructionEnabled = this.featureEnabled('enableUnderConstruction')
		const menuEntry = {
			title: 'Change Status',
			target: [] as Autodesk.Viewing.ContextMenuItem[],
		}

		if (selectedDbIds?.length && !document.location.pathname.includes('/watchlist')) {
			menuEntry.target.push(this.buildMenuItemForReclassification('Set to Verified', 'verified', selectedDbIds))
			menuEntry.target.push(this.buildMenuItemForReclassification('Set to Deviated', 'deviated', selectedDbIds))
			if (underConstructionEnabled) {
				menuEntry.target.push(
					this.buildMenuItemForReclassification('Set to Under construction', 'under_construction', selectedDbIds),
				)
			}
			menuEntry.target.push(this.buildMenuItemForReclassification('Set to Missing', 'missing', selectedDbIds))
			menuEntry.target.push(this.buildMenuItemForReclassification('Set to No data', 'no_data', selectedDbIds))

			menu.unshift({divider: true} as any)
			menu.unshift(menuEntry as any)
			if (manualWorkflowActivated && analysisViewId) {
				menu.unshift(this.buildMenuItemForCheckUncheck(analysisViewId, selectedDbIds, classifications))
			}

			eoiEnabled &&
				menu.unshift(this.buildMenuItemForInterestingElements('Bookmark element', selectedElementId, analysisViewId))

			menu.unshift({
				title: 'Create issue',
				target: () => {
					history.push(`${getCurrentBasePath(store.getState())}issues/new`)
				},
			})
		}
	}

	load() {
		this.viewer.registerContextMenuCallback(CUSTOM_CONTEXT_MENU_EXTENSION, this.onBuildingContextMenuItem)
		this.viewer.setContextMenu(new SRContextMenu(this.viewer))
		return true
	}

	unload() {
		// Remove all menu items added from this extension
		this.viewer.unregisterContextMenuCallback(CUSTOM_CONTEXT_MENU_EXTENSION)
		// Restore default context menu
		this.viewer.setContextMenu(new Autodesk.Viewing.UI.ObjectContextMenu(this.viewer))
		return true
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(CUSTOM_CONTEXT_MENU_EXTENSION, CustomContextMenuExtension)
