import React, {useCallback, useMemo} from 'react'
import {ProjectFileEntity} from '../../../../../api/files.api'
import {ManagementDashboardAnalysisRequestStepState} from '../ManagementDashboardAnalysisRequestFormInProgress'
import {ManagementDashboardAnalysisRequestSectionBox} from '../../support/ManagementDashboardAnalysisRequestSectionBox'
import {ManagementDashboardFileSelection} from '../../../core/components/fileSelection/ManagementDashboardFileSelection'
import {Box, Text} from 'grommet'
import {SRHeading, SRTextArea} from 'sr-react-commons'

export type ManagementDashboardAnalysisRequestStep3State = ManagementDashboardAnalysisRequestStepState<'documentDomain'>

export const setValidity = (
	dto: ManagementDashboardAnalysisRequestStep3State['dto'],
): ManagementDashboardAnalysisRequestStep3State => {
	return {
		dto: {...dto, documentDomain: {...dto.documentDomain, files: dto.documentDomain.files || []}},
		valid: !!dto.documentDomain.roiDescription,
	} as ManagementDashboardAnalysisRequestStep3State
}

type DocDomain = ManagementDashboardAnalysisRequestStep3State['dto']['documentDomain']

export const ManagementDashboardAnalysisRequestFormStep3 = ({
	documents,
	state,
	setState,
}: {
	documents: ProjectFileEntity[]
	state: ManagementDashboardAnalysisRequestStep3State
	setState: (state: ManagementDashboardAnalysisRequestStep3State) => void
}) => {
	const docDomain = useMemo(() => state.dto.documentDomain, [state])
	const setDocDomain = useCallback(
		(partial: Partial<DocDomain>) => setState(setValidity({documentDomain: {...docDomain, ...partial}})),
		[docDomain, setState],
	)
	const setFiles = useCallback((files: DocDomain['files']) => setDocDomain({files}), [setDocDomain])
	const setRoiDesc = useCallback((roiDescription: DocDomain['roiDescription']) => setDocDomain({roiDescription}), [
		setDocDomain,
	])
	return (
		<ManagementDashboardAnalysisRequestSectionBox step={3} title={'Support documents'}>
			<Box pad={{left: 'small'}}>
				<Box fill={'horizontal'} gap="xsmall">
					<SRHeading level={5}>3.1 Add area of interest information</SRHeading>
					<SRHeading level={6}>Add area of interest description*</SRHeading>
					<Text style={{fontStyle: 'italic'}}>Example: “Second floor”, “Area B”, etc</Text>
					<Box width={'600px'}>
						<SRTextArea
							placeholder={'Please provide a description of the scanned area. Max 500 characters'}
							value={docDomain.roiDescription}
							rows={3}
							onChange={e => setRoiDesc(e.target.value)}
							maxLength={499}
						/>
					</Box>
				</Box>
				<Box fill={'horizontal'} gap="xsmall">
					<SRHeading level={6}>Add supporting documents</SRHeading>
					<Text>Additional documentation such as images of the scanned area or georeference data.</Text>
					<ManagementDashboardFileSelection
						fileType={'Document'}
						srFiles={documents}
						selectedFileIds={docDomain.files || []}
						setSelectedFileIds={setFiles}
					/>
				</Box>
			</Box>
		</ManagementDashboardAnalysisRequestSectionBox>
	)
}
