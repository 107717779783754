import React from 'react'
import './BCFButton.scss'
import {SRDocumentBcf, SRFileDownloadButton, SRIconButton} from 'sr-react-commons'
import {IssueEntity} from '../../issue.entities'
import {fetchBcfByIssueById} from '../api/issue.exports.api'
import {BCF_EXPORT_FILENAME} from '../index'

function IssueBCFButton({issue}: {issue: IssueEntity}) {
	const fetchBCFFile = async () => {
		return fetchBcfByIssueById(issue._id)
	}

	return (
		<SRFileDownloadButton
			buttonClass={SRIconButton}
			icon={<SRDocumentBcf size={'large'} />}
			fileName={BCF_EXPORT_FILENAME}
			getFile={fetchBCFFile}
		/>
	)
}

export default React.memo(IssueBCFButton)
