import React from 'react'
import {Box, Text, TextInput} from 'grommet'
import {Download, FormAdd, FormSearch, MoreVertical} from 'grommet-icons'
import {ProgressAggregation} from '../../aggregations/progress.aggregations'
import {
	SRCardContainer,
	SRHeading,
	SRIconButton,
	SRNotificationCard,
	SRPrimaryButton,
	SRRightPanelBody,
	SRRightPanelContainer,
} from 'sr-react-commons'
import {AnalysisViewEntityPopulated} from '../../../AnalysisView/analysis-view.entities'
import {SRMultiActionDropButton} from '../../../../components/CommonsCandidate/DropButton/SRMultiActionDropButton'
import {RightPanelHeader} from '../../../../components/CommonsCandidate/Layout/RightPanel/RightPanelHeader'
import {WatchlistListCardPresenter} from './WatchlistListCardPresenter'
import {workPackageHasInvalidIds} from '../../watchlist.utils'
import {LoadingWrapper} from '../../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {WatchListEmptyView} from './WatchListEmptyView'

export function WatchlistListPresenter({
	hasWatchLists,
	analysisViews,
	latestModelId,
	filteredWatchLists,
	nameFilter,
	setNameFilter,
	goToNew,
	goToEdit,
	goToDetail,
	isLoading,
	isError,
	downloadPrimaveraXlsx,
}: {
	hasWatchLists: boolean
	analysisViews: AnalysisViewEntityPopulated[] | null
	latestModelId?: string
	filteredWatchLists: ProgressAggregation[]
	nameFilter: string
	setNameFilter: (a: string) => void
	goToNew: () => void
	goToEdit: (id: string) => void
	goToDetail: (id: string) => void
	isLoading: boolean
	isError: boolean
	downloadPrimaveraXlsx: () => void
}) {
	const top = (
		<Box flex={false} gap={'small'}>
			{analysisViews && analysisViews.some(av => av.status !== 'ready') ? (
				<SRNotificationCard
					pad="xsmall"
					type="warning"
					title="Processing analysis"
					text={
						<Text>
							To avoid inconsistencies, only data from completed analyses is being counted towards Progress Monitoring.
						</Text>
					}
				/>
			) : null}
			<Box direction={'row'} gap={'small'} justify={'between'} align={'center'} flex={false}>
				<SRHeading level={4}>Work packages</SRHeading>
				<Box direction={'row'} gap={'xsmall'} align={'center'}>
					<SRPrimaryButton
						gap={'xsmall'}
						icon={<FormAdd size={'medium'} />}
						onClick={goToNew}
						reverse
						label={'Create work package'}
						size={'large'}
					/>
					<SRMultiActionDropButton
						actions={[{icon: <Download />, label: 'Export to Primavera P6', onClick: downloadPrimaveraXlsx}]}
						buttonClass={SRIconButton}
						icon={<MoreVertical size={'large'} />}
						dropIfOnlyOne={true}
					/>
				</Box>
			</Box>
			<TextInput
				icon={<FormSearch />}
				placeholder={'Search work package'}
				onChange={event => setNameFilter(event.target.value)}
				value={nameFilter}
				size={'medium'}
				disabled={!hasWatchLists}
			/>
		</Box>
	)
	return (
		<SRRightPanelContainer>
			<RightPanelHeader title={'Progress Monitoring'} />
			<SRRightPanelBody {...{top}}>
				<LoadingWrapper
					{...{isLoading, isError}}
					fill
					spinnerText={'Loading work packages'}
					errorMsg="We couldn't display the work packages, please retry."
					isEmpty={!filteredWatchLists.length}
					emptyMsg={<WatchListEmptyView onAction={() => setNameFilter('')} />}
				>
					<SRCardContainer>
						{filteredWatchLists.map((progress: ProgressAggregation) => (
							<WatchlistListCardPresenter
								{...{
									progress,
									goToEdit,
									goToDetail,
									hasInvalidElements: Boolean(
										latestModelId && workPackageHasInvalidIds(progress.watchlist, latestModelId),
									),
									key: progress.watchlist._id,
								}}
							/>
						))}
					</SRCardContainer>
				</LoadingWrapper>
			</SRRightPanelBody>
		</SRRightPanelContainer>
	)
}
