import {Box} from 'grommet'
import React, {useCallback, useMemo, useState} from 'react'
import {ProjectFileEntity} from '../../../../../api/files.api'
import {ManagementDashboardFileSelectionTable} from './ManagementDashboardFileSelectionTable'
import {
	SelectSRFileModal,
	SelectSRFileModalProps,
} from '../../../../../components/CommonsCandidate/SelectSRFileModal/SelectSRFileModal'
import {SRSecondaryButton} from 'sr-react-commons'
import _ from 'lodash'

export type ManagementDashboardFileSelectionProps = {
	fileType: 'Scan' | 'Document' | 'Model'
	srFiles: SelectSRFileModalProps['srFiles']
	selectedFileIds: ProjectFileEntity['_id'][]
	setSelectedFileIds: (files: ProjectFileEntity['_id'][]) => void
}

export const ManagementDashboardFileSelection = ({
	selectedFileIds,
	fileType,
	setSelectedFileIds,
	srFiles,
}: ManagementDashboardFileSelectionProps) => {
	const [openFilesSelectionModal, setOpenFilesSelectionModal] = useState<boolean>(false)
	const onSelectedFileIds = useCallback(
		(ids: ProjectFileEntity['_id'][]) => {
			setSelectedFileIds(ids)
			setOpenFilesSelectionModal(false)
		},
		[setSelectedFileIds, setOpenFilesSelectionModal],
	)
	const selectedFiles = useMemo(() => selectedFileIds.map(id => srFiles.find(scan => scan._id === id)!), [
		selectedFileIds,
		srFiles,
	])
	const onDeleteFile = useCallback(
		(id: ProjectFileEntity['_id']) => onSelectedFileIds(_.without(selectedFileIds, id)),
		[onSelectedFileIds, selectedFileIds],
	)
	return (
		<>
			<SelectSRFileModal
				open={openFilesSelectionModal}
				srFiles={srFiles}
				initialSelectedIds={selectedFileIds}
				title={`Select ${fileType.toLowerCase()} files`}
				onClose={onSelectedFileIds}
				fileNameColumnTitle={`${fileType} files`}
			/>
			<Box gap={'xsmall'} fill={'horizontal'}>
				<Box width={'small'}>
					<SRSecondaryButton
						label={`Select ${fileType.toLowerCase()} files`}
						onClick={() => setOpenFilesSelectionModal(true)}
					/>
				</Box>
				<ManagementDashboardFileSelectionTable
					fileType={fileType}
					selectedFiles={selectedFiles}
					onDeleteFile={onDeleteFile}
				/>
			</Box>
		</>
	)
}
