import {HeaderGroup, Row, useColumnOrder, usePagination, useTable} from 'react-table'
import React, {useCallback, useMemo} from 'react'
import {nonNull} from '../../config'
import {selectorViewerProperties} from '../../selectors/viewer-state.selector'
import {useSelector} from 'react-redux'
import {MagnitudeSign} from '../../reducers/classification'
import {ClassificationEntityWithElementRef} from '../../features/Classification/classification.entities'
import {DataTableColumnOptions, getDataTableColumns} from './DataTableColumns'

export const SORTABLE_COLUMNS = [
	'magnitudeDisplay',
	'status',
	'element.name',
	'magnitudeXYDisplay',
	'magnitudeZDisplay',
] as const

export const CLASSIFICATION_TABLE_COLUMNS = [
	'approved',
	'status',
	'element.name',
	'mappedElementType',
	'mappedFloor',
	'externalId',
	'description',
	'magnitudeXYDisplay',
	'magnitudeZDisplay',
	'magnitudeDisplay',
	'issues',
	'isInteresting',
] as const

export const ELEMENT_DESCRIPTIONS = ['Displaced', 'Built too high', 'Built too low']

export enum MagnitudeDescription {
	'DISPLACED' = 'Displaced',
	'BUILT_TOO_HIGH' = 'Built too high',
	'BUILT_TOO_LOW' = 'Built too low',
}

export function isClassificationTableColumn(possibleColumnName: string): possibleColumnName is TableColumn {
	return (CLASSIFICATION_TABLE_COLUMNS as readonly string[]).includes(possibleColumnName)
}

export type ClassificationSortFields = typeof SORTABLE_COLUMNS[number]
export type TableColumn = typeof CLASSIFICATION_TABLE_COLUMNS[number]
export type TableColumns = Array<TableColumn>
export type ElementDescription = typeof ELEMENT_DESCRIPTIONS[number] | null
export type ClassificationTableRow = {
	status: string
	magnitude: number
	description: ElementDescription
	magnitudeXY: number
	magnitudeZ: number
	magnitudeZSign: MagnitudeSign
	name: string
	mappedFloors: string
	mappedElementType: string
	externalId: string
	elementId: string
	forgeObjectId: number
	approved: boolean
	isInteresting?: ClassificationEntityWithElementRef['isInteresting']
}

export function useClassificationsTable(
	tableData: {
		pageCount: number
		data: ClassificationTableRow[]
		currentPage: number
	},
	options: DataTableColumnOptions,
) {
	const columns = useMemo(() => {
		return getDataTableColumns(options)
	}, [options.displayMagnitudeByAxis, options.enableEOI])

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		allColumns,
		setColumnOrder,
		// Get the state from the instance
		state: {pageIndex, pageSize, sortBy, columnOrder},
	} = useTable<ClassificationTableRow>(
		{
			columns,
			data: tableData.data,
			manualPagination: true,
			autoResetPage: false,
			initialState: {
				columnOrder: [...CLASSIFICATION_TABLE_COLUMNS],
			},
		},
		usePagination,
		useColumnOrder,
	)

	return {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		allColumns,
		pageIndex,
		pageSize,
		sortBy,
		columnOrder,
		setColumnOrder,
	}
}

export function useTableRowClickHandlers(viewer: typeof window.NOP_VIEWER) {
	const {threeSixtyLockedView} = useSelector(selectorViewerProperties)
	const handleRowDoubleClick = useCallback(
		(
			selectedForgeObjectIds: number[],
			event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
			row: Row<ClassificationTableRow>,
		) => {
			if (event.shiftKey) {
				viewer.select([...selectedForgeObjectIds, row.original.forgeObjectId])
				if (!threeSixtyLockedView) {
					viewer.fitToView([...selectedForgeObjectIds, row.original.forgeObjectId])
				}
			} else {
				viewer.select(row.original.forgeObjectId)
				if (!threeSixtyLockedView) {
					viewer.fitToView([row.original.forgeObjectId])
				}
			}
		},
		[threeSixtyLockedView, viewer],
	)

	const handleRowClick = useCallback(
		(
			selectedForgeObjectIds: number[],
			event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
			row: Row<ClassificationTableRow>,
		) => {
			if (event.shiftKey) {
				viewer.select([...selectedForgeObjectIds, row.original.forgeObjectId])
			} else {
				viewer.select(row.original.forgeObjectId)
			}
		},
		[viewer],
	)

	return {
		handleRowClick,
		handleRowDoubleClick,
	}
}

export function isSortableClassificationsColumn(column: HeaderGroup<ClassificationTableRow>) {
	return SORTABLE_COLUMNS.some(sortableColumnKey => sortableColumnKey === column.id)
}

export function validateSortableClassificationsColumnKey(
	column: HeaderGroup<ClassificationTableRow>,
): typeof SORTABLE_COLUMNS[number] {
	return nonNull(
		SORTABLE_COLUMNS.find(sortableColumnKey => sortableColumnKey === column.id),
		`${column.id} is not a sortable column`,
	)
}

export function isNumericColumn(columnId: string) {
	return ['magnitudeDisplay', 'magnitudeXYDisplay', 'magnitudeZDisplay'].includes(columnId)
}
