import React, {useEffect, useRef, useState} from 'react'
import {Box, Text} from 'grommet'
import {SuggestionRow} from './SuggestionRow'
import {SRCard, SRHeading, SRNotificationCard} from 'sr-react-commons'
import {useGetNextSuggestion} from '../useGetNextSuggestion'
import {LoadingWrapper} from '../../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {useClassificationState} from 'reducers/classification.slice'
import {
	SRDismissableTooltip,
	SRDismissableTooltipRef,
} from '../../../../components/CommonsCandidate/SRDismissableTooltip/SRDismissableTooltip'

const localStorageKeys = ['suggestions-part-1', 'suggestions-part-2', 'suggestions-part-3']
const generateTooltipContent = (heading: string, content: string) => {
	return (
		<>
			<SRHeading level={'6'}>{heading}</SRHeading>
			<Text>{content}</Text>
		</>
	)
}

const tooltipContent = [
	generateTooltipContent(
		'New: Recommendations (1/3)',
		'Select elements from the list to see their images, sections, and heatmap.',
	),
	generateTooltipContent(
		'New: Recommendations (2/3)',
		'If you think this is a good suggestion, give it a thumbs up.  Otherwise, give it a thumbs down.',
	),
	generateTooltipContent('New: Recommendations (3/3)', 'Our recommendations will improve with your input. Try it out!'),
]

export function SuggestionsBody() {
	const {selectedAnalysisView} = useClassificationState()
	const analysisViewId = selectedAnalysisView?._id
	const {data: suggestion, isLoading, isError} = useGetNextSuggestion(analysisViewId!)
	const [banner, setBanner] = useState<React.ReactElement | null>(null)
	const tooltipRef = useRef<SRDismissableTooltipRef>()

	useEffect(() => {
		if (banner) {
			setBanner(null)
		}
	}, [selectedAnalysisView])

	const displaySuccessBanner = () => {
		setBanner(
			<Box>
				<SRNotificationCard
					closeable
					onClose={() => setBanner(null)}
					text={
						<Text>
							You've finished reviewing the elements of interest.
							<br />
							New ones will be available in the next analysis.
						</Text>
					}
					type="success"
					title="Elements review complete"
					pad="small"
				></SRNotificationCard>
			</Box>,
		)
	}

	return suggestion?.next ? (
		<LoadingWrapper isLoading={isLoading} isError={isError}>
			<SRCard pad="small">
				<Box direction="row" justify={'between'} pad={{right: 'xxsmall'}}>
					<SRHeading level={5}>Recommendations</SRHeading>
					<Box>{`${suggestion.currentIndex}/${suggestion.total}`}</Box>
				</Box>
				<SRDismissableTooltip
					tooltipContent={tooltipContent}
					dropAlign={{right: 'left', top: 'bottom'}}
					localStorageKey={localStorageKeys}
					ref={tooltipRef}
				>
					<SuggestionRow
						element={suggestion.next}
						suggestion={suggestion}
						analysisViewId={analysisViewId!}
						displaySuccessBanner={displaySuccessBanner}
						tooltipRef={tooltipRef}
					/>
				</SRDismissableTooltip>
			</SRCard>
		</LoadingWrapper>
	) : (
		banner
	)
}
