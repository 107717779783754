import {useGlobalNotifications} from '../../GlobalNotifications'
import {fetchBcfsByIssueIds, fetchPDFByIssueIds, fetchCsvByIssueIds} from './api/issue.exports.api'
import {BCF_EXPORT_FILENAME, IssueExportFormat} from './index'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {UserProject} from '../../../reducers/userProfile'

type FileExtension = 'pdf' | 'csv'

function getMultiExportFileName(project: Pick<UserProject, 'name'>, fileExtension: FileExtension) {
	return `${project.name} Issues.${fileExtension}`
}

export function useIssuesExport() {
	const globalNotifications = useGlobalNotifications()
	const project = useCurrentProject()!
	const onExport = async (issues: string[], exportAs: IssueExportFormat) => {
		try {
			switch (exportAs) {
				case IssueExportFormat.PDF:
					const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
					const pdf = await fetchPDFByIssueIds(issues, timeZone)
					return saveAs(pdf, getMultiExportFileName(project, 'pdf'))
				case IssueExportFormat.BCF:
					const bcf = await fetchBcfsByIssueIds(issues)
					return saveAs(bcf, BCF_EXPORT_FILENAME)
				case IssueExportFormat.CSV:
					const timeZoneForCsv = Intl.DateTimeFormat().resolvedOptions().timeZone
					const csv = await fetchCsvByIssueIds(issues, timeZoneForCsv)
					const file = new Blob([csv], {type: 'text/csv; charset=utf-8;'})
					return saveAs(file, getMultiExportFileName(project, 'csv'))
			}
		} catch (e) {
			globalNotifications.notifyError(`An error occurred while exporting the issues as ${exportAs}.`)
		}
	}
	return {onExport}
}
