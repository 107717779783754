import React, {useEffect, useRef, useState} from 'react'
import {Container, Stage} from '@inlet/react-pixi'
import {Controls} from './Controls'
import {DistanceMeasureLine, LineProps} from './DistanceMeasureLine'
import {ScaleBar} from './ScaleBar'
import {Box, Image as GrommetImage} from 'grommet'
import {CanvasResize} from './CanvasResize'
import {getDistance} from '../ScaleUtils'
import {loadS3Image} from '../../../../utilities/storageUtilities'
import {ProtectedImage} from '../../../ProtectedImage/ProtectedImage'
import {Spinner} from '../../../CommonsCandidate/Loading/Spinner'

interface Props {
	imageSource: string
	axesPath: string
	scale: number
	measurements: LineProps[]
	addMeasurement: (line: LineProps) => void
	removeMeasurement: (id: number) => void
	clearMeasurements: () => void
}

export function DistanceMeasureCanvas({
	imageSource,
	axesPath,
	scale,
	measurements,
	addMeasurement,
	removeMeasurement,
	clearMeasurements,
}: Props) {
	const parentRef = useRef<HTMLDivElement>(null)
	const imageRef = useRef<HTMLImageElement>(new Image())
	const stageRef = useRef<Stage>(null)
	const [imageSize, setImageSize] = useState<number[]>([0, 0])
	const [drawOffset, setDrawOffset] = useState<number[]>([0, 0])
	const [zoom, setZoom] = useState(1)
	const [startPosition, setStartPosition] = useState<[number, number] | null>(null)
	const [endPosition, setEndPosition] = useState<[number, number] | null>(null)
	const [measureMode, setMeasureMode] = useState(false)
	const [grabbing, setGrabbing] = useState<boolean>(false)
	const [lastMousePos, setLastMousePos] = useState<number[] | null>(null)
	const [imageLoaded, setImageLoaded] = useState(false)

	const imagePathRef = useRef<string>(imageSource)

	// This is called when the component is loaded the first time and when the image source changes
	useEffect(() => {
		imageRef.current = new Image()
		imageRef.current.crossOrigin = 'anonymous'
		imagePathRef.current = imageSource
		setImageLoaded(false)

		loadS3Image(imageSource).then(base64Image => {
			if (imagePathRef.current !== imageSource) {
				return
			}
			imageRef.current.src = base64Image
			// if the image was loaded before, it's in internal cache and doesn't fire onload again
			if (imageRef.current.naturalHeight) {
				setImageSize([imageRef.current.naturalWidth, imageRef.current.naturalHeight])
				setImageLoaded(true)
				reset()
			} else {
				imageRef.current.onload = () => {
					if (imageRef.current) {
						setImageSize([imageRef.current.naturalWidth, imageRef.current.naturalHeight])
					}
					setImageLoaded(true)
					reset()
				}
			}
		})
	}, [imageSource, imageRef])

	const onWheel = (event: any) => {
		if (event.deltaY) {
			let factor = 1.1
			if (event.deltaY > 0) {
				factor = 0.9
			}
			const newZoom = zoom * factor
			const dx = (event.nativeEvent.offsetX - drawOffset[0]) * (factor - 1),
				dy = (event.nativeEvent.offsetY - drawOffset[1]) * (factor - 1)

			setZoom(newZoom)
			setDrawOffset([drawOffset[0] - dx, drawOffset[1] - dy])
		}
	}

	const handleGrab = (event: any) => {
		if (grabbing && lastMousePos) {
			const [lastMouseX, lastMouseY] = lastMousePos
			const mouseX = event.clientX - parentRef.current!.clientLeft
			const mouseY = event.clientY - parentRef.current!.clientTop
			setLastMousePos([mouseX, mouseY])
			setDrawOffset([drawOffset[0] - (lastMouseX - mouseX), drawOffset[1] - (lastMouseY - mouseY)])
			setLastMousePos([mouseX, mouseY])
		}
	}

	const onMouseMove = (event: any) => {
		if (event.target) {
			const canvasRect = parentRef.current!.getBoundingClientRect()
			const relativeX = event.clientX - canvasRect.x
			const relativeY = event.clientY - canvasRect.y
			const localX = (relativeX - drawOffset[0]) / zoom
			const localY = (relativeY - drawOffset[1]) / zoom
			if (measureMode && startPosition) {
				const [startX, startY] = startPosition
				let [endX, endY] = [localX, localY]
				if (event.shiftKey) {
					if (Math.abs(endY - startY) < Math.abs(endX - startX)) {
						endY = startY
					} else {
						endX = startX
					}
				}
				setEndPosition([endX, endY])
			} else if (!measureMode) {
				handleGrab(event)
			}
		}
	}

	const onMouseDown = (event: any) => {
		const relativeX = event.clientX
		const relativeY = event.clientY
		if (!measureMode) {
			setStartPosition(null)
			setGrabbing(true)
			setLastMousePos([relativeX, relativeY])
		} else {
			handleDistanceMeasureClicks(event)
		}
	}

	const onMouseUp = (event: any) => {
		setGrabbing(false)
		if (measureMode && startPosition) {
			addLine(startPosition, event)
		}
	}

	const reset = () => {
		if (imageRef.current && parentRef.current) {
			const fitToCanvasZoom = ((parentRef.current.clientHeight || 400) - 20) / imageRef.current.height
			const zoom = Math.min(fitToCanvasZoom, 1)
			setZoom(zoom)
			const offsetLeft = ((parentRef.current.clientWidth || 600) - imageRef.current.width * zoom) / 2
			setDrawOffset([offsetLeft, 10])
		}
	}

	const addLine = (start: [number, number], event: any) => {
		const canvasRect = parentRef.current!.getBoundingClientRect()
		const relativeX = event.clientX - canvasRect.x
		const relativeY = event.clientY - canvasRect.y
		const localX = (relativeX - drawOffset[0]) / zoom
		const localY = (relativeY - drawOffset[1]) / zoom
		let [startX, startY] = start
		let [endX, endY] = [localX, localY]
		if (getDistance([startX, startY], [endX, endY]) * zoom > 5) {
			if (event.shiftKey) {
				if (Math.abs(endY - startY) < Math.abs(endX - startX)) {
					endY = startY
				} else {
					endX = startX
				}
			}
			addMeasurement({
				startX: startX,
				startY: startY,
				endX: endX,
				endY: endY,
			})
			setMeasureMode(false)
		}
		setStartPosition(null)
		setEndPosition(null)
	}

	const handleDistanceMeasureClicks = (event: any) => {
		const canvasRect = parentRef.current!.getBoundingClientRect()
		const relativeX = event.clientX - canvasRect.x
		const relativeY = event.clientY - canvasRect.y
		const localX = (relativeX - drawOffset[0]) / zoom
		const localY = (relativeY - drawOffset[1]) / zoom
		if (!startPosition) {
			setStartPosition([localX, localY])
		} else {
			addLine(startPosition, event)
		}
	}

	const cancelGrabAndMeasuring = () => {
		setStartPosition(null)
		setEndPosition(null)
		setGrabbing(false)
		setLastMousePos(null)
	}

	const coordsToGlobal = (point: [number, number]): [number, number] => {
		return [drawOffset[0] + point[0] * zoom, drawOffset[1] + point[1] * zoom]
	}

	return (
		<Box style={{height: '100%'}}>
			<Box
				ref={parentRef}
				className="canvas-container"
				onWheel={onWheel}
				onMouseDown={onMouseDown}
				onMouseMove={onMouseMove}
				onMouseUp={onMouseUp}
				onMouseOut={cancelGrabAndMeasuring}
				style={{cursor: measureMode ? 'crosshair' : 'grab'}}
			>
				{parentRef.current && (
					<>
						<Stage
							ref={stageRef}
							height={parentRef.current.clientHeight}
							width={parentRef.current.clientWidth}
							style={{position: 'relative'}}
							options={{antialias: true, transparent: true, preserveDrawingBuffer: true}}
							onUnmount={app => {
								if (!app) return
								const context = app.view.getContext('webgl2')
								if (!context) return
								const extension = context.getExtension('WEBGL_lose_context')
								if (!extension) return
								extension.loseContext()
							}}
						>
							<CanvasResize parentRef={parentRef.current} />
							<Container>
								{measurements.map(l => (
									<DistanceMeasureLine
										key={l.id}
										lineProps={l}
										transformToGlobal={coordsToGlobal}
										zoom={zoom}
										removeLine={removeMeasurement}
										scale={scale}
									/>
								))}
							</Container>
							{startPosition && endPosition && (
								<DistanceMeasureLine
									lineProps={{
										id: -1,
										startX: startPosition[0],
										startY: startPosition[1],
										endX: endPosition[0],
										endY: endPosition[1],
									}}
									transformToGlobal={coordsToGlobal}
									zoom={zoom}
									scale={scale}
								/>
							)}
						</Stage>
						{imageLoaded ? (
							<>
								<GrommetImage
									className={'section-drawing'}
									style={{top: drawOffset[1], left: drawOffset[0]}}
									src={imageRef.current!.src}
									alt={'Section drawing'}
									width={imageSize[0] * zoom}
									height={imageSize[1] * zoom}
								/>
								<Box style={{position: 'absolute', right: '0', bottom: '0', mixBlendMode: 'multiply'}}>
									<ProtectedImage
										plain
										crossOrigin={'anonymous'}
										className="section-axes"
										s3key={axesPath}
										alt="axis"
									/>
								</Box>
							</>
						) : (
							<Box className={'section-drawing-loading-spinner'} fill alignSelf={'center'} justify={'center'}>
								<Spinner />
							</Box>
						)}
					</>
				)}
				<ScaleBar scale={scale} zoom={zoom} maxWidth={200} />
			</Box>
			<Box flex={false} pad={'xsmall'} direction={'row'} justify={'end'}>
				<Controls
					reset={reset}
					measureMode={measureMode}
					setMeasureMode={setMeasureMode}
					clearCanvasLines={clearMeasurements}
				/>
			</Box>
		</Box>
	)
}
