import React from 'react'
import {ManagementDashboardAnalysisRequestList} from './list/ManagementDashboardAnalysisRequestList'
import {ManagementDashboardAnalysisRequestWizardPage} from './ManagementDashboardAnalysisRequestWizardPage'
import {Route, Switch} from 'react-router-dom'
import {ManagementDashboardAnalysisRequestViewPage} from './ManagementDashboardAnalysisRequestViewPage'

export const ManagementDashboardAnalysisRequestPage = () => {
	return (
		<Switch>
			<Route path={'/*/*/*/create'}>
				<ManagementDashboardAnalysisRequestWizardPage />
			</Route>
			<Route path={'/*/*/*/view/:analysisRequestId'}>
				<ManagementDashboardAnalysisRequestViewPage />
			</Route>
			<Route path={'/*/*/*'}>
				<ManagementDashboardAnalysisRequestList />
			</Route>
		</Switch>
	)
}
