import {UploadFileDTO, UploadFilePartResponseDTO} from './file-uploader.types'
import {Subscriber} from 'rxjs'
import raw from 'raw.macro'
import {range, sleep} from 'sr-core'
import {filePartUploadAction} from './file-uploader.actions'

const workerString = raw('./file-uploader.worker.js')

export function createWorker(): Worker {
	return new Worker('data:application/javascript,' + encodeURIComponent(workerString))
}

export type FileUploadInWorkersControls = {
	terminated: Promise<void>
	cancel: () => void
	start: () => void
	started: () => boolean
}

export function fileUploadInWorkers(
	observer: Subscriber<number>,
	token: () => Promise<string>,
	dto: UploadFileDTO,
	maxConcurrencyPerFile: number,
): FileUploadInWorkersControls {
	const workers = range(maxConcurrencyPerFile).map(() => createWorker())
	let terminatedResolve: (() => void) | null = null

	const terminated = new Promise<void>(resolve => {
		terminatedResolve = resolve
	})
	const terminate = () => {
		workers.forEach(worker => {
			worker.terminate()
			worker.onmessage = null
		})
		terminatedResolve!()
	}
	let progress = 0
	let _started = false
	const start = () => {
		workers.forEach(async worker =>
			worker.postMessage(filePartUploadAction({...dto, previousCompletedPart: null, token: await token()})),
		)
		_started = true
		progress = 1 // set progress to 1 instead of 0 so it's visible that it started.
		observer.next(progress)
	}
	const started = () => _started
	workers.forEach(worker => {
		worker.onmessage = async (e: any) => {
			switch (e.data.type) {
				case 'FILE_UPLOAD_PART_STATUS':
					{
						const payload: UploadFilePartResponseDTO = e.data.payload
						progress = payload.progress > progress ? payload.progress : progress
						console.log('file part uploaded ', progress)
						observer.next(progress)
						if (payload.status === 'uploaded') {
							console.log(`File ${dto.file.name} uploaded`)
							observer.complete()
							terminate()
						} else {
							worker.postMessage(
								filePartUploadAction({...dto, previousCompletedPart: payload.part, token: await token()}),
							)
						}
					}
					break
				case 'FILE_ERROR': {
					console.error('Error in file uploader worker, retrying in 10s: ' + JSON.stringify(e.data.payload))
					await sleep(10 * 1000)
					worker.postMessage(filePartUploadAction({...dto, previousCompletedPart: null, token: await token()}))
					break
				}
				case 'NOT_FOUND':
					console.error('Irrecoverable error, file not found ' + JSON.stringify(dto))
					observer.error(new Error('Not found'))
					observer.complete()
					terminate()
					break
				case 'UNAUTHORIZED':
					console.error('Irrecoverable error, user unauthorized ' + JSON.stringify(dto))
					observer.error(new Error('Unauthorized'))
					observer.complete()
					terminate()
					break
				case 'TOKEN_EXPIRED': {
					console.log('Token expired, retrying ' + JSON.stringify(dto))
					await sleep(10 * 1000)
					worker.postMessage(filePartUploadAction({...dto, previousCompletedPart: null, token: await token()}))
					break
				}
				case 'LOG':
					console.log('Message from worker:', e.data.payload)
					break
				default:
					console.error('Unknown type:', JSON.stringify(e.data))
			}
		}
	})
	return {
		cancel: () => {
			observer.complete()
			terminate()
		},
		terminated,
		start,
		started,
	}
}
