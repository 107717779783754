import {eoiApiV3} from '../eoi.api'
import {UserProject} from '../../../reducers/userProfile'
import {NextSuggestionResponse} from 'entities/suggestion.entities'

let currentProjectId: UserProject['_id']
export const setCurrentProjectId = (projectId: UserProject['_id']) => {
	currentProjectId = projectId
}

export const getNextSuggestedElement = async (analysisViewId: string): Promise<NextSuggestionResponse> => {
	return eoiApiV3
		.get(`/projects/${currentProjectId}/suggestions/${analysisViewId}/next`)
		.then(response => response.data)
}
