import {FunctionComponent, useEffect, useState} from 'react'
import {useApp} from '@inlet/react-pixi'

type CanvasResizeProps = {
	parentRef: HTMLDivElement
}

export const CanvasResize: FunctionComponent<CanvasResizeProps> = ({parentRef}: CanvasResizeProps) => {
	const app = useApp()
	const [parentWidth, setParentWidth] = useState(parentRef.clientWidth)
	const [parentHeight, setParentHeight] = useState(parentRef.clientHeight)

	useEffect(() => {
		const setRefDimensions = () => {
			setParentWidth(parentRef.clientWidth)
			setParentHeight(parentRef.clientHeight)
		}

		app.ticker.add(setRefDimensions)

		return () => {
			app.ticker.remove(setRefDimensions)
		}
	}, [parentRef, app])

	useEffect(() => {
		app.renderer.resize(parentWidth, parentHeight)
	}, [app, parentWidth, parentHeight])

	return null
}
