export type MappedElementAttributes = {
	mappedFloors: string
	mappedElementType: string
}

export type ElementEntity = {
	_id: string
	model: string
	forgeObjectId: number
	type: string
	layers: string[]
	name: string | null
	externalId: string
	elementTypeLabels: string[]
	watchlists: string[]
	tenantId: string
	createdDate: string
} & MappedElementAttributes

export type ClassificationElementRef = {
	_id: string
	forgeObjectId: number
	externalId: string
	floors: string[]
	layers: string[]
	type: string
	name: string | null
	elementTypeLabels: string[]
} & MappedElementAttributes

export function getMappedElementType(originalElementType: string, elementTypeMapping: Record<string, string>): string {
	return (elementTypeMapping && elementTypeMapping[originalElementType]) || originalElementType
}

export function getMappedElementTypeLabels(labels: string[], elementTypeMapping: Record<string, string>): string[] {
	return [getMappedElementType(labels[0], elementTypeMapping)].concat(labels.slice(1))
}

export function getMappedFloor(floor: string, floorMapping: Record<string, string>) {
	return (floorMapping && floorMapping[floor]) || floor
}

export function mapFloors(layers: string[], floorMapping: Record<string, string>): string[] {
	const floors = layers.map(layer => getMappedFloor(layer, floorMapping)).filter(floor => !!floor)
	return floors.length > 0 ? floors : ['Unknown']
}

export function getMappedElementAttributes(
	element: Pick<ElementEntity, 'layers' | 'elementTypeLabels' | 'type' | 'name'>,
	floorMapping: Record<string, string>,
	elementTypeMapping: Record<string, string>,
): MappedElementAttributes & {name: string; elementTypeLabels: ElementEntity['elementTypeLabels']} {
	return {
		mappedFloors: mapFloors(element.layers, floorMapping).join(', '),
		mappedElementType: getMappedElementType(element.type, elementTypeMapping),
		name: element.name || 'Unknown',
		elementTypeLabels: getMappedElementTypeLabels(element.elementTypeLabels, elementTypeMapping),
	}
}
