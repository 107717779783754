import React from 'react'
import {RotateLeft} from 'grommet-icons'
import {Image} from 'grommet'

import {SREmptyView} from 'sr-react-commons'

type EmptyViewProps = {
	onAction: () => void
}

export const QualityEmptyView = ({onAction}: EmptyViewProps) => {
	return (
		<SREmptyView
			onAction={onAction}
			actionText="Reset filters"
			asset={<Image src="/images/empty-states/empty-quality.svg" />}
			title="There are no classifications to display"
			buttonIcon={<RotateLeft size="small" />}
			message={`There are no elements to display for the current active filters.\nPlease reset or adjust your filters to find the information you need.`}
			buttonTextSize="large"
			buttonReverse={true}
		/>
	)
}
