import React, {useState} from 'react'
import {Auth} from 'aws-amplify'
import {Anchor, Box} from 'grommet'
import {AuthenticatorForm} from './AuthenticatorForm'
import {Spinner} from '../Loading/Spinner'
import {SRBrandButton, SRFormErrorMessage, SRHeading, SRPasswordInput, SRTextInput} from 'sr-react-commons'
import {config} from '../../../config'

export function SRSignIn(props: {authState?: string; onStateChange?: (newState: string, data?: any) => void}) {
	if (!props.authState || !props.onStateChange) throw new Error('Must be used inside an authenticator component')
	const {authState, onStateChange} = props
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(false)

	const handleErrorMessage = (error: Error & {code: string}) => {
		error.code === 'UserNotFoundException'
			? setErrorMessage('Incorrect username or password')
			: setErrorMessage(error.message)
	}

	const signInClick = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoading(true)
		const formElements = event.currentTarget.elements as typeof event.currentTarget.elements & {
			emailInput: HTMLInputElement
			passwordInput: HTMLInputElement
		}
		const email = formElements.emailInput.value.trim()
		const password = formElements.passwordInput.value.trim()

		await Auth.signIn(email, password)
			.then(user => {
				if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
					onStateChange('requireNewPassword', user)
				} else {
					onStateChange('signedin')
				}
				setErrorMessage('')
			})
			.catch(handleErrorMessage)
		setLoading(false)
	}
	return !['signIn', 'signedOut', 'signedUp'].includes(authState) ? null : (
		<AuthenticatorForm onSubmit={signInClick}>
			<SRHeading level="5">Sign In</SRHeading>
			{errorMessage ? SRFormErrorMessage(errorMessage) : null}
			<Box alignSelf={'stretch'}>
				<label htmlFor="emailInput">Email</label>
				<SRTextInput id={'emailInput'} size="medium" type="email" placeholder="Enter your email" required />
			</Box>
			<Box alignSelf={'stretch'}>
				<label htmlFor="passwordInput">Password</label>
				<SRPasswordInput id="passwordInput" size="medium" placeholder={'Enter your password'} />
			</Box>
			<Anchor color={'accent-3'} onClick={() => onStateChange('forgotPassword')}>
				Forgot your password?
			</Anchor>
			<SRBrandButton
				disabled={loading}
				icon={loading ? <Spinner size={'xsmall'} color={'darker'} hideIfGlobalLoader={false} /> : undefined}
				label={loading ? '' : 'Sign In'}
				size={'medium'}
				alignSelf="stretch"
				type="submit"
			/>
			<SRHeading alignSelf="center" level="6">
				New to {config.sr.companyName}?
				<Anchor color={'accent-3'} href="mailto:support@naska.ai" target="_blank">
					&nbsp;Contact our sales team.
				</Anchor>
			</SRHeading>
		</AuthenticatorForm>
	)
}
