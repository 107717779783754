import React, {useCallback} from 'react'
import {ManagementDashboardBaseFilePage} from '../file/ManagementDashboardBaseFilePage'
import {useManagementDashboardState} from '../core/management-dashboad.slice'
import {ModelFileUploadModal} from '../../FileUpload/components/ModelFileUploadModal'
import {FileUploaderWorkerProvider} from '../../FileUpload/components/FileUploadProviders'
import {useFileUploaderWorker} from '../../FileUpload/hooks/useFileUploaderWorker'
import {useCurrentForgeIntegration} from '../../ForgeIntegration/hooks/UseCurrentForgeIntegration'
import {ForgeIntegrationUploadFileModal} from '../../ForgeIntegration/components/ForgeIntegrationUploadFileModal'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'

export const ManagementDashboardModelsPage = () => {
	const {project} = useManagementDashboardState()
	const worker = useFileUploaderWorker()
	const [fromDeviceModalOpen, setFromDeviceModalOpen] = React.useState(false)
	const onFromDeviceModalClose = () => {
		setFromDeviceModalOpen(false)
	}
	const [fromForgeModalOpen, setFromForgeModalOpen] = React.useState(false)
	const onFromForgeModalClose = useCallback(() => {
		setFromForgeModalOpen(false)
	}, [])
	const forgeFeatureEnabled = useFeatureEnabled('forgeIntegration')
	const {data: integration} = useCurrentForgeIntegration(project || undefined)
	return (
		<FileUploaderWorkerProvider worker={worker}>
			<ManagementDashboardBaseFilePage
				fileVisibility="model"
				onOpenUploadModal={() => setFromDeviceModalOpen(true)}
				uploadModal={
					<ModelFileUploadModal
						projectId={project!._id}
						projectType={project!.modelType}
						open={fromDeviceModalOpen}
						onClose={onFromDeviceModalClose}
					/>
				}
				fromForgeOps={
					!forgeFeatureEnabled
						? undefined
						: {
								integration: integration || null,
								onOpenUploadModal: () => setFromForgeModalOpen(true),
								uploadModal: (
									<ForgeIntegrationUploadFileModal
										integration={integration}
										open={fromForgeModalOpen}
										onClose={onFromForgeModalClose}
										mode={'model'}
										fileType="model"
									/>
								),
						  }
				}
			/>
		</FileUploaderWorkerProvider>
	)
}
