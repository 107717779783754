import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useMemo} from 'react'
import {UserProject} from '../../../reducers/userProfile'
import {RootState} from '../../../reducers'
import {isValidPage, ManagementDashboardPage, ManagementDashboardSubPage} from './management-dashboard-page.utils'
import {useParams} from 'react-router-dom'

export type ManagementDashboardSliceState = {
	project: UserProject | null
	page: ManagementDashboardPage | null
	subPage: ManagementDashboardSubPage
}

const buildInitialState = (): ManagementDashboardSliceState => ({
	project: null,
	page: null,
	subPage: undefined,
})

export const managementDashboardSlice = createSlice({
	name: 'managementDashboard',
	initialState: buildInitialState(),
	reducers: {
		setProjectAndPage: (
			state,
			action: PayloadAction<Pick<ManagementDashboardSliceState, 'project' | 'page' | 'subPage'>>,
		) => ({
			...state,
			...action.payload,
		}),
		resetManagementDashboardState: () => buildInitialState(),
	},
})

export function useManagementDashboardState() {
	const dispatch = useDispatch()
	const state = useSelector((state: RootState) => state.managementDashboard)
	const actions = useMemo(
		() => ({
			setProjectAndPage: (
				project: ManagementDashboardSliceState['project'],
				page: ManagementDashboardSliceState['page'],
				subPage: ManagementDashboardSliceState['subPage'],
			) => {
				dispatch(managementDashboardSlice.actions.setProjectAndPage({project, page, subPage}))
			},
			resetManagementDashboardState: () => {
				dispatch(managementDashboardSlice.actions.resetManagementDashboardState())
			},
		}),
		[dispatch],
	)
	return {...state, ...actions}
}

export function useManagementDashboardProject(): {isProjectLoading: boolean; project: UserProject | null} {
	const {resetManagementDashboardState, setProjectAndPage, project} = useManagementDashboardState()
	const {projectId, dashboardPage, dashboardSubPage} = useParams<{
		projectId?: string
		dashboardPage?: string
		dashboardSubPage?: string
	}>()
	const userProjects = useSelector((state: RootState) => state.userProfileState.userProjects)
	const page: ManagementDashboardPage | null =
		(isValidPage(dashboardPage) && (dashboardPage as ManagementDashboardPage)) || null
	useEffect(() => {
		setProjectAndPage(
			userProjects?.find(p => p._id === projectId) || null,
			page,
			dashboardSubPage as ManagementDashboardSubPage,
		)
		return () => resetManagementDashboardState()
	}, [projectId, userProjects, page, setProjectAndPage, resetManagementDashboardState, dashboardSubPage])
	return {project, isProjectLoading: !userProjects}
}
