import {PixiComponent} from '@inlet/react-pixi'
import {Graphics} from 'pixi.js'
import {SR_SECTION_ANNOTATION} from 'sr-react-commons'

const color = parseInt(SR_SECTION_ANNOTATION.slice(1), 16)

interface LineProps {
	startX: number
	startY: number
	endX: number
	endY: number
}

export default PixiComponent('Line', {
	create: () => {
		return new Graphics()
	},
	applyProps: (instance: Graphics, oldProps, {startX, startY, endX, endY}: LineProps) => {
		// starting circle
		instance.clear()
		instance.beginFill(color)
		instance.drawCircle(startX, startY, 2)
		instance.endFill()

		// line
		instance.lineStyle(1, color)
		instance.moveTo(startX, startY)
		instance.lineTo(endX, endY)
		instance.endFill()

		// ending circle
		instance.beginFill(color)
		instance.drawCircle(endX, endY, 2)
		instance.endFill()
	},
})
