import {Box, Layer, Text} from 'grommet'
import {SRHeading} from 'sr-react-commons'
import {ProjectFileUploader} from './ProjectFileUploader'
import React from 'react'
import {SRUl} from '../../../components/List/SRUl'
import {SupportLink} from './SupportLink'

export function ScanFileUploadHelpText() {
	return (
		<Box gap="small">
			<Box>
				<SRHeading level="5">Upload scan files</SRHeading>
				<Text>
					Please upload the scan files to be compared to the BIM. This step could take a while, depending on the file
					size.
				</Text>
			</Box>
			<Box gap={'xsmall'}>
				<SRHeading level="6">Scan file requirements:</SRHeading>
				<SRUl>
					<li>Accepted formats are .e57, .b2g and .zip files.</li>
					<li>
						.e57 file should:
						<ul>
							<li>Be registered and georeferenced.</li>
							<li>Should not be cropped.</li>
							<li>Contain good quality panoramic images.</li>
							<li>Have a cloud resolution of at least 2cm.</li>
						</ul>
					</li>
				</SRUl>
				<SupportLink title={'If you have any doubts about the scan file requirements please'} />
			</Box>
		</Box>
	)
}

export function ScanFileUploadModal(props: {projectId: string; open: boolean; onClose: () => void}) {
	return props.open ? (
		// TODO refoctor for better code sharing
		<Layer modal animation="none">
			<Box pad="medium" width="416px" gap={'medium'}>
				<SRHeading level="4">Upload files</SRHeading>
				<ProjectFileUploader
					projectId={props.projectId}
					visibility={'scan'}
					acceptedFileTypes={['.e57', '.b2g', '.zip']}
					onCancel={props.onClose}
					onFinish={props.onClose}
					dropzoneText={
						<>
							Drop scan files to upload or <br />
						</>
					}
					helpText={<ScanFileUploadHelpText />}
				/>
			</Box>
		</Layer>
	) : null
}
