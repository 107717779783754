import {useSelector} from 'react-redux'
import {selectorViewerInstance} from '../selectors/viewer-state.selector'
import {useViewerReady} from './useViewerReady'
import {useEffect} from 'react'
import {getVerticalToolBar} from '../components/Viewer/Viewer-helper'
import {VERTICAL_CONTROL_GROUP} from '../components/Viewer.Components/Viewing.Extension.VerticalToolbar/Viewing.Extension.VerticalToolbar'

export function useDisableVerticalToolbar() {
	const viewer = useSelector(selectorViewerInstance)
	const viewerReady = useViewerReady()
	//Toggles vertical toolbar button active status
	useEffect(() => {
		if (viewer && viewerReady) {
			toggleVerticalToolbarButtons(false, viewer)
		}
		return () => {
			if (viewer && viewerReady) {
				toggleVerticalToolbarButtons(true, viewer)
			}
		}
	}, [viewer, viewerReady])
}

function toggleVerticalToolbarButtons(active: boolean, viewer: Autodesk.Viewing.Viewer3D) {
	const subToolbar = getVerticalToolBar(viewer).getControl(
		VERTICAL_CONTROL_GROUP,
	) as Autodesk.Viewing.UI.ControlGroup | null
	if (!subToolbar) return
	const numberOfButtons = subToolbar.getNumberOfControls()

	for (let i = 0; i < numberOfButtons; i++) {
		if (subToolbar.getControlId(i) === 'ScreenshotsToolbarButton') return
		if (subToolbar.getControlId(i) === 'runHeatmapCode') {
			const heatMapControl = subToolbar.getControl(subToolbar.getControlId(i))
			active ? heatMapControl.removeClass('disable-click') : disableButtons(heatMapControl)
			return
		}
		const currentControl = subToolbar.getControl(subToolbar.getControlId(i))
		active ? enableButtons(currentControl) : disableButtons(currentControl)
	}
}

function enableButtons(currentControl: Autodesk.Viewing.UI.Control) {
	currentControl.removeClass('disabled')
	currentControl.removeClass('disable-click')
}

function disableButtons(currentControl: Autodesk.Viewing.UI.Control) {
	currentControl.addClass('disabled')
	currentControl.addClass('disable-click')
}
