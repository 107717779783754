import React, {forwardRef, ReactNode, Ref, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Anchor, Box, Drop, DropProps} from 'grommet'
import {FormNextLink} from 'grommet-icons'
import styled from 'styled-components'
import {SR_COLOR_BRAND, SR_COLOR_TEXT_LIGHT, SR_COLOR_WHITE, SRButton} from 'sr-react-commons'

const DropWithArrow = styled(Drop)`
	overflow: visible;
	background: transparent;
	border: none;
	box-shadow: none;
	width: 15%;

	${(props: DropProps) =>
		!!props.align?.bottom && !!props.align?.right
			? 'margin-bottom: 10px;'
			: !!props.align?.top && !!props.align?.right
			? 'margin-top: -40px;'
			: 'margin-top: 10px;'}
	&::after {
		content: '';
		position: absolute;
		${(props: DropProps) =>
			!!props.align?.top && !!props.align?.left
				? 'bottom: 100%;left: 8px;'
				: !!props.align?.top && !!props.align?.right
				? 'bottom: 70%; right: 21px;'
				: !!props.align?.bottom && !!props.align?.right
				? 'right: 8px;'
				: 'top: 100%; margin: 0 auto;'}
		border-width: 8px;
		border-style: solid;
		border-color: ${(props: DropProps) =>
			!!props.align?.bottom && !!props.align?.right
				? 'black transparent transparent transparent;'
				: !!props.align?.top && !!props.align?.right
				? 'transparent transparent transparent black;'
				: 'transparent transparent black transparent;'}
		z-index: 100000;
	}
`

export interface SRDismissableTooltipRef {
	dismissTooltip: () => void
}

export const SRDismissableTooltip = forwardRef(
	(
		{
			children,
			tooltipContent,
			localStorageKey: persistenceKey,
			dropAlign = {top: 'bottom', left: 'left'},
		}: {
			children: ReactNode
			tooltipContent: ReactNode[]
			localStorageKey: string[]
			dropAlign?: DropProps['align']
		},
		ref: Ref<SRDismissableTooltipRef | undefined>,
	) => {
		const [displayTooltip, setDisplayTooltip] = useState(() => false)
		const [persistenceKeyIndex, setPersistenceKeyIndex] = useState(0)
		const persistenceSize = persistenceKey.length - 1

		useEffect(() => {
			const timeout = setTimeout(() => {
				if (sessionStorage.getItem(persistenceKey[persistenceKeyIndex] + '-seen')) {
					setPersistenceKeyIndex(persistenceKeyIndex + 1)
					sessionStorage.setItem(persistenceKey[persistenceKeyIndex] + '-seen', '1')
					setDisplayTooltip(false)
				} else if (persistenceKeyIndex <= persistenceSize) {
					setDisplayTooltip(true)
				}
			}, 500)

			return () => {
				clearTimeout(timeout)
			}
		}, [persistenceKeyIndex, persistenceKey])

		const onDismissTooltip = () => {
			if (persistenceKeyIndex <= persistenceSize) {
				sessionStorage.setItem(persistenceKey[persistenceKeyIndex] + '-seen', '1')
				setPersistenceKeyIndex(persistenceKeyIndex + 1)
			}
			setDisplayTooltip(false)
		}

		const onDismissAllKeys = () => {
			persistenceKey.forEach(value => {
				if (!sessionStorage.getItem(value)) {
					sessionStorage.setItem(value + '-seen', '1')
					setDisplayTooltip(false)
				}
			})
		}

		useImperativeHandle(ref, () => ({
			dismissTooltip: onDismissTooltip,
		}))

		const dropRef = useRef<HTMLDivElement | null>(null)
		return (
			<>
				<Box ref={dropRef}>{children}</Box>
				{displayTooltip && (
					<DropWithArrow align={dropAlign} target={dropRef.current!}>
						<Box background={SR_COLOR_TEXT_LIGHT} round={'xsmall'} elevation={'small'} width={'91%'} pad={'small'}>
							<Box gap={'xsmall'}>
								{tooltipContent[persistenceKeyIndex]}
								<Box direction="row" justify={'between'}>
									{persistenceKeyIndex < persistenceSize ? (
										<Anchor
											alignSelf={'center'}
											label="Close and skip all tips"
											color={SR_COLOR_BRAND}
											onClick={onDismissAllKeys}
											size={'small'}
										/>
									) : (
										<Box />
									)}
									{persistenceKeyIndex < persistenceSize ? (
										<SRButton
											icon={<FormNextLink />}
											size={'small'}
											gap={'none'}
											reverse
											label={'See next tip'}
											onClick={onDismissTooltip}
											textColor={SR_COLOR_WHITE}
											hoverBgColor={'rgba(211, 213, 216, 0.5)'}
											borderColor={SR_COLOR_WHITE}
											hasBorder={true}
										/>
									) : (
										<SRButton
											gap={'none'}
											size={'small'}
											label={'Ok, I understand'}
											onClick={onDismissTooltip}
											textColor={SR_COLOR_WHITE}
											hoverBgColor={'rgba(211, 213, 216, 0.5)'}
											borderColor={SR_COLOR_WHITE}
											hasBorder={true}
										/>
									)}
								</Box>
							</Box>
						</Box>
					</DropWithArrow>
				)}
			</>
		)
	},
)
