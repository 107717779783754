import React from 'react'
import {Box} from 'grommet'
import {SRBrandSelectableIconButton, SREraser, SRIconButton, SRResetZoom, SRRuler} from 'sr-react-commons'

interface Props {
	reset: () => void
	setMeasureMode: (value: ((prevState: boolean) => boolean) | boolean) => void
	measureMode: boolean
	clearCanvasLines: () => void
}

export function Controls({reset, setMeasureMode, measureMode, clearCanvasLines}: Props) {
	return (
		<Box
			alignSelf={'start'}
			gap="xsmall"
			direction={'row'}
			justify={'between'}
			wrap={true}
			flex={false}
			data-hide-on-screenshot
		>
			<Box margin={{bottom: 'xsmall'}} alignContent={'start'} direction={'row'} gap={'xsmall'}>
				<SRIconButton size="medium" icon={<SRResetZoom size="medium" />} onClick={reset} />
				<SRBrandSelectableIconButton
					size={'medium'}
					isSelected={measureMode}
					icon={<SRRuler size="medium" />}
					onClick={() => setMeasureMode(!measureMode)}
				/>
				<SRIconButton size="medium" icon={<SREraser size="medium" />} onClick={clearCanvasLines} />
			</Box>
		</Box>
	)
}
