import {Box, Layer} from 'grommet'
import {SRHeading, SRIconButton, SRPrimaryButton} from 'sr-react-commons'
import {CircleInformation, Close} from 'grommet-icons'
import React from 'react'
import {SRUl} from '../../../../../components/List/SRUl'

export const ManagementDashboardAnalysisRequestFormBimModelInformationModal = ({onClose}: {onClose: () => void}) => {
	return (
		<Layer position="top" margin={{top: '100px'}}>
			<Box gap="small" pad="medium" width="520px">
				<Box direction="row" gap="medium" justify="between">
					<SRHeading level={4} alignSelf="center">
						BIM Model
					</SRHeading>
					<SRIconButton icon={<Close />} onClick={onClose} alignSelf="center" />
				</Box>
				<Box pad="xsmall" direction="row" gap="medium">
					<CircleInformation color="accent-3" size="46px" />
					<Box>
						<SRHeading level={5}>Information about BIM Model</SRHeading>
						<SRUl>
							<li>To create a new analysis, we use the last BIM version available as a default.</li>
							<li>
								To request a BIM version update, please upload the new BIM files in the models section and contact our
								support team.
							</li>
							<li>If you already requested a BIM version update, it will show up here as soon as it is ready.</li>
						</SRUl>
					</Box>
				</Box>
				<Box align="center">
					<SRPrimaryButton size="large" label="Acknowledge" onClick={onClose} />
				</Box>
			</Box>
		</Layer>
	)
}
