import React from 'react'
// TODO: read from environment
import {ForgeIntegrationEntity, ForgeProjectType} from './forge-integration.entities'
import {ForgeIntegrationAutodeskIcon} from './icons/AutodeskIcon/ForgeIntegrationAutodeskIcon'
import {ForgeIntegrationBIM360Icon} from './icons/Bim360Icon/ForgeIntegrationBIM360Icon'
import {ForgeIntegrationACCIcon} from './icons/ACCIcon/ForgeIntegrationACCIcon'
import {IconProps} from 'grommet-icons'
import {ForgeFileSource} from '../../api/files.api'

export const isBIM360 = (forgeProjectType: ForgeProjectType) => forgeProjectType === 'BIM360'

export const isACC = (forgeProjectType: ForgeProjectType) => forgeProjectType === 'ACC'

export const getIntegrationName = (integration?: ForgeIntegrationEntity | null) =>
	!integration ? 'Autodesk' : isBIM360(integration.forgeProject.type) ? 'BIM 360' : 'ACC'

export const getIntegrationIcon = (integration?: ForgeIntegrationEntity | null, size: IconProps['size'] = 'medium') =>
	!integration ? (
		<ForgeIntegrationAutodeskIcon size={size} />
	) : isBIM360(integration.forgeProject.type) ? (
		<ForgeIntegrationBIM360Icon size={size} />
	) : (
		<ForgeIntegrationACCIcon size={size} />
	)

export const getIconByForgeFileSource = (forgeFileSource: ForgeFileSource, size: IconProps['size'] = 'medium') => {
	return isBIM360(forgeFileSource.meta.forgeProjectType) ? (
		<ForgeIntegrationBIM360Icon size={size} />
	) : (
		<ForgeIntegrationACCIcon size={size} />
	)
}
