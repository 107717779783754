import React from 'react'
import {Box, Text} from 'grommet'
import {Close} from 'grommet-icons'
import {SRIconButton, SRNotificationCard} from 'sr-react-commons'

export const AnalysisReadyProgress = ({close}: {close: () => void}) => {
	const notificationText = (text: string) => {
		return (
			<Box align="center" direction="row" gap="small" justify="between">
				<Text size={'large'} weight={'bold'}>
					{text}
				</Text>
				<Box pad={{horizontal: 'xxsmall'}}>
					<SRIconButton icon={<Close size={'small'} />} onClick={close} />
				</Box>
			</Box>
		)
	}

	return (
		<Box>
			<SRNotificationCard
				type={'success'}
				text={notificationText('Analysis ready')}
				pad={{left: 'small', vertical: 'xsmall'}}
				fill
			/>
		</Box>
	)
}
