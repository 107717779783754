import React, {ReactNode} from 'react'
import {SRHeading} from 'sr-react-commons'
import {Box} from 'grommet'

export const ManagementDashboardAnalysisRequestSectionBox = ({
	children,
	step,
	title,
}: {
	children: ReactNode
	step?: number
	title: string
}) => (
	<Box gap={'xsmall'}>
		<SRHeading level={4}>
			{step ? `Step ${step}: ` : null} {title}
		</SRHeading>
		<Box gap="small">{children}</Box>
	</Box>
)
