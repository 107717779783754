import {useQuery} from 'react-query'
import {AnalysisRequestEntity} from './analysis-request.entities'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {fetchAnalysisRequest} from './analysis-request.api'

export const useAnalysisRequest = (id: AnalysisRequestEntity['_id']) => {
	const currentProject = useCurrentProject()
	return useQuery<AnalysisRequestEntity | undefined>(
		['analysis-request', 'analysis-request-get', currentProject?._id, id],
		() => currentProject && fetchAnalysisRequest(currentProject?._id, id),
		{enabled: !!currentProject},
	)
}
