import {useSelector} from 'react-redux'
import {selectorFilterPresets} from '../selectors/filter-preset.selectors'
import React from 'react'
import {Box, Text} from 'grommet'
import {Group} from 'grommet-icons'
import {SRIconButton, SRSaveIcon, SRSimpleSelect, SRSimpleSelectOptionObject} from 'sr-react-commons'
import {FilterPresetEntity} from '../entities/filter-preset.entities'
import {FilterPresetOps} from './FilterPresets'
import {FilterPresetDescription} from './description/FilterPresetDescription'

export function FilterPresetsHeader({ops}: {ops: FilterPresetOps}) {
	const toOption = (preset: FilterPresetEntity): SRSimpleSelectOptionObject => ({
		value: preset._id,
		label: (
			<Box direction={'row'} justify={'between'} fill>
				<Text>{preset.name}</Text>
				{preset.scope === 'project' && (
					<Box alignSelf={'center'}>
						<Group color={'dark-1'} size={'18px'} />
					</Box>
				)}
			</Box>
		),
		content: <FilterPresetDescription preset={preset} />,
	})

	const {filterPresets, current, displaying, isNotFiltering} = useSelector(selectorFilterPresets)
	return (
		<Box gap="xxsmall">
			<Text weight="bold" size="small">
				Preset Filters
			</Text>
			<Box gap="xsmall" justify="between" direction="row">
				<Box width="374px">
					<SRSimpleSelect
						showSearchInput
						size="medium"
						options={filterPresets.map(preset => toOption(preset))}
						value={current.selected ? current.selected._id : undefined}
						placeholder="Select a Preset"
						disabled={!filterPresets.length || ops.readonly}
						onChange={presetId =>
							ops.select(
								!current.selected || current.selected._id !== presetId
									? filterPresets.find(preset => preset._id === presetId) || null
									: null,
							)
						}
						searchPlaceholder="Search"
						emptySearchMessage="Nothing found"
					/>
				</Box>
				<SRIconButton
					data-testid={'filter-header-save-icon'}
					icon={<SRSaveIcon color="neutral-1" size="medium" />}
					size={'medium'}
					onClick={() => ops.showSettings({...displaying!, _id: undefined, name: '', scope: 'user'})}
					disabled={isNotFiltering || ops.readonly}
				/>
			</Box>
		</Box>
	)
}
