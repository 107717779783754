import {RootState} from '../reducers'
import {ClassificationEntity} from '../features/Classification/classification.entities'

export function selectorSelectedClassifications(
	state: Pick<RootState, 'classificationState'>,
): ClassificationEntity[] | undefined {
	return state.classificationState.classificationsForSelectedElements
}

export const selectorClassificationState = (state: RootState) => state.classification
