import {EditIssue} from './EditIssue'
import React from 'react'
import {deleteIssue, fetchIssueById, postAssetToIssue, postIssue, putIssue} from '../api/issue-management.api'
import {useParams} from 'react-router-dom'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {AxiosError} from 'axios'
import {LoadingWrapper} from '../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {history} from '../../../router/history'
import {useGlobalNotifications} from '../../GlobalNotifications'
import {useIssuePageState} from '../issue.slice'
import {useBasePath} from '../../../hooks/useBasePath'
import {RightPanelHeader} from '../../../components/CommonsCandidate/Layout/RightPanel/RightPanelHeader'
import {SRRightPanelBody, SRRightPanelContainer} from 'sr-react-commons'
import {Id} from '../../../entities/value-objects'
import {useClassificationState} from '../../../reducers/classification.slice'
import {IssueEntity} from '../issue.entities'
import {useViewerReady} from '../../../hooks/useViewerReady'
import {trackLogRocketEvent} from '../../Tracking/logrocket'
import {useCurrentProject} from '../../../hooks/useCurrentProject'

export type EditIssueRouteProps = {isEdit: boolean}

function prepareIssueForTracking(issue: IssueEntity) {
	return {
		issueId: issue.issueId,
		issueStatus: issue.issueStatus,
		assignedUser: issue.assignedUser ? issue.assignedUser.toString() : 'Unassigned',
		name: issue.diagnosis,
		createdBy: `${issue.user?.firstName} ${issue.user?.lastName}`,
		analysis: issue.analysisView || 'none',
		elements: issue.externalId,
	}
}

export function EditIssueRoute({isEdit}: EditIssueRouteProps) {
	const {id} = useParams<{id: Id}>()
	const issuesUrl = `${useBasePath()}issues`
	const {notifyAlert} = useGlobalNotifications()
	const {selectedAnalysisView} = useClassificationState()
	const pageState = useIssuePageState()
	const queryClient = useQueryClient()
	const viewerReady = useViewerReady()
	const project = useCurrentProject()!

	const {data: issue, isLoading: isIssuesLoading, isError: isIssuesError} = useQuery<IssueEntity, AxiosError>(
		['issues', 'issue-by-id', id],
		() => fetchIssueById(id!),
		{enabled: !!(isEdit && id && selectedAnalysisView)},
	)
	const {mutateAsync: remove, isLoading: isRemoveLoading} = useMutation((id: IssueEntity['_id']) => deleteIssue(id), {
		onSuccess: async (_, id) => {
			pageState.gotoPage(0)
			history.push(issuesUrl)
			console.log(id)
			trackLogRocketEvent('Issue.Removed', {
				issueId: id,
			})
			await queryClient.invalidateQueries(['issues', 'issues-by-forge'])
			return queryClient.invalidateQueries(['issues', 'issues-page'])
		},
		onError: () => notifyAlert('The issue could not be deleted, please retry'),
	})
	const {mutateAsync: create, isLoading: isCreateLoading} = useMutation(
		// only add the analysis id on creation, since on edit it could have confusing consequences
		postIssue,
		{
			onSuccess: async (issue, variables) => {
				if (variables.asset) {
					await postAssetToIssue(
						project!,
						issue._id,
						variables.asset.img,
						variables.asset.type,
						selectedAnalysisView!._id,
					)
				}
				pageState.gotoPage(0)
				trackLogRocketEvent('Issue.Created', prepareIssueForTracking(issue))
				history.push(issuesUrl)
				await queryClient.invalidateQueries(['issues', 'issues-by-forge'])
				return queryClient.invalidateQueries(['issues', 'issues-page'])
			},
			onError: () => notifyAlert('The issue could not be created, please retry'),
		},
	)
	const {mutateAsync: update, isLoading: isUpdateLoading} = useMutation(
		// only add the analysis id on creation, since on edit it could have confusing consequences
		putIssue,
		{
			onSuccess: async issue => {
				history.push(issuesUrl)
				trackLogRocketEvent('Issue.Updated', prepareIssueForTracking(issue))
				queryClient.removeQueries(['issues', 'issue-by-id', issue._id])
				await queryClient.invalidateQueries(['issues', 'issues-by-forge'])
				return queryClient.invalidateQueries(['issues', 'issues-page'])
			},
			onError: () => notifyAlert('The issue could not be updated, please retry'),
		},
	)
	const isLoading = isIssuesLoading || isCreateLoading || isRemoveLoading || isUpdateLoading || !viewerReady
	return (
		<SRRightPanelContainer id="IssueLayer" className="edit-issue">
			<RightPanelHeader title={isEdit ? 'Edit issue' : 'Create issue'} back={'issues'} />
			<SRRightPanelBody>
				<LoadingWrapper {...{isLoading, isError: isIssuesError}} errorMsg="We couldn't access the issue, please retry.">
					{!isEdit || issue ? <EditIssue {...{isEdit, remove, create, update, issue}} /> : null}
				</LoadingWrapper>
			</SRRightPanelBody>
		</SRRightPanelContainer>
	)
}
