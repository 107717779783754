import * as WatchlistRouting from '../../Watchlist/watchlistRouting'
import {UserProject} from '../../../reducers/userProfile'
import {WatchlistEntity} from '../../Watchlist/entities/watchlist.entities'
import {WatchlistElementsFilter} from '../../Watchlist/slices/watchlist-filter-elements.slice'
import {AnalysisViewEntityPopulated} from '../../AnalysisView/analysis-view.entities'
import {AnalysisVersionMap} from '../../../reducers/classification.slice'
import {FiltersEntity} from '../../FilterPresets/entities/filter-preset.entities'
import {useQuery} from 'react-query'
import {getElementStatesByForgeObjectIds} from '../api/classification.api'
import {CLASSIFICATIONS_BASE_QUERY_KEY} from './index'

export type SimplifiedLocation = 'quality' | 'watchlist-list' | 'watchlist-create-edit' | 'watchlist-detail'

export function getSimplifiedLocation(locationPathname: string): SimplifiedLocation {
	if (WatchlistRouting.isProgressMonitoringWorkPackageCreateOrEdit(locationPathname)) {
		return 'watchlist-create-edit'
	} else if (WatchlistRouting.isProgressMonitoringWorkPackageDetailArea(locationPathname)) {
		return 'watchlist-detail'
	} else if (WatchlistRouting.isProgressMonitoringWorkPackageListArea(locationPathname)) {
		return 'watchlist-list'
	} else {
		return 'quality'
	}
}

export function useClassificationsForColorizeModel(
	selectedProject: UserProject,
	selectedWatchlist: WatchlistEntity | null,
	watchlistElementsFilter: WatchlistElementsFilter,
	selectedAnalysisView: AnalysisViewEntityPopulated | null,
	analysisVersionMap: AnalysisVersionMap,
	classificationFiltersDebounced: FiltersEntity,
	enableUnderConstruction: boolean,
	approvedFilter: boolean | null,
	simplifiedLocation: 'quality' | 'watchlist-list' | 'watchlist-create-edit' | 'watchlist-detail',
	classificationsFilter: FiltersEntity,
) {
	const {data, status} = useQuery(
		[
			CLASSIFICATIONS_BASE_QUERY_KEY,
			'states-by-forge-object-id',
			{
				selectedWatchlist,
				watchlistElementsFilter,
				selectedAnalysisView,
				analysisVersionMap,
				classificationsFilter: classificationFiltersDebounced,
				enableUnderConstruction,
				approvedFilter,
				simplifiedLocation,
			},
		],
		async () => {
			return getElementStatesByForgeObjectIds(
				selectedWatchlist,
				watchlistElementsFilter,
				selectedProject,
				selectedAnalysisView,
				analysisVersionMap,
				classificationsFilter,
				enableUnderConstruction,
				approvedFilter,
				simplifiedLocation,
			)
		},
	)
	return {data, status}
}
