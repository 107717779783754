import {Box} from 'grommet'
import React, {ReactNode} from 'react'

export const ManagementDashboardAnalysisRequestSizedContentBox = ({children}: {children: ReactNode}) => {
	return (
		<Box
			width={{min: '930px', max: '930px'}}
			border={{color: 'neutral-2', size: '1px'}}
			gap="small"
			pad="medium"
			flex={false}
		>
			{children}
		</Box>
	)
}
