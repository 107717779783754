import React from 'react'
import {Box} from 'grommet'
import {useSelector} from 'react-redux'
import {selectorViewerIsReady} from '../../../selectors/viewer-state.selector'
import {useManagementDashboardState} from '../../../features/ManagementDashboard/core/management-dashboad.slice'

const COLORS = {
	light: '#ffffff',
	dark: '#bbbbbb',
	darker: '#1f232e',
}

const SIZES = {
	xsmall: '18px',
	small: '24px',
	normal: '40px',
}

export type SpinnerProps = {
	size?: keyof typeof SIZES
	color?: keyof typeof COLORS
	hideIfGlobalLoader?: boolean
	text?: string
	center?: boolean
}

type SpinnerSVGProps = Pick<SpinnerProps, 'color' | 'size' | 'center'>

export function SpinnerSVG(props: {size?: string; color?: string}) {
	return (
		<svg version="1.1" viewBox="0 0 32 32" width={props.size} height={props.size} fill={props.color}>
			<path opacity=".25" d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4" />
			<path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
				<animateTransform
					attributeName="transform"
					type="rotate"
					from="0 16 16"
					to="360 16 16"
					dur="0.8s"
					repeatCount="indefinite"
				/>
			</path>
		</svg>
	)
}

export const SpinnerSVGIcon = ({size = 'normal', color = 'dark'}: SpinnerSVGProps) => (
	<Box width={SIZES[size]} height={SIZES[size]}>
		<SpinnerSVG size={SIZES[size]} color={COLORS[color]} />
	</Box>
)

export const Spinner = ({
	size = 'normal',
	color = 'dark',
	hideIfGlobalLoader = true,
	text,
	center = true,
}: SpinnerProps) => {
	const {page} = useManagementDashboardState()
	const visible = useSelector(selectorViewerIsReady) || !!page || !hideIfGlobalLoader
	return (
		<Box
			align={center ? 'center' : undefined}
			justify={center ? 'center' : undefined}
			alignSelf={center ? 'center' : undefined}
			style={center ? {margin: 'auto'} : undefined}
			margin={center ? 'auto' : undefined}
		>
			{!visible ? null : (
				<>
					<SpinnerSVGIcon size={size} color={color} />
					{text ? <Box pad={'small'}>{text}</Box> : null}
				</>
			)}
		</Box>
	)
}
