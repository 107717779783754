import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FilterPresetEntity} from '../entities/filter-preset.entities'

export type FilterPresetState = {
	filterPresets: FilterPresetEntity[]
	current: {
		selected: FilterPresetEntity | null
	}
	loading: boolean
}

export function buildInitialState(): FilterPresetState {
	return {
		filterPresets: [],
		current: {
			selected: null,
		},
		loading: false,
	}
}

export const filterPresetSlice = createSlice({
	name: 'filterPreset',
	initialState: buildInitialState(),
	reducers: {
		doReset: () => buildInitialState(),
		doSetLoading: (state, action: PayloadAction<boolean>) => {
			return {...state, loading: action.payload}
		},
		doFilterPresetFetchAll: (state, action: PayloadAction<FilterPresetEntity[]>) => {
			return {...state, loading: false, filterPresets: action.payload.reverse()}
		},
		doFilterPresetSave: (state, action: PayloadAction<FilterPresetEntity>) => {
			let filterPresets = state.filterPresets.map(preset =>
				preset._id !== action.payload._id ? preset : action.payload,
			)
			filterPresets = filterPresets.includes(action.payload) ? filterPresets : [action.payload, ...filterPresets]
			return {
				...state,
				filterPresets,
				loading: false,
				current: {...state.current, selected: action.payload},
			}
		},
		doFilterPresetSetSelected: (state, action: PayloadAction<string | null>) => {
			let selected = null
			if (action.payload) {
				selected = state.filterPresets.find(preset => preset._id === action.payload) || null
				if (!selected && action.payload) {
					console.error(`The filter preset with id ${action.payload} could not be found`)
				}
			}
			return {
				...state,
				current: {
					...state.current,
					selected,
				},
			}
		},
		doFilterPresetRemove: (state, action: PayloadAction<string>) => {
			return {
				...state,
				loading: false,
				filterPresets: state.filterPresets.filter(preset => preset._id !== action.payload),
				current:
					(state.current.selected || {})._id === action.payload
						? {
								...buildInitialState().current,
						  }
						: {...state.current},
			}
		},
	},
})
