import React, {useEffect} from 'react'
import './App.scss'
import ProjectPicker from './ProjectPicker'
import {Redirect, Route, Switch, useParams, useRouteMatch, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {GlobalNotifications} from '../features/GlobalNotifications'
import {MainContent} from './MainContent'
import {UserProject} from '../reducers/userProfile'
import {Grommet} from 'grommet'
import {amplifyConfig} from '../config'
import {selectorCurrentUser} from '../selectors/current-user.selectors'
import {ShowMaintenanceMode} from './MaintenanceMode/ShowMaintenanceMode'
import {QueryClientProvider} from 'react-query'
import {GetProjectInitData} from './GetData/GetProjectInitData'
import GetUserProfileFromDB from './GetData/GetUserProfileFromDB'
import Amplify from 'aws-amplify'
import {theme} from '../theme'
import {Tenant} from '../entities/value-objects'
import {GuardedRoute} from './CommonsCandidate/AccessControl/GuardedRoute'
import {PermissionsEnum} from '../entities/auth'
import {SRHeading} from 'sr-react-commons'
import {defaultQueryClient} from '../utilities/query-client.util'
import {ForgeIntegrationOauthCodeHandler} from '../features/ForgeIntegration/oauth/ForgeIntegrationOauthCodeHandler'
import {FeatureFlagsProvider, useFeatureEnabled} from '../features/FeatureFlags/FeatureFlagsProvider'
import {classificationSlice} from '../reducers/classification.slice'

// test
Amplify.configure(amplifyConfig)

declare global {
	interface Window {
		Cypress: any
		NOP_VIEWER: Autodesk.Viewing.GuiViewer3D
	}
}

type AppProps = {
	authState?: string
	authData?: {
		attributes: {email: string}
	}
	location: {
		pathname: string
	}
}

type ProjectBaseContainerProps = {
	project: UserProject
	userAttributes: {email: string}
}

function ProjectBaseContainer({project, userAttributes}: ProjectBaseContainerProps) {
	const {url} = useRouteMatch()
	const {tenantId} = useParams<{tenantId: Tenant}>()
	const dispatch = useDispatch()
	const eoiEnabled = useFeatureEnabled('elementsOfInterest')

	useEffect(() => {
		dispatch(classificationSlice.actions.setIsInterestingColumnEnabled(eoiEnabled))
	}, [])

	return (
		<>
			{project.lastAnalysisView ? (
				<Switch>
					<GuardedRoute path={`${url}/insights`} project={project} permission={PermissionsEnum.READ}>
						<Redirect to={`/${tenantId}/${encodeURIComponent(project.lastAnalysisView!._id)}/insights`} />
					</GuardedRoute>
					<GuardedRoute path={`${url}/quality`} project={project} permission={PermissionsEnum.READ}>
						<Redirect to={`/${tenantId}/${encodeURIComponent(project.lastAnalysisView!._id)}/quality`} />
					</GuardedRoute>
					<GuardedRoute path={`${url}/issues`} project={project} permission={PermissionsEnum.READ}>
						<Redirect to={`/${tenantId}/${encodeURIComponent(project.lastAnalysisView!._id)})/issues`} />
					</GuardedRoute>
					<GuardedRoute path={`${url}/:analysisViewId`} project={project} permission={PermissionsEnum.READ}>
						<MainContent project={project} userAttributes={userAttributes} />
					</GuardedRoute>
					<GuardedRoute path={`${url}/`} project={project} permission={PermissionsEnum.READ}>
						<Redirect to={`/${tenantId}/${encodeURIComponent(project.lastAnalysisView!._id)}`} />
					</GuardedRoute>
				</Switch>
			) : (
				<SRHeading level="2">This project does not have any analyses yet.</SRHeading>
			)}
		</>
	)
}

export function App({authState, authData, location}: AppProps) {
	const currentUser = useSelector(selectorCurrentUser)
	useEffect(() => {
		if (window.NOP_VIEWER) {
			window.NOP_VIEWER.resize()
		}
	}, [location])

	useEffect(() => {
		// After a user is created and changed his/her password, the authState is "signedIn" and the CognitoUser object
		// does not have the attributes. Reloading fixes the problem.
		// This is not invoked when a normal login happens.
		// This is probably fixed in a newer version of amplify
		// https://github.com/aws-amplify/amplify-js/issues/1728
		if (authState === 'signedIn' && authData && authData.attributes === undefined) {
			window.location.reload()
		}
	}, [authState, authData])

	if (authState !== 'signedIn' || !authData) return null

	return (
		<Grommet theme={theme} full>
			<FeatureFlagsProvider>
				<QueryClientProvider client={defaultQueryClient}>
					<ShowMaintenanceMode>
						<Switch>
							<Route path="/forgeauth">
								<ForgeIntegrationOauthCodeHandler />
							</Route>
							<Route>
								<GlobalNotifications />
								<GetUserProfileFromDB userAttributes={authData.attributes}>
									{(userProjects: any) => (
										<ProjectPicker userProjects={userProjects} userAttributes={authData.attributes}>
											{(selectedProject: UserProject) => (
												<GetProjectInitData currentUser={currentUser} selectedProject={selectedProject}>
													<ProjectBaseContainer
														key={selectedProject.tenantId}
														userAttributes={authData.attributes}
														project={selectedProject}
													/>
												</GetProjectInitData>
											)}
										</ProjectPicker>
									)}
								</GetUserProfileFromDB>
							</Route>
						</Switch>
					</ShowMaintenanceMode>
				</QueryClientProvider>
			</FeatureFlagsProvider>
		</Grommet>
	)
}

export const AppWithStore = withRouter(App)
