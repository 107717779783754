import {Box, Text} from 'grommet'
import {Cube, Lock, Unlock} from 'grommet-icons'
import {
	ToggleAssetsModeAction,
	ToggleThreeSixtyHighlightAction,
	ToggleThreeSixtyLockedView,
} from '../../../actions/viewer'
import React from 'react'
import {ViewerState} from '../../../reducers/viewerReducer'
import {format} from 'date-fns'
import {
	SRBrandSelectableButton,
	SRBrandSelectableIconButton,
	SRCarrouselArrows,
	SRFloatingHeaderOverlay,
} from 'sr-react-commons'
import {AddToIssueBox} from '../../AddToIssueButton/AddToIssueBox'
import {Viewsphere} from '../../../reducers/classification'
import {useThreeSixtyState} from './UseThreeSixtyState'
import {CENTER_BUTTON_WIDTH} from '../../Viewer/DockedViewsHeader'
import {AssetsModeSwitchButton} from '../../Assets/AssetModeSwithButton'
import {ClassificationEntity} from '../../../features/Classification/classification.entities'

export type ThreeSixtyHeaderProps = {
	contextMode: ViewerState['properties']['assetsMode']
}

export function ThreeSixtySplitControlsWrapper() {
	const {lockedViewsProps, displayViewsphereHeaderElements, carouselProps, threeSixtyProps} = useThreeSixtyState()

	return (
		<ThreeSixtyHeaderSplit
			carouselProps={carouselProps}
			lockedViewsProps={lockedViewsProps}
			threeSixtyProps={threeSixtyProps}
			displayViewsphereHeaderElements={displayViewsphereHeaderElements}
		/>
	)
}

function ThreeSixtyHeaderSplit(props: {
	carouselProps: {carouselIndex: number; prev: () => void; next: () => void; viewspheres: Viewsphere[]}
	lockedViewsProps: {
		threeSixtyLockedView: boolean
		onToggleLockedViews: () => ToggleThreeSixtyLockedView
		classification: ClassificationEntity | null
	}
	threeSixtyProps: {
		onToggleMode: () => ToggleAssetsModeAction
		highLightElement: boolean
		onToggleHighlightElement: () => ToggleThreeSixtyHighlightAction
	}
	displayViewsphereHeaderElements: boolean
}) {
	return (
		<>
			{!props.displayViewsphereHeaderElements ? null : (
				<Box width={`${CENTER_BUTTON_WIDTH}px`}>
					<SRBrandSelectableButton
						size={'medium'}
						label={
							<strong>{props.lockedViewsProps.threeSixtyLockedView ? 'Movement Locked' : 'Movement Unlocked'}</strong>
						}
						isSelected={props.lockedViewsProps.threeSixtyLockedView}
						icon={props.lockedViewsProps.threeSixtyLockedView ? <Lock size="small" /> : <Unlock size="small" />}
						onClick={props.lockedViewsProps.onToggleLockedViews}
						reverse
					/>
				</Box>
			)}
			{props.displayViewsphereHeaderElements && props.lockedViewsProps.classification ? (
				<Box width={`calc(50% - ${CENTER_BUTTON_WIDTH / 2}px)`} align="end">
					<ThreeSixtyControls
						classification={props.lockedViewsProps.classification}
						threeSixtyProps={props.threeSixtyProps}
						carouselProps={props.carouselProps}
					/>
				</Box>
			) : null}
		</>
	)
}

function ThreeSixtyControls(props: {
	classification: ClassificationEntity
	threeSixtyProps: {
		onToggleMode: () => ToggleAssetsModeAction
		highLightElement: boolean
		onToggleHighlightElement: () => ToggleThreeSixtyHighlightAction
	}
	carouselProps: {carouselIndex: number; prev: () => void; next: () => void; viewspheres: Viewsphere[]}
}) {
	return (
		<Box direction="row" gap={'xsmall'}>
			<Box align="center" justify="center" direction={'row'}>
				<Text
					title={format(new Date(props.classification.scannedDate), 'yyyy-MM-dd HH:mmaaa')}
					color={'white'}
					textAlign={'center'}
				>
					Scan date: {format(new Date(props.classification.scannedDate), 'yyyy-MM-dd')}
				</Text>
			</Box>
			<SRBrandSelectableIconButton
				icon={<Cube />}
				isSelected={props.threeSixtyProps.highLightElement}
				onClick={props.threeSixtyProps.onToggleHighlightElement}
				title="Toggle if the element should be highlighted in the 360°"
			/>
			<Box>
				<SRCarrouselArrows
					index={props.carouselProps.carouselIndex}
					total={props.carouselProps.viewspheres.length}
					prev={props.carouselProps.prev}
					next={props.carouselProps.next}
				/>
			</Box>
		</Box>
	)
}

function ThreeSixtyHeaderPopup(props: {
	carouselProps: {carouselIndex: number; prev: () => void; next: () => void; viewspheres: Viewsphere[]}
	classification: ClassificationEntity | null
	threeSixtyProps: {
		onToggleMode: () => ToggleAssetsModeAction
		highLightElement: boolean
		onToggleHighlightElement: () => ToggleThreeSixtyHighlightAction
	}
	displayViewsphereHeaderElements: boolean
}) {
	return (
		<>
			<Box direction={'row'} align="center" gap={'xsmall'}>
				<AssetsModeSwitchButton />
				{props.displayViewsphereHeaderElements && props.classification && (
					<Box flex={false} alignSelf={'end'}>
						<AddToIssueBox domSelector={'#photosphere-asset-container'} type={'photosphere'} />
					</Box>
				)}
			</Box>
			{props.displayViewsphereHeaderElements && props.classification ? (
				<ThreeSixtyControls
					classification={props.classification}
					threeSixtyProps={props.threeSixtyProps}
					carouselProps={props.carouselProps}
				/>
			) : null}
		</>
	)
}

export const ThreeSixtyPopupHeaderWrapper = () => {
	const {classification, displayViewsphereHeaderElements, carouselProps, threeSixtyProps} = useThreeSixtyState()

	return (
		<SRFloatingHeaderOverlay dataHideOnScreenshot={true}>
			<ThreeSixtyHeaderPopup
				threeSixtyProps={threeSixtyProps}
				carouselProps={carouselProps}
				displayViewsphereHeaderElements={displayViewsphereHeaderElements}
				classification={classification}
			/>
		</SRFloatingHeaderOverlay>
	)
}
