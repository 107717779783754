import {useQuery} from 'react-query'
import {getNextSuggestedElement} from './suggestion.api'
import {defaultQueryClient} from '../../../utilities/query-client.util'
import {AnalysisViewEntity} from '../../AnalysisView/analysis-view.entities'

export const useGetNextSuggestion = (analysisViewId: AnalysisViewEntity['_id']) =>
	useQuery(['eoi', 'suggestions', 'next-suggestion', analysisViewId], () => getNextSuggestedElement(analysisViewId), {
		retry: false,
	})

export const invalidateGetNextQuery = (analysisViewId: AnalysisViewEntity['_id']) => {
	return defaultQueryClient.invalidateQueries(['eoi', 'suggestions', 'next-suggestion', analysisViewId])
}
