import React, {useState} from 'react'
import {Box} from 'grommet'
import {SRBrandButton} from 'sr-react-commons'
import {Add} from 'grommet-icons'
import {goTo, goToFn} from '../../../../router/history'
import {useCurrentProject} from '../../../../hooks/useCurrentProject'
import {ManagementDashboardPageLayout} from '../../core/components/layout/ManagementDashboardPageLayout'
import {SearchBox} from '../../../../components/CommonsCandidate/SearchBox/SearchBox'
import {useAnalysisRequestList} from '../../../AnalysisRequest/UseAnalysisRequestList'
import {LoadingWrapper} from '../../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {ManagementDashboardAnalysisRequestListTable} from './table/ManagementDashboardAnalysisRequestListTable'
import {ManagementDashboardAnalysisRequestEmptyView} from '../support/ManagementDashboardAnalysisRequestEmptyView'

export const ManagementDashboardAnalysisRequestList = () => {
	const currentProject = useCurrentProject()
	const goToWizard = goToFn(`/manage/${currentProject?._id}/analysisrequest/create`)
	const goToAnalysisRequestViewPage = (analysisRequestId: string) => {
		goTo(`/manage/${currentProject?._id}/analysisrequest/view/${analysisRequestId}`)
	}
	const [searchText, setSearchText] = useState<string>('')
	const {data: analysisRequestList, isLoading, isError} = useAnalysisRequestList(searchText)
	return (
		<ManagementDashboardPageLayout>
			<Box gap={'small'} fill>
				<Box justify="between" direction="row" alignContent="center">
					<SearchBox placeholder={'Search analyses'} onChange={setSearchText} />
					<SRBrandButton size="medium" label={`Request Analysis`} icon={<Add size="small" />} onClick={goToWizard} />
				</Box>
				<LoadingWrapper
					{...{isLoading, isError}}
					fill
					errorMsg="We couldn't display the analysis requests, please retry."
					isEmpty={!analysisRequestList?.length}
					emptyMsg={<ManagementDashboardAnalysisRequestEmptyView {...{searchText, goToWizard}} />}
				>
					{!analysisRequestList?.length ? null : (
						<ManagementDashboardAnalysisRequestListTable
							analysisRequests={analysisRequestList}
							goToAnalysisRequestViewPage={goToAnalysisRequestViewPage}
						/>
					)}
				</LoadingWrapper>
			</Box>
		</ManagementDashboardPageLayout>
	)
}
