import React from 'react'
import {useSelector} from 'react-redux'
import {Box} from 'grommet'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {ThreeSixtyPopupHeaderWrapper} from './ThreeSixtyHeader'
import {PhotoSphereRenderer} from './PhotoSphereRenderer'
import {selectorCurrentProject} from '../../../selectors/current-project.selectors'
import {AssetContainer} from '../../Assets/AssetContainer'

export default function PhotoSpherePanelContent() {
	const project = useSelector(selectorCurrentProject)
	const {
		threeSixtyHighlight,
		threeSixtyClassification: classification,
		threeSixtyViewsphere: viewsphere,
		forgeObjectIds,
	} = useSelector(selectorViewerProperties)
	const [cameraFov, setCameraFov] = React.useState(45)
	const [cameraOrientation, setCameraOrientation] = React.useState<[number, number]>([0, 0])
	if (!project) return null
	return (
		<Box className="docking-panel-content">
			<ThreeSixtyPopupHeaderWrapper />
			<Box gap={'xsmall'} fill>
				<Box id="photosphere-asset-container" fill>
					<AssetContainer
						display={!!(forgeObjectIds.length === 1 && classification?.viewspheres?.length)}
						placeholderTitle={
							forgeObjectIds.length > 1
								? 'Multiple elements selected'
								: classification !== null && !classification.viewspheres?.length
								? 'No 360° images available'
								: undefined
						}
						placeholderText={
							forgeObjectIds.length > 1
								? 'Please select only one element to see its 360º images'
								: classification !== null && !classification.viewspheres?.length
								? 'This element has no 360° images in the current analysis.'
								: 'Please select an element to see its 360º image.'
						}
					>
						<PhotoSphereRenderer
							cameraFov={cameraFov}
							cameraOrientation={cameraOrientation}
							setCameraFov={setCameraFov}
							setCameraOrientation={setCameraOrientation}
							highlightElement={threeSixtyHighlight}
							contextMode="popup"
							viewsphere={viewsphere!}
							tenantId={project!.tenantId}
						/>
					</AssetContainer>
				</Box>
			</Box>
		</Box>
	)
}
