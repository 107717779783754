import React, {ReactNode} from 'react'
import {Box, Text} from 'grommet'

// TODO use in Quality Filter Components (StatusFilter, ElementNameFilter etc.)
export function FilterControl(props: {label: string; children: ReactNode}) {
	return (
		<Box flex={{shrink: 0}}>
			<Text weight="bold" size="small">
				{props.label}
			</Text>
			{props.children}
		</Box>
	)
}
