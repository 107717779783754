import {Box, Text} from 'grommet'
import React, {useEffect, useState} from 'react'
import {
	SR_COLOR_NEUTRAL_1,
	SR_COLOR_NEUTRAL_2,
	SR_COLOR_TEXT_LIGHT,
	SR_COLOR_WHITE,
	SRIconButton,
} from 'sr-react-commons'
import {ReactComponent as ThumbsUp} from '../../icons/thumb_up.svg'
import {ReactComponent as ThumbsDown} from '../../icons/thumb_down.svg'
import {ElementEntity} from 'entities/element.entities'
import {vote} from 'features/ElementsOfInterest/Vote/vote.api'
import {invalidateGetNextQuery} from '../useGetNextSuggestion'
import {useSelector} from 'react-redux'
import {selectorForgeSelection, selectorViewerInstance} from 'selectors/viewer-state.selector'
import {invalidateClassificationQueryData} from 'features/Classification/data-management/useInvalidateClassification'
import {NextSuggestionResponse} from 'entities/suggestion.entities'
import {SRDismissableTooltipRef} from '../../../../components/CommonsCandidate/SRDismissableTooltip/SRDismissableTooltip'
import {store} from '../../../../store/store'
import {createGlobalNotificationsAction as createAction} from '../../../GlobalNotifications'

interface SuggestionRowProps {
	element: ElementEntity
	analysisViewId: string
	suggestion: NextSuggestionResponse
	displaySuccessBanner: () => void
	tooltipRef?: React.MutableRefObject<SRDismissableTooltipRef | undefined>
}

export function SuggestionRow({
	element,
	analysisViewId,
	suggestion,
	displaySuccessBanner,
	tooltipRef,
}: SuggestionRowProps) {
	const [isSelected, setIsSelected] = useState(false)
	const viewer = useSelector(selectorViewerInstance)
	const selectedForgeObjectIds = useSelector(selectorForgeSelection)

	useEffect(() => {
		setIsSelected(selectedForgeObjectIds.includes(element.forgeObjectId))
	}, [selectedForgeObjectIds, element.forgeObjectId])

	const voteForElement = async (isInteresting: boolean) => {
		tooltipRef?.current?.dismissTooltip()
		await vote({
			analysisViewId,
			voteContext: {from: 'eoi-suggestion', suggestionId: suggestion.suggestionId},
			elementId: element._id,
			isInteresting,
		}).catch(e => {
			console.log('Error trying to bookmark an element ', e.message)
			store.dispatch(createAction.error(`Could not bookmark element, an unexpected error occurred: ${e.message}`))
		})

		await invalidateGetNextQuery(analysisViewId)
		await invalidateClassificationQueryData()
		if (suggestion.currentIndex === suggestion.total) {
			displaySuccessBanner()
		}
	}

	const focusSuggestionElement = () => {
		if (viewer && !isSelected) {
			tooltipRef?.current?.dismissTooltip()
			viewer.fitToView([element.forgeObjectId])
			viewer.select([element.forgeObjectId])
			setIsSelected(true)
		}
	}

	return (
		<Box
			pad={'xsmall'}
			background={isSelected ? SR_COLOR_NEUTRAL_1 : SR_COLOR_WHITE}
			direction="row"
			justify={'between'}
			border={
				!isSelected
					? {
							color: SR_COLOR_NEUTRAL_2,
							size: 'xsmall',
							style: 'solid',
					  }
					: false
			}
		>
			<Box pad={'xxsmall'} fill="horizontal" onClick={focusSuggestionElement} focusIndicator={false}>
				<Text truncate>{element.name || 'Unknown'}</Text>
			</Box>
			<Box direction="row" justify={'center'}>
				<SRIconButton
					data-testid="test-upvote-button"
					onClick={() => voteForElement(true)}
					textColor={isSelected ? SR_COLOR_WHITE : SR_COLOR_TEXT_LIGHT}
					icon={<ThumbsUp />}
				/>
				<SRIconButton
					data-testid="test-downvote-button"
					onClick={() => voteForElement(false)}
					textColor={isSelected ? SR_COLOR_WHITE : SR_COLOR_TEXT_LIGHT}
					icon={<ThumbsDown />}
				/>
			</Box>
		</Box>
	)
}
