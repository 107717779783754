import React from 'react'
import {Box} from 'grommet'
import {Redirect, Route, Switch, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import _ from 'lodash'
import {userHasAllPermissionsForProject, UserProject, userProjectHasPermission} from '../../reducers/userProfile'
import {RootState} from '../../reducers'
import {ProjectCard} from './ProjectCard'
import {routeEncodeTenantId} from '../../utilities/routeUtilities'
import {Search} from 'grommet-icons'
import {SRHeading, SRTextInput} from 'sr-react-commons'
import {ManagementDashboard} from '../../features/ManagementDashboard/core/components/ManagementDashboard'
import {AppBar} from '../AppBar/AppBar'
import {GoBackToSelectProjectComponent} from '../CommonsCandidate/AccessControl/GoBackToSelectProjectWrapper'
import {Tenant} from '../../entities/value-objects'
import {PermissionsEnum} from '../../entities/auth'

function ProjectPicker({
	userProjects,
	children,
	userAttributes,
}: {
	userProjects: UserProject[]
	children: any
	userAttributes: {email: string}
}) {
	const [searchText, setSearchText] = React.useState('')
	const escapedText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
	const exp = new RegExp(escapedText, 'i')
	const sortedUserProjects = _.sortBy(
		userProjects.filter(project => searchText === '' || exp.test(project.name) || exp.test(project.company.name)),
		['company.name', 'name'],
	)

	if (userProjects.length === 0) {
		return (
			<Box align="center" pad="large" gap="large">
				User not configured yet
			</Box>
		)
	}
	return (
		<Switch>
			<Route path="/select-project">
				<Box fill>
					<AppBar userAttributes={userAttributes} />
					<Box fill overflow="auto" style={{display: 'block'}}>
						<Box pad={{vertical: 'large', horizontal: 'large'}} align="center" gap="medium">
							<Box width={'850px'} gap={'medium'}>
								<SRHeading alignSelf="start" level={2}>
									My Projects
								</SRHeading>
								<Box alignSelf="start" width="250px">
									<SRTextInput
										width="250px"
										icon={<Search />}
										value={searchText}
										onChange={event => setSearchText(event.target.value)}
										placeholder={'Search all projects'}
										size={'large'}
									/>
								</Box>
							</Box>
							<Box gap={'small'} width="850px">
								{sortedUserProjects.length > 0 ? (
									sortedUserProjects.map(project => (
										<ProjectCard
											key={project.tenantId}
											project={project}
											disabled={!project.lastAnalysisView || !userProjectHasPermission(project, PermissionsEnum.READ)}
										/>
									))
								) : (
									<Box>No matching projects found</Box>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</Route>
			<Route path="/manage/:projectId?/:dashboardPage?/:dashboardSubPage?">
				<ManagementDashboard />
			</Route>
			<Route path="/:tenantId/">
				<ProjectBaseRoute userProjects={userProjects} children={children} />
			</Route>
			<Route path="/">
				<PickOrSelect userProjects={userProjects} />
			</Route>
		</Switch>
	)
}

const VIEW_PROJECT_PERMISSIONS = [PermissionsEnum.READ, PermissionsEnum.LIST, PermissionsEnum.VIEW_ASSETS]

function ProjectBaseRoute({userProjects, children}: {userProjects: UserProject[]; children: any}) {
	let {tenantId} = useParams<{tenantId: Tenant}>()
	const selectedProject = userProjects.find(project => routeEncodeTenantId(project.tenantId) === tenantId)
	if (!selectedProject) {
		return (
			<GoBackToSelectProjectComponent
				title="Project not found."
				reason="the project you requested could not be found"
			/>
		)
	}
	const isAuthorizedToViewProject = userHasAllPermissionsForProject(selectedProject, VIEW_PROJECT_PERMISSIONS)
	if (!isAuthorizedToViewProject) {
		return (
			<GoBackToSelectProjectComponent
				title={'Unauthorized'}
				reason={'you do not have the required permissions to access this page'}
			/>
		)
	}
	if (!selectedProject.lastAnalysisView) {
		return (
			<GoBackToSelectProjectComponent
				title="No analyses published yet."
				reason="the project you requested does not have any analyses published yet."
			/>
		)
	}
	return children(selectedProject)
}

const PickOrSelect = ({userProjects}: {userProjects: UserProject[]}) => {
	const lastProject = useSelector((state: RootState) => state.userProfileState.selectedProject)

	if (lastProject) {
		const tenantId = routeEncodeTenantId(lastProject.tenantId)
		return <Redirect to={'/' + tenantId} />
	} else if (userProjects.length === 1) {
		const tenantId = routeEncodeTenantId(userProjects[0].tenantId)
		return <Redirect to={'/' + tenantId} />
	} else {
		return <Redirect to="/select-project" />
	}
}

export default ProjectPicker
