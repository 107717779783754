import {IssueComment} from '../issue-comment.entities'
import {Box, Text} from 'grommet'
import React from 'react'
import {format, isThisYear} from 'date-fns'
import {User} from '../../../types/user'
import {SRDeleteSecondaryIconButton} from 'sr-react-commons'

type IssueCommentCardProps = {
	comment: IssueComment
	handleDeleteClick?: (event: any, comment: IssueComment) => void
}

export const IssueCommentCard = ({comment, handleDeleteClick}: IssueCommentCardProps) => {
	const date = format(
		new Date(comment.createdDate),
		isThisYear(new Date(comment.createdDate)) ? 'MMM d, h:mm a' : 'MMM d yyyy, h:mm a',
	)

	return (
		<Box>
			<Box data-testid="issue-comment-card" direction="row" justify="between">
				<Box width={{max: '90%'}}>
					<Box direction={'row'} gap={'xsmall'}>
						<Text size="small" weight="bold">
							{(comment.user as User)?.firstName} {(comment.user as User)?.lastName}
						</Text>
						<Text color="neutral-1" size="xsmall">
							{date}
						</Text>
					</Box>
					<Text size="small">{comment.comment}</Text>
				</Box>
				<Box>
					{handleDeleteClick && (
						<SRDeleteSecondaryIconButton size="small" onClick={event => handleDeleteClick(event, comment)} />
					)}
				</Box>
			</Box>
		</Box>
	)
}
