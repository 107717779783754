import {useDropzone} from 'react-dropzone'
import {Box} from 'grommet'
import React from 'react'
import {SRSecondaryButton} from 'sr-react-commons'

export function FileUploadButton(props: {
	acceptedFileTypes?: string[] | null
	onFileAccepted: (files: File[]) => void
	onFileRejected: (files: File[]) => void
}) {
	const {getRootProps, getInputProps} = useDropzone({
		onDropAccepted: props.onFileAccepted,
		onDropRejected: fileRejections => props.onFileRejected(fileRejections.map(fileRejection => fileRejection.file)),
		accept: props.acceptedFileTypes || undefined,
	})
	const {onClick, ...rootProps} = getRootProps()
	return (
		<Box {...rootProps}>
			<input data-testid="qr-image-upload" {...getInputProps()} />
			<SRSecondaryButton size={'small'} onClick={onClick} label={'Select Images'} />
		</Box>
	)
}
