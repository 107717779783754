import React from 'react'
import {Text} from 'grommet'

export type SupportLinkProps = {
	title: string
}
export function SupportLink(props: SupportLinkProps) {
	return (
		<Text>
			{props.title}{' '}
			<a target="_blank" href="mailto:support@naska.ai" rel="noreferrer">
				contact our customer support team
			</a>
			.
		</Text>
	)
}
