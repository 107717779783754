import React from 'react'
import {ManagementDashboardBaseFilePage} from '../file/ManagementDashboardBaseFilePage'
import {ScanFileUploadModal} from '../../FileUpload/components/ScanFileUploadModal'
import {FileUploaderWorkerProvider} from '../../FileUpload/components/FileUploadProviders'
import {useFileUploaderWorker} from '../../FileUpload/hooks/useFileUploaderWorker'
import {useManagementDashboardState} from '../core/management-dashboad.slice'

export function ManagementDashboardScansPage() {
	const {project} = useManagementDashboardState()
	const worker = useFileUploaderWorker()
	const [modalOpen, setModalOpen] = React.useState(false)
	const onClose = () => {
		setModalOpen(false)
	}
	return (
		<FileUploaderWorkerProvider worker={worker}>
			<ManagementDashboardBaseFilePage
				uploadModal={<ScanFileUploadModal projectId={project!._id} open={modalOpen} onClose={onClose} />}
				onOpenUploadModal={() => setModalOpen(true)}
				fileVisibility="scan"
			/>
		</FileUploaderWorkerProvider>
	)
}
