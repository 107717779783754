import React, {useState} from 'react'
import {SRPrimaryButton} from 'sr-react-commons'
import {PrimaryModal} from '../../../components/CommonsCandidate/Modals/PrimaryModal'
import {Box, Text} from 'grommet'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {forgeIntegrationEnsureUserIsAuthed} from '../oauth/forge-integration.oauth'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'
import {useGlobalNotifications} from '../../GlobalNotifications'
import {ForgeOauthError, handleForgeOAuthError} from '../oauth/ForgeOauthError'
import {invalidateUseUserHasValidForgeAccess} from '../hooks/UseUserHasValidForgeAccess'

export type ForgeIntegrationUserNotLoggedInModalProps = {
	onClose?: () => void
	open: boolean
}

export const ForgeIntegrationUserNotLoggedInModal = ({open, onClose}: ForgeIntegrationUserNotLoggedInModalProps) => {
	const project = useCurrentProject()
	const [forgeAuthLoading, setForgeAuthLoading] = useState(false)
	const {notifyError} = useGlobalNotifications()
	const logInUserAndCloseModal = async () => {
		setForgeAuthLoading(true)
		try {
			await forgeIntegrationEnsureUserIsAuthed(project!._id)
			await invalidateUseUserHasValidForgeAccess()
		} catch (e) {
			if (e instanceof ForgeOauthError) {
				handleForgeOAuthError(e, notifyError)
			} else {
				notifyError(`An unexpected error occurred during login.`)
			}
		} finally {
			setForgeAuthLoading(false)
		}
	}

	return (
		<PrimaryModal
			open={open}
			onClose={onClose}
			submitElement={
				<SRPrimaryButton
					disabled={forgeAuthLoading}
					label={'Login to Autodesk'}
					onClick={logInUserAndCloseModal}
					icon={forgeAuthLoading ? <Spinner size={'xsmall'} color={'darker'} /> : undefined}
				/>
			}
			title={'Import from Autodesk'}
			heading={'You are not logged into Autodesk'}
			bodyElement={
				<Box gap={'xxsmall'}>
					<Text>Log in to start browsing your files.</Text>
				</Box>
			}
		/>
	)
}
