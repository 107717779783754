import {Id, Tenant} from '../../entities/value-objects'

export type AnalysisViewStatus = 'ready' | 'in_progress' | 'no_progress'

export enum DeliveryType {
	Normal = 'Normal',
	Incremental = 'Incremental',
}

export interface Analysis {
	_id: Id
	tenantId: string
	id: string
	name: string
	scanDate: string
	analysisDate?: string
	versionNumber?: number
	versions?: {_id: string; versionNumber: number; status: string}[]
	model: {
		_id: string
		modelVersion: number
		modelUrn: string
	}
}

export type AnalysisViewEntity = {
	_id: Id
	id: string
	enabled: boolean
	createdDate: string
	tenantId: Tenant
	name: string
	scanDate: string
	model: {
		_id: string
		modelVersion: number
		modelUrn: string
		partialModels: {
			name: string
			externalIdsProjectFileId: Id
		}[]
	}
	scan: Id | null
	analysisDate: string
	statusUpdateTime: string
	status: AnalysisViewStatus
	deliveryType: DeliveryType
	analyses: Analysis['_id'][]
}

export type AnalysisViewEntityPopulated = Omit<AnalysisViewEntity, 'analyses'> & {analyses: Analysis[]}
