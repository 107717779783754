import {fetchElementRootModelsByModel} from '../../api/elements.api.v3'
import {AxiosError} from 'axios'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {useQuery} from 'react-query'

export function useRootModelInformations(modelId: string | undefined) {
	const project = useCurrentProject()
	return useQuery<string[], AxiosError>(
		['models', 'rootModels', project?._id, modelId],
		() => fetchElementRootModelsByModel(modelId!, project!._id),
		{
			enabled: !!(project && modelId),
		},
	)
}
