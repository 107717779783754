import {createGlobalNotificationsAction as createAction} from './reducer'
import {useDispatch} from 'react-redux'
import {useMemo} from 'react'

export function useGlobalNotifications() {
	const dispatch = useDispatch()
	return useMemo(
		() => ({
			notifyInfo: function (message: string): void {
				dispatch(createAction.info(message))
			},
			notifyAlert: function (message: string): void {
				dispatch(createAction.alert(message))
			},
			notifyError: function (message: string): void {
				dispatch(createAction.error(message))
			},
			dismissCurrent: function (): void {
				dispatch(createAction.dismissCurrent())
			},
			dismissAllInfos: function (): void {
				dispatch(createAction.dismissAllInfos())
			},
			dismissAllAlerts: function (): void {
				dispatch(createAction.dismissAllAlerts())
			},
			dismissAllErrors: function (): void {
				dispatch(createAction.dismissAllErrors())
			},
		}),
		[dispatch],
	)
}
