import axios, {AxiosError, AxiosInstance} from 'axios'
import {PayloadError, ApiRequestError} from './errors'

export function buildRequestInstance(
	baseUrl: string,
	getIdToken: () => Promise<string>,
	getMaintenanceMode: () => string | null,
	onMaintenanceActiveResponse: () => unknown,
): AxiosInstance {
	const client = axios.create({
		baseURL: baseUrl,
	})

	client.interceptors.request.use(
		async config => {
			const accessToken = await getIdToken()
			const maintenanceMode = getMaintenanceMode()
			config.headers = {
				accesstoken: accessToken,
				...(maintenanceMode ? {maintenanceMode} : {}),
			}
			return config
		},
		error => Promise.reject(error),
	)

	client.interceptors.response.use(
		response => response,
		(error: AxiosError) => {
			if (error.response?.status === 503) {
				onMaintenanceActiveResponse()
				return
			}
			if (error.response?.data?.type && error.response?.data?.payload)
				throw new PayloadError(error.response.status, error.response.data)
			if (error.response) throw new ApiRequestError(error.response)
			throw error
		},
	)

	return client
}
