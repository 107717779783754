import {useClassificationState} from '../reducers/classification.slice'
import _ from 'lodash'

export function useLatestModel() {
	const {analysisViews} = useClassificationState()
	return (
		(analysisViews &&
			(_.maxBy(
				analysisViews.filter(av => av.status === 'ready').map(av => av.model),
				'modelVersion',
			) ||
				_.maxBy(
					analysisViews.map(av => av.model),
					'modelVersion',
				))) ||
		null
	)
}
