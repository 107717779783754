import {useParams, useRouteMatch} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../reducers'
import React, {useEffect} from 'react'
import {useGlobalNotifications} from '../features/GlobalNotifications'
import {history} from '../router/history'
import {routeEncodeTenantId} from '../utilities/routeUtilities'
import {useSidebarWidth} from '../hooks/useSidebarWidth'
import {MainContentPresenter} from './MainContentPresenter'
import {useClassificationState} from '../reducers/classification.slice'
import {UserProject} from '../reducers/userProfile'
import {useLatestModel} from '../hooks/UseLatestModel'

type MainContentProps = {
	project: UserProject
	userAttributes: {email: string}
}

export function MainContent({project, userAttributes}: MainContentProps) {
	const sidebarWidth = useSidebarWidth()
	const {analysisViewId} = useParams<{analysisViewId: string}>()
	const {url} = useRouteMatch()
	const dispatch = useDispatch()
	const {analysisViews, setSelectedAnalysisView} = useClassificationState()
	const {notifyAlert} = useGlobalNotifications()
	const latestModel = useLatestModel()
	const documentUrn: string | null = useSelector((state: RootState) => {
		const modelUrn = state.progressMonitoringState.isOpen
			? latestModel?.modelUrn
			: state.classification.selectedAnalysisView?.model.modelUrn
		return modelUrn ? `urn:${btoa(modelUrn)}` : null
	})
	useEffect(() => {
		if (project.tenantId === analysisViews?.[0]?.tenantId) {
			const selectedAnalysisView = analysisViews.find(av => av._id === analysisViewId)
			if (selectedAnalysisView) {
				setSelectedAnalysisView(selectedAnalysisView)
			} else {
				// For legacy routes support, we check if the id is a string analysis view id
				const byStringIdView = analysisViews.find(av => av.id === analysisViewId)
				if (!byStringIdView) {
					notifyAlert(
						`No analysis found with id ${analysisViewId}. The last analysis for this project is loaded instead.`,
					)
				}
				history.push(
					`/${routeEncodeTenantId(project.tenantId)}/${encodeURIComponent(
						(byStringIdView || analysisViews[0])._id,
					)}/quality`,
				)
			}
		}
	}, [analysisViews, analysisViewId, dispatch, project, notifyAlert, setSelectedAnalysisView])
	return documentUrn ? (
		<MainContentPresenter
			project={project}
			documentUrn={documentUrn}
			userAttributes={userAttributes}
			url={url}
			sidebarWidth={sidebarWidth}
		/>
	) : null
}
