import {SRHeading, SRIconButton} from 'sr-react-commons'
import React, {useEffect, useState} from 'react'
import {ModelEntity} from '../../../../Models/model.entities'
import {Box} from 'grommet'
import {CircleInformation} from 'grommet-icons'
import {ManagementDashboardAnalysisRequestFormBimModelInformationModal} from '../modals/ManagementDashboardAnalysisRequestFormBimModelInformationModal'
import {ManagementDashboardAnalysisRequestStepState} from '../ManagementDashboardAnalysisRequestFormInProgress'
import {ManagementDashboardAnalysisRequestSectionBox} from '../../support/ManagementDashboardAnalysisRequestSectionBox'
import {ManagementDashboardAnalysisRequestActiveModelTable} from '../../support/ManagementDashboardAnalysisRequestActiveModelTable'

export const VERSION_CELL_WIDTH = '200px'
export const CREATED_DATE_CELL_WIDTH = '150px'

export type ManagementDashboardAnalysisRequestStep1State = ManagementDashboardAnalysisRequestStepState<'modelDomain'>

export const setValidity = (
	dto: ManagementDashboardAnalysisRequestStep1State['dto'],
): ManagementDashboardAnalysisRequestStep1State => {
	return {dto, valid: !!dto.modelDomain.model} as ManagementDashboardAnalysisRequestStep1State
}

export const ManagementDashboardAnalysisRequestFormStep1 = ({
	activeModel,
	rootModels,
	setState,
}: {
	activeModel: ModelEntity
	rootModels: string[]
	state: ManagementDashboardAnalysisRequestStep1State
	setState: (state: ManagementDashboardAnalysisRequestStep1State) => void
}) => {
	const [openInformationModal, setOpenInformationModal] = useState<boolean>(false)
	useEffect(() => {
		setState(setValidity({modelDomain: {model: activeModel._id}}))
	}, [activeModel, setState])
	return (
		<>
			{openInformationModal ? (
				<ManagementDashboardAnalysisRequestFormBimModelInformationModal
					onClose={() => setOpenInformationModal(false)}
				/>
			) : null}
			<ManagementDashboardAnalysisRequestSectionBox step={1} title={'BIM model'}>
				<Box pad={{left: 'small'}}>
					<Box direction="row" align="center" gap="xxsmall">
						<SRHeading level={5}>1.1 Confirm BIM model</SRHeading>
						<SRIconButton icon={<CircleInformation />} onClick={() => setOpenInformationModal(true)} />
					</Box>
					<Box>
						<ManagementDashboardAnalysisRequestActiveModelTable model={activeModel} rootModels={rootModels} />
					</Box>
				</Box>
			</ManagementDashboardAnalysisRequestSectionBox>
		</>
	)
}
