import React from 'react'
import {postForgeAuthorizationFromCodeExchange} from '../forge-integration.api'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'
import {Box} from 'grommet'
import {ForgePopupMessage} from './forge-integration.oauth'
import {ForgeOauthErrorTypes} from './ForgeOauthError'

const notifyParent = (forgePopupMessage: ForgePopupMessage) => {
	window.opener?.postMessage(forgePopupMessage, '*')
}

export const ForgeIntegrationOauthCodeHandler = () => {
	const {code, state, error, error_description} = Object.fromEntries(new URLSearchParams(document.location.search))
	const {projectId}: {projectId: string} = JSON.parse(decodeURI(state))
	if (!(code && projectId)) notifyParent({type: 'forgeauth.error', data: error, debugInfo: error_description})
	postForgeAuthorizationFromCodeExchange(projectId, code)
		.then(() => {
			notifyParent({type: 'forgeauth.success'})
		})
		.catch(e => {
			notifyParent({
				type: 'forgeauth.error',
				data: ForgeOauthErrorTypes.code_exchange_failed,
				debugInfo: e instanceof Error ? e.message : undefined,
			})
		})
	return (
		<Box fill>
			<Spinner size={'normal'} text={'Authorizing'} hideIfGlobalLoader={false} />
		</Box>
	)
}
