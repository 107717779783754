import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, ThemeContext, ThemeType} from 'grommet'
import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../reducers'
import {ClassificationElementRef} from '../../../entities/element.entities'
import {SRHeading, SRSecondaryButton} from 'sr-react-commons'
import {normalizeColor} from 'grommet/utils'
import {CardContainer} from '../../../components/CommonsCandidate/CardContainer/CardContainer'

interface IssueElementsBoxProps {
	elements:
		| Pick<ClassificationElementRef, '_id' | 'forgeObjectId' | 'name' | 'mappedFloors' | 'mappedElementType'>[]
		| undefined
}

export function IssueElementsBox({elements}: IssueElementsBoxProps) {
	const project = useSelector((state: RootState) => state.userProfileState.selectedProject)

	if (!project) {
		return null
	}

	return (
		<CardContainer gap="small">
			<Box direction={'row'} justify={'between'}>
				<SRHeading level={4}>Associated elements</SRHeading>
				<SRSecondaryButton
					size={'medium'}
					label={'Select all'}
					onClick={() => {
						elements && window.NOP_VIEWER.select(elements.map(el => el.forgeObjectId))
					}}
				/>
			</Box>

			<Box>
				<ThemeContext.Extend
					value={{
						table: {
							header: {
								extend: ({theme}: {theme: ThemeType}) => {
									return `border-color: ${normalizeColor('neutral-2', theme)};`
								},
							},
						},
					}}
				>
					<Table alignSelf={'start'}>
						<TableHeader>
							<TableRow>
								<TableCell pad={'none'} scope="col">
									<Text weight={600}>Name</Text>
								</TableCell>
								<TableCell scope="col">
									<Text weight={600}>Floor</Text>
								</TableCell>
								<TableCell scope="col">
									<Text weight={600}>Type</Text>
								</TableCell>
								<TableCell pad={'none'} alignSelf="end" scope="col">
									<Text weight={600}>Actions</Text>
								</TableCell>
							</TableRow>
						</TableHeader>
						<TableBody>
							{elements &&
								elements.map(element => (
									<TableRow key={element._id}>
										<TableCell pad={'none'} width="145px">
											<Text title={element.name || ''} truncate>
												{element.name}
											</Text>
										</TableCell>
										<TableCell width="55px">
											<Text title={element.mappedFloors} truncate>
												{element.mappedFloors}
											</Text>
										</TableCell>
										<TableCell width="80px">
											<Text title={element.mappedElementType} truncate>
												{element.mappedElementType}
											</Text>
										</TableCell>
										<TableCell alignSelf="end" pad={'none'}>
											<SRSecondaryButton
												size="small"
												label="Select"
												onClick={() => {
													window.NOP_VIEWER.select([element.forgeObjectId])
												}}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</ThemeContext.Extend>
			</Box>
		</CardContainer>
	)
}
