import React from 'react'

import {Box, Text} from 'grommet'
import {useSelector} from 'react-redux'
import {selectorFilterPresets} from '../selectors/filter-preset.selectors'
import {Performance, Refresh} from 'grommet-icons'
import {Filters} from './filters/Filters'
import {FilterPresetOps} from './FilterPresets'
import {SRIconButton, SRSecondaryButton} from 'sr-react-commons'
import {pick} from 'lodash'

export function FilterPresetsBody({ops}: {ops: FilterPresetOps}) {
	const {isEdited, displaying, current, isNotFiltering, canModify} = useSelector(selectorFilterPresets)
	return (
		<Box gap="xsmall">
			{current.selected && (
				<Box direction="row" gap="xsmall" align="start" justify="between">
					<Box>
						{isEdited && (
							<Text weight="bold" color="dark-4">
								Edited
							</Text>
						)}
					</Box>
					<Box gap="xsmall" direction="row">
						<SRSecondaryButton
							onClick={ops.discardChanges}
							size="medium"
							label="Discard Changes"
							icon={<Refresh size="small" />}
							reverse
							disabled={!isEdited || ops.readonly}
						/>
						<SRSecondaryButton
							onClick={() => ops.save(displaying!)}
							size="medium"
							label="Save"
							disabled={!canModify(current.selected) || !isEdited || isNotFiltering || ops.readonly}
						/>
						<SRIconButton
							data-cy={'filter-presets-performance'}
							onClick={() => ops.showSettings(current.selected!)}
							size="medium"
							icon={<Performance size={'20px'} />}
							disabled={ops.readonly}
						/>
					</Box>
				</Box>
			)}
			<Filters {...pick(ops, 'mode', 'readonly')} />
		</Box>
	)
}
