import {
	CURRENT_USER_ADD,
	USER_PROFILE_ADD,
	USER_PROFILE_FETCH,
	USER_PROFILE_FETCH_ERROR,
	USER_PROJECT_SELECTED,
	USER_SIGN_OUT,
} from '../constants/action-types'
import {UserProject} from '../reducers/userProfile'

export const doFetchUserProfile = () => ({
	type: USER_PROFILE_FETCH,
})

export const doFetchUserProfileError = (error: Error) => ({
	type: USER_PROFILE_FETCH_ERROR,
	error,
})

export const doAddUserProfile = (userProfile: UserProject) => ({
	type: USER_PROFILE_ADD,
	userProfile,
})

export const doAddCurrentUser = (payload: UserProject) => ({
	type: CURRENT_USER_ADD,
	payload,
})

export const doUserProjectSelect = (project: UserProject | null | undefined) => ({
	type: USER_PROJECT_SELECTED,
	selectedProject: project,
})

export const doUserSignOut = () => ({
	type: USER_SIGN_OUT,
})
