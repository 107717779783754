import {Redirect, Route, Switch} from 'react-router-dom'
import React from 'react'
import {AppBar} from './AppBar/AppBar'
import {IssueList} from '../features/IssueManagement/components/IssueList'
import {Box, Grid} from 'grommet'
import QualityLayer from './QualityLayer'
import {Watchlist} from '../features/Watchlist/Watchlist'
import {UserProject} from '../reducers/userProfile'
import {ViewerContainer} from './Viewer/ViewerContainer'
import {ViewerLoadingOverlay} from './Viewer/ViewerLoadingOverlay'
import {InsightsDashboard} from '../features/InsightsDashboard/InsightsDashboard'
import {useFeatureEnabled} from '../features/FeatureFlags/FeatureFlagsProvider'

type MainContentPresenterProps = {
	project: UserProject
	documentUrn: string
	userAttributes: {email: string}
	url: string
	sidebarWidth: string
}

export function MainContentPresenter({
	project,
	documentUrn,
	userAttributes,
	url,
	sidebarWidth,
}: MainContentPresenterProps): JSX.Element {
	const enableInsightDashboard = useFeatureEnabled('insightsDashboard')
	return (
		<Box overflow={'hidden'} style={{height: '100vh'}}>
			<Grid
				rows={['auto', 'flex']}
				columns={['auto', sidebarWidth]}
				gap="none"
				fill
				areas={[
					{name: 'app-bar', start: [0, 0], end: [1, 0]},
					{name: 'main', start: [0, 1], end: [0, 1]},
					{name: 'sidebar', start: [1, 1], end: [1, 1]},
				]}
			>
				<Box gridArea="app-bar">
					<AppBar userAttributes={userAttributes} selectedProject={project} />
				</Box>
				<Box
					background={'light-1'}
					border={{
						color: 'light-3',
						size: 'xsmall',
						style: 'solid',
						side: 'left',
					}}
					gridArea="sidebar"
				>
					<Switch>
						{enableInsightDashboard && (
							<Route path={url + '/insights'}>
								<InsightsDashboard />
							</Route>
						)}
						<Route path={url + '/quality'}>
							<QualityLayer />
						</Route>
						<Route path={url + '/issues'}>
							<IssueList />
						</Route>
						<Route path={url + '/progress/work-packages'}>
							<Watchlist />
						</Route>
						<Route path={`${url}/*`} render={() => <Redirect to={url} />} />
					</Switch>
				</Box>
				<Box gridArea={'main'}>
					<ViewerLoadingOverlay>
						<ViewerContainer key={project._id} selectedProject={project} documentUrn={documentUrn} />
					</ViewerLoadingOverlay>
				</Box>
			</Grid>
		</Box>
	)
}
