import React from 'react'
import {SRFileDownloadButton, SRPrimaryButton} from 'sr-react-commons'
import {format} from 'date-fns'

function WatchListPDFButton({watchListName, fetchPDFFile}: {watchListName: string; fetchPDFFile: () => Promise<Blob>}) {
	return (
		<SRFileDownloadButton
			buttonClass={SRPrimaryButton}
			label="Get PDF report"
			fileName={`${watchListName}-${format(new Date(), 'yyyyMMdd-HHmm')}.pdf`}
			getFile={fetchPDFFile}
		/>
	)
}

export default React.memo(WatchListPDFButton)
