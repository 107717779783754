import {RootState} from '../reducers'
import {useHistory, useLocation} from 'react-router-dom'
import {Dispatch} from 'redux'
import {issueSlice} from '../features/IssueManagement/issue.slice'
import {AnalysisViewEntityPopulated} from '../features/AnalysisView/analysis-view.entities'
import {selectorClassificationState} from '../selectors/classification.selectors'
import {IssueEntity} from '../features/IssueManagement/issue.entities'

export function routeEncodeTenantId(tenantId: string): string {
	return tenantId.replace('|', '-')
}

export function getCurrentBasePath(state: RootState): string {
	const {selectedAnalysisView} = selectorClassificationState(state)
	if (state.userProfileState.selectedProject && selectedAnalysisView) {
		return `/${routeEncodeTenantId(state.userProfileState.selectedProject!.tenantId)}/${encodeURIComponent(
			selectedAnalysisView._id,
		)}/`
	} else {
		return '/'
	}
}

export function selectAnalysisView(
	history: ReturnType<typeof useHistory>,
	location: ReturnType<typeof useLocation>,
	selectedAnalysisView: AnalysisViewEntityPopulated,
	selectedIssue: IssueEntity | null,
	dispatch: Dispatch,
	issuePath?: string,
) {
	let featurePath = location.pathname.split('/').slice(3).join('/')
	if (issuePath) featurePath = featurePath.concat('/', issuePath)
	const path = `/${routeEncodeTenantId(selectedAnalysisView.tenantId)}/${encodeURIComponent(
		selectedAnalysisView._id,
	)}/${featurePath}`
	if (selectedIssue?.analysisView !== selectedAnalysisView._id) {
		dispatch(issueSlice.actions.setSelectedIssue({issue: null}))
	}
	history.push(path)
}
