import {IssueEntity} from '../issue.entities'
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useClassificationState} from '../../../reducers/classification.slice'
import {selectAnalysisView} from '../../../utilities/routeUtilities'
import React from 'react'
import {IssueCard} from './IssueCard'

export function IssueCardContainer({
	issue,
	checked,
	active,
	setActive,
	onCheckChange,
}: {
	issue: IssueEntity
	checked: boolean
	active: boolean
	setActive: () => void
	onCheckChange: () => void
}) {
	const history = useHistory()
	const location = useLocation()
	const {url} = useRouteMatch()
	const dispatch = useDispatch()
	const {selectedAnalysisView, analysisViews} = useClassificationState()
	const issueAnalysisView = analysisViews!.find(av => av._id === issue.analysisView)
	const handleIssueRedirect = (issuePath: string) => {
		if (selectedAnalysisView?._id !== issue.analysisView) {
			selectAnalysisView(history, location, issueAnalysisView!, issue as IssueEntity, dispatch, issuePath)
		} else {
			history.push(url + '/' + issuePath)
		}
	}
	const handleEditClick = (event: React.MouseEvent) => {
		handleIssueRedirect(issue._id + '/edit')
		event.stopPropagation()
	}
	const handleCommentClick = (event: React.MouseEvent) => {
		handleIssueRedirect(issue._id + '/comments')
		event.stopPropagation()
	}
	const goToDetail = (event: React.MouseEvent) => {
		handleIssueRedirect(issue._id + '/detail/')
		event.stopPropagation()
	}
	const handleCardClick = () => {
		if (selectedAnalysisView?._id !== issue.analysisView)
			selectAnalysisView(history, location, issueAnalysisView!, issue as IssueEntity, dispatch)
		return setActive && setActive()
	}
	return (
		<IssueCard
			checked={checked}
			onCheckChange={onCheckChange}
			handleCommentClick={handleCommentClick}
			handleEditClick={handleEditClick}
			goToDetail={goToDetail}
			handleCardClick={handleCardClick}
			issue={issue}
			showEdit={true}
			active={active}
			issueAnalysisView={issueAnalysisView!}
			issueSelectRequiresModelSwitch={selectedAnalysisView?.model._id !== issueAnalysisView?.model._id}
		/>
	)
}
