import {AnalysisViewEntityPopulated} from '../../AnalysisView/analysis-view.entities'
import {FiltersEntity, toFiltersDTO} from '../../FilterPresets/entities/filter-preset.entities'
import {UserProject} from '../../../reducers/userProfile'
import React, {useCallback, useState} from 'react'
import {SRPlainButton} from 'sr-react-commons'
import {Box, Text} from 'grommet'
import {DocumentPdf} from 'grommet-icons'
import {ExportQualityPdfDto, ExportQualityReportAsPDFModal} from './ExportQualityReportAsPDFModal'
import {useSelector} from 'react-redux'
import {selectorApprovedFilter} from '../../../reducers/viewerReducer'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'
import {fetchQualityReportPdf} from '../api/quality-report.api'
import {format} from 'date-fns'
import {postImageToS3} from '../../../utilities/storageUtilities'
import {DroppedFile} from '../../FileUpload/components/ProjectFileUploader'
import {useGlobalNotifications} from '../../GlobalNotifications'

const onStartImageS3Upload = async (selectedImages: DroppedFile[], project: UserProject) => {
	const uploadPromises = selectedImages
		.map(fileDescriptor => fileDescriptor.file)
		.map(async file => {
			const reader = new FileReader()
			return new Promise<string>(resolve => {
				reader.onload = async () => {
					resolve(await postImageToS3(project, reader.result as string, file))
				}
				reader.readAsDataURL(file)
			})
		})
	return await Promise.all(uploadPromises)
}
export default function QualityPdfModalButton({
	selectedAnalysisView,
	filters,
	project,
}: {
	selectedAnalysisView: AnalysisViewEntityPopulated
	filters: FiltersEntity | undefined
	project: UserProject
}) {
	const {notifyError} = useGlobalNotifications()
	const [showDownloadPDFModal, setShowDownloadPDFModal] = useState(false)
	const approvedFilter = useSelector(selectorApprovedFilter)
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const filename = `${selectedAnalysisView.name}-${format(new Date(), 'yyyyMMdd-HHmm')}.pdf`

	const onSubmit = useCallback(
		async (dto: ExportQualityPdfDto) => {
			const imageS3Keys = await onStartImageS3Upload(dto.selectedImages, project)
			return fetchQualityReportPdf(
				project._id,
				selectedAnalysisView._id,
				filters
					? toFiltersDTO(
							filters,
							project.floorMapping,
							project.elementTypeMapping,
							enableUnderConstruction,
							approvedFilter,
					  )
					: undefined,
				dto.showQuality,
				dto.includeMagnitudeDistribution,
				enableUnderConstruction,
				dto.descriptionText,
				imageS3Keys,
			)
		},
		[approvedFilter, enableUnderConstruction, filters, project, selectedAnalysisView._id],
	)

	const onError = (error: Error) => {
		notifyError(error.message)
	}

	return (
		<Box>
			<SRPlainButton
				alignSelf="stretch"
				size={'medium'}
				label={
					<Box direction={'row'} gap={'small'} align={'center'} pad={'xsmall'}>
						<DocumentPdf color="black" size={'medium'} />
						<Text>Export report as PDF</Text>
					</Box>
				}
				onClick={() => setShowDownloadPDFModal(true)}
			/>
			{showDownloadPDFModal && (
				<ExportQualityReportAsPDFModal
					fileName={filename}
					onClose={() => setShowDownloadPDFModal(false)}
					onSubmit={onSubmit}
					onError={onError}
				/>
			)}
		</Box>
	)
}
