import {Box, Text} from 'grommet'
import React from 'react'
import {SRMultiSelect} from 'sr-react-commons'
import {FiltersEntity} from '../../../entities/filter-preset.entities'

export function DeviationDescriptionFilter({
	filters,
	setFilter,
	displayMagnitudeByAxis,
}: {
	filters: {descriptions: string[] | null}
	setFilter: (filter: Partial<Pick<FiltersEntity, 'descriptions'>>) => void
	displayMagnitudeByAxis: boolean
}) {
	return (
		<>
			{displayMagnitudeByAxis && (
				<Box>
					<Text weight="bold" size="small">
						Description
					</Text>
					<SRMultiSelect
						options={[
							{label: 'Displaced', value: 'displaced'},
							{label: 'Built too high', value: 'too_high'},
							{label: 'Built too low', value: 'too_low'},
						]}
						placeholder="All descriptions displayed"
						size="medium"
						onChange={(values: any) => {
							setFilter({descriptions: values.length === 0 ? null : values})
						}}
						value={filters.descriptions ?? []}
					/>
				</Box>
			)}
		</>
	)
}
