import PhotoSpherePanelContent from './PhotoSpherePanelContent'
import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {theme} from '../../../theme'
import {Grommet} from 'grommet'
import {history} from '../../../router/history'
import {Router} from 'react-router-dom'
import {QueryClientProvider} from 'react-query'
import {store} from '../../../store/store'
import {defaultQueryClient} from '../../../utilities/query-client.util'

const id = 'ThreeSixtyPhotoPanel'
const title = '360° Image'
const options = {
	shadow: false,
}

export default class PhotoSpherePanel extends Autodesk.Viewing.UI.DockingPanel {
	viewer: Autodesk.Viewing.Viewer3D
	DOMContent: HTMLDivElement
	private reactMounted = false

	constructor(viewer: Autodesk.Viewing.Viewer3D) {
		super(viewer.container as HTMLElement, id, title, options)
		this.viewer = viewer
		this.container.style.height = '600px'
		this.container.style.width = '800px'
		this.container.style.top = '50px'
		this.container.style.left = '100px'
		this.DOMContent = document.createElement('div')
		this.DOMContent.className = 'docking-panel-content-wrapper'
		this.container.appendChild(this.DOMContent)
	}

	setVisible(show: boolean) {
		super.setVisible(show)
		if (show) {
			ReactDOM.render(
				<Provider store={store}>
					<Router history={history}>
						<Grommet full style={{height: '100%', width: '100%'}} theme={theme}>
							<QueryClientProvider client={defaultQueryClient}>
								<PhotoSpherePanelContent />
							</QueryClientProvider>
						</Grommet>
					</Router>
				</Provider>,
				this.DOMContent,
			)
			this.reactMounted = true
		} else {
			if (this.reactMounted) {
				ReactDOM.unmountComponentAtNode(this.DOMContent)
				this.reactMounted = false
			}
		}
	}
}
