import {CLASSIFICATION_RESET_STATE, SELECTED_CLASSIFICATIONS_FETCH_SUCCESS} from '../constants/action-types'
import {ClassificationEntity} from '../features/Classification/classification.entities'

export interface Viewsphere {
	elementBoundaries: number[]
	elementCentroid: [number, number]
	poseCoordinates: [number, number, number]
	poseOrientation: [number, number, number, number]
	_id: string
	imagePath: string
}

export interface Section {
	_id: string
	name: string
	axis: string
	height: number
	perspectiveAxesPath: string
	perspectivePath: string
	scale: number
	sectionAxesPath: string
	sectionPath: string
	planeCoefficients?: [number, number, number, number]
}

export interface ModificationHistory {
	classificationType: 'automatic' | 'manual'
	createdBy: string
	createdDate: string
	status: ClassificationStatus
}

export type ClassificationStatus = 'verified' | 'deviated' | 'under_construction' | 'no_data' | 'missing'

export type ApprovedHistory = {
	approved: boolean
	createdBy: string
	createdDate: Date
}

export enum MagnitudeSign {
	'POSITIVE' = 'POSITIVE',
	'NEGATIVE' = 'NEGATIVE',
}

export interface ClassificationState {
	classificationsForSelectedElements: ClassificationEntity[]
}

export function buildInitialState(): ClassificationState {
	return {
		classificationsForSelectedElements: [],
	}
}

function applySelectedClassificationsFetched(
	state: ClassificationState,
	action: {type: string; classificationsForSelectedElements: ClassificationEntity[]},
) {
	return {
		...state,
		classificationsForSelectedElements: action.classificationsForSelectedElements,
	}
}

function reducer(state = buildInitialState(), action: any): ClassificationState {
	switch (action.type) {
		case SELECTED_CLASSIFICATIONS_FETCH_SUCCESS: {
			return applySelectedClassificationsFetched(state, action)
		}
		case CLASSIFICATION_RESET_STATE: {
			return buildInitialState()
		}
	}
	return state
}

export default reducer
