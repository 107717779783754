import React from 'react'
import {Viewsphere} from '../../../reducers/classification'
import {PhotoSphereExtensionI} from './PhotoSphere.viewer.extension'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../reducers'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {doSelectThreeSixtyClassification, doSelectThreeSixtyViewsphere} from '../../../actions/viewer'
import {usePhotosphereViewerWrapper} from './PhotosphereViewerWrapper'
import {getCurrentRotationDelta} from './PhotoSphere.utilities'
import {ClassificationEntity} from '../../../features/Classification/classification.entities'

const INITIAL_CAMERA_FOV = 45

/**
 * External dependencies: Redux State, PhotosphereViewerWrapperI, PhotoSphereExtensionI
 */
export function usePhotosphereExtension(selectedClassification: ClassificationEntity | null) {
	const dispatch = useDispatch()
	const viewerWrapper = usePhotosphereViewerWrapper()
	const {
		threeSixtyOpened,
		threeSixtyLockedView: viewsLocked,
		assetsMode,
		threeSixtyViewsphere: viewsphere,
		threeSixtyClassification: classification,
	} = useSelector(selectorViewerProperties)
	const forgeMapping = useSelector((state: RootState) => state.viewerState.isReady.forgeMapping)
	const isModelLoaded = forgeMapping && viewerWrapper
	const extensionRef = React.useRef<PhotoSphereExtensionI>()
	const [extensionLoaded, setExtensionLoaded] = React.useState(false)
	const [cameraOrientation, setCameraOrientation] = React.useState<[number, number]>([0, 0])
	const [cameraFov, setCameraFov] = React.useState(INITIAL_CAMERA_FOV)
	const setActiveViewSphere = React.useCallback(
		(viewsphere: Viewsphere) => {
			dispatch(doSelectThreeSixtyViewsphere(viewsphere))
		},
		[dispatch],
	)

	// on classification change
	React.useEffect(() => {
		dispatch(doSelectThreeSixtyClassification(selectedClassification || null))
	}, [selectedClassification, dispatch])

	// on three sixty mode change
	React.useEffect(() => {
		extensionRef.current?.setVisible(threeSixtyOpened && assetsMode === 'popup')
	}, [threeSixtyOpened, extensionRef, assetsMode])

	// on model loaded
	// this is basically the "onMount"
	React.useEffect(() => {
		if (isModelLoaded && viewerWrapper) {
			viewerWrapper
				.loadPhotosphereExtension({
					setActiveViewSphere,
					setCameraFov,
					setCameraOrientation,
				})
				.then(extension => {
					if (extension) {
						extensionRef.current = extension
						setExtensionLoaded(true)
					}
				})
		}
		return () => {
			viewerWrapper?.unloadPhotosphereExtension()
		}
	}, [isModelLoaded, viewerWrapper, dispatch, setActiveViewSphere, setCameraOrientation])

	// on classification update
	React.useEffect(() => {
		if (extensionLoaded && threeSixtyOpened) {
			extensionRef.current?.setSelectedClassification(classification)
		}
	}, [classification, extensionLoaded, threeSixtyOpened])

	// on viewsphere update
	React.useEffect(() => {
		if (extensionLoaded) {
			if (viewsphere && threeSixtyOpened) {
				extensionRef.current?.setSelectedViewSphere(viewsphere)
			} else {
				extensionRef.current?.setSelectedViewSphere(null)
			}
		}
	}, [extensionLoaded, extensionRef, threeSixtyOpened, viewsphere])

	// on zoom change
	React.useEffect(() => {
		if (viewerWrapper && extensionLoaded && viewsLocked) {
			viewerWrapper.applyViewerCameraZoom(cameraFov)
		}
	}, [extensionLoaded, viewerWrapper, cameraFov, viewsLocked])

	// on locked views change
	React.useEffect(() => {
		if (extensionLoaded && isModelLoaded) {
			extensionRef.current?.setViewsLocked(viewsLocked)
			if (viewerWrapper) {
				if (viewsLocked) {
					viewerWrapper.updateViewerUIForLockedViews()
					viewerWrapper.setToolBarButtonVisible(false)
				} else {
					viewerWrapper.setToolBarButtonVisible(true)
					viewerWrapper.applyViewerCameraZoom(INITIAL_CAMERA_FOV)
				}
			}
		}
	}, [extensionLoaded, viewsLocked, isModelLoaded, viewerWrapper])

	// on orientation change
	React.useEffect(() => {
		if (viewerWrapper && viewsphere && classification && extensionLoaded && viewsLocked) {
			const viewSpherePosePosition = viewerWrapper.getViewerCoordinates(...viewsphere.poseCoordinates)
			const elementCenter = viewerWrapper.getCenterOfElementBounds(classification.forgeObjectId)
			const {deltaLon, deltaLat} = getCurrentRotationDelta(viewsphere, cameraOrientation)
			viewerWrapper.applyViewerCameraRotation(viewSpherePosePosition, elementCenter, deltaLon, deltaLat)
		}
	}, [viewerWrapper, classification, cameraOrientation, viewsphere, extensionLoaded, viewsLocked])

	return {
		cameraOrientation,
		setCameraOrientation,
		cameraFov,
		setCameraFov,
		extensionLoaded,
	}
}
