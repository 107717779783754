import React, {useEffect, useState} from 'react'
import {Box, Layer, Text} from 'grommet'
import {Close} from 'grommet-icons'
import {SRHeading, SRIconButton, SRPlainButton, SRPrimaryButton, SRSimpleSelect} from 'sr-react-commons'
import {useForgeIntegrationHubs} from '../hooks/UseForgeIntegrationHubs'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {useForgeIntegrationHubProjects} from '../hooks/UseForgeIntegrationHubProjects'
import {ForgeHub, ForgeIntegrationEntity, ForgeProject} from '../forge-integration.entities'
import {createForgeIntegration} from '../forge-integration.api'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'
import {useGlobalNotifications} from '../../GlobalNotifications'

export type ForgeIntegrationCreateModalProps = {
	close: () => void
	onIntegration: (integration: ForgeIntegrationEntity) => void
}

export const ForgeIntegrationCreateModal = ({close, onIntegration}: ForgeIntegrationCreateModalProps) => {
	const {notifyError} = useGlobalNotifications()
	const project = useCurrentProject()
	const [createIntegrationLoading, setCreateIntegrationLoading] = useState(false)
	const [selectedHub, setSelectedHub] = useState<ForgeHub | null>(null)
	const [selectedProject, setSelectedProject] = useState<ForgeProject | null>(null)
	const {data: hubs, isLoading: isHubsLoading} = useForgeIntegrationHubs(project || null)
	const {data: projects, isLoading: isProjectsLoading} = useForgeIntegrationHubProjects(
		project || null,
		selectedHub || null,
	)
	useEffect(() => {
		setSelectedProject(null)
	}, [selectedHub, setSelectedProject])
	const onIntegrateClick = async () => {
		setCreateIntegrationLoading(true)
		try {
			const integration = await createForgeIntegration(project!._id, selectedHub!.id, selectedProject!.id)
			onIntegration(integration)
		} catch (e) {
			notifyError(
				'Unable to connect to Autodesk. An unexpected error occurred while trying to connect to your Autodesk project.',
			)
			setCreateIntegrationLoading(false)
		}
	}
	return (
		<Layer position={'top'} margin={{top: '100px'}}>
			<Box gap={'medium'} pad={'medium'} width={'525px'}>
				<Box justify={'between'} direction={'row'}>
					<SRHeading level={4}>Autodesk BIM 360/ACC Integration</SRHeading>
					<SRIconButton icon={<Close />} onClick={close} alignSelf={'center'} />
				</Box>
				<Box gap="medium">
					<SRHeading level={5}>Select an Autodesk hub and project</SRHeading>
					<Box gap="xxsmall">
						<SRHeading level={6}>Hub</SRHeading>
						{!hubs?.length ? (
							<Text>There are no hubs to show. Please make sure you have permissions in a hub.</Text>
						) : (
							<SRSimpleSelect
								disabled={isHubsLoading || createIntegrationLoading}
								placeholder={'Select an Autodesk hub'}
								options={hubs?.map(hub => ({value: hub.id, label: hub.name})) || []}
								size={'medium'}
								onChange={hubId => setSelectedHub(hubs!.find(hub => hub.id === hubId)!)}
								value={selectedHub?.id}
							/>
						)}
					</Box>
					{!hubs?.length ? null : (
						<Box gap="xxsmall">
							<SRHeading level={6}>Project</SRHeading>
							{selectedHub && !projects?.length ? (
								<Text>
									There are no projects in this hub to show. Please make sure you have permissions in this hub's
									projects.
								</Text>
							) : (
								<SRSimpleSelect
									disabled={isProjectsLoading || !selectedHub || createIntegrationLoading}
									placeholder={'Select an Autodesk project'}
									options={projects?.map(hub => ({value: hub.id, label: hub.name})) || []}
									size={'medium'}
									onChange={projectId =>
										setSelectedProject(projects?.find(project => project.id === projectId) || null)
									}
									value={selectedProject?.id}
								/>
							)}
						</Box>
					)}
				</Box>
				<Box direction="row" justify="between">
					<SRPlainButton onClick={close} label="Cancel" size={'medium'} />
					<SRPrimaryButton
						label={'Connect to Autodesk'}
						disabled={!(selectedHub && selectedProject) || createIntegrationLoading}
						icon={!createIntegrationLoading ? undefined : <Spinner size={'xsmall'} color={'darker'} />}
						onClick={onIntegrateClick}
					/>
				</Box>
			</Box>
		</Layer>
	)
}
