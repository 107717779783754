import {Box, Layer, Text} from 'grommet'
import {SRHeading, SRIconButton, SRPlainButton, SRPrimaryButton} from 'sr-react-commons'
import {Close} from 'grommet-icons'
import React from 'react'

export const ManagementDashboardAnalysisRequestFormCancelModal = ({
	goBackForm,
	exitForm,
}: {
	goBackForm: () => void
	exitForm: () => void
}) => {
	return (
		<Layer position="top" margin={{top: '100px'}}>
			<Box gap="medium" pad="medium" width="520px">
				<Box direction="row" gap="medium" justify="between">
					<SRHeading level={4} alignSelf="center">
						Cancel
					</SRHeading>
					<SRIconButton icon={<Close />} onClick={goBackForm} alignSelf="center" />
				</Box>
				<Box pad="xsmall" gap="small">
					<SRHeading level={6}>Do you really want to exit the wizard?</SRHeading>
					<Text>If you exit now, your data will be lost.</Text>
				</Box>
				<Box direction="row" justify="between">
					<SRPlainButton size="large" label="Back" onClick={goBackForm} />
					<SRPrimaryButton size="large" label="Yes, exit wizard" onClick={exitForm} />
				</Box>
			</Box>
		</Layer>
	)
}
