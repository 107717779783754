import {vector3FromRealWorldToViewerCoordinates} from '../utilities/viewerUtilities'

const THREE = window.THREE

export function coordinateFromIFCtoViewer(model: Autodesk.Viewing.Model, x: number, y: number, z: number) {
	return vector3FromRealWorldToViewerCoordinates([x, y, z], model.getGlobalOffset(), model.getUnitScale())
}

export function scaleFromIFCtoViewer(model: Autodesk.Viewing.Model) {
	const scale = model.getUnitScale()
	return 1 / scale
}

export function raycastMouseEvent(
	event: MouseEvent,
	viewer: Autodesk.Viewing.GuiViewer3D,
	collisionObjects: THREE.Mesh[],
	modelIds: number[],
) {
	// Project mouse event coordinates into scene camera and perform scene raycasting against custom meshes
	// event: mouse event
	// viewer: scene viewer instance
	// collisionObjects: array of Three.js meshes appended to the scene
	// modelIds: array of model ids loaded into the viewer

	// Capture pointer position TODO: IS THIS NEEDED?
	const pointer: MouseEvent = (event as any).pointers ? (event as any).pointers[0] : event

	// Backproject with render camera
	const rect = viewer.impl.canvas.getBoundingClientRect()
	const x = ((pointer.clientX - rect.left) / rect.width) * 2 - 1
	const y = -((pointer.clientY - rect.top) / rect.height) * 2 + 1

	const pointerVector = new THREE.Vector3()
	pointerVector.set(x, y, 0.5)
	pointerVector.unproject(viewer.impl.camera)

	// Build raycaster and check for intersections
	const raycaster = new THREE.Raycaster()
	raycaster.set(viewer.impl.camera.position, pointerVector.sub(viewer.impl.camera.position).normalize())
	const intersectResults = raycaster.intersectObjects(collisionObjects, true)

	// Check for model occlusions with native viewer raycaster
	//TODO: model has no rayIntersect ts definition, impl has one, but with different params
	const hitTest = (viewer.model as any).rayIntersect(raycaster, true, modelIds)
	const selections = intersectResults.filter(res => !hitTest || hitTest.distance > res.distance)
	return selections
}
