import React, {ReactNode} from 'react'
import {ForgeIntegrationUserNotLoggedInModal} from './ForgeIntegrationUserNotLoggedInModal'
import {ForgeIntegrationProjectNotConnectedModal} from './ForgeIntegrationProjectNotConnectedModal'
import {ForgeIntegrationHasValidAccess} from '../forge-integration.entities'

export type ForgeIntegrationModalWrapperProps = {
	hasValidForgeAccess: ForgeIntegrationHasValidAccess | undefined
	isProjectConnected: boolean | undefined
	open: boolean
	onClose: () => void
	children: ReactNode
}

export const ForgeIntegrationModalWrapper = ({
	hasValidForgeAccess,
	isProjectConnected,
	open,
	onClose,
	children,
}: ForgeIntegrationModalWrapperProps) => {
	return !hasValidForgeAccess?.hasAccess ? (
		<ForgeIntegrationUserNotLoggedInModal open={open} onClose={onClose} />
	) : isProjectConnected ? (
		<ForgeIntegrationProjectNotConnectedModal open={open} onClose={onClose} />
	) : (
		<>{children}</>
	)
}
