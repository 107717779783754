import {useQuery} from 'react-query'
import {VersionEntity} from '../../entities/version.entities'
import {AxiosError} from 'axios'
import {fetchVersionsForStatus} from '../Classification/api/classification.api'
import {AnalysisViewEntityPopulated} from './analysis-view.entities'
import {AnalysisVersionMap} from '../../reducers/classification.slice'
import {hasIncrementalDelivery} from './analysis-view.helpers'

export const useAnalysisViewVersionsForStatus = (
	analysisView: AnalysisViewEntityPopulated | null,
	analysisVersionMap: AnalysisVersionMap,
) => {
	const {data: analysisViewVersions} = useQuery<VersionEntity[] | null, AxiosError>(
		['classifications', 'analysis-view-versions-for-status', analysisView?._id],
		() => analysisView && fetchVersionsForStatus(analysisView._id, analysisVersionMap),
		{enabled: !!(analysisView && hasIncrementalDelivery(analysisView))},
	)
	return analysisViewVersions || null
}
