import {srApiV3} from './base.api.v3'
import {PermissionsEnum} from '../entities/auth'

export const fetchUserProfile = async () => {
	const response = await srApiV3.get('users/current/projects/all')
	return response.data
}

export const fetchCurrentUser = async () => {
	const response = await srApiV3.get('users/current')
	return response.data
}

export async function fetchCurrentUserHasPermissionOnResource(
	id: string,
	permission: PermissionsEnum,
): Promise<boolean> {
	return (await srApiV3.get(`users/current/resources/${id}/permissions/${permission}`)).data
}
