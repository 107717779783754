import {Draft, PayloadAction} from '@reduxjs/toolkit'
import {PaginationState} from './base.pagination.state'

export type FilterState<Filter extends Record<string, unknown>> = {
	filter: Filter
}

export function buildInitialFilterState<State extends FilterState<Record<string, unknown>>>(
	initialFilter: State['filter'],
): Pick<State, keyof FilterState<Record<string, unknown>>> {
	return {
		filter: initialFilter,
	}
}

export function buildFilterStateReducer<State extends FilterState<Record<string, unknown>>>(initialState: State) {
	return {
		setFilter: (state: Draft<State>, action: PayloadAction<{filter: State['filter']}>) => {
			state.filter = action.payload.filter
		},
		setPartialFilter: (state: Draft<State>, action: PayloadAction<Partial<State['filter']>>) => {
			state.filter = {...state.filter, ...action.payload}
		},
		resetFilter: (state: Draft<State>) => {
			state.filter = initialState.filter
		},
	}
}

export function buildFilterStateWithPaginationReducer<
	State extends FilterState<Record<string, unknown>> & PaginationState
>(initialState: State) {
	return {
		setFilter: (state: Draft<State>, action: PayloadAction<{filter: State['filter']}>) => {
			state.filter = action.payload.filter
			state.pagination.currentPage = 0
		},
		setPartialFilter: (state: Draft<State>, action: PayloadAction<Partial<State['filter']>>) => {
			state.filter = {...state.filter, ...action.payload}
			state.pagination.currentPage = 0
		},
		resetFilter: (state: Draft<State>) => {
			state.filter = initialState.filter
			state.pagination.totalRecords = 0
			state.pagination.currentPage = 0
		},
	}
}
