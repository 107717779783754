import {srProjectApiV3} from '../../../api/project.api.v3'
import {FilterPresetEntity, normalizeFilterPreset} from '../entities/filter-preset.entities'

type SaveDocument = Pick<FilterPresetEntity, 'scope' | 'name' | 'filters'>

function toSaveDocument(preset: Partial<FilterPresetEntity>): SaveDocument {
	const normalized = normalizeFilterPreset(preset)
	return {name: normalized.name!, scope: normalized.scope!, filters: normalized.filters!}
}

const route = (id = '') => 'filter-presets/' + id

export const fetchFilterPresets = async (): Promise<FilterPresetEntity[]> => {
	const response = await srProjectApiV3.get(route())
	return response.data
}

export const postFilterPreset = async (preset: Partial<FilterPresetEntity>) => {
	const response = await srProjectApiV3.post(route(), toSaveDocument(preset))
	return response.data
}

export const putFilterPreset = async (preset: Partial<FilterPresetEntity>) => {
	const response = await srProjectApiV3.put(route(preset._id), toSaveDocument(preset))
	return response.data
}

export const deleteFilterPreset = async (id: string) => {
	await srProjectApiV3.delete(route(id))
	return id
}
