import React from 'react'
import {Anchor, Box, Text} from 'grommet'
import {goTo} from '../../../../../router/history'
import {SR_COLOR_ACCENT_3, SRHeading} from 'sr-react-commons'
import {useManagementDashboardState} from '../../management-dashboad.slice'
import {pageName} from '../../management-dashboard-page.utils'
import {useParams} from 'react-router-dom'
import {Id} from '../../../../../entities/value-objects'
import {useAnalysisRequest} from '../../../../AnalysisRequest/UseAnalysisRequest'

export const ManagementDashboardHeader = () => {
	const {page, project, subPage} = useManagementDashboardState()
	const {analysisRequestId} = useParams<{analysisRequestId: Id}>()

	const {data: analysisRequest} = useAnalysisRequest(analysisRequestId)
	return (
		<Box gap={'xsmall'} flex={false} pad={{bottom: '2px'}}>
			<Text>
				<Anchor color={SR_COLOR_ACCENT_3} onClick={() => goTo('/select-project')}>
					My Projects
				</Anchor>
				{` / ${project?.name} / `}
				{!subPage ? null : (
					<Anchor color={SR_COLOR_ACCENT_3} onClick={() => goTo(`/manage/${project?._id}/${page}`)}>
						{pageName(page!)}
					</Anchor>
				)}
			</Text>
			<SRHeading level={2}>
				{analysisRequest && analysisRequestId ? analysisRequest?.analysisName : pageName(page!, subPage)}
			</SRHeading>
		</Box>
	)
}
