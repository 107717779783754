import React from 'react'
import {Box, Text} from 'grommet'
import _ from 'lodash'
import {format} from 'date-fns'
import {Radial} from 'grommet-icons'
import {AnalysisViewEntityPopulated} from '../analysis-view.entities'
import {SRChip, SRDeveloperIcon, SRTooltip, SROverline, SRSecondaryChip, SRRadialFillIcon} from 'sr-react-commons'
import {useClassificationState} from '../../../reducers/classification.slice'
import {useSelectedVersionsForAnalysisView} from '../useSelectedVersionsForAnalysisView'
import {versionForAnalysis} from '../analysis-view.helpers'
import {AnalysisSelectorFilter} from '../../../reducers/analysisSelectorFilter.slice'

const DeveloperVersionInfoTooltip = ({analysisView}: {analysisView: AnalysisViewEntityPopulated}) => {
	const {analysisVersionMap} = useClassificationState()
	const selectedVersions = useSelectedVersionsForAnalysisView(analysisView, analysisVersionMap)
	const displayTooltip =
		selectedVersions &&
		(selectedVersions.some(v => v.status === 'dev') ||
			analysisView.analyses.some(a => analysisVersionMap.hasOwnProperty(a._id)))
	const tooltipText = (!selectedVersions
		? []
		: analysisView.analyses.map(analysis => {
				const version = versionForAnalysis(analysis, selectedVersions)
				const rowName = analysisView.analyses.length > 1 ? `${analysis.name} - ` : ''
				return `${rowName}${version?.versionNumber} - ${version?.status === 'stable' ? 'Published' : 'Unpublished'}`
		  })
	).join('\n')
	return !displayTooltip ? null : (
		<SRTooltip tooltipText={tooltipText} width={200} arrowPos="center">
			<SRDeveloperIcon size="small" color="neutral-1" />
		</SRTooltip>
	)
}

export function AnalysisViewSelector(props: {
	analysisViews: AnalysisViewEntityPopulated[]
	selectedAnalysisViewId: string | null
	onSelectAnalysisView: (view: AnalysisViewEntityPopulated) => void
	sort: 'asc' | 'desc'
	filter: AnalysisSelectorFilter
	hasDeveloperPermissions: boolean
}) {
	const filteredAnalysisViews = props.analysisViews.filter(
		analysisView =>
			!props.filter.analysisName || analysisView.name.toLowerCase().includes(props.filter.analysisName.toLowerCase()),
	)
	return (
		<Box flex={false}>
			{!filteredAnalysisViews.length ? (
				<Box pad={'small'}>No matches found</Box>
			) : (
				<Box overflow={'auto'}>
					{_.sortBy(
						filteredAnalysisViews,
						analysisView => new Date(analysisView.scanDate).getTime() * (props.sort === 'asc' ? 1 : -1),
					).map(analysisView => (
						<Box
							id={`analysis-view-${analysisView.id}-button`}
							key={analysisView._id}
							background={props.selectedAnalysisViewId === analysisView._id ? 'neutral-2' : undefined}
							onClick={() => props.onSelectAnalysisView(analysisView)}
							direction="row"
							hoverIndicator
							fill
						>
							<Box pad={{vertical: 'xsmall', horizontal: 'small'}} gap={'xsmall'} align={'center'}>
								<Box alignSelf={'stretch'} align={'center'} pad={{top: '4px'}}>
									{props.selectedAnalysisViewId === analysisView._id ? (
										<SRRadialFillIcon color={'brand-main'} />
									) : (
										<Radial />
									)}
								</Box>
								<Box background="brand" width={'1px'} flex="grow" />
							</Box>
							<Box fill pad={{vertical: 'xsmall', left: 'xsmall', right: 'small'}} gap="xsmall">
								<Box direction={'row'} gap={'xsmall'}>
									<strong>{format(new Date(analysisView.scanDate), 'dd/MM/yy')}</strong>
									{analysisView.status !== 'in_progress' ? null : (
										<SRChip text={'Processing'} background={'brand-main'} />
									)}
									{props.hasDeveloperPermissions &&
									analysisView?.deliveryType === 'Normal' &&
									analysisView.status !== 'ready' ? (
										<SRChip text={'Unpublished'} background={'neutral-1'} />
									) : null}
									{props.hasDeveloperPermissions && <DeveloperVersionInfoTooltip analysisView={analysisView} />}
								</Box>
								<Box gap="xsmall">
									<Box>
										<SROverline label={'Analysis description'} />
										<Text>{analysisView.name}</Text>
									</Box>
									<Box>
										<SROverline label={'BIM version'} />
										<SRSecondaryChip text={`Version ${analysisView.model.modelVersion}`} />
									</Box>
								</Box>
							</Box>
						</Box>
					))}
				</Box>
			)}
		</Box>
	)
}
