import ReactDOM from 'react-dom'
import React from 'react'
import {Provider} from 'react-redux'
import {Grommet} from 'grommet'
import {theme} from '../../../theme'
import {history} from '../../../router/history'
import {Router} from 'react-router-dom'
import {QueryClientProvider} from 'react-query'
import {store} from '../../../store/store'
import {defaultQueryClient} from '../../../utilities/query-client.util'
import {PropertiesToolTipContainer} from './Viewing.Extension.PropertiesTooltipContainer'
import {FeatureFlagsProvider} from '../../../features/FeatureFlags/FeatureFlagsProvider'

const Autodesk = window.Autodesk

export default class PropertiesTooltipPanel extends Autodesk.Viewing.UI.DockingPanel {
	private readonly DOMContent: HTMLDivElement
	reactMounted: boolean = false
	constructor(container: HTMLElement, id: string, title?: string, options?: any) {
		super(container, id, title as string, options)

		this.container.style.display = 'none'
		this.container.style.zIndex = 1
		this.DOMContent = document.createElement('div')
		this.DOMContent.className = 'docking-panel-content'
		this.container.appendChild(this.DOMContent)
	}

	setVisible(show: boolean) {
		super.setVisible(show)
		if (show) {
			ReactDOM.render(
				<Provider store={store}>
					<Router history={history}>
						<Grommet style={{height: '100%'}} theme={theme}>
							<QueryClientProvider client={defaultQueryClient}>
								<FeatureFlagsProvider>
									<PropertiesToolTipContainer />
								</FeatureFlagsProvider>
							</QueryClientProvider>
						</Grommet>
					</Router>
				</Provider>,
				this.DOMContent,
			)
			this.reactMounted = true
		} else {
			if (this.reactMounted) {
				ReactDOM.unmountComponentAtNode(this.DOMContent)
				this.reactMounted = false
			}
		}
	}

	initialize(): void {}
}
