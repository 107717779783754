import React, {Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useRef, useState} from 'react'
import {Box} from 'grommet'
import {getS3KeyForPath, loadS3Image} from '../../../utilities/storageUtilities'
import {Stack} from 'grommet/es6'
import {Viewsphere} from '../../../reducers/classification'
import {Spinner} from '../../CommonsCandidate/Loading/Spinner'
import {createBoundingBox, disposeThreeNode, getCameraTargetFromOrientation} from '../../Viewer/Viewer-helper'
import {useMounted} from '../../../hooks/useMounted'
import {ViewerState} from '../../../reducers/viewerReducer'
import {normalizeExtremeLatitudeValue} from './PhotoSphere.utilities'

declare global {
	interface Window {
		photoSphereScene: THREE.Scene
	}
}

const THREE = window.THREE

export interface PhotoSphereRendererProps {
	tenantId: string
	viewsphere: Viewsphere
	contextMode: ViewerState['properties']['assetsMode']
	highlightElement: boolean
	cameraOrientation: [number, number]
	setCameraOrientation: React.Dispatch<React.SetStateAction<[number, number]>>
	cameraFov: number
	setCameraFov: React.Dispatch<React.SetStateAction<number>>
}

const sphereRadius = 500

const fov = 45
const near = 0.1
const far = 1000

const _renderers: {[k in ViewerState['properties']['assetsMode']]?: THREE.WebGLRenderer} = {}
const rendererByContext = (context: ViewerState['properties']['assetsMode']): THREE.WebGLRenderer => {
	if (!_renderers[context]) {
		const r = new THREE.WebGLRenderer({preserveDrawingBuffer: true})
		r.domElement.id = `${context}PhotoSphereCanvas`
		r.setSize(100, 100, false)
		r.setPixelRatio(window.devicePixelRatio)
		_renderers[context] = r
	}
	return _renderers[context]!
}

export function PhotoSphereRenderer({
	tenantId,
	viewsphere,
	contextMode = 'popup',
	highlightElement,
	cameraOrientation,
	setCameraOrientation,
	cameraFov,
	setCameraFov,
}: PhotoSphereRendererProps) {
	const mount = useRef<HTMLDivElement>(null)
	const [grabPosition, setGrabPosition] = useState<[number, number] | null>(null)
	const [grabOrientation, setGrabOrientation] = useState<[number, number] | null>(null)
	const sceneRef = useRef<THREE.Scene>(new THREE.Scene())
	const boundingBoxRef: MutableRefObject<THREE.Line | null> = useRef<THREE.Line>(null)
	const cameraRef: MutableRefObject<THREE.PerspectiveCamera> = useRef<THREE.PerspectiveCamera>(
		new THREE.PerspectiveCamera(fov, undefined, near, far),
	)
	const renderer = rendererByContext(contextMode)
	const {isLoaded} = useImageAs360Texture(viewsphere, sceneRef, tenantId, cameraRef, setCameraOrientation)

	useEffect(() => {
		setupRenderer(mount as MutableRefObject<HTMLDivElement>, renderer)
	}, [mount, renderer])

	useEffect(() => {
		updateCamera(cameraRef, cameraOrientation, cameraFov)
	}, [cameraOrientation, cameraFov, cameraRef, isLoaded])

	useEffect(() => {
		updateBoundingBox(sceneRef, boundingBoxRef, viewsphere, highlightElement, isLoaded)
	}, [viewsphere, boundingBoxRef, highlightElement, isLoaded])

	useEffect(() => {
		const stop = startAnimation(cameraRef, sceneRef, mount as MutableRefObject<HTMLDivElement>, isLoaded, renderer)
		if (window.Cypress) {
			window.photoSphereScene = sceneRef.current
		}
		return () => {
			stop()
		}
	}, [isLoaded, renderer, cameraRef, sceneRef, mount])

	const {onZoom, onMouseDown, onMouseMove, onMouseUp} = useMouseCallbacks(
		setGrabPosition,
		setGrabOrientation,
		cameraOrientation,
		grabPosition,
		grabOrientation,
		setCameraOrientation,
		setCameraFov,
	)

	return (
		<Box fill={true}>
			<Stack fill={true}>
				<Box
					style={{
						cursor:
							'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAt1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD///8AAAAzMzP6+vri4uISEhKKioqtra2dnZ2EhIR9fX10dHRkZGQdHR3t7e3Hx8e5ubm1tbWoqKhWVlZKSko4ODgICAjv7+/o6OjMzMyxsbFOTk4pKSkXFxcEBAT29vbW1tZ6enpISEgLCwvhzeX+AAAAGXRSTlMANRO0nHRJHfnskIxQRKh89syDVwTWZjEJxPFEswAAAOFJREFUKM+1j+lygkAQhIflEAJe0Rw9u4CCeKKoSTTX+z9XoMJWWeX+ssrvZ3f19DQ5zOw/0DUMQPlmQ72bE2adBp8/Rp3CQUi3ILx+bxj4fjDs9T1Bmo6bbPPN8aDU4bjJt4nb+de789kSFyxn826jW3ICLNZZKU8nWWbrBTCRVm04U8TpjquRFf1Go0d7l8aYOrUR7FGEFr1S9LGymwthgX2gE/Kl0cHPOtF2xOWZ5QpIC93RflW4InkDoPRXesd5LJIMQPzV7tCMa7f6BvhJL79AVDmYTNQ1NhnxbI/uwB8H5Bjd4zQPBAAAAABJRU5ErkJggg=="), auto',
					}}
					fill={true}
					ref={mount}
					onMouseDown={onMouseDown}
					onWheel={onZoom}
					onMouseMove={onMouseMove}
					onMouseUp={onMouseUp}
					onMouseLeave={onMouseUp}
				/>
				{!isLoaded ? (
					<Box
						data-testid={'photoSphereSpinner'}
						fill={true}
						alignSelf={'center'}
						background={'light-4'}
						justify={'center'}
					>
						<Spinner />
					</Box>
				) : null}
			</Stack>
		</Box>
	)
}

function useMouseCallbacks(
	setGrabPosition: Dispatch<SetStateAction<[number, number] | null>>,
	setGrabOrientation: Dispatch<SetStateAction<[number, number] | null>>,
	cameraOrientation: [number, number],
	grabPosition: [number, number] | null,
	grabOrientation: [number, number] | null,
	setCameraOrientation: Dispatch<SetStateAction<[number, number]>>,
	setCameraFov: Dispatch<SetStateAction<number>>,
) {
	const onZoom = useCallback(
		event => {
			updateZoom(setCameraFov, event)
		},
		[setCameraFov],
	)

	const onMouseDown = useCallback(
		event => {
			startGrab(event, setGrabPosition, setGrabOrientation, cameraOrientation)
		},
		[cameraOrientation, setGrabOrientation, setGrabPosition],
	)

	const onMouseMove = useCallback(
		event => {
			updateCameraOrientation(event, grabPosition, grabOrientation, setCameraOrientation)
		},
		[grabOrientation, grabPosition, setCameraOrientation],
	)

	const onMouseUp = useCallback(() => {
		stopGrab(setGrabPosition, setGrabOrientation)
	}, [setGrabOrientation, setGrabPosition])
	return {onZoom, onMouseDown, onMouseMove, onMouseUp}
}

function useImageAs360Texture(
	viewsphere: {imagePath: string; elementCentroid: [number, number]},
	sceneRef: React.MutableRefObject<THREE.Scene>,
	tenantId: string,
	cameraRef: React.MutableRefObject<THREE.PerspectiveCamera>,
	setCameraOrientation: React.Dispatch<React.SetStateAction<[number, number]>>,
) {
	const {
		imagePath,
		elementCentroid: [centroidX, centroidY],
	} = viewsphere
	const isMounted = useMounted()
	const imagePathRef = useRef<string>(imagePath)
	const [isLoaded, setIsLoaded] = useState(false)
	useEffect(() => {
		const scene = sceneRef.current
		updatePhotoSphere(tenantId, sceneRef, imagePathRef, setIsLoaded, imagePath, isMounted)
		return () => {
			cleanUpScene(scene)
		}
	}, [imagePath, isMounted, sceneRef, tenantId])

	useEffect(() => {
		if (isLoaded) {
			const lat = normalizeExtremeLatitudeValue(centroidX)
			setCameraOrientation([lat, centroidY])
		}
	}, [cameraRef, centroidX, centroidY, isLoaded, setCameraOrientation])
	return {isLoaded}
}

function setupRenderer(mount: React.MutableRefObject<HTMLDivElement>, renderer: THREE.WebGLRenderer) {
	if (mount.current) {
		mount.current.appendChild(renderer.domElement)
	}
}

function updateCamera(
	cameraRef: React.MutableRefObject<THREE.PerspectiveCamera>,
	cameraOrientation: number[],
	cameraFov: number,
) {
	//UPDATES PHOTOSPHERE TARGET
	const camera = cameraRef.current

	if (camera) {
		const [lat, lon] = cameraOrientation

		//Converts Orientation to Target for PhotoSphere Cam
		const target = getCameraTargetFromOrientation(lat, lon).multiplyScalar(sphereRadius)
		camera.lookAt(target)
		camera.fov = cameraFov
	}
}

function cleanUpScene(scene: THREE.Scene) {
	if (scene) {
		for (let i = scene.children.length - 1; i >= 0; i--) {
			disposeThreeNode(scene.children[i])
			scene.remove(scene.children[i])
		}
	}
}

function updatePhotoSphere(
	tenantId: string,
	sceneRef: React.MutableRefObject<THREE.Scene>,
	imagePathRef: React.MutableRefObject<string>,
	setIsLoaded: Dispatch<SetStateAction<boolean>>,
	imagePath: string,
	isMounted: () => boolean,
) {
	if (!tenantId) {
		return
	}
	const scene = sceneRef.current
	const textureLoader = new THREE.TextureLoader()
	setIsLoaded(false)
	imagePathRef.current = imagePath

	textureLoader.setCrossOrigin('Anonymous')

	cleanUpScene(scene)

	// TODO move this to a service worker
	loadS3Image(getS3KeyForPath(tenantId, imagePath)).then(imgUrl => {
		if (!isMounted() || imagePathRef.current !== imagePath) {
			return
		}
		textureLoader.load(imgUrl, texture => {
			if (!isMounted() || imagePathRef.current !== imagePath) {
				return
			}
			const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 60, 40)
			sphereGeometry.applyMatrix(new THREE.Matrix4().makeScale(-1, 1, 1))

			const viewsphereTexture = new THREE.MeshBasicMaterial({
				map: texture,
			})

			const viewSphereMesh = new THREE.Mesh(sphereGeometry, viewsphereTexture)
			viewSphereMesh.name = 'photoSphereMesh'
			scene.add(viewSphereMesh)
			setIsLoaded(true)
		})
	})
}

function updateBoundingBox(
	sceneRef: React.MutableRefObject<THREE.Scene>,
	boundingBoxRef: React.MutableRefObject<THREE.Line | null>,
	viewsphere: Viewsphere,
	highlightElement: boolean,
	isLoaded: boolean,
) {
	const scene = sceneRef.current

	if (scene) {
		if (boundingBoxRef.current) {
			scene.remove(boundingBoxRef.current)
		}
		const boundingBox = createBoundingBox(viewsphere.elementBoundaries.map(n => n.toString()))
		boundingBox.visible = highlightElement && isLoaded
		boundingBox.name = 'boundingBoxLine'
		scene.add(boundingBox)
		boundingBoxRef.current = boundingBox
	}
}

function updateZoom(setCameraFov: Dispatch<SetStateAction<number>>, event: any) {
	// WebKit
	const nativeEvent = event.nativeEvent
	let update = 0
	if (nativeEvent.wheelDeltaY) {
		update = -(nativeEvent.wheelDeltaY * 0.05)
		// Opera / Explorer 9
	} else if (nativeEvent.wheelDelta) {
		update = -(nativeEvent.wheelDelta * 0.05)
		// Firefox
	} else if (nativeEvent.detail) {
		update = nativeEvent.detail
	}

	// Clamp FOV values to avoid overflows
	setCameraFov(current => Math.min(Math.max(current + update, 5), 120))
}

function startGrab(
	event: any,
	setGrabPosition: Dispatch<SetStateAction<[number, number] | null>>,
	setGrabOrientation: Dispatch<SetStateAction<[number, number] | null>>,
	cameraOrientation: [number, number] | null,
) {
	const nativeEvent = event.nativeEvent
	setGrabPosition([nativeEvent.clientX, nativeEvent.clientY])
	setGrabOrientation(cameraOrientation)
}

function updateCameraOrientation(
	event: any,
	grabPosition: [number, number] | null,
	grabOrientation: [number, number] | null,
	setCameraOrientation: Dispatch<SetStateAction<[number, number]>>,
) {
	const nativeEvent = event.nativeEvent
	if (grabPosition && grabOrientation) {
		const [grabX, grabY] = grabPosition
		const [grabLat, grabLon] = grabOrientation
		let lat = (nativeEvent.clientY - grabY) * 0.1 + grabLat
		lat = Math.max(-85, Math.min(85, lat))
		const lon = ((grabX - nativeEvent.clientX) * 0.1 + grabLon) % 360
		setCameraOrientation([lat, lon])
	}
}

function stopGrab(
	setGrabPosition: (value: ((prevState: any) => undefined) | any) => void,
	setGrabOrientation: (value: ((prevState: any) => undefined) | any) => void,
) {
	setGrabPosition(null)
	setGrabOrientation(null)
}

function startAnimation(
	cameraRef: React.MutableRefObject<THREE.PerspectiveCamera>,
	sceneRef: React.MutableRefObject<THREE.Scene>,
	mount: React.MutableRefObject<HTMLDivElement>,
	isLoaded: boolean,
	renderer: THREE.WebGLRenderer,
) {
	let frameId: number
	const camera = cameraRef.current
	const scene = sceneRef.current
	scene.add(camera)

	const animate = () => {
		const canvas = mount.current
		if (isLoaded && canvas && !renderer.context.isContextLost()) {
			const mountWidth = canvas.offsetWidth
			const mountHeight = canvas.offsetHeight
			camera.aspect = mountWidth / mountHeight
			camera.updateProjectionMatrix()

			renderer.setSize(mountWidth, mountHeight, false)
			renderer.render(scene, camera)
			frameId = requestAnimationFrame(animate)
		}
	}

	const start = () => {
		if (!frameId) {
			frameId = requestAnimationFrame(animate)
		}
	}

	const stop = () => {
		if (frameId) {
			cancelAnimationFrame(frameId)
			frameId = -1
		}
	}

	if (isLoaded) {
		start()
	} else {
		stop()
	}
	return stop
}
