import {persistReducer, persistStore} from 'redux-persist'
import {configureStore, EnhancedStore, ThunkAction} from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import {Action, Reducer} from 'redux'
import rootReducer, {RootState} from '../reducers'
import storage from 'redux-persist/lib/storage'
import {createFilter} from 'redux-persist-transform-filter'

export type AppThunk = ThunkAction<void, any, unknown, Action<string>>

export type RootStore = EnhancedStore<RootState>

const persistConfig = {
	key: 'root',
	storage,
	transforms: [createFilter('userProfileState', ['selectedProject'])],
	whitelist: ['userProfileState'],
}

const persistedReducer: Reducer<RootState> = persistReducer(persistConfig, rootReducer as Reducer<RootState>)

const store: RootStore = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
	devTools: false,
})

const persistor = persistStore(store)

export {store, persistor}
