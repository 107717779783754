import {SRIconButton, SRRightPanelBody, SRRightPanelContainer} from 'sr-react-commons'
import {RightPanelHeader} from '../../components/CommonsCandidate/Layout/RightPanel/RightPanelHeader'
import React from 'react'
import {InsightsDashboardBody} from './components/InsightsDashboardBody'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../reducers'
import {Box} from 'grommet'
import NumberOfElements from '../../components/DataTableContainer/NumberOfElements'
import {
	classificationSlice,
	useClassificationPageState,
	useClassificationSortState,
} from '../../reducers/classification.slice'
import useDebounce from '../../components/DataTableContainer/useDebounce'
import {config} from '../../config'
import {useQueryClassificationTableData} from '../Classification/data-management/useQueryClassificationTableData'
import {selectorClassificationState} from '../../selectors/classification.selectors'
import {RotateLeft} from 'grommet-icons'
import {useFilterStateForMode} from '../FilterPresets/components/filters/Filters'
import {FiltersEntity} from '../FilterPresets/entities/filter-preset.entities'
import {filtersAreEmpty} from './InsightDashboard-helper'

export function InsightsDashboard() {
	const FILTER_MODE = 'overview'
	const {filter} = useFilterStateForMode(FILTER_MODE)
	const {selectedAnalysisView, analysisVersionMap} = useSelector(selectorClassificationState)
	const pageState = useClassificationPageState()
	const sortState = useClassificationSortState()
	const project = useSelector((state: RootState) => state.userProfileState.selectedProject!)
	const debouncedFilters = useDebounce(filter as FiltersEntity, config.sr.debouncingTime)
	const dispatch = useDispatch()
	useQueryClassificationTableData(
		selectedAnalysisView!,
		analysisVersionMap,
		pageState,
		sortState,
		debouncedFilters,
		project,
	)
	const numberOfElements = useSelector<RootState, number>(state => state.classification.pagination.totalRecords)
	const resetFilters = () => {
		dispatch(classificationSlice.actions.resetFilter())
	}
	const canResetFilters = filtersAreEmpty(filter)
	return (
		<SRRightPanelContainer>
			<RightPanelHeader title="Insights" gap={'none'}>
				<Box direction={'row'}>
					<NumberOfElements
						resourceName={'classification'}
						numberOfElements={numberOfElements}
						margin={{bottom: '3px', horizontal: 'xsmall'}}
					/>
					<SRIconButton icon={<RotateLeft size="large" />} onClick={resetFilters} disabled={canResetFilters} />
				</Box>
			</RightPanelHeader>
			<SRRightPanelBody>
				<InsightsDashboardBody resetFilters={resetFilters} />
			</SRRightPanelBody>
		</SRRightPanelContainer>
	)
}
