import {buildAxios} from '../../api/api-factory'
import {config} from '../../config'
import {Id} from '../../entities/value-objects'
import {ModelEntity} from './model.entities'

export const buildModelsProjectApi = (projectId: string) =>
	buildAxios(`${config.sr.backendUrl}model/v3/projects/${projectId}`)

const path = (id?: Id, tail?: string) => `/models/${id ? `${encodeURIComponent(id)}/` : ''}${tail ? `${tail}/` : ''}`

export const fetchActiveModel = async (projectId: string): Promise<ModelEntity | null> => {
	const api = buildModelsProjectApi(projectId)
	return (await api.get(path(undefined, 'active'))).data
}

export const fetchModelById = async (projectId: string, modelId: ModelEntity['_id']): Promise<ModelEntity> => {
	const api = buildModelsProjectApi(projectId)
	return (await api.get(path(modelId))).data
}
