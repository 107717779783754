import {Box, Layer, Text} from 'grommet'
import {SRHeading} from 'sr-react-commons'
import {ProjectFileUploader} from './ProjectFileUploader'
import React from 'react'
import {SRUl} from '../../../components/List/SRUl'
import {UserProjectModelType} from '../../../reducers/userProfile'
import {SupportLink} from './SupportLink'

export function ModelFileUploadHelpText() {
	return (
		<Box gap="small">
			<Box>
				<SRHeading level="5">Upload BIM files</SRHeading>
				<Text>Please upload the BIM files to compare against the scan files.</Text>
			</Box>
			<Box gap={'xsmall'}>
				<SRHeading level="6">BIM file requirements:</SRHeading>
				<SRUl>
					<li>The model format should be IFC (2x3), RVT, NWC, or NWD.</li>
					<li>
						Please make sure that categories are well labeled and clear. We will be using element types to organize your
						data.
					</li>
				</SRUl>
				<SupportLink title={'If you have any doubts about the BIM file requirements please'} />
			</Box>
		</Box>
	)
}

export function ModelFileUploadModal(props: {
	projectId: string
	projectType: UserProjectModelType
	open: boolean
	onClose: () => void
}) {
	const acceptedFileTypes = ['.ifc', '.nwd', '.nwc', '.rvt']
	return props.open ? (
		// TODO refoctor for better code sharing
		<Layer modal animation="none">
			<Box pad="medium" width="416px" gap={'medium'}>
				<SRHeading level="4">Upload files</SRHeading>
				<ProjectFileUploader
					projectId={props.projectId}
					visibility={'model'}
					acceptedFileTypes={acceptedFileTypes}
					onCancel={props.onClose}
					onFinish={props.onClose}
					dropzoneText={
						<>
							Drop BIM model files to upload or
							<br />
						</>
					}
					helpText={<ModelFileUploadHelpText />}
				/>
			</Box>
		</Layer>
	) : null
}
