import React, {useState} from 'react'
import {Auth} from 'aws-amplify'
import {Anchor, Box, Text} from 'grommet'
import {AuthenticatorForm} from './AuthenticatorForm'
import {Spinner} from '../Loading/Spinner'
import {SRBrandButton, SRHeading, SRPasswordInput, SRTextInput, SRFormErrorMessage} from 'sr-react-commons'

export const PASSWORD_MIN_LENGTH = 8

export function SRForgotPassword(props: {authState?: string; onStateChange?: (newState: string) => void}) {
	if (!props.authState || !props.onStateChange) throw new Error('Must be used inside an authenticator component')
	const {authState, onStateChange} = props
	const [email, setEmail] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [inputPasswordError, setInputPasswordError] = useState('')
	const [inputConfirmPasswordError, setInputConfirmPasswordError] = useState('')
	const [codeSent, setCodeSent] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleErrorMessage = (error: Error & {code: string}) => {
		error.code === 'UserNotFoundException' || error.code === 'CodeMismatchException'
			? setErrorMessage('Invalid verification code, please try again.')
			: setErrorMessage(error.message)
	}

	const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const formElements = event.currentTarget.elements as typeof event.currentTarget.elements & {
			emailInput: HTMLInputElement
		}
		const email = formElements.emailInput.value
		Auth.forgotPassword(email)
			.then(response => {
				setCodeSent(true)
				setErrorMessage('')
			})
			.catch(() => {
				// It is allowed to turn the page so as not to reveal sensitive information.
				setCodeSent(true)
			})
	}
	const handleCodePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoading(true)
		const formElements = event.currentTarget.elements as typeof event.currentTarget.elements & {
			codeInput: HTMLInputElement
			passwordInput: HTMLInputElement
			confirmPasswordInput: HTMLInputElement
		}
		const code = formElements.codeInput.value
		const password = formElements.passwordInput.value.trim()
		const confirmPassword = formElements.confirmPasswordInput.value.trim()

		if (password.length < PASSWORD_MIN_LENGTH) {
			return setInputPasswordError('Your password should be at least 8 characters long')
		} else setInputPasswordError('')

		if (password === confirmPassword) {
			await Auth.forgotPasswordSubmit(email, code, password)
				.then(() => {
					setCodeSent(false)
					setErrorMessage('')
					setInputPasswordError('')
					setInputConfirmPasswordError('')
					onStateChange('signIn')
					setEmail('')
				})
				.catch((err: any) => {
					handleErrorMessage(err)
					setInputPasswordError('')
					setInputConfirmPasswordError('')
				})
		} else {
			setInputConfirmPasswordError("The passwords don't match.")
		}
		setLoading(false)
	}
	const goToLogin = (
		<Anchor alignSelf="center" color={'accent-3'} onClick={() => onStateChange('signIn')}>
			Back to login
		</Anchor>
	)

	return authState !== 'forgotPassword' ? null : codeSent ? (
		<AuthenticatorForm onSubmit={handleCodePasswordSubmit}>
			<SRHeading level="5">Change your password</SRHeading>
			<Box>
				<SRHeading level="6">Enter verification code</SRHeading>
				<Text wordBreak="normal">We'll send a verification code to your email.</Text>
				<Text wordBreak="normal">If you cannot find it please check your spam folder or</Text>
				<Anchor
					alignSelf="start"
					color={'accent-3'}
					onClick={() => {
						setCodeSent(false)
						setErrorMessage('')
					}}
				>
					reenter the email
				</Anchor>
			</Box>
			{errorMessage ? SRFormErrorMessage(errorMessage) : null}
			<Box gap="medium" alignSelf={'stretch'}>
				<Box gap="small">
					<Box alignSelf={'stretch'}>
						<label htmlFor="codeInput">Verification code</label>
						<SRTextInput id={'codeInput'} size="medium" type="text" placeholder="Enter verification code" required />
					</Box>
					<Box>
						<SRHeading level="6">Create new password</SRHeading>
						<Text wordBreak="normal">Make sure your password is at least 8 characters long.</Text>
					</Box>
					<Box>
						<label htmlFor="passwordInput">New Password</label>
						<SRPasswordInput
							error={inputPasswordError}
							id="passwordInput"
							size="medium"
							autoComplete={'new-password'}
							placeholder={'Enter your new password'}
						/>
					</Box>
					<Box>
						<label htmlFor="confirmPasswordInput">Confirm your new password</label>
						<SRPasswordInput
							error={inputConfirmPasswordError}
							id="confirmPasswordInput"
							size="medium"
							autoComplete={'new-password'}
							placeholder={'Type again your password'}
						/>
					</Box>
				</Box>
				<SRBrandButton
					disabled={loading}
					icon={loading ? <Spinner size={'xsmall'} hideIfGlobalLoader={false} /> : undefined}
					label={loading ? '' : 'Next'}
					size={'medium'}
					type="submit"
				/>
			</Box>
			{goToLogin}
		</AuthenticatorForm>
	) : (
		<AuthenticatorForm onSubmit={handleEmailSubmit}>
			<Box>
				<SRHeading level="5">Forgot your password?</SRHeading>
				<Text wordBreak="normal">Don't worry, it happens to the best of us.</Text>
				<Text wordBreak="normal">We'll send you a verification code to your email.</Text>
			</Box>
			{errorMessage ? SRFormErrorMessage(errorMessage) : null}
			<Box gap="medium" alignSelf={'stretch'}>
				<Box>
					<label htmlFor="emailInput">Email</label>
					<SRTextInput
						id={'emailInput'}
						size="medium"
						type="email"
						value={email}
						onChange={e => setEmail(e.target.value)}
						placeholder={'Enter your email'}
						required
					/>
				</Box>
				<SRBrandButton label={'Get verification code'} size={'medium'} type="submit" />
			</Box>
			{goToLogin}
		</AuthenticatorForm>
	)
}
