import React, {ReactNode} from 'react'
import {Box, BoxTypes} from 'grommet'

export function CardContainer(props: {children: ReactNode; gap?: BoxTypes['gap']}) {
	return (
		<Box background="white" round="xsmall" elevation="small" pad="small" gap={props.gap}>
			{props.children}
		</Box>
	)
}
