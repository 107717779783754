import {WatchlistElementsFilter} from '../Watchlist/slices/watchlist-filter-elements.slice'
import React from 'react'
import {abbreviateNumber} from 'js-abbreviation-number'

export const INSIGHTS_CHART_WIDTH = 435
export const INSIGHTS_CHART_HEIGHT = 215
export const FILL_OPACITY = 1
export const HOVER_FILL_OPACITY = 0.5

export const verifyTypeData = (classificationType: number[] | undefined) => {
	return classificationType ? classificationType.length : 0
}

export const updateData = (classifications: Record<string, number[]> | undefined) => {
	return classifications
		? {
				verified: verifyTypeData(classifications.verified),
				deviated: verifyTypeData(classifications.deviated),
				under_construction: verifyTypeData(classifications.under_construction),
				missing: verifyTypeData(classifications.missing),
				no_data: verifyTypeData(classifications.no_data),
		  }
		: {
				verified: 0,
				deviated: 0,
				under_construction: 0,
				missing: 0,
				no_data: 0,
		  }
}
export const filtersAreEmpty = (filters: WatchlistElementsFilter) => Object.values(filters || {}).every(v => v === null)

export const setChartItemOpacity = (evt: React.SyntheticEvent<any, Event>, opacity: number) => {
	const chartItemList = evt.currentTarget?.parentNode?.children
	for (let item of chartItemList) {
		item.role && (item.style['fill-opacity'] = opacity) && (item.style['transition'] = 'ease-in 100ms')
	}
}

export const abbrNumber = (value: number) => (Math.abs(value) > 999 ? abbreviateNumber(value, 1) : value)
