import {PermissionsEnum} from '../../../entities/auth'
import {ManagementDashboardPageFileMeta} from '../core/management-dashboard-page.utils'

export const SCANS_PAGE_META: ManagementDashboardPageFileMeta = {
	name: 'Scans',
	singular: 'Scan',
	displayVersion: false,
	permission: PermissionsEnum.FILE_SCAN_LIST,
	emptyView: {
		title: "You haven't uploaded any scans yet.",
		message: 'Uploaded scans will appear here.',
		actionText: 'Upload scan',
	},
} as const
