import React, {useState} from 'react'
import html2canvas from 'html2canvas'
import {AddToIssueButton, AddToIssueButtonStateType} from './AddToIssueButton'
import {useDispatch, useSelector} from 'react-redux'
import {fetchIssuesByForgeObjectIds, postAssetToIssue} from '../../features/IssueManagement/api/issue-management.api'
import {RootState} from '../../reducers'
import {useBasePath} from '../../hooks/useBasePath'
import {useQuery, useQueryClient} from 'react-query'
import {AxiosError} from 'axios'
import {LoadingWrapper} from '../CommonsCandidate/Loading/LoadingWrapper'
import {Box} from 'grommet'
import {history} from '../../router/history'
import {useClassificationState} from '../../reducers/classification.slice'
import {Asset, IssueEntity} from '../../features/IssueManagement/issue.entities'
import {useGlobalNotifications} from '../../features/GlobalNotifications'
import {issueSlice} from '../../features/IssueManagement/issue.slice'

type AddToIssueBoxProps = {
	domSelector?: string
	type: Asset['type']
	issueSelection?: 'all' | 'current'
	getAssetDataFn?: () => Promise<string>
	isScreenShot?: boolean
	label?: string
}

export function ignoreShadowRootElements(el: Element) {
	return typeof el.shadowRoot == 'object' && el.shadowRoot !== null
}

export function AddToIssueBox({
	domSelector,
	type,
	issueSelection,
	getAssetDataFn,
	isScreenShot = false,
	label,
}: AddToIssueBoxProps) {
	const dispatch = useDispatch()
	const project = useSelector((state: RootState) => state.userProfileState.selectedProject)
	const queryClient = useQueryClient()
	const selectedForgeId = useSelector((state: any) => state.viewerState.properties.lastSelectedForgeId)
	const {selectedAnalysisView} = useClassificationState()
	const [buttonState, setButtonState] = useState<AddToIssueButtonStateType>('ready')
	const basePath = useBasePath()
	const {notifyAlert} = useGlobalNotifications()
	const {data: issues, isLoading: isLoadingIssues, isError: isErrorLoadingIssues} = useQuery<IssueEntity[], AxiosError>(
		['issues', 'issues-by-forge', issueSelection, selectedForgeId],
		() => fetchIssuesByForgeObjectIds(issueSelection !== 'all' && [selectedForgeId], true),
	)
	if (!project) return null
	const getAssetData =
		getAssetDataFn ||
		(async () => {
			if (!domSelector) throw Error('No dom selector was passed to the component.')
			const domElement = document.querySelector(domSelector) as HTMLElement
			if (!domElement) throw Error('Dom element not found: Selector: ' + domSelector)
			const canvas = await html2canvas(domElement, {
				useCORS: true,
				allowTaint: false,
				ignoreElements: element =>
					ignoreShadowRootElements(element) ||
					(element.hasAttribute('data-hide-on-screenshot') &&
						element.getAttribute('data-hide-on-screenshot') === 'true'),
			})
			return canvas.toDataURL()
		})
	const saveToIssue = (selectedIssue: IssueEntity, data: string) => {
		setButtonState('loading')
		postAssetToIssue(project, selectedIssue._id, data, type, selectedAnalysisView!._id)
			.then(() => {
				setButtonState('done')
				setTimeout(() => setButtonState('ready'), 3000)
				return queryClient.invalidateQueries(['issues'])
			})
			.catch(err => {
				console.error('Error in postAssetToIssue', err)
				setButtonState('error')
				setTimeout(() => setButtonState('ready'), 3000)
			})
	}
	if (!domSelector && !getAssetDataFn) {
		throw Error('AddToIssueBox needs either domSelector or getAssetDataFn to function.')
	}
	return (
		<Box alignSelf="start">
			<LoadingWrapper
				isLoading={isLoadingIssues}
				isError={isErrorLoadingIssues}
				errorMsg="We couldn't display the issues, please retry."
				textAlign="end"
				spinnerSize="small"
			>
				{issues && (
					<Box direction={'row'} justify={'end'} wrap={'reverse'}>
						<Box alignSelf={'end'} direction="row">
							<AddToIssueButton
								label={label}
								issues={issues}
								buttonState={buttonState}
								onCreate={async () => {
									const asset = await getAssetData()
									dispatch(issueSlice.actions.setTempAssetForNewIssue({img: asset, type}))
									history.push(`${basePath}issues/new`)
								}}
								onAdd={selectedIssue => {
									getAssetData()
										.then(data => saveToIssue(selectedIssue, data))
										.catch(err => {
											console.error('Error in saveToIssue', err)
											setButtonState('error')
											setTimeout(() => setButtonState('ready'), 3000)
											notifyAlert('The attachment could not be saved, please retry')
										})
								}}
								isScreenShot={isScreenShot}
							/>
						</Box>
					</Box>
				)}
			</LoadingWrapper>
		</Box>
	)
}
