import React from 'react'
import {Accordion, AccordionProps, ThemeContext} from 'grommet'

export const BorderLessAccordion: React.FC<AccordionProps> = props => {
	return (
		<ThemeContext.Extend
			value={{heading: {extend: 'margin-block-start: 0.5em; margin-block-end: 0.5em;'}, accordion: {border: undefined}}}
		>
			<Accordion {...props} />
		</ThemeContext.Extend>
	)
}
