import React from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import {EditIssueRoute} from './EditIssueRoute'
import {IssueCommentsRoute} from './IssueCommentsRoute'
import {DetailIssueRoute} from './DetailIssueRoute'

import {IssueCardListRoute} from './IssueCardListRoute'

export function IssueList() {
	const {path} = useRouteMatch()
	return (
		<Switch>
			<Route path={path + '/new'}>
				<EditIssueRoute isEdit={false} />
			</Route>
			<Route path={path + '/:id/detail/asset/:assetId'}>
				<DetailIssueRoute />
			</Route>
			<Route path={path + '/:id/detail'}>
				<DetailIssueRoute />
			</Route>
			<Route path={path + '/:id/edit'}>
				<EditIssueRoute isEdit={true} />
			</Route>
			<Route path={path + '/:id/comments'}>
				<IssueCommentsRoute />
			</Route>
			<Route path={path}>
				<IssueCardListRoute />
			</Route>
		</Switch>
	)
}
