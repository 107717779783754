import React, {useEffect} from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import {WatchlistList} from './components/list/WatchlistList'
import {WatchlistDetail} from './components/detail/WatchlistDetail'
import {WatchlistCreate} from './components/create/WatchlistCreate'
import {WatchlistEdit} from './components/edit/WatchlistEdit'
import {watchlistSlice} from './slices/watchlist.slice'
import {useDispatch, useSelector} from 'react-redux'
import {RightPanelHeader} from '../../components/CommonsCandidate/Layout/RightPanel/RightPanelHeader'
import {SRRightPanelBody, SRRightPanelContainer} from 'sr-react-commons'
import {selectorClassificationState} from '../../selectors/classification.selectors'

export function Watchlist() {
	const {path} = useRouteMatch()
	const dispatch = useDispatch()

	const publishedAnalyses = useSelector(selectorClassificationState).analysisViews

	useEffect(() => {
		dispatch(watchlistSlice.actions.doSetOpen(true))
		return () => {
			dispatch(watchlistSlice.actions.doSetOpen(false))
		}
	}, [dispatch])

	if (!publishedAnalyses?.find(av => av.status === 'ready')) {
		return (
			<SRRightPanelContainer>
				<RightPanelHeader title={'Progress Monitoring'} />
				<SRRightPanelBody>Progress Monitoring is not available without a published analysis.</SRRightPanelBody>
			</SRRightPanelContainer>
		)
	}

	return (
		<Switch>
			<Route exact path={path + '/'}>
				<WatchlistList />
			</Route>
			<Route exact path={path + '/new'}>
				<WatchlistCreate />
			</Route>
			<Route path={path + '/:watchlistId/detail'}>
				<WatchlistDetail />
			</Route>
			<Route path={path + '/:watchlistId/edit'}>
				<WatchlistEdit />
			</Route>
			<Route path="*">Incorrect path</Route>
		</Switch>
	)
}
