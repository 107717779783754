import React, {ReactNode, useRef, useState} from 'react'
import {Box, Text} from 'grommet'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PaginationFromPageState} from '../Pagination/Pagination'
import {useBasePath} from '../../hooks/useBasePath'
import {FiltersEntity} from '../../features/FilterPresets/entities/filter-preset.entities'
import {RootState} from '../../reducers'
import {ClassificationDataTable} from './ClassificationDataTable'
import {TableColumn, TableColumns, useClassificationsTable, useTableRowClickHandlers} from './UseClassificationsTable'
import {useQueryClassificationTableData} from '../../features/Classification/data-management/useQueryClassificationTableData'
import {useIssuesByGlobalId} from './UseIssuesByGlobalId'
import {PageState} from '../../reducers/base.pagination.state'
import {classificationSlice, ClassificationSortState, useClassificationState} from '../../reducers/classification.slice'
import {IssueEntity} from '../../features/IssueManagement/issue.entities'
import {LoadingWrapper} from '../CommonsCandidate/Loading/LoadingWrapper'
import useDebounce from './useDebounce'
import {config} from '../../config'
import {
	approveClassificationByForgeId,
	unapproveClassificationByForgeId,
} from '../../features/Classification/api/classification.api'
import {UnapproveClassificationModal} from './UnapproveClassificationModal'
import {useInvalidateClassifications} from '../../features/Classification/data-management/useInvalidateClassification'
import {SRDropButton, SRHeading, SRIconButton} from 'sr-react-commons'
import {QualityMenu} from './QualityMenu'
import {TableColumnSettings} from './TableColumnSettings'
import ClassificationDownloadCSV from './ClassificationDownloadCSV'
import {TableColumnsIcon} from 'components/CommonsCandidate/Icons/TableColumnsIcon'
import {
	SRDismissableTooltip,
	SRDismissableTooltipRef,
} from '../CommonsCandidate/SRDismissableTooltip/SRDismissableTooltip'
import {useFeatureEnabled} from 'features/FeatureFlags/FeatureFlagsProvider'
import {DataTableConfigBox} from './DataTableConfigBox'
import {selectFilterPreset} from '../../features/FilterPresets/slices/filter-preset.thunks'
import {QualityEmptyView} from './QualityEmptyView'

export function DataTable({
	filters,
	sortState,
	pageState,
}: {
	filters: FiltersEntity
	sortState: ClassificationSortState
	pageState: PageState
}) {
	const dispatch = useDispatch()
	const [unapproveForgeObjectId, setUnapproveForgeObjectId] = useState<number | null>(null)
	const manualWorkflowActivated = useSelector((state: RootState) => state.viewerState.manualWorkflowActivated)
	const {selectedAnalysisView, analysisVersionMap} = useClassificationState()
	const selectedForgeObjectIds = useSelector((state: RootState) => state.viewerState.properties.forgeObjectIds)
	const project = useSelector((state: RootState) => state.userProfileState.selectedProject!)
	const history = useHistory()
	const basePath = useBasePath()
	const invalidateClassifications = useInvalidateClassifications()
	const issuesUrl = `${basePath}issues`
	const handleAddIssue = (forgeObjectId: IssueEntity['forgeObjectId'][number]) => {
		window.NOP_VIEWER.select(forgeObjectId)
		history.push(issuesUrl + '/new')
	}
	const handleApprove = async (forgeObjectId: number) => {
		await approveClassificationByForgeId(selectedAnalysisView!._id, forgeObjectId)
		await invalidateClassifications()
	}
	const handleUnapprove = async (forgeObjectId: number) => {
		setUnapproveForgeObjectId(forgeObjectId)
	}
	const handleUnapproveAction = async () => {
		await unapproveClassificationByForgeId(selectedAnalysisView!._id, unapproveForgeObjectId!)
		await invalidateClassifications()
		setUnapproveForgeObjectId(null)
	}
	const {handleRowClick, handleRowDoubleClick} = useTableRowClickHandlers(window.NOP_VIEWER)
	const debouncedFilters = useDebounce(filters, config.sr.debouncingTime)
	const {data: tableData, isLoading, isError} = useQueryClassificationTableData(
		selectedAnalysisView!,
		analysisVersionMap,
		pageState,
		sortState,
		debouncedFilters,
		project,
	)
	const tableColumns = useSelector((state: RootState) => {
		return state.viewerState.manualWorkflowActivated
			? ['approved' as const, ...state.classification.tableColumns]
			: state.classification.tableColumns
	})
	const displayMagnitudeByAxis = useFeatureEnabled('displayMagnitudeByAxis')
	const enableEOI = useFeatureEnabled('elementsOfInterest')
	const issuesByGlobalId = useIssuesByGlobalId(tableData || [], tableColumns.includes('issues'))
	const table = useClassificationsTable(
		{
			data: tableData || [],
			pageCount: pageState.pageCount ?? 1,
			currentPage: pageState.pageIndex,
		},
		{displayMagnitudeByAxis, enableEOI},
	)

	const hideTable = isLoading || isError || tableData?.length

	const isManualApprovalEnabled = useFeatureEnabled('manualApproval')

	const resetFilters = () => {
		dispatch(selectFilterPreset(null))
	}

	return (
		<LoadingWrapper
			{...{isLoading, isError}}
			hideIfGlobalLoader={false}
			errorMsg="We couldn't display the data, please retry."
		>
			{unapproveForgeObjectId !== null && (
				<UnapproveClassificationModal
					elementCount={1}
					onClose={() => setUnapproveForgeObjectId(null)}
					onAction={handleUnapproveAction}
				/>
			)}
			<Box flex={false} align="center" direction="row" justify={'between'}>
				<SRHeading level={'4'}>Elements displayed</SRHeading>
				<Box direction="row">
					{displayMagnitudeByAxis && (
						<TableColumnSettingsDropButton
							allColumns={table.allColumns
								.map(column => ({
									value: column.id,
									label: column.Header as React.ReactNode,
								}))
								.concat([{label: 'Issues', value: 'issues'}])}
							columns={tableColumns}
							setColumns={selectedOptions =>
								dispatch(classificationSlice.actions.setTableColumns({columns: selectedOptions as TableColumns}))
							}
							displayMagnitudeByAxis={displayMagnitudeByAxis}
							enableEOI={enableEOI}
						/>
					)}
					{isManualApprovalEnabled ? (
						<QualityMenu />
					) : (
						<ClassificationDownloadCSV
							{...{analysisView: selectedAnalysisView!, analysisVersionMap, filters, project}}
						/>
					)}
				</Box>
			</Box>
			<Box gap="small" height={{min: hideTable ? '660px' : '0px'}}>
				{!tableData?.length ? (
					<Box height={'600px'} align="center" justify="center" direction="column">
						<QualityEmptyView onAction={resetFilters} />
					</Box>
				) : (
					<ClassificationDataTable
						setColumns={selectedOptions =>
							dispatch(classificationSlice.actions.setTableColumns({columns: selectedOptions as TableColumns}))
						}
						tableColumns={tableColumns}
						table={table}
						selectedForgeObjectIds={selectedForgeObjectIds}
						handleRowClick={handleRowClick}
						handleRowDoubleClick={handleRowDoubleClick}
						handleAddIssue={handleAddIssue}
						getIssuesByGlobalId={issuesByGlobalId}
						sortState={sortState}
						manualWorkflowActivated={manualWorkflowActivated}
						handleApprove={handleApprove}
						handleUnapprove={handleUnapprove}
						displayMagnitudeByAxis={displayMagnitudeByAxis}
					/>
				)}
				{tableData?.length ? (
					<>
						<PaginationFromPageState pageState={pageState} />
						{!displayMagnitudeByAxis && (
							<DataTableConfigBox
								allColumns={table.allColumns}
								onTableColumnChange={selectedOptions =>
									dispatch(classificationSlice.actions.setTableColumns({columns: selectedOptions}))
								}
								displayedTableColumns={tableColumns}
							/>
						)}
					</>
				) : null}
			</Box>
		</LoadingWrapper>
	)
}

function TableColumnSettingsDropButton(props: {
	allColumns: {value: string; label: ReactNode}[]
	columns: Array<TableColumn>
	setColumns: (columns: string[]) => void
	displayMagnitudeByAxis: boolean
	enableEOI: boolean
}) {
	const tooltipRef = useRef<SRDismissableTooltipRef>()
	const dropButton = (
		<SRDropButton
			size={'small'}
			buttonClass={SRIconButton}
			reverse
			icon={<TableColumnsIcon size={'small'} />}
			onOpen={tooltipRef.current?.dismissTooltip}
			dropContent={
				<Box width={'230px'}>
					<TableColumnSettings
						presets={[
							...(props.displayMagnitudeByAxis
								? [
										{
											label: 'Display magnitude columns',
											columns: ['description', 'magnitudeXYDisplay', 'magnitudeZDisplay', 'magnitudeDisplay'],
										},
								  ]
								: []),
							{
								label: 'Display basic columns',
								columns: [
									'status',
									'mappedElementType',
									'mappedFloor',
									'magnitudeDisplay',
									...(props.enableEOI ? ['isInteresting'] : []),
								],
							},
						]}
						allColumns={props.allColumns}
						columns={props.columns}
						setColumns={props.setColumns}
					/>
				</Box>
			}
			dropAlign={{top: 'bottom', right: 'right'}}
		/>
	)
	return props.displayMagnitudeByAxis ? (
		<SRDismissableTooltip
			ref={tooltipRef}
			tooltipContent={[
				<Box>
					<SRHeading level={6}>Display magnitude columns</SRHeading>
					<Text>Find if elements are built too high, too low or if they are displaced.</Text>
				</Box>,
			]}
			dropAlign={{bottom: 'top', right: 'right'}}
			localStorageKey={['table-columns-magnitude-columns']}
		>
			{dropButton}
		</SRDismissableTooltip>
	) : (
		dropButton
	)
}
