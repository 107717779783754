import {doToggleClassificationsColoring} from 'actions/viewer'
import {store} from '../../../store/store'
import {getVerticalToolBar} from '../../Viewer/Viewer-helper'
import {SRExtension} from '../SRExtension'
import VerticalToolbarExtension from '../Viewing.Extension.VerticalToolbar'
import {VERTICAL_CONTROL_GROUP} from '../Viewing.Extension.VerticalToolbar/Viewing.Extension.VerticalToolbar'
import './Viewing.Extension.ToggleClassifications.scss'

export const TOGGLE_CLASSIFICATIONS_EXTENSION = 'Viewing.Extension.ToggleClassifications'

const id = 'ToggleClassificationsPanel'
const title = 'Toggle classification coloring'
const options: Autodesk.Viewing.UI.DockingPanelOptions = {
	shadow: false,
	addFooter: false,
}

class ToggleClassificationsExtension extends SRExtension {
	private subToolbar: any
	private toolbarButton?: Autodesk.Viewing.UI.Button

	constructor(viewer: Autodesk.Viewing.Viewer3D, options: any) {
		super(viewer as Autodesk.Viewing.GuiViewer3D, options)
	}

	private coloringEnabled = () => store.getState().viewerState.properties.classificationsColoringEnabled

	getNames() {
		// Override required for registering extension as tool
		return [ToggleClassificationsExtension]
	}

	load() {
		if (
			this.viewer.isExtensionLoaded('Viewing.Extension.VerticalToolbar') &&
			(this.viewer.getExtension('Viewing.Extension.VerticalToolbar') as VerticalToolbarExtension).SRVerticalToolbar
		) {
			this.createUI()

			// Register and activate extension itself as a tool in order to handle mouse events
			this.viewer.toolController.registerTool(this)
			this.viewer.toolController.activateTool(TOGGLE_CLASSIFICATIONS_EXTENSION)

			return true
		} else {
			console.error('Cannot load extension because the vertical toolbar has not been loaded before.')
			return false
		}
	}

	unload(): boolean {
		const verticalToolBar = this.viewer.getExtension('Viewing.Extension.VerticalToolbar') as VerticalToolbarExtension
		if (verticalToolBar && verticalToolBar.SRVerticalToolbar) {
			verticalToolBar.SRVerticalToolbar.removeControl(this.subToolbar)
		}

		this.viewer.toolController.deactivateTool(TOGGLE_CLASSIFICATIONS_EXTENSION)
		this.viewer.toolController.deregisterTool(this)

		return super.unload()
	}

	private createUI() {
		// Toolbar button UI bindings
		const toggleClassificationsToolbarButton = new Autodesk.Viewing.UI.Button('ToggleClassificationsToolbarButton')
		toggleClassificationsToolbarButton.addClass('ToggleClassificationsToolbarButton')
		toggleClassificationsToolbarButton.setToolTip('Toggle classification coloring')

		toggleClassificationsToolbarButton.onClick = () => this.toggleExtension()

		// SubToolbar
		this.subToolbar =
			(getVerticalToolBar(this.viewer).getControl(VERTICAL_CONTROL_GROUP) as Autodesk.Viewing.UI.ControlGroup) ||
			new Autodesk.Viewing.UI.ControlGroup(VERTICAL_CONTROL_GROUP)

		this.subToolbar.addControl(toggleClassificationsToolbarButton, {index: 3})

		this.toolbarButton = toggleClassificationsToolbarButton
		this.updateBtnStyle()
	}

	private toggleExtension() {
		store.dispatch(doToggleClassificationsColoring())
		this.updateBtnStyle()
	}

	private updateBtnStyle() {
		if (this.toolbarButton) {
			if (this.coloringEnabled()) {
				this.toolbarButton.addClass('active')
			} else {
				this.toolbarButton.removeClass('active')
			}
		}
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(TOGGLE_CLASSIFICATIONS_EXTENSION, ToggleClassificationsExtension)
