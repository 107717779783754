import {Box, Text} from 'grommet'
import React from 'react'
import {FormSearch} from 'grommet-icons'
import {SRTextInput} from 'sr-react-commons'

export function GlobalIdFilter({
	filters,
	setFilter,
}: {
	filters: {externalId: string | null}
	setFilter: (key: 'externalId', value: string | null) => void
}) {
	return (
		<Box>
			<Text weight="bold" size="small">
				Global ID
			</Text>
			<SRTextInput
				icon={<FormSearch />}
				placeholder={'Type global ID'}
				data-testid={'filter-globalid-input'}
				value={filters.externalId || ''}
				onChange={event => setFilter('externalId', event.target.value === '' ? null : event.target.value)}
			/>
		</Box>
	)
}
