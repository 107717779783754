import React, {ChangeEventHandler, useEffect, useState} from 'react'
import {Box} from 'grommet'
import {watchlistElementsFilterToDTO, WatchlistEntity} from '../../entities/watchlist.entities'
import {isEmpty} from 'lodash'
import {RightPanelHeader} from '../../../../components/CommonsCandidate/Layout/RightPanel/RightPanelHeader'
import {LoadingWrapper} from '../../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {WatchListCreateErrorModal} from '../../modals/WatchListCreateErrorModal'
import {Spinner} from '../../../../components/CommonsCandidate/Loading/Spinner'
import {
	FileInputProps,
	SRConfirmDeleteModal,
	SRDeletePrimaryIconButton,
	SRDualCalendar,
	SRDualCalendarProps,
	SRHeading,
	SRPrimaryButton,
	SRRightPanelBody,
	SRRightPanelContainer,
	SRTextArea,
	SRTextInput,
	SRTooltip,
} from 'sr-react-commons'
import {WatchlistEditCreateSelector} from '../selection/WatchlistEditCreateSelector'
import {useWatchlistFilterElementsState, WatchlistElementsFilter} from '../../slices/watchlist-filter-elements.slice'
import {UserProject} from '../../../../reducers/userProfile'
import {CreateWatchlistDTO, UpdateWatchlistDTO} from '../../api/watchlist.api'
import {nonNull} from '../../../../config'
import {CircleInformation} from 'grommet-icons'

const nameIsValid = (name: string) => name.length <= 50
const descriptionIsValid = (description: string) => description.length <= 235

function getSource(
	source: 'csv' | 'filter' | null,
	file: File | undefined,
	readonly: boolean,
	filter: WatchlistElementsFilter,
	project: UserProject,
) {
	switch (source) {
		case 'csv':
			return file
				? {
						mode: 'csv',
						file: nonNull(file, 'File must be defined'),
						filter: null,
				  }
				: null
		case 'filter':
			return readonly
				? null
				: {
						mode: 'filter',
						file: null,
						filter: watchlistElementsFilterToDTO(filter, project),
				  }
		case null:
			return null
	}
}

export function WatchlistCreatePresenter({
	watchlist,
	project,
	isLoading = false,
	isError = false,
	submitWatchlist,
	deleteWatchlist,
	isEdit = false,
	missingIds,
	hasInvalidIds = false,
}: {
	watchlist: WatchlistEntity | null
	project: UserProject
	isLoading?: boolean
	isError?: boolean
	submitWatchlist: ((dto: CreateWatchlistDTO) => Promise<void>) | ((dto: UpdateWatchlistDTO) => Promise<void>)
	deleteWatchlist?: (id: string) => any
	isEdit?: boolean
	missingIds?: string[]
	hasInvalidIds?: boolean
}) {
	const [nameError, setNameError] = useState<string>()
	const [descriptionError, setDescriptionError] = useState<string>()
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [activityId, setActivityId] = useState<string | null>(null)
	const [wbs, setWbs] = useState<string | null>(null)
	const [startDate, setStartDate] = useState<string>()
	const [endDate, setEndDate] = useState<string>()
	const [file, setFile] = useState<File>()
	const [confirmDeletion, setConfirmDeletion] = useState(false)
	const [isWatchlistSubmitted, setIsWatchlistSubmitted] = useState(false)
	const [showErrorModal, setShowErrorModal] = useState(false)
	const [source, setSource] = useState(watchlist?.source?.mode || null)
	const [readonly, setReadonly] = useState(isEdit)
	const {filter} = useWatchlistFilterElementsState()

	useEffect(() => {
		if (missingIds) {
			setShowErrorModal(true)
			return
		}
		if (!watchlist) return
		setName(watchlist.name)
		setDescription(watchlist.description || '')
		setStartDate(watchlist.startDate)
		setEndDate(watchlist.endDate)
		setActivityId(watchlist.primavera.activityId)
		setWbs(watchlist.primavera.wbs)
	}, [watchlist, missingIds])
	const sourceValid = source === 'csv' ? !!file : !!filter

	const submitEnabled = () => {
		const attributesValid =
			!isEmpty(name) && descriptionIsValid(description) && nameIsValid(name) && startDate && endDate
		return attributesValid && sourceValid //sourceValid has to be true for create too.
	}

	const handleSubmit = async (haveToIgnoreMissingIds: boolean = false) => {
		setIsWatchlistSubmitted(true)
		const dto = {
			name,
			description,
			source: getSource(source, file, readonly, filter, project),
			startDate: nonNull(startDate, 'Start date must be defined'),
			endDate: nonNull(endDate, 'End date must be defined'),
			ignoreMissingIds: haveToIgnoreMissingIds,
			primavera: {
				activityId,
				wbs,
			},
		}
		try {
			if (isEdit) {
				const submitFunction = submitWatchlist as (dto: UpdateWatchlistDTO) => Promise<void>
				await submitFunction(dto as UpdateWatchlistDTO)
			} else {
				const submitFunction = submitWatchlist as (dto: CreateWatchlistDTO) => Promise<void>
				await submitFunction(dto as CreateWatchlistDTO)
			}
		} finally {
			setIsWatchlistSubmitted(false)
		}
	}

	const handleDelete = () => setConfirmDeletion(true)
	const performDelete = () => deleteWatchlist && watchlist && deleteWatchlist(watchlist._id)
	const onNameChange: ChangeEventHandler<HTMLInputElement> = e => {
		setName(e.target.value)
		setNameError(!nameIsValid(e.target.value) ? 'Name is too long' : undefined)
	}
	const onDescriptionChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
		setDescription(e.target.value)
		setDescriptionError(!descriptionIsValid(e.target.value) ? 'Description is too long' : undefined)
	}
	const onRangeChange: SRDualCalendarProps['onRangeChange'] = dates => {
		setStartDate(dates.start?.toISOString())
		setEndDate(dates.end?.toISOString())
	}
	const onFileChange: FileInputProps['onChange'] = ([file]) => {
		setFile(file)
	}

	const onActivityIdChange: ChangeEventHandler<HTMLInputElement> = e => {
		setActivityId(e.target.value === '' ? null : e.target.value)
	}

	const onWbsChange: ChangeEventHandler<HTMLInputElement> = e => {
		setWbs(e.target.value === '' ? null : e.target.value)
	}

	const bottom =
		isError || isLoading ? null : (
			<Box width="full" direction="row" justify={watchlist ? 'between' : 'end'} flex={false}>
				{watchlist && <SRDeletePrimaryIconButton onClick={handleDelete} />}
				<SRPrimaryButton
					icon={isWatchlistSubmitted ? <Spinner size={'small'} /> : undefined}
					label={isWatchlistSubmitted ? 'Saving' : 'Save'}
					size={'large'}
					onClick={() => handleSubmit()}
					disabled={!submitEnabled() || isWatchlistSubmitted}
				/>
			</Box>
		)
	return (
		<SRRightPanelContainer>
			<RightPanelHeader title={`${isEdit ? 'Edit' : 'Create'} work package`} back="progress/work-packages" />
			<SRRightPanelBody {...{bottom}}>
				<LoadingWrapper
					{...{isLoading, isError}}
					fill
					spinnerText={'Loading work package'}
					errorMsg="We couldn't display the work package, please retry."
				>
					{confirmDeletion && (
						<SRConfirmDeleteModal
							entityName={'work package'}
							name={watchlist!.name}
							onDelete={performDelete}
							onCancel={() => setConfirmDeletion(false)}
						/>
					)}
					{showErrorModal && missingIds ? (
						<WatchListCreateErrorModal
							onClose={() => setShowErrorModal(false)}
							missingIds={missingIds}
							handleIgnoreSubmit={() => {
								handleSubmit(true)
								setShowErrorModal(false)
							}}
						/>
					) : null}
					<Box flex={false} direction={'column'} gap={'small'}>
						<WatchlistEditCreateSelector
							readonly={readonly}
							setReadonly={setReadonly}
							isEdit={isEdit}
							hasInvalidIds={hasInvalidIds}
							file={file}
							watchlist={watchlist}
							onFileChange={onFileChange}
							source={source}
							setSource={setSource}
						/>
						<Box gap={'xsmall'}>
							<SRHeading level={5}>Work package details</SRHeading>
							<Box>
								<SRHeading level={6}>Name*</SRHeading>
								<SRTextInput
									autoFocus
									className={'watchlist-create-name'}
									placeholder={"Work package's name"}
									value={name}
									onChange={onNameChange}
									error={nameError}
								/>
							</Box>
						</Box>
						<Box direction="row" justify="between" gap="medium">
							<Box fill>
								<Box direction="row" justify="between">
									<SRHeading level={6}>WBS Code</SRHeading>
									<SRTooltip
										tooltipText={'WBS Code can be used to export progress to Primavera P6'}
										width={200}
										arrowPos="center"
									>
										<CircleInformation size="small" color="neutral-1" />
									</SRTooltip>
								</Box>
								<SRTextInput
									autoFocus
									className={'watchlist-create-name'}
									placeholder={"Work package's WBS"}
									value={wbs || ''}
									onChange={onWbsChange}
								/>
							</Box>
							<Box fill>
								<Box direction="row" justify="between">
									<SRHeading level={6}>Activity ID</SRHeading>
									<SRTooltip
										tooltipText={'Activity ID can be used to export progress to Primavera P6'}
										width={200}
										arrowPos="right"
									>
										<CircleInformation size="small" color="neutral-1" />
									</SRTooltip>
								</Box>
								<SRTextInput
									autoFocus
									className={'watchlist-create-name'}
									placeholder={"Work package's Activity ID"}
									value={activityId || ''}
									onChange={onActivityIdChange}
								/>
							</Box>
						</Box>
						<Box>
							<SRHeading level={6}>Description</SRHeading>
							<SRTextArea
								className={'watchlist-create-description'}
								style={{height: '72px'}}
								placeholder={'A short description'}
								value={description}
								onChange={onDescriptionChange}
								error={descriptionError}
							/>
						</Box>
						<SRDualCalendar
							dates={{start: startDate ? new Date(startDate) : undefined, end: endDate ? new Date(endDate) : undefined}}
							onRangeChange={onRangeChange}
						/>
					</Box>
				</LoadingWrapper>
			</SRRightPanelBody>
		</SRRightPanelContainer>
	)
}
