import React from 'react'
import {BurnUpChart} from '../charts/BurnUpChart'
import {ProgressAggregation} from '../../aggregations/progress.aggregations'
import {SRCard, SRHeading} from 'sr-react-commons'

export function WatchlistBurnUpCardPresenter({progress}: {progress: ProgressAggregation}) {
	const data = progress.progresses.map(item => {
		return {
			x: new Date(item.analysis.scanDate).getTime(),
			y: item.total ? Math.round((100 * item.progress) / item.total) / 100 : 0,
		}
	})

	const startDate = new Date(progress.watchlist.startDate).getTime()
	const endDate = new Date(progress.watchlist.endDate).getTime()

	return (
		<SRCard className={'burnup-card'} gap={'small'}>
			<SRHeading level={5} size={'small'}>
				Progress over time
			</SRHeading>
			<BurnUpChart data={data} startDate={startDate} endDate={endDate} />
		</SRCard>
	)
}
