import {types} from '../actions'
import {
	DoSelectThreeSixtyClassificationAction,
	doSelectThreeSixtyViewsphere,
	SetApprovedFilterAction,
	SetDBReadyAction,
	SetDisplayAllElementsAction,
	SetForgeMappingReadyAction,
	SetForgeReadyAction,
	SetMaintenanceModeAction,
	SetManualWorkflowAction,
	SetUpdatedSelectionAction,
	SetUserProfileReadyAction,
	SetViewerAction,
	SetViewerInitializedAction,
	ViewerActionTypes,
} from '../actions/viewer'
import {ClassificationEntity} from '../features/Classification/classification.entities'
import {Viewsphere} from './classification'
import {RootState} from './index'

export type ViewerState = {
	isReady: {
		db: boolean
		forgeViewer: boolean
		forgeMapping: boolean
		classificationsPainted: boolean
		userProfile: boolean
	}
	properties: {
		forgeObjectIds: number[]
		lastSelectedForgeId: number | null
		threeSixtyClassification: ClassificationEntity | null
		threeSixtyViewsphere: Viewsphere | null
		assetsMode: 'split' | 'popup'
		threeSixtyOpened: boolean
		threeSixtyHighlight: boolean
		threeSixtyLockedView: boolean
		visibleElements: number[]
		classificationsColoringEnabled: boolean
	}
	viewer: Autodesk.Viewing.GuiViewer3D | null
	viewerInitialized: boolean
	maintenanceMode: boolean
	manualWorkflowActivated: boolean
	approvedFilter: boolean | null
	displayAllElements: boolean
}
export function buildInitialViewerState(): ViewerState {
	return {
		isReady: {
			db: false,
			forgeViewer: false,
			forgeMapping: false,
			classificationsPainted: false,
			userProfile: false,
		},
		properties: {
			forgeObjectIds: [],
			lastSelectedForgeId: null,
			threeSixtyClassification: null,
			threeSixtyViewsphere: null,
			assetsMode: 'popup',
			threeSixtyOpened: false,
			threeSixtyHighlight: true,
			threeSixtyLockedView: false,
			visibleElements: [],
			classificationsColoringEnabled: true,
		},
		viewer: null,
		viewerInitialized: false,
		maintenanceMode: false,
		manualWorkflowActivated: false,
		approvedFilter: null,
		displayAllElements: false,
	}
}

const setDBReady = (state: ViewerState, action: SetDBReadyAction): ViewerState => {
	return {
		...state,
		isReady: {
			...state.isReady,
			db: action.value,
		},
	}
}

const setUserProfileReady = (state: ViewerState, action: SetUserProfileReadyAction): ViewerState => {
	return {
		...state,
		isReady: {
			...state.isReady,
			userProfile: action.value,
		},
	}
}

const setForgeReady = (state: ViewerState, action: SetForgeReadyAction) => {
	return {
		...state,
		isReady: {
			...state.isReady,
			...action.value,
		},
	}
}

const setForgeMappingReady = (state: ViewerState, action: SetForgeMappingReadyAction) => {
	return {
		...state,
		isReady: {
			...state.isReady,
			...action.value,
		},
	}
}

const getAggregateProperties = (state: ViewerState, action: SetUpdatedSelectionAction): ViewerState => {
	const oldForgeObjectIds = state.properties.forgeObjectIds
	const forgeObjectIds = action.forgeObjectIds
	let newLastSelectedForgeId = state.properties.lastSelectedForgeId

	// If only one element in selection, this is the last selected element
	if (forgeObjectIds.length === 1) {
		newLastSelectedForgeId = forgeObjectIds[0]
		// If multiple elements, we have to compare with old set of selected elements
	} else if (forgeObjectIds.length > 1) {
		const newSelectedIds = forgeObjectIds.filter(selectedEl => !oldForgeObjectIds.includes(selectedEl))
		if (newSelectedIds.length > 0) {
			newLastSelectedForgeId = newSelectedIds[0]
		}
		// If no element in selection, set to null
	} else {
		newLastSelectedForgeId = null
	}

	return {
		...state,
		properties: {
			...state.properties,
			forgeObjectIds: forgeObjectIds,
			lastSelectedForgeId: newLastSelectedForgeId,
		},
	}
}

function selectThreeSixtyViewsphere(
	state: ViewerState,
	action: ReturnType<typeof doSelectThreeSixtyViewsphere>,
): ViewerState {
	return {
		...state,
		properties: {
			...state.properties,
			threeSixtyViewsphere: action.viewsphere,
		},
	}
}

function selectThreeSixtyClassification(
	state: ViewerState,
	action: DoSelectThreeSixtyClassificationAction,
): ViewerState {
	const viewsphere =
		(action.classification?.viewspheres || []).find(
			v => v.imagePath === state.properties.threeSixtyViewsphere?.imagePath,
		) ||
		action.classification?.viewspheres[0] ||
		null
	return {
		...state,
		properties: {
			...state.properties,
			threeSixtyClassification: action.classification,
			threeSixtyViewsphere: viewsphere,
			threeSixtyLockedView: action.classification === null ? false : state.properties.threeSixtyLockedView,
		},
	}
}

function toggleAssetsMode(state: ViewerState): ViewerState {
	return {
		...state,
		properties: {
			...state.properties,
			assetsMode: state.properties.assetsMode === 'popup' ? 'split' : 'popup',
			threeSixtyLockedView: false,
		},
	}
}

function toggleThreeSixtyOpened(state: ViewerState): ViewerState {
	return {
		...state,
		properties: {
			...state.properties,
			threeSixtyOpened: !state.properties.threeSixtyOpened,
			threeSixtyLockedView: false,
		},
	}
}

function toggleThreeSixtyHighlight(state: ViewerState): ViewerState {
	return {
		...state,
		properties: {...state.properties, threeSixtyHighlight: !state.properties.threeSixtyHighlight},
	}
}

function toggleThreeSixtyLockedView(state: ViewerState): ViewerState {
	return {
		...state,
		properties: {...state.properties, threeSixtyLockedView: !state.properties.threeSixtyLockedView},
	}
}

function setMaintenanceMode(state: ViewerState, action: SetMaintenanceModeAction): ViewerState {
	return {
		...state,
		maintenanceMode: action.value,
	}
}

function setViewer(state: ViewerState, action: SetViewerAction): ViewerState {
	return {
		...state,
		viewer: action.value,
	}
}

function setViewerInitialized(state: ViewerState, action: SetViewerInitializedAction): ViewerState {
	return {
		...state,
		viewerInitialized: action.value,
	}
}

function setManualWorkflow(state: ViewerState, action: SetManualWorkflowAction) {
	return {
		...state,
		manualWorkflowActivated: action.value,
	}
}

function setApprovedFilter(state: ViewerState, action: SetApprovedFilterAction) {
	return {
		...state,
		approvedFilter: action.value,
	}
}

function setDisplayAllElements(state: ViewerState, action: SetDisplayAllElementsAction): ViewerState {
	return {
		...state,
		displayAllElements: action.value,
	}
}

function toggleClassificationsColoring(state: ViewerState): ViewerState {
	return {
		...state,
		properties: {...state.properties, classificationsColoringEnabled: !state.properties.classificationsColoringEnabled},
	}
}

export default function viewer(state: ViewerState = buildInitialViewerState(), action: ViewerActionTypes): ViewerState {
	switch (action.type) {
		case types.GET_AGGREGATE_PROPERTIES:
			return getAggregateProperties(state, action)
		case types.SET_DB_READY:
			return setDBReady(state, action)
		case types.SET_USER_PROFILE_READY:
			return setUserProfileReady(state, action)
		case types.SET_FORGE_READY:
			return setForgeReady(state, action)
		case types.SET_FORGE_MAPPING_READY:
			return setForgeMappingReady(state, action)
		case types.SET_CLASSIFICATIONS_PAINTED:
			return {...state, isReady: {...state.isReady, classificationsPainted: action.value}}
		case types.RESET_VIEWER_STATE:
			return {
				...buildInitialViewerState(),
				isReady: {...buildInitialViewerState().isReady, userProfile: state.isReady.userProfile},
			}
		case types.SET_MAINTENANCE_MODE:
			return setMaintenanceMode(state, action)
		case types.SET_VIEWER:
			return setViewer(state, action)
		case types.SET_VIEWER_INITIALIZED:
			return setViewerInitialized(state, action)
		case types.TOGGLE_ASSETS_MODE:
			return toggleAssetsMode(state)
		case types.TOGGLE_THREESIXTY_OPENED:
			return toggleThreeSixtyOpened(state)
		case types.TOGGLE_THREESIXTY_HIGHLIGHT:
			return toggleThreeSixtyHighlight(state)
		case types.TOGGLE_THREESIXTY_LOCKED_VIEW:
			return toggleThreeSixtyLockedView(state)
		case types.THREESIXTY_CLASSIFICATION_SELECT:
			return selectThreeSixtyClassification(state, action)
		case types.THREESIXTY_VIEWSPHERE_SELECT:
			return selectThreeSixtyViewsphere(state, action)
		case types.TOGGLE_MANUAL_WORKFLOW:
			return setManualWorkflow(state, action)
		case types.SET_APPROVED_FILTER:
			return setApprovedFilter(state, action)
		case types.SET_DISPLAY_ALL_ELEMENTS:
			return setDisplayAllElements(state, action)
		case types.TOGGLE_CLASSIFICATIONS_COLORING:
			return toggleClassificationsColoring(state)
		default:
			return state
	}
}

export function selectorApprovedFilter(state: RootState): boolean | null {
	return state.viewerState.manualWorkflowActivated === false ? null : state.viewerState.approvedFilter
}
