import React from 'react'
import './BCFButton.scss'
import {DocumentCsv} from 'grommet-icons'
import {SRFileDownloadButton, SRIconButton} from 'sr-react-commons'
import {getIssueTitle, IssueEntity} from '../../issue.entities'
import {fetchCsvByIssueId} from '../api/issue.exports.api'

function IssueCSVButton({issue}: {issue: IssueEntity}) {
	const fetchCsvFile = async () => {
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
		return fetchCsvByIssueId(issue._id, timeZone)
	}

	return (
		<SRFileDownloadButton
			buttonClass={SRIconButton}
			icon={<DocumentCsv size={'large'} />}
			fileName={`${getIssueTitle(issue)}.csv`}
			getFile={fetchCsvFile}
		/>
	)
}

export default React.memo(IssueCSVButton)
