import {Box, Text} from 'grommet'
import {SRHeading} from 'sr-react-commons'
import React from 'react'
import {ManagementDashboardAnalysisRequestFormBox} from '../../support/ManagementDashboardAnalysisRequestFormBox'
import {AnalysisRequestEntity} from '../../../../AnalysisRequest/analysis-request.entities'

export const ManagementDashboardAnalysisRequestFormStep4 = ({
	exitForm,
	analysisRequest,
}: {
	exitForm: () => void
	analysisRequest: AnalysisRequestEntity
}) => {
	return (
		<ManagementDashboardAnalysisRequestFormBox onConfirm={exitForm} confirmText={'Go back to analyses'}>
			<SRHeading level={3}>{analysisRequest.analysisName}</SRHeading>
			<Box direction="row" justify={'between'} gap="60px">
				<Box gap="small" width="600px">
					<SRHeading level={6}>Your new analysis in on the way.</SRHeading>
					<Text>We have started to process your new analysis with the data you just sent us.</Text>
					<Text>You will receive an email once the analysis is ready.</Text>
				</Box>
				<Box width="200px" height="200px"></Box>
			</Box>
		</ManagementDashboardAnalysisRequestFormBox>
	)
}
