import React, {ReactNode} from 'react'
import {Spinner, SpinnerProps} from './Spinner'
import {Box, Text, TextProps} from 'grommet'
import {BoxProps} from 'grommet/components/Box'

export type LoadingWrapperProps = {
	isLoading: boolean
	isError?: boolean
	errorMsg?: ReactNode
	isEmpty?: boolean
	emptyMsg?: ReactNode
	children: ReactNode
	textAlign?: TextProps['textAlign']
	spinnerSize?: SpinnerProps['size']
	spinnerText?: string
	fill?: BoxProps['fill']
	hideIfGlobalLoader?: SpinnerProps['hideIfGlobalLoader']
}

export const LoadingWrapper = ({
	isLoading,
	isError = false,
	errorMsg,
	isEmpty = false,
	emptyMsg,
	children,
	textAlign = 'center',
	spinnerSize = 'normal',
	spinnerText,
	fill = false,
	hideIfGlobalLoader = false,
}: LoadingWrapperProps) =>
	isLoading ? (
		<Spinner size={spinnerSize} text={spinnerText} hideIfGlobalLoader={hideIfGlobalLoader} />
	) : isError ? (
		<Text textAlign={textAlign} color="status-error" margin="auto">
			{errorMsg}
		</Text>
	) : isEmpty ? (
		<Text textAlign={textAlign} margin="auto">
			{emptyMsg}
		</Text>
	) : (
		<Box fill={fill}>{children}</Box>
	)
