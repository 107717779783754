import {
	ManagementDashboardTableHeaderCell,
	ManagementDashboardTableRowBox,
} from '../../../core/components/table/ManagementDashboardTable'
import React from 'react'
import {
	ANALYSIS_REQUEST_ID_WIDTH,
	CREATED_BY_WIDTH,
	DATE_CREATED_WIDTH,
} from './ManagementDashboardAnalysisRequestListTable'

export const ManagementDashboardAnalysisRequestListTableHeader = () => (
	<ManagementDashboardTableRowBox>
		<ManagementDashboardTableHeaderCell flexible width={{min: ANALYSIS_REQUEST_ID_WIDTH}}>
			Analysis Request ID
		</ManagementDashboardTableHeaderCell>
		<ManagementDashboardTableHeaderCell width={DATE_CREATED_WIDTH}>Date Created</ManagementDashboardTableHeaderCell>
		<ManagementDashboardTableHeaderCell width={CREATED_BY_WIDTH}>Created by</ManagementDashboardTableHeaderCell>
	</ManagementDashboardTableRowBox>
)
