import {Viewsphere} from '../../../reducers/classification'

const MAX_LATITUDE = 85

export function normalizeExtremeLatitudeValue(latitude: number): number {
	return Math.max(-MAX_LATITUDE, Math.min(MAX_LATITUDE, latitude))
}

export function getCurrentRotationDelta(viewsphere: Viewsphere, cameraOrientation: [number, number]) {
	const initialLat = normalizeExtremeLatitudeValue(viewsphere.elementCentroid[0])
	const initialLon = viewsphere.elementCentroid[1]
	const [currentLat, currentLon] = cameraOrientation
	const deltaLon = currentLon - initialLon
	const deltaLat = currentLat - initialLat
	return {deltaLon, deltaLat}
}
