import {Box} from 'grommet'
import {LinkDown, LinkUp} from 'grommet-icons'
import {format} from 'date-fns'
import {AnalysisViewSelector} from './AnalysisViewSelector'
import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../reducers'
import {SRChip, SRHeading, SRIconButton} from 'sr-react-commons'
import {AnalysisViewEntityPopulated} from '../analysis-view.entities'
import {AppBarDropButton} from '../../../components/AppBar/AppBarDropButton'
import {DropContentContainer} from '../../../components/AppBar/AppBar'
import {useIsDeveloperForCurrentProject} from '../../../hooks/UseIsDeveloperForCurrentProject'
import {AnalysisSelectorFilterAccordion} from '../Filters/AnalysisSelectorFilters'
import {
	AnalysisSelectorFilter,
	analysisSelectorFilterSlice,
	useAnalysisSelectorFilterState,
} from '../../../reducers/analysisSelectorFilter.slice'
import {isEmpty} from 'lodash'

export function AnalysisViewSelectionDropdown(props: {
	analysisViews: AnalysisViewEntityPopulated[]
	selectedAnalysisViewId: string | null
	onSelectAnalysisView: (view: AnalysisViewEntityPopulated) => void
}) {
	const [open, setOpen] = React.useState(false)
	const [sort, setSort] = React.useState<'asc' | 'desc'>('desc')
	const currentAnalysisView = props.analysisViews.find(
		analysisView => analysisView._id === props.selectedAnalysisViewId,
	)
	const {filter} = useAnalysisSelectorFilterState()
	const dispatch = useDispatch()
	const setFilter = useCallback(
		<K extends keyof AnalysisSelectorFilter>(filterKey: K, value: AnalysisSelectorFilter[K]) => {
			dispatch(
				analysisSelectorFilterSlice.actions.setFilter({
					filter: {...filter, [filterKey]: isEmpty(value) ? null : value},
				}),
			)
		},
		[dispatch, filter],
	)
	const resetFilters = useCallback(() => {
		dispatch(analysisSelectorFilterSlice.actions.resetFilter())
	}, [dispatch])
	const toggleSort = React.useCallback(() => setSort(sort => (sort === 'asc' ? 'desc' : 'asc')), [])
	const {db, forgeViewer, forgeMapping, classificationsPainted} = useSelector(
		(state: RootState) => state.viewerState.isReady,
	)
	const isLoading = !(db && forgeViewer && forgeMapping && classificationsPainted)
	const hasDeveloperPermissions = useIsDeveloperForCurrentProject()
	const analysisSelectorChip =
		hasDeveloperPermissions &&
		currentAnalysisView?.deliveryType === 'Normal' &&
		currentAnalysisView.status !== 'ready' ? (
			<SRChip text={'Unpublished'} background={'neutral-1'} />
		) : currentAnalysisView?.status === 'in_progress' ? (
			<SRChip width={'85px'} text={'Processing'} background={'brand-main'} />
		) : null

	useEffect(() => {
		if (!open) {
			resetFilters()
		}
		return () => {
			resetFilters()
		}
	}, [open, resetFilters])

	const AnalysisListSort = () => {
		return (
			<Box direction="row" pad={{vertical: 'xsmall'}}>
				<SRIconButton
					size="small"
					onClick={toggleSort}
					icon={sort === 'asc' ? <LinkUp size="small" /> : <LinkDown size="small" />}
				/>
				<SRHeading alignSelf="center" level={5}>
					Analyses list
				</SRHeading>
			</Box>
		)
	}
	return (
		<AppBarDropButton
			id="analysis-view-selection-dropdown"
			open={open}
			label={currentAnalysisView ? format(new Date(currentAnalysisView.scanDate), 'dd/MM/yy') : 'Loading...'}
			title={currentAnalysisView?.name || ''}
			afterTitle={analysisSelectorChip}
			onOpen={() => setOpen(true)}
			onClose={() => !isLoading && setOpen(false)}
			dropContent={
				<DropContentContainer
					onClose={() => {
						setOpen(false)
						resetFilters()
					}}
					header={
						<Box pad={{horizontal: 'xxsmall'}}>
							<SRHeading level={5}>Analysis selector</SRHeading>
						</Box>
					}
					filters={
						<Box flex={false} fill pad={{horizontal: 'xsmall'}}>
							<AnalysisSelectorFilterAccordion filters={filter} setFilter={setFilter} />
							<AnalysisListSort />
						</Box>
					}
				>
					<AnalysisViewSelector
						analysisViews={props.analysisViews}
						hasDeveloperPermissions={hasDeveloperPermissions}
						selectedAnalysisViewId={props.selectedAnalysisViewId}
						onSelectAnalysisView={props.onSelectAnalysisView}
						sort={sort}
						filter={filter}
					/>
				</DropContentContainer>
			}
		/>
	)
}
