import _ from 'lodash'
import {FiltersEntity, toFiltersDTO} from '../../FilterPresets/entities/filter-preset.entities'
import {config} from '../../../config'
import {buildAxios} from '../../../api/api-factory'
import {Id} from '../../../entities/value-objects'
import {srProjectApiV3} from '../../../api/project.api.v3'
import {PaginationResponse} from '../../../api/api.types'
import {ClassificationCSVFormat, UserProject} from '../../../reducers/userProfile'
import {watchlistElementsFilterToDTO, WatchlistEntity} from '../../Watchlist/entities/watchlist.entities'
import {AnalysisViewEntityPopulated} from '../../AnalysisView/analysis-view.entities'
import {AnalysisVersionMap} from '../../../reducers/classification.slice'
import {VersionEntity} from '../../../entities/version.entities'
import {hasElementFilters, WatchlistElementsFilter} from '../../Watchlist/slices/watchlist-filter-elements.slice'
import {fetchForgeObjectIdsByFilterFromLatestModel} from '../../../api/elements.api.v3'
import {SimplifiedLocation} from '../data-management/useClassificationsForColorizeModel'
import {ClassificationEntity, ClassificationEntityWithElementRef} from '../classification.entities'

let currentProjectId: UserProject['_id']
export const setCurrentProjectId = (projectId: UserProject['_id']) => {
	currentProjectId = projectId
}

export const srClassificationApiV3 = buildAxios(`${config.sr.backendUrl}classification/v3`)

const projectPath = async (id?: Id, tail?: string) =>
	`projects/${currentProjectId}/classifications/${id ? `${encodeURIComponent(id)}/` : ''}${tail ? `${tail}/` : ''}`

const byVersionsPath = async (analysisViewId: string, tail?: string) =>
	`${await projectPath()}byVersions/${encodeURIComponent(analysisViewId)}/${tail ? `${tail}/` : ''}`

const byForgeObjectIdsPath = async (analysisViewId: string, tail?: string) =>
	`${await projectPath()}byForgeObjectIds/${encodeURIComponent(analysisViewId)}/${tail ? `${tail}/` : ''}`

export async function fetchClassifications(args: {
	analysisViewId: string
	analysisVersionMap: AnalysisVersionMap
	limit: number
	skip: number
	sort?: string
	filtersDTO: FiltersEntity
	tail?: string
}): Promise<PaginationResponse<ClassificationEntityWithElementRef>> {
	const path = await byVersionsPath(args.analysisViewId, args.tail)
	return srClassificationApiV3
		.get(path, {
			params: {
				..._.omit(args, 'analysisViewId'),
				sort: args.sort,
			},
		})
		.then(response => response.data)
}

export async function fetchVersionsForStatus(
	analysisViewId: string,
	analysisVersionMap: AnalysisVersionMap,
): Promise<VersionEntity[]> {
	const path = await byVersionsPath(analysisViewId, 'versions-for-status')
	return srClassificationApiV3
		.get(path, {
			params: {analysisVersionMap},
		})
		.then(response => response.data)
}

export async function fetchSelectedVersionsForAnalysisView(
	analysisViewId: string,
	analysisVersionMap: AnalysisVersionMap,
): Promise<VersionEntity[]> {
	const path = await byVersionsPath(analysisViewId, 'selected-versions')
	return srClassificationApiV3
		.get(path, {
			params: {analysisVersionMap},
		})
		.then(response => response.data)
}

export const fetchClassificationCsv = async (
	analysisView: AnalysisViewEntityPopulated,
	analysisVersionMap: AnalysisVersionMap,
	filtersDTO: FiltersEntity | undefined,
	format: ClassificationCSVFormat | undefined,
	enableUnderConstruction: boolean,
): Promise<string> =>
	(
		await srClassificationApiV3.get(await byVersionsPath(analysisView._id, 'csv'), {
			params: {analysisVersionMap: analysisVersionMap, filtersDTO, format, enableUnderConstruction},
		})
	).data

export const reclassifyClassificationByForgeId = async (
	analysisViewId: string,
	analysisVersionMap: AnalysisVersionMap,
	forgeId: number,
	status: string,
) => {
	await srProjectApiV3.post(
		`classifications/byAnalysisView/${analysisViewId}/${forgeId}/reclassify-by-forge-id`,
		{classification: status},
		{params: {analysisVersionMap}},
	)
}

export async function fetchClassificationsForColorizingModelElements(
	selectedAnalysisView: AnalysisViewEntityPopulated,
	analysisVersionMap: AnalysisVersionMap,
	filtersDTO: FiltersEntity,
): Promise<Record<string, number[]>> {
	const id = selectedAnalysisView._id
	let url = `classifications/byAnalysisView/${encodeURIComponent(id)}/for-colorizing-model-elements`
	const response = await srProjectApiV3.get(url, {
		params: {filtersDTO, analysisVersionMap},
	})
	return response.data
}

export async function fetchClassificationsByWatchlist(
	selectedWatchlist: WatchlistEntity,
): Promise<Record<string, number[]>> {
	const url = `classifications/byWatchlist/${encodeURIComponent(selectedWatchlist._id)}/for-colorizing-model-elements`
	try {
		const response = await srProjectApiV3.get(url)
		return response.data
	} catch (e) {
		console.log('No published analysis yet.', e)
		return {}
	}
}

export async function fetchClassificationsByForgeIds(
	analysisView: AnalysisViewEntityPopulated,
	forgeObjectIds: number[],
	analysisVersionMap: AnalysisVersionMap,
): Promise<ClassificationEntity[]> {
	const path = await byForgeObjectIdsPath(analysisView._id)
	return srClassificationApiV3
		.get(path, {
			params: {forgeObjectIds, analysisVersionMap},
		})
		.then(response => response.data)
}

const lastsByForgeObjectIdsPath = async (tail?: string) =>
	`${await projectPath()}lastsByForgeObjectIds/${tail ? `${tail}/` : ''}`

export async function fetchLastClassificationsByForgeIds(
	forgeObjectIds: number[],
): Promise<Record<number, ClassificationEntity>> {
	const path = await lastsByForgeObjectIdsPath()
	return srClassificationApiV3
		.get(path, {
			params: {forgeObjectIds},
		})
		.then(response => response.data)
}

export const approveClassificationByForgeId = async (analysisViewId: string, forgeId: number) => {
	await srProjectApiV3.post(`classifications/byAnalysisView/${analysisViewId}/${forgeId}/approve`)
}

export const unapproveClassificationByForgeId = async (analysisViewId: string, forgeId: number) => {
	await srProjectApiV3.post(`classifications/byAnalysisView/${analysisViewId}/${forgeId}/unapprove`)
}

export const approveClassifications = async (analysisViewId: string, filtersDTO: FiltersEntity) => {
	await srProjectApiV3.post(`classifications/byAnalysisView/${analysisViewId}/approve`, {filter: filtersDTO})
}

export const unapproveClassifications = async (analysisViewId: string, filtersDTO: FiltersEntity) => {
	await srProjectApiV3.post(`classifications/byAnalysisView/${analysisViewId}/unapprove`, {filter: filtersDTO})
}

export async function getElementStatesByForgeObjectIds(
	selectedWatchlist: WatchlistEntity | null,
	watchlistElementsFilter: WatchlistElementsFilter,
	selectedProject: UserProject,
	selectedAnalysisView: AnalysisViewEntityPopulated | null,
	analysisVersionMap: AnalysisVersionMap,
	filter: FiltersEntity,
	enableUnderConstruction: boolean,
	approvedFilter: null | boolean,
	simplifiedLocation: SimplifiedLocation,
) {
	switch (simplifiedLocation) {
		case 'quality': {
			const classifications = await fetchClassificationsForColorizingModelElements(
				selectedAnalysisView!,
				analysisVersionMap,
				toFiltersDTO(
					filter,
					selectedProject.floorMapping,
					selectedProject.elementTypeMapping,
					enableUnderConstruction,
					approvedFilter,
				),
			)
			return {classifications, isolateAll: false}
		}
		case 'watchlist-list':
			return {classifications: {}, isolateAll: true}
		case 'watchlist-create-edit': {
			if (selectedWatchlist?.source.mode === 'csv') {
				const classifications = await fetchClassificationsByWatchlist(selectedWatchlist)
				return {classifications, isolateAll: false}
			} else if (hasElementFilters(watchlistElementsFilter)) {
				const forgeObjectIds = await fetchForgeObjectIdsByFilterFromLatestModel(
					watchlistElementsFilterToDTO(watchlistElementsFilter, selectedProject),
				)
				return {classifications: {default: forgeObjectIds}, isolateAll: false}
			} else {
				return {classifications: {}, isolateAll: true}
			}
		}
		case 'watchlist-detail': {
			const classifications = selectedWatchlist ? await fetchClassificationsByWatchlist(selectedWatchlist) : {}
			return {classifications, isolateAll: false}
		}
	}
}
