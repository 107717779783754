import {useQuery} from 'react-query'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {fetchModelById} from './model.api.v3'
import {ModelEntity} from './model.entities'
import {AxiosError} from 'axios'

export function useModelById(modelId: ModelEntity['_id'] | undefined) {
	const project = useCurrentProject()
	return useQuery<ModelEntity, AxiosError>(
		['models', 'modelById', project?._id, modelId],
		() => fetchModelById(project!._id, modelId!),
		{
			enabled: !!(project && modelId),
		},
	)
}
