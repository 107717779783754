import React, {useState} from 'react'
import {Box} from 'grommet'
import {SRTextInput} from 'sr-react-commons'
import {FormSearch} from 'grommet-icons'

export type SearchBoxProps = {
	placeholder: string
	onChange: (value: string) => void
}

export const SearchBox = (props: SearchBoxProps) => {
	const [search, setSearch] = useState<string>('')
	const onChange = (value: string) => {
		setSearch(value)
		props.onChange(value)
	}
	return (
		<Box width={'medium'}>
			<SRTextInput
				icon={<FormSearch />}
				placeholder={props.placeholder}
				onChange={event => onChange(event.target.value)}
				value={search}
				size={'medium'}
			/>
		</Box>
	)
}
