import {FiltersEntity} from '../../../FilterPresets/entities/filter-preset.entities'
import styled from 'styled-components'
import {Box} from 'grommet'
import {RootModelsFilter} from '../../../FilterPresets/components/filters/RootModels/RootModelsFilter'
import {FloorFilter} from '../../../FilterPresets/components/filters/Floor/FloorFilter'
import React from 'react'
import {WatchlistFilter} from '../../../Watchlist/entities/watchlist.entities'
import {QueryObserverResult} from 'react-query'
import {AxiosError} from 'axios'

export const DashboardFiltersPresenter = styled(
	({
		displayRootModelFilter,
		elementRootModelOptions,
		elementFloorsOptions,
		setFilter,
		filter,
		className,
	}: {
		displayRootModelFilter: boolean
		elementRootModelOptions: QueryObserverResult<{value: string; label: string}[], AxiosError>
		elementFloorsOptions: QueryObserverResult<{value: string; label: string}[], AxiosError>
		setFilter: <K extends keyof FiltersEntity>(filterKey: K, value: FiltersEntity[K]) => void
		filter: WatchlistFilter
		className?: string
	}) => {
		return (
			<Box className={className} direction={'row'} justify="between" gap={'xsmall'}>
				{displayRootModelFilter && (
					<RootModelsFilter
						rootModelOptions={elementRootModelOptions.data}
						filters={filter as FiltersEntity}
						setFilter={setFilter}
						isError={elementRootModelOptions.isError}
						isLoading={elementRootModelOptions.isLoading}
						readonly={false}
						showTitle={false}
					/>
				)}
				<FloorFilter
					filters={filter}
					floorOptions={elementFloorsOptions.data}
					isError={elementFloorsOptions.isError}
					isLoading={elementFloorsOptions.isLoading}
					readonly={false}
					setFilter={setFilter}
					showTitle={false}
				/>
			</Box>
		)
	},
)`
	button {
		background: white;
		height: 40px;
	}
`
