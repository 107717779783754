import html2canvas from 'html2canvas'
import {isViewerGui} from '../components/Viewer.Components/Viewing.Extension.ViewerScreenshots/ViewerScreenshotContent'
import {ignoreShadowRootElements} from '../components/AddToIssueButton/AddToIssueBox'

export function captureViewerContainerAsCanvas() {
	const domElement = document.querySelector<HTMLElement>('#viewer-container')
	return (
		domElement &&
		html2canvas(domElement, {
			ignoreElements: element =>
				ignoreShadowRootElements(element) ||
				isViewerGui(element) ||
				(element.hasAttribute('data-hide-on-screenshot') && element.getAttribute('data-hide-on-screenshot') === 'true'),
			onclone: clonedDoc => {
				const wrapper = clonedDoc.getElementById('viewer-container')
				const wrapperOriginal = domElement.getBoundingClientRect()
				if (wrapper) {
					wrapper.style.height = `${wrapperOriginal.height}px`
					wrapper.style.width = `${wrapperOriginal.width}px`
				}
			},
		})
	)
}
