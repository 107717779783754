import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {UserProject} from '../../../reducers/userProfile'
import {getForgeHubProjects} from '../forge-integration.api'
import {ForgeHub, ForgeProject} from '../forge-integration.entities'
import {useGlobalNotifications} from '../../GlobalNotifications'

export function useForgeIntegrationHubProjects(project: UserProject | null, forgeHub: ForgeHub | null) {
	const {notifyError} = useGlobalNotifications()
	return useQuery<ForgeProject[], AxiosError>(
		['forge-integration', 'forge-integration.hub-projects', project?._id, forgeHub?.id],
		() => getForgeHubProjects(project!._id, forgeHub!.id),
		{
			enabled: !!(project && forgeHub),
			onError: () =>
				notifyError(
					'Unable to connect to Autodesk. An unexpected error occurred while trying to connect to your Autodesk project.',
				),
		},
	)
}
