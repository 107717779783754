import _ from 'lodash'
import {TreeSelect} from '../../../../../components/TreeSelect/TreeSelect'
import React, {ReactNode, useMemo} from 'react'
import {FilterDropdownButton} from '../FilterDropdownButton'
import {Box, Text} from 'grommet'
import {Selection, SRSelectOptionsSectionHeader} from 'sr-react-commons'
import {LoadingWrapper} from '../../../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {CheckBoxListItem} from '../../../../../components/TreeSelect/CheckBoxListItem'
import {formatValueForModelElementFilters} from '../filter.helpers'

function getUnavailableFiltersForElementTypes(elementTypes: string[], availableElementTypes: string[]) {
	return elementTypes.filter(value => !availableElementTypes.some(available => available.startsWith(value)))
}

export function ElementTypeFilter({
	filters,
	setFilter,
	readonly,
	isLoading,
	isError,
	elementTypes,
}: {
	filters: {elementTypes: string[] | null}
	setFilter: (key: 'elementTypes', value: string[] | null) => void
	readonly: boolean
	isLoading: boolean
	isError: boolean
	elementTypes: string[] | undefined
}) {
	const unavailableFilters = useMemo(
		() =>
			filters.elementTypes && elementTypes
				? getUnavailableFiltersForElementTypes(filters.elementTypes, elementTypes)
				: [],
		[filters.elementTypes, elementTypes],
	)
	const removeElementType = (elementType: string) => {
		const ids = _.without(filters.elementTypes, elementType)
		setFilter('elementTypes', ids.length ? ids : null)
	}
	const elementTypesValueFormatter = (
		selections: Selection[],
		unavailableFilters: string[],
		removeElementType: (filter: string) => void,
	) => {
		const value = formatValueForModelElementFilters(selections, unavailableFilters, removeElementType)
		return _.isEmpty(value) ? '' : value
	}
	const selections: Selection[] =
		filters.elementTypes && filters.elementTypes.length
			? filters.elementTypes.map(elementType => ({
					label: _.last(elementType.split(':')),
					value: elementType,
			  }))
			: []
	return (
		<Box fill>
			<Text weight="bold" size="small">
				Element Types
			</Text>
			<LoadingWrapper
				{...{isLoading, isError}}
				errorMsg="We couldn't display the element types selector"
				spinnerSize="small"
			>
				{elementTypes && (
					<Box>
						<FilterDropdownButton
							placeholder="All element types displayed"
							disabled={readonly}
							label={elementTypesValueFormatter(selections, unavailableFilters, removeElementType)}
						>
							<Box height={{max: '600px'}} flex={false} overflow={'hidden'}>
								<Box height={{max: '300px'}} overflow={{vertical: 'auto'}}>
									<TreeSelect
										treeKeys={elementTypes}
										selectedKeys={filters.elementTypes || []}
										onChange={newSelected => setFilter('elementTypes', newSelected)}
									/>
								</Box>
								{unavailableFilters.length > 0 && (
									<>
										<SRSelectOptionsSectionHeader pad={{horizontal: 'xsmall'}} sectionText={'Unavailable filters'} />
										<Box
											pad={{horizontal: 'xxsmall', vertical: 'xsmall'}}
											height={{max: '150px'}}
											overflow={{vertical: 'auto'}}
											flex={false}
										>
											{unavailableFilters.map(filter => (
												<CheckBoxListItem
													key={filter}
													label={filter}
													titleFormatter={(label: string) => label.replace(/:/g, ' / ')}
													labelFormatter={(label: string) =>
														label
															.split(':')
															.map<ReactNode>(l => <span>{l}</span>)
															.reduce((acc, next) => [acc, <strong> / </strong>, next])
													}
													checked={true}
													expanded={false}
													onChecked={() => removeElementType(filter)}
													onExpand={() => {}}
													isLeaf={true}
												/>
											))}
										</Box>
									</>
								)}
							</Box>
						</FilterDropdownButton>
					</Box>
				)}
			</LoadingWrapper>
		</Box>
	)
}
