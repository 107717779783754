import {buildAxios} from '../../api/api-factory'
import {config} from '../../config'
import {UserProject} from '../../reducers/userProfile'
import {
	ForgeFileType,
	ForgeFolder,
	ForgeHub,
	ForgeIntegrationEntity,
	ForgeIntegrationHasValidAccess,
	ForgeItem,
	ForgeItemOrFolder,
	ForgeProject,
} from './forge-integration.entities'
import {ProjectFileEntity} from '../../api/files.api'
import {ForgeOauthError, ForgeOauthErrorTypes} from './oauth/ForgeOauthError'

export const featureApiV3 = buildAxios(`${config.sr.backendUrl}forge-integration/v3/`)

export const getHasValidAccess = async (projectId: UserProject['_id']): Promise<ForgeIntegrationHasValidAccess> =>
	(await featureApiV3.get(`projects/${projectId}/has_valid_access`)).data

export const postForgeAuthorizationFromCodeExchange = async (
	projectId: UserProject['_id'],
	code: string,
): Promise<void> => await featureApiV3.post(`projects/${projectId}/oauth/code_exchange`, {code})

export const getForgeHubs = async (projectId: UserProject['_id']): Promise<ForgeHub[]> =>
	(await featureApiV3.get(`projects/${projectId}/hubs`)).data

export const getForgeHubProjects = async (
	projectId: UserProject['_id'],
	hubId: ForgeHub['id'],
): Promise<ForgeProject[]> => (await featureApiV3.get(`projects/${projectId}/hubs/${hubId}/projects`)).data

export const getForgeFolderContents = async (
	projectId: UserProject['_id'],
	folderId: ForgeFolder['id'] | 'root',
	fileType: ForgeFileType,
): Promise<ForgeItemOrFolder[]> => {
	try {
		const {data} = await featureApiV3.get<ForgeItemOrFolder[]>(
			`projects/${projectId}/folders/contents/${folderId}?filter=${JSON.stringify({fileType})}`,
		)
		return data.map(el => ({...el, lastModifiedTime: new Date(el.lastModifiedTime)}))
	} catch (e) {
		if (e.response?.status === 404) {
			throw new ForgeOauthError(ForgeOauthErrorTypes.unable_access_project)
		}
		throw e
	}
}

export const getCurrentForgeIntegration = async (
	projectId: UserProject['_id'],
): Promise<ForgeIntegrationEntity | null> => (await featureApiV3.get(`projects/${projectId}/integration/current`)).data

export const deleteCurrentForgeIntegration = async (projectId: UserProject['_id']): Promise<void> =>
	(await featureApiV3.delete(`projects/${projectId}/integration/current`)).data

export const deleteCurrentLogin = async (projectId: UserProject['_id']): Promise<void> =>
	(await featureApiV3.delete(`projects/${projectId}/user_access`)).data

export const createForgeIntegration = async (
	projectId: UserProject['_id'],
	forgeHubId: string,
	forgeProjectId: string,
): Promise<ForgeIntegrationEntity> =>
	(await featureApiV3.post(`projects/${projectId}/integration`, {forgeHubId, forgeProjectId})).data

export const copyForgeItems = async (
	projectId: UserProject['_id'],
	itemIds: ForgeItem['id'][],
): Promise<ProjectFileEntity[]> => (await featureApiV3.post(`projects/${projectId}/items/copy`, {itemIds})).data
