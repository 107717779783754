import {Observable} from 'rxjs'
import {UploadFileDTO} from './file-uploader.types'
import {fileUploadInWorkers, FileUploadInWorkersControls} from './file-uploader.worker-helper'

type FileQueueElement = FileUploadInWorkersControls & {fileId: string; filename: string}

class FileUploaderQueueService {
	token: string | null = null
	fileQueue: FileQueueElement[] = []
	maxConcurrentFiles = 2
	maxConcurrencyPerFile = 3

	constructor(protected getToken: () => Promise<string>) {
		this.setToken().catch(console.error)
		const w = new WeakRef(this)
		const t = setInterval(() => {
			const self = w.deref()
			if (self) {
				this.setToken().catch(console.error)
			} else {
				clearInterval(t)
			}
		}, 60 * 1000)
	}

	setToken(): Promise<string> {
		return this.getToken().then(value => (this.token = value))
	}

	cancelFile(fileId: string) {
		this.fileQueue
			.filter(f => f.fileId === fileId)
			.forEach(f => {
				f.cancel()
			})
		this.fileQueue = this.fileQueue.filter(f => f.fileId !== fileId)
	}

	cancelFileByFilename(filename: string) {
		this.fileQueue
			.filter(f => f.filename === filename)
			.forEach(f => {
				f.cancel()
			})
		this.fileQueue = this.fileQueue.filter(f => f.filename !== filename)
	}

	numStarted(): number {
		return this.fileQueue.filter(f => f.started()).length
	}

	startNextInQueue(): void {
		const elem = this.fileQueue.find(f => !f.started())
		elem?.start()
	}

	public addFileToUploadQueue(dto: UploadFileDTO): Observable<number> {
		return new Observable<number>(observer => {
			const controls = fileUploadInWorkers(
				observer,
				this.getToken,
				dto,
				Math.min(dto.numberOfParts, this.maxConcurrencyPerFile),
			)
			this.addControlsToQueue(dto.fileId, dto.file.name, controls)
		})
	}

	private addControlsToQueue(fileId: string, filename: string, controls: FileUploadInWorkersControls): void {
		this.fileQueue.push({...controls, fileId, filename})
		if (this.numStarted() < this.maxConcurrentFiles) {
			controls.start()
		}
		controls.terminated.then(() => {
			this.fileQueue = this.fileQueue.filter(f => f.fileId !== fileId)
			this.startNextInQueue()
		})
	}
}

export const createFileUploaderQueue = (getToken: () => Promise<string>) => {
	return new FileUploaderQueueService(getToken)
}
