import {ActionCreatorWithPayload, Draft} from '@reduxjs/toolkit'
import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'

export type PaginationState = {
	pagination: {
		currentPage: number
		pageSize: number
		totalRecords: number
	}
}

export function buildInitialPaginationState<State extends PaginationState>(
	pageSize = 20,
): Pick<State, keyof PaginationState> {
	return {pagination: {pageSize, currentPage: 0, totalRecords: 0}}
}

export type PaginationActions = {
	setPage: ActionCreatorWithPayload<{page: number}>
	setPageSize: ActionCreatorWithPayload<{pageSize: number}>
	setTotalRecords: ActionCreatorWithPayload<{totalRecords: number}>
	resetPagination: ActionCreatorWithPayload<void>
}

export function buildPaginationStateReducer<State extends PaginationState>() {
	return {
		setPage: (state: Draft<State>, action: ReturnType<PaginationActions['setPage']>) => {
			state.pagination.currentPage = action.payload.page
		},
		setPageSize: (state: Draft<State>, action: ReturnType<PaginationActions['setPageSize']>) => {
			state.pagination.pageSize = action.payload.pageSize
			state.pagination.currentPage = 0
		},
		setTotalRecords: (state: Draft<State>, action: ReturnType<PaginationActions['setTotalRecords']>) => {
			state.pagination.totalRecords = action.payload.totalRecords
		},
		resetPagination: (state: Draft<State>) => {
			state.pagination.totalRecords = 0
			state.pagination.currentPage = 0
		},
	}
}

export type PageState = {
	pageIndex: number
	pageSize: number
	totalRecords: number
	setPageSize: (pageSize: number) => void
	gotoPage: (pageIndex: number) => void
	canPreviousPage: boolean
	canNextPage: boolean
	nextPage: () => void
	previousPage: () => void
	pageCount: number | undefined
	setTotalRecords: (totalRecords: number) => void
	resetPagination: () => void
}

export function usePageState(
	{currentPage, pageSize, totalRecords}: PaginationState['pagination'],
	actions: PaginationActions,
): PageState {
	const dispatch = useDispatch()
	const gotoPage = useCallback((newCurrentPage: number) => dispatch(actions.setPage({page: newCurrentPage})), [
		actions,
		dispatch,
	])
	const pageCount = totalRecords && Math.ceil(totalRecords / pageSize)
	return useMemo(
		() => ({
			gotoPage,
			canNextPage: pageCount ? currentPage < pageCount - 1 : false,
			canPreviousPage: currentPage > 0,
			pageCount: pageCount,
			pageIndex: currentPage,
			pageSize: pageSize,
			totalRecords: totalRecords,
			nextPage: () => gotoPage(currentPage + 1),
			previousPage: () => gotoPage(currentPage - 1),
			setPageSize: (newPageSize: number) => dispatch(actions.setPageSize({pageSize: newPageSize})),
			setTotalRecords: (totalRecords: number) => dispatch(actions.setTotalRecords({totalRecords})),
			resetPagination: () => dispatch(actions.resetPagination()),
		}),
		[actions, currentPage, dispatch, gotoPage, pageCount, pageSize, totalRecords],
	)
}
