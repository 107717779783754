import React from 'react'
import {Box, Image} from 'grommet'
import {RotateLeft} from 'grommet-icons'

import {SREmptyView} from 'sr-react-commons'

type EmptyViewProps = {
	onAction: () => void
}

export const InsightsEmptyView = ({onAction}: EmptyViewProps) => {
	return (
		<Box height={'100%'}>
			<SREmptyView
				onAction={onAction}
				actionText="Reset filters"
				asset={<Image src="/images/empty-states/empty-insights.svg" />}
				title="No information matches your current filter criteria"
				buttonIcon={<RotateLeft size="small" />}
				buttonTextSize="large"
				buttonReverse={true}
				message={`There is no information to display for the current active filters.\nPlease reset or adjust your filters to find the information you need.`}
			/>
		</Box>
	)
}
