import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {UserProject} from '../../../reducers/userProfile'
import {getForgeHubs} from '../forge-integration.api'
import {ForgeHub} from '../forge-integration.entities'
import {useGlobalNotifications} from '../../GlobalNotifications'

export function useForgeIntegrationHubs(project: UserProject | null) {
	const {notifyError} = useGlobalNotifications()
	return useQuery<ForgeHub[], AxiosError>(
		['forge-integration', 'forge-integration.hubs', project?._id],
		() => getForgeHubs(project!._id),
		{
			enabled: !!project,
			onError: () =>
				notifyError(
					'Unable to connect to Autodesk. An unexpected error occurred while trying to connect to your Autodesk project.',
				),
		},
	)
}
