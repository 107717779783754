import React from 'react'
import {Box, Text} from 'grommet'
import pluralize from 'pluralize'
import {PadType} from 'grommet/utils'

export default function NumberOfElements({
	numberOfElements,
	selected,
	resourceName,
	margin = {horizontal: 'small'},
}: {
	numberOfElements: number
	selected?: number
	resourceName: string
	margin?: PadType
}) {
	return (
		<Box direction="row" alignSelf="end" margin={margin}>
			<Text data-testid="numberOfElementsDisplayed" weight={500}>
				{selected
					? `${selected}/${numberOfElements} ${pluralize(resourceName)} selected`
					: `${numberOfElements} ${pluralize(resourceName, numberOfElements)}`}
			</Text>
		</Box>
	)
}
