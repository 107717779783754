import VerticalToolbarExtension from '../Viewing.Extension.VerticalToolbar'
import './Viewing.Extension.ViewerScreenshots.scss'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Grommet} from 'grommet'
import {theme} from '../../../theme'
import React from 'react'
import {Router} from 'react-router-dom'
import {history} from '../../../router/history'
import {ViewerScreenshotContent} from './ViewerScreenshotContent'
import {QueryClientProvider} from 'react-query'
import {store} from '../../../store/store'
import {VERTICAL_CONTROL_GROUP} from '../Viewing.Extension.VerticalToolbar/Viewing.Extension.VerticalToolbar'
import {getVerticalToolBar} from '../../Viewer/Viewer-helper'
import {defaultQueryClient} from '../../../utilities/query-client.util'
import {SRExtension} from '../SRExtension'

export const VIEWER_SCREENSHOTS_EXTENSION = 'Viewing.Extension.ViewerScreenshots'

const id = 'ScreenshotsPanel'
const title = 'Capture viewer'
const options: Autodesk.Viewing.UI.DockingPanelOptions = {
	shadow: false,
	addFooter: false,
}

class ViewerScreenshotsPanel extends Autodesk.Viewing.UI.DockingPanel {
	private reactMounted = false
	private readonly DOMContent: HTMLDivElement
	constructor(container: HTMLElement) {
		super(container, id, title, options)
		this.container.style.top = '100px'
		this.container.style.left = '100px'
		this.DOMContent = document.createElement('div')
		this.DOMContent.className = 'docking-panel-content'
		this.container.appendChild(this.DOMContent)
	}

	setVisible(show: boolean) {
		super.setVisible(show)
		if (show) {
			ReactDOM.render(
				<Provider store={store}>
					<Router history={history}>
						<Grommet theme={theme}>
							<QueryClientProvider client={defaultQueryClient}>
								<ViewerScreenshotContent />
							</QueryClientProvider>
						</Grommet>
					</Router>
				</Provider>,
				this.DOMContent,
			)
			this.reactMounted = true
		} else {
			if (this.reactMounted) {
				ReactDOM.unmountComponentAtNode(this.DOMContent)
				this.reactMounted = false
			}
		}
	}
}

class ViewerScreenshotsExtension extends SRExtension {
	private active: boolean
	private subToolbar: any
	private toolbarButton?: Autodesk.Viewing.UI.Button
	private panel?: ViewerScreenshotsPanel

	constructor(viewer: Autodesk.Viewing.Viewer3D, options: any) {
		super(viewer as Autodesk.Viewing.GuiViewer3D, options)
		this.active = false
	}

	getNames() {
		// Override required for registering extension as tool
		return [ViewerScreenshotsExtension]
	}

	load() {
		if (
			this.viewer.isExtensionLoaded('Viewing.Extension.VerticalToolbar') &&
			(this.viewer.getExtension('Viewing.Extension.VerticalToolbar') as VerticalToolbarExtension).SRVerticalToolbar
		) {
			this.createUI()

			// Register and activate extension itself as a tool in order to handle mouse events
			this.viewer.toolController.registerTool(this)
			this.viewer.toolController.activateTool(VIEWER_SCREENSHOTS_EXTENSION)

			return true
		} else {
			console.error('Cannot load extension because the vertical toolbar has not been loaded before.')
			return false
		}
	}

	unload(): boolean {
		const verticalToolBar = this.viewer.getExtension('Viewing.Extension.VerticalToolbar') as VerticalToolbarExtension
		if (verticalToolBar && verticalToolBar.SRVerticalToolbar) {
			verticalToolBar.SRVerticalToolbar.removeControl(this.subToolbar)
		}

		this.viewer.toolController.deactivateTool(VIEWER_SCREENSHOTS_EXTENSION)
		this.viewer.toolController.deregisterTool(this)

		return super.unload()
	}

	private createUI() {
		// Toolbar button UI bindings
		const ScreenshotsToolbarButton = new Autodesk.Viewing.UI.Button('ScreenshotsToolbarButton')
		ScreenshotsToolbarButton.addClass('ScreenshotsToolbarButton')
		ScreenshotsToolbarButton.setToolTip('Capture viewer screenshots')

		ScreenshotsToolbarButton.onClick = () => {
			this.toggleExtension(!this.active)
		}

		// SubToolbar
		this.subToolbar =
			(getVerticalToolBar(this.viewer).getControl(VERTICAL_CONTROL_GROUP) as Autodesk.Viewing.UI.ControlGroup) ||
			new Autodesk.Viewing.UI.ControlGroup(VERTICAL_CONTROL_GROUP)

		this.subToolbar.addControl(ScreenshotsToolbarButton, {index: 2})

		this.toolbarButton = ScreenshotsToolbarButton

		// initialize panel
		this.panel = new ViewerScreenshotsPanel(this.viewer.container as HTMLElement)
		this.panel.setVisible(false)
		this.panel.addVisibilityListener(isVisible => {
			this.active = isVisible
			if (!isVisible) {
				this.toggleButton(false)
			}
		})
	}

	private toggleExtension(active: boolean) {
		this.active = active
		if (this.panel) {
			this.toggleButton(active)
			this.panel.setVisible(active)
		}
	}

	private toggleButton(active: boolean) {
		if (this.toolbarButton) {
			if (active) {
				this.toolbarButton.addClass('active')
			} else {
				this.toolbarButton.removeClass('active')
			}
		}
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(VIEWER_SCREENSHOTS_EXTENSION, ViewerScreenshotsExtension)
