import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ManagementDashboardScansPage} from '../../scans/ManagementDashboardScansPage'
import {ManagementDashboardModelsPage} from '../../models/ManagementDashboardModelsPage'
import {useManagementDashboardProject} from '../management-dashboad.slice'
import {CustomGuardedRoute} from '../../../../components/CommonsCandidate/AccessControl/GuardedRoute'
import {LoadingWrapper} from '../../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {useIsManagementDashboardPageEnabled} from '../management-dashboard-page.utils'
import {ManagementDashboardIntegrationsPage} from '../../integrations/ManagementDashboardIntegrationsPage'
import {ManagementDashboardDocumentsPage} from '../../documents/ManagementDashboardDocumentsPage'
import {GoBackToSelectProjectComponent} from '../../../../components/CommonsCandidate/AccessControl/GoBackToSelectProjectWrapper'
import {ManagementDashboardAnalysisRequestPage} from '../../analysisRequest/ManagementDashboardAnalysisRequestPage'

export const ManagementDashboard = () => {
	const {isProjectLoading, project} = useManagementDashboardProject()
	const isPageEnabled = useIsManagementDashboardPageEnabled()
	return (
		<LoadingWrapper {...{isLoading: isProjectLoading}} fill>
			{!isProjectLoading && !project ? (
				<GoBackToSelectProjectComponent
					title={'Unauthorized'}
					reason={"You don't have permission to access this project"}
				/>
			) : (
				<Switch>
					<CustomGuardedRoute path={'/*/*/analysisrequest*'} customFn={() => isPageEnabled('analysisrequest')}>
						<ManagementDashboardAnalysisRequestPage />
					</CustomGuardedRoute>
					<CustomGuardedRoute path={'/*/*/scans'} customFn={() => isPageEnabled('scans')}>
						<ManagementDashboardScansPage />
					</CustomGuardedRoute>
					<CustomGuardedRoute path={'/*/*/models'} customFn={() => isPageEnabled('models')}>
						<ManagementDashboardModelsPage />
					</CustomGuardedRoute>
					<CustomGuardedRoute path={'/*/*/documents'} customFn={() => isPageEnabled('documents')}>
						<ManagementDashboardDocumentsPage />
					</CustomGuardedRoute>
					<CustomGuardedRoute path={'/*/*/integrations'} customFn={() => isPageEnabled('integrations')}>
						<ManagementDashboardIntegrationsPage />
					</CustomGuardedRoute>
					<Route path={'/*/*/home'}>
						{isPageEnabled('analysisrequest') ? (
							<Redirect to={`/manage/${project!._id}/analysisrequest`} />
						) : isPageEnabled('scans') ? (
							<Redirect to={`/manage/${project!._id}/scans`} />
						) : isPageEnabled('models') ? (
							<Redirect to={`/manage/${project!._id}/models`} />
						) : isPageEnabled('documents') ? (
							<Redirect to={`/manage/${project!._id}/documents`} />
						) : isPageEnabled('integrations') ? (
							<Redirect to={`/manage/${project!._id}/integrations`} />
						) : (
							<Redirect to={'/select-project'} />
						)}
					</Route>
					<Route>
						<Redirect to={'/select-project'} />
					</Route>
				</Switch>
			)}
		</LoadingWrapper>
	)
}
