import {useLocation} from 'react-router-dom'

export type SidebarWidth = '0px' | '480px'

export function sidebarWidth(pathname: string): SidebarWidth {
	if (
		pathname.includes('/insights') ||
		pathname.includes('/quality') ||
		pathname.includes('/work-packages') ||
		pathname.includes('/issues')
	) {
		return '480px'
	} else return '0px'
}

export function useSidebarWidth(): SidebarWidth {
	const location = useLocation()
	return sidebarWidth(location.pathname)
}
