import {Box} from 'grommet'
import {SRFileDownloadButton, SRPlainButton} from 'sr-react-commons'
import {DocumentCsv, DocumentPdf} from 'grommet-icons'
import React from 'react'

// This is a hack until we have a good solution for Drop Down Menu Item Buttons
// It is necessary because otherwise the button icon and label would be horizontally centered
const BUTTON_LABEL_WIDTH = '160px'
const FixedWidthLabel = (props: {label: string}) => (
	<Box align={'start'} width={BUTTON_LABEL_WIDTH}>
		{props.label}
	</Box>
)

export function WorkPackageDetailExportMenuActionList(props: {
	onExportAsPDF: () => void
	onExportToCSVProps: {
		fetchFile: () => Promise<Blob>
		filename: string
	}
}) {
	return (
		<Box flex={false}>
			<SRPlainButton
				icon={<DocumentPdf />}
				label={<FixedWidthLabel label="Export report as PDF" />}
				onClick={props.onExportAsPDF}
			/>
			<SRFileDownloadButton
				buttonClass={SRPlainButton}
				icon={<DocumentCsv />}
				label={<FixedWidthLabel label="Export CSV data table" />}
				getFile={props.onExportToCSVProps.fetchFile}
				fileName={props.onExportToCSVProps.filename}
			/>
		</Box>
	)
}
