import React from 'react'
import {Box} from 'grommet'
import {useSelector} from 'react-redux'
import {DataTable} from './DataTable'
import {RootState} from '../../reducers'
import {FilterPresets} from '../../features/FilterPresets/components/FilterPresets'
import {useClassificationPageState, useClassificationSortState} from '../../reducers/classification.slice'
import {AnalysisViewStatus} from '../../features/AnalysisView/Status/AnalysisViewStatus'
import {useFeatureEnabled} from '../../features/FeatureFlags/FeatureFlagsProvider'
import {SuggestionsBody} from '../../features/ElementsOfInterest/Suggestion/components/SuggestionsBody'

export function QualityBody() {
	const filters = useSelector((state: RootState) => state.classification.filter)
	const pageState = useClassificationPageState()
	const sortState = useClassificationSortState()
	const eoiEnabled = useFeatureEnabled('elementsOfInterest')
	return (
		<Box gap={'small'} data-testid="overviewPanel" fill>
			<Box gap={'small'} flex={false} height={{min: 'calc(100% - 40px)'}}>
				<AnalysisViewStatus />
				{eoiEnabled ? <SuggestionsBody /> : null}
				<FilterPresets mode="overview" />
				<DataTable filters={filters} sortState={sortState} pageState={pageState} />
			</Box>
		</Box>
	)
}
