import {
	ProgressMonitoringData,
	progressMonitoringDataLabels,
} from '../../../Watchlist/components/charts/BreakDownDataTable'
import {VictoryPie} from 'victory'
import {WatchlistClassification, watchlistClassificationColor} from '../../../Watchlist/watchlist.utils'
import React from 'react'
import {getDisplayValueForClassificationStatus} from '../../../Classification/classification.helpers'
import {useFilterStateForMode} from '../../../FilterPresets/components/filters/Filters'
import {BreakDownDonutDataProps} from '../../../Watchlist/components/charts/BreakDownDonutChart'
import {FILL_OPACITY, HOVER_FILL_OPACITY, setChartItemOpacity} from '../../InsightDashboard-helper'

//TODO standardize how donut chart data is generated between insights and progress monitoring
const buildDonutChartData = (data: ProgressMonitoringData, enableUnderConstruction: boolean) => {
	const labels = progressMonitoringDataLabels(enableUnderConstruction)

	return labels.map(label => {
		return {x: getDisplayValueForClassificationStatus(label) as WatchlistClassification, y: data[label]}
	})
}

const convertStatusForFiltering = (status: string) => {
	return status.replace(/ /g, '_').toLowerCase()
}

export function QualityBreakdownPieChart(props: BreakDownDonutDataProps): JSX.Element {
	const FILTER_MODE = 'overview'
	const updatedData = buildDonutChartData(props.data, props.enableUnderConstruction)
	const {setPartialFilter} = useFilterStateForMode(FILTER_MODE)
	return (
		<VictoryPie
			style={{
				data: {
					fillOpacity: FILL_OPACITY,
					stroke: 'none',
					strokeWidth: 0.5,
				},
				labels: {display: 'none'},
			}}
			events={[
				{
					target: 'data',
					eventHandlers: {
						onClick: (event, data) => {
							const statusFilter = data?.datum?.xName
							setPartialFilter({statuses: statusFilter ? [convertStatusForFiltering(statusFilter as string)] : null})
						},
						onMouseOver: evt => {
							setChartItemOpacity(evt, HOVER_FILL_OPACITY)
							evt.currentTarget.style['fill-opacity'] = FILL_OPACITY
							evt.currentTarget.style['cursor'] = 'pointer'
						},
						onMouseOut: evt => {
							setChartItemOpacity(evt, FILL_OPACITY)
						},
					},
				},
			]}
			colorScale={
				props.enableUnderConstruction
					? [
							watchlistClassificationColor('Verified'),
							watchlistClassificationColor('Deviated'),
							watchlistClassificationColor('Under construction'),
							watchlistClassificationColor('Missing'),
							watchlistClassificationColor('No data'),
					  ]
					: [
							watchlistClassificationColor('Verified'),
							watchlistClassificationColor('Deviated'),
							watchlistClassificationColor('Missing'),
							watchlistClassificationColor('No data'),
					  ]
			}
			data={updatedData}
			innerRadius={75}
			radius={185}
		/>
	)
}
