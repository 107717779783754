import {Box, Text} from 'grommet'
import React, {useCallback, useMemo} from 'react'
import {Selection, SRMultiSelect, SRMultiSelectValueFormatter} from 'sr-react-commons'
import {LoadingWrapper} from '../../../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {
	buildSelectOptionsWithUnavailableFiltersSection,
	formatValueForModelElementFilters,
	getUnavailableFilters,
} from '../filter.helpers'

export function FloorFilter({
	filters,
	setFilter,
	isLoading,
	isError,
	readonly,
	floorOptions,
	showTitle = true,
}: {
	filters: {floors: string[] | null}
	setFilter: (key: 'floors', value: string[] | null) => void
	readonly: boolean
	isLoading: boolean
	isError: boolean
	floorOptions: {value: string; label: string}[] | undefined
	showTitle?: boolean
}) {
	const unavailableFilters = useMemo(
		() => (filters.floors && floorOptions ? getUnavailableFilters(filters.floors, floorOptions) : []),
		[filters.floors, floorOptions],
	)
	const onRemoveOption = useCallback(
		(toBeRemoved: string) =>
			setFilter(
				'floors',
				filters.floors!.filter(activeFilter => activeFilter !== toBeRemoved),
			),
		[filters.floors, setFilter],
	)
	const defaultValueFormatter: SRMultiSelectValueFormatter = useCallback(
		(selections: Selection[]) => formatValueForModelElementFilters(selections, unavailableFilters, onRemoveOption),
		[onRemoveOption, unavailableFilters],
	)
	return (
		<Box fill>
			{showTitle && (
				<Text weight="bold" size="small">
					Floor
				</Text>
			)}
			<LoadingWrapper {...{isLoading, isError}} errorMsg="We couldn't display the floors selector" spinnerSize="small">
				{floorOptions && (
					<SRMultiSelect
						options={buildSelectOptionsWithUnavailableFiltersSection(floorOptions, unavailableFilters)}
						placeholder="All floors displayed"
						size="medium"
						onChange={(selectedOptions: string[]) => setFilter('floors', selectedOptions)}
						value={filters.floors || []}
						searchPlaceholder="Search Floors"
						emptySearchMessage="No Floors Found"
						valueFormatter={defaultValueFormatter}
						showToggleAllButtons
						showSearchInput
						disabled={readonly}
					/>
				)}
			</LoadingWrapper>
		</Box>
	)
}
