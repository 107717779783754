import React, {ReactNode} from 'react'
import {AppBar} from '../../../../../components/AppBar/AppBar'
import {Box} from 'grommet'
import {ManagementDashboardSidebar} from './ManagementDashboardSidebar'
import {ManagementDashboardHeader} from './ManagementDashboardHeader'

export type ManagementDashboardPageLayoutProps = {
	children: ReactNode
}

export const ManagementDashboardPageLayout = ({children}: ManagementDashboardPageLayoutProps) => (
	<Box fill>
		<AppBar />
		<Box fill direction="row">
			<ManagementDashboardSidebar />
			<Box fill direction="column">
				<Box pad={{horizontal: 'xlarge', vertical: 'medium'}}>
					<ManagementDashboardHeader />
				</Box>
				<Box fill pad={{horizontal: 'xlarge', bottom: 'medium'}} overflow={{vertical: 'auto'}}>
					{children}
				</Box>
			</Box>
		</Box>
	</Box>
)
