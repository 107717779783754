import React, {useEffect, useState} from 'react'
import {SR_COLOR_TEXT_LIGHT} from 'sr-react-commons'
import {Bookmark} from 'grommet-icons'
import {Row} from 'react-table'
import {ClassificationTableRow} from './UseClassificationsTable'
import {vote} from '../../features/ElementsOfInterest/Vote/vote.api'
import {createGlobalNotificationsAction as createAction} from '../../features/GlobalNotifications'
import {LoadingWrapper} from 'components/CommonsCandidate/Loading/LoadingWrapper'
import {invalidateGetNextQuery} from 'features/ElementsOfInterest/Suggestion/useGetNextSuggestion'
import {useSelector} from 'react-redux'
import {selectorClassificationState} from 'selectors/classification.selectors'
import styled from 'styled-components'
import {useInvalidateClassifications} from '../../features/Classification/data-management/useInvalidateClassification'
import {store} from '../../store/store'

interface BookmarkTableIconProps {
	isInteresting: boolean | null | undefined
	row: Row<ClassificationTableRow>
}

export const BookmarkStyled = styled(Bookmark)`
	cursor: pointer;
	&.isInteresting {
		stroke: ${SR_COLOR_TEXT_LIGHT};
		path[fill='none'] {
			fill: ${SR_COLOR_TEXT_LIGHT};
		}
		polygon[fill='none'] {
			fill: ${SR_COLOR_TEXT_LIGHT};
		}
	}
`

export const BookmarkTableIcon = ({isInteresting, row}: BookmarkTableIconProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isFilled, setIsFilled] = useState<boolean>(false)
	const {selectedAnalysisView} = useSelector(selectorClassificationState)
	const invalidateClassifications = useInvalidateClassifications()

	useEffect(() => {
		setIsFilled(isInteresting!)
	}, [isInteresting, isLoading])

	const voteFromTable = async () => {
		if (selectedAnalysisView) {
			setIsLoading(true)
			await vote({
				voteContext: {from: 'quality-table'},
				analysisViewId: selectedAnalysisView._id,
				elementId: row.values.elementId,
				isInteresting: isInteresting ? null : true,
			}).catch(e => {
				console.log('Error trying to bookmark an element ', e.message)
				store.dispatch(createAction.error(`Could not bookmark element, an unexpected error occurred: ${e.message}`))
			})

			await invalidateClassifications()
			await invalidateGetNextQuery(selectedAnalysisView._id)
			setIsLoading(false)
		}
	}

	return (
		<LoadingWrapper isLoading={isLoading} spinnerSize="xsmall">
			<BookmarkStyled
				className={isFilled ? 'isInteresting' : ''}
				size="small"
				onClick={() => voteFromTable()}
				onMouseOver={() => setIsFilled(!isInteresting)}
				onMouseLeave={() => setIsFilled(isInteresting!)}
			/>
		</LoadingWrapper>
	)
}
