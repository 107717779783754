import {createSlice} from '@reduxjs/toolkit'
import {buildFilterStateReducer, buildInitialFilterState, FilterState} from '../../../reducers/base.filter.state'
import {useSelector} from 'react-redux'
import {RootState} from '../../../reducers'

export type WatchlistElementsFilter = {
	floors: string[] | null
	elementTypes: string[] | null
	rootModels: string[] | null
}

type WatchlistFilterElementsSliceState = FilterState<WatchlistElementsFilter>

function buildWatchlistFilterElementsInitialState(): WatchlistFilterElementsSliceState {
	return buildInitialFilterState<WatchlistFilterElementsSliceState>({
		floors: null,
		elementTypes: null,
		rootModels: null,
	})
}

const initialState = buildWatchlistFilterElementsInitialState()

export const watchlistFilterElementsSlice = createSlice({
	name: 'watchlistFilterElements',
	initialState: initialState,
	reducers: {
		...buildFilterStateReducer<WatchlistFilterElementsSliceState>(initialState),
	},
})

export function useWatchlistFilterElementsState() {
	return useSelector((state: RootState) => state.watchlistFilterElementsState)
}

export const hasElementFilters = (filter: WatchlistElementsFilter | null) =>
	filter && (!!filter.elementTypes || !!filter.floors || !!filter.rootModels)
