import React, {useEffect, useRef} from 'react'

export function useMounted() {
	const mountedRef = useRef(false)

	useEffect(() => {
		mountedRef.current = true
		return () => {
			mountedRef.current = false
		}
	}, [])

	return React.useCallback(() => {
		return mountedRef.current
	}, [mountedRef])
}
