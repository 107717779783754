import {buildAxios} from '../../api/api-factory'
import {config} from '../../config'
import {Id} from '../../entities/value-objects'
import {AnalysisRequestEntity} from './analysis-request.entities'
import {identity} from 'lodash'
import {CreateAnalysisRequestsDTO} from './analysis-request.dtos'

export const buildAnalysisRequestProjectApi = (projectId: string) =>
	buildAxios(`${config.sr.backendUrl}analysis/v3/projects/${projectId}`)

const path = (id?: Id, tail?: string) =>
	`/analysis-requests/${id ? `${encodeURIComponent(id)}/` : ''}${tail ? `${tail}/` : ''}`

const buildFilter = (searchText?: string) => {
	const searchIndex = searchText && searchText !== '' && searchText.replace(/\D/g, '')
	return [searchIndex && `index==${searchIndex}`].filter(identity).join(';')
}

export const fetchAnalysisRequests = async (
	projectId: string,
	searchText?: string,
): Promise<AnalysisRequestEntity[]> => {
	const params = {skip: 0, limit: Number.MAX_SAFE_INTEGER, sort: '_id', filter: buildFilter(searchText)}
	const api = buildAnalysisRequestProjectApi(projectId)
	return (await api.get(path(), {params})).data.result
}

export const fetchAnalysisRequest = async (
	projectId: string,
	id: AnalysisRequestEntity['_id'],
): Promise<AnalysisRequestEntity> => {
	const api = buildAnalysisRequestProjectApi(projectId)
	return (await api.get(path(id))).data
}

export const postAnalysisRequest = async (
	projectId: string,
	dto: CreateAnalysisRequestsDTO,
): Promise<AnalysisRequestEntity> => {
	return (await buildAnalysisRequestProjectApi(projectId).post(path(), dto)).data
}
