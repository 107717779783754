import {Add} from 'grommet-icons'
import React from 'react'
import styled from 'styled-components'
import {SRIconButton, SRIconButtonProps} from 'sr-react-commons'

const AddButton = (props: Pick<SRIconButtonProps, 'onClick' | 'textColor'>) => {
	return <SRIconButton title={'Create new issue for this element'} icon={<Add size="xsmall" />} {...props} />
}
export const AddIssueButton = styled(AddButton)`
	padding: 3px;
`
