import React, {useEffect, useState} from 'react'

import {useBasePath} from '../../../../hooks/useBasePath'
import {WatchlistCreatePresenter} from '../create/WatchlistCreatePresenter'
import {goTo, goToFn} from '../../../../router/history'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {apiDeleteWatchlist, updateWatchlist, UpdateWatchlistDTO} from '../../api/watchlist.api'
import {selectorCurrentProgressMonitoring, selectorCurrentWatchlist} from '../../selectors/watchlist.selectors'
import {useGlobalNotifications} from '../../../GlobalNotifications'
import {watchlistSlice} from '../../slices/watchlist.slice'
import {reqErrorMsg} from '../../../../utilities/errorHandleUtilities'
import {getCurrentProgressMonitoringAndWatchlist} from '../../slices/watchlist.thunk'
import {Id} from '../../../../entities/value-objects'
import {useLatestModel} from '../../../../hooks/UseLatestModel'
import {workPackageHasInvalidIds} from '../../watchlist.utils'
import {PayloadError} from 'sr-request-commons'
import {useDisableVerticalToolbar} from '../../../../hooks/useDisableVerticalToolbarButtons'
import {watchlistFilterElementsSlice} from '../../slices/watchlist-filter-elements.slice'
import {LoadingWrapper} from '../../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {useCurrentProject} from '../../../../hooks/useCurrentProject'
import {getMappedElementType} from '../../../../entities/element.entities'

export function WatchlistEdit() {
	const {watchlistId} = useParams<{watchlistId: Id}>()
	const basePath = useBasePath()
	const dispatch = useDispatch()
	const {notifyAlert} = useGlobalNotifications()
	const [missingIds, setMissingIds] = useState<string[] | undefined>(undefined)
	useEffect(() => {
		dispatch(getCurrentProgressMonitoringAndWatchlist(watchlistId!))
		return () => {
			dispatch(watchlistSlice.actions.doWatchlistRemoveCurrent())
		}
	}, [dispatch, watchlistId])
	const watchlist = useSelector(selectorCurrentWatchlist)
	const goToPrevious = goToFn(useBasePath() + 'progress/work-packages')
	const goToDetail = (id: string) => goTo(`${basePath}progress/work-packages/${id}/detail`)
	const submitWatchlist = async (dto: UpdateWatchlistDTO) => {
		try {
			await updateWatchlist(watchlistId!, dto)
			goToDetail(watchlistId)
		} catch (e) {
			if (e instanceof PayloadError && e.responseData.type === 'ProgressMonitoring.CreateWorkPackage.MissingIds') {
				setMissingIds(e.responseData.payload.ids as string[])
			} else {
				notifyAlert(e.message)
			}
		}
	}
	const deleteWatchlist = async (id: string) => {
		try {
			await apiDeleteWatchlist(id)
		} catch (e) {
			notifyAlert(reqErrorMsg(e, 'Error deleting watchlist'))
		}
		dispatch(watchlistSlice.actions.doWatchlistRemoveAll())
		goToPrevious()
	}
	const {isLoading, isError} = useSelector(selectorCurrentProgressMonitoring)
	const project = useCurrentProject()
	const latestModel = useLatestModel()
	useDisableVerticalToolbar()

	useEffect(() => {
		// reset filters when unmounting WatchlistEdit component
		return () => {
			dispatch(watchlistFilterElementsSlice.actions.resetFilter())
		}
	}, [dispatch])

	useEffect(() => {
		// restore filter from watchlist
		if (project && watchlist && watchlist.source.mode === 'filter')
			dispatch(
				watchlistFilterElementsSlice.actions.setFilter({
					filter: {
						floors: watchlist.source.filter.floors,
						elementTypes: watchlist.source.filter.elementTypes
							? watchlist.source.filter.elementTypes.map(elementType =>
									getMappedElementType(elementType, project.elementTypeMapping),
							  )
							: null,
						rootModels: watchlist.source.filter.rootModels,
					},
				}),
			)
	}, [dispatch, project, watchlist])
	return (
		<LoadingWrapper isLoading={!watchlist || !project}>
			{watchlist && project && (
				<WatchlistCreatePresenter
					{...{watchlist, isLoading, isError, submitWatchlist, deleteWatchlist, missingIds, project}}
					isEdit
					hasInvalidIds={Boolean(latestModel && watchlist && workPackageHasInvalidIds(watchlist, latestModel._id))}
				/>
			)}
		</LoadingWrapper>
	)
}
