import React, {FormHTMLAttributes, ReactNode} from 'react'
import {Box, Image} from 'grommet'
import {config} from '../../../config'

export const AuthenticatorForm = ({
	children,
	onSubmit,
}: {
	children: ReactNode
	onSubmit: FormHTMLAttributes<unknown>['onSubmit']
}) => (
	<Box flex fill overflow={'auto'} background={'light-1'}>
		<Box
			width="460px"
			pad="large"
			background="white"
			round="xsmall"
			border={{color: 'neutral-2', size: '1px'}}
			flex={false}
			margin="auto"
		>
			<form onSubmit={onSubmit}>
				<Box align="start" gap="small">
					<Image
						margin={{bottom: '10px'}}
						alignSelf={'start'}
						src={'/images/Logo.svg'}
						alt={`${config.sr.companyName} Logo`}
					/>
					{children}
				</Box>
			</form>
		</Box>
	</Box>
)
