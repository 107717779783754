import {ReactComponent as Icon} from './TableColumns.svg'
import {Blank, IconProps} from 'grommet-icons'
import React from 'react'
export function TableColumnsIcon(props: IconProps) {
	return (
		<Blank {...props}>
			<Icon />
		</Blank>
	)
}
