import {isolateAndMaintainSelectionVisibility} from './Viewer-helper'

export class ViewerElementIsolation {
	constructor(private viewer: Autodesk.Viewing.GuiViewer3D) {}

	async isolateModelRoot() {
		await isolateAndMaintainSelectionVisibility(this.viewer, this.viewer.model.getData().instanceTree.getRootId())
	}

	hideModelRoot() {
		this.viewer.hide(this.viewer.model.getData().instanceTree.getRootId())
	}

	async isolateOrHide(forgeObjectIds: number[]): Promise<void> {
		if (forgeObjectIds.length > 0) {
			await isolateAndMaintainSelectionVisibility(this.viewer, forgeObjectIds)
		} else {
			this.hideModelRoot()
		}
	}
}
