import {buildFilterStateReducer, buildInitialFilterState, FilterState} from './base.filter.state'
import {createSlice} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {RootState} from './index'

export type AnalysisSelectorFilter = {
	analysisName: string | null
	fromDate: Date | null
	toDate: Date | null
	modelVersions: number[] | null
}

type AnalysisSelectorFilterSliceState = FilterState<AnalysisSelectorFilter>

function buildAnalysisSelectorFilterInitialState(): AnalysisSelectorFilterSliceState {
	return buildInitialFilterState<AnalysisSelectorFilterSliceState>({
		analysisName: null,
		fromDate: null,
		toDate: null,
		modelVersions: null,
	})
}

const initialState = buildAnalysisSelectorFilterInitialState()

export const analysisSelectorFilterSlice = createSlice({
	name: 'analysisSelectorFilter',
	initialState: initialState,
	reducers: {
		...buildFilterStateReducer<AnalysisSelectorFilterSliceState>(initialState),
	},
})

export function useAnalysisSelectorFilterState() {
	return useSelector((state: RootState) => state.analysisSelectorFilterSliceState)
}
