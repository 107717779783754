import {Analysis, AnalysisViewEntityPopulated, DeliveryType} from './analysis-view.entities'
import {VersionEntity} from '../../entities/version.entities'
import {UserLocalState} from '../UserLocalState/useUserLocalState'
import {differenceInCalendarDays} from 'date-fns'

export type TradeStatus = 'Ready' | 'In progress'

export type TradeAndStatus = {trade: string; status: TradeStatus}

export const versionForAnalysis = (analysis: Analysis, versions: VersionEntity[]): VersionEntity =>
	versions.find(v => v.analysis === analysis._id)!

export const hasIncrementalDelivery = (analysisView: AnalysisViewEntityPopulated) =>
	analysisView.deliveryType === DeliveryType.Incremental

export const isAnalysisViewReady = (analysisView: AnalysisViewEntityPopulated, versions: VersionEntity[]) =>
	// could also check from analysisView.status, but it would not consider the versionsMap
	analysisView.analyses.every(a => versionForAnalysis(a, versions).status === 'stable')

export const shouldDisplayAnalysisViewReadyNotification = (
	analysisView: AnalysisViewEntityPopulated,
	versions: VersionEntity[],
	dismissedAnalysisViewReadyNotifications: UserLocalState['dismissedAnalysisReadyNotifications'],
) =>
	isAnalysisViewReady(analysisView, versions) &&
	!dismissedAnalysisViewReadyNotifications.includes(analysisView._id) &&
	Math.abs(differenceInCalendarDays(new Date(analysisView.statusUpdateTime), new Date())) <= 4

export const analysisViewTradesAndStatuses = (
	analysisView: AnalysisViewEntityPopulated,
	versions: VersionEntity[],
): TradeAndStatus[] => {
	return analysisView.analyses.map(
		(a): TradeAndStatus => ({
			trade: a.name,
			// could also check from analysisView.status, but it would not consider the versionsMap
			status: versionForAnalysis(a, versions).status === 'stable' ? 'Ready' : 'In progress',
		}),
	)
}
