import {Box, Layer, Text} from 'grommet'
import {Close, Trash} from 'grommet-icons'
import React from 'react'
import {SRDeletePrimaryButton, SRHeading, SRIconButton, SRPlainButton} from 'sr-react-commons'

type ConfirmAssetDeleteModalProps = {
	onDelete: () => void
	onCancel: () => void
}

export function ConfirmAssetDeleteModal({onDelete, onCancel}: ConfirmAssetDeleteModalProps) {
	return (
		<Layer position="top" margin={{top: '100px'}}>
			<Box gap="medium" pad="medium" width="416px">
				<Box direction="row" gap="medium" justify="between">
					<SRHeading level={4} alignSelf="center">
						Delete attachment
					</SRHeading>
					<SRIconButton icon={<Close />} onClick={onCancel} alignSelf="center" />
				</Box>
				<Box pad="xsmall" align="center" direction="row" gap="medium">
					<Trash color="accent-2" size="40px" />
					<Box gap="small">
						<SRHeading level={5}>Do you want to delete this?</SRHeading>
						<Text>Once you delete this attachment, you won’t be able to restore it.</Text>
					</Box>
				</Box>

				<Box direction="row" justify="between">
					<SRPlainButton onClick={onCancel} label="Cancel" />
					<SRDeletePrimaryButton onClick={onDelete} />
				</Box>
			</Box>
		</Layer>
	)
}
