import {Box, Text} from 'grommet'
import {CaretDown} from 'grommet-icons'
import React, {ReactNode} from 'react'
import {SRButton, SRDropButton, SRDropButtonProps} from 'sr-react-commons'

export function AppBarDropButton({
	open,
	label,
	title,
	afterTitle,
	...props
}: Pick<SRDropButtonProps<typeof SRButton>, 'id' | 'onClose' | 'onOpen' | 'dropContent' | 'open'> & {
	label: string
	title?: string
	afterTitle?: ReactNode
}) {
	return (
		<SRDropButton
			{...props}
			buttonClass={SRButton}
			isSelected={open}
			open={open}
			plain
			dropAlign={{top: 'bottom', left: 'left'}}
			dropContent={props.dropContent}
			icon={<CaretDown size={'xsmall'} />}
			size="medium"
			reverse
			label={
				<Box direction={'row'} gap={'xsmall'} align={'center'} height={'22px'}>
					<Box
						direction={'row'}
						gap={'xsmall'}
						wrap={false}
						width={{max: afterTitle ? '165px' : '250px'}}
						overflow="hidden"
					>
						<Text style={{whiteSpace: 'nowrap'}}>{label}</Text>
						{title && (
							<Text
								weight={'bold'}
								style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
								title={title}
							>
								{title}
							</Text>
						)}
					</Box>
					{!afterTitle ? null : afterTitle}
				</Box>
			}
		/>
	)
}
