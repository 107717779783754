import {Box, Text} from 'grommet'
import React from 'react'
import {SRHeading, SROverline, SRSecondaryChip} from 'sr-react-commons'
import {AppBarDropButton} from '../../../../components/AppBar/AppBarDropButton'
import {DropContentContainer} from '../../../../components/AppBar/AppBar'
import {useLatestModel} from '../../../../hooks/UseLatestModel'

export function MergedAnalysesViewDropdown() {
	const [open, setOpen] = React.useState(false)
	const model = useLatestModel()
	return (
		<AppBarDropButton
			id="merged-analyses-view-dropdown"
			open={open}
			label={'Merged analyses view'}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			dropContent={
				<DropContentContainer
					onClose={() => setOpen(false)}
					header={
						<Box pad={{horizontal: 'small', vertical: 'xxsmall'}}>
							<SRHeading level={5}>Merged analysis view</SRHeading>
						</Box>
					}
				>
					<Box pad={{horizontal: 'small'}}>
						<Box pad={{horizontal: 'xsmall', bottom: 'small'}} gap="xsmall">
							<Text weight={'bold'}>Classifications from all analyses on the most recent BIM</Text>
							<Text>
								Please take into account that classifications and assets coming from previous model versions and older
								analyses might be outdated.
							</Text>
							<Box>
								<SROverline label="BIM Version displayed" />
								<SRSecondaryChip text={`Version ${model?.modelVersion}`} />
							</Box>
						</Box>
					</Box>
				</DropContentContainer>
			}
		/>
	)
}
