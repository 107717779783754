import {FeatureFlagReader} from './featureFlagReader'

export class FeatureFlags<T extends string> {
	constructor(private featureFlagReader: FeatureFlagReader<T, string>) {}

	featureEnabled(feature: T) {
		return this.featureFlagReader.featureFlagEnabled(feature)
	}

	featureFlags() {
		return this.featureFlagReader.getFeatureFlags()
	}

	featureFlagsWithLayer() {
		return this.featureFlagReader.getFeatureFlagsWithLayer()
	}
}
