import {Anchor, Avatar, Box, DropButton, Image, Text} from 'grommet'
import {CircleQuestion, Close, Projects, Search} from 'grommet-icons'
import {Auth} from 'aws-amplify'
import React, {ReactNode, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {doUserSignOut} from '../../actions/userProfile'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {RootState} from '../../reducers'
import {UserProject} from '../../reducers/userProfile'
import {routeEncodeTenantId, selectAnalysisView} from '../../utilities/routeUtilities'
import {useBasePath} from '../../hooks/useBasePath'
import {NavBarPresenter} from './NavBarPresenter'
import {User} from '../../types/user'
import {selectorCurrentUser} from '../../selectors/current-user.selectors'
import {store} from '../../store/store'
import {AppBarDropButton} from './AppBarDropButton'
import {SRDeveloperIcon, SRIconButton, SRPrimaryButton, SRTextInput, SRTooltip} from 'sr-react-commons'
import {AnalysisViewEntityPopulated} from '../../features/AnalysisView/analysis-view.entities'
import {useClassificationState} from '../../reducers/classification.slice'
import {MergedAnalysesViewDropdown} from '../../features/Watchlist/components/selection/MergedAnalysesViewDropdown'
import {useIsDeveloperForCurrentProject} from '../../hooks/UseIsDeveloperForCurrentProject'
import {AnalysisViewSelectionDropdown} from '../../features/AnalysisView/Selection/AnalysisViewSelectionDropdown'
import {normalizeColor} from 'grommet/utils'
import {config} from '../../config'
import {endCurrentSessionIfExists, trackLogRocketEvent} from '../../features/Tracking/logrocket'
import posthog from 'posthog-js'
import {useFeatureEnabled} from '../../features/FeatureFlags/FeatureFlagsProvider'

const DeveloperView = ({useTooltip}: {useTooltip: boolean}) => {
	const hasPermission = useIsDeveloperForCurrentProject()
	return hasPermission ? (
		<Box direction="row" gap="xsmall">
			{useTooltip ? (
				<Text size="small" color="neutral-1">
					developer
				</Text>
			) : (
				<Text size="medium" color="neutral-1">
					- Developer
				</Text>
			)}
			{useTooltip ? (
				<SRTooltip
					tooltipText={
						'As a developer you can see unpublished avalyses versions. Customers can only see published analyses versions.'
					}
					width={272}
					arrowPos="right"
				>
					<SRDeveloperIcon size="small" color="neutral-1" />
				</SRTooltip>
			) : (
				<SRDeveloperIcon size="small" color="neutral-1" />
			)}
		</Box>
	) : null
}

const UserAvatar = ({currentUser, showMyProjects}: {currentUser: User; showMyProjects: boolean}) => {
	const history = useHistory()
	const avatar = currentUser.firstName[0].toUpperCase() + currentUser.lastName[0].toUpperCase()
	return (
		<Box>
			<DropButton
				label={
					<Avatar size={'medium'} alignSelf={'end'} background={config.env === 'prod' ? 'green' : 'orange'}>
						<Text color={'light-1'}>{avatar}</Text>
					</Avatar>
				}
				plain
				dropAlign={{top: 'bottom'}}
				dropProps={{plain: true}}
				dropContent={
					<Box
						background={'light-1'}
						border={{
							color: 'light-3',
							size: 'xsmall',
							side: 'all',
						}}
						width={{min: '350px'}}
						pad={'small'}
						elevation={'small'}
						margin={'10px'}
					>
						<Box direction={'row'} gap={'small'}>
							<Avatar alignSelf={'end'} background={config.env === 'prod' ? 'green' : 'orange'}>
								<Text color={'light-1'}>{avatar}</Text>
							</Avatar>
							<Box>
								<Box align="center" gap="xsmall" direction="row">
									<Text>
										{currentUser.firstName} {currentUser.lastName}
									</Text>
									<DeveloperView useTooltip={false} />
								</Box>

								<Text size={'small'} color={'dark-4'}>
									{currentUser.email}
								</Text>
							</Box>
						</Box>
						<Box align={'start'} margin={{top: 'small'}} direction={'row'} justify={showMyProjects ? 'between' : 'end'}>
							{showMyProjects ? (
								<Anchor icon={<Projects />} label={'My projects'} onClick={() => history.push('/select-project')} />
							) : null}
							<SRPrimaryButton
								size={'medium'}
								label={'Sign out'}
								onClick={async () => {
									await Auth.signOut()
									await endCurrentSessionIfExists()
									if (config.sr.activatePosthog) {
										posthog.reset()
									}
									store.dispatch(doUserSignOut())
								}}
							/>
						</Box>
					</Box>
				}
			/>
		</Box>
	)
}

const MenuLink = styled(Link)`
	text-decoration: none;
	color: inherit;
	display: block;
	padding: 14px;

	&:hover {
		background-color: ${props => normalizeColor('light-2', props.theme)};
	}
`

export function DropContentContainer({
	children,
	onClose,
	header,
	filters,
}: {
	children: ReactNode
	onClose: () => void
	header: ReactNode
	filters?: ReactNode
}) {
	return (
		<Box width={'450px'} height={{max: '560px'}} pad={{top: '12px'}}>
			<Box
				pad={{horizontal: 'xsmall', bottom: 'xsmall'}}
				width="100%"
				direction={'row'}
				justify={header ? 'between' : 'end'}
				flex={false}
			>
				{header}
				<SRIconButton alignSelf="start" onClick={onClose} size="small" icon={<Close size="small" />} />
			</Box>
			{!filters ? null : (
				<Box direction={'row'} flex={false}>
					{filters}
				</Box>
			)}
			<Box overflow={{vertical: 'auto'}}>{children}</Box>
		</Box>
	)
}

function ProjectSelectionDropContent({projects, onClose}: {projects: UserProject[]; onClose: () => void}) {
	const [searchText, setSearchText] = useState('')
	const inputRef = useRef<any>(null)

	const getUrl = (project: UserProject) =>
		`/${routeEncodeTenantId(project.tenantId)}/${
			project.lastAnalysisView ? encodeURIComponent(project.lastAnalysisView._id) : ''
		}`

	useEffect(() => {
		if (inputRef.current !== null) {
			setTimeout(() => inputRef.current!.focus(), 0)
		}
	}, [])

	const escapedText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
	const exp = new RegExp(escapedText, 'i')

	const filteredProjects = _.sortBy(
		projects.filter(project => searchText === '' || exp.test(project.name) || exp.test(project.company.name)),
		['company.name', 'name'],
	)

	return (
		<Box flex={false} width={'medium'} height={{max: 'large'}}>
			<Box pad={'xsmall'} flex={false}>
				<Box pad={'xsmall'} gap={'xsmall'} align={'center'} direction={'row'} justify={'between'}>
					<Box fill>
						<SRTextInput
							icon={<Search />}
							inputRef={inputRef}
							size={'small'}
							placeholder={'Search all projects...'}
							value={searchText}
							onChange={event => setSearchText(event.target.value)}
						/>
					</Box>
					<SRIconButton size="medium" onClick={onClose} icon={<Close />} />
				</Box>
			</Box>
			<Box overflow={'auto'}>
				{!filteredProjects.length ? (
					<Box pad={'small'}>No matches found</Box>
				) : (
					filteredProjects.map(project => (
						<MenuLink to={getUrl(project)} key={project._id}>
							<Text style={{display: 'block'}} title={`${project.company.name} > ${project.name}`} truncate={true}>
								{project.company.name} {'>'} {project.name}
							</Text>
						</MenuLink>
					))
				)}
			</Box>
		</Box>
	)
}

const ProjectSelectionDropdown = ({currentProject}: {currentProject: UserProject}) => {
	const userProjects = useSelector((state: RootState) => state.userProfileState.userProjects)
	const projectsWithAnalysis = userProjects?.filter(project => project.lastAnalysisView)
	const companyName: string = currentProject.company.name
	const projectName: string = currentProject.name
	const [open, setOpen] = useState(false)
	const onClose = () => setOpen(false)
	return !projectsWithAnalysis ? null : (
		<AppBarDropButton
			open={open}
			label={companyName}
			title={projectName}
			onOpen={() => setOpen(true)}
			onClose={onClose}
			dropContent={<ProjectSelectionDropContent projects={projectsWithAnalysis} onClose={onClose} />}
		/>
	)
}

const VersionTag = () => {
	return (
		<Box justify={'center'} gap={'xsmall'}>
			{config.env !== 'prod' && <Text size={'small'}>{config.env}</Text>}
		</Box>
	)
}

type AppBarProps = {
	userAttributes?: any
	selectedProject?: UserProject
}
export const HeaderPanel = styled(Box)`
	border-bottom: solid 1px rgba(107, 113, 123, 0.2);
	z-index: 5;
`

export function AppBar({selectedProject}: AppBarProps) {
	const location = useLocation()
	const history = useHistory()
	const baseUrl = useBasePath()
	const dispatch = useDispatch()
	const currentUser = useSelector(selectorCurrentUser)
	const isProgressEnabled = useFeatureEnabled('progressMonitoring')
	const isDashboardEnabled = useFeatureEnabled('insightsDashboard')
	const {analysisViews, selectedAnalysisView} = useClassificationState()
	const selectedIssue = useSelector((state: RootState) => state.issue.selectedIssue)
	const showAnalysisSelector = !location.pathname.includes('progress/work-packages') && selectedProject && analysisViews
	const onSelectedAnalysisView = (analysisView: AnalysisViewEntityPopulated) => {
		selectAnalysisView(history, location, analysisView, selectedIssue, dispatch)
	}
	const showMergedAnalysisSelector = location.pathname.includes('work-packages')
	return (
		<HeaderPanel
			flex={false}
			tag="header"
			height="60px"
			width="100%"
			pad={{horizontal: 'small'}}
			gap="xsmall"
			background="light-1"
			justify="center"
		>
			<Box height="48px" direction="row" align="center" justify="between">
				<Box direction="row" gap="small" align="center">
					<Link style={{display: 'flex'}} title="Open project selection" to={'/select-project'}>
						<Image
							alignSelf={'start'}
							src={'/images/logo-horizontal-small.svg'}
							alt={`${config.sr.companyName} Logo`}
							height={'40px'}
						/>
					</Link>
					{selectedProject ? <ProjectSelectionDropdown currentProject={selectedProject} /> : null}
					{showAnalysisSelector ? (
						<AnalysisViewSelectionDropdown
							analysisViews={analysisViews!}
							selectedAnalysisViewId={selectedAnalysisView?._id || null}
							onSelectAnalysisView={onSelectedAnalysisView}
						/>
					) : null}
					{showMergedAnalysisSelector ? <MergedAnalysesViewDropdown /> : null}
				</Box>
				<Box flex={false} fill={'vertical'} direction="row" gap={'small'} align={'center'} justify={'end'}>
					{selectedProject ? (
						<NavBarPresenter
							pathname={location.pathname}
							baseUrl={baseUrl}
							isDashboardEnabled={isDashboardEnabled}
							isProgressDisabled={!isProgressEnabled}
						/>
					) : null}
					<SRTooltip tooltipText="Help and resources">
						<Box margin={{top: '1px'}} direction={'row'} align={'center'} pad={{vertical: 'xsmall'}}>
							<a
								href="https://help.naska.ai/"
								onClick={() => trackLogRocketEvent('HelpSectionVisited')}
								rel="noreferrer"
								target="_blank"
							>
								<SRIconButton icon={<CircleQuestion size={'large'} />} />
							</a>
						</Box>
					</SRTooltip>

					{currentUser && <UserAvatar showMyProjects={!!selectedProject} currentUser={currentUser} />}
					<Box>
						{process.env.REACT_APP_STAGE !== 'prod' && <VersionTag />}
						<DeveloperView useTooltip={true} />
					</Box>
				</Box>
			</Box>
		</HeaderPanel>
	)
}
