import {SRBrandButton, SRHeading, SRNotificationCard} from 'sr-react-commons'
import React from 'react'
import {Box, Text} from 'grommet'
import {ManagementDashboardAnalysisRequestFormBox} from '../../support/ManagementDashboardAnalysisRequestFormBox'

export const ManagementDashboardAnalysisRequestFormStep0 = ({
	nextStep,
	exitForm,
}: {
	nextStep: () => void
	exitForm: () => void
}) => {
	return (
		<ManagementDashboardAnalysisRequestFormBox
			confirmClass={SRBrandButton}
			confirmText={'Start'}
			onConfirm={nextStep}
			onCancel={exitForm}
		>
			<SRHeading level={3}>Welcome to the analysis wizard</SRHeading>
			<Box direction="row" justify={'between'} gap="60px">
				<Box gap="small" width="600px">
					<Text>We need a request with the following information to create a new analysis:</Text>

					<Box gap="xsmall">
						<SRHeading level={6}>BIM Models</SRHeading>
						<Text>
							The BIM model that will be compared against the point cloud. To update your model, please contact us
							before requesting a new analysis.
						</Text>
					</Box>

					<Box gap="xsmall">
						<SRHeading level={6}>Scans</SRHeading>
						<Text>
							The point clouds files from the relevant areas of your site. The scans must belong to a single continuous
							area and must have been acquired within the same day. If you want to analyze multiple areas, we advise you
							to submit multiple requests.
						</Text>
					</Box>

					<Box gap="xsmall">
						<SRHeading level={6}>Documents</SRHeading>
						<Text>Documentation to explain the area of interest that has been scanned.</Text>
					</Box>
				</Box>
				<Box width="200px" height="200px"></Box>
			</Box>
			<Box height="52px">
				<SRNotificationCard
					pad="small"
					fill
					type="info"
					text={<Text>Please make sure that you have uploaded all the necessary files before you continue.</Text>}
				/>
			</Box>
		</ManagementDashboardAnalysisRequestFormBox>
	)
}
