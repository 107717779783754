import {SRFileExplorerFileNode, SRFileExplorerNode} from '../FileExplorer/SRFileExplorer.logic'
import React from 'react'
import {SRFileExplorer, SRFileExplorerProps} from '../FileExplorer/SRFileExplorer'
import {ProjectFileEntity} from '../../../api/files.api'

export type SRFileListExplorerProps = {
	srFiles: ProjectFileEntity[]
	selectedIds: ProjectFileEntity['_id'][]
	toggleFileSelected: (id: ProjectFileEntity['_id']) => void
	fileNameColumnTitle?: SRFileExplorerProps['fileNameColumnTitle']
}

export const SRFileListExplorer = ({
	srFiles,
	selectedIds,
	toggleFileSelected,
	fileNameColumnTitle,
}: SRFileListExplorerProps) => {
	const rootNodes: SRFileExplorerNode[] = srFiles.map<SRFileExplorerFileNode>(f => ({
		name: f.filename,
		selected: selectedIds.includes(f._id),
		leaf: true,
		id: f._id,
		date: f.createdDate,
		parent: null,
	}))
	return (
		<SRFileExplorer
			rootNodes={rootNodes}
			dateColumnTitle={'Date Uploaded'}
			fileNameColumnTitle={fileNameColumnTitle}
			toggleFolderExpanded={() => {}}
			toggleFileSelected={node => toggleFileSelected(node.id)}
		/>
	)
}
