import {Route} from 'react-router-dom'
import {UserProject, userProjectHasPermission} from '../../../reducers/userProfile'
import {PermissionsEnum} from '../../../entities/auth'
import {GoBackToSelectProjectComponent} from './GoBackToSelectProjectWrapper'
import React, {ReactNode} from 'react'
import {SrFeatureFlag} from 'sr-feature-flags'
import {useFeatureEnabledFunc} from '../../../features/FeatureFlags/FeatureFlagsProvider'

export type GuardedRouteProps = {
	project: UserProject | null
	permission: PermissionsEnum
	path: string
	children: ReactNode
	feature?: SrFeatureFlag
}

export function GuardedRoute({project, permission, path, children, feature}: GuardedRouteProps) {
	const hasPermission = userProjectHasPermission(project, permission)
	const featureEnabled = useFeatureEnabledFunc()
	const featureActive = feature ? featureEnabled(feature) : true
	return <CustomGuardedRoute path={path} customFn={() => hasPermission && featureActive} children={children} />
}
export type CustomGuardedRouteProps = {
	path: string
	children: ReactNode
	customFn: () => boolean
}

export function CustomGuardedRoute({customFn, path, children}: CustomGuardedRouteProps) {
	return customFn() ? (
		<Route path={path}>{children}</Route>
	) : (
		<GoBackToSelectProjectComponent title={'Unauthorized'} reason={'User has not the required permissions'} />
	)
}
