import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {doResetClassificationState} from '../../actions/classification'
import {doUserProjectSelect} from '../../actions/userProfile'
import {doSetDBReady, resetViewerState} from '../../actions/viewer'
import {UserProject} from '../../reducers/userProfile'
import {User} from '../../types/user'
import {getAllProgressMonitoringWithWatchlists} from '../../features/Watchlist/slices/watchlist.thunk'
import * as projectApi from '../../api/project.api.v3'
import * as elementApi from '../../api/elements.api.v3'
import * as classificationApi from '../../features/Classification/api/classification.api'
import * as voteApi from '../../features/ElementsOfInterest/Vote/vote.api'
import * as suggestionApi from '../../features/ElementsOfInterest/Suggestion/suggestion.api'
import * as analysisViewApi from '../../features/AnalysisView/analysis-view.api'
import {fetchActiveAnalysisViews} from '../../features/AnalysisView/analysis-view.api'
import {issueSlice} from '../../features/IssueManagement/issue.slice'
import {watchlistSlice} from '../../features/Watchlist/slices/watchlist.slice'
import {classificationSlice, useClassificationState} from '../../reducers/classification.slice'
import {filterPresetSlice} from '../../features/FilterPresets/slices/filter-preset.slice'
import {useSectionState} from '../Viewer.Components/Viewing.Extension.ElementSections/StateManagement/section.slice'
import {flatten} from 'lodash'
import {useQuery} from 'react-query'
import {defaultQueryClient} from '../../utilities/query-client.util'
import {useFeatureFlagsImport} from '../../features/FeatureFlags/FeatureFlagsProvider'

type GetProjectInitDataProps = {
	selectedProject: UserProject | null | undefined
	currentUser: User | null | undefined
}

function updateApisWithProjectId(projectId: UserProject['_id']) {
	;[projectApi, elementApi, classificationApi, analysisViewApi, voteApi, suggestionApi].forEach(api =>
		api.setCurrentProjectId(projectId),
	)
}

export function GetProjectInitData(props: React.PropsWithChildren<GetProjectInitDataProps>) {
	const dispatch = useDispatch()
	const importDataToLayer = useFeatureFlagsImport()
	const [ready, setReady] = useState(false)
	const [analysisViewsReady, setAnalysisViewsReady] = useState(false)
	const allReady = ready && analysisViewsReady
	const {resetSectionState} = useSectionState()
	const {setAnalysisViews, setSelectedAnalysisView} = useClassificationState()
	const {data: analysisViews} = useQuery(
		['analysis-view', 'analysis-view-active', props.selectedProject],
		async () => {
			props.selectedProject && updateApisWithProjectId(props.selectedProject._id)
			return fetchActiveAnalysisViews()
		},
		{enabled: !!props.selectedProject?._id && !!props.currentUser},
	)
	useEffect(() => {
		setAnalysisViewsReady(false)
		if (!analysisViews) return
		const searchParameters = Object.fromEntries(new URLSearchParams(document.location.search))
		const analysisVersionMap: {[k in string]: number} = flatten(analysisViews.map(av => av.analyses)).reduce(
			(map, a) => (a.id in searchParameters ? {...map, [a._id]: parseInt(searchParameters[a.id])} : map),
			{},
		)
		setAnalysisViews(analysisViews, analysisVersionMap)
		setAnalysisViewsReady(true)
	}, [analysisViews, setAnalysisViews, setAnalysisViewsReady])
	useEffect(() => {
		dispatch(resetViewerState())
		resetSectionState()
		setReady(false)
		if (!props.currentUser || !props.selectedProject) return
		updateApisWithProjectId(props.selectedProject._id)
		;(async () => {
			// reset states
			dispatch(doResetClassificationState())
			;[issueSlice].forEach(slice => dispatch(slice.actions.resetPagination()))
			dispatch(watchlistSlice.actions.doWatchlistRemoveAll())
			dispatch(classificationSlice.actions.reset())
			dispatch(filterPresetSlice.actions.doReset())
			await defaultQueryClient.removeQueries()
			dispatch(doUserProjectSelect(props.selectedProject))
			importDataToLayer(
				Object.fromEntries(
					Object.entries(props.selectedProject?.viewerFeatureFlags ?? {})
						.filter(([, value]) => typeof value === 'boolean')
						.map(([id, value]) => {
							return [id, !!value]
						}),
				),
				'server',
			)
			//call progress monitoring to activate cache on web-backend
			dispatch(getAllProgressMonitoringWithWatchlists())
			setReady(true)
		})().catch(e => {
			console.error('Error while getting project init data', e)
		})
	}, [
		setSelectedAnalysisView,
		props.selectedProject,
		props.currentUser,
		dispatch,
		resetSectionState,
		importDataToLayer,
	])
	useEffect(() => {
		dispatch(doSetDBReady(allReady))
	}, [dispatch, allReady])
	return !allReady ? null : <>{props.children}</>
}
