import type {PhotoshpereExtensionOptions, PhotoSphereExtensionI} from './PhotoSphere.viewer.extension'
import PhotoSphereExtension, {PHOTO_SPHERE_EXTENSION_ID} from './PhotoSphere.viewer.extension'
import {coordinateFromIFCtoViewer} from '../../NaskaUtilities'
import {getCenterOfElementBounds} from '../../Viewer/Viewer-helper'
import {useSelector} from 'react-redux'
import {RootState} from '../../../reducers'
import React from 'react'

const THREE = window.THREE

export interface PhotosphereViewerWrapperI {
	setToolBarButtonVisible(toggle: boolean): void
	updateViewerUIForLockedViews(): void
	loadPhotosphereExtension(options: PhotoshpereExtensionOptions): Promise<PhotoSphereExtensionI | null>
	unloadPhotosphereExtension(): void
	getViewerCoordinates(x: number, y: number, z: number): THREE.Vector3
	getCenterOfElementBounds(forgeObjectId: number): THREE.Vector3
	applyViewerCameraRotation(
		viewSpherePosePosition: THREE.Vector3,
		elementCenter: THREE.Vector3,
		deltaLon: number,
		deltaLat: number,
	): void
	applyViewerCameraZoom(cameraFov: number): void
}

export function usePhotosphereViewerWrapper(): PhotosphereViewerWrapperI | null {
	const viewer = useSelector((state: RootState) => state.viewerState.viewer)
	return React.useMemo(() => (viewer ? new PhotosphereViewerWrapper(viewer) : null), [viewer])
}

export class PhotosphereViewerWrapper implements PhotosphereViewerWrapperI {
	viewer: Autodesk.Viewing.GuiViewer3D
	constructor(viewer: Autodesk.Viewing.GuiViewer3D) {
		this.viewer = viewer
	}

	setToolBarButtonVisible(toggle: boolean) {
		const toolbar = this.viewer.toolbar
		// @ts-ignore
		toolbar?.navTools?.setVisible(toggle)
		// @ts-ignore
		toolbar?.modelTools?.setVisible(toggle)
		// @ts-ignore
		toolbar?.settingsTools?.structurebutton?.setVisible(toggle)
	}

	updateViewerUIForLockedViews() {
		this.viewer.setActiveNavigationTool('orbit')
		// Switch to perspective because locked views does not work with orthographic view
		const viewCubeUi = this.viewer.getExtension('Autodesk.ViewCubeUi')
		// @ts-ignore
		viewCubeUi.setViewType(Autodesk.Viewing.Private.VIEW_TYPES.PERSPECTIVE)
		this.viewer.navigation?.setCameraUpVector(new THREE.Vector3(0, 0, 1))
	}

	async loadPhotosphereExtension(options: PhotoshpereExtensionOptions) {
		if (!this.viewer.model) return null
		this.viewer.setBimWalkToolPopup(false)
		const extension = await this.viewer.loadExtension(PHOTO_SPHERE_EXTENSION_ID, options)
		return extension as PhotoSphereExtension
	}

	unloadPhotosphereExtension() {
		this.viewer.unloadExtension(PHOTO_SPHERE_EXTENSION_ID)
	}

	getViewerCoordinates(x: number, y: number, z: number) {
		const [viewerX, viewerY, viewerZ] = coordinateFromIFCtoViewer(this.viewer.model, x, y, z)
		return new THREE.Vector3(viewerX, viewerY, viewerZ)
	}

	getCenterOfElementBounds(forgeObjectId: number) {
		return getCenterOfElementBounds(forgeObjectId, this.viewer)
	}

	applyViewerCameraZoom(cameraFov: number) {
		if (!this.viewer.navigation) return
		this.viewer.navigation.getCamera().fov = cameraFov
		this.viewer.impl.sceneUpdated(false)
	}

	applyViewerCameraRotation(
		viewSpherePosePosition: THREE.Vector3,
		elementCenter: THREE.Vector3,
		deltaLon: number,
		deltaLat: number,
	) {
		const camera = this.viewer.navigation.getCamera()
		const newPosition = viewSpherePosePosition
		const directionFwd = elementCenter.clone().sub(newPosition)
		const directionRight = directionFwd.clone().cross(camera.up).normalize()

		// rotate around up vector
		const yawX = new THREE.Quaternion()
		const angleX = (2 * Math.PI * deltaLon * -1) / 360
		yawX.setFromAxisAngle(camera.up, angleX)

		// rotate around right vector
		const yawY = new THREE.Quaternion()
		const angleY = (2 * Math.PI * deltaLat) / 360
		yawY.setFromAxisAngle(directionRight, angleY)

		const yawQ = new THREE.Quaternion()
		yawQ.multiply(yawX).multiply(yawY)

		directionFwd.applyQuaternion(yawQ)
		const newTarget = newPosition.clone().add(directionFwd)
		this.viewer.navigation.setView(newPosition, newTarget)
		this.viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 0, 1))
	}
}
