import {ModelEntity} from '../../../Models/model.entities'
import {ProjectFileEntity} from '../../../../api/files.api'
import React, {useCallback, useMemo, useState} from 'react'
import {ManagementDashboardAnalysisRequestFormCancelModal} from './modals/ManagementDashboardAnalysisRequestFormCancelModal'
import {ManagementDashboardAnalysisRequestFormBox} from '../support/ManagementDashboardAnalysisRequestFormBox'
import {
	ManagementDashboardAnalysisRequestFormStep1,
	ManagementDashboardAnalysisRequestStep1State,
} from './steps/ManagementDashboardAnalysisRequestForm.step1'
import {
	ManagementDashboardAnalysisRequestFormStep2,
	ManagementDashboardAnalysisRequestStep2State,
} from './steps/ManagementDashboardAnalysisRequestForm.step2'
import {
	ManagementDashboardAnalysisRequestFormStep3,
	ManagementDashboardAnalysisRequestStep3State,
} from './steps/ManagementDashboardAnalysisRequestForm.step3'
import {SRBrandButton, SRPrimaryButton} from 'sr-react-commons'
import {CreateAnalysisRequestsDTO} from '../../../AnalysisRequest/analysis-request.dtos'
import {ManagementDashboardAnalysisRequestConfirmSaveModal} from './modals/ManagementDashboardAnalysisRequestConfirmSaveModal'
import {useNavigationBlocker} from '../../../../hooks/UseNavigationBlocker'
import {postAnalysisRequest} from '../../../AnalysisRequest/analysis-request.api'
import {useCurrentProject} from '../../../../hooks/useCurrentProject'
import {AnalysisRequestEntity} from '../../../AnalysisRequest/analysis-request.entities'

export type ManagementDashboardAnalysisRequestStepState<
	DTOKeys extends keyof CreateAnalysisRequestsDTO,
	DTO = Pick<CreateAnalysisRequestsDTO, DTOKeys>
> = {valid: false; dto: {[K in keyof DTO]: Partial<DTO[K]>}} | {valid: true; dto: DTO}

export const ManagementDashboardAnalysisRequestFormInProgress = ({
	step,
	nextStep,
	activeModel,
	rootModels,
	scans,
	documents,
	exitForm,
}: {
	step: number
	nextStep: (newAnalysisRequest?: AnalysisRequestEntity) => void
	activeModel: ModelEntity
	rootModels: string[]
	scans: ProjectFileEntity[]
	documents: ProjectFileEntity[]
	exitForm: () => void
}) => {
	const {unblockRef} = useNavigationBlocker(
		'Do you really want to exit the wizard?\nIf you exit now, your data will be lost.',
	)
	const project = useCurrentProject()
	const [openCancelModal, setOpenCancelModal] = useState(false)
	const [openConfirmSaveModal, setOpenConfirmSaveModal] = useState(false)
	const [analysisName, setAnalysisName] = useState('')
	const [step1State, setStep1State] = useState<ManagementDashboardAnalysisRequestStep1State>({
		valid: false,
		dto: {modelDomain: {}},
	})
	const [step2State, setStep2State] = useState<ManagementDashboardAnalysisRequestStep2State>({
		valid: false,
		dto: {scanDomain: {}, hasImages: false},
	})
	const [step3State, setStep3State] = useState<ManagementDashboardAnalysisRequestStep3State>({
		valid: false,
		dto: {documentDomain: {}},
	})
	const confirmEnabled = useMemo(
		() => step1State.valid && (step < 2 || step2State.valid) && (step < 3 || step3State.valid),
		[step1State, step2State, step3State, step],
	)

	const finalStep = useMemo(() => step === 3, [step])
	const save = useCallback(async () => {
		if (step1State.valid && step2State.valid && step3State.valid && analysisName !== '') {
			const analysisRequest = await postAnalysisRequest(project!._id, {
				...step1State.dto,
				...step2State.dto,
				...step3State.dto,
				analysisName,
			})
			nextStep(analysisRequest)
		} else {
			console.error('Trying to save an invalid Analysis Request.')
		}
	}, [step1State, step2State, step3State, nextStep, project, analysisName])
	return (
		<>
			{!openCancelModal ? null : (
				<ManagementDashboardAnalysisRequestFormCancelModal
					goBackForm={() => setOpenCancelModal(false)}
					exitForm={() => {
						unblockRef.current?.()
						exitForm()
					}}
				/>
			)}
			{!openConfirmSaveModal ? null : (
				<ManagementDashboardAnalysisRequestConfirmSaveModal
					cancel={() => setOpenConfirmSaveModal(false)}
					confirm={save}
					analysisNameValid={analysisName !== ''}
					onAnalysisNameTextInputChange={value => setAnalysisName(value)}
				/>
			)}
			<ManagementDashboardAnalysisRequestFormBox
				onCancel={() => setOpenCancelModal(true)}
				onConfirm={() => (finalStep ? setOpenConfirmSaveModal(true) : nextStep())}
				confirmEnabled={confirmEnabled}
				confirmText={finalStep ? 'Request Analysis' : 'Next'}
				confirmClass={finalStep ? SRBrandButton : SRPrimaryButton}
			>
				<ManagementDashboardAnalysisRequestFormStep1
					{...{activeModel, rootModels}}
					state={step1State}
					setState={setStep1State}
				/>
				{step > 1 ? (
					<ManagementDashboardAnalysisRequestFormStep2 {...{scans}} state={step2State} setState={setStep2State} />
				) : null}
				{step > 2 ? (
					<ManagementDashboardAnalysisRequestFormStep3 {...{documents}} state={step3State} setState={setStep3State} />
				) : null}
			</ManagementDashboardAnalysisRequestFormBox>
		</>
	)
}
