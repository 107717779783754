import {LoadingWrapper} from '../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {SRCard, SRIconButton, SROverline} from 'sr-react-commons'
import {Box} from 'grommet'
import {RotateLeft} from 'grommet-icons'
import React from 'react'
import {FiltersEntity, toFiltersDTO} from '../../FilterPresets/entities/filter-preset.entities'
import {useFilterStateForMode} from '../../FilterPresets/components/filters/Filters'
import {ElementTypeChart} from './charts/ElementTypeChart'
import {useQuery} from 'react-query'
import {CLASSIFICATIONS_BASE_QUERY_KEY} from '../../Classification/data-management'
import {fetchElementTypesForInsights} from '../api/insight-dashboard.api'
import {useSelector} from 'react-redux'
import {selectorClassificationState} from '../../../selectors/classification.selectors'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'
import {RootState} from '../../../reducers'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import useDebounce from '../../../components/DataTableContainer/useDebounce'

export function ElementTypeChartBody({isClassifications}: {isClassifications: boolean}) {
	const FILTER_MODE = 'overview'
	const CARD_HEIGHT = '265px'
	const isElementFiltered = (filter: FiltersEntity) => {
		return !filter.elementTypes
	}
	const {selectedAnalysisView, analysisVersionMap} = useSelector(selectorClassificationState)
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const {approvedFilter} = useSelector((state: RootState) => state.viewerState)
	const selectedProject = useCurrentProject()!
	const {filter, setPartialFilter} = useFilterStateForMode(FILTER_MODE)
	const canResetFiltered = isElementFiltered(filter as FiltersEntity)
	const resetElementTypesFilter = () => {
		setPartialFilter({elementTypes: null})
	}

	const {data: elementTypes, isLoading: elementTypesLoading, isError: isElementTypesError} = useQuery(
		[
			CLASSIFICATIONS_BASE_QUERY_KEY,
			'for-insights-element-types',
			{
				selectedAnalysisView,
				analysisVersionMap,
				enableUnderConstruction,
				filter,
			},
		],
		async () => {
			return fetchElementTypesForInsights(
				selectedAnalysisView!._id,
				analysisVersionMap,
				toFiltersDTO(
					filter as FiltersEntity,
					selectedProject.floorMapping,
					selectedProject.elementTypeMapping,
					enableUnderConstruction,
					approvedFilter,
				),
				selectedProject._id,
			)
		},
		{enabled: !isClassifications},
	)
	const elementTypesData = elementTypes ? elementTypes : []
	return (
		<SRCard height={CARD_HEIGHT} gap={'none'} pad={'7px'}>
			<LoadingWrapper
				isLoading={useDebounce(elementTypesLoading, 500)}
				errorMsg="There was an error loading the insights element types chart"
				isError={isElementTypesError}
				fill
			>
				<Box direction="row" justify="between">
					<Box alignSelf="end">
						<SROverline label={'ELEMENT TYPE DISTRIBUTION'} />
					</Box>
					<SRIconButton
						icon={<RotateLeft size="small" />}
						onClick={resetElementTypesFilter}
						disabled={canResetFiltered}
					/>
				</Box>
				<ElementTypeChart {...{elementTypesData}} />
			</LoadingWrapper>
		</SRCard>
	)
}
