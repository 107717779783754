import {FileUploadState} from '../hooks/useFileUploader'
import {Box, Text} from 'grommet'
import {Close} from 'grommet-icons'
import React from 'react'
import {DroppedFile} from './ProjectFileUploader'
import {SRIconButton} from 'sr-react-commons'

export function FileUploadedTable(props: {
	files: DroppedFile[]
	uploadingFiles: FileUploadState['files']
	onRemove: (fileName: string) => void
	canRemoveFiles: boolean
	onCancelUpload: (fileName: string) => void
}) {
	return (
		<Box overflow={'auto'} style={{maxHeight: '150px'}}>
			{props.files.map(({file}) => {
				const fileProgress = props.uploadingFiles[file.name]
				return (
					<div style={{height: '30px'}}>
						<Box
							width={'content'}
							pad={'xxsmall'}
							direction={'row'}
							key={file.name}
							border={{color: 'neutral-2', side: 'bottom'}}
						>
							<Box alignSelf={'stretch'} width={'100%'} gap="xsmall">
								<Text truncate title={file.name}>
									{file.name}
								</Text>
							</Box>
							<Box align={'end'}>
								{(!fileProgress || ['pending', 'uploading'].includes(fileProgress?.status)) && props.canRemoveFiles && (
									<SRIconButton
										size={'small'}
										icon={<Close size={'xsmall'} />}
										onClick={() => props.onRemove(file.name)}
									/>
								)}
							</Box>
						</Box>
					</div>
				)
			})}
		</Box>
	)
}
