import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {useCallback, useReducer} from 'react'
import {FileVisibility} from '../../../api/files.api'
import {useFileUploaderWorkerContext} from '../components/FileUploadProviders'
import {defaultQueryClient} from '../../../utilities/query-client.util'

export type FileMap = {
	[k: string]: {progress: number; status: 'pending' | 'uploading' | 'success' | 'error' | 'canceled'}
}
export type UploadStatus = 'pending' | 'uploading' | 'finished' | 'canceled' | 'partial' | 'failed' | 'success' | null
export type FileUploadState = {
	files: FileMap
}

function buildInitialState(): FileUploadState {
	return {
		files: {},
	}
}

export function selectorFileUploadStatus(files: FileMap): UploadStatus {
	const nonUndefinedFiles = Object.values(files).filter(file => file && file.status !== undefined)
	const nonCancelledFiles = nonUndefinedFiles.filter(file => file && file.status !== 'canceled')
	if (nonUndefinedFiles.every(file => file.status === 'pending')) return 'pending'
	if (nonUndefinedFiles.some(file => file.status === 'uploading')) return 'uploading'
	if (nonUndefinedFiles.every(file => file.status === 'canceled')) return 'canceled'
	if (nonCancelledFiles.every(file => file.status === 'success')) return 'finished'
	if (nonCancelledFiles.every(file => file.status === 'error')) return 'failed'
	if (nonCancelledFiles.every(file => file && ['success', 'error'].includes(file.status))) return 'partial'
	return null
}

const fileUploadSlice = createSlice({
	name: 'FileUpload',
	initialState: buildInitialState(),
	reducers: {
		addFiles: (state, action: PayloadAction<{files: {filename: string}[]}>) => {
			for (const file of action.payload.files) {
				state.files[file.filename] = {progress: 0, status: 'pending'}
			}
		},
		updateFileProgress: (state, action: PayloadAction<{filename: string; progress: number}>) => {
			const fileProgress = state.files[action.payload.filename]
			if (fileProgress) {
				if (fileProgress.progress === 0 && action.payload.progress > 0 && fileProgress.status === 'pending') {
					fileProgress.status = 'uploading'
				}
				fileProgress.progress = Math.min(action.payload.progress, 100)
			}
		},
		finishFileUpload: (state, action: PayloadAction<{filename: string}>) => {
			const fileProgress = state.files[action.payload.filename]
			if (fileProgress) {
				if (fileProgress.progress >= 100) {
					fileProgress.status = 'success'
				} else {
					fileProgress.status = 'canceled'
				}
			}
		},
		setFileError: (state, action: PayloadAction<{filename: string}>) => {
			const fileProgress = state.files[action.payload.filename]
			if (fileProgress) {
				fileProgress.status = 'error'
			}
		},
		reset: () => buildInitialState(),
	},
})

function getFailedFileCount(files: FileMap) {
	const failedOrCanceledFiles = Object.values(files).filter(
		file => file.status === 'error' || file.status === 'canceled',
	)
	return failedOrCanceledFiles.length
}

export function useFileUploader(
	projectId: string,
	visibility: FileVisibility,
): {
	uploadFiles: (files: File[]) => Promise<void>
	files: FileMap
	reset: () => void
	fileUploadStatus: UploadStatus
	cancelFileUpload: (filename: string) => Promise<void>
	failedFileCount: number
} {
	const [{files}, dispatch] = useReducer(fileUploadSlice.reducer, buildInitialState())
	const worker = useFileUploaderWorkerContext()
	const uploadFiles = useCallback(
		async (files: File[]) => {
			dispatch(fileUploadSlice.actions.addFiles({files: files.map(file => ({filename: file.name}))}))
			await Promise.all(
				files.map(async file => {
					const $source = worker.uploadFile(projectId, visibility, file).subscribe({
						next: (progress: number) => {
							dispatch(fileUploadSlice.actions.updateFileProgress({filename: file.name, progress}))
						},
						error: (e: Error) => {
							dispatch(fileUploadSlice.actions.setFileError({filename: file.name}))
							console.error(`Error uploading file ${file.name}`, e)
							$source.unsubscribe()
						},
						complete: async () => {
							dispatch(fileUploadSlice.actions.finishFileUpload({filename: file.name}))
							await defaultQueryClient.invalidateQueries(['project-files'])
							$source.unsubscribe()
						},
					})
				}),
			)
		},
		[projectId, visibility, worker],
	)
	const cancelFileUpload = useCallback(
		async (filename: string) => {
			await worker.cancelUpload(filename)
			dispatch(fileUploadSlice.actions.finishFileUpload({filename: filename}))
		},
		[worker],
	)
	const reset = useCallback(() => {
		dispatch(fileUploadSlice.actions.reset())
	}, [])

	const fileUploadStatus = selectorFileUploadStatus(files)
	const failedFileCount = getFailedFileCount(files)
	return {files, uploadFiles, fileUploadStatus, cancelFileUpload, reset, failedFileCount}
}
