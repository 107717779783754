export class PayloadError extends Error {
	constructor(
		public readonly status: number,
		public readonly responseData: {
			message: string
			type?: string
			stack?: string
			payload: Record<string, unknown>
		},
	) {
		super(responseData.message)
	}
}
