import React from 'react'
import {AccordionPanel, Box} from 'grommet'
import {BorderLessAccordion} from '../../../components/Accordions/BorderLessAccordion'
import {AnalysisDescriptionFilter} from './AnalysisDescriptionFilter'
import {AnalysisSelectorFilter} from '../../../reducers/analysisSelectorFilter.slice'

interface AnalysisSelectorFilterAccordionProps {
	setFilter: (key: 'analysisName', value: string | null) => void
	filters: AnalysisSelectorFilter
}

export function AnalysisSelectorFilterAccordion({setFilter, filters}: AnalysisSelectorFilterAccordionProps) {
	return (
		<Box width="100%" pad={{horizontal: 'xsmall'}} border={{color: 'neutral-1', size: '1px'}} round="xsmall">
			<BorderLessAccordion>
				<AccordionPanel label="Filters">
					<AnalysisSelectorFilters filters={filters} setFilter={setFilter} />
				</AccordionPanel>
			</BorderLessAccordion>
		</Box>
	)
}

interface AnalysisSelectorFiltersProps {
	setFilter: (key: 'analysisName', value: string | null) => void
	filters: AnalysisSelectorFilter
}

function AnalysisSelectorFilters({setFilter, filters}: AnalysisSelectorFiltersProps) {
	return (
		<Box flex={false} pad={{bottom: 'small', horizontal: 'xsmall'}}>
			<AnalysisDescriptionFilter filters={filters} setFilter={setFilter} />
		</Box>
	)
}
