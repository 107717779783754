import {WatchlistEntity} from '../entities/watchlist.entities'
import {ProgressMonitoringEntity} from '../entities/progress-monitoring.entities'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export type WatchlistState = {
	watchlists: WatchlistEntity[]
	progressMonitorings: ProgressMonitoringEntity[]
	isLoading: boolean
	isError: boolean
	isOpen: boolean
	current: {
		watchlist: WatchlistEntity | null
		progressMonitorings: ProgressMonitoringEntity[] | null
		isError: boolean
		isLoading: boolean
	}
}

export function buildInitialWatchlistState(): WatchlistState {
	return {
		watchlists: [],
		progressMonitorings: [],
		isLoading: false,
		isError: false,
		isOpen: false,
		current: {
			watchlist: null,
			progressMonitorings: null,
			isError: false,
			isLoading: false,
		},
	}
}

export const watchlistSlice = createSlice({
	name: 'work-packages',
	initialState: buildInitialWatchlistState(),
	reducers: {
		doWatchlistFetchAll: (
			state,
			action: PayloadAction<{watchlists: WatchlistEntity[]; progressMonitorings: ProgressMonitoringEntity[]}>,
		): WatchlistState => {
			return {...state, ...action.payload}
		},
		doWatchlistRemoveAll: (state, _action: PayloadAction<void>): WatchlistState => {
			return {...state, watchlists: [], progressMonitorings: []}
		},
		doFetchCurrent: (
			state,
			action: PayloadAction<{watchlist: WatchlistEntity; progressMonitorings: ProgressMonitoringEntity[]}>,
		): WatchlistState => {
			return {...state, current: {...state.current, ...action.payload}}
		},
		doWatchlistRemoveCurrent: (state, _action: PayloadAction<void>): WatchlistState => {
			return {...state, current: {...buildInitialWatchlistState().current}}
		},
		doSetLoading: (state, action: PayloadAction<boolean>): WatchlistState => ({
			...state,
			isLoading: action.payload,
			isError: action.payload ? false : state.isError,
		}),
		doSetError: (state, action: PayloadAction<boolean>): WatchlistState => ({...state, isError: action.payload}),
		doSetCurrentError: (state, action: PayloadAction<boolean>): WatchlistState => ({
			...state,
			current: {...state.current, isError: action.payload},
		}),
		doSetCurrentLoading: (state, action: PayloadAction<boolean>): WatchlistState => ({
			...state,
			current: {...state.current, isLoading: action.payload, isError: action.payload ? false : state.current.isError},
		}),
		doSetOpen: (state, action: PayloadAction<boolean>): WatchlistState => ({...state, isOpen: action.payload}),
	},
})
