import {useQuery} from 'react-query'
import {VersionEntity} from '../../entities/version.entities'
import {AxiosError} from 'axios'
import {fetchSelectedVersionsForAnalysisView} from '../Classification/api/classification.api'
import {AnalysisViewEntityPopulated} from './analysis-view.entities'
import {AnalysisVersionMap} from '../../reducers/classification.slice'

export const useSelectedVersionsForAnalysisView = (
	analysisView: AnalysisViewEntityPopulated | null,
	analysisVersionMap: AnalysisVersionMap,
) => {
	const {data: analysisViewVersions} = useQuery<VersionEntity[] | null, AxiosError>(
		['classifications', 'selected-versions-for-analysis-view', analysisView?._id],
		() => analysisView && fetchSelectedVersionsForAnalysisView(analysisView._id, analysisVersionMap),
		{enabled: !!analysisView},
	)
	return analysisViewVersions || null
}
