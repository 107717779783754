import {SRCard, SRIconButton, SROverline} from 'sr-react-commons'
import {Box} from 'grommet'
import React from 'react'
import {QualityBreakdownPieChart} from '../charts/QualityBreakdownPieChart'
import {RotateLeft} from 'grommet-icons'
import {BreakDownDataTable} from '../../../Watchlist/components/charts/BreakDownDataTable'
import {updateData} from '../../InsightDashboard-helper'
import useDebounce from '../../../../components/DataTableContainer/useDebounce'
import {LoadingWrapper} from 'components/CommonsCandidate/Loading/LoadingWrapper'

export function QualityBreakdownPresenter({
	classifications,
	enableUnderConstruction,
	resetStatusFilter,
	canResetStatus,
	isClassificationsLoading,
	isClassificationsError,
}: {
	classifications: Record<string, number[]> | undefined
	enableUnderConstruction: boolean
	resetStatusFilter: () => void
	canResetStatus: boolean
	isClassificationsLoading: boolean
	isClassificationsError: boolean
}) {
	const CARD_HEIGHT = '223px'
	const PIE_CHART_HEIGHT = '156px'
	const debouncedClassifications = useDebounce(classifications, 500)
	return (
		<SRCard height={CARD_HEIGHT} gap={'small'} pad={'7px'}>
			<LoadingWrapper
				isLoading={useDebounce(isClassificationsLoading, 500)}
				errorMsg="There was an error loading the insights element types chart"
				isError={isClassificationsError}
				fill
			>
				<Box direction={'row'} justify="between">
					<Box alignSelf="end">
						<SROverline label={'QUALITY BREAKDOWN'} />
					</Box>
					<SRIconButton icon={<RotateLeft size="small" />} onClick={resetStatusFilter} disabled={canResetStatus} />
				</Box>
				<Box direction={'row'} gap={'xxsmall'} margin={{top: '15px', right: '10px'}}>
					<Box width={'40%'} height={PIE_CHART_HEIGHT}>
						<QualityBreakdownPieChart
							data={updateData(debouncedClassifications)}
							enableUnderConstruction={enableUnderConstruction}
						/>
					</Box>
					<Box>
						<BreakDownDataTable
							data={updateData(debouncedClassifications)}
							enableUnderConstruction={enableUnderConstruction}
						/>
					</Box>
				</Box>
			</LoadingWrapper>
		</SRCard>
	)
}
