import React, {useCallback, useState} from 'react'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {Box, Layer} from 'grommet'
import {Close} from 'grommet-icons'
import {SRHeading, SRIconButton, SRPlainButton, SRPrimaryButton, SRSpinnerIcon} from 'sr-react-commons'
import {getIntegrationIcon, getIntegrationName} from '../forge-integration.utils'
import {ForgeFileType, ForgeIntegrationEntity, ForgeItem} from '../forge-integration.entities'
import {ForgeFileExplorer} from './ForgeFileExplorer/ForgeFileExplorer'
import {copyForgeItems} from '../forge-integration.api'
import {useUserHasValidForgeAccess} from '../hooks/UseUserHasValidForgeAccess'
import {ForgeIntegrationModalWrapper} from './ForgeIntegrationModalWrapper'
import {invalidateUseProjectFilesByVisibility} from '../../ManagementDashboard/file/UseProjectFilesByVisibility'
import {useGlobalNotifications} from '../../GlobalNotifications'

export type ForgeIntegrationUploadFileModalProps = {
	onClose: () => void
	open: boolean
	fileType: ForgeFileType
	mode: 'model' | 'scan'
	integration?: ForgeIntegrationEntity | null
}

export const ForgeIntegrationUploadFileModal = ({
	onClose,
	open,
	fileType,
	mode,
	integration,
}: ForgeIntegrationUploadFileModalProps) => {
	const {notifyError} = useGlobalNotifications()
	const project = useCurrentProject()
	const [selectedFiles, setSelectedFiles] = useState<ForgeItem['id'][]>([])
	const {data: hasValidForgeAccess} = useUserHasValidForgeAccess(project)
	const [uploadingFiles, setUploadingFiles] = useState<boolean>(false)
	const uploadFiles = useCallback(async () => {
		setUploadingFiles(true)

		try {
			await copyForgeItems(project!._id, selectedFiles)
			await invalidateUseProjectFilesByVisibility(project!, mode)
			onClose()
		} catch {
			notifyError('We were not able to import your files. Please try to import your files again.')
		} finally {
			setUploadingFiles(false)
		}
	}, [project, selectedFiles, mode, onClose, notifyError])
	const isProjectConnected = !integration
	if (!open || !project) return null
	return (
		<ForgeIntegrationModalWrapper {...{hasValidForgeAccess, isProjectConnected, open, onClose}}>
			<Layer position={'top'} margin={{top: '100px'}}>
				<Box gap={'medium'} pad={'medium'} width={'650px'}>
					<Box justify={'between'} direction={'row'}>
						<SRHeading level={4}>Import from {getIntegrationName(integration)}</SRHeading>
						<SRIconButton icon={<Close />} onClick={onClose} alignSelf={'center'} />
					</Box>
					<Box gap="medium">
						<SRHeading level={5}>
							Select {mode === 'model' ? 'models' : 'scans'} from your {getIntegrationName(integration)} folder
						</SRHeading>
						<Box direction={'row'} gap={'small'} align={'center'}>
							{getIntegrationIcon(integration)}
							<strong>{integration?.forgeProject.name}</strong>
						</Box>
						<Box height={'320px'} fill={'horizontal'}>
							<ForgeFileExplorer
								{...{integration, project, fileType}}
								onSelectionChange={setSelectedFiles}
								onFileError={onClose}
							/>
						</Box>
					</Box>
					<Box direction="row" justify="between">
						<SRPlainButton onClick={onClose} label="Cancel" size={'medium'} />
						<SRPrimaryButton
							disabled={!selectedFiles.length || uploadingFiles}
							label={`Import from ${getIntegrationName(integration)}`}
							onClick={uploadFiles}
							icon={!uploadingFiles ? undefined : <SRSpinnerIcon size={'xsmall'} color={'darker'} />}
						/>
					</Box>
				</Box>
			</Layer>
		</ForgeIntegrationModalWrapper>
	)
}
