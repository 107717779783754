import React, {useCallback} from 'react'
import {useParams} from 'react-router-dom'
import {IssueComment} from '../issue-comment.entities'
import {
	deleteIssueComment,
	fetchIssueById,
	fetchIssueCommentsByIssueId,
	postIssueComment,
} from '../api/issue-management.api'
import {useGlobalNotifications} from '../../GlobalNotifications'
import {IssueCommentList} from './IssueCommentsList'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {AxiosError} from 'axios'
import {LoadingWrapper} from '../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {RightPanelHeader} from '../../../components/CommonsCandidate/Layout/RightPanel/RightPanelHeader'
import {SRRightPanelBody, SRRightPanelContainer} from 'sr-react-commons'
import {Id} from '../../../entities/value-objects'
import {IssueEntity} from '../issue.entities'

export function IssueCommentsRoute() {
	const {id} = useParams<{id: Id}>()
	const queryClient = useQueryClient()
	const {notifyAlert} = useGlobalNotifications()
	const {data: issue, isError: isIssuesError, isLoading: isIssuesLoading} = useQuery<IssueEntity, AxiosError>(
		['issues', 'issue-by-id', id],
		() => fetchIssueById(id!),
		{enabled: !!id},
	)
	const {data: comments, isLoading: isCommentsLoading, isError: isCommentsError} = useQuery<IssueComment[], AxiosError>(
		['issues', 'issue-comments', id],
		() => fetchIssueCommentsByIssueId(id!),
		{enabled: !!id, keepPreviousData: true},
	)
	const commentsInvalidator = useCallback(() => queryClient.invalidateQueries(['issues', 'issue-comments']), [
		queryClient,
	])
	const {mutateAsync: removeIssueComment} = useMutation(deleteIssueComment, {
		onSuccess: commentsInvalidator,
		onError: () => notifyAlert('The issue comment could not be deleted, please retry'),
	})
	const {mutateAsync: addIssueComment, isLoading: isCreating} = useMutation(postIssueComment, {
		onSuccess: commentsInvalidator,
		onError: () => notifyAlert("We couldn't create the Issue Comment, please retry."),
	})
	const isLoading = isIssuesLoading || isCommentsLoading
	const isError = isIssuesError || isCommentsError
	return (
		<SRRightPanelContainer id="IssueLayer">
			<RightPanelHeader title={'Issue Comments'} back={'issues'} />
			<SRRightPanelBody>
				<LoadingWrapper {...{isLoading, isError}} errorMsg="We couldn't display the comments, please retry.">
					{issue && comments && (
						<IssueCommentList
							{...{issue, comments, removeIssueComment: removeIssueComment, addIssueComment, isCreating}}
						/>
					)}
				</LoadingWrapper>
			</SRRightPanelBody>
		</SRRightPanelContainer>
	)
}
