import {Box, Layer, Text} from 'grommet'
import React from 'react'
import {Alert, Close} from 'grommet-icons'
import {SRHeading, SRIconButton, SRPlainButton} from 'sr-react-commons'

export function PrimaryModal(props: {
	open: boolean
	onClose?: () => void
	submitElement: JSX.Element
	title: string
	heading: string
	bodyElement: JSX.Element
	warningIcon?: JSX.Element
	isHideCancel?: boolean
	cancelButtonText?: string
}) {
	return props.open ? (
		<Layer modal position={'top'} margin={{top: '100px'}} onClickOutside={props.onClose} onEsc={props.onClose}>
			<Box pad="medium" gap="small" width={{min: '520px'}} height={{min: 'small'}} fill>
				<Box direction={'row'} justify={'between'} alignContent={'center'}>
					<SRHeading level="4" margin="none">
						{props.title}
					</SRHeading>
					<SRIconButton icon={<Close size="large" />} onClick={props.onClose} />
				</Box>
				<Box direction="row" width={{min: '472px'}} justify={'between'} gap={'medium'}>
					<Box pad={{right: 'medium', left: 'xsmall'}}>
						{props.warningIcon || <Alert size="45px" color="brand-main" />}
					</Box>
					<Box gap={'xsmall'} width={{min: '392px'}}>
						<Text weight={'bold'}>{props.heading}</Text>
						{props.bodyElement}
					</Box>
				</Box>
				<Box direction="row" justify={props?.isHideCancel ? 'end' : 'between'}>
					{!props?.isHideCancel && (
						<SRPlainButton
							onClick={props.onClose}
							label={props.cancelButtonText ? props.cancelButtonText : 'Cancel'}
							size="large"
						/>
					)}
					{props.submitElement}
				</Box>
			</Box>
		</Layer>
	) : null
}
