import {Id} from '../../../entities/value-objects'
import {srProjectApiV3} from '../../../api/project.api.v3'

export const fechAllProgressMonitoringByWatchlist = async (watchlistId: Id) =>
	(await srProjectApiV3.get(`/watchlists/${watchlistId}/progress-monitorings`)).data

export const fetchAllProgressMonitoring = async () => (await srProjectApiV3.get('/progress-monitorings')).data

export const fetchAllProgressMonitoringWithWatchlists = async () => {
	return (await srProjectApiV3.get('/progress-monitorings/with-watchlists')).data
}

export const fetchPdfByWatchList = async (
	id: string,
	reportOptions: {
		showQuality: boolean
		elementTypeBreakdown: boolean
		enableUnderConstruction: boolean
	},
): Promise<Blob> => (await srProjectApiV3.post(`watchlists/${id}/pdf`, reportOptions, {responseType: 'blob'})).data

export function fetchWorkPackageAsCSV(id: string, enableUnderConstruction: boolean) {
	return srProjectApiV3
		.get(`watchlists/${id}/csv?enableUnderConstruction=${enableUnderConstruction}`)
		.then(response => response.data)
}

export const fetchPrimaveraXlsx = async (): Promise<Blob> =>
	(await srProjectApiV3.post(`progress-monitorings/primavera-export`, {}, {responseType: 'blob'})).data
