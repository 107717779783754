import {useSelector} from 'react-redux'
import {selectorClassificationState} from '../../../selectors/classification.selectors'
import {RootState} from '../../../reducers'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'
import {useQuery} from 'react-query'
import {CLASSIFICATIONS_BASE_QUERY_KEY} from '../../Classification/data-management'
import React from 'react'
import {QualityBreakdownPresenter} from './detail/QualityBreakdownPresenter'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {FiltersEntity, toFiltersDTO} from '../../FilterPresets/entities/filter-preset.entities'
import {Box} from 'grommet'
import {useFilterStateForMode} from '../../FilterPresets/components/filters/Filters'
import {useElementFloorsOptions} from '../../FilterPresets/components/filters/Floor/useElementFloorsOptions'
import {useElementRootModelOptions} from '../../FilterPresets/components/filters/RootModels/useElementRootModelOptions'
import {DashboardFiltersPresenter} from './detail/DashboardFiltersPresenter'
import {MagnitudeDistributionBody} from './MagnitudeDistributionBody'
import {fetchClassificationsForInsights} from '../api/insight-dashboard.api'
import {ElementTypeChartBody} from './ElementTypeChartBody'
import {InsightsEmptyView} from './InsightsEmptyView'

const isStatusFiltered = (filter: FiltersEntity) => {
	return !filter.statuses
}

type InsightDashboardProps = {
	resetFilters: () => void
}

export function InsightsDashboardBody({resetFilters}: InsightDashboardProps) {
	const FILTER_MODE = 'overview'
	const {selectedAnalysisView, analysisVersionMap} = useSelector(selectorClassificationState)
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const {approvedFilter} = useSelector((state: RootState) => state.viewerState)
	const selectedProject = useCurrentProject()!
	const model = selectedAnalysisView!.model._id
	const {filter, setFilter, setPartialFilter} = useFilterStateForMode(FILTER_MODE)
	const elementFloorsOptions = useElementFloorsOptions(selectedProject, false, model)
	const elementRootModelOptions = useElementRootModelOptions(selectedProject, false, model)
	const displayRootModelFilter = useFeatureEnabled('displayRootModelFilter')
	const canResetStatus = isStatusFiltered(filter as FiltersEntity)

	const {data: classifications, isLoading: isClassificationsLoading, isError: isClassificationsError} = useQuery(
		[
			CLASSIFICATIONS_BASE_QUERY_KEY,
			'for-insights-classifications',
			{
				selectedAnalysisView,
				analysisVersionMap,
				enableUnderConstruction,
				filter,
			},
		],
		async () => {
			return fetchClassificationsForInsights(
				selectedAnalysisView!._id,
				analysisVersionMap,
				toFiltersDTO(
					filter as FiltersEntity,
					selectedProject.floorMapping,
					selectedProject.elementTypeMapping,
					enableUnderConstruction,
					approvedFilter,
				),
				selectedProject._id,
			)
		},
	)

	const resetStatusFilter = () => {
		setPartialFilter({statuses: null})
	}

	const isEmptyState = () =>
		!isClassificationsLoading && (!classifications || Object.values(classifications).every(cls => !cls.length))

	return (
		<Box gap={'small'} pad={{top: 'small'}} height={'100%'}>
			<DashboardFiltersPresenter
				{...{displayRootModelFilter, elementRootModelOptions, elementFloorsOptions, setFilter, filter}}
			/>
			{isClassificationsError || isEmptyState() ? (
				<InsightsEmptyView onAction={resetFilters} />
			) : (
				<Box gap={'small'}>
					<QualityBreakdownPresenter
						{...{
							classifications,
							enableUnderConstruction,
							resetStatusFilter,
							canResetStatus,
							isClassificationsLoading,
							isClassificationsError,
						}}
					/>
					<MagnitudeDistributionBody {...{isClassifications: !classifications}} />
					<ElementTypeChartBody {...{isClassifications: !classifications}} />
				</Box>
			)}
		</Box>
	)
}
