import React, {ReactEventHandler} from 'react'
import {Box, CheckBox, Stack, Text} from 'grommet'
import {ChatOption, DocumentText, Edit, Update} from 'grommet-icons'
import {format} from 'date-fns'
import {IssueComment} from '../issue-comment.entities'
import './IssueCard.scss'
import {
	COLOR_BY_ISSUE_PRIORITY,
	SRCard,
	SRChip,
	SRDeleteSecondaryIconButton,
	SRHeading,
	SRIconButton,
	SRTooltip,
} from 'sr-react-commons'
import {getIssueTitle, issueDelayed, IssueEntity} from '../issue.entities'
import {AnalysisViewEntityPopulated} from '../../AnalysisView/analysis-view.entities'

type PartialIssue = {
	analysisView: IssueEntity['analysisView']
	diagnosis: IssueEntity['diagnosis']
	issueStatus: IssueEntity['issueStatus']
	issueId?: IssueEntity['issueId']
	numComments?: IssueEntity['numComments']
	createdDate: IssueEntity['createdDate']
	deadline: IssueEntity['deadline']
	location: IssueEntity['location']
	priority: IssueEntity['priority']
}

type IssueCardProps = {
	active: boolean
	handleCardClick?: () => void
	issue: PartialIssue
	handleDeleteClick?: () => void
	disableClick?: boolean
	handleEditClick?: ReactEventHandler
	handleCommentClick?: ReactEventHandler
	goToDetail?: ReactEventHandler
	showEdit: boolean
	classification?: string
	comments?: IssueComment[]
	checked?: boolean
	onCheckChange?: () => void
	issueAnalysisView: AnalysisViewEntityPopulated
	issueSelectRequiresModelSwitch?: boolean
}

const CLASS_NAME_BY_STATUS: Record<string, string> = {
	'Open Issue': 'open',
	'Under Inspection': 'under-review',
	Closed: 'closed',
}

export const IssueCard = ({
	issue,
	handleDeleteClick,
	disableClick,
	showEdit,
	active,
	handleCardClick,
	checked,
	onCheckChange,
	handleEditClick,
	handleCommentClick,
	goToDetail,
	issueAnalysisView,
	issueSelectRequiresModelSwitch,
}: IssueCardProps) => {
	const statusClassName = (issue.issueStatus && CLASS_NAME_BY_STATUS[issue.issueStatus]) || ''

	return (
		<SRCard
			className={`issue-card ${statusClassName}`}
			background={active ? 'selected' : undefined}
			onClick={!disableClick ? handleCardClick : undefined}
		>
			<Box direction="row" justify="between" align="start">
				<SRHeading level={5}>{getIssueTitle(issue)}</SRHeading>
				{checked !== undefined && onCheckChange !== undefined && (
					<CheckBox
						checked={checked}
						onClick={event => {
							event.stopPropagation()
							onCheckChange()
						}}
					/>
				)}
			</Box>

			<Box direction="row">
				<Box gap={'xxsmall'}>
					<Text size="medium">
						<strong>Created:</strong> {issue.createdDate && format(new Date(issue.createdDate), 'E do, MMMM yyyy')}
					</Text>
					{issueAnalysisView && (
						<Text size="medium">
							{issueSelectRequiresModelSwitch ? (
								<Box direction="row" align="center" gap="xsmall">
									<SRTooltip
										width={300}
										arrowPos="left"
										tooltipText="This issue has been created in an analysis that uses a different model version than the selected one, and may take some time and load."
									>
										<Update color="text-light" size="small" />
									</SRTooltip>
									<Text truncate title={issueAnalysisView.name} color="neutral-1">
										{issueAnalysisView.name}
									</Text>
								</Box>
							) : (
								<Box>
									<Text truncate title={issueAnalysisView.name} color="neutral-1">
										{issueAnalysisView.name}
									</Text>
								</Box>
							)}
						</Text>
					)}
				</Box>
			</Box>
			<Box align={'center'} direction="row" pad={{top: 'xsmall'}}>
				<Box direction="row" gap="xsmall">
					<SRChip className={'issue-status-badge'} text={issue.issueStatus || ''} />
					{issue.priority && (
						<SRChip
							color={issue.priority === 'Minor' ? 'black' : 'white'}
							background={COLOR_BY_ISSUE_PRIORITY[issue.priority]}
							text={issue.priority}
						/>
					)}
					{issueDelayed(issue) && <SRChip color="white" background="status-error" text="Delayed" />}
				</Box>
				<Box flex={{grow: 1}} alignSelf="end">
					{showEdit && (
						<Box direction="row" align={'baseline'} gap={'xsmall'} alignSelf={'end'}>
							<SRIconButton title={'Open detail view'} icon={<DocumentText size={'small'} />} onClick={goToDetail} />
							<Stack style={{height: '24px'}} onClick={handleCommentClick} anchor={'bottom-right'}>
								<SRIconButton icon={<ChatOption size={'small'} />} />
								{issue.numComments !== 0 && (
									<Box pad={{horizontal: '4px'}} round background={'light-4'}>
										<Text size={'xsmall'}>{issue.numComments}</Text>
									</Box>
								)}
							</Stack>
							<SRIconButton icon={<Edit size={'small'} />} onClick={handleEditClick} />
						</Box>
					)}
					{!showEdit && handleDeleteClick && (
						<Box direction="row" align={'baseline'} alignSelf={'end'}>
							<SRDeleteSecondaryIconButton onClick={handleDeleteClick} />
						</Box>
					)}
				</Box>
			</Box>
		</SRCard>
	)
}
