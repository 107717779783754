import React, {useEffect} from 'react'
import './Viewer.scss'
import {UserProject} from '../../reducers/userProfile'
import {getForgeAccessToken} from '../../api/forge-client.api'
import '../Viewer.Components/Viewing.Extension.VerticalToolbar'
import '../Viewer.Components/Viewing.Extension.CustomPropertyPanel'
import {doSetForgeMappingReady, doSetForgeReady, doSetViewer, doSetViewerInitialized} from '../../actions/viewer'
import {
	initLayers,
	loadModel,
	loadNaskaExtensions,
	onModelLoaded,
	unloadLoadedNaskaExtensions,
	waitForViewerEvent,
} from './Viewer-helper'
import {viewerAsyncIsolatePatch} from './patch/viewerAsyncIsolatePatch'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../reducers'
import {translateViewportFromViewerToRealWorldCoordinates} from '../../utilities/viewerUtilities'
import {createGlobalNotificationsAction as createAction} from '../../features/GlobalNotifications'
import {store} from '../../store/store'
import {useFeatureEnabled, useFeatureFlags} from '../../features/FeatureFlags/FeatureFlagsProvider'
import Viewer3DConfig = Autodesk.Viewing.Viewer3DConfig
import InitializerOptions = Autodesk.Viewing.InitializerOptions

type ViewerProps = {
	selectedProject: UserProject
	documentUrn: string
}

async function getForgeToken(callback: (token: string, expires_in: any) => void) {
	try {
		const data = await getForgeAccessToken()
		callback(data.access_token, data.refreshTimer)
	} catch (e) {
		store.dispatch(createAction.error(e.response?.data?.message || e.message || 'Unknown error'))
	}
}

export const Viewer = ({selectedProject, documentUrn}: ViewerProps): JSX.Element => {
	const viewer = useSelector((state: RootState) => state.viewerState.viewer)
	const viewerInitialized = useSelector((state: RootState) => state.viewerState.viewerInitialized)
	const {forceNearRadius} = selectedProject.viewerFeatureFlags
	const useSVF2ViewerOptions = useFeatureEnabled('useSVF2ViewerOptions')
	const dispatch = useDispatch()
	const featureFlags = useFeatureFlags()

	useEffect(() => {
		return () => {
			if (window.NOP_VIEWER) {
				window.NOP_VIEWER.finish()
				dispatch(doSetViewer(null))
				dispatch(doSetViewerInitialized(false))
			}
		}
	}, [dispatch])

	// 1. INITIALIZE VIEWER
	useEffect(() => {
		const viewerDiv = document.getElementById('viewerDiv')
		if (!viewerInitialized && viewerDiv && !viewer) {
			const options: InitializerOptions = {
				env: useSVF2ViewerOptions ? 'AutodeskProduction2' : 'AutodeskProduction',
				api: useSVF2ViewerOptions ? 'streamingV2' : 'derivativeV2',
				getAccessToken: getForgeToken,
			}
			const config3d: Viewer3DConfig = {
				extensions: ['Viewing.Extension.VerticalToolbar'],
				theme: 'light-theme',
			}

			const newViewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv, config3d)
			Autodesk.Viewing.Initializer(options, () => {
				newViewer.start()
				viewerAsyncIsolatePatch(newViewer)
				dispatch(doSetForgeReady({forgeViewer: true}))
				dispatch(doSetViewer(newViewer))
				dispatch(doSetViewerInitialized(true))
			})
		}
	}, [viewerInitialized, viewer, dispatch, useSVF2ViewerOptions])

	// 2. After Viewer Is Initialized load a model
	useEffect(() => {
		if (viewerInitialized && viewer && viewer.impl) {
			initLayers(viewer)
			const viewBeforeLoading = viewer.model
				? translateViewportFromViewerToRealWorldCoordinates(
						viewer.getState({viewport: true}).viewport,
						viewer.model.getGlobalOffset(),
						viewer.model.getUnitScale(),
				  )
				: undefined
			unloadLoadedNaskaExtensions(viewer)
			dispatch(doSetForgeMappingReady({forgeMapping: false}))
			loadModel(documentUrn, viewer, forceNearRadius || null).then(async modelTreeLoaded => {
				// If the model has 3D lines there might be precision issues in the forge viewer that could cause
				// that the wrong element gets selected when clicking on them. Forge is working in a solution his
				// internal ticket code is LMV-6888 we can ask about the status of this reaching forge.help@autodesk.com
				// This is a workaround, and should be removed if forge fixes the issue.
				viewer.hideLines(true)
				const waitEvents = [
					...(modelTreeLoaded ? [] : [Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT]),
					Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
				]
				await Promise.all(waitEvents.map(e => waitForViewerEvent(viewer, e)))
				onModelLoaded(viewer, selectedProject, viewBeforeLoading || selectedProject.viewerState?.viewport)
				await loadNaskaExtensions(viewer, featureFlags)
				dispatch(doSetForgeMappingReady({forgeMapping: true}))
			})
		}
	}, [viewer, documentUrn, viewerInitialized, forceNearRadius, dispatch, selectedProject, featureFlags])

	return <div className="forge-viewer" id="viewerDiv" style={{height: '100%', position: 'relative'}} />
}
