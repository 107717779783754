import {FeatureFlag, validFeatureFlag} from './feature-flag'

export class FeatureFlagConfig<T extends string> {
	constructor(readonly featureFlags: FeatureFlag<T>[]) {}

	validFeatureFlag(id: string): id is T {
		return validFeatureFlag(id, this.featureFlags)
	}

	getDefaultValue(id: T): boolean {
		return this.featureFlags.find(flag => flag.id === id)!.defaultValue
	}

	getFeatureFlags(): T[] {
		return this.featureFlags.map(flag => flag.id)
	}
}
