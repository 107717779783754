import _ from 'lodash'
import {VictoryThemeDefinition} from 'victory'
import {SR_COLOR_BRAND, SR_COLOR_NEUTRAL_2, SR_COLOR_TEXT_LIGHT} from 'sr-react-commons'

// *
// * Colors
// *
const WHITE_BG = '#f0f0f0'
const COLORS = ['#252525', '#525252', '#737373', '#969696', '#bdbdbd', '#d9d9d9', WHITE_BG]

const DARK_GREY = SR_COLOR_BRAND
const DARK_TEXT = SR_COLOR_TEXT_LIGHT
const GREY = SR_COLOR_NEUTRAL_2
// *
// * Typography
// *
const SANS_SERIF_FONT_FAMILY = "'Inter', sans-serif"
const LETTER_SPACING = 'normal'
const FONT_SIZE = 8
// *
// * Layout
// *
export const baseProps = {
	width: 450,
	height: 300,
	padding: {
		top: 50,
		bottom: 50,
		left: 50,
		right: 25,
	},
	colorScale: COLORS,
}
// *
// * Labels
// *
const baseLabelStyles = {
	fontFamily: SANS_SERIF_FONT_FAMILY,
	fontSize: FONT_SIZE,
	letterSpacing: LETTER_SPACING,
	padding: 10,
	fill: DARK_GREY,
	stroke: 'transparent',
}

const centeredLabelStyles = _.assign({textAnchor: 'middle'}, baseLabelStyles)
// *
// * Strokes
// *
const STROKE_LINE_CAP = 'round'
const STROKE_LINEJOIN = 'round'

export const insightDashboardTheme: VictoryThemeDefinition = {
	area: {
		...baseProps,
		style: {
			data: {
				fill: DARK_GREY,
			},
			labels: baseLabelStyles,
		},
	},
	axis: {
		...baseProps,
		style: {
			axis: {
				fill: 'transparent',
				stroke: GREY,
				strokeWidth: 1,
				strokeLinecap: STROKE_LINE_CAP,
				strokeLinejoin: STROKE_LINEJOIN,
			},
			axisLabel: _.assign({}, centeredLabelStyles, {
				padding: 25,
				color: DARK_TEXT,
				fill: DARK_TEXT,
			}),
			grid: {
				fill: 'none',
				stroke: GREY,
				pointerEvents: 'painted',
			},
			ticks: {
				fill: 'transparent',
				size: 1,
				stroke: GREY,
			},
			tickLabels: _.assign({}, baseLabelStyles, {
				fontSize: '8px',
			}),
		},
	},
	bar: {
		...baseProps,
		style: {
			data: {
				fill: DARK_GREY,
				padding: 8,
				strokeWidth: 0,
			},
			labels: baseLabelStyles,
		},
	},
	boxplot: {
		...baseProps,
		style: {
			max: {padding: 8, stroke: DARK_GREY, strokeWidth: 1},
			maxLabels: _.assign({}, baseLabelStyles, {padding: 3}),
			median: {padding: 8, stroke: DARK_GREY, strokeWidth: 1},
			medianLabels: _.assign({}, baseLabelStyles, {padding: 3}),
			min: {padding: 8, stroke: DARK_GREY, strokeWidth: 1},
			minLabels: _.assign({}, baseLabelStyles, {padding: 3}),
			q1: {padding: 8, fill: GREY},
			q1Labels: _.assign({}, baseLabelStyles, {padding: 3}),
			q3: {padding: 8, fill: GREY},
			q3Labels: _.assign({}, baseLabelStyles, {padding: 3}),
		},
		boxWidth: 20,
	},
	candlestick: {
		...baseProps,
		style: {
			data: {
				stroke: DARK_GREY,
				strokeWidth: 1,
			},
			labels: _.assign({}, baseLabelStyles, {padding: 5}),
		},
		candleColors: {
			positive: '#ffffff',
			negative: DARK_GREY,
		},
	},
	chart: baseProps,
	errorbar: {
		...baseProps,
		borderWidth: 8,
		style: {
			data: {
				fill: 'transparent',
				stroke: DARK_GREY,
				strokeWidth: 2,
			},
			labels: baseLabelStyles,
		},
	},
	group: {
		...baseProps,
		colorScale: COLORS,
	},
	histogram: {
		...baseProps,
		style: {
			data: {
				fill: GREY,
				stroke: DARK_GREY,
				strokeWidth: 2,
			},
			labels: baseLabelStyles,
		},
	},
	legend: {
		colorScale: COLORS,
		gutter: 10,
		orientation: 'vertical',
		titleOrientation: 'top',
		style: {
			data: {
				type: 'circle',
			},
			labels: baseLabelStyles,
			title: _.assign({}, baseLabelStyles, {padding: 5}),
		},
	},
	line: {
		...baseProps,
		style: {
			data: {
				fill: 'transparent',
				stroke: DARK_GREY,
				strokeWidth: 2,
			},
			labels: baseLabelStyles,
		},
	},
	pie: {
		style: {
			data: {
				padding: 10,
				stroke: 'transparent',
				strokeWidth: 1,
			},
			labels: _.assign({}, baseLabelStyles, {padding: 20}),
		},
		colorScale: COLORS,
		width: 400,
		height: 400,
		padding: 50,
	},
	scatter: {
		...baseProps,
		style: {
			data: {
				fill: DARK_GREY,
				stroke: 'transparent',
				strokeWidth: 0,
			},
			labels: baseLabelStyles,
		},
	},
	stack: {
		...baseProps,
		colorScale: COLORS,
	},
	tooltip: {
		style: _.assign({}, baseLabelStyles, {padding: 0, pointerEvents: 'none'}),
		flyoutStyle: {
			stroke: DARK_GREY,
			strokeWidth: 1,
			fill: WHITE_BG,
			pointerEvents: 'none',
		},
		flyoutPadding: 5,
		cornerRadius: 5,
		pointerLength: 10,
	},
	voronoi: {
		...baseProps,
		style: {
			data: {
				fill: 'transparent',
				stroke: 'transparent',
				strokeWidth: 0,
			},
			labels: _.assign({}, baseLabelStyles, {
				padding: 5,
				pointerEvents: 'none',
			}),
			flyout: {
				stroke: DARK_GREY,
				strokeWidth: 1,
				fill: WHITE_BG,
				pointerEvents: 'none',
			},
		},
	},
}
