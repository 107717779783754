import {ClassificationTableRow} from './UseClassificationsTable'
import {useGlobalNotifications} from '../../features/GlobalNotifications'
import {fetchIssuesByGlobalIds} from '../../features/IssueManagement/api/issue-management.api'
import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {IssueEntity} from '../../features/IssueManagement/issue.entities'

export function useIssuesByGlobalId(tableData: ClassificationTableRow[], showIssueColumn: boolean) {
	const {notifyAlert} = useGlobalNotifications()
	const externalIds = tableData.map(c => c.externalId).sort()
	const {data: classificationIssues} = useQuery<IssueEntity[], AxiosError>(
		['issues', 'issues-by-global-ids', externalIds],
		() => fetchIssuesByGlobalIds(externalIds),
		{
			enabled: showIssueColumn && !!tableData,
			onError: () => notifyAlert("We couldn't display the classification issues in the table, please retry."),
		},
	)
	return (externalId: string) => classificationIssues?.filter(i => i.externalId.includes(externalId)) || []
}
