import React, {useEffect, useState} from 'react'

import {useBasePath} from '../../../../hooks/useBasePath'
import {WatchlistListPresenter} from './WatchlistListPresenter'
import {useRouteMatch} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {selectorProgressAggregations} from '../../selectors/progress.selectors'
import {goTo, goToFn} from '../../../../router/history'
import {useGlobalNotifications} from '../../../GlobalNotifications'
import {getAllProgressMonitoringWithWatchlists} from '../../slices/watchlist.thunk'
import {RootState} from '../../../../reducers'
import {watchlistSlice} from '../../slices/watchlist.slice'
import {selectorProgressMonitoring} from '../../selectors/watchlist.selectors'
import {useClassificationState} from '../../../../reducers/classification.slice'
import {useLatestModel} from '../../../../hooks/UseLatestModel'
import {useDisableVerticalToolbar} from '../../../../hooks/useDisableVerticalToolbarButtons'
import {fetchPrimaveraXlsx} from '../../api/progress-monitoring.api'
import {useCurrentProject} from '../../../../hooks/useCurrentProject'
import {format} from 'date-fns'

export function WatchlistList() {
	const {analysisViews} = useClassificationState()
	const currentProject = useCurrentProject()!
	const basePath = useBasePath()
	const {path} = useRouteMatch()
	const dispatch = useDispatch()
	const selectedProject = useSelector((state: RootState) => state.userProfileState.selectedProject)
	const [nameFilter, setNameFilter] = useState('')
	const {notifyAlert} = useGlobalNotifications()
	const goToNew = goToFn(basePath + 'progress/work-packages/new')
	const goToEdit = (id: string) => goTo(`${basePath}progress/work-packages/${id}/edit`)
	const goToDetail = (id: string) => goTo(`${basePath}progress/work-packages/${id}/detail`)

	useEffect(() => {
		;(async () => {
			if (!selectedProject) {
				dispatch(watchlistSlice.actions.doWatchlistRemoveAll())
			} else {
				dispatch(getAllProgressMonitoringWithWatchlists())
			}
		})()
	}, [notifyAlert, dispatch, selectedProject])
	const allProgresses = useSelector(selectorProgressAggregations)
	const filteredWatchLists = allProgresses.filter(progress => {
		if (!nameFilter || nameFilter === '') return true
		return progress.watchlist.name.match(new RegExp(nameFilter, 'i'))
	})
	const {isLoading, isError} = useSelector(selectorProgressMonitoring)
	const latestModel = useLatestModel()
	const hasWatchLists = allProgresses.length > 0
	useDisableVerticalToolbar()
	const downloadPrimaveraXlsx = async () => {
		try {
			const xlsx = await fetchPrimaveraXlsx()
			saveAs(xlsx, `Naska_AI.p6.${currentProject.tenantId}.${format(new Date(), 'yyyyMMdd-HHmm')}.xlsx`)
		} catch (e) {
			console.error('Error downloading the Primavera xlsx', e)
		}
	}
	return (
		<WatchlistListPresenter
			{...{
				path,
				filteredWatchLists,
				analysisViews,
				hasWatchLists,
				nameFilter,
				setNameFilter,
				goToNew,
				goToEdit,
				goToDetail,
				isLoading,
				isError,
				downloadPrimaveraXlsx,
				latestModelId: latestModel?._id,
			}}
		/>
	)
}
