import {VoteEntity} from './vote.entity'
import {UserProject} from '../../../reducers/userProfile'
import {eoiApiV3} from '../eoi.api'
import {VoteDTO} from './vote.dtos'

let currentProjectId: UserProject['_id']
export const setCurrentProjectId = (projectId: UserProject['_id']) => {
	currentProjectId = projectId
}

export async function vote(vote: VoteDTO): Promise<VoteEntity> {
	return eoiApiV3.post(`/projects/${currentProjectId}/votes`, vote).then(response => response.data)
}
