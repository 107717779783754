import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {UserProject} from '../../../../reducers/userProfile'
import {ForgeFSFileNode, ForgeFSFolderNode, ForgeFSNode, getChildrenNodesFactory} from './ForgeFileExplorer.logic'
import {ForgeFileType} from '../../forge-integration.entities'
import {SRFileExplorer, SRFileExplorerProps} from '../../../../components/CommonsCandidate/FileExplorer/SRFileExplorer'
import {
	copyNodes,
	findNodeById,
	SRFileExplorerFileNode,
	SRFileExplorerFolderNode,
} from '../../../../components/CommonsCandidate/FileExplorer/SRFileExplorer.logic'
import {ForgeOauthError, handleForgeOAuthError} from '../../oauth/ForgeOauthError'
import {useGlobalNotifications} from '../../../GlobalNotifications'

export type ForgeFileExplorerProps = {
	project: UserProject
	onSelectionChange: SRFileExplorerProps['onSelectionChange']
	onFileError: () => void
	fileType: ForgeFileType
}

export const ForgeFileExplorer = ({project, fileType, onSelectionChange, onFileError}: ForgeFileExplorerProps) => {
	const {notifyError} = useGlobalNotifications()
	const [sourceTree, setSourceTree] = useState<ForgeFSNode[]>()
	const [rootNodes, setRootNodes] = useState<ForgeFSNode[]>()
	const getNodes = useMemo(() => getChildrenNodesFactory(project._id, fileType), [project, fileType])
	const refreshRootNodes = useCallback(() => {
		sourceTree && setRootNodes(copyNodes(sourceTree))
	}, [sourceTree])
	useEffect(() => {
		!sourceTree &&
			getNodes &&
			getNodes()
				.then(setSourceTree)
				.catch(e => {
					if (e instanceof ForgeOauthError) {
						handleForgeOAuthError(e, notifyError)
					} else {
						notifyError(`An unexpected error occurred while loading the file list.`)
					}
					setSourceTree([])
					onFileError()
				})
	}, [getNodes, notifyError, onFileError, sourceTree])
	useEffect(refreshRootNodes, [refreshRootNodes])
	const toggleFolderExpanded = useMemo(
		() => (node: SRFileExplorerFolderNode) => {
			const folder = findNodeById<ForgeFSFolderNode>(sourceTree!, node.id)!
			folder.toggleExpanded().then(refreshRootNodes)
			refreshRootNodes()
		},
		[refreshRootNodes, sourceTree],
	)
	const toggleFileSelected = useMemo(
		() => (node: SRFileExplorerFileNode) => {
			const file = findNodeById<ForgeFSFileNode>(sourceTree!, node.id)!
			file.toggleSelected()
			refreshRootNodes()
		},
		[refreshRootNodes, sourceTree],
	)
	return <SRFileExplorer {...{rootNodes, onSelectionChange, toggleFolderExpanded, toggleFileSelected}} />
}
