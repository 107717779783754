import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {fetchIssueById, fetchIssueElements} from '../api/issue-management.api'
import {Box} from 'grommet'
import {restoreViewerFromIssue} from '../issueUtils'
import {IssueDetailBox} from './IssueDetailBox'
import {useSelector} from 'react-redux'
import {RootState} from '../../../reducers'
import {ElementEntity, getMappedElementAttributes} from '../../../entities/element.entities'
import {useQuery} from 'react-query'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {AxiosError} from 'axios'
import {IssueElementsBox} from './IssueElementsBox'
import {IssueAssetsBox} from './IssueAssetsBox'
import {IssueMoreInfoBox} from './IssueMoreInfoBox'
import {LoadingWrapper} from '../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {RightPanelHeader} from '../../../components/CommonsCandidate/Layout/RightPanel/RightPanelHeader'
import {SRHeading, SRRightPanelBody, SRRightPanelContainer} from 'sr-react-commons'
import {Id} from '../../../entities/value-objects'
import {getIssueTitle, IssueEntity} from '../issue.entities'
import {useClassificationState} from '../../../reducers/classification.slice'
import IssuePDFButton from '../Export/components/IssuePDFButton'
import IssueBCFButton from '../Export/components/IssueBCFButton'
import IssueCSVButton from '../Export/components/IssueCSVButton'

export function DetailIssueRoute() {
	const {id} = useParams<{id: Id}>()
	const {analysisViews} = useClassificationState()
	const {threeSixtyLockedView} = useSelector(selectorViewerProperties)
	const {data: issue, isError: isIssuesError, isLoading: isIssuesLoading} = useQuery<IssueEntity, AxiosError>(
		['issues', 'issue-by-id', id],
		() => fetchIssueById(id!),
		{enabled: id !== undefined},
	)
	const project = useSelector((state: RootState) => state.userProfileState.selectedProject!)
	const analysisView = issue ? analysisViews!.find(av => av._id === issue.analysisView) || null : null
	const {data: elements, isError: isElementsError, isLoading: isElementsLoading} = useQuery<
		ElementEntity[],
		AxiosError
	>(
		['issues', 'issue-elements', issue, project.floorMapping, project.elementTypeMapping],
		async () =>
			(await fetchIssueElements(issue!.forgeObjectId, issue!.model)).result.map(element => ({
				...element,
				...getMappedElementAttributes(element, project.floorMapping, project.elementTypeMapping),
			})),
		{enabled: !!issue},
	)
	useEffect(() => {
		issue && !threeSixtyLockedView && restoreViewerFromIssue(issue)
	}, [issue, threeSixtyLockedView])
	const isLoading = isIssuesLoading || isElementsLoading
	const isError = isIssuesError || isElementsError

	const top = issue && <SRHeading level={4}>{getIssueTitle(issue)}</SRHeading>

	return (
		<SRRightPanelContainer id="IssueLayer">
			<RightPanelHeader title="Issue Details" back={'issues'}>
				<Box pad={{horizontal: 'xsmall'}} flex={false} align={'center'} direction="row" gap={'xsmall'}>
					{elements && issue && (
						<>
							<IssuePDFButton issue={issue} />
							<IssueBCFButton issue={issue} />
							<IssueCSVButton issue={issue} />
						</>
					)}
				</Box>
			</RightPanelHeader>
			<SRRightPanelBody {...{top}}>
				<LoadingWrapper {...{isLoading, isError}} errorMsg="We couldn't display Issue details, please retry.">
					{issue && (
						<Box gap={'small'} flex={false}>
							<IssueDetailBox issue={issue} analysisView={analysisView} />
							{!Object.keys(issue?.customProperties || {}).length ? null : <IssueMoreInfoBox issue={issue} />}
							<IssueElementsBox elements={elements} />
							<IssueAssetsBox issue={issue} />
						</Box>
					)}
				</LoadingWrapper>
			</SRRightPanelBody>
		</SRRightPanelContainer>
	)
}
