import React, {useMemo} from 'react'
import {Box} from 'grommet'
import {useManagementDashboardState} from '../core/management-dashboad.slice'
import {
	ManagementDashboardPage,
	pageEmptyViewParams,
	pageName,
	pageSingularName,
} from '../core/management-dashboard-page.utils'
import {useProjectFileByVisibilityWithSearch} from './UseProjectFilesByVisibility'
import {LoadingWrapper} from '../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {deleteFile, FileVisibility} from '../../../api/files.api'
import {ManagementDashboardFileTable} from './ManagementDashboardFileTable'
import {SearchBox} from '../../../components/CommonsCandidate/SearchBox/SearchBox'
import {isNotEmpty} from '../../../utilities/arrayUtilities'
import {useQueryClient} from 'react-query'
import {userProjectHasPermission} from '../../../reducers/userProfile'
import {useGlobalNotifications} from '../../GlobalNotifications'
import {PermissionsEnum} from '../../../entities/auth'
import {getProjectFilesByVisibilityProjectQueryKey} from './projectFileQueryKey.utils'
import {SRMultiActionDropButton} from '../../../components/CommonsCandidate/DropButton/SRMultiActionDropButton'
import {SRBrandButton, SREmptyView, SREmptySearch} from 'sr-react-commons'
import {Add, Upload} from 'grommet-icons'
import {getIntegrationIcon, getIntegrationName} from '../../ForgeIntegration/forge-integration.utils'
import {ManagmentDashboardFilePageFromForgeOps} from './ManagementDashboardBaseFilePage'

export type ManagementDashboardBaseFileListProps = {
	fileVisibility: FileVisibility
	onOpenUploadModal: () => void
	fromForgeOps?: ManagmentDashboardFilePageFromForgeOps
}

type EmptyFilesProps = {
	page: ManagementDashboardPage
	projectHasFiles: boolean
	onOpenUploadModal: () => void
	searchText: string
}
const EmptyFiles = (props: EmptyFilesProps) => {
	const emptyViewParams = useMemo(() => pageEmptyViewParams(props.page), [props.page])
	return !emptyViewParams ? (
		<div>'No files to display'</div>
	) : !props.projectHasFiles && props.searchText.length ? (
		<SREmptySearch title={'No matching files found.'} message={'Please try again.'} />
	) : (
		<SREmptyView {...emptyViewParams} onAction={props.onOpenUploadModal} />
	)
}

export const ManagementDashboardBaseFileList = ({
	fileVisibility,
	onOpenUploadModal,
	fromForgeOps,
}: ManagementDashboardBaseFileListProps) => {
	const {page, project} = useManagementDashboardState()
	const {files, isLoading, isError, searchText, setSearchText} = useProjectFileByVisibilityWithSearch(
		project,
		fileVisibility,
		['uploaded'],
	)
	const queryClient = useQueryClient()
	const {notifyAlert} = useGlobalNotifications()
	const isAdmin = userProjectHasPermission(project, PermissionsEnum.ADMIN)
	const onDeleteFile = async (deleteId: string) => {
		if (project && deleteId) {
			return deleteFile(project._id, fileVisibility, deleteId)
				.then(() =>
					queryClient.invalidateQueries(getProjectFilesByVisibilityProjectQueryKey(project?._id, fileVisibility)),
				)
				.catch(err => {
					console.error(err)
					notifyAlert("We couldn't remove the file, please retry.")
				})
		}
	}
	return (
		<Box gap={'small'} fill>
			<Box justify="between" direction="row" alignContent="center">
				<SearchBox placeholder={`Search ${pageName(page)}`} onChange={setSearchText} />
				<SRMultiActionDropButton
					buttonClass={SRBrandButton}
					size="medium"
					label={`Upload ${pageSingularName(page)?.toLowerCase()}`}
					icon={<Add size="small" />}
					actions={[
						{
							icon: <Upload size={'medium'} />,
							label: 'Upload from my device',
							onClick: onOpenUploadModal,
						},
						...(!fromForgeOps
							? []
							: [
									{
										icon: getIntegrationIcon(fromForgeOps.integration),
										label: `Import from ${getIntegrationName(fromForgeOps.integration)}`,
										onClick: fromForgeOps.onOpenUploadModal,
									},
							  ]),
					]}
				/>
			</Box>
			<LoadingWrapper
				{...{isLoading, isError}}
				fill
				errorMsg="We couldn't display the files, please retry."
				isEmpty={!files?.length}
				emptyMsg={
					<EmptyFiles
						searchText={searchText}
						projectHasFiles={isNotEmpty(files)}
						page={page}
						onOpenUploadModal={onOpenUploadModal}
					/>
				}
			>
				{!files?.length ? null : (
					<ManagementDashboardFileTable onDeleteFile={onDeleteFile} page={page} files={files} isAdmin={isAdmin} />
				)}
			</LoadingWrapper>
		</Box>
	)
}
