import React from 'react'
import {Box, Text} from 'grommet'
import {firstProgressMonitoring, ProgressAggregation} from '../../aggregations/progress.aggregations'
import {BreakDownDonutChart} from '../charts/BreakDownDonutChart'
import {BreakDownDataTable, ProgressMonitoringData} from '../charts/BreakDownDataTable'
import {format} from 'date-fns'
import {SRCard, SRHeading} from 'sr-react-commons'

function buildProgressMonitoringData(
	progress: ProgressAggregation,
	enableUnderConstruction: boolean,
): ProgressMonitoringData {
	const {verified, total, missing, deviated, under_construction} = firstProgressMonitoring(progress)
	const no_data = total ? total - verified - missing - deviated - under_construction : 0

	return {
		verified,
		deviated: enableUnderConstruction ? deviated : deviated + under_construction,
		under_construction: enableUnderConstruction ? under_construction : 0,
		missing,
		no_data,
	}
}

export function WatchlistProgressBreakDownCardPresenter({
	progress,
	enableUnderConstruction,
}: {
	progress: ProgressAggregation
	enableUnderConstruction: boolean
}) {
	const data = buildProgressMonitoringData(progress, enableUnderConstruction)
	return (
		<SRCard gap={'small'}>
			<SRHeading level={5} size={'small'}>
				Progress break down
			</SRHeading>
			<Box direction={'row'}>
				<Box width={'138px'} height={'138px'} alignSelf={'center'}>
					<BreakDownDonutChart data={data} enableUnderConstruction={enableUnderConstruction} />
				</Box>
				<Box pad={{left: 'small'}}>
					<BreakDownDataTable data={data} enableUnderConstruction={enableUnderConstruction} />
				</Box>
			</Box>
			<Text size={'small'} color={'neutral-1'}>
				<strong>Last Analysis: </strong>
				{progress.progresses.length > 0
					? `${progress.progresses[0].analysis.name} - ${format(
							new Date(progress.progresses[0].analysis.scanDate),
							'dd/MM/yyyy',
					  )}`
					: 'None'}
			</Text>
		</SRCard>
	)
}
