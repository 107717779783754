import React, {ReactNode} from 'react'
import {Box, CheckBox, Text} from 'grommet'
import {CaretDownFill, CaretUpFill} from 'grommet-icons'
import styled from 'styled-components'
import {SRIconButton} from 'sr-react-commons'
import {normalizeColor} from 'grommet/utils'

const HoverableRow = styled(Box)`
	&:hover {
		background-color: ${props => normalizeColor('light-2', props.theme)};
`

export function CheckBoxListItem(props: {
	label: string
	checked: boolean
	expanded: boolean
	onChecked: () => void
	onExpand: () => void
	isLeaf: boolean
	boldLabel?: boolean
	labelFormatter?: (label: string) => ReactNode
	titleFormatter?: (label: string) => string
}) {
	return (
		<HoverableRow flex={false} pad="xxsmall" gap="xsmall" direction="row">
			<CheckBox
				label={
					<Box width={{max: '250px'}}>
						<Text
							data-cy={'checkbox-' + props.label}
							title={props.titleFormatter?.(props.label) || props.label}
							truncate
							weight={props.boldLabel ? 'bold' : undefined}
							size="small"
						>
							{props.labelFormatter?.(props.label) || props.label}
						</Text>
					</Box>
				}
				type="checkbox"
				checked={props.checked}
				onChange={props.onChecked}
			/>
			{!props.isLeaf && (
				<SRIconButton
					alignSelf="center"
					icon={props.expanded ? <CaretUpFill /> : <CaretDownFill />}
					pad="0"
					onClick={props.onExpand}
				/>
			)}
		</HoverableRow>
	)
}
