import React, {useEffect} from 'react'

import {useBasePath} from '../../../../hooks/useBasePath'
import {WatchlistDetailPresenter} from './WatchlistDetailPresenter'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {selectorCurrentProgress} from '../../selectors/progress.selectors'
import {watchlistSlice} from '../../slices/watchlist.slice'
import {goTo} from '../../../../router/history'
import {selectorCurrentProgressMonitoring, selectorCurrentWatchlist} from '../../selectors/watchlist.selectors'
import {getCurrentProgressMonitoringAndWatchlist} from '../../slices/watchlist.thunk'
import {Id} from '../../../../entities/value-objects'
import {useLatestModel} from '../../../../hooks/UseLatestModel'
import {workPackageHasInvalidIds} from '../../watchlist.utils'
import {useExportWorkPackageToCSV} from '../../hooks/UseExportWorkPackageToCSV'
import {useFeatureEnabled} from '../../../FeatureFlags/FeatureFlagsProvider'

export function WatchlistDetail() {
	const latestModel = useLatestModel()
	const basePath = useBasePath()
	const {watchlistId} = useParams<{watchlistId: Id}>()
	const watchlist = useSelector(selectorCurrentWatchlist)
	const dispatch = useDispatch()
	const goToEdit = (id: string) => goTo(`${basePath}progress/work-packages/${id}/edit`)
	useEffect(() => {
		dispatch(getCurrentProgressMonitoringAndWatchlist(watchlistId!))
		return () => {
			dispatch(watchlistSlice.actions.doWatchlistRemoveCurrent())
		}
	}, [dispatch, watchlistId])
	const progress = useSelector(selectorCurrentProgress)
	const {isLoading, isError} = useSelector(selectorCurrentProgressMonitoring)
	const showPDFDownload = useFeatureEnabled('enableWorkPackageToPDFExport')
	const showElementTypeBreakdown = useFeatureEnabled('pmWpPDFElementTypeBreakdown')
	const exportToCSVProps = useExportWorkPackageToCSV(watchlist)
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')

	return (
		<WatchlistDetailPresenter
			{...{
				progress,
				isLoading,
				isError,
				basePath,
				goToEdit,
				showPDFDownload,
				showElementTypeBreakdown,
				exportToCSVProps,
				enableUnderConstruction,
			}}
			hasInvalidIds={Boolean(latestModel && watchlist && workPackageHasInvalidIds(watchlist, latestModel._id))}
		/>
	)
}
