import React from 'react'
import {ManagementDashboardPageLayout} from '../core/components/layout/ManagementDashboardPageLayout'
import {useActiveModel} from '../../Models/UseActiveModel'
import {LoadingWrapper} from '../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {useProjectFileByVisibilityWithSearch} from '../file/UseProjectFilesByVisibility'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {ManagementDashboardAnalysisRequestForm} from './form/ManagementDashboardAnalysisRequestForm'
import {useRootModelInformations} from '../../Models/useRootModelsInformation'

export const ManagementDashboardAnalysisRequestWizardPage = () => {
	const project = useCurrentProject()
	const {data: activeModel, isLoading: isActiveModelLoading, isError: isActiveModelError} = useActiveModel()
	const {data: rootModels, isLoading: isRootModelLoading, isError: isRootModelError} = useRootModelInformations(
		activeModel?._id,
	)
	const {
		files: scans,
		isLoading: isScansLoading,
		isError: isScansError,
	} = useProjectFileByVisibilityWithSearch(project!, 'scan', ['uploaded'])
	const {
		files: documents,
		isLoading: isDocumentsLoading,
		isError: isDocumentsError,
	} = useProjectFileByVisibilityWithSearch(project!, 'document', ['uploaded'])
	const isLoading = isActiveModelLoading || isScansLoading || isDocumentsLoading || isRootModelLoading
	const isError = isActiveModelError || isScansError || isDocumentsError || isRootModelError
	return (
		<ManagementDashboardPageLayout>
			<LoadingWrapper
				{...{isLoading, isError, errorMsg: 'There was a problem loading this page. Please retry or contact support.'}}
				fill
			>
				{isLoading ? null : (
					<ManagementDashboardAnalysisRequestForm
						activeModel={activeModel}
						rootModels={rootModels ?? []}
						scans={scans || []}
						documents={documents || []}
					/>
				)}
			</LoadingWrapper>
		</ManagementDashboardPageLayout>
	)
}
