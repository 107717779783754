import {Box, BoxProps, Text} from 'grommet'
import React, {ComponentProps, ReactNode} from 'react'
import {ColorType} from 'grommet/utils'

export type ManagementDashboardTableCellProps = {
	flexible?: boolean
	width?: BoxProps['width']
	children?: ReactNode
}

export const ManagementDashboardTableCell = ({flexible, width, children}: ManagementDashboardTableCellProps) => (
	<Box
		fill={flexible ? 'horizontal' : undefined}
		flex={flexible || {shrink: 0, grow: 0}}
		width={width}
		children={children}
		direction={'row'}
		gap={'small'}
		pad={{right: 'xsmall'}}
	/>
)

export const ManagementDashboardTableHeaderCell = ({children, ...props}: ManagementDashboardTableCellProps) => (
	<ManagementDashboardTableCell {...props}>
		<Text color={'brand'} weight={'bold'}>
			{children}
		</Text>
	</ManagementDashboardTableCell>
)

export const ManagementDashboardTableRowBox = ({
	borderColor = 'neutral-2',
	size = 'medium',
	children,
}: {
	borderColor?: ColorType
	size?: 'medium' | 'small'
	children: ReactNode
}) => (
	<Box
		flex={{grow: 0, shrink: 0}}
		fill={'horizontal'}
		direction={'row'}
		border={{side: 'bottom', size: 'xsmall', color: borderColor}}
		height={size === 'medium' ? '54px' : '32px'}
		children={children}
		style={{alignItems: 'center'}}
	/>
)

export const ManagementDashboardTable = (props: ComponentProps<typeof Box>) => <Box {...props} overflow={'auto'} fill />
