import {PermissionsEnum} from '../entities/auth'
import {useSelector} from 'react-redux'
import {RootState} from '../reducers'
import {UserProject, userProjectHasPermission} from '../reducers/userProfile'

export function useCurrentUserProject(projectId: string | undefined): UserProject | null {
	const userProjects = useSelector((state: RootState) => state.userProfileState.userProjects)
	return userProjects?.find(p => p._id === projectId) || null
}

export function useCurrentUserHasPermissionOnResource(permission: PermissionsEnum, projectId: string | undefined) {
	const project = useCurrentUserProject(projectId)
	if (!project) return false
	return userProjectHasPermission(project, permission)
}
