import {SRHeading, SRSimpleSelect} from 'sr-react-commons'
import React, {ChangeEvent, useCallback, useMemo} from 'react'
import {ProjectFileEntity} from '../../../../../api/files.api'
import {Box, DateInput, RadioButtonGroup} from 'grommet'
import {ManagementDashboardAnalysisRequestStepState} from '../ManagementDashboardAnalysisRequestFormInProgress'
import {NonUndefined} from 'grommet/utils'
import {ManagementDashboardFileSelection} from '../../../core/components/fileSelection/ManagementDashboardFileSelection'
import {ManagementDashboardAnalysisRequestSectionBox} from '../../support/ManagementDashboardAnalysisRequestSectionBox'

export const SCANNER_MODEL_OPTIONS: {value: NonUndefined<ScanDomain['scannerModel']>; label: string}[] = [
	{value: 'static', label: 'Static'},
	{value: 'mobile.leica.blk2go', label: 'Leica BLK2GO'},
	{value: 'mobile.navvis', label: 'NavVis'},
]

export const GEOREF_OPTIONS: {value: NonUndefined<ScanDomain['georeference']>; label: string}[] = [
	{value: 'GlobalCoordinateSystemGeoreferenced', label: 'Georeferenced with global coordinates system (Suggested)'},
	{value: 'LocallyGeoreferenced', label: 'Georeferenced locally to the model'},
	{value: 'NotGeoreferenced', label: 'Not georeferenced'},
]

export const HAS_IMAGES_OPTIONS: {value: NonUndefined<boolean>; label: string}[] = [
	{value: true, label: 'Yes - Recommended'},
	{value: false, label: 'No - Synthentic images will be generated'},
]

export type ManagementDashboardAnalysisRequestStep2State = ManagementDashboardAnalysisRequestStepState<
	'scanDomain' | 'hasImages'
>

export const setValidity = (
	dto: ManagementDashboardAnalysisRequestStep2State['dto'],
): ManagementDashboardAnalysisRequestStep2State => {
	const {scanDomain: sd} = dto
	return {
		dto,
		valid: !!(sd.files?.length && sd.date && sd.scannerModel && sd.georeference),
	} as ManagementDashboardAnalysisRequestStep2State
}
export type ScanDomain = ManagementDashboardAnalysisRequestStep2State['dto']['scanDomain']

export const ManagementDashboardAnalysisRequestFormStep2 = ({
	scans,
	state,
	setState,
}: {
	scans: ProjectFileEntity[]
	state: ManagementDashboardAnalysisRequestStep2State
	setState: (state: ManagementDashboardAnalysisRequestStep2State) => void
}) => {
	const scanDomain = useMemo(() => state.dto.scanDomain, [state])
	const hasImages = useMemo(() => state.dto.hasImages, [state])
	const setScanDomain = useCallback(
		(partial: Partial<ScanDomain>) => setState(setValidity({...state.dto, scanDomain: {...scanDomain, ...partial}})),
		[scanDomain, setState, state.dto],
	)

	const setHasImageState = useCallback((value: boolean) => setState(setValidity({...state.dto, hasImages: value})), [
		setState,
		state.dto,
	])

	const setFiles = useCallback((files: ScanDomain['files']) => setScanDomain({files}), [setScanDomain])
	const setScanDate = useCallback((date: ScanDomain['date']) => setScanDomain({date}), [setScanDomain])
	const setScannerModel = useCallback((scannerModel?: ScanDomain['scannerModel']) => setScanDomain({scannerModel}), [
		setScanDomain,
	])
	const setGeoreference = useCallback((georeference?: ScanDomain['georeference']) => setScanDomain({georeference}), [
		setScanDomain,
	])

	const setImagesValue = useCallback((hasImage: any) => setHasImageState(hasImage), [setHasImageState])

	return (
		<ManagementDashboardAnalysisRequestSectionBox step={2} title={'Scan information'}>
			<Box fill={'horizontal'} gap="small" pad={{left: 'small'}}>
				<SRHeading level={5}>2.1 Select scan files</SRHeading>
				<ManagementDashboardFileSelection
					fileType={'Scan'}
					srFiles={scans}
					selectedFileIds={scanDomain.files || []}
					setSelectedFileIds={setFiles}
				/>
			</Box>
			<Box gap="small" pad={{left: 'small'}}>
				<SRHeading level={5}>2.2 Add scan file details</SRHeading>
				<Box fill={'horizontal'} direction="row" gap="small">
					<Box width="220px">
						<SRHeading level={6}>Scan date*</SRHeading>
						<DateInput
							inputProps={{size: 'medium'}}
							format="dd/mm/yyyy"
							value={scanDomain.date?.toISOString()}
							onChange={e => setScanDate(new Date(e.value as string))}
							calendarProps={{size: 'small'}}
						/>
					</Box>
					<Box width="220px">
						<SRHeading level={6}>Scanner model*</SRHeading>
						<SRSimpleSelect
							size="medium"
							onChange={option => setScannerModel(option as ScanDomain['scannerModel'])}
							value={scanDomain.scannerModel}
							placeholder="Select scanner model"
							options={SCANNER_MODEL_OPTIONS}
						/>
					</Box>
				</Box>
			</Box>
			<Box gap="small" pad={{left: 'small'}}>
				<SRHeading level={5}>2.3 Georeference</SRHeading>
				<SRHeading level={6}>Are the scan files georeferenced?*</SRHeading>
				<RadioButtonGroup
					name="radio"
					value={scanDomain.georeference}
					options={GEOREF_OPTIONS}
					onChange={(event: ChangeEvent<HTMLInputElement>) =>
						setGeoreference(event.target.value as ScanDomain['georeference'])
					}
				/>
			</Box>
			<Box gap="small" pad={{left: 'small'}}>
				<SRHeading level={5}>2.4 Images</SRHeading>
				<SRHeading level={6}>Does the scan contain images?*</SRHeading>
				<RadioButtonGroup
					name="radio"
					value={hasImages}
					options={HAS_IMAGES_OPTIONS}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						setImagesValue(JSON.parse(event.target.value))
					}}
				/>
			</Box>
		</ManagementDashboardAnalysisRequestSectionBox>
	)
}
