import {colors, thresholdPercentages, toleranceColor} from './ColorPalette.json'
import {params} from './ParamsHeatmap.json'
import {coordinateFromIFCtoViewer, scaleFromIFCtoViewer} from '../../NaskaUtilities'
import debounce from 'lodash/debounce'
import {fetchS3File, getS3KeyForPath} from '../../../utilities/storageUtilities'
import {store} from '../../../store/store'
import {zip} from 'lodash'
import {VERTICAL_CONTROL_GROUP} from '../Viewing.Extension.VerticalToolbar/Viewing.Extension.VerticalToolbar'
import {disposeThreeNode, getVerticalToolBar} from '../../Viewer/Viewer-helper'
import './Viewing.Extension.Heatmap.scss'
import './ColorPalette.json'
import {format} from 'date-fns'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {Unsubscribe} from 'redux'
import {sleep} from '../../../utilities/retryUtilities'
import {MagnitudeTooltips} from './MagnitudeTooltips'
import {ClassificationEntity} from '../../../features/Classification/classification.entities'

export const HEATMAP_EXTENSION = 'Viewing.Extension.Heatmap'
const Autodesk = window.Autodesk
export const HEATMAP_SCENE_ID = 'heatmapSceneId'

const maxDeviationInitial = params.max_deviation_initial
const toleranceInitial = params.tolerance_initial
const param = new Map()
param.set('maxDeviation', maxDeviationInitial)
param.set('tolerance', toleranceInitial)

const SELECTED_MAT_COLOR = new THREE.Color('#777')

function createPointCloudShaderMaterial(pointSize: number, ifcScaleFactor: number) {
	return new THREE.ShaderMaterial({
		depthWrite: true,
		depthTest: true,
		vertexColors: THREE.VertexColors,
		uniforms: {
			size: {type: 'f', value: pointSize},
			zoomFactor: {type: 'f', value: 2.0 * ifcScaleFactor},
			zCorrection: {type: 'f', value: 0.0005 * Math.sqrt(ifcScaleFactor)},
		},
		attributes: {
			selected: {type: 'f', value: []},
		},
		vertexShader: `
			uniform float size;
			varying vec3 vColor;
			varying float vSelected;
			attribute float selected;
			uniform float zoomFactor;
			uniform float zCorrection;
			void main() {
				vColor = color;
				vSelected = selected;
				vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
				vec4 pPosition = projectionMatrix * mvPosition;
				float fov = projectionMatrix[0][0] / 2.0;
				float scaleFactor = zoomFactor / - (mvPosition.z/fov);
				vec4 correction = vec4(0.0, 0.0, scaleFactor * zCorrection, 0.0);
				gl_Position = projectionMatrix * mvPosition - correction;
				gl_PointSize = clamp(scaleFactor * size, 1.0, 60.0);
			}`,
		fragmentShader: `
			varying vec3 vColor;
			varying float vSelected;
			void main() {
				float distanceToCenter;
				vec2 cxy = 2.0 * gl_PointCoord - 1.0;
				distanceToCenter = dot(cxy, cxy);
				// only draw points with distance less than 1.0 so we achieve a round point
				if (distanceToCenter > 1.0) {
					discard;
				}
				if (vSelected > 0.0) {
 					if(1.0 - distanceToCenter < 0.7) {
    					gl_FragColor = vec4( 0.0, 0.0, 0.0, 1.0 );
					} else {
						gl_FragColor = vec4( vColor, 1.0 );
					}
				} else {
					gl_FragColor = vec4( vColor, 1.0 );
				}
			}`,
	})
}
export default class HeatmapExtension extends Autodesk.Viewing.Extension {
	active = false
	heatmapScaleActive = false
	heatmapSettingsFormActive = false
	callbacksInProgressCounter = 0
	classifications: ClassificationEntity[] = []
	toolbarButton: Autodesk.Viewing.UI.Button | null = null
	heatmapData: Record<string, {pointCloud: THREE.PointCloud; magnitudes: number[]; forgeObjectId: number}> = {}
	deviationColors = colors
	toleranceColor = toleranceColor
	deviationPercent = thresholdPercentages
	scale = parseFloat(params.scale_out_pipeline_to_mm)
	points_size = params.points_size
	fieldsOfPointcloud: number = parseInt(params.fields_of_pointcloud)
	percentSampleColorSlider = params.percent_sample_color_slider
	raycaster: THREE.Raycaster | null = null
	previousSelectionColor: THREE.Color
	deviationSteps: number[]
	subToolbar: any
	unsubscribe: Unsubscribe = () => {}
	mousePosition: {x: number; y: number} = {x: 0, y: 0}
	pointCloudMaterial: THREE.ShaderMaterial | undefined
	magnitudeTooltips: MagnitudeTooltips
	usePointCloudAsHeatmap: boolean = false
	displayingLegacyHeatmap: boolean = false

	constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
		super(viewer, options)
		this.magnitudeTooltips = new MagnitudeTooltips(
			classificationId => this.heatmapData[classificationId],
			classification => this.updatePointCloudColorsAndInvalidate(classification),
			() => param.get('tolerance'),
			viewer,
		)
		this.previousSelectionColor = (viewer.impl as any).selectionMaterialBase.color.clone()
		this.deviationSteps = this.deviationPercent.map((x: string) => parseFloat(x) * param.get('maxDeviation'))
	}

	async load() {
		// Prepare raycaster for heatmap tooltip value update
		this.raycaster = new THREE.Raycaster()
		// @ts-ignore
		for (;;) {
			if (this.viewer.model) break
			await sleep(1000)
		}
		this.raycaster.params.PointCloud.threshold = 0.01 * scaleFromIFCtoViewer(this.viewer.model) // 1cm threshold
		this.createUI()
		this.unsubscribe = store.subscribe(() => this.toggleScanDate())

		this.pointCloudMaterial = createPointCloudShaderMaterial(20.0, scaleFromIFCtoViewer(this.viewer.model))
		this.viewer.impl.matman().addMaterial('SR_POINTCLOUD_MATERIAL', this.pointCloudMaterial, true)
		// Register and activate extension itself as a tool in order to handle mouse hover via overriden method
		this.viewer.toolController.registerTool(this)
		this.viewer.toolController.activateTool(HEATMAP_EXTENSION)
		return true
	}

	unload() {
		const verticalToolBar = this.viewer.getExtension('Viewing.Extension.VerticalToolbar')
		verticalToolBar && (verticalToolBar as any).SRVerticalToolbar.removeControl(this.subToolbar)

		this.setActive(false)
		this.removeHeatMapElement()
		this.unsubscribe()
		this.viewer.toolController.deactivateTool(HEATMAP_EXTENSION)
		this.viewer.toolController.deregisterTool(this)

		return true
	}

	getNames() {
		return [HEATMAP_EXTENSION]
	}

	setUsePointCloudAsHeatmap(value: boolean) {
		this.usePointCloudAsHeatmap = value
		if (value) {
			this.toolbarButton?.icon.parentElement?.classList.add('pointCloud')
			this.toolbarButton?.setToolTip('Display deviation point cloud for the selected elements')
		} else {
			this.toolbarButton?.icon.parentElement?.classList.remove('pointCloud')
			this.toolbarButton?.setToolTip('Display deviation heatmap for the selected elements')
		}
	}

	setClassifications(classifications: ClassificationEntity[] | undefined) {
		this.classifications = classifications || []

		if (this.classifications.length === 0) {
			if (this.active) this.setActive(false)
			this.toolbarButton?.setState(Autodesk.Viewing.UI.Button.State.DISABLED)
		} else {
			if (this.active) this.selectionChanged().catch(error => console.error('Error in selectionChanged', error))
			this.toolbarButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE)
		}
	}

	updateScanDateElement(scannedDate: string) {
		const scanDateElement = document.getElementById('scanDateContainer')
		scanDateElement!.innerHTML = `<span>Scan date: ${format(new Date(scannedDate), 'yyyy-MM-dd HH:mmaaa')}</span>${
			this.displayingLegacyHeatmap
				? "<span style='font-size: 20px;'>&nbsp;⚠&nbsp;</span><span>Point cloud not displayed</span>"
				: ''
		}`
	}

	updateDeviationContainers() {
		const deviation = parseFloat(param.get('maxDeviation')).toFixed(0)
		const maxDeviationContainer = document.getElementById('maxDeviationContainer')
		const minDeviationContainer = document.getElementById('minDeviationContainer')

		if (maxDeviationContainer) maxDeviationContainer.innerText = '+' + deviation + ' mm'
		if (minDeviationContainer) minDeviationContainer.innerText = '-' + deviation + ' mm'
	}

	toggleScanDate() {
		const scanDateElement = document.getElementById('scanDateContainer')
		if (!scanDateElement) return
		const isInSplitMode = selectorViewerProperties(store.getState()).assetsMode === 'split'
		scanDateElement.hidden = isInSplitMode
		scanDateElement.style.visibility = isInSplitMode ? 'hidden' : 'visible'
	}

	setActive(active: boolean) {
		this.active = active
		this.toggleHeatmapScale(active)
		this.toggleSettingsForm(active)

		if (active) {
			this.computeSliderColors()
			this.toolbarButton!.removeClass('heatmapsToolbarButton')
			this.toolbarButton!.addClass('heatmapsToolbarButtonBlue')
			this.selectionChanged()
			// Change appearance (colour) of selected element to avoid confusion
			this.viewer.setSelectionColor(SELECTED_MAT_COLOR, (Autodesk.Viewing as any).SelectionType.OVERLAYED)
			this.viewer.impl.disableHighlight(true)
		} else {
			this.viewer.setSelectionColor(this.previousSelectionColor, (Autodesk.Viewing as any).SelectionType.OVERLAYED)
			this.viewer.impl.disableHighlight(false)
			if (this.usePointCloudAsHeatmap) {
				if (!this.displayingLegacyHeatmap) {
					this.viewer.show(this.classifications.map(cl => cl.forgeObjectId))
				}
			}
			this.toolbarButton!.removeClass('heatmapsToolbarButtonBlue')
			this.toolbarButton!.addClass('heatmapsToolbarButton')
			this.deleteHeatmapData()
		}
		this.viewer.impl.invalidate(true, true, true)
	}

	removeHeatMapElement() {
		const toolbarContainer = document.getElementById('toolbar-TtIf')
		const heatmapScaleContainer = document.getElementById('heatmapScaleContainer')
		const scanDateContainer = document.getElementById('scanDateContainer')
		;(heatmapScaleContainer && toolbarContainer)?.removeChild(heatmapScaleContainer as Node)
		;(scanDateContainer && toolbarContainer)?.removeChild(scanDateContainer as Node)
	}

	createUI() {
		// prepare to execute the button action
		const heatmapToolbarButton = new Autodesk.Viewing.UI.Button('runHeatmapCode')
		heatmapToolbarButton.addClass('heatmapsToolbarButton')

		heatmapToolbarButton.onClick = () => {
			this.setActive(!this.active)
		}
		// heatmapToolbarButton CSS class should be defined on your .css file
		// you may include icons, below is a sample class:
		heatmapToolbarButton.setToolTip('Display deviation heatmap for the selected elements')

		this.toolbarButton = heatmapToolbarButton

		if (this.classifications.length === 0) {
			this.toolbarButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED)
		} else {
			this.toolbarButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE)
		}

		// Prepare floating magnitude tooltip (hidden by default on creation)
		const tooltipContainer = this.magnitudeTooltips.createUI()
		this.viewer.container.appendChild(tooltipContainer)

		// SubToolbar
		this.subToolbar =
			(getVerticalToolBar(this.viewer).getControl(VERTICAL_CONTROL_GROUP) as Autodesk.Viewing.UI.ControlGroup) ||
			new Autodesk.Viewing.UI.ControlGroup(VERTICAL_CONTROL_GROUP)

		this.subToolbar.addControl(heatmapToolbarButton, {index: 1})
	}

	computeSliderColors(): void {
		//compute color each 5 %
		const perc = parseFloat(this.percentSampleColorSlider)
		const min = -param.get('maxDeviation')
		const max = param.get('maxDeviation')
		const perc_scale = perc * (max - min)
		let background_string = 'linear-gradient('

		for (let i = 1.0 / perc; i >= 0; i--) {
			const color = this.computeDeviationColor((min + perc_scale * i) / this.scale)
			background_string = background_string + color + ','
		}

		background_string = background_string.slice(0, background_string.length - 1)
		background_string = background_string + ')'
		document.querySelector<HTMLElement>('#heatmapScale')!.style.backgroundImage = background_string
	}

	interpolateDeviationColor(
		deviation: number,
		limit_up: number,
		limit_down: number,
		color_up: string,
		color_down: string,
	) {
		const percent = Math.abs((deviation - limit_down) / (limit_up - limit_down))
		const rgb_up = color_up.match(/\d+/g)!.map((x: string) => parseInt(x))
		const rgb_down = color_down.match(/\d+/g)!.map((x: string) => parseInt(x))
		const color = zip(rgb_up, rgb_down).map(([up, down]) => Math.floor(down! + (up! - down!) * percent))
		return `rgb(${color.join(',')})`
	}

	computeDeviationColor(deviation: number) {
		//from meters to mm
		deviation = deviation * this.scale
		if (Math.abs(deviation) <= param.get('tolerance')) {
			return this.toleranceColor
		}

		for (let j = 1; j < this.deviationSteps.length - 1; j++) {
			if (deviation >= this.deviationSteps[j] && deviation <= this.deviationSteps[j - 1]) {
				if (deviation > 0.0) {
					return this.interpolateDeviationColor(
						deviation,
						this.deviationSteps[j - 1],
						this.deviationSteps[j],
						this.deviationColors[j - 1],
						this.deviationColors[j],
					)
				} else {
					return this.interpolateDeviationColor(
						deviation,
						this.deviationSteps[j],
						this.deviationSteps[j - 1],
						this.deviationColors[j],
						this.deviationColors[j - 1],
					)
				}
			}
		}
		if (deviation >= this.deviationSteps[0]) {
			return this.deviationColors[0]
		} else {
			return this.deviationColors[this.deviationColors.length - 1]
		}
	}

	toggleHeatmapScale(active: boolean) {
		this.heatmapScaleActive = active
		if (this.heatmapScaleActive) {
			let scaleContainer = document.createElement('DIV')
			scaleContainer.setAttribute('id', 'heatmapScaleContainer')
			scaleContainer.setAttribute('class', 'adsk-control adsk-control-group toolbar-vertical-group')

			let scaleValue = document.createElement('DIV')
			scaleValue.setAttribute('id', 'heatmapScaleValue')

			let scanDateContainer = document.createElement('DIV')
			scanDateContainer.setAttribute('id', 'scanDateContainer')

			let maxDeviationContainer = document.createElement('DIV')
			maxDeviationContainer.setAttribute('id', 'maxDeviationContainer')
			maxDeviationContainer.setAttribute('class', 'deviationContainer')

			let minDeviationContainer = document.createElement('DIV')
			minDeviationContainer.setAttribute('id', 'minDeviationContainer')
			minDeviationContainer.setAttribute('class', 'deviationContainer')

			let scale = document.createElement('DIV')
			scale.setAttribute('id', 'heatmapScale')
			scale.addEventListener('mousemove', function (e) {
				const min = -param.get('maxDeviation')
				const max = param.get('maxDeviation')
				const scaleTopPosition = scale.getBoundingClientRect().top
				const offset = e.clientY - scaleTopPosition
				const y = this.offsetHeight - offset
				const percent = y / this.offsetHeight
				const deviation = Math.max(min, Math.min(max, percent * (max - min) + min))
				scaleValue.style.display = 'block'
				scaleValue.textContent = deviation.toFixed(0) + ' mm'
				scaleValue.style.marginTop =
					Math.max(5, Math.min(this.offsetHeight - 30, (1 - percent) * this.offsetHeight)) + 'px'
			})

			scaleContainer.appendChild(maxDeviationContainer)
			scaleContainer.appendChild(scale)
			scaleContainer.appendChild(minDeviationContainer)
			scale.appendChild(scaleValue)
			document.getElementById('toolbar-TtIf')!.appendChild(scaleContainer)
			document.getElementById('toolbar-TtIf')!.appendChild(scanDateContainer)
			this.updateDeviationContainers()
		} else {
			this.removeHeatMapElement()
		}
	}

	toggleSettingsForm(active: boolean) {
		this.heatmapSettingsFormActive = active

		if (this.heatmapSettingsFormActive) {
			const changeColorBinded = () => {
				this.updateCloudAndSliderColors()
				this.updateDeviationContainers()
			}

			const heatmapSettingsForm = document.createElement('form')
			heatmapSettingsForm.setAttribute('id', 'heatmapSetterContainer')
			heatmapSettingsForm.setAttribute('class', 'adsk-control adsk-control-group css-class-name')

			const toleranceLabel = document.createElement('div')
			toleranceLabel.setAttribute('id', 'heatmapToleranceLabel')
			const toleranceLabelText = document.createElement('span')
			toleranceLabelText.setAttribute('class', 'tooltiptext')
			toleranceLabelText.innerHTML = 'Tolerance (mm)'
			toleranceLabel.appendChild(toleranceLabelText)
			heatmapSettingsForm.appendChild(toleranceLabel)

			const toleranceInput = document.createElement('input')
			toleranceInput.type = 'text'
			toleranceInput.autocomplete = 'off'
			toleranceInput.value = parseFloat(param.get('tolerance')).toFixed(0)
			toleranceInput.setAttribute('id', 'heatmapToleranceInput')
			toleranceInput.setAttribute('class', 'adsk-control adsk-control-group css-class-name')
			heatmapSettingsForm.appendChild(toleranceInput)

			const maxDeviationLabel = document.createElement('div')
			maxDeviationLabel.setAttribute('id', 'heatmapMaxDeviationLabel')
			const maxDeviationLabelText = document.createElement('span')
			maxDeviationLabelText.setAttribute('class', 'tooltiptext')
			maxDeviationLabelText.innerHTML = 'Maximum deviation range (mm)'
			maxDeviationLabel.appendChild(maxDeviationLabelText)
			heatmapSettingsForm.appendChild(maxDeviationLabel)

			const maxDeviationInput = document.createElement('input')
			maxDeviationInput.type = 'text'
			maxDeviationInput.autocomplete = 'off'
			maxDeviationInput.value = parseFloat(param.get('maxDeviation')).toFixed(0)
			maxDeviationInput.setAttribute('id', 'heatmapMaxDeviationInput')
			maxDeviationInput.setAttribute('class', 'adsk-control adsk-control-group css-class-name')
			heatmapSettingsForm.appendChild(maxDeviationInput)

			const resetButton = document.createElement('input')
			resetButton.type = 'button'
			resetButton.size = 1
			resetButton.setAttribute('id', 'heatmapResetButton')
			resetButton.setAttribute('class', 'adsk-control adsk-control-group css-class-name')
			heatmapSettingsForm.appendChild(resetButton)

			const applyNewValues = () => {
				param.set('tolerance', parseFloat(toleranceInput.value).toFixed(0))
				param.set('maxDeviation', parseFloat(maxDeviationInput.value).toFixed(0))
				changeColorBinded()
			}

			heatmapSettingsForm.addEventListener('change', applyNewValues)
			heatmapSettingsForm.addEventListener('keypress', event => {
				if (event.key === 'Enter') {
					applyNewValues()
				}
			})

			resetButton.addEventListener('click', () => {
				param.set('tolerance', parseFloat(toleranceInitial).toFixed(0))
				toleranceInput.value = parseFloat(toleranceInitial).toFixed(0)

				param.set('maxDeviation', parseFloat(maxDeviationInitial).toFixed(0))
				maxDeviationInput.value = parseFloat(maxDeviationInitial).toFixed(0)
				changeColorBinded()
			})

			document.getElementById('toolbar-TtIf')!.appendChild(heatmapSettingsForm)
		} else {
			const toolbarContainer = document.getElementById('toolbar-TtIf')
			let heatmapSettingsForm = document.getElementById('heatmapSetterContainer')
			toolbarContainer && heatmapSettingsForm && toolbarContainer.removeChild(heatmapSettingsForm)
		}
	}

	showLoadingSpinner() {
		if (this.callbacksInProgressCounter === 0) {
			let spinner = document.createElement('div')
			spinner.setAttribute('class', 'lds-ring')
			spinner.innerHTML = '<div></div>'
			const toolbar = document.getElementById('toolbar-TtIf')
			toolbar && toolbar.appendChild(spinner)
		}
		this.callbacksInProgressCounter++
	}

	hideLoadingSpinner(): void {
		this.callbacksInProgressCounter--
		const spinners = document.getElementsByClassName('lds-ring')
		if (spinners.length > 0 && this.callbacksInProgressCounter === 0) {
			document.getElementById('toolbar-TtIf')!.removeChild(spinners[0])
		}
	}

	createPointCloud(heatmap: number[][]) {
		this.deviationSteps = this.deviationPercent.map(x => parseFloat(x) * param.get('maxDeviation'))

		const geometry = this.createBufferGeometryForHeatmap(heatmap)
		return new THREE.PointCloud(geometry, this.pointCloudMaterial!)
	}

	createBufferGeometryForHeatmap(heatmap: number[][]) {
		const geometry = new THREE.BufferGeometry()
		const positions = new Float32Array(heatmap.length * 3)
		const colors = new Float32Array(heatmap.length * 3)
		const selected = new Float32Array(heatmap.length)
		for (let i = 0; i < heatmap.length; ++i) {
			const coordinates = coordinateFromIFCtoViewer(this.viewer.model, heatmap[i][0], heatmap[i][1], heatmap[i][2])
			const color = new THREE.Color(this.computeDeviationColor(heatmap[i][3]))
			positions[3 * i] = coordinates[0]
			positions[3 * i + 1] = coordinates[1]
			positions[3 * i + 2] = coordinates[2]
			colors[3 * i] = color.r
			colors[3 * i + 1] = color.g
			colors[3 * i + 2] = color.b
			selected[i] = 0.0
		}

		;(geometry as any).setAttribute('position', new THREE.BufferAttribute(positions, 3))
		;(geometry as any).setAttribute('color', new THREE.BufferAttribute(colors, 3))
		;(geometry as any).setAttribute('selected', new THREE.BufferAttribute(selected, 1))
		geometry.computeBoundingBox()
		// @ts-ignore
		geometry.isPoints = true //Need to suppress because isPoints is not declared in BufferGeometry
		return geometry
	}

	updatePointCloudColors(classificationId: string): void {
		const heatmapData = this.heatmapData[classificationId]
		const colors = new Float32Array(heatmapData.magnitudes.length * 3)
		const hovered = this.magnitudeTooltips.hoveredPoint?.classificationId === classificationId
		const selectedPoints = new Set(
			this.magnitudeTooltips.getSelectedPointsForClassification(classificationId).map(point => point.pointIndex),
		)
		const cloud = heatmapData.pointCloud
		const selected = new Float32Array(heatmapData.magnitudes.length)
		for (let i = 0; i < heatmapData.magnitudes.length; ++i) {
			const pointHighlighted = hovered && this.magnitudeTooltips.hoveredPoint?.pointIndex === i ? 0.6 : 1
			const pointSelected = selectedPoints.has(i)
			const color = new THREE.Color(this.computeDeviationColor(heatmapData.magnitudes[i] / this.scale))
			colors[3 * i] = pointHighlighted * color.r
			colors[3 * i + 1] = pointHighlighted * color.g
			colors[3 * i + 2] = pointHighlighted * color.b
			selected[i] = pointSelected ? 1 : 0
		}
		const cloudGeometry = cloud.geometry as any
		cloudGeometry.attributes['color'].set(colors)
		cloudGeometry.attributes['color'].needsUpdate = true
		cloudGeometry.attributes['selected'].set(selected)
		cloudGeometry.attributes['selected'].needsUpdate = true
	}

	getHeatmapMagnitudes(heatmap: number[][]) {
		const magnitudes = []
		for (let i = 0; i < heatmap.length; ++i) {
			magnitudes.push(heatmap[i][3] * this.scale)
		}
		return magnitudes
	}

	deleteHeatmapData(): void {
		Object.keys(this.heatmapData).forEach(classificationId => {
			this.removeHeatMapOverlayForElement(classificationId)
		}, this)
		this.viewer.impl.invalidate(false, false, true)
	}

	async readHeatmapFile(file_name: string) {
		let current_array: string[] = []
		let content = await fetchS3File(file_name)
		if (content !== undefined) {
			content = content.replace(/\[/g, '')
			content = content.replace(/]/g, '')
			current_array = content.split(',')
		}

		const heatmap_array = []
		let index = 0
		while (index < current_array.length) {
			const sub_array = []
			for (let i = 0; i < this.fieldsOfPointcloud; i++) {
				sub_array.push(parseFloat(current_array[index]))
				index++
			}
			heatmap_array.push(sub_array)
		}

		return heatmap_array
	}

	update() {
		if (this.active) {
			this.magnitudeTooltips.updateHoveredMagnitudeLabel()
			this.magnitudeTooltips.updateSelectedPointTooltipLabels()
		}
	}

	renderFromEvent = debounce(() => {
		// Handles raycast against available heatmap clouds and updates magnitude tooltip
		if (Object.keys(this.heatmapData).length === 0) {
			return false
		}

		const intersection = this.getHeatmapIntersection()
		// Raycast also against model to check for self occlusions
		const ignoreTransparentElements = false
		const modelHitTest = (this.viewer.model as any).rayIntersect(this.raycaster, ignoreTransparentElements)
		// A heatmap is intersected if the model is also there but no other element causes any occlusion
		if (
			this.active &&
			intersection &&
			(modelHitTest === null ||
				intersection.intersectedPoint.distance <= modelHitTest.distance + this.raycaster!.params.PointCloud.threshold)
		) {
			this.magnitudeTooltips.hoveredPoint = {
				classificationId: intersection.classificationId,
				pointIndex: intersection.intersectedPoint.index,
			}
		} else {
			this.magnitudeTooltips.hoveredPoint = undefined
		}
		this.updateCloudAndSliderColors()
		this.viewer.impl.invalidate(false, false, true)
		return false
	}, 10)

	updatePointCloudColorsAndInvalidate(classificationId: string) {
		this.updatePointCloudColors(classificationId)
		this.viewer.impl.invalidate(false, false, true)
	}

	handleSingleClick(clickEvent: MouseEvent) {
		if (clickEvent.button !== 0) return false
		if (this.magnitudeTooltips.hoveredPoint) {
			this.magnitudeTooltips.addSelectedPointTooltip(
				this.magnitudeTooltips.hoveredPoint.classificationId,
				this.magnitudeTooltips.hoveredPoint.pointIndex,
			)
			return true
		}
		return false
	}

	handleMouseMove(pointer: PointerEvent) {
		this.mousePosition.x = pointer.clientX
		this.mousePosition.y = pointer.clientY
		this.renderFromEvent()
	}

	handleWheelInput() {
		this.renderFromEvent()
	}

	private getHeatmapIntersection() {
		// Backproject with render camera
		const rect = (this.viewer.impl as any).canvas.getBoundingClientRect()
		const x = ((this.mousePosition.x - rect.left) / rect.width) * 2 - 1
		const y = -((this.mousePosition.y - rect.top) / rect.height) * 2 + 1
		const viewVector = new THREE.Vector3(x, y, 0.5).unproject((this.viewer.impl as any).camera)

		// Check intersection against all enabled heatmaps at the moment
		this.raycaster!.set(
			(this.viewer.impl as any).camera.position,
			viewVector.sub((this.viewer.impl as any).camera.position).normalize(),
		)
		let intersectedPoint = null
		let classificationId = null

		for (const [elementId, heatmapData] of Object.entries(this.heatmapData)) {
			const intersectedPointAux = this.raycaster!.intersectObject(heatmapData.pointCloud)
			if (intersectedPointAux.length > 0) {
				intersectedPoint = intersectedPointAux[0]
				classificationId = elementId
			}
		}
		return classificationId && intersectedPoint ? {intersectedPoint, classificationId} : null
	}

	async selectionChanged() {
		if (this.active && this.viewer?.model) {
			// Overlay loading spinner while work is being done
			this.showLoadingSpinner()
			this.toggleScanDate()

			// Flush cached elements that were not in the callback
			const classificationIds = this.classifications.map(c => c._id)
			Object.keys(this.heatmapData).forEach(classificationId => {
				if (!classificationIds.includes(classificationId)) {
					if (this.usePointCloudAsHeatmap) {
						this.viewer.show(this.heatmapData[classificationId].forgeObjectId)
					}
					this.removeHeatMapOverlayForElement(classificationId)
				}
			}, this)

			// Create new heatmaps of the clicked elements
			for (let classification of this.classifications) {
				if (classification.deviationHeatmap) {
					// Process only non-cached heatmap files
					if (!(classification._id in this.heatmapData)) {
						const heatmapRawData = await this.readHeatmapFile(
							getS3KeyForPath(
								store.getState().userProfileState.selectedProject!.tenantId,
								this.usePointCloudAsHeatmap && classification.pointCloudHeatmapPath
									? classification.pointCloudHeatmapPath
									: classification.deviationHeatmap.filePath,
							),
						)
						if (heatmapRawData === undefined || heatmapRawData.length === 0) {
							continue
						}
						const pointCloud = this.createPointCloud(heatmapRawData)
						const magnitudes = this.getHeatmapMagnitudes(heatmapRawData)
						this.heatmapData[classification._id] = {
							pointCloud,
							magnitudes,
							forgeObjectId: classification.forgeObjectId,
						}
						this.viewer.overlays.addMesh(pointCloud as THREE.Mesh, HEATMAP_SCENE_ID)
					}
				}
			}
			if (this.usePointCloudAsHeatmap) {
				if (this.classifications.some(cl => cl.pointCloudHeatmapPath)) {
					const elementsToHide = this.classifications
						.filter(cl => !!cl.pointCloudHeatmapPath)
						.map(cl => cl.forgeObjectId)
					this.viewer.hide(elementsToHide)
					this.viewer.select(this.classifications.map(cl => cl.forgeObjectId))
					this.displayingLegacyHeatmap = false
				} else {
					this.displayingLegacyHeatmap = true
				}
			}
			//Update Scanned Date Container
			this.updateScanDateElement(this.classifications[0].scannedDate)

			// Detach preloading spinner after all work is done
			this.hideLoadingSpinner()
		}
	}

	updateCloudAndSliderColors() {
		// Update deviation steps
		this.deviationSteps = this.deviationPercent.map((x: string) => parseFloat(x) * param.get('maxDeviation'))

		// Updates heatmap pointcloud colors after tolerance and/or range have been changed
		Object.keys(this.heatmapData).forEach(classificationId => {
			this.updatePointCloudColors(classificationId)
		}, this)

		// Make scale value invisible to force update via user mouse interaction, and update range colors
		document.getElementById('heatmapScaleValue')!.style.display = 'none'
		this.computeSliderColors()
	}

	private removeHeatMapOverlayForElement(classificationId: string) {
		this.viewer.overlays.removeMesh(this.heatmapData[classificationId].pointCloud as THREE.Mesh, HEATMAP_SCENE_ID)
		disposeThreeNode(this.heatmapData[classificationId].pointCloud)
		delete this.heatmapData[classificationId]
		this.magnitudeTooltips.removeTooltipsForClassification(classificationId)
		this.viewer.impl.invalidate(false, false, true)
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(HEATMAP_EXTENSION, HeatmapExtension)
