import {UserProject} from '../../../../../reducers/userProfile'
import {useQuery, UseQueryOptions} from 'react-query'
import {AxiosError} from 'axios'
import {useGlobalNotifications} from '../../../../GlobalNotifications'
import {fetchElementRootModelsByModel, fetchElementRootModelsFromLatestModel} from '../../../../../api/elements.api.v3'
import {useFeatureEnabled} from '../../../../FeatureFlags/FeatureFlagsProvider'

export const useElementRootModelOptions = (
	selectedProject: UserProject | undefined,
	fromLatestModel: boolean = false,
	modelId?: string,
	options?: UseQueryOptions<{value: string; label: string}[], AxiosError>,
) => {
	const {notifyError} = useGlobalNotifications()
	const displayRootModelFilter = useFeatureEnabled('displayRootModelFilter')
	return useQuery<{value: string; label: string}[], AxiosError>(
		[selectedProject!._id, 'rootModels', fromLatestModel, modelId],
		() =>
			(fromLatestModel ? fetchElementRootModelsFromLatestModel() : fetchElementRootModelsByModel(modelId!))
				.then(rootModels => rootModels.map(rootModel => ({value: rootModel, label: rootModel})))
				.catch(e => {
					notifyError(e.message)
					return []
				}),
		{staleTime: 60 * 60 * 1000, ...options, enabled: !!selectedProject && displayRootModelFilter},
	)
}
