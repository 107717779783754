import {srProjectApiV3} from '../../../../api/project.api.v3'

export const fetchBcfByIssueById = async (id: string): Promise<Blob> =>
	(await srProjectApiV3.post(`bcfs/${id}`, undefined, {responseType: 'blob'})).data

export const fetchBcfsByIssueIds = async (ids: string[]): Promise<Blob> =>
	(await srProjectApiV3.post(`bcfs/by-filter`, undefined, {params: {ids}, responseType: 'blob'})).data

export const fetchPDFByIssueIds = async (ids: string[], timeZone: string): Promise<Blob> =>
	(await srProjectApiV3.post(`pdfs/by-filter`, undefined, {params: {ids, timeZone: timeZone}, responseType: 'blob'}))
		.data

export const fetchPdfByIssueById = async (id: string, timeZone: string): Promise<Blob> =>
	(await srProjectApiV3.post(`pdfs/${id}`, undefined, {params: {timeZone: timeZone}, responseType: 'blob'})).data

export const fetchCsvByIssueIds = async (ids: string[], timeZone: string): Promise<Blob> =>
	(await srProjectApiV3.post(`csvs/by-filter`, undefined, {params: {ids, timeZone: timeZone}, responseType: 'blob'}))
		.data

export const fetchCsvByIssueId = async (id: string, timeZone: string): Promise<Blob> =>
	(await srProjectApiV3.post(`csvs/${id}`, undefined, {params: {timeZone: timeZone}, responseType: 'blob'})).data
