import React from 'react'
import {Box, Text} from 'grommet'
import {FilterPresetEntity} from '../../entities/filter-preset.entities'
import _ from 'lodash'
import {SRChip} from 'sr-react-commons'

const renderItems = (items: string[]) =>
	items.map(item => (
		// max width 180 so that 2 Chips would fit per row
		<SRChip width={{max: '180px'}} color="white" key={item} text={item} background="brand" margin={'xxsmall'} />
	))

function FilterDescription(props: {filterName: string; filterLabels: string[]}) {
	return (
		<Box gap="xxsmall">
			<Box pad={{horizontal: 'xxsmall'}}>
				<Text weight="bold">{props.filterName}:</Text>
			</Box>
			<Box direction="row" wrap>
				{renderItems(props.filterLabels)}
			</Box>
		</Box>
	)
}
const allStatuses = ['Verified', 'Deviated', 'Missing', 'No data']

export function FilterPresetDescription({preset}: {preset: FilterPresetEntity}) {
	return (
		<Box gap="xsmall" background="#fff" pad="xsmall" width="auto">
			<FilterDescription
				filterName="Status"
				filterLabels={preset.filters.statuses !== null ? preset.filters.statuses : allStatuses}
			/>
			{preset.filters.magnitude && (
				<FilterDescription
					filterName="Magnitude"
					filterLabels={[`${preset.filters.magnitude.from}mm - ${preset.filters.magnitude.to}mm`]}
				/>
			)}
			{preset.filters.rootModels && (
				<FilterDescription filterName="Root model" filterLabels={preset.filters.rootModels} />
			)}
			{preset.filters.elementTypes && (
				<FilterDescription
					filterName="Element type"
					filterLabels={preset.filters.elementTypes.map(elementType => _.last(elementType.split(':'))!)}
				/>
			)}
			{preset.filters.floors && <FilterDescription filterName="Floor" filterLabels={preset.filters.floors} />}
			{preset.filters.externalId && (
				<FilterDescription filterName="Global ID" filterLabels={[`${preset.filters.externalId}`]} />
			)}
			{preset.filters.elementName && (
				<FilterDescription filterName="Name" filterLabels={[`${preset.filters.elementName}`]} />
			)}
			{preset.filters.eoi && <FilterDescription filterName="Eoi" filterLabels={[`${preset.filters.eoi}`]} />}
		</Box>
	)
}

FilterPresetDescription.story = {
	name: 'filterPresetDescription',
}
