import {Box} from 'grommet'
import {useHistory} from 'react-router-dom'
import {Analytics, Directions, Overview, Workshop} from 'grommet-icons'
import React from 'react'
import {SRPlainButton} from 'sr-react-commons'

export type NavBarPresenterProps = {
	pathname: string
	baseUrl: string
	isProgressDisabled: boolean
	isDashboardEnabled: boolean
}
export function NavBarPresenter({pathname, baseUrl, isProgressDisabled, isDashboardEnabled}: NavBarPresenterProps) {
	const history = useHistory()
	return (
		<Box fill={'vertical'} direction="row" gap="xsmall" align={'center'} justify={'center'}>
			{isDashboardEnabled && (
				<SRPlainButton
					size={'large'}
					icon={<Analytics size={'large'} />}
					label="Insights"
					isSelected={pathname.indexOf('/insights') > -1}
					disabled={!isDashboardEnabled}
					onClick={() => history.push(pathname.indexOf('/insights') > -1 ? baseUrl : baseUrl + 'insights')}
				/>
			)}
			<SRPlainButton
				size={'large'}
				onClick={() => history.push(pathname.indexOf('/quality') > -1 ? baseUrl : baseUrl + 'quality')}
				icon={<Overview size={'large'} />}
				label="Quality"
				isSelected={pathname.indexOf('/quality') > -1}
			/>
			<SRPlainButton
				size={'large'}
				onClick={() => history.push(pathname.indexOf('/issues') > -1 ? baseUrl : baseUrl + 'issues')}
				icon={<Directions size={'large'} />}
				label="Issues"
				isSelected={pathname.indexOf('/issues') > -1}
			/>
			<SRPlainButton
				size={'large'}
				icon={<Workshop size={'large'} />}
				disabled={isProgressDisabled}
				label="Progress"
				isSelected={pathname.indexOf('/progress/work-packages') > -1}
				onClick={() =>
					history.push(pathname.indexOf('/progress/work-packages') > -1 ? baseUrl : baseUrl + 'progress/work-packages')
				}
			/>
		</Box>
	)
}
