import {useSelector} from 'react-redux'
import {RootState} from '../../reducers'
import {MaintenanceLanding} from './MaintenanceLanding'
import React from 'react'

export function ShowMaintenanceMode(props: {children: any}) {
	const maintenanceMode = useSelector((state: RootState) => {
		return state.viewerState.maintenanceMode
	})

	if (maintenanceMode) {
		return <MaintenanceLanding />
	} else {
		return props.children
	}
}
