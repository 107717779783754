import React from 'react'
import {AnalysisRequestEntity} from '../../../../AnalysisRequest/analysis-request.entities'
import {ManagementDashboardTable} from '../../../core/components/table/ManagementDashboardTable'
import {ManagementDashboardAnalysisRequestListTableRow} from './ManagementDashboardAnalysisRequestListTableRow'
import {ManagementDashboardAnalysisRequestListTableHeader} from './ManagementDashboardAnalysisRequestListTableHeader'

export const ANALYSIS_REQUEST_ID_WIDTH = '150px'
export const DATE_CREATED_WIDTH = '190px'
export const CREATED_BY_WIDTH = '300px'

type ManagementDashboardAnalysisRequestListProps = {
	analysisRequests: AnalysisRequestEntity[]
	goToAnalysisRequestViewPage: (analysisRequestId: string) => void
}

export const ManagementDashboardAnalysisRequestListTable = ({
	analysisRequests,
	goToAnalysisRequestViewPage,
}: ManagementDashboardAnalysisRequestListProps) => (
	<ManagementDashboardTable>
		<ManagementDashboardAnalysisRequestListTableHeader />
		{analysisRequests.map(analysisRequest => (
			<ManagementDashboardAnalysisRequestListTableRow
				key={analysisRequest._id}
				analysisRequest={analysisRequest}
				goToAnalysisRequestViewPage={goToAnalysisRequestViewPage}
			/>
		))}
	</ManagementDashboardTable>
)
