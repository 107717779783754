import {Box, RadioButton, Text} from 'grommet'
import React, {ChangeEvent, useState} from 'react'
import {SRFileInput, SRHeading, SRPrimaryButton, SRSecondaryButton} from 'sr-react-commons'
import {SRHasInvalidIdsIconTooltip} from '../../../../components/HasInvalidIdsIconTooltip/HasInvalidIdsIconTooltip'
import {WatchlistEntity} from '../../entities/watchlist.entities'
import {WatchListModal} from '../edit/WatchListModal'
import {WatchlistEditCreateFilter} from '../../../FilterPresets/components/WatchlistEditCreateFilter'

export function WatchlistEditCreateSelector({
	isEdit = false,
	hasInvalidIds = false,
	file,
	watchlist,
	onFileChange,
	source,
	setSource,
	readonly,
	setReadonly,
}: {
	isEdit?: boolean
	hasInvalidIds?: boolean
	file: File | undefined
	watchlist: WatchlistEntity | null
	onFileChange: (files: File[]) => void
	source: 'csv' | 'filter' | null
	setSource: React.Dispatch<React.SetStateAction<'csv' | 'filter' | null>>
	readonly: boolean
	setReadonly: React.Dispatch<React.SetStateAction<boolean>>
}) {
	// TODO: Check if whe need the optional chaining for source?, we shouldn't so it might be wrong data in our local env.
	const isCsv = source === 'csv'
	const isFiltered = source === 'filter'
	const [showEditModal, setShowEditModal] = useState(false)
	const onShowEditOpen = () => setShowEditModal(true)
	const onShowEditClose = () => setShowEditModal(false)

	return (
		<Box gap={'xsmall'}>
			<WatchListModal
				open={showEditModal}
				onClose={onShowEditClose}
				fileInputElement={
					isCsv ? (
						<SRFileInput
							label={'Edit CSV'}
							accept={'.csv'}
							size="large"
							buttonVariant="primary"
							onChange={(files: File[]) => {
								setReadonly(false)
								onShowEditClose()
								onFileChange(files)
							}}
						/>
					) : (
						<SRPrimaryButton
							label={'Edit filter selection'}
							onClick={() => {
								setReadonly(false)
								onShowEditClose()
							}}
						/>
					)
				}
			/>
			<SRHeading level={5}>Select work package elements</SRHeading>
			<Box direction="row" gap="xsmall">
				<RadioButton
					name="radio"
					checked={isCsv}
					label="Upload CSV global ID file"
					onChange={(event: ChangeEvent<HTMLInputElement>) => setSource(event.target.checked ? 'csv' : 'filter')}
					disabled={isEdit && !!watchlist?.source?.mode}
				/>
				{isCsv && hasInvalidIds && isEdit ? <SRHasInvalidIdsIconTooltip /> : null}
			</Box>
			<Box direction="row" justify="between" align={'center'} gap="xsmall">
				{isCsv ? (
					<Box width={{max: '300px'}}>
						{file ? (
							<Text title={file.name} truncate>
								{file.name}
							</Text>
						) : !file && watchlist?.source?.file?.filename ? (
							<Text title={watchlist.source?.file?.filename} truncate>
								{watchlist.source?.file?.filename}
							</Text>
						) : (
							<Text title={'No file selected'} truncate>
								{'No file selected'}
							</Text>
						)}
					</Box>
				) : null}
				{!isCsv ? null : readonly ? (
					<SRSecondaryButton onClick={onShowEditOpen} size={'small'} label={isEdit ? 'Edit .CSV' : 'Select .CSV'} />
				) : (
					<SRFileInput
						label={isEdit ? 'Edit .CSV' : 'Select .CSV'}
						accept={'.csv'}
						size="small"
						onChange={onFileChange}
					/>
				)}
			</Box>
			<Box gap={'small'}>
				<Box direction="row" justify="between">
					<Box direction="row" align={'center'}>
						<RadioButton
							name="radio"
							checked={isFiltered}
							label="Filter elements"
							onChange={event => setSource(event.target.checked ? 'filter' : 'csv')}
							disabled={isEdit && !!watchlist?.source?.mode}
						/>
						{isFiltered && hasInvalidIds && isEdit ? <SRHasInvalidIdsIconTooltip /> : null}
					</Box>
					{isFiltered && readonly ? (
						<SRSecondaryButton onClick={onShowEditOpen} size={'small'} label={'Edit filtered selection'} />
					) : null}
				</Box>
				{isFiltered ? <WatchlistEditCreateFilter mode={'work-package'} readonly={readonly} /> : null}
			</Box>
		</Box>
	)
}
