import React from 'react'
import {DistanceMeasureCanvas} from './DistanceMeasureCanvas'
import {LineProps} from './DistanceMeasureLine'
import {connect, ConnectedProps} from 'react-redux'
import {getS3KeyForPath} from '../../../../utilities/storageUtilities'
import {selectorCurrentProject} from '../../../../selectors/current-project.selectors'
import {sectionSlice} from '../StateManagement/section.slice'
import {RootState} from '../../../../reducers'

const mapStateToProps = (state: RootState) => {
	const currentSection = state.section.currentSection
	return {
		section: currentSection,
		measurements: currentSection ? state.section.measurementsBySectionId[currentSection.sectionPath] : [],
		project: selectorCurrentProject(state),
	}
}

const connector = connect(mapStateToProps, {
	doAddMeasurement: sectionSlice.actions.addMeasurement,
	doClearMeasurements: sectionSlice.actions.clearMeasurements,
	doRemoveMeasurement: sectionSlice.actions.removeMeasurement,
})

type ConnectorProps = ConnectedProps<typeof connector>

type ElementSectionViewProps = ConnectorProps

export function ElementSectionView({
	section,
	doAddMeasurement,
	doRemoveMeasurement,
	doClearMeasurements,
	measurements,
	project,
}: ElementSectionViewProps) {
	if (!project || !section) {
		return null
	}

	let imagePath = getS3KeyForPath(project.tenantId, section.sectionPath)
	let axesPath = getS3KeyForPath(project.tenantId, section.sectionAxesPath)

	const sectionId = section.sectionPath

	const addMeasurementLine = (measurement: LineProps) => {
		doAddMeasurement({sectionId, measurement})
	}

	const removeMeasurement = (measurementId: number) => {
		doRemoveMeasurement({sectionId, measurementId})
	}

	const clearMeasurements = () => {
		doClearMeasurements({sectionId})
	}

	return (
		<DistanceMeasureCanvas
			clearMeasurements={clearMeasurements}
			addMeasurement={addMeasurementLine}
			removeMeasurement={removeMeasurement}
			measurements={measurements || []}
			imageSource={imagePath}
			axesPath={axesPath}
			scale={section.scale}
		/>
	)
}

export const ElementSectionViewWithStore = connector(ElementSectionView)
