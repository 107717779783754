import React, {ReactNode} from 'react'
import {SRVerticalGrayscaleGradient} from 'sr-react-commons'
import {Box, Text} from 'grommet'

export function AssetContainer({
	display,
	placeholderTitle = 'No element selected',
	placeholderText,
	children,
}: {
	display: boolean
	placeholderTitle?: string
	placeholderText: string
	children: ReactNode
}) {
	return display ? (
		<>{children}</>
	) : (
		<SRVerticalGrayscaleGradient>
			<Box gap="xsmall" margin="auto">
				<Text textAlign="center" weight="bold" size="large">
					{placeholderTitle}
				</Text>
				<Text textAlign="center" size="large">
					{placeholderText}
				</Text>
			</Box>
		</SRVerticalGrayscaleGradient>
	)
}
