import React from 'react'
import {DocumentCsv} from 'grommet-icons'
import {format} from 'date-fns'
import {fetchClassificationCsv} from '../../features/Classification/api/classification.api'
import {FiltersEntity, toFiltersDTO} from '../../features/FilterPresets/entities/filter-preset.entities'
import {UserProject} from '../../reducers/userProfile'
import {SRFileDownloadButton, SRIconButton} from 'sr-react-commons'
import {AnalysisViewEntityPopulated} from '../../features/AnalysisView/analysis-view.entities'
import {AnalysisVersionMap} from '../../reducers/classification.slice'
import {useSelector} from 'react-redux'
import {selectorApprovedFilter} from '../../reducers/viewerReducer'
import {useFeatureEnabled} from '../../features/FeatureFlags/FeatureFlagsProvider'

export default function ClassificationDownloadCSV({
	analysisView,
	analysisVersionMap,
	filters,
	project,
	label,
}: {
	analysisView: AnalysisViewEntityPopulated
	analysisVersionMap: AnalysisVersionMap
	filters: FiltersEntity | undefined
	project: UserProject
	label?: string
}) {
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const approvedFilter = useSelector(selectorApprovedFilter)

	const fetchCSVFile = async () => {
		const fetchCSV = await fetchClassificationCsv(
			analysisView,
			analysisVersionMap,
			filters
				? toFiltersDTO(
						filters,
						project.floorMapping,
						project.elementTypeMapping,
						enableUnderConstruction,
						approvedFilter,
				  )
				: undefined,
			project.viewerFeatureFlags.csvExportFormat,
			enableUnderConstruction,
		)

		return new Blob([fetchCSV], {type: 'text/csv; charset=utf-8;'})
	}

	return (
		<SRFileDownloadButton
			label={label}
			buttonClass={SRIconButton}
			icon={<DocumentCsv size={'small'} />}
			fileName={`Naska_AI_DataTable-${format(new Date(), 'yyyyMMdd-HHmm')}.csv`}
			getFile={fetchCSVFile}
		/>
	)
}
