import React, {ReactNode} from 'react'
import {Box, CheckBox, Text} from 'grommet'
import {SRPlainButton} from 'sr-react-commons'
import styled from 'styled-components'

const SRMenuButton = styled(SRPlainButton)`
	border-radius: 0;
`

export function TableColumnSettings(props: {
	presets: {label: string; columns: string[]}[]
	allColumns: {value: string; label: ReactNode}[]
	columns: string[]
	setColumns: (columns: string[]) => void
}) {
	return (
		<Box>
			<Box>
				{props.presets.map(preset => (
					<SRMenuButton
						key={preset.label}
						label={
							<Box fill={true}>
								<Text weight={'normal'} textAlign={'start'}>
									{preset.label}
								</Text>
							</Box>
						}
						onClick={() => props.setColumns([...preset.columns])}
					/>
				))}
			</Box>
			<Box
				border={{
					color: 'neutral-2',
					size: 'xsmall',
					side: 'top',
				}}
			>
				{props.allColumns.map(column => (
					<SRMenuButton
						key={column.value}
						onClick={() =>
							props.setColumns(
								props.columns.includes(column.value)
									? props.columns.filter(col => col !== column.value)
									: props.columns.concat([column.value]),
							)
						}
						label={
							<Box
								direction="row"
								gap="small"
								align="center"
								pad="xxsmall"
								overflow="hidden"
								style={{
									// Exaggerated width so grommet corrects to the same width as the selector but not less
									width: '10000px',
								}}
							>
								<CheckBox tabIndex={-1} checked={props.columns.includes(column.value)} />
								<Box fill="horizontal">
									<Text weight={'normal'} textAlign={'start'}>
										{column.label}
									</Text>
								</Box>
							</Box>
						}
					></SRMenuButton>
				))}
			</Box>
		</Box>
	)
}
