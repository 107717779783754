import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {usePageState} from './base.pagination.state'
import {buildFilterStateWithPaginationReducer, buildInitialFilterState, FilterState} from './base.filter.state'
import {buildEmptyFilters, FiltersEntity} from '../features/FilterPresets/entities/filter-preset.entities'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from './index'
import {
	buildInitialSortPaginationState,
	buildSortPaginationStateReducer,
	SortPaginationState,
	SortState,
	useSortState,
} from './base.pagination.sorting.state'
import {ClassificationSortFields, TableColumns} from '../components/DataTableContainer/UseClassificationsTable'
import {AnalysisViewEntityPopulated} from '../features/AnalysisView/analysis-view.entities'
import {useMemo} from 'react'
import {selectorClassificationState} from '../selectors/classification.selectors'
import _ from 'lodash'

export type AnalysisVersionMap = {[k in string]: number}

export type ClassificationSliceState = SortPaginationState<ClassificationSortFields> &
	FilterState<FiltersEntity> & {
		tableColumns: TableColumns
		analysisViews: AnalysisViewEntityPopulated[] | null
		selectedAnalysisView: AnalysisViewEntityPopulated | null
		analysisVersionMap: AnalysisVersionMap
	}

export function buildClassificationInitialState(): ClassificationSliceState {
	return {
		...buildInitialSortPaginationState<ClassificationSliceState>({field: 'magnitudeDisplay', ascending: false}),
		...buildInitialFilterState<ClassificationSliceState>(buildEmptyFilters()),
		tableColumns: ['status', 'mappedFloor', 'mappedElementType', 'magnitudeDisplay'],
		analysisViews: null,
		selectedAnalysisView: null,
		analysisVersionMap: {},
	}
}

const initialState = buildClassificationInitialState()

export const classificationSlice = createSlice({
	name: 'classification',
	initialState,
	reducers: {
		...buildSortPaginationStateReducer<ClassificationSliceState>(),
		...buildFilterStateWithPaginationReducer<ClassificationSliceState>(initialState),
		setAnalysisViews: (
			state,
			action: PayloadAction<{
				analysisViews: ClassificationSliceState['analysisViews']
				analysisVersionMap: ClassificationSliceState['analysisVersionMap']
			}>,
		) => {
			state.analysisViews = action.payload.analysisViews
			state.analysisVersionMap = action.payload.analysisVersionMap
		},
		setSelectedAnalysisView: (state, action: PayloadAction<ClassificationSliceState['selectedAnalysisView']>) => {
			state.selectedAnalysisView = action.payload
			state.pagination.currentPage = 0
		},
		setTableColumns: (state, action: PayloadAction<{columns: TableColumns}>) => {
			state.tableColumns = action.payload.columns
		},
		setIsInterestingColumnEnabled: (state, action: PayloadAction<boolean>) => {
			state.tableColumns = [
				..._.without(state.tableColumns, 'isInteresting'),
				...(action.payload ? ['isInteresting'] : []),
			] as TableColumns
		},
		reset: () => {
			return buildClassificationInitialState()
		},
	},
})

export function useClassificationPageState() {
	const {pagination} = useSelector<RootState, ClassificationSliceState>(selectorClassificationState)
	return usePageState(pagination, classificationSlice.actions)
}

export type ClassificationSortState = SortState<ClassificationSortFields>

export function useClassificationSortState() {
	const {sorting} = useSelector<RootState, ClassificationSliceState>(selectorClassificationState)
	return useSortState<ClassificationSortFields>(sorting, classificationSlice.actions)
}

export function useClassificationState() {
	const dispatch = useDispatch()
	const state = useSelector(selectorClassificationState)

	const actions = useMemo(
		() => ({
			setAnalysisViews: (
				analysisViews: ClassificationSliceState['analysisViews'],
				analysisVersionMap: ClassificationSliceState['analysisVersionMap'],
			) => {
				dispatch(classificationSlice.actions.setAnalysisViews({analysisViews, analysisVersionMap}))
			},
			setSelectedAnalysisView: (analysisView: ClassificationSliceState['selectedAnalysisView']) => {
				dispatch(classificationSlice.actions.setSelectedAnalysisView(analysisView))
			},
		}),
		[dispatch],
	)
	return {...state, ...actions}
}
