import React, {useEffect, useState} from 'react'
import {IssueCardList} from './IssueCardList'
import {Box, Grid} from 'grommet'
import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {
	equalsInitialFilterState,
	issueSlice,
	useIssueFilterState,
	useIssueSliceState,
	validateIssueFilterPriorityValues,
} from '../issue.slice'
import {useDispatch, useSelector} from 'react-redux'
import {PaginationFromPageState} from '../../../components/Pagination/Pagination'
import {fetchAssignableUsersForProject, fetchIssuesByPage} from '../api/issue-management.api'
import {history} from '../../../router/history'
import {useRouteMatch} from 'react-router-dom'
import {LoadingWrapper} from '../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {RightPanelHeader} from '../../../components/CommonsCandidate/Layout/RightPanel/RightPanelHeader'
import {Close, FormAdd, FormSearch, SettingsOption} from 'grommet-icons'
import {IssueMenu} from './IssueMenu'
import NumberOfElements from '../../../components/DataTableContainer/NumberOfElements'
import {PaginationResponse} from '../../../api/api.types'
import {
	SRIconButton,
	SRMultiSelect,
	SRPrimaryButton,
	SRRightPanelBody,
	SRRightPanelContainer,
	SRTextInput,
} from 'sr-react-commons'
import {IssueEntity} from '../issue.entities'
import {useIssuesExport} from '../Export/useIssuesExport'
import {FiltersContainer} from '../../../components/Filters/FiltersContainer'
import {FilterControl} from '../../../components/Filters/FilterControl'
import _ from 'lodash'
import {useGlobalNotifications} from '../../GlobalNotifications'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'
import {useClassificationState} from '../../../reducers/classification.slice'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {restoreViewerFromIssue} from '../issueUtils'
import {useViewerReady} from '../../../hooks/useViewerReady'
import {SRDateInput} from '../../../components/CommonsCandidate/SRDateInput/SRDateInput'
import {IssueCardListEmptyView} from './IssueCardListEmptyView'
import {IssueConfigModal} from './IssueConfigModal'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'

function useIssueSelection(issues: IssueEntity[] | undefined) {
	const [checkedIssues, setCheckedIssues] = useState<string[]>([])

	useEffect(() => {
		setCheckedIssues(checkedIssues =>
			checkedIssues.filter(checkedIssueId => issues?.find(issue => issue._id === checkedIssueId)),
		)
	}, [issues])

	const toggleIssue = React.useCallback((issueId: string) => {
		setCheckedIssues(checkedIssuesIds =>
			checkedIssuesIds.includes(issueId)
				? checkedIssuesIds.filter(id => id !== issueId)
				: [...checkedIssuesIds, issueId],
		)
	}, [])

	const checkAll = () => {
		if (issues) {
			setCheckedIssues(issues.map(issue => issue._id))
		}
	}

	const checkNone = () => {
		setCheckedIssues([])
	}
	const notAllSelected = !issues?.every(issue => checkedIssues.includes(issue._id)) || false
	const anySelected = checkedIssues.length > 0
	return {checkedIssues, toggleIssue, checkAll, checkNone, notAllSelected, anySelected}
}

function IssueListFilters(props: {onChange: () => void}) {
	const {filter, actions} = useIssueFilterState()
	const filtersUpdated = !equalsInitialFilterState(filter)
	const dispatch = useDispatch()
	const onReset = () => {
		dispatch(actions.resetFilter())
		props.onChange()
	}
	const globalNotifications = useGlobalNotifications()
	const assignableUsers = useQuery(
		['users', 'assignable-to-issues'],
		() => {
			return fetchAssignableUsersForProject()
		},
		{onError: () => globalNotifications.notifyError('Could not load assignable users for issue filtering')},
	)
	const {analysisViews} = useClassificationState()

	return (
		<FiltersContainer title={'Filters and search'} onReset={onReset} canReset={filtersUpdated}>
			<Box gap="xsmall">
				<FilterControl label="Name">
					<SRTextInput
						icon={<FormSearch />}
						placeholder={'Search all issues'}
						onChange={event => {
							dispatch(actions.setPartialFilter({searchText: event.target.value || null}))
							props.onChange()
						}}
						value={filter.searchText || ''}
					/>
				</FilterControl>
				<Grid columns={{count: 2, size: 'auto'}} gap={'xsmall'}>
					<FilterControl label="Status">
						<SRMultiSelect
							placeholder="All issue states displayed"
							options={['Open Issue', 'Under Inspection', 'Closed'].map(status => ({value: status, label: status}))}
							size={'medium'}
							value={filter.statuses || []}
							onChange={(states: string[]) => {
								dispatch(actions.setPartialFilter({statuses: states && states.length > 0 ? _.sortBy(states) : null}))
								props.onChange()
							}}
						/>
					</FilterControl>
					<FilterControl label="Priority">
						<SRMultiSelect
							placeholder="All priorities displayed"
							options={['Blocker', 'Critical', 'Major', 'Minor']
								.map(status => ({value: status, label: status}))
								.concat([{value: 'Unassigned', label: 'Unassigned'}])}
							size={'medium'}
							value={filter.priorities || []}
							onChange={(priorities: string[]) => {
								dispatch(
									actions.setPartialFilter({
										priorities:
											priorities && priorities.length && validateIssueFilterPriorityValues(priorities)
												? _.sortBy(priorities)
												: null,
									}),
								)
								props.onChange()
							}}
						/>
					</FilterControl>
				</Grid>
				<Grid columns={{count: 2, size: 'auto'}} gap={'xsmall'}>
					<FilterControl label="Analysis">
						<SRMultiSelect
							options={
								analysisViews?.map(analysis => ({
									value: analysis._id,
									label: analysis.name,
								})) || []
							}
							placeholder="All analyses displayed"
							size="medium"
							onChange={(analysisIds: string[]) => {
								dispatch(
									actions.setPartialFilter({
										analysisViews: analysisIds && analysisIds.length > 0 ? _.sortBy(analysisIds) : null,
									}),
								)
								props.onChange()
							}}
							value={filter.analysisViews || []}
							searchPlaceholder="Search analyses"
							emptySearchMessage="No analyses found"
							showToggleAllButtons
							showSearchInput
						/>
					</FilterControl>
					<FilterControl label="Assigned to">
						{assignableUsers.isLoading ? (
							<Spinner hideIfGlobalLoader={false} size="small" />
						) : (
							<SRMultiSelect
								options={[{value: 'unassigned', label: 'Unassigned'}].concat(
									assignableUsers.data?.map(dto => ({
										value: dto._id,
										label: `${dto.firstName} ${dto.lastName}`,
									})) || [],
								)}
								placeholder="All assignees displayed"
								size="medium"
								onChange={(userIds: string[]) => {
									dispatch(
										actions.setPartialFilter({
											assignedUsers: userIds && userIds.length > 0 ? _.sortBy(userIds) : null,
										}),
									)
									props.onChange()
								}}
								value={filter.assignedUsers || []}
								searchPlaceholder="Search assignees"
								emptySearchMessage="No assignees Found"
								showToggleAllButtons
								showSearchInput
							/>
						)}
					</FilterControl>
				</Grid>
				<Grid columns={{count: 2, size: 'auto'}} gap={'xsmall'}>
					<FilterControl label={'Deadline after'}>
						<Box direction={'row'} gap={'xxsmall'}>
							<SRDateInput
								value={filter.deadlineAfter}
								onChange={date => {
									dispatch(actions.setPartialFilter({deadlineAfter: date}))
								}}
							/>
							<SRIconButton
								alignSelf={'center'}
								size="small"
								disabled={filter.deadlineAfter === null}
								icon={<Close size="xsmall" />}
								onClick={() => dispatch(actions.setPartialFilter({deadlineAfter: null}))}
							/>
						</Box>
					</FilterControl>
					<FilterControl label={'Deadline before'}>
						<Box direction={'row'} gap={'xxsmall'}>
							<SRDateInput
								value={filter.deadlineBefore}
								onChange={date => {
									dispatch(actions.setPartialFilter({deadlineBefore: date}))
								}}
							/>
							<SRIconButton
								alignSelf={'center'}
								size="small"
								disabled={filter.deadlineBefore === null}
								icon={<Close size="xsmall" />}
								onClick={() => dispatch(actions.setPartialFilter({deadlineBefore: null}))}
							/>
						</Box>
					</FilterControl>
				</Grid>
				<Grid columns={{count: 2, size: 'auto'}} gap={'xsmall'}>
					<FilterControl label={'Created after'}>
						<Box direction={'row'} gap={'xxsmall'}>
							<SRDateInput
								value={filter.createdAfter}
								onChange={date => {
									dispatch(actions.setPartialFilter({createdAfter: date}))
								}}
							/>
							<SRIconButton
								alignSelf={'center'}
								size="small"
								disabled={filter.createdAfter === null}
								icon={<Close size="xsmall" />}
								onClick={() => dispatch(actions.setPartialFilter({createdAfter: null}))}
							/>
						</Box>
					</FilterControl>
					<FilterControl label={'Created before'}>
						<Box direction={'row'} gap={'xxsmall'}>
							<SRDateInput
								value={filter.createdBefore}
								onChange={date => {
									dispatch(actions.setPartialFilter({createdBefore: date}))
								}}
							/>
							<SRIconButton
								alignSelf={'center'}
								size="small"
								disabled={filter.createdBefore === null}
								icon={<Close size="xsmall" />}
								onClick={() => dispatch(actions.setPartialFilter({createdBefore: null}))}
							/>
						</Box>
					</FilterControl>
				</Grid>
				<Grid columns={{count: 2, size: 'auto'}} gap={'xsmall'}>
					<FilterControl label="Location">
						<SRTextInput
							icon={<FormSearch />}
							placeholder={'Search all locations'}
							onChange={event => {
								dispatch(actions.setPartialFilter({location: event.target.value || null}))
								props.onChange()
							}}
							value={filter.location || ''}
						/>
					</FilterControl>
					<FilterControl label="Created by">
						{assignableUsers.isLoading ? (
							<Spinner hideIfGlobalLoader={false} size="small" />
						) : (
							<SRMultiSelect
								options={
									assignableUsers.data?.map(dto => ({
										value: dto._id,
										label: `${dto.firstName} ${dto.lastName}`,
									})) || []
								}
								placeholder="All creators displayed"
								size="medium"
								onChange={(userIds: string[]) => {
									dispatch(
										actions.setPartialFilter({createdBy: userIds && userIds.length > 0 ? _.sortBy(userIds) : null}),
									)
									props.onChange()
								}}
								value={filter.createdBy || []}
								searchPlaceholder="Search creators"
								emptySearchMessage="No creators Found"
								showToggleAllButtons
								showSearchInput
							/>
						)}
					</FilterControl>
				</Grid>
			</Box>
		</FiltersContainer>
	)
}

export function IssueCardListRoute() {
	const {url} = useRouteMatch()
	const dispatch = useDispatch()
	const {pageState, selectedIssue} = useIssueSliceState()
	const {filter} = useIssueFilterState()
	const viewerReady = useViewerReady()
	const [isShowCustomPropsModal, setIsShowCustomPropsModal] = useState(false)
	const issuesCustomFieldsEnabled = useFeatureEnabled('issuesCustomFields')
	const {data: issuesResponse, isLoading, isError} = useQuery<PaginationResponse<IssueEntity>, AxiosError>(
		['issues', 'issues-page', pageState.pageIndex, pageState.pageSize, filter],
		() => fetchIssuesByPage(pageState.pageIndex * pageState.pageSize, pageState.pageSize, filter),
		{
			keepPreviousData: true,
		},
	)

	// When leaving the issue card list route, the issue should be unselected
	useEffect(() => {
		return () => {
			dispatch(issueSlice.actions.setSelectedIssue({issue: null}))
		}
	}, [dispatch])

	useEffect(() => {
		// This if is necessary to avoid an infinite loop
		if (issuesResponse && pageState.totalRecords !== issuesResponse.count) {
			pageState.setTotalRecords(issuesResponse.count)
		}
	}, [issuesResponse, pageState])

	const {threeSixtyLockedView} = useSelector(selectorViewerProperties)

	useEffect(() => {
		if (selectedIssue && viewerReady) {
			if (!threeSixtyLockedView) {
				restoreViewerFromIssue(selectedIssue)
			}
		}
	}, [selectedIssue, viewerReady, threeSixtyLockedView])

	const issues = issuesResponse?.result

	const {checkedIssues, toggleIssue, checkAll, checkNone, anySelected, notAllSelected} = useIssueSelection(issues)

	const {onExport} = useIssuesExport()
	const exportSelectedIssues = onExport.bind(null, checkedIssues)

	const toggleSettings = () => {
		setIsShowCustomPropsModal(!isShowCustomPropsModal)
	}

	const issuesHeaderMenu = (
		<Box flex={false} gap={'small'}>
			<Box direction="row" justify="end" gap="small">
				<SRPrimaryButton
					label={'Create issue'}
					gap={'xsmall'}
					size={'large'}
					onClick={() => history.push(url + '/new')}
					icon={<FormAdd size={'medium'} />}
					reverse
				/>
				<Box>
					<IssueMenu
						issueSelection={{
							onCheckAll: checkAll,
							onCheckNone: checkNone,
							anyIssueChecked: anySelected,
							canCheckAll: notAllSelected,
						}}
						onExport={exportSelectedIssues}
					/>
				</Box>
			</Box>
			<IssueListFilters onChange={() => checkNone()} />
		</Box>
	)
	const pagination = !(isLoading || isError || issues?.length) ? null : (
		<Box gap="small">
			<PaginationFromPageState pageState={pageState} />
		</Box>
	)

	return (
		<>
			<IssueConfigModal toggleSettings={toggleSettings} isShow={isShowCustomPropsModal} />
			<SRRightPanelContainer id="IssueLayer">
				<RightPanelHeader title={'Issues'}>
					<Box direction={'row'} alignContent={'center'} gap={'small'}>
						<Box alignSelf={'center'}>
							<NumberOfElements
								resourceName={'issue'}
								selected={checkedIssues.length}
								numberOfElements={pageState.totalRecords}
								margin={{top: '9px', horizontal: 'xxsmall'}}
							/>
						</Box>
						{issuesCustomFieldsEnabled && (
							<SRIconButton
								alignSelf="center"
								icon={<SettingsOption size={'large'} />}
								onClick={toggleSettings}
								data-testid="close-panel"
							/>
						)}
					</Box>
				</RightPanelHeader>
				<SRRightPanelBody top={issuesHeaderMenu} bottom={pagination}>
					<LoadingWrapper
						{...{isLoading, isError, isEmpty: !issues?.length}}
						fill
						errorMsg="We couldn't display the issues, please retry."
						emptyMsg={<IssueCardListEmptyView onAction={() => history.push(url + '/new')} />}
					>
						{issues && (
							<IssueCardList
								{...{
									checkedIssues,
									toggleIssue,
									issues,
									selectedIssue,
									setSelectedIssue: issue => {
										dispatch(issueSlice.actions.setSelectedIssue({issue: issue}))
									},
								}}
							/>
						)}
					</LoadingWrapper>
				</SRRightPanelBody>
			</SRRightPanelContainer>
		</>
	)
}
