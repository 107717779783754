import React from 'react'
import {Box} from 'grommet'
import styled from 'styled-components'
import {Bookmark} from 'grommet-icons'
import {SR_COLOR_TEXT_LIGHT} from 'sr-react-commons'

const BookmarkFilled = styled(Bookmark)`
	stroke: ${SR_COLOR_TEXT_LIGHT};
	path[fill='none'] {
		fill: ${SR_COLOR_TEXT_LIGHT};
	}
	polygon[fill='none'] {
		fill: ${SR_COLOR_TEXT_LIGHT};
	}
`

export const BookmarkTableHeaderIcon = () => {
	return (
		<Box flex height="100%" style={{position: 'relative', right: 1}}>
			<BookmarkFilled size="small" />
		</Box>
	)
}
