import {ClassificationStatus} from '../../reducers/classification'
import {
	SR_RGBA_VIEWER_APPROVED,
	SR_RGBA_VIEWER_DEFAULT,
	SR_RGBA_VIEWER_DEVIATED,
	SR_RGBA_VIEWER_MISSING,
	SR_RGBA_VIEWER_NO_DATA,
	SR_RGBA_VIEWER_UNDER_CONSTRUCTION,
	SR_RGBA_VIEWER_VERIFIED,
} from 'sr-react-commons'
import {getClassificationStatusWithoutUnderConstruction} from '../../features/Classification/classification.helpers'

const THREE = window.THREE

type RGBAColor = {
	r: number
	g: number
	b: number
	a: number
}

export class ViewerColor {
	static getColor(rgbaColorObject: RGBAColor) {
		return new THREE.Vector4(
			rgbaColorObject.r / 255,
			rgbaColorObject.g / 255,
			rgbaColorObject.b / 255,
			rgbaColorObject.a,
		)
	}

	COLOR_BY_STATUS = {
		approved: ViewerColor.getColor(SR_RGBA_VIEWER_APPROVED),
		verified: ViewerColor.getColor(SR_RGBA_VIEWER_VERIFIED),
		deviated: ViewerColor.getColor(SR_RGBA_VIEWER_DEVIATED),
		under_construction: ViewerColor.getColor(SR_RGBA_VIEWER_UNDER_CONSTRUCTION),
		missing: ViewerColor.getColor(SR_RGBA_VIEWER_MISSING),
		no_data: ViewerColor.getColor(SR_RGBA_VIEWER_NO_DATA),
		default: ViewerColor.getColor(SR_RGBA_VIEWER_DEFAULT),
	}

	constructor(private viewer: Autodesk.Viewing.GuiViewer3D) {}

	paintByForgeIdAndStatus(
		forgeObjectId: number,
		status: ClassificationStatus | 'approved' | 'default',
		enableUnderConstruction: boolean,
	): void {
		if (!enableUnderConstruction && status !== 'default' && status !== 'approved') {
			status = getClassificationStatusWithoutUnderConstruction(status)
		}

		// the signature of Autodesk.Viewing.Viewer3D in the @types/forge-viewer package
		// unfortunately is not correct, as it should be
		// setThemingColor(dbId: number, color: THREE.Vector4, model?: any, recursive: boolean): void;
		// where recursive: Should apply theming color recursively to all child nodes.
		this.viewer.setThemingColor(
			forgeObjectId,
			this.COLOR_BY_STATUS[status] || this.COLOR_BY_STATUS['default'],
			undefined,
			// @ts-ignore
			true,
		)
	}

	findPaintedElementByMinDepth(dbIds: number[], viewer: Autodesk.Viewing.Viewer3D): number | undefined {
		return dbIds
			.slice()
			.reverse()
			.find(dbId => {
				const fragList = viewer.model.getFragmentList()
				const colorMap = fragList.db2ThemingColor
				return [
					this.COLOR_BY_STATUS['deviated'],
					this.COLOR_BY_STATUS['verified'],
					this.COLOR_BY_STATUS['under_construction'],
					this.COLOR_BY_STATUS['missing'],
					this.COLOR_BY_STATUS['no_data'],
					this.COLOR_BY_STATUS['approved'],
				].some(color => colorMap[dbId] && color.equals(colorMap[dbId]))
			})
	}

	getAllDbIds(): number[] {
		return !this.viewer.model
			? []
			: Object.keys(this.viewer.model.getData().instanceTree.nodeAccess.dbIdToIndex).map(id => parseInt(id, 10))
	}

	clear(): void {
		this.getAllDbIds().forEach(id => this.paintByForgeIdAndStatus(id, 'default', false))
	}
}
