import React from 'react'
import {
	analysisViewTradesAndStatuses,
	hasIncrementalDelivery,
	isAnalysisViewReady,
	shouldDisplayAnalysisViewReadyNotification,
} from '../analysis-view.helpers'
import {useAnalysisViewVersionsForStatus} from '../useAnalysisViewVersionsForStatus'
import {useClassificationState} from '../../../reducers/classification.slice'
import {AnalysisViewEntityPopulated} from '../analysis-view.entities'
import {VersionEntity} from '../../../entities/version.entities'
import {useDismissedAnalysisReadyNotifications} from '../../UserLocalState/useUserLocalState'
import {AnalysisInProgressAccordion} from './AnalysisInProgressAccordion'
import {AnalysisReadyProgress} from './AnalysisReadyProgress'

export const AnalysisViewStatus = () => {
	const {selectedAnalysisView: analysisView, analysisVersionMap} = useClassificationState()
	const versions = useAnalysisViewVersionsForStatus(analysisView, analysisVersionMap)
	if (!analysisView || !versions || !hasIncrementalDelivery(analysisView)) return null
	return isAnalysisViewReady(analysisView, versions) ? (
		<AnalysisViewStatusReady {...{analysisView, versions}} />
	) : (
		<AnalysisInProgressAccordion trades={analysisViewTradesAndStatuses(analysisView, versions)} />
	)
}

const AnalysisViewStatusReady = ({
	analysisView,
	versions,
}: {
	analysisView: AnalysisViewEntityPopulated
	versions: VersionEntity[]
}) => {
	const [dismissed, setDismissed] = useDismissedAnalysisReadyNotifications()
	const enabled = shouldDisplayAnalysisViewReadyNotification(analysisView, versions, dismissed)
	return !enabled ? null : <AnalysisReadyProgress close={() => setDismissed(analysisView._id)} />
}
