import {FeatureFlagConfig} from './featureFlagConfig'
import {FeatureFlags} from './featureFlags'

export class FeatureFlagReader<T extends string, L extends string> {
	private readonly layerConfigurations: {id: L; values: Map<T, boolean>}[]
	readonly featureFlags: FeatureFlags<T>

	constructor(private config: FeatureFlagConfig<T>, layers: L[]) {
		this.layerConfigurations = layers.map(layer => ({id: layer, values: new Map<T, boolean>()}))
		this.featureFlags = new FeatureFlags(this)
	}

	// TODO handle unknown feature flags
	importDataToLayer(values: Record<string, boolean>, layer: L) {
		const layerConfig = this.layerConfigurations.find(config => config.id === layer)!
		layerConfig.values = new Map<T, boolean>()
		Object.entries(values).forEach(([id, value]) => {
			if (this.config.validFeatureFlag(id)) {
				layerConfig.values.set(id, value)
			}
		})
	}

	featureFlagEnabled(id: T) {
		const enabled = this.layerConfigurations.find(config => config.values.has(id))?.values.get(id)
		return enabled ?? this.config.getDefaultValue(id)
	}

	getFeatureFlags() {
		return this.config.getFeatureFlags().map(flag => {
			const layerConfig = this.layerConfigurations.find(config => config.values.has(flag))
			return {
				id: flag,
				value: layerConfig?.values.get(flag) ?? this.config.getDefaultValue(flag),
			}
		})
	}

	getFeatureFlagsWithLayer() {
		return this.getFeatureFlagsWithLayerWithFilter(this.layerConfigurations.map(c => c.id))
	}

	getFeatureFlagsWithLayerWithFilter(layers: L[]) {
		const filteredLayers = this.layerConfigurations.filter(config => layers.includes(config.id))
		return this.config.getFeatureFlags().map(flag => {
			const layerConfig = filteredLayers.find(config => config.values.has(flag))
			return {
				id: flag,
				value: layerConfig?.values.get(flag) ?? this.config.getDefaultValue(flag),
				layer: layerConfig?.id ?? 'defaultValue',
			}
		})
	}
}
