import {RootState} from '../../../reducers'
import {ProgressAggregation} from '../aggregations/progress.aggregations'
import {selectorCurrentWatchlist} from './watchlist.selectors'
import {selectorCurrentProgressMonitorings} from './progress-monitoring.selectors'

export function selectorProgressAggregations(state: RootState): ProgressAggregation[] {
	return state.progressMonitoringState.watchlists.map(watchlist => {
		const progressMonitoring = state.progressMonitoringState.progressMonitorings.filter(
			elem => elem.watchlist === watchlist._id,
		)
		return {watchlist, progresses: progressMonitoring}
	})
}

export function selectorCurrentProgress(state: RootState): ProgressAggregation | null {
	const watchlist = selectorCurrentWatchlist(state)
	if (watchlist === null) return null
	const progresses = selectorCurrentProgressMonitorings(state)
	if (progresses === null) return null
	return {
		watchlist,
		progresses,
	}
}
