import {useDispatch, useSelector} from 'react-redux'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {useCallback, useMemo} from 'react'
import {
	doSelectThreeSixtyViewsphere,
	doToggleAssetsMode,
	doToggleThreeSixtyHighlight,
	doToggleThreeSixtyLockedView,
} from '../../../actions/viewer'

export function useThreeSixtyState() {
	const dispatch = useDispatch()
	const {
		threeSixtyHighlight,
		threeSixtyLockedView,
		threeSixtyClassification: classification,
		threeSixtyViewsphere: viewsphere,
	} = useSelector(selectorViewerProperties)
	const viewspheres = useMemo(() => classification?.viewspheres || [], [classification])
	const incrViewsphereIndex = useCallback(
		(amount: number) => {
			const newIndex =
				(viewspheres.findIndex(v => v.imagePath === viewsphere?.imagePath) + amount + viewspheres.length) %
				viewspheres.length
			return dispatch(doSelectThreeSixtyViewsphere(viewspheres[newIndex]))
		},
		[dispatch, viewspheres, viewsphere],
	)
	const nextImage = useCallback(() => incrViewsphereIndex(1), [incrViewsphereIndex])
	const previousImage = useCallback(() => incrViewsphereIndex(-1), [incrViewsphereIndex])

	const displayViewsphereHeaderElements = !!classification && viewspheres?.length > 0

	const threeSixtyIndex = viewspheres.findIndex(v => v.imagePath === viewsphere?.imagePath)

	const carouselProps = {
		prev: previousImage,
		next: nextImage,
		carouselIndex: threeSixtyIndex,
		viewspheres: viewspheres,
	}
	const threeSixtyProps = {
		onToggleMode: () => dispatch(doToggleAssetsMode()),
		highLightElement: threeSixtyHighlight,
		onToggleHighlightElement: () => dispatch(doToggleThreeSixtyHighlight()),
	}
	const lockedViewsProps = {
		threeSixtyLockedView: threeSixtyLockedView,
		onToggleLockedViews: () => dispatch(doToggleThreeSixtyLockedView()),
		classification: classification,
	}
	return {classification, displayViewsphereHeaderElements, carouselProps, threeSixtyProps, lockedViewsProps}
}
