import {buildAxios} from '../../../api/api-factory'
import {config} from '../../../config'
import {FiltersEntity} from '../../FilterPresets/entities/filter-preset.entities'

export const qualityFeatureApiV3 = buildAxios(`${config.sr.backendUrl}quality-report/v3/`)

export const fetchQualityReportPdf = async (
	projectId: string,
	analysisViewId: string,
	filtersDTO: FiltersEntity | undefined,
	showQuality: boolean,
	includeMagnitudeDistribution: boolean,
	enableUnderConstruction: boolean,
	description: string,
	imageS3Keys: string[],
): Promise<Blob> => {
	return (
		await qualityFeatureApiV3.post(
			`projects/${projectId}/analysis/${analysisViewId}/pdf`,
			{showQuality, enableUnderConstruction, includeMagnitudeDistribution, description, imageS3Keys},
			{
				params: {filtersDTO},
				responseType: 'blob',
			},
		)
	).data
}
