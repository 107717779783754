import viewerReducer from './viewerReducer'
import classificationReducer from './classification'
import userProfileReducer from './userProfile'
import storage from 'redux-persist/lib/storage'
import {GlobalNotificationsReducer} from '../features/GlobalNotifications'
import {combineReducers} from '@reduxjs/toolkit'
import {watchlistSlice} from '../features/Watchlist/slices/watchlist.slice'
import currentUserReducer from './current-user.reducer'
import {filterPresetSlice} from '../features/FilterPresets/slices/filter-preset.slice'
import {classificationSlice} from './classification.slice'
import {issueSlice} from '../features/IssueManagement/issue.slice'
import {sectionSlice} from '../components/Viewer.Components/Viewing.Extension.ElementSections/StateManagement/section.slice'
import {managementDashboardSlice} from '../features/ManagementDashboard/core/management-dashboad.slice'
import {watchlistFilterElementsSlice} from '../features/Watchlist/slices/watchlist-filter-elements.slice'
import {analysisSelectorFilterSlice} from './analysisSelectorFilter.slice'

const appReducer = combineReducers({
	viewerState: viewerReducer,
	classificationState: classificationReducer,
	userProfileState: userProfileReducer,
	notifications: GlobalNotificationsReducer,
	progressMonitoringState: watchlistSlice.reducer,
	currentUser: currentUserReducer,
	filterPresetState: filterPresetSlice.reducer,
	classification: classificationSlice.reducer,
	issue: issueSlice.reducer,
	section: sectionSlice.reducer,
	managementDashboard: managementDashboardSlice.reducer,
	watchlistFilterElementsState: watchlistFilterElementsSlice.reducer,
	analysisSelectorFilterSliceState: analysisSelectorFilterSlice.reducer,
})

export type RootState = ReturnType<typeof appReducer>

// Added an intermediary step to the global reducer to implement wipe state on sign out
const rootReducer = (state: RootState, action: any) => {
	// For when we implement redux-persist

	if (action.type === 'USER_SIGN_OUT') {
		storage.removeItem('persist:root')
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}
export default rootReducer
