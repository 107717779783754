import {useSelector} from 'react-redux'
import {selectorViewerInstance, selectorViewerProperties} from '../../../../selectors/viewer-state.selector'
import {RootState} from '../../../../reducers'
import React, {useEffect} from 'react'
import ElementSectionsExtension, {ELEMENT_SECTIONS_EXTENSION} from '../Viewing.Extension.ElementSections'
import {ElementSectionsPanelWithStore} from './ElementSectionsPanelContent'
import {useSectionState} from '../StateManagement/section.slice'

interface SectionExtensionI {
	setVisible(visible: boolean): void
}

export function ElementSectionsSplitViewContainer() {
	const {assetsMode} = useSelector(selectorViewerProperties)
	const {isOpened: sectionsOpened} = useSectionState()
	const isSplitView = sectionsOpened && assetsMode === 'split'

	const isModelLoaded = useSelector((state: RootState) => state.viewerState.isReady.forgeMapping)
	const viewer = useSelector(selectorViewerInstance)

	const extensionRef = React.useRef<SectionExtensionI>()
	const [extensionLoaded, setExtensionLoaded] = React.useState(false)

	useEffect(() => {
		if (isModelLoaded && viewer) {
			extensionRef.current = viewer.getExtension(ELEMENT_SECTIONS_EXTENSION) as ElementSectionsExtension
			setExtensionLoaded(true)
		}
	}, [isModelLoaded, viewer])

	useEffect(() => {
		if (!extensionLoaded) return
		extensionRef.current?.setVisible(sectionsOpened && !isSplitView)
	}, [extensionLoaded, sectionsOpened, isSplitView])

	return isSplitView ? <ElementSectionsPanelWithStore /> : null
}
