import {CURRENT_USER_ADD} from '../constants/action-types'

const INITIAL_STATE = {
	currentUser: null,
}
export default function currentUserReducer(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case CURRENT_USER_ADD:
			return {
				...state,
				currentUser: action.payload,
			}
		default:
			return state
	}
}
