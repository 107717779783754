import {Box, Table, TableBody, TableCell, TableRow, Text, ThemeContext} from 'grommet'
import {format} from 'date-fns'
import React from 'react'
import {AnalysisViewEntityPopulated} from '../../AnalysisView/analysis-view.entities'
import {issueDelayed, IssueEntity} from '../issue.entities'
import {COLOR_BY_ISSUE_PRIORITY, SRChip, SRHeading} from 'sr-react-commons'
import {CardContainer} from '../../../components/CommonsCandidate/CardContainer/CardContainer'

type IssueDetailBoxProps = {
	issue: IssueEntity
	analysisView: AnalysisViewEntityPopulated | null
}

function IssueTableRow(props: {label: string; value: string}) {
	return (
		<TableRow>
			<TableCell size="100px">
				<Text weight={'bold'}>{props.label}: </Text>
			</TableCell>
			<TableCell>
				<Text>{props.value}</Text>
			</TableCell>
		</TableRow>
	)
}

function IssueTableGap() {
	return (
		<TableRow>
			<Box height="10px"></Box>
		</TableRow>
	)
}

export function IssueDetailBox({issue, analysisView}: IssueDetailBoxProps) {
	return (
		<CardContainer gap="xsmall">
			<Box direction={'row'} justify={'between'}>
				<Box>
					<SRHeading level={4}>Detail</SRHeading>
				</Box>
				<Box direction={'row'} gap={'xsmall'} alignSelf={'center'}>
					{issue.priority && (
						<SRChip
							color={issue.priority === 'Minor' ? 'black' : 'white'}
							background={COLOR_BY_ISSUE_PRIORITY[issue.priority]}
							text={issue.priority}
						/>
					)}
					{issueDelayed(issue) && <SRChip color="white" background="status-error" text="Delayed" />}
				</Box>
			</Box>

			<ThemeContext.Extend value={{table: {body: {pad: {horizontal: 0, vertical: '2px'}}}}}>
				<Table>
					<TableBody>
						<TableRow>
							<IssueTableRow label={'Created'} value={format(new Date(issue.createdDate), 'E do, MMMM yyyy H:mm')} />
							{issue.user && (
								<IssueTableRow label={'Created by'} value={`${issue.user.firstName} ${issue.user.lastName}`} />
							)}
							<IssueTableGap />
							<IssueTableRow label={'Status'} value={issue.issueStatus} />
							<IssueTableRow
								label={'Deadline'}
								value={issue.deadline ? format(new Date(issue.deadline), 'E do, MMMM yyyy') : '-'}
							/>
							<IssueTableRow
								label={'Assigned to'}
								value={
									issue.assignedUser ? `${issue.assignedUser.firstName} ${issue.assignedUser.lastName}` : 'Unassigned'
								}
							/>
							<IssueTableGap />
							<IssueTableRow label={'Location'} value={issue.location} />
							{analysisView && <IssueTableRow label={'Reported for'} value={analysisView.name} />}
						</TableRow>
					</TableBody>
				</Table>
			</ThemeContext.Extend>
		</CardContainer>
	)
}
