// @ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {Box, Text} from 'grommet'
import {Spinner} from '../CommonsCandidate/Loading/Spinner'
import {useSelector} from 'react-redux'
import {RootState} from '../../reducers'
import {useTrackTimeDisplayed} from '../../hooks/useTrackTimeDisplayed'

const StyledLoader = styled(LoadingOverlay)`
	&._loading_overlay_wrapper {
		height: 100%;
		width: 100%;
	}

	._loading_overlay_overlay {
		background: linear-gradient(360deg, #242629 16.7%, rgba(36, 38, 41, 0.5) 100%);
		opacity: 0.9;
		z-index: 10;
	}

	._loading_overlay_content {
		height: 100%;
		width: 100%;
	}
`

function ModelLoadingSpinner() {
	useTrackTimeDisplayed('ModelLoader')
	return (
		<Box justify={'end'} fill>
			<Box gap={'small'} margin={{bottom: '8%'}}>
				<Spinner hideIfGlobalLoader={false} color={'light'} />
				<Text size={'large'} weight={'bold'}>
					Your model is loading
				</Text>
				<Text>Tip: You can select multiple elements with SHIFT + CLICK</Text>
			</Box>
		</Box>
	)
}

export const ViewerLoadingOverlay = ({children}: {children: ReactNode}) => {
	const isReady = useSelector((state: RootState) => state.viewerState.isReady)
	const {db, forgeViewer, forgeMapping} = isReady
	const isLoading = !(db && forgeViewer && forgeMapping)
	return <StyledLoader spinner={<ModelLoadingSpinner />} active={isLoading} children={children} />
}
