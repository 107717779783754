import {FileUploadState} from '../hooks/useFileUploader'
import {Box, Meter, Text} from 'grommet'
import {Close} from 'grommet-icons'
import React from 'react'
import {prettyDisplayFileSize} from '../../../utilities/fileUtilities'
import {DroppedFile} from './ProjectFileUploader'
import {SRIconButton} from 'sr-react-commons'

export function FileUploadProgressTable(props: {
	files: DroppedFile[]
	uploadingFiles: FileUploadState['files']
	onRemove: (fileName: string) => void
	canRemoveFiles: boolean
	onCancelUpload: (fileName: string) => void
}) {
	return (
		<Box flex={false}>
			{props.files.map(({file, accepted}) => {
				const fileProgress = props.uploadingFiles[file.name]
				return (
					<Box pad={'xsmall'} direction={'row'} key={file.name} border={{color: 'neutral-2', side: 'bottom'}}>
						<Box alignSelf={'stretch'} width={'75%'} gap="xsmall">
							<Box justify={'center'} height={'24px'}>
								<Text title={file.name} truncate>
									{file.name}
								</Text>
							</Box>
							<Box height={'18px'} justify={'center'}>
								{fileProgress && ['uploading', 'success'].includes(fileProgress.status) ? (
									<Meter
										color={'accent-4'}
										background={'neutral-2'}
										thickness="xsmall"
										round
										type="bar"
										value={fileProgress?.progress || 0}
									/>
								) : accepted || (fileProgress && fileProgress?.status === 'error') ? (
									<Text size={'xsmall'} color={'neutral-1'}>
										{prettyDisplayFileSize(file.size)}
									</Text>
								) : null}
							</Box>
						</Box>
						<Box alignSelf={'stretch'} width={'25%'} align={'end'} gap="xsmall">
							<Box height={'24px'}>
								{(!fileProgress || ['pending', 'uploading'].includes(fileProgress?.status)) && props.canRemoveFiles && (
									<SRIconButton
										size={'small'}
										icon={<Close size={'xsmall'} />}
										onClick={() => (!!fileProgress ? props.onCancelUpload(file.name) : props.onRemove(file.name))}
									/>
								)}
							</Box>
							<Box height={'18px'} justify={'center'}>
								{fileProgress &&
									(fileProgress.status === 'error' ? (
										<Text color={'status-error'} size={'xsmall'}>
											Error
										</Text>
									) : fileProgress.status === 'canceled' ? (
										<Text color={'neutral-1'} size={'xsmall'}>
											Canceled
										</Text>
									) : (
										<Text color={'neutral-1'} size={'xsmall'}>
											{Math.round(fileProgress.progress || 0)}%
										</Text>
									))}
								{!accepted && (
									<Text color={'status-error'} size={'xsmall'}>
										Wrong file format
									</Text>
								)}
							</Box>
						</Box>
					</Box>
				)
			})}
		</Box>
	)
}
