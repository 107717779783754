import React, {useCallback, useEffect, useState} from 'react'
import {AccordionPanel, Box} from 'grommet'
import {useDispatch, useSelector} from 'react-redux'
import {selectorFilterPresets} from '../selectors/filter-preset.selectors'
import {FilterPresetsHeader} from './FilterPresetsHeader'
import {FilterPresetsBody} from './FilterPresetsBody'
import {BorderLessAccordion} from '../../../components/Accordions/BorderLessAccordion'
import {FilterPresetEntity, FilterPresetEntityOptionalId} from '../entities/filter-preset.entities'
import {FilterPresetSettingsModal} from './modals/FilterPresetSettingsModal'
import {
	getFilterPresets,
	removeFilterPreset,
	saveFilterPreset,
	selectFilterPreset,
} from '../slices/filter-preset.thunks'
import {classificationSlice} from '../../../reducers/classification.slice'
import {SRLoadingOverlay} from '../../../components/CommonsCandidate/Loading/SRLoadingOverlay'
import {RotateLeft} from 'grommet-icons'
import {SRHeading, SRIconButton} from 'sr-react-commons'

export type FilterPresetsMode = 'overview' | 'work-package' | undefined
export interface FilterPresetOps {
	save: (preset: FilterPresetEntityOptionalId) => any
	select: (preset: FilterPresetEntity | null) => any
	remove: () => void
	discardChanges: () => void
	showSettings: (preset: FilterPresetEntityOptionalId) => void
	mode: FilterPresetsMode
	readonly: boolean
}

export function FilterPresets({mode, readonly}: {mode: FilterPresetsMode; readonly?: boolean}) {
	const dispatch = useDispatch()
	const {loading, current, canModify, isNotFiltering} = useSelector(selectorFilterPresets)
	const load = useCallback(() => dispatch(getFilterPresets()), [dispatch])
	const [settingsPreset, setSettingsPreset] = useState<FilterPresetEntityOptionalId | null>(null)
	const ops: FilterPresetOps = {
		mode,
		readonly: !!readonly,
		save: (preset: FilterPresetEntityOptionalId) => dispatch(saveFilterPreset(preset)),
		select: (preset: FilterPresetEntity | null) => dispatch(selectFilterPreset(preset)),
		discardChanges: () =>
			current.selected && dispatch(classificationSlice.actions.setFilter({filter: current.selected.filters})),
		showSettings: setSettingsPreset,
		remove: () => current.selected && dispatch(removeFilterPreset(current.selected._id)) && setSettingsPreset(null),
	}

	useEffect(() => {
		load()
	}, [load])

	const accordionPanelHeaderLabel = (icon: JSX.Element, text: string) => {
		return (
			<Box fill align="center" direction="row" justify="between" pad={{horizontal: 'xxsmall', vertical: 'xsmall'}}>
				<SRHeading level={5}>{text}</SRHeading>
				<SRIconButton disabled={isNotFiltering || ops.readonly} icon={icon} />
			</Box>
		)
	}
	const onResetFilterPresets = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e.stopPropagation()
		ops.select(null)
	}
	return (
		<Box>
			{settingsPreset && (
				<FilterPresetSettingsModal
					preset={settingsPreset}
					onClose={() => setSettingsPreset(null)}
					ops={ops}
					canModify={canModify(settingsPreset)}
				/>
			)}
			{/* TODO refactor this to use FiltersContainer. Current blocker: data-testids for e2e tests*/}
			<Box elevation="small" pad={{horizontal: 'xsmall'}} background={'light-3'} round="xsmall">
				<BorderLessAccordion>
					<AccordionPanel
						data-testid={'filter-presets-accordion'}
						label={accordionPanelHeaderLabel(
							<RotateLeft size="small" onClick={onResetFilterPresets} data-testid={'filter-header-reset-icon'} />,
							'Filters',
						)}
					>
						<SRLoadingOverlay active={loading}>
							<Box fill gap="xsmall" pad={{bottom: 'small', horizontal: 'xxsmall'}}>
								<FilterPresetsHeader ops={ops} />
								<FilterPresetsBody ops={ops} />
							</Box>
						</SRLoadingOverlay>
					</AccordionPanel>
				</BorderLessAccordion>
			</Box>
		</Box>
	)
}
