import {PermissionsEnum} from '../../../entities/auth'
import {ManagementDashboardPageFileMeta} from '../core/management-dashboard-page.utils'

export const DOCUMENT_PAGE_META: ManagementDashboardPageFileMeta = {
	name: 'Documents',
	singular: 'Document',
	displayVersion: false,
	permission: PermissionsEnum.FILE_DOC_LIST,
	emptyView: {
		title: "You haven't uploaded any documents yet.",
		message: 'Uploaded documents will appear here.',
		actionText: 'Upload document',
	},
} as const
