import {Box, Form, Layer, RadioButton, Text, TextInput} from 'grommet'
import React, {MouseEvent, useState} from 'react'
import {FilterPresetEntityOptionalId} from '../../entities/filter-preset.entities'
import {Close, Edit, Group} from 'grommet-icons'
import {FilterPresetOps} from '../FilterPresets'
import {
	SRConfirmDeleteModal,
	SRDeleteSecondaryIconButton,
	SRHeading,
	SRIconButton,
	SRPlainButton,
	SRPrimaryButton,
} from 'sr-react-commons'

export function FilterPresetSettingsModal({
	preset,
	canModify,
	onClose,
	ops,
}: {
	preset: FilterPresetEntityOptionalId
	canModify: boolean
	onClose: () => void
	ops: FilterPresetOps
}) {
	const [confirmDeletion, setConfirmDeletion] = useState(false)
	const [name, setName] = useState(preset.name || '')
	const [editing, setEditing] = useState(!name)
	const [scope, setScope] = useState(preset.scope)
	const [nameError, setNameError] = useState('')
	const handleDelete = async () => setConfirmDeletion(true)
	const handleEdit = () => setEditing(true)
	const handleSubmit = async (e: MouseEvent) => {
		e.nativeEvent.preventDefault()
		if (formValidation()) {
			ops.save({
				...preset,
				name,
				scope,
			})
			onClose()
		}
	}
	const formValidation = () => {
		setNameError('')
		let valid = true
		if (name === '') {
			setNameError("Name can't be blank")
			valid = false
		}
		return valid
	}
	return (
		<Layer
			position={'top'}
			margin={{top: '100px'}}
			modal={!confirmDeletion}
			data-testid={'filter-preset-settings-modal'}
		>
			{confirmDeletion && (
				<SRConfirmDeleteModal
					entityName="preset filter"
					name={preset.name}
					onDelete={ops.remove}
					onCancel={() => setConfirmDeletion(false)}
				/>
			)}
			<Form>
				<Box gap={'small'} pad={'medium'} width={'525px'}>
					<Box justify={'between'} direction={'row'} margin={{bottom: 'small'}}>
						<SRHeading level={4} alignSelf={'center'}>
							{preset._id ? 'Preset details' : 'Save as new preset'}
						</SRHeading>
						<SRIconButton icon={<Close />} onClick={onClose} alignSelf={'center'} />
					</Box>
					<Box>
						<SRHeading level={6}>Preset filter name</SRHeading>
						<Box direction={'row'} gap={'medium'} justify="between">
							{editing || !name ? (
								<TextInput
									placeholder={'Name'}
									value={name}
									autoFocus={true}
									size={'medium'}
									onChange={event => {
										setName(event.target.value)
									}}
									onBlur={() => setEditing(false)}
								/>
							) : (
								<Box fill pad={'5px 5px 6px 6px'} margin={{horizontal: '1px', top: '1px'}}>
									<Text weight={600}>{name}</Text>
								</Box>
							)}
							{canModify && (
								<Box direction={'row'} height={'35px'} alignSelf={'center'}>
									<SRIconButton
										className={editing ? ' no-interaction' : ''}
										size={'small'}
										icon={<Edit size={'small'} color={editing || !name ? 'dark-1' : 'dark-3'} />}
										onClick={handleEdit}
									/>
									{preset._id && (
										<SRDeleteSecondaryIconButton
											data-cy={'filter-presets-delete-icon'}
											disabled={editing}
											onClick={handleDelete}
										/>
									)}
								</Box>
							)}
						</Box>
						{nameError && (
							<Text size={'xsmall'} color={'status-critical'}>
								{nameError}
							</Text>
						)}
					</Box>
					<Box>
						<SRHeading level={6}>Permissions</SRHeading>
						<Box gap={'small'}>
							<Box direction={'row'} gap={'medium'}>
								<Box alignSelf={'start'} margin={{top: 'xxsmall'}}>
									<RadioButton
										name="scope"
										checked={scope === 'user'}
										onChange={() => setScope('user')}
										disabled={!canModify || editing}
									/>
								</Box>
								<Box>
									<Text weight={'bold'} size={'small'}>
										My Filters
									</Text>
									<Text color={'dark-4'} size={'small'}>
										This filter is only visible to you
									</Text>
								</Box>
							</Box>
							<Box direction={'row'} gap={'medium'} justify={'start'}>
								<Box alignSelf={'start'} margin={{top: 'xxsmall'}}>
									<RadioButton
										name="scope"
										checked={scope === 'project'}
										onChange={() => setScope('project')}
										disabled={!canModify || editing}
									/>
								</Box>
								<Box pad={{left: '6px'}}>
									<Box gap={'small'} direction={'row'}>
										<Text weight={'bold'} size={'small'}>
											Project Filters
										</Text>
										<Group color={'dark-1'} size={'18px'} />
									</Box>
									<Text color={'dark-4'} size={'small'}>
										All users working on this project can use this filter.
										<br />
										Use this option if you want to share this filter with other members of your organization.
									</Text>
								</Box>
							</Box>
						</Box>
					</Box>
					{canModify && (
						<Box direction="row" justify="between" margin={{top: 'medium'}}>
							<SRPlainButton
								data-cy="filter-preset-setting-modals-cancel"
								onClick={onClose}
								label="Cancel"
								size={'medium'}
							/>
							<SRPrimaryButton
								type="submit"
								size={'medium'}
								label={preset._id ? 'Edit preset details' : 'Save preset'}
								onClick={handleSubmit}
							/>
						</Box>
					)}
				</Box>
			</Form>
		</Layer>
	)
}
