import {UserProject} from '../../../../../reducers/userProfile'
import {useQuery, UseQueryOptions} from 'react-query'
import {AxiosError} from 'axios'
import {fetchElementTypesByModel, fetchElementTypesFromLatestModel} from '../../../../../api/elements.api.v3'
import {getMappedElementTypeLabels} from '../../../../../entities/element.entities'

export function useElementTypeLabelsForProject(
	selectedProject: UserProject | undefined,
	fromLatestModel: boolean = false,
	modelId?: string,
	options?: UseQueryOptions<string[], AxiosError>,
) {
	return useQuery<string[], AxiosError>(
		[selectedProject!._id, 'element-types', fromLatestModel, modelId],
		function (): Promise<string[]> {
			return (fromLatestModel
				? fetchElementTypesFromLatestModel()
				: fetchElementTypesByModel(modelId!)
			).then(elementTypes =>
				elementTypes.map(el => getMappedElementTypeLabels(el, selectedProject?.elementTypeMapping || {}).join(':')),
			)
		},
		{staleTime: 60 * 60 * 1000, ...options, enabled: !!selectedProject},
	)
}
