import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {
	buildInitialPaginationState,
	buildPaginationStateReducer,
	PaginationState,
	usePageState,
} from '../../reducers/base.pagination.state'
import {RootState} from '../../reducers'
import {Asset, IssueEntity, PRIORITIES_FOR_FORM_AND_FILTERS, Priority} from './issue.entities'
import {
	buildFilterStateWithPaginationReducer,
	buildInitialFilterState,
	FilterState,
} from '../../reducers/base.filter.state'
import _ from 'lodash'

type IssueFilterPriorityValue = Priority | 'Unassigned'

export type IssueFilter = {
	statuses: string[] | null
	searchText: string | null
	assignedUsers: string[] | null
	analysisViews: string[] | null
	deadlineBefore: Date | null
	deadlineAfter: Date | null
	createdBefore: Date | null
	createdAfter: Date | null
	priorities: IssueFilterPriorityValue[] | null
	location: string | null
	createdBy: string[] | null
}

export function validateIssueFilterPriorityValue(value: string): value is IssueFilterPriorityValue {
	return PRIORITIES_FOR_FORM_AND_FILTERS.some(priority => priority === value)
}

export function validateIssueFilterPriorityValues(values: string[]): values is IssueFilterPriorityValue[] {
	return values.every(validateIssueFilterPriorityValue)
}

export type IssueSliceState = PaginationState &
	FilterState<IssueFilter> & {
		displayClosed: boolean
		selectedIssue: IssueEntity | null
		tempAssetForNewIssue: {img: string; type: Asset['type']} | null
	}

function buildEmptyFilters(): IssueFilter {
	return {
		statuses: ['Open Issue', 'Under Inspection'],
		searchText: null,
		assignedUsers: null,
		analysisViews: null,
		deadlineBefore: null,
		deadlineAfter: null,
		createdBefore: null,
		createdAfter: null,
		priorities: null,
		location: null,
		createdBy: null,
	}
}

export function equalsInitialFilterState(filter: IssueFilter) {
	return _.isEqual(filter, buildEmptyFilters())
}

export function buildIssueInitialState(): IssueSliceState {
	return {
		...buildInitialPaginationState<IssueSliceState>(20),
		...buildInitialFilterState<IssueSliceState>(buildEmptyFilters()),
		displayClosed: false,
		selectedIssue: null,
		tempAssetForNewIssue: null,
	}
}

const initialState = buildIssueInitialState()

export const issueSlice = createSlice({
	name: 'issue',
	initialState: buildIssueInitialState(),
	reducers: {
		...buildPaginationStateReducer<IssueSliceState>(),
		...buildFilterStateWithPaginationReducer<IssueSliceState>(initialState),
		reset: buildIssueInitialState,
		toggleDisplayClosed: state => {
			state.displayClosed = !state.displayClosed
			state.pagination.currentPage = 0
		},
		setSelectedIssue: (state, action: PayloadAction<{issue: IssueEntity | null}>) => {
			state.selectedIssue = action.payload.issue
		},
		setTempAssetForNewIssue: (state, action: PayloadAction<IssueSliceState['tempAssetForNewIssue']>) => {
			return {
				...state,
				tempAssetForNewIssue: action.payload,
			}
		},
	},
})

export function useIssuePageState() {
	const {pagination} = useSelector<RootState, IssueSliceState>(state => state.issue)
	return usePageState(pagination, issueSlice.actions)
}

export function useIssueSliceState() {
	const pageState = useIssuePageState()
	const {displayClosed, selectedIssue, filter, tempAssetForNewIssue} = useSelector((state: RootState) => state.issue)
	return {pageState, displayClosed, selectedIssue, filter, tempAssetForNewIssue}
}

export function useIssueFilterState() {
	const filter = useSelector<RootState, IssueFilter>(state => state.issue.filter)
	return {filter, actions: _.pick(issueSlice.actions, ['setFilter', 'setPartialFilter', 'resetFilter'])}
}
