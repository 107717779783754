import {AppThunk} from '../../../store/store'
import {deleteFilterPreset, fetchFilterPresets, postFilterPreset, putFilterPreset} from '../api/filter-preset.api'
import {createGlobalNotificationsAction as globalNotification} from '../../GlobalNotifications'
import {reqErrorMsg} from '../../../utilities/errorHandleUtilities'
import {FilterPresetEntity, FilterPresetEntityOptionalId} from '../entities/filter-preset.entities'
import {filterPresetSlice} from './filter-preset.slice'
import {classificationSlice} from '../../../reducers/classification.slice'

export const getFilterPresets = (): AppThunk => async dispatch => {
	dispatch(filterPresetSlice.actions.doSetLoading(true))
	try {
		dispatch(filterPresetSlice.actions.doFilterPresetFetchAll(await fetchFilterPresets()))
	} catch (e) {
		dispatch(globalNotification.alert(reqErrorMsg(e, 'Error fetching filter presets')))
		dispatch(filterPresetSlice.actions.doSetLoading(false))
	}
}

export const saveFilterPreset = (preset: FilterPresetEntityOptionalId): AppThunk => async dispatch => {
	dispatch(filterPresetSlice.actions.doSetLoading(true))
	const api = preset._id ? putFilterPreset : postFilterPreset
	try {
		dispatch(filterPresetSlice.actions.doFilterPresetSave(await api(preset)))
	} catch (e) {
		dispatch(globalNotification.alert(reqErrorMsg(e, 'Error saving the filter preset')))
		dispatch(filterPresetSlice.actions.doSetLoading(false))
	}
}

export const selectFilterPreset = (preset: FilterPresetEntity | null): AppThunk => async dispatch => {
	dispatch(filterPresetSlice.actions.doFilterPresetSetSelected(preset && preset._id))
	if (preset) {
		dispatch(classificationSlice.actions.setFilter({filter: preset.filters}))
	} else {
		dispatch(classificationSlice.actions.resetFilter())
	}
}

export const removeFilterPreset = (id: string): AppThunk => async dispatch => {
	dispatch(filterPresetSlice.actions.doSetLoading(true))
	try {
		dispatch(filterPresetSlice.actions.doFilterPresetRemove(await deleteFilterPreset(id)))
	} catch (e) {
		dispatch(globalNotification.alert(reqErrorMsg(e, 'Error deleting the filter preset')))
		dispatch(filterPresetSlice.actions.doSetLoading(false))
	}
}
