import {ManagementDashboardPageLayout} from '../core/components/layout/ManagementDashboardPageLayout'
import React from 'react'
import {ManagementDashboardBaseFileList} from './ManagementDashboardBaseFileList'
import {FileVisibility} from '../../../api/files.api'
import {ForgeIntegrationEntity} from '../../ForgeIntegration/forge-integration.entities'

export type ManagmentDashboardFilePageFromForgeOps = {
	onOpenUploadModal: () => void
	uploadModal: JSX.Element
	integration: ForgeIntegrationEntity | null
}

export type ManagementDashboardBaseFilePagePops = {
	fileVisibility: FileVisibility
	uploadModal: JSX.Element
	onOpenUploadModal: () => void
	fromForgeOps?: ManagmentDashboardFilePageFromForgeOps
}

export const ManagementDashboardBaseFilePage = ({
	fileVisibility,
	uploadModal,
	onOpenUploadModal,
	fromForgeOps,
}: ManagementDashboardBaseFilePagePops) => {
	return (
		<ManagementDashboardPageLayout>
			{uploadModal}
			{fromForgeOps?.uploadModal || null}
			<ManagementDashboardBaseFileList {...{fileVisibility, onOpenUploadModal, fromForgeOps}} />
		</ManagementDashboardPageLayout>
	)
}
