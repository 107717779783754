export function isProgressMonitoringArea(pathname: string): boolean {
	return pathname.includes('/work-packages')
}

export function isProgressMonitoringWorkPackageListArea(pathname: string): boolean {
	return pathname.endsWith('/work-packages')
}

export function isProgressMonitoringWorkPackageDetailArea(pathname: string): boolean {
	return isProgressMonitoringArea(pathname) && pathname.endsWith('/detail')
}

export function isProgressMonitoringWorkPackageEdit(pathname: string): boolean {
	return isProgressMonitoringArea(pathname) && pathname.endsWith('edit')
}

export function isProgressMonitoringWorkPackageCreate(pathname: string): boolean {
	return isProgressMonitoringArea(pathname) && pathname.endsWith('new')
}

export function isProgressMonitoringWorkPackageCreateOrEdit(pathname: string): boolean {
	return isProgressMonitoringWorkPackageCreate(pathname) || isProgressMonitoringWorkPackageEdit(pathname)
}
