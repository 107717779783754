import {WatchlistEntity} from '../entities/watchlist.entities'
import React from 'react'
import {fetchWorkPackageAsCSV} from '../api/progress-monitoring.api'
import {format} from 'date-fns'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'

export function useExportWorkPackageToCSV(watchlist: WatchlistEntity | null) {
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	return React.useMemo(
		() => ({
			fetchFile: () => {
				if (!watchlist) {
					throw new Error('Work package is not fetched yet, it should not be possible to request the CSV file.')
				}
				return fetchWorkPackageAsCSV(watchlist._id, enableUnderConstruction).then(
					data => new Blob([data], {type: 'text/csv; charset=utf-8;'}),
				)
			},
			filename: `${watchlist?.name}-${format(new Date(), 'yyyyMMdd-HHmm')}.csv`,
		}),
		[enableUnderConstruction, watchlist],
	)
}
