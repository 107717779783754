//TODO create core library to use by front and back

export function sleep(timeout: number): Promise<void> {
	return new Promise(resolve => {
		setTimeout(resolve, timeout)
	})
}

export class Sleeper {
	public currentSleepTime = 0

	constructor(
		public readonly minSleepTime = 10,
		public readonly maxSleepTime = 1000,
		public readonly multiplySleepTimeFactor = 2,
	) {
		this.currentSleepTime = minSleepTime
	}

	clear(): void {
		this.currentSleepTime = this.minSleepTime
	}

	async sleep(): Promise<void> {
		await sleep(this.currentSleepTime)
	}

	increase(): void {
		const time = this.currentSleepTime * this.multiplySleepTimeFactor
		this.currentSleepTime = time > this.maxSleepTime ? this.maxSleepTime : time
	}
}

/**
 * It repeats the passed function until its promise resolves with true.
 * The timeout before retrying starts with 50ms and is increased by 1.3 on every retry
 * but will never exceed 10000ms.
 * @param fn The function to repeat until it resolves truthy
 */
export const waitUntil = async (fn: () => Promise<boolean>, maxTime = 60000): Promise<void> => {
	const sleeper = new Sleeper(50, 10000, 1.3)
	const startTime = Date.now()
	for (;;) {
		const result = await fn()
		if (result) return
		if (Date.now() > startTime + maxTime) throw new Error('wait until timeout, too much time waiting.')
		await sleeper.sleep()
		sleeper.increase()
	}
}
