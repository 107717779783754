import React, {useCallback, useState} from 'react'
import {Box, Text} from 'grommet'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {forgeIntegrationEnsureUserIsAuthed} from '../oauth/forge-integration.oauth'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'
import {ForgeIntegrationCreateModal} from './ForgeIntegrationCreateModal'
import {invalidateUseCurrentForgeIntegration, useCurrentForgeIntegration} from '../hooks/UseCurrentForgeIntegration'
import {LoadingWrapper} from '../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {ForgeIntegrationDisconnectIntegrationModal} from './ForgeIntegrationDisconnectIntegrationModal'
import {ForgeIntegrationAutodeskIcon} from '../icons/AutodeskIcon/ForgeIntegrationAutodeskIcon'
import {getIntegrationIcon} from '../forge-integration.utils'
import {useGlobalNotifications} from '../../GlobalNotifications'
import {ForgeOauthError, handleForgeOAuthError} from '../oauth/ForgeOauthError'
import {
	SRBrandButton,
	SRCard,
	SRHeading,
	SRPrimaryButton,
	SRRadialFillIcon,
	SRSecondaryButton,
	SRTooltip,
} from 'sr-react-commons'
import {invalidateUseUserHasValidForgeAccess, useUserHasValidForgeAccess} from '../hooks/UseUserHasValidForgeAccess'
import {userProjectHasPermission} from '../../../reducers/userProfile'
import {PermissionsEnum} from '../../../entities/auth'
import {deleteCurrentLogin} from '../forge-integration.api'
import {config} from '../../../config'

export const ForgeIntegrationCard = () => {
	const project = useCurrentProject()
	const isAdmin = userProjectHasPermission(project || null, PermissionsEnum.ADMIN)

	const {data: currentForgeIntegration, isLoading: isCurrentForgeIntegrationLoading} = useCurrentForgeIntegration(
		project,
	)
	const {data: hasValidAccess, isLoading: isHasValidAccessLoading} = useUserHasValidForgeAccess(project)
	const [forgeConnectLoading, setForgeConnectLoading] = useState(false)
	const [forgeLoginLoading, setForgeLoginLoading] = useState(false)
	const [showDisconnectIntegrationModal, setShowDisconnectIntegrationModal] = useState(false)
	const [showCreateIntegrationModal, setShowCreateIntegrationModal] = useState(false)
	const closeCreateIntegrationModal = useCallback(() => setShowCreateIntegrationModal(false), [
		setShowCreateIntegrationModal,
	])
	const {notifyError} = useGlobalNotifications()
	const onIntegrationCreated = useCallback(async () => {
		setShowCreateIntegrationModal(false)
		await invalidateUseCurrentForgeIntegration()
	}, [setShowCreateIntegrationModal])
	const onConnectForge = async () => {
		setForgeConnectLoading(true)
		try {
			await forgeIntegrationEnsureUserIsAuthed(project!._id)
			setShowCreateIntegrationModal(true)
		} catch (e) {
			if (e instanceof ForgeOauthError) {
				handleForgeOAuthError(e, notifyError)
			} else {
				notifyError(`An unexpected error occurred while connecting the project.`)
			}
		} finally {
			setForgeConnectLoading(false)
		}
	}
	const closeDisconnectModal = useCallback(() => setShowDisconnectIntegrationModal(false), [
		setShowDisconnectIntegrationModal,
	])
	const onDisconnect = async () => {
		setShowDisconnectIntegrationModal(false)
		await invalidateUseCurrentForgeIntegration()
	}
	const onDisconnectForgeClick = async () => {
		setShowDisconnectIntegrationModal(true)
	}
	const onLogInOutForge = async () => {
		setForgeLoginLoading(true)
		const action = hasValidAccess?.hasAccess ? ' logout' : 'login'
		try {
			action === 'login'
				? await forgeIntegrationEnsureUserIsAuthed(project!._id)
				: await deleteCurrentLogin(project!._id)
			await invalidateUseUserHasValidForgeAccess()
		} catch (e) {
			if (e instanceof ForgeOauthError) {
				handleForgeOAuthError(e, notifyError)
			} else {
				notifyError(`An unexpected error occurred during ${action}.`)
			}
		} finally {
			setForgeLoginLoading(false)
		}
	}
	return (
		<>
			{!showDisconnectIntegrationModal ? null : (
				<ForgeIntegrationDisconnectIntegrationModal
					close={closeDisconnectModal}
					onDisconnect={onDisconnect}
					integration={currentForgeIntegration}
				/>
			)}
			{!showCreateIntegrationModal ? null : (
				<ForgeIntegrationCreateModal close={closeCreateIntegrationModal} onIntegration={onIntegrationCreated} />
			)}
			<SRCard width={'450px'} height={{min: '216px'}} border="neutral-1">
				<LoadingWrapper isLoading={isCurrentForgeIntegrationLoading || isHasValidAccessLoading}>
					<Box gap={'small'}>
						<SRHeading level={3}>
							<Box justify={'between'} direction={'row'} align={'center'}>
								<Box direction={'row'} align={'center'} gap={'small'}>
									<ForgeIntegrationAutodeskIcon size={'44px'} />
									<Text size="large">Autodesk</Text>
								</Box>
								<SRTooltip
									tooltipText={'Only Admin users can connect and disconnect projects.'}
									active={!isAdmin}
									width={350}
								>
									{currentForgeIntegration ? (
										<SRSecondaryButton label={'Disconnect'} onClick={onDisconnectForgeClick} disabled={!isAdmin} />
									) : (
										<SRBrandButton
											label={'Connect Project'}
											onClick={onConnectForge}
											disabled={!isAdmin || forgeConnectLoading || forgeLoginLoading || showCreateIntegrationModal}
											icon={
												forgeConnectLoading || showCreateIntegrationModal ? (
													<Spinner size={'xsmall'} color={'darker'} />
												) : undefined
											}
										/>
									)}
								</SRTooltip>
							</Box>
						</SRHeading>
						<Box gap={'xsmall'}>
							<Box gap={'small'} direction={'row'} align={'center'}>
								<SRRadialFillIcon color={currentForgeIntegration ? '#00C781' : '#D3D5D8'} size={'small'} />
								<Text color={currentForgeIntegration ? undefined : 'neutral-1'}>
									{currentForgeIntegration ? 'Connected' : 'Disconnected'}
								</Text>
							</Box>
							{currentForgeIntegration ? (
								<Box>
									<Box direction={'row'}>
										<Box width={'65px'}>Hub:</Box>
										{currentForgeIntegration.forgeHub.name}
									</Box>
									<Box direction={'row'} gap={'xsmall'} align={'center'}>
										<Box width={'65px'}>Project:</Box>
										{getIntegrationIcon(currentForgeIntegration, 'xsmall')}
										{currentForgeIntegration.forgeProject.name}
									</Box>
								</Box>
							) : (
								<ul>
									<li>
										Browse Autodesk BIM 360 or Autodesk Construction Cloud files on {config.sr.companyName} Platform
									</li>
								</ul>
							)}
							<Box direction="row" justify="between" style={{alignItems: 'center'}}>
								{hasValidAccess?.hasAccess ? (
									<>
										<Text>{hasValidAccess.userAccess.profile.forgeEmail}</Text>
										<SRSecondaryButton
											label="Log Out"
											onClick={onLogInOutForge}
											disabled={forgeConnectLoading || showCreateIntegrationModal}
											icon={forgeLoginLoading ? <Spinner size={'xsmall'} color={'darker'} /> : undefined}
										/>
									</>
								) : (
									<>
										<Text color="neutral-1">You are not logged in to Autodesk</Text>
										<SRPrimaryButton
											label="Log In"
											onClick={onLogInOutForge}
											disabled={forgeLoginLoading || forgeConnectLoading || showCreateIntegrationModal}
											icon={forgeLoginLoading ? <Spinner size={'xsmall'} color={'darker'} /> : undefined}
										/>
									</>
								)}
							</Box>
						</Box>
					</Box>
				</LoadingWrapper>
			</SRCard>
		</>
	)
}
