import {Box, Text} from 'grommet'
import React, {useEffect, useState} from 'react'
import {SRMultiSelect} from 'sr-react-commons'
import {ColumnInstance} from 'react-table'
import {ClassificationTableRow, TableColumns} from './UseClassificationsTable'

export function DataTableConfigBox({
	allColumns,
	onTableColumnChange,
	displayedTableColumns,
}: {
	allColumns: ColumnInstance<ClassificationTableRow>[]
	onTableColumnChange: (selectedOptions: TableColumns) => void
	displayedTableColumns: TableColumns
}) {
	const [columnOptions, setColumnOptions] = useState<ColumnInstance<ClassificationTableRow>[]>(allColumns)

	useEffect(() => {
		setColumnOptions(columnOptions.filter(column => column.id !== 'elementId'))
	}, allColumns)

	return (
		<Box direction={'row'} justify={'end'} gap={'xsmall'}>
			<Box gap={'small'}>
				<SRMultiSelect
					borderless
					focusIndicator={false}
					valueFormatter={() => <Text size="small">Display/hide columns</Text>}
					value={displayedTableColumns}
					onChange={onTableColumnChange}
					options={[
						...columnOptions.map(column => ({
							value: column.id,
							label: column.render('Header'),
						})),
						{value: 'issues', label: 'Issues'},
					]}
					size="medium"
				/>
			</Box>
		</Box>
	)
}
