import React from 'react'
import {Image} from 'grommet'
import {Add} from 'grommet-icons'

import {SREmptyView} from 'sr-react-commons'

type EmptyViewProps = {
	onAction: () => void
}

export const IssueCardListEmptyView = ({onAction}: EmptyViewProps) => {
	return (
		<SREmptyView
			onAction={onAction}
			actionText="Create Issue"
			asset={<Image src="/images/empty-states/empty-issues.svg" />}
			title="No issues to display"
			buttonIcon={<Add size="small" />}
			message={`There are currently no issues to display.\nImprove collaboration and communication within your team by creating new issues today.`}
			buttonTextSize="large"
			buttonReverse={true}
		/>
	)
}
