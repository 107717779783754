import _ from 'lodash'
import {FiltersEntity} from '../FilterPresets/entities/filter-preset.entities'
import {ClassificationEntity} from './classification.entities'

export function getDisplayValueForClassificationStatus(status: ClassificationEntity['status']): string {
	return _.capitalize(status).replace('_', ' ')
}

export function getClassificationStatusWithoutUnderConstruction(
	status: ClassificationEntity['status'],
): ClassificationEntity['status'] {
	return status === 'under_construction' ? 'deviated' : status
}

export function translateStatusFiltersWhenUnderConstructionDisabled(
	statuses: NonNullable<FiltersEntity['statuses']>,
): NonNullable<FiltersEntity['statuses']> {
	return statuses.includes('deviated') ? [...statuses, 'under_construction'] : statuses
}

export function translateSortingWhenUnderConstructionDisabled(sortField: string): string {
	return sortField === 'status' ? 'statusWithoutUnderConstruction' : sortField
}
