import {createBrowserHistory, History, LocationDescriptor, LocationState} from 'history'
import {MAINTENANCE_MODE_QS} from '../api/maintenance-mode.token'
import {FEATURE_QS_PREFIX} from '../features/FeatureFlags/FeatureFlagsProvider'

/**
 * The history object can preserve certain query string values on navigation.
 * Fill this constant list with the querystring key patterns you want to preserve.
 */
const PERMANENT_QS_PATTERNS = [FEATURE_QS_PREFIX, MAINTENANCE_MODE_QS]
const setupHistory = (history: History) => {
	const basePush = history.push
	const baseReplace = history.replace
	const toDescriptor = (path: string, state: LocationState) => {
		const url = new URL(path, document.location.origin)
		return {pathname: url.pathname, origin: url.origin, search: url.search, state}
	}
	const permanentQS = (path: LocationDescriptor, state?: LocationState) => {
		const newLocation: LocationDescriptor = typeof path === 'string' ? toDescriptor(path, state) : path
		const qs = new URLSearchParams(newLocation.search)
		new URLSearchParams(history.location.search).forEach((value, key) => {
			PERMANENT_QS_PATTERNS.some(pattern => key.includes(pattern)) && !qs.has(key) && qs.append(key, value)
		})
		newLocation.search = qs.entries() ? qs.toString() : newLocation.search
		return newLocation
	}
	history.push = (path: LocationDescriptor, state?: LocationState) => basePush.call(history, permanentQS(path, state))
	history.replace = (path: LocationDescriptor, state?: LocationState) =>
		baseReplace.call(history, permanentQS(path, state))
	return history
}

export const history = setupHistory(createBrowserHistory())

export const goToFn = (url: string) => () => goTo(url)

export const goTo = (url: string) => history.push(url)
