import {useEffect, useRef} from 'react'
import {trackLogRocketEvent} from '../features/Tracking/logrocket'

export function useTrackTimeDisplayed(elementName: string) {
	const timeDisplayedRef = useRef(new Date().getTime())

	useEffect(() => {
		const timeDisplayStarted = timeDisplayedRef.current
		return () => {
			const timeElapsed = new Date().getTime() - timeDisplayStarted
			trackLogRocketEvent(`${elementName}Displayed`, {time: timeElapsed})
		}
	}, [elementName, timeDisplayedRef])
}
