import _ from 'lodash'
import {format, isThisYear} from 'date-fns'
import {fetchClassificationsByForgeIds} from '../../../features/Classification/api/classification.api'
import {store} from '../../../store/store'
import {selectorClassificationState} from '../../../selectors/classification.selectors'
import {
	getClassificationStatusWithoutUnderConstruction,
	getDisplayValueForClassificationStatus,
} from '../../../features/Classification/classification.helpers'
import {SRExtension} from '../SRExtension'
import {config} from '../../../config'
import {ClassificationEntity} from '../../../features/Classification/classification.entities'

const Autodesk = window.Autodesk
export const CUSTOM_PROPERTY_PANEL_EXTENSION = 'Viewing.Extension.CustomPropertyPanel'

export default class CustomPropertyPanelExtension extends SRExtension {
	constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
		super(viewer, options)
		this.viewer = viewer
	}

	// @ts-ignore
	load() {
		this.viewer.setPropertyPanel(new CustomPropertyPanel(this.viewer, this))
		return true
	}

	unload() {
		this.viewer.setPropertyPanel(new Autodesk.Viewing.Extensions.ViewerPropertyPanel(this.viewer))
		return true
	}
}

interface PropertyPanelProperty {
	displayName: string
	displayValue: string
	hidden: boolean
	displayCategory: string
}

class CustomPropertyPanel extends Autodesk.Viewing.Extensions.ViewerPropertyPanel {
	constructor(viewer: Autodesk.Viewing.GuiViewer3D, private extension: CustomPropertyPanelExtension) {
		super(viewer)
		this.container.style.width = '400px'
		this.addVisibilityListener(this.updatePropertyPanel.bind(this))
		viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, () => {
			if (this.container) {
				this.updatePropertyPanel(this.isVisible())
			}
		})
	}

	async updatePropertyPanel(visible: boolean) {
		const dbId = Number(this.currentNodeIds[0])
		if (!dbId || !visible) {
			return
		}

		const {selectedAnalysisView, analysisVersionMap} = selectorClassificationState(store.getState())
		const elementClassifications = await fetchClassificationsByForgeIds(
			selectedAnalysisView!,
			[dbId],
			analysisVersionMap,
		)
		const baseProperties: PropertyPanelProperty[] = [
			{
				displayName: 'dbId',
				displayValue: dbId.toString(),
				displayCategory: `${config.sr.companyName} Properties`,
				hidden: false,
			},
		]
		const sortedClassifications = sortClassificationsByScanAndCreationDate(elementClassifications)
		const classificationsWithMods = updateClassificationsWithModifications(sortedClassifications)
		const classificationProperties = classificationsWithMods.flatMap((el, index) => {
			return prepareClassificationProperties(el, this.extension.featureEnabled('enableUnderConstruction')).map(
				property => {
					return {
						...property,
						displayCategory: `${index + 1}: ${config.sr.companyName} ${el.analysis.name} - ${el.analysis.id}`,
						hidden: false,
					}
				},
			)
		})

		const allProperties = [...baseProperties, ...classificationProperties]
		allProperties.forEach(el => {
			this.addProperty(el.displayName, el.displayValue, el.displayCategory)
		})
	}

	displayProperty(property: {name: string}, parent: HTMLElement, options: Autodesk.Viewing.UI.DisplayCategoryOptions) {
		const elements = super.displayProperty(property, parent, options)
		if (property.name === 'Deviation tolerance') {
			const [labelElement] = elements
			labelElement.title =
				'The tolerance we apply to the analysis to determine if the element should be classified as verified, deviated or missing.'
		}
		if (property.name === 'Deviation magnitude') {
			const [labelElement] = elements
			labelElement.title =
				'Deviation magnitude is an indication of the maximum deviation of an element, remember this is only an indicator and results can vary, it should be used with caution. Be aware that the Deviation magnitude can also differ from allocated tolerance.'
		}
		return elements
	}
}

function metersToMillimeters(value: number) {
	return Number((value * 1000).toFixed(0))
}

function formatDate(date: string | Date) {
	return format(new Date(date), isThisYear(new Date(date)) ? 'MMM d, h:mm a' : 'MMM d yyyy, h:mm a')
}

function prepareClassificationProperties(classification: ClassificationEntity, enableUnderConstruction: boolean) {
	let status = classification.status
	if (!enableUnderConstruction) {
		status = getClassificationStatusWithoutUnderConstruction(status)
	}
	return [
		{displayName: 'Classification Type', displayValue: _.capitalize(classification.classificationType)},
		{displayName: 'Status', displayValue: getDisplayValueForClassificationStatus(status)},
		...(classification.classificationType === 'automatic'
			? prepareAutomaticClassificationProperties(classification)
			: prepareManualClassificationProperties(classification)),
	]
}

function prepareAutomaticClassificationProperties(classification: ClassificationEntity) {
	return [
		...(['verified', 'deviated', 'under_construction'].includes(classification.status)
			? [
					{
						displayName: 'Deviation magnitude',
						displayValue: metersToMillimeters(classification.magnitudeDisplay) + ' mm',
					},
			  ]
			: []),
		{
			displayName: 'Deviation tolerance',
			displayValue: metersToMillimeters(classification.tolerance) + ' mm',
		},
		{displayName: 'Scan date', displayValue: formatDate(classification.scannedDate)},
	]
}

function prepareManualClassificationProperties(classification: ClassificationEntity) {
	return [{displayName: 'Reclassification date', displayValue: formatDate(classification.createdDate)}]
}

function sortClassificationsByScanAndCreationDate(classifications: ClassificationEntity[]) {
	return classifications.sort((a, b) => {
		const scanDateSort = a.scannedDate.localeCompare(b.scannedDate)
		if (scanDateSort !== 0) {
			return scanDateSort
		} else {
			return a.createdDate.localeCompare(b.createdDate)
		}
	})
}

function updateClassificationsWithModifications(classifications: ClassificationEntity[]) {
	const modClassifications: ClassificationEntity[] = []
	classifications.forEach(c => c.modificationHistory.forEach(mod => modClassifications.push({...c, ...mod})))
	return modClassifications
}

Autodesk.Viewing.theExtensionManager.registerExtension(CUSTOM_PROPERTY_PANEL_EXTENSION, CustomPropertyPanelExtension)
