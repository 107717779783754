import {Column} from 'react-table'
import {BookmarkTableIcon} from './BookmarkTableIcon'
import {Box, Text} from 'grommet'
import {MagnitudeSign} from '../../reducers/classification'
import React from 'react'
import {ClassificationTableRow} from './UseClassificationsTable'
import {CheckIconInHeader} from './CheckIconInHeader'
import _ from 'lodash'
import {BookmarkTableHeaderIcon} from './BookmarkTableHeaderIcon'

export interface DataTableColumnOptions {
	displayMagnitudeByAxis: boolean
	enableEOI: boolean
}

export const EOI_COLUMN: Column<ClassificationTableRow>[] = [
	{
		Header: <BookmarkTableHeaderIcon />,
		width: 18,
		id: 'isInteresting',
		accessor: 'isInteresting',
		Cell: ({value, row}) => <BookmarkTableIcon isInteresting={value} row={row} />,
	},
]

const MAGNITUDE_BY_AXIS_COLUMNS: Column<ClassificationTableRow>[] = [
	{
		Header: 'Description',
		width: 140,
		id: 'description',
		accessor: 'description',
	},
	{
		Header: 'D(x,y)',
		width: 55,
		id: 'magnitudeXYDisplay',
		accessor: 'magnitudeXY',
		sortType: 'basic',
		Cell: ({value}: {value: number}) => (value * 1000).toFixed(0),
	},
	{
		Header: 'H(z)',
		width: 50,
		id: 'magnitudeZDisplay',
		accessor: 'magnitudeZ',
		sortType: 'basic',
		Cell: props => {
			return (
				<Box direction={'row'}>
					<Box flex={'grow'}>
						<Text size={'small'} alignSelf={'end'}>
							{(props.value * 1000).toFixed(0)}
						</Text>
					</Box>
					<Box width={'20px'}>
						<Text size={'small'} textAlign={'center'}>
							{props.value !== 0 && (props.row.original.magnitudeZSign === MagnitudeSign.POSITIVE ? '(+)' : '(-)')}
						</Text>
					</Box>
				</Box>
			)
		},
	},
]

const DATA_TABLE_COLUMNS: Column<ClassificationTableRow>[] = [
	{
		Header: <CheckIconInHeader />,
		width: 20,
		id: 'approved',
		accessor: 'approved',
	},
	{
		Header: 'Status',
		width: 70,
		id: 'status',
		accessor: 'status',
	},
	{
		Header: 'Floor',
		width: 80,
		id: 'mappedFloor',
		accessor: 'mappedFloors',
	},
	{
		Header: 'Type',
		id: 'mappedElementType',
		width: 70,
		accessor: 'mappedElementType',
	},
	{
		Header: 'Name',
		width: 80,
		id: 'element.name',
		accessor: 'name',
	},
	{
		Header: 'GlobalId',
		width: 80,
		id: 'externalId',
		accessor: 'externalId',
	},
	{
		Header: 'M ',
		width: 50,
		id: 'magnitudeDisplay',
		accessor: 'magnitude',
		Cell: ({value}: {value: number}) => {
			const magnitudeInMM = (value * 1000).toFixed(0)
			return (
				<Text size="small" title={`${magnitudeInMM} mm`}>
					{magnitudeInMM}
				</Text>
			)
		},
	},
	// Is not displayed, needed for accessing the value
	{id: 'elementId', accessor: 'elementId'},
]

const OPTIONAL_COLUMNS = {
	displayMagnitudeByAxis: MAGNITUDE_BY_AXIS_COLUMNS,
	enableEOI: EOI_COLUMN,
}

function getOptionalColumns(options: DataTableColumnOptions) {
	const columns: Column<ClassificationTableRow>[] = []
	_.forIn(options, (v, k) => {
		v && columns.push(...OPTIONAL_COLUMNS[k as keyof DataTableColumnOptions])
	})
	return columns
}

export function getDataTableColumns(options: DataTableColumnOptions) {
	return DATA_TABLE_COLUMNS.concat(getOptionalColumns(options))
}
