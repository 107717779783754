import {useDropzone} from 'react-dropzone'
import {Box, Text} from 'grommet'
import React from 'react'
import {SRSecondaryButton} from 'sr-react-commons'

export function FileDropZone(props: {
	acceptedFileTypes?: string[] | null
	text: JSX.Element
	onDropAccepted: (files: File[]) => void
	onDropRejected: (files: File[]) => void
}) {
	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDropAccepted: props.onDropAccepted,
		onDropRejected: fileRejections => props.onDropRejected(fileRejections.map(fileRejection => fileRejection.file)),
		accept: props.acceptedFileTypes || undefined,
	})
	const {onClick, ...rootProps} = getRootProps()
	return (
		<Box
			border={{
				style: 'dashed',
				size: '2px',
				color: 'neutral-1',
			}}
			background={isDragActive ? 'neutral-2' : undefined}
			pad="medium"
			align="center"
			round="xsmall"
			justify="center"
			fill
			{...rootProps}
		>
			<input id="file-upload" {...getInputProps()} />
			<Box align={'center'} gap="small">
				<label htmlFor="file-upload">
					<Text weight="bold" size={'small'} textAlign={'center'}>
						{props.text}
					</Text>
				</label>
				<SRSecondaryButton size={'small'} onClick={onClick} label={'Search files'} />
			</Box>
		</Box>
	)
}
