import {PermissionsEnum} from '../../../entities/auth'
import {ManagementDashboardPageMeta} from '../core/management-dashboard-page.utils'

export const INTEGRATIONS_PAGE_META: ManagementDashboardPageMeta = {
	name: 'Integrations',
	permission: PermissionsEnum.ADMIN,
	// TODO we need something more elaborate here when we have multiple integrations and
	// the page should be accessible if any of the feature flags is enabled
	feature: 'forgeIntegration',
} as const
