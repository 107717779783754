export {reducer as GlobalNotificationsReducer, createAction as createGlobalNotificationsAction}

export interface Notification {
	level: 'INFO' | 'ALERT' | 'ERROR'
	message: string
}

interface QueueAction {
	type: 'queueGlobalNotification'
	notification: Notification
}

interface DismissAction {
	type:
		| 'dismissCurrentGlobalNotification'
		| 'dismissAllInfoGlobalNotifications'
		| 'dismissaAllAlertGlobalNotifications'
		| 'dismissAllErrorGlobalNotifications'
}

const createAction = {
	error: function (message: string): QueueAction {
		return {type: 'queueGlobalNotification', notification: {level: 'ERROR', message}}
	},
	alert: function (message: string): QueueAction {
		return {type: 'queueGlobalNotification', notification: {level: 'ALERT', message}}
	},
	info: function (message: string): QueueAction {
		return {type: 'queueGlobalNotification', notification: {level: 'INFO', message}}
	},
	dismissCurrent: function (): DismissAction {
		return {type: 'dismissCurrentGlobalNotification'}
	},
	dismissAllInfos: function (): DismissAction {
		return {type: 'dismissAllInfoGlobalNotifications'}
	},
	dismissAllAlerts: function (): DismissAction {
		return {type: 'dismissaAllAlertGlobalNotifications'}
	},
	dismissAllErrors: function (): DismissAction {
		return {type: 'dismissAllErrorGlobalNotifications'}
	},
}

interface State {
	current: Notification | null
	info: Notification[]
	alert: Notification[]
	error: Notification[]
}

const initialState: State = {
	current: null,
	info: [],
	alert: [],
	error: [],
}

function reducer(state: State = initialState, action: QueueAction | DismissAction) {
	const newState = {
		current: state.current ? state.current : null,
		info: [...state.info],
		alert: [...state.alert],
		error: [...state.error],
	}

	switch (action.type) {
		case 'queueGlobalNotification':
			doQueue(newState, action.notification)
			break
		case 'dismissCurrentGlobalNotification':
			doDismissCurrent(newState)
			break
		case 'dismissAllInfoGlobalNotifications':
			doDismissAllInfos(newState)
			break
		case 'dismissaAllAlertGlobalNotifications':
			doDismissAllAlerts(newState)
			break
		case 'dismissAllErrorGlobalNotifications':
			doDismissAllErrors(newState)
			break
		default:
			return state
	}

	setCurrent(newState)

	return newState
}

function setCurrent(newState: State): void {
	if (newState.current === null) {
		if (newState.error.length > 0) {
			newState.current = newState.error.shift()!
		} else if (newState.alert.length > 0) {
			newState.current = newState.alert.shift()!
		} else if (newState.info.length > 0) {
			newState.current = newState.info.shift()!
		}
	} else if (newState.current.level === 'ALERT') {
		if (newState.error.length > 0) {
			doPutBackCurrent(newState)
			newState.current = newState.error.shift()!
		}
	} else if (newState.current.level === 'INFO') {
		if (newState.error.length > 0) {
			doPutBackCurrent(newState)
			newState.current = newState.error.shift()!
		} else if (newState.alert.length > 0) {
			doPutBackCurrent(newState)
			newState.current = newState.alert.shift()!
		}
	}
}

function doPutBackCurrent(newState: State): void {
	if (newState.current) {
		const {level} = newState.current
		switch (level) {
			case 'INFO':
				newState.info.unshift(newState.current)
				break
			case 'ALERT':
				newState.alert.unshift(newState.current)
				break
			case 'ERROR':
				newState.error.unshift(newState.current)
				break
		}
		newState.current = null
	}
}

function doQueue(newState: State, notification: Notification): void {
	const {level, message} = notification
	switch (level) {
		case 'INFO':
			newState.info.push({level, message})
			break
		case 'ALERT':
			newState.alert.push({level, message})
			break
		case 'ERROR':
			newState.error.push({level, message})
			break
	}
}

function doDismissCurrent(newState: State): void {
	newState.current = null
}

function doDismissAllInfos(newState: State): void {
	newState.info = []
}

function doDismissAllAlerts(newState: State): void {
	newState.alert = []
}

function doDismissAllErrors(newState: State): void {
	newState.alert = []
}
