import {config} from '../../../config'

export enum ForgeOauthErrorTypes {
	'access_denied',
	'window_closed',
	'unknown_error',
	'code_exchange_failed',
	'unable_access_project',
}

function getErrorMessageFromForgeErrorKey(forgeErrorKey: ForgeOauthErrorTypes) {
	switch (forgeErrorKey) {
		case ForgeOauthErrorTypes.code_exchange_failed:
			return `An unexpected error occurred while trying to connect to your Autodesk project. Please try to connect again. If the problem persist contact ${config.sr.companyName} for support.`
		case ForgeOauthErrorTypes.window_closed:
			return `You have closed the Forge Sign-in window. If you want to integrate your Autodesk account with ${config.sr.companyName}, please grant access.`
		case ForgeOauthErrorTypes.unknown_error:
			return `An unexpected error occurred. We apologize for the inconvenience. If the problem persists, please contact ${config.sr.companyName} for support.`
		case ForgeOauthErrorTypes.access_denied:
			return `You have canceled the Autodesk authorization. Please authorize the application to integrate your Autodesk account with ${config.sr.companyName}.`
		case ForgeOauthErrorTypes.unable_access_project:
			return `You are not authorized to access this project. Please check that you have signed in with an Autodesk account that has access to this project, or request access to Autodesk account administrator.`
		default:
			throw new Error(`Unknown error code: ${forgeErrorKey}`)
	}
}

export class ForgeOauthError extends Error {
	errorKey: ForgeOauthErrorTypes

	constructor(errorCode: string | ForgeOauthErrorTypes, public debugInfo?: string) {
		super()
		console.log(this.debugInfo)
		if (typeof errorCode === 'string') {
			switch (errorCode) {
				case 'access_denied':
					this.errorKey = ForgeOauthErrorTypes.access_denied
					break
				default:
					this.errorKey = ForgeOauthErrorTypes.unknown_error
					break
			}
		} else {
			this.errorKey = errorCode
		}

		this.message = getErrorMessageFromForgeErrorKey(this.errorKey)
	}
}

export function handleForgeOAuthError(e: ForgeOauthError, notify: (message: string) => void): void {
	// Don't display an error when the error is window closed
	if (e.errorKey !== ForgeOauthErrorTypes.window_closed) {
		notify(e.message)
	}
}
