import React, {useContext} from 'react'
import {FileUploaderWorker} from '../hooks/FIleUploaderWorker.interface'
import {useFileUploaderWorker} from '../hooks/useFileUploaderWorker'

const FileUploaderWorkerContext = React.createContext<ReturnType<typeof useFileUploaderWorker> | null>(null)

export function FileUploaderWorkerProvider(props: {worker: FileUploaderWorker; children: JSX.Element | JSX.Element[]}) {
	return <FileUploaderWorkerContext.Provider value={props.worker}>{props.children}</FileUploaderWorkerContext.Provider>
}

export function useFileUploaderWorkerContext() {
	const context = useContext(FileUploaderWorkerContext)
	if (!context) {
		throw Error(
			"'useFileUploaderWorkerContext()' can only be used in a child component of a '<FileUploaderWorkerContext />'",
		)
	}
	return context
}
