import {ToggleAssetsModeAction} from '../../../../actions/viewer'
import {Section} from '../../../../reducers/classification'
import {SRFloatingHeaderOverlay} from 'sr-react-commons'
import {Box} from 'grommet'
import {AddToIssueBox} from '../../../AddToIssueButton/AddToIssueBox'
import {ElementSectionControlsWrapper} from './ElementSectionControlsWrapper'
import React from 'react'
import {AssetsModeSwitchButton} from '../../../Assets/AssetModeSwithButton'
import {ClassificationEntity} from '../../../../features/Classification/classification.entities'

export function ElementSectionsPanelHeader(props: {
	assetsMode: 'split' | 'popup'
	onToggleAssetsMode: () => ToggleAssetsModeAction
	currentSection: Section | null
	classification: ClassificationEntity | null
}) {
	return (
		<SRFloatingHeaderOverlay zIndex={101} dataHideOnScreenshot={true}>
			<Box direction={'row'} fill justify="between">
				{props.assetsMode === 'popup' ? (
					<Box direction={'row'} gap={'xsmall'}>
						<AssetsModeSwitchButton />
						{props.currentSection && props.classification && (
							<AddToIssueBox type={'section'} domSelector={'#element-sections-container'} />
						)}
					</Box>
				) : (
					<AssetsModeSwitchButton />
				)}
				<ElementSectionControlsWrapper />
			</Box>
		</SRFloatingHeaderOverlay>
	)
}
