import {getIdToken} from './getIdToken.api'
import {getMaintenanceMode} from './maintenance-mode.token'
import {store} from '../store/store'
import {doSetMaintenanceMode} from '../actions/viewer'
import {buildRequestInstance} from 'sr-request-commons'

export function buildAxios(url: string, getToken = getIdToken) {
	return buildRequestInstance(url, getToken, getMaintenanceMode, () => {
		store.dispatch(doSetMaintenanceMode(true))
	})
}
