export function getBestReferenceScale(maxWidth: number, scale: number, zoom: number) {
	let inMeters = maxWidth * (scale / zoom)
	let [coefficientString, exponentString] = inMeters.toExponential().split('e')
	const coefficient = parseFloat(coefficientString)
	const exponent = parseInt(exponentString)
	const coefficientCandidates = [1, 2, 5]
	// get closest coefficient candidate that is smaller than the actual coefficient
	const bestCoefficient = coefficientCandidates.filter(f => f <= coefficient).reverse()[0]
	return bestCoefficient * Math.pow(10, exponent)
}

export function getDistance([startX, startY]: [number, number], [endX, endY]: [number, number]) {
	return Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2))
}
