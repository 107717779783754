import {srApiV3} from '../api/base.api.v3'
import {UserProject} from '../reducers/userProfile'
import {v4 as uuidv4} from 'uuid'

export type SignResponse = {
	url: string
	key: string
}

export function getS3KeyForPath(tenantId: string, imagePath: string) {
	return tenantId.replace('|', '/') + '/' + imagePath
}

export enum S3Operation {
	GET_PRIVATE = 'sign-asset',
	GET_PUBLIC = 'sign-asset-public',
	PUT_PUBLIC = 'sign-asset-write-public',
}

async function signS3Asset(key: string, operation: S3Operation): Promise<SignResponse> {
	return srApiV3.post(`auth/${operation}/${key}`).then(r => r.data)
}

async function signS3AssetImage(key: string, operation: S3Operation, contentType: string): Promise<SignResponse> {
	return srApiV3.post(`auth/${operation}/${key}?contentType=${contentType}`).then(r => r.data)
}

export async function fetchS3FileRaw(key: string, operation = S3Operation.GET_PRIVATE): Promise<Response> {
	const signResponse = await signS3Asset(key, operation)
	return fetch(signResponse.url)
}

export async function putS3File(key: string, body: Blob): Promise<SignResponse> {
	const signResponse = await signS3Asset(key, S3Operation.PUT_PUBLIC)
	await fetch(signResponse.url, {method: 'PUT', body})
	return signResponse
}

export async function putS3Image(key: string, body: Blob, contentType: string): Promise<string> {
	const signResponse = await signS3AssetImage(key, S3Operation.PUT_PUBLIC, contentType)
	const fetchResponse = await fetch(signResponse.url, {method: 'PUT', body})
	if (fetchResponse.status !== 200) {
		throw new Error('Image upload failed for Quality Report' + fetchResponse.status)
	}
	//TODO should unify with Issue Assets putS3File
	const url = new URL(signResponse.url)
	return url.pathname.slice(1)
}

export async function fetchS3File(key: string): Promise<string> {
	const response = await fetchS3FileRaw(key)
	return response.text()
}

function getBase64ImageTypeByKey(key: string) {
	const fileExtensionPointIndex = key.lastIndexOf('.')
	if (fileExtensionPointIndex < 0) {
		throw new Error('Key does not contain file extension: ' + key)
	}
	const extension = key.substring(fileExtensionPointIndex + 1)
	switch (extension) {
		case 'svg':
			return 'svg+xml'
		case 'png':
			return 'png'
		case 'jpeg':
		case 'jpg':
			return 'jpg'
		default:
			throw new Error('Unsupported image extension: ' + extension)
	}
}

export async function loadS3Image(key: string, operation = S3Operation.GET_PRIVATE): Promise<string> {
	const base64ImageFileType = getBase64ImageTypeByKey(key)
	const response = await fetchS3FileRaw(key, operation)
	const buffer = await response.arrayBuffer()
	return arrayBufferToBase64(buffer, base64ImageFileType)
}

function arrayBufferToBase64(buffer: ArrayBuffer, fileType: string = 'png') {
	const base64Flag = `data:image/${fileType};base64,`
	return base64Flag + Buffer.from(buffer).toString('base64')
}

async function dataURItoBlob(dataURI: string, fileType: string) {
	const binary = atob(dataURI.split(',')[1])
	const array = []
	for (let i = 0; i < binary.length; i++) {
		array.push(binary.charCodeAt(i))
	}
	return new Blob([new Uint8Array(array)], {type: fileType})
}

export async function postImageToS3(project: UserProject, imageData: string, file: File): Promise<string> {
	const fileNameAndExtension = file.name.split('.')
	const extension = fileNameAndExtension[fileNameAndExtension.length - 1]
	const newFileName = `temp/${uuidv4()}.${extension}`
	const key = getS3KeyForPath(project.tenantId, newFileName)
	const blob = dataURItoBlob(imageData, file.type)
	return putS3Image(key, await blob, file.type)
}
