import ElementSectionsPanel from './components/ElementSectionsPanel'
import VerticalToolbarExtension from '../Viewing.Extension.VerticalToolbar'
import {VERTICAL_CONTROL_GROUP} from '../Viewing.Extension.VerticalToolbar/Viewing.Extension.VerticalToolbar'
import {getVerticalToolBar} from '../../Viewer/Viewer-helper'
import {store} from '../../../store/store'
import {sectionSlice} from './StateManagement/section.slice'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {SRExtension} from '../SRExtension'

export const ELEMENT_SECTIONS_EXTENSION = 'Viewing.Extension.ElementSections'

export default class ElementSectionsExtension extends SRExtension {
	private panel: ElementSectionsPanel | undefined
	private subToolbar: Autodesk.Viewing.UI.ControlGroup | undefined
	private toolbarButton: Autodesk.Viewing.UI.Button | undefined

	constructor(viewer: Autodesk.Viewing.Viewer3D, options: any) {
		super(viewer as Autodesk.Viewing.GuiViewer3D, options)
	}

	setVisible(visible: boolean) {
		this.panel?.setVisible(visible)
	}

	private isOpened = () => store.getState().section.isOpened

	private isSplitMode = () => {
		const {assetsMode} = selectorViewerProperties(store.getState())
		return assetsMode === 'split'
	}

	private onVisibilityChange = () => {
		if (this.isOpened() && !this.isSplitMode() && !this.panel!.isVisible()) {
			this.toggleExtension(false)
		}
		this.toggleButton(this.isOpened())
	}

	getNames() {
		// Override required for registering extension as tool
		return [ELEMENT_SECTIONS_EXTENSION]
	}

	load() {
		if (
			this.viewer.isExtensionLoaded('Viewing.Extension.VerticalToolbar') &&
			(this.viewer.getExtension('Viewing.Extension.VerticalToolbar') as VerticalToolbarExtension).SRVerticalToolbar
		) {
			this.createUI()
			// Register and activate extension itself as a tool in order to handle mouse events
			this.viewer.toolController.registerTool(this)
			this.viewer.toolController.activateTool(ELEMENT_SECTIONS_EXTENSION)
			return true
		} else {
			console.error('Cannot load extension because the vertical toolbar has not been loaded before.')
			return false
		}
	}

	unload() {
		const verticalToolBar = this.viewer.getExtension('Viewing.Extension.VerticalToolbar') as VerticalToolbarExtension
		if (verticalToolBar && this.subToolbar) {
			verticalToolBar.SRVerticalToolbar.removeControl(this.subToolbar)
		}
		this.viewer.toolController.deactivateTool(ELEMENT_SECTIONS_EXTENSION)
		this.viewer.toolController.deregisterTool(this)
		return true
	}

	private createUI() {
		// Toolbar button UI bindings
		const elementSectionsButton = new Autodesk.Viewing.UI.Button('StaticPhotoButton')
		elementSectionsButton.addClass('SectionsToolbarButton')
		elementSectionsButton.onClick = () => this.toggleExtension(!this.isOpened())
		this.subToolbar =
			(getVerticalToolBar(this.viewer).getControl(VERTICAL_CONTROL_GROUP) as Autodesk.Viewing.UI.ControlGroup) ||
			new Autodesk.Viewing.UI.ControlGroup(VERTICAL_CONTROL_GROUP)
		this.subToolbar.addControl(elementSectionsButton, {index: 0})
		this.toolbarButton = elementSectionsButton
		this.toolbarButton?.setToolTip('Display deviations in section cuts')
		// initialize panel
		this.panel = new ElementSectionsPanel(this.viewer.container as HTMLElement)
		this.panel.addVisibilityListener(this.onVisibilityChange)
		this.toggleExtension(false)
	}

	private toggleExtension(active: boolean) {
		store.dispatch(sectionSlice.actions.setIsOpened(active))
	}

	private toggleButton(willBeActive: boolean) {
		if (!this.toolbarButton) return
		if (willBeActive) {
			this.toolbarButton.addClass('active')
		} else {
			this.toolbarButton.removeClass('active')
		}
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(ELEMENT_SECTIONS_EXTENSION, ElementSectionsExtension)
