import {v4 as uuidv4} from 'uuid'
import _ from 'lodash'
import {Opaque} from 'types/base.types'

import {getS3KeyForPath, putS3File} from '../../../utilities/storageUtilities'
import {srProjectApiV3} from '../../../api/project.api.v3'
import {PaginationResponse} from '../../../api/api.types'
import {Id} from '../../../entities/value-objects'
import {IssueComment} from '../issue-comment.entities'
import {UserProject} from '../../../reducers/userProfile'
import {ElementEntity} from '../../../entities/element.entities'
import {ViewportDTO} from '../../../utilities/viewerUtilities'
import {Asset, IssueEntity, Priority} from '../issue.entities'
import {IssueFilter} from '../issue.slice'
import {addDays} from '../issueUtils'

const filterByIssueStatus = (closedStatus: boolean) => {
	return closedStatus ? ['Open Issue', 'Under Inspection', 'Closed'] : ['Open Issue', 'Under Inspection']
}

function buildRsqlInFilter(field: keyof IssueEntity, values: string[]): string {
	return `${field}=in=(${values.join(',')})`
}

function issueFilterToQueryParams(filter: IssueFilter): string {
	return [
		filter.statuses ? buildRsqlInFilter('issueStatus', filter.statuses) : undefined,
		filter.assignedUsers
			? buildRsqlInFilter(
					'assignedUser',
					filter.assignedUsers.map(user => (user === 'unassigned' ? 'null' : user)),
			  )
			: undefined,
		filter.analysisViews ? buildRsqlInFilter('analysisView', filter.analysisViews) : undefined,
		filter.deadlineBefore ? `deadline=le=${addDays(filter.deadlineBefore, 1).toISOString()}` : undefined,
		filter.deadlineAfter ? `deadline=ge=${filter.deadlineAfter.toISOString()}` : undefined,
		filter.createdBefore ? `createdDate=le=${addDays(filter.createdBefore, 1).toISOString()}` : undefined,
		filter.createdAfter ? `createdDate=ge=${filter.createdAfter.toISOString()}` : undefined,
		filter.priorities
			? buildRsqlInFilter(
					'priority',
					filter.priorities.map(priority => (priority === 'Unassigned' ? 'null' : priority)),
			  )
			: undefined,
		filter.location ? `location=~"${filter.location.trim()}"=si` : undefined,
		filter.createdBy
			? buildRsqlInFilter(
					'user',
					filter.createdBy.map(user => user),
			  )
			: undefined,
	]
		.filter(filters => filters !== undefined)
		.join(';')
}

export const fetchIssuesByPage = async (
	skip: number,
	limit: number,
	filter: IssueFilter,
	sort: string = '-createdDate',
): Promise<PaginationResponse<IssueEntity>> => {
	const params = {
		skip,
		limit,
		sort,
		filter: issueFilterToQueryParams(filter),
		...(filter.searchText && filter.searchText.length > 0 ? {searchText: filter.searchText.trim()} : {}),
	}
	return (await srProjectApiV3.get('issues', {params})).data
}

export const fetchIssuesByGlobalIds = async (
	externalIds: Id[],
	closedStatus: boolean = false,
): Promise<IssueEntity[]> => {
	const filters = [
		buildRsqlInFilter('issueStatus', filterByIssueStatus(closedStatus)),
		buildRsqlInFilter(
			'externalId',
			externalIds.map(id => JSON.stringify(id)),
		),
	]
	const params = {skip: 0, limit: 10000, filter: filters.join(';')}
	return (await srProjectApiV3.get('issues', {params})).data.result
}

export const fetchIssuesByForgeObjectIds = async (
	forgeObjectIds: IssueEntity['forgeObjectId'] | false,
	closedStatus: boolean = false,
): Promise<IssueEntity[]> => {
	const filters = [
		buildRsqlInFilter('issueStatus', filterByIssueStatus(closedStatus)),
		forgeObjectIds ? buildRsqlInFilter('forgeObjectId', forgeObjectIds.map(String)) : '',
	]
	const params = {skip: 0, limit: 10000, filter: filters.join(';'), sort: '-createdDate'}
	return (await srProjectApiV3.get('issues', {params})).data.result
}

export const fetchIssueById = async (id: string): Promise<IssueEntity> =>
	(await srProjectApiV3.get(`issues/${id}`)).data

export const fetchIssueElements = async (
	forgeObjectId: number[],
	model: string,
): Promise<PaginationResponse<ElementEntity>> => {
	const params = {
		filter: `${buildRsqlInFilter('forgeObjectId', forgeObjectId.map(String))};model=="${model}"`,
		limit: forgeObjectId.length,
	}
	return (await srProjectApiV3.get(`elements`, {params})).data
}

export type IssueCustomPropertiesName = Opaque<'IssueCustomPropertiesName', string>

export type IssueCustomPropertiesValue = Opaque<'IssueCustomPropertiesValue', string>

export type IssueCustomPropConfigField = {
	uuid: string
	name: IssueCustomPropertiesName
}

export type IssueCustomProperties = {
	[uuid: string]: {
		name: IssueCustomPropertiesName
		value: IssueCustomPropertiesValue
	}
}

export type IssueConfig = {
	customProps: IssueCustomPropConfigField[]
}

export type UpdateIssueDTO = {
	_id: string
	body: {
		location: string
		diagnosis: string
		issueStatus: string
		externalId: string[]
		forgeObjectId: number[]
		assignedUser: string | null
		deadline: Date | null
		priority: Priority | null
		customProperties: IssueCustomProperties
	}
}

export const putIssue = async (update: UpdateIssueDTO): Promise<IssueEntity> =>
	(await srProjectApiV3.put('issues/' + update._id, update.body)).data

export type CreateIssueDTO = {
	forgeObjectId: number[]
	externalId: string[]
	issueStatus: string
	classification: string
	viewport: ViewportDTO
	location: string
	diagnosis: string
	analysisViewId: string
	assignedUser: string | null
	deadline: Date | null
	priority: Priority | null
	asset?: {img: string; type: Asset['type']}
	customProperties: IssueCustomProperties
}

export const postIssue = async (createDTO: CreateIssueDTO): Promise<IssueEntity> =>
	(await srProjectApiV3.post('issues/', _.omit(createDTO, 'asset'))).data

export const deleteIssue = async (id: string) => (await srProjectApiV3.delete('issues/' + id)).data.result

export const fetchIssueCommentsByIssueId = async (issueId: string): Promise<IssueComment[]> => {
	const params = {filter: `issue==${issueId}`, limit: 10000}
	return (await srProjectApiV3.get('issueComments', {params})).data.result
}

export const postIssueComment = async (issueComment: Partial<IssueComment>): Promise<IssueComment> =>
	(await srProjectApiV3.post('issueComments', issueComment)).data

export const deleteIssueComment = async (id: IssueComment['_id']): Promise<void> =>
	(await srProjectApiV3.delete('issueComments/' + id)).data

function dataURItoBlob(dataURI: string) {
	const binary = atob(dataURI.split(',')[1])
	const array = []
	for (let i = 0; i < binary.length; i++) {
		array.push(binary.charCodeAt(i))
	}
	return new Blob([new Uint8Array(array)], {type: 'image/png'})
}

export async function postAssetToIssue(
	project: UserProject,
	id: string,
	imageData: string,
	type: 'section' | 'photosphere' | 'viewer' | 'attachment',
	analysisViewId: string,
): Promise<IssueEntity> {
	const blob = dataURItoBlob(imageData)
	const key = getS3KeyForPath(project.tenantId, `issues/${id}/${uuidv4()}.png`)
	const s3Response: any = await putS3File(key, blob)
	const uploadUrl = `issues/${id}/assets`
	return (await srProjectApiV3.post(uploadUrl, {imagePath: s3Response.key, type, analysisViewId})).data
}

export const deleteIssueAsset = async (issueId: string, assetId: string): Promise<IssueEntity> =>
	(await srProjectApiV3.delete(`issues/${issueId}/assets/${assetId}`)).data

export type AssignableUserDTO = {
	_id: string
	firstName: string
	lastName: string
}

export async function fetchAssignableUsersForProject(): Promise<AssignableUserDTO[]> {
	return srProjectApiV3.get(`issues/assignable-users`).then(response => response.data)
}

export async function fetchIssueConfig(): Promise<IssueConfig> {
	return (await srProjectApiV3.get(`issueConfig`, {responseType: 'json'})).data
}

export async function saveIssueCustomPropertiesConfig(passedConfig: {
	customProps: IssueCustomPropConfigField[]
}): Promise<any> {
	return srProjectApiV3.post(`issueConfig`, passedConfig, {responseType: 'json'})
}
