import React, {useCallback, useEffect, useState} from 'react'
import {v4 as uuid} from 'uuid'
import {Box, Image, Layer, Text} from 'grommet'
import {Add, Checkmark, Close, Edit, Trash} from 'grommet-icons'
import {
	SRDeletePrimaryButton,
	SRHeading,
	SRIconButton,
	SRPlainButton,
	SRPrimaryButton,
	SRSecondaryButton,
	SRTextInput,
} from 'sr-react-commons'
import {PrimaryModal} from 'components/CommonsCandidate/Modals/PrimaryModal'
import {useMutation, useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {
	fetchIssueConfig,
	IssueCustomPropConfigField,
	IssueCustomPropertiesName,
	saveIssueCustomPropertiesConfig,
} from '../api/issue-management.api'
import {LoadingWrapper} from 'components/CommonsCandidate/Loading/LoadingWrapper'

export type IssueCustomPropertiesConfigStateItem = IssueCustomPropConfigField & {
	isEdit: boolean
}

export type IssueConfigModalProps = {
	toggleSettings: () => void
	isShow: boolean
}

const MAX_FIELD_LENGTH = 20
const MAX_FIELDS_AMOUNT = 8

export function IssueConfigModal(props: IssueConfigModalProps) {
	const {toggleSettings, isShow} = props

	const [config, setConfig] = useState<IssueCustomPropertiesConfigStateItem[]>([])
	const [initConfig, setInitConfig] = useState<IssueCustomPropertiesConfigStateItem[]>([])
	const [successModalOpen, setSuccessModalOpen] = useState(false)
	const [readyToExitModalOpen, setreadyToExitModalOpen] = useState(false)

	const {data: issueConfig, isLoading: isGetConfigLoading, isError: isGetConfigError} = useQuery<
		{customProps: IssueCustomPropConfigField[]},
		AxiosError
	>(['issue', 'issueConfig', isShow], fetchIssueConfig, {
		staleTime: 0,
		cacheTime: 0,
	})

	const {mutateAsync: saveConfig, isLoading: isSaveConfigLoading, isError: isSaveConfigError} = useMutation(
		saveIssueCustomPropertiesConfig,
		{
			onSuccess: toggleSettings,
		},
	)

	const isError = isGetConfigError || isSaveConfigError
	const activeOne = config.find(el => el.isEdit)

	const onInputChange = useCallback(
		(uuid: string, val: string) => {
			setConfig(config.map(el => ({...el, name: el.uuid === uuid ? (val as IssueCustomPropertiesName) : el.name})))
		},
		[config],
	)

	const onSetEdit = useCallback(
		(uuid: string, editVal: boolean) => {
			setConfig(config.map(el => ({...el, isEdit: el.uuid === uuid ? editVal : el.isEdit})))
		},
		[config],
	)

	const handleCloseMainModal = () => {
		if (JSON.stringify(config) !== JSON.stringify(initConfig)) setreadyToExitModalOpen(true)
		else toggleSettings()
	}

	useEffect(() => {
		if (!isShow) return

		if (!isError && issueConfig?.customProps) {
			const obj = issueConfig.customProps.map((el: IssueCustomPropConfigField) => ({
				name: el.name,
				uuid: el.uuid as string,
				isEdit: false,
			}))
			setConfig(obj)
			setInitConfig(obj)
		}
	}, [isShow, isGetConfigLoading, isError, issueConfig?.customProps])

	const handleSave = async () => {
		await saveConfig({
			customProps: config.map((el: IssueCustomPropConfigField) => ({name: el.name, uuid: el.uuid})),
		})

		setSuccessModalOpen(true)
	}

	const addProp = () => {
		setConfig([...config, {name: '' as IssueCustomPropertiesName, uuid: uuid(), isEdit: true}])
	}

	const deleteProp = useCallback(
		uuid => {
			setConfig(config.filter(el => el.uuid !== uuid))
		},
		[config],
	)

	return (
		<>
			<PrimaryModal
				isHideCancel
				open={successModalOpen}
				warningIcon={<Checkmark size="45px" color="green" />}
				onClose={() => setSuccessModalOpen(false)}
				submitElement={
					<SRPrimaryButton label={'Back to analysis'} onClick={() => setSuccessModalOpen(false)} size={'large'} />
				}
				title={'Customize issue template'}
				heading={'Your custom fields have been saved'}
				bodyElement={<Text>You can use them to add more information to your issues.</Text>}
			/>

			<PrimaryModal
				open={readyToExitModalOpen}
				cancelButtonText={'Keep editing'}
				onClose={() => setreadyToExitModalOpen(false)}
				submitElement={
					<SRPrimaryButton
						label={'Leave and lose changes'}
						size={'large'}
						onClick={() => (setreadyToExitModalOpen(false), toggleSettings())}
					/>
				}
				title={'Customize issue template'}
				heading={'Do you want to discard the unsaved fields?'}
				bodyElement={<Text>If you continue without saving you will lose the changes.</Text>}
			/>
			{isShow && (
				<Layer
					modal
					position={'top'}
					margin={{top: '100px'}}
					onClickOutside={handleCloseMainModal}
					onEsc={handleCloseMainModal}
				>
					<Box pad="medium" gap="small" width={{min: '520px'}} height={{min: 'medium'}} fill>
						<Box direction={'row'} justify={'between'}>
							<SRHeading level="4" margin="none">
								Customize issue template
							</SRHeading>
							<SRIconButton icon={<Close size="large" />} onClick={handleCloseMainModal} />
						</Box>
						<LoadingWrapper
							isError={isError}
							{...{isLoading: isGetConfigLoading || isSaveConfigLoading}}
							fill
							errorMsg="We couldn't display the issue configs, please retry."
						>
							<Box direction="row" width={{min: '472px'}} justify={'between'} gap={'medium'} pad={{bottom: 'small'}}>
								<Box gap={'small'} width={{min: '392px'}}>
									<SRHeading level="5" margin="none">
										Add custom text fields
									</SRHeading>

									<Text>
										• Custom text fields are included in PDF, and CSV exports.
										<br />
										• They will appear as comments for the BCF format export.
										<br />• You can add up to 8 custom text fields.
									</Text>
									<Box gap={'xsmall'}>
										{config.map(el => (
											<CustomPropertiesEditItem
												key={el.uuid}
												{...el}
												onSetEdit={onSetEdit}
												onInputChange={onInputChange}
												isDisabled={!!activeOne && activeOne.uuid !== el.uuid}
												deleteProp={deleteProp}
											/>
										))}
									</Box>
								</Box>
							</Box>
							<Box
								style={{display: 'initial'}}
								title={
									config.length >= MAX_FIELDS_AMOUNT ? 'You have already reached the maximum of 8 fields.' : undefined
								}
							>
								<SRSecondaryButton
									label={'Add new field'}
									gap={'xsmall'}
									size={'large'}
									onClick={addProp}
									icon={<Add size={'medium'} />}
									disabled={!!activeOne || config.length >= MAX_FIELDS_AMOUNT}
								/>
							</Box>
							<Box direction="row" justify={'between'} pad={{top: 'small'}}>
								<SRPlainButton onClick={handleCloseMainModal} label="Cancel" size="large" />
								<SRPrimaryButton disabled={!!activeOne} onClick={handleSave} label="Save custom fields" size="large" />
							</Box>
						</LoadingWrapper>
					</Box>
				</Layer>
			)}
		</>
	)
}

type CustomPropertiesEditItemProps = IssueCustomPropertiesConfigStateItem & {
	onInputChange: (a: string, b: string) => void
	onSetEdit: (a: string, b: boolean) => void
	deleteProp: (a: string) => void
	isDisabled: boolean
}

function CustomPropertiesEditItem(props: CustomPropertiesEditItemProps) {
	const {isEdit, isDisabled, name, onInputChange, uuid, onSetEdit, deleteProp} = props

	const [delModalOpen, setDelModalOpen] = useState(false)

	const handleDelIconClick = () => {
		setDelModalOpen(true)
	}

	const finalDelClick = () => {
		deleteProp(uuid)
		setDelModalOpen(false)
	}

	return (
		<Box direction="row" width={{min: '472px'}} justify={'between'} align={'start'}>
			<PrimaryModal
				open={delModalOpen}
				onClose={() => setDelModalOpen(false)}
				warningIcon={<Trash size="45px" color="red" />}
				submitElement={
					<SRDeletePrimaryButton label={'Yes, delete custom field'} onClick={finalDelClick} size={'large'} />
				}
				title={'Customize issue template'}
				heading={'Do you really want to delete this field?'}
				bodyElement={
					<Box gap={'xxsmall'}>
						<Text>You are about to delete the “{name}” custom field.</Text>
						<Text>All information saved on this field will be lost.</Text>
						<Text>You can’t undo this action.</Text>
					</Box>
				}
			/>
			{isEdit ? (
				<Box gap={'xxsmall'}>
					<SRTextInput
						placeholder={'Add custom field title'}
						onChange={e => e.target.value.length <= MAX_FIELD_LENGTH && onInputChange(uuid, e.target.value)}
						value={name}
						autoFocus={true}
					/>
					<Text size={'small'}>
						{name.length}/{MAX_FIELD_LENGTH}
					</Text>
				</Box>
			) : (
				<Text>{name}</Text>
			)}

			<Box direction={'row'}>
				{isEdit ? (
					<SRIconButton
						size="small"
						disabled={!name.length}
						icon={<Image src={'/images/icons/save.svg'} height={'20px'}></Image>}
						onClick={() => onSetEdit(uuid, false)}
					/>
				) : (
					<SRIconButton
						disabled={isDisabled}
						size="small"
						icon={<Edit size="medium" />}
						onClick={() => onSetEdit(uuid, true)}
					/>
				)}
				<SRIconButton disabled={isDisabled} size="small" icon={<Trash size="medium" />} onClick={handleDelIconClick} />
			</Box>
		</Box>
	)
}
