import React, {useMemo} from 'react'
import {Box, Text} from 'grommet'
import {SRHeading} from 'sr-react-commons'
import {ManagementDashboardAnalysisRequestSectionBox} from './support/ManagementDashboardAnalysisRequestSectionBox'
import {ManagementDashboardPageLayout} from '../core/components/layout/ManagementDashboardPageLayout'
import {useAnalysisRequest} from '../../AnalysisRequest/UseAnalysisRequest'
import {useParams} from 'react-router-dom'
import {Id} from '../../../entities/value-objects'
import {ManagementDashboardAnalysisRequestActiveModelTable} from './support/ManagementDashboardAnalysisRequestActiveModelTable'
import {useModelById} from '../../Models/UseModelById'
import {LoadingWrapper} from '../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {ManagementDashboardFileSelectionTable} from '../core/components/fileSelection/ManagementDashboardFileSelectionTable'
import {useProjectFileByVisibilityWithSearch} from '../file/UseProjectFilesByVisibility'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {format} from 'date-fns'
import {ManagementDashboardAnalysisRequestSizedContentBox} from './support/ManagementDashboardAnalysisRequestSizedContentBox'
import {
	GEOREF_OPTIONS,
	HAS_IMAGES_OPTIONS,
	SCANNER_MODEL_OPTIONS,
} from './form/steps/ManagementDashboardAnalysisRequestForm.step2'
import {useRootModelInformations} from '../../Models/useRootModelsInformation'

export const ManagementDashboardAnalysisRequestViewPage = () => {
	const project = useCurrentProject()
	const {analysisRequestId} = useParams<{analysisRequestId: Id}>()
	const {
		data: analysisRequest,
		isLoading: isAnalysisRequestLoading,
		isError: isAnalysisRequestError,
	} = useAnalysisRequest(analysisRequestId)
	const {data: model, isLoading: isModelLoading, isError: isModelError} = useModelById(
		analysisRequest?.modelDomain.model._id,
	)
	const {data: rootModels, isLoading: isRootModelLoading, isError: isRootModelError} = useRootModelInformations(
		model?._id,
	)

	const {
		files: scans,
		isLoading: isScansLoading,
		isError: isScansError,
	} = useProjectFileByVisibilityWithSearch(project!, 'scan', ['uploaded'])
	const {
		files: documents,
		isLoading: isDocumentsLoading,
		isError: isDocumentsError,
	} = useProjectFileByVisibilityWithSearch(project!, 'document', ['uploaded'])

	const isLoading =
		isAnalysisRequestLoading || isModelLoading || isScansLoading || isDocumentsLoading || isRootModelLoading
	const isError = isAnalysisRequestError || isModelError || isScansError || isDocumentsError || isRootModelError
	const scanFiles = useMemo(
		() => scans && analysisRequest?.scanDomain.files.map(scanRef => scans.find(scan => scan._id === scanRef._id)!),
		[analysisRequest?.scanDomain.files, scans],
	)
	const documentFiles = useMemo(
		() =>
			documents &&
			analysisRequest?.documentDomain.files.map(
				documentRef => documents.find(document => document._id === documentRef._id)!,
			),
		[analysisRequest?.documentDomain.files, documents],
	)

	const getGeoreference = (georeference: string) => {
		const geo = GEOREF_OPTIONS.find(georefOption => georeference === georefOption.value)
		return geo?.label || ''
	}

	const getImagesOption = (value: boolean) => {
		const result = HAS_IMAGES_OPTIONS.find(hasImageOpt => value === hasImageOpt.value)
		return result?.label || ''
	}

	const getScannerModel = (scanner: string) => {
		const scannerModel = SCANNER_MODEL_OPTIONS.find(scannerOption => scanner === scannerOption.value)
		return scannerModel?.label || ''
	}

	return (
		<ManagementDashboardPageLayout>
			<LoadingWrapper
				{...{isLoading, isError, errorMsg: 'There was a problem loading this page. Please retry or contact support.'}}
				fill
			>
				{isLoading || !model ? null : (
					<ManagementDashboardAnalysisRequestSizedContentBox>
						<ManagementDashboardAnalysisRequestSectionBox title={'BIM model'}>
							<Box pad={{left: 'medium'}}>
								<ManagementDashboardAnalysisRequestActiveModelTable model={model} rootModels={rootModels} />
							</Box>
						</ManagementDashboardAnalysisRequestSectionBox>
						<ManagementDashboardAnalysisRequestSectionBox title={'Scan information'}>
							{!scanFiles ? null : (
								<ManagementDashboardFileSelectionTable fileType={'Scan'} selectedFiles={scanFiles} />
							)}
							<SRHeading level={5}>Scan file details</SRHeading>
							<Box gap="xsmall">
								<Box direction="row" align="center" gap="xxsmall">
									<SRHeading level={6}>Scan date:</SRHeading>
									<Text size="medium">{format(new Date(analysisRequest!.scanDomain.date), 'dd/MM/yyyy')}</Text>
								</Box>
								<Box direction="row" align="center" gap="xxsmall">
									<SRHeading level={6}>Scanner model:</SRHeading>
									<Text size="medium">{getScannerModel(analysisRequest!.scanDomain.scannerModel)}</Text>
								</Box>
							</Box>
							<SRHeading level={5}>Georeference</SRHeading>
							<Box direction="row" align="center" gap="xxsmall">
								<SRHeading level={6}>Georeference:</SRHeading>
								<Text size="medium">{getGeoreference(analysisRequest!.scanDomain.georeference)}</Text>
							</Box>
							<Box direction="row" align="center" gap="xxsmall">
								<SRHeading level={6}>Images:</SRHeading>
								<Text size="medium">{getImagesOption(analysisRequest!.hasImages)}</Text>
							</Box>
						</ManagementDashboardAnalysisRequestSectionBox>
						<ManagementDashboardAnalysisRequestSectionBox title={'Support documents'}>
							<SRHeading level={5}>Area of interest information</SRHeading>
							<SRHeading level={6}>Area of interest description</SRHeading>
							<Text size="medium">{analysisRequest!.documentDomain.roiDescription}</Text>
							<SRHeading level={6}>Supporting documents*</SRHeading>
							<Text size="medium">Extra documentation like images of the scanned area or georeference data.</Text>
							{!documentFiles ? null : (
								<ManagementDashboardFileSelectionTable fileType={'Document'} selectedFiles={documentFiles} />
							)}
						</ManagementDashboardAnalysisRequestSectionBox>
					</ManagementDashboardAnalysisRequestSizedContentBox>
				)}
			</LoadingWrapper>
		</ManagementDashboardPageLayout>
	)
}
