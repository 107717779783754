import ReactDOM from 'react-dom'
import React from 'react'
import {Provider} from 'react-redux'
import {Grommet} from 'grommet'
import {theme} from '../../../../theme'
import {ElementSectionsPanelWithStore} from './ElementSectionsPanelContent'
import {history} from '../../../../router/history'
import {Router} from 'react-router-dom'
import {QueryClientProvider} from 'react-query'
import {store} from '../../../../store/store'
import {defaultQueryClient} from '../../../../utilities/query-client.util'

const Autodesk = window.Autodesk

const id = 'ElementSectionsPanel'
const title = 'Element Sections'
const options = {
	shadow: false,
}

export default class ElementSectionsPanel extends Autodesk.Viewing.UI.DockingPanel {
	private reactMounted = false
	private readonly DOMContent: HTMLDivElement
	constructor(container: HTMLElement) {
		super(container, id, title, options)

		this.container.style.height = '600px'
		this.container.style.width = '800px'
		this.container.style.top = '50px'
		this.container.style.right = '100px'

		this.DOMContent = document.createElement('div')
		this.DOMContent.className = 'docking-panel-content'
		this.container.appendChild(this.DOMContent)
	}

	setVisible(show: boolean) {
		super.setVisible(show)
		if (show) {
			ReactDOM.render(
				<Provider store={store}>
					<Router history={history}>
						<Grommet style={{height: '100%'}} theme={theme}>
							<QueryClientProvider client={defaultQueryClient}>
								<ElementSectionsPanelWithStore />
							</QueryClientProvider>
						</Grommet>
					</Router>
				</Provider>,
				this.DOMContent,
			)
			this.reactMounted = true
		} else {
			if (this.reactMounted) {
				ReactDOM.unmountComponentAtNode(this.DOMContent)
				this.reactMounted = false
			}
		}
	}
}
