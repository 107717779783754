import React from 'react'
import {useSelector} from 'react-redux'
import {useBasePath} from '../../hooks/useBasePath'
import {QualityLayerPresenter} from './QualityLayerPresenter'
import {QualityBody} from '../DataTableContainer/QualityBody'
import {RootState} from '../../reducers'

export function QualityLayer() {
	const numberOfElements = useSelector<RootState, number>(state => state.classification.pagination.totalRecords)
	const basePath = useBasePath()
	const dataTableContainer = <QualityBody />

	return <QualityLayerPresenter {...{numberOfElements, basePath, dataTableContainer}} />
}
