import {IconProps} from 'grommet-icons'
import React from 'react'
import styled from 'styled-components'
//@ts-ignore
import {StyledIcon} from 'grommet-icons/es6/StyledIcon'
import {ReactComponent as SvgIcon} from './forge-integration-autodesk-icon.svg'
import {ColorType} from 'grommet/utils'
import {Box} from 'grommet'

const COLOR: ColorType = 'black' as const

export const ForgeIntegrationAutodeskIcon = styled((props: Omit<IconProps, 'color'>) => (
	<Box title={'Autodesk'}>
		<StyledIcon {...props} viewBox="0 0 24 24" color={COLOR}>
			<SvgIcon />
		</StyledIcon>
	</Box>
))`
	svg {
		// force it to use the forge defined color
		fill: ${COLOR} !important;
		stroke: ${COLOR} !important;
	}
`
