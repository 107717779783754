import {Id} from '../../../entities/value-objects'
import {srProjectApiV3} from '../../../api/project.api.v3'
import {WatchlistEntity, WatchlistFilterSource} from '../entities/watchlist.entities'
import _ from 'lodash'

export async function fetchOneWatchlist(id: Id): Promise<WatchlistEntity> {
	const result = await srProjectApiV3.get(`/watchlists/${id}`)
	return result.data
}

export async function fetchAllWatchlist(): Promise<WatchlistEntity[]> {
	const result = await srProjectApiV3.get('/watchlists/all')
	return result.data
}

export type CreateWatchlistDTO = Pick<WatchlistEntity, 'name' | 'description' | 'startDate' | 'endDate'> & {
	source: WatchlistFilterSource | {mode: 'csv'; filter: null; file: File}
	ignoreMissingIds: boolean
}

export type UpdateWatchlistDTO = Pick<WatchlistEntity, 'name' | 'description' | 'startDate' | 'endDate'> & {
	source: WatchlistFilterSource | {mode: 'csv'; filter: null; file: File} | null
	ignoreMissingIds: boolean
}

function buildFormDataFromDto(dto: CreateWatchlistDTO | UpdateWatchlistDTO) {
	const formData = new FormData()
	const json: Record<string, unknown> = _.omit(dto, 'source')
	if (dto.source) {
		if (dto.source.mode === 'csv') {
			formData.append('csv', dto.source.file)
		} else {
			json.filter = dto.source.filter
		}
	}
	formData.append('json', JSON.stringify(json))
	return formData
}

export async function createWatchlist(dto: CreateWatchlistDTO): Promise<WatchlistEntity> {
	const result = await srProjectApiV3.post(`watchlists/`, buildFormDataFromDto(dto), {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
	return result.data
}

export async function updateWatchlist(id: Id, dto: UpdateWatchlistDTO): Promise<WatchlistEntity> {
	const result = await srProjectApiV3.put(`watchlists/${id}`, buildFormDataFromDto(dto), {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
	return result.data
}

export async function apiDeleteWatchlist(id: Id): Promise<void> {
	await srProjectApiV3.delete(`watchlists/${id}`)
	return
}
