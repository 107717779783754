import React from 'react'
import {SRCardContainer} from 'sr-react-commons'
import {IssueEntity} from '../issue.entities'
import {IssueCardContainer} from './IssueCardContainer'

type IssueCardListProps = {
	issues: IssueEntity[]
	checkedIssues: string[]
	toggleIssue: (issueId: string) => void
	selectedIssue: IssueEntity | null
	setSelectedIssue: (issue: IssueEntity | null) => void
}

export function IssueCardList({
	issues,
	checkedIssues,
	toggleIssue,
	selectedIssue,
	setSelectedIssue,
}: IssueCardListProps) {
	return (
		<SRCardContainer>
			{issues.map((issue: IssueEntity) => (
				<IssueCardContainer
					key={issue._id}
					issue={issue}
					checked={checkedIssues.includes(issue._id)}
					active={selectedIssue?._id === issue._id}
					setActive={() => setSelectedIssue(issue)}
					onCheckChange={() => toggleIssue(issue._id)}
				/>
			))}
		</SRCardContainer>
	)
}
