import {Box, Layer, Text} from 'grommet'
import {Close} from 'grommet-icons'
import React, {useCallback, useState} from 'react'
import {SRBrandButton, SRHeading, SRIconButton, SRNotificationCard, SRPlainButton, SRTextInput} from 'sr-react-commons'
import {Spinner} from '../../../../../components/CommonsCandidate/Loading/Spinner'
import {useGlobalNotifications} from '../../../../GlobalNotifications'

export const ManagementDashboardAnalysisRequestConfirmSaveModal = ({
	cancel,
	confirm,
	analysisNameValid,
	onAnalysisNameTextInputChange,
}: {
	cancel: () => void
	confirm: () => Promise<void>
	analysisNameValid: boolean
	onAnalysisNameTextInputChange: (value: string) => void
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const {notifyError} = useGlobalNotifications()
	const onConfirm = useCallback(async () => {
		setIsLoading(true)
		try {
			await confirm()
		} catch (e) {
			notifyError('We could not process the request. Please try again.')
		} finally {
			setIsLoading(false)
		}
	}, [setIsLoading, confirm, notifyError])

	return (
		<Layer position="top" margin={{top: '100px'}}>
			<Box gap="medium" pad="medium" width="520px">
				<Box direction="row" gap="medium" justify="between">
					<SRHeading level={4} alignSelf="center">
						Send analysis request
					</SRHeading>
					<SRIconButton icon={<Close />} onClick={cancel} alignSelf="center" />
				</Box>
				<Box>
					<SRHeading level={6} alignSelf="start">
						Analysis name*
					</SRHeading>
					<SRTextInput
						style={{fontWeight: 'normal'}}
						id="analysisNameInputText"
						onChange={event => onAnalysisNameTextInputChange(event.target.value)}
					></SRTextInput>
				</Box>
				<SRNotificationCard
					pad="small"
					title="Please make sure all information is correct"
					text={
						<Text>
							Once you send your request, you won't be able to edit it. <br />
							If you need to modify your request, please contact us.
						</Text>
					}
					type="warning"
				/>
				<Box direction="row" justify="between">
					<SRPlainButton size="large" label="Go back" onClick={cancel} />
					<SRBrandButton
						size="large"
						label="Send Request"
						disabled={isLoading || !analysisNameValid}
						onClick={onConfirm}
						icon={isLoading ? <Spinner size={'xsmall'} color={'darker'} /> : undefined}
					/>
				</Box>
			</Box>
		</Layer>
	)
}
