import {Box, Text} from 'grommet'
import {
	ManagementDashboardTable,
	ManagementDashboardTableCell,
	ManagementDashboardTableHeaderCell,
	ManagementDashboardTableRowBox,
} from '../table/ManagementDashboardTable'
import {
	DELETE_CELL_WIDTH,
	FILE_NAME_CELL_MIN_WIDTH,
	UPLOADED_BY_CELL_WIDTH,
	UPLOADED_CELL_WIDTH,
} from '../../../file/ManagementDashboardFileTable'
import {format} from 'date-fns'
import {Close} from 'grommet-icons'
import React from 'react'
import {ProjectFileEntity} from '../../../../../api/files.api'
import {SRIconButton} from 'sr-react-commons'

export type ManagementDashboardFileSelectionTableProps = {
	fileType: 'Scan' | 'Document' | 'Model'
	selectedFiles: ProjectFileEntity[]
	onDeleteFile?: (deleteId: string) => void
}

export const ManagementDashboardFileSelectionTable = ({
	selectedFiles,
	onDeleteFile,
	fileType,
}: ManagementDashboardFileSelectionTableProps) => {
	return (
		<Box fill={'horizontal'}>
			{selectedFiles.length ? (
				<ManagementDashboardTable pad={{left: 'medium'}}>
					<ManagementDashboardTableRowBox size={'small'}>
						<ManagementDashboardTableHeaderCell flexible width={{min: FILE_NAME_CELL_MIN_WIDTH}}>
							{fileType} file name
						</ManagementDashboardTableHeaderCell>
						<ManagementDashboardTableHeaderCell width={UPLOADED_CELL_WIDTH}>
							Date uploaded
						</ManagementDashboardTableHeaderCell>
						<ManagementDashboardTableHeaderCell width={UPLOADED_BY_CELL_WIDTH}>
							Uploaded by
						</ManagementDashboardTableHeaderCell>
						{!onDeleteFile ? null : (
							<ManagementDashboardTableHeaderCell width={DELETE_CELL_WIDTH}></ManagementDashboardTableHeaderCell>
						)}
					</ManagementDashboardTableRowBox>
					{selectedFiles.map(scan => (
						<ManagementDashboardTableRowBox key={scan._id} size={'small'}>
							<ManagementDashboardTableCell flexible width={{min: FILE_NAME_CELL_MIN_WIDTH}}>
								<Text alignSelf="center" title={scan.filename} truncate>
									{scan.filename}
								</Text>
							</ManagementDashboardTableCell>
							<ManagementDashboardTableCell width={{min: UPLOADED_CELL_WIDTH}}>
								{format(new Date(scan.createdDate), 'dd/MM/yyyy HH:mm')}
							</ManagementDashboardTableCell>
							<ManagementDashboardTableCell width={UPLOADED_BY_CELL_WIDTH}>
								<Text alignSelf="center" title={scan.createdByRef?.email} truncate>
									{scan.createdByRef?.email}
								</Text>
							</ManagementDashboardTableCell>
							{!onDeleteFile ? null : (
								<ManagementDashboardTableCell width={DELETE_CELL_WIDTH}>
									<SRIconButton
										size={'small'}
										icon={<Close size={'xsmall'} />}
										onClick={() => onDeleteFile(scan._id)}
									/>
								</ManagementDashboardTableCell>
							)}
						</ManagementDashboardTableRowBox>
					))}
				</ManagementDashboardTable>
			) : (
				<Box pad={{vertical: 'xsmall'}}>
					<Text style={{fontStyle: 'italic'}}>No {fileType.toLowerCase()} files selected</Text>
				</Box>
			)}
		</Box>
	)
}
