import {Box, Image} from 'grommet'
import React, {useState} from 'react'
import {Camera} from 'grommet-icons'
import {AddToIssueBox} from '../../AddToIssueButton/AddToIssueBox'
import {SRPrimaryButton, SRSecondaryButton} from 'sr-react-commons'
import {captureViewerContainerAsCanvas} from '../../../utilities/captureViewerContainerAsCanvas'

function saveAs(uri: string, filename: string) {
	// TODO find a better way to do this
	const link = document.createElement('a')
	link.href = uri
	link.download = filename
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

export function isViewerGui(element: Element) {
	return (
		element.classList.contains('viewcubeWrapper') ||
		element.classList.contains('progressbg') ||
		element.id === 'ThreeSixtyPhotoPanel' ||
		element.id === 'ElementSectionsPanel' ||
		element.id === 'ScreenshotsPanel' ||
		element.id === 'guiviewer3d-toolbar' ||
		element.id === 'verticalControlGroup' ||
		element.id === 'heatmapMagnitudeTooltip' ||
		element.id === 'heatmapSetterContainer'
	)
}

export function ViewerScreenshotContent() {
	const [imageData, setImageData] = useState<string>()
	const captureScreenShot = async () => {
		const canvas = await captureViewerContainerAsCanvas()
		if (canvas) {
			setImageData(canvas.toDataURL())
		}
	}

	return (
		<Box pad={'small'} gap={'small'}>
			<SRPrimaryButton
				alignSelf={'start'}
				size={'medium'}
				onClick={captureScreenShot}
				label={'Capture current viewer'}
				icon={<Camera size={'small'} />}
			/>
			{imageData && (
				<Box height={'500px'} width={'large'}>
					<Image src={imageData} fit={'contain'} fill={false} />
					<Box direction={'row'} gap={'xsmall'} margin={{vertical: 'xsmall'}}>
						<SRSecondaryButton
							alignSelf={'start'}
							size={'medium'}
							label={'Download'}
							onClick={() => saveAs(imageData, 'viewer-screenshot.png')}
						/>
						<SRSecondaryButton
							alignSelf={'start'}
							size={'medium'}
							label={'Reset'}
							onClick={() => setImageData(undefined)}
						/>
						<AddToIssueBox
							domSelector={'#viewerDiv > div > div.canvas-wrap > canvas'}
							type={'viewer'}
							issueSelection={'all'}
							getAssetDataFn={async () => imageData}
							isScreenShot={true}
						/>
					</Box>
				</Box>
			)}
		</Box>
	)
}
