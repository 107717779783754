import {sleep} from '../../../utilities/asyncUtilities'
import {config} from '../../../config'

const VALID_FORGE_VERSION = '7.86.*'
const ISOLATE_CHUNK_SIZE = 25000
const releaseThread = () => sleep(0)
const checkPatchVersion = () => {
	if (config.versions.forgeViewer !== VALID_FORGE_VERSION) {
		throw Error(
			`Forge viewer version ${config.versions.forgeViewer} is being patched by viewerAsyncIsolatePatch for version ${VALID_FORGE_VERSION}, please verify if the patch needs to be updated and raise the patch version`,
		)
	}
}

/*
 * The below patch method adds a new function `isolateAsync` which copies
 * Autodesk Viewer code with the minimum possible intervention.
 *
 * NOTE 1: Important additions are marked by the comment label: "[[--ADDITION--]]"
 * NOTE 2: In the original code several "this" references were replaced by the actual object
 * NOTE 3: Forge types are not well defined, the use of "any" in this patch is to bypass type errors due to wrong definitions.
 *
 * It makes viewer isolate functionality to work asynchronously so it does not block the main thread
 */
export const viewerAsyncIsolatePatch = (viewer: Autodesk.Viewing.Viewer3D) => {
	checkPatchVersion() // [[--ADDITION--]]
	function fireAggregateIsolationChangedEvent(_this: any) {
		const isolation = _this.getAggregateIsolatedNodes()
		if (_this.models.length === 1) {
			const event = {
				type: Autodesk.Viewing.ISOLATE_EVENT,
				nodeIdArray: isolation.length ? isolation[0].ids : [],
				model: _this.models[0],
			}
			_this.viewerImpl.api.dispatchEvent(event)
		}
		const event = {
			type: Autodesk.Viewing.AGGREGATE_ISOLATION_CHANGED_EVENT,
			isolation: isolation,
		}
		_this.viewerImpl.api.dispatchEvent(event)
	}
	Autodesk.Viewing.Private.VisibilityManager.prototype.isolateMultipleAsync = async function (nodeList) {
		const anyThis = this as any
		if (!nodeList?.length) {
			this.isolateNone()
		} else {
			if (!anyThis.model.getData().is2d) {
				anyThis.model.setAllVisibility(false)
				anyThis.viewerImpl.sceneUpdated(true)
			}
			this.setAllVisibility(false)
			anyThis.isolatedNodes = nodeList.slice(0)
			anyThis.hiddenNodes = []
			for (let i = 0; i < nodeList.length; i++) {
				this.setVisibilityOnNode(nodeList[i], true)
				if (!(i % ISOLATE_CHUNK_SIZE)) await releaseThread() // [[--ADDITION--]]
			}
		}
		anyThis.viewerImpl.invalidate(true)
	}
	Autodesk.Viewing.Private.VisibilityManager.prototype.isolateAsync = async function (node) {
		const it = this.getInstanceTree()
		const rootId = it ? it.getRootId() : null
		const isRoot =
			(typeof node == 'number' && node === rootId) || (typeof node == 'object' && (node as any).dbId === rootId)
		if (node && !isRoot) {
			await this.isolateMultipleAsync(Array.isArray(node) ? node : [node]) // [[--ADDITION--]] => await
		} else {
			this.isolateNone()
		}
	}
	;(viewer.impl.visibilityManager.isolateAsync as any) = async (
		node: number | object,
		model: Autodesk.Viewing.Model,
	) => {
		if (!model) {
			;(viewer.impl.visibilityManager as any).warn()
			model = (viewer.impl.visibilityManager as any).models[0]
		}
		await model.visibilityManager.isolateAsync(node) // [[--ADDITION--]] => await
		viewer.impl?.visibilityManager && fireAggregateIsolationChangedEvent(viewer.impl.visibilityManager)
	}
	viewer.isolateAsync = async (node, model?) => {
		model = model || viewer.model
		if (!model) return
		await (viewer.impl.visibilityManager as any).isolateAsync(node, model) // [[--ADDITION--]] => await
	}
}
