import {Selection, SRMultiSelectValueFormatter, SRSelectionChips} from 'sr-react-commons'
import React from 'react'
import {MagnitudeFilterType} from './Magnitude/MagnitudeSelectorFilter'

export function getUnavailableFilters(
	activeFilters: string[],
	availableFilterOptions: {value: string; label: string}[],
): string[] {
	const availableFiltersSet = new Set(availableFilterOptions.map(option => option.value))
	return activeFilters.filter(activeFilter => !availableFiltersSet.has(activeFilter))
}

export function formatValueForModelElementFilters(
	selections: Selection[],
	unavailableFilters: string[],
	onRemoveOption: (toBeRemoved: string) => void,
): ReturnType<SRMultiSelectValueFormatter> {
	return selections && selections.length ? (
		<SRSelectionChips
			withCounter
			selections={selections.map(value => ({...value, secondary: unavailableFilters?.includes(value.value)}))}
			onSelectionClick={selection => onRemoveOption(selection.value)}
		/>
	) : (
		[]
	)
}

export function formatValueForMagnitudeFilters(
	selections: Selection[],
	onRemoveOption: (toBeRemoved: MagnitudeFilterType) => void,
): ReturnType<SRMultiSelectValueFormatter> {
	return selections && selections.length ? (
		<SRSelectionChips
			withCounter
			selections={selections.map(value => ({...value}))}
			onSelectionClick={selection => onRemoveOption(selection.value as MagnitudeFilterType)}
		/>
	) : (
		[]
	)
}

export function buildSelectOptionsWithUnavailableFiltersSection(
	options: {value: string; label: string}[],
	unavailableFilters: string[],
): {value: string; label: string; section?: string}[] {
	return options.concat(
		(unavailableFilters || []).map(filter => ({
			value: filter,
			label: filter,
			section: 'Unavailable filters',
		})),
	)
}
