import {Box, Form, Layer, Text} from 'grommet'
import React from 'react'
import {
	SR_COLOR_SELECTED,
	SR_COLOR_TEXT_LIGHT,
	SRHeading,
	SRIconButton,
	SRPlainButton,
	SRPrimaryButton,
} from 'sr-react-commons'
import {Close} from 'grommet-icons'

export function WatchListCreateErrorModal({
	onClose,
	missingIds,
	handleIgnoreSubmit,
}: {
	onClose: () => void
	missingIds: string[]
	handleIgnoreSubmit: () => void
}) {
	return (
		<Layer position={'top'} margin={{top: '100px'}}>
			<Form>
				<Box gap={'medium'} pad={'medium'} width={'525px'}>
					<Box justify={'between'} direction={'row'}>
						<SRHeading level={4}>Work package's Global Ids not found</SRHeading>
						<SRIconButton icon={<Close />} onClick={onClose} alignSelf={'center'} />
					</Box>
					<Box gap={'small'}>
						<Box direction="row" gap={'xsmall'}>
							<SRHeading level={5}>
								We could not find {missingIds.length} Global IDs from the uploaded CSV file in the last BIM version.
							</SRHeading>
						</Box>
						<Box gap="xsmall">
							<Text size="medium">
								You can still use this CSV file but the Global ID's from the list will be ignored.
							</Text>
							<Text size="medium">Click on "Ignore and upload CSV file" to continue.</Text>
						</Box>
					</Box>
					<Box width="100%">
						<Box
							pad={{bottom: 'xsmall', left: 'xsmall'}}
							direction="row"
							justify="between"
							border={{
								color: SR_COLOR_TEXT_LIGHT,
								size: 'xsmall',
								side: 'bottom',
							}}
						>
							<Text weight="bold">Data not found</Text>
							{/*<SRIconButton icon={<Copy size="medium" />} future implementation />*/}
						</Box>
						<Box width="100%" height={{max: '292px'}} overflow="auto">
							{missingIds.map((id, index) => {
								return (
									<Box
										pad={{vertical: 'xsmall', left: 'xsmall'}}
										flex={false}
										border={{
											color: SR_COLOR_SELECTED,
											size: 'xsmall',
											side: 'bottom',
										}}
										key={index}
									>
										<Text>{id}</Text>
									</Box>
								)
							})}
						</Box>
					</Box>
					<Box direction="row" justify="between">
						<SRPlainButton onClick={onClose} label="Cancel" size={'medium'} />
						<SRPrimaryButton
							type="submit"
							size={'medium'}
							label="Ignore and upload CSV file"
							onClick={handleIgnoreSubmit}
						/>
					</Box>
				</Box>
			</Form>
		</Layer>
	)
}
