import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {fetchClassificationsByForgeIds, fetchLastClassificationsByForgeIds} from '../api/classification.api'
import _ from 'lodash'
import {useMemo} from 'react'
import {selectorCurrentWatchlist} from '../../Watchlist/selectors/watchlist.selectors'
import {useClassificationState} from '../../../reducers/classification.slice'
import {CLASSIFICATIONS_BASE_QUERY_KEY} from './index'

function useClassificationsByForgeIds(forgeObjectIds: number[]) {
	const {selectedAnalysisView, analysisVersionMap} = useClassificationState()
	const currentWatchlist = useSelector(selectorCurrentWatchlist)
	const enabled = !!(selectedAnalysisView && forgeObjectIds?.length > 0)
	const query = useQuery(
		[CLASSIFICATIONS_BASE_QUERY_KEY, currentWatchlist ? null : selectedAnalysisView?._id, _.sortBy(forgeObjectIds)],
		() =>
			selectedAnalysisView
				? currentWatchlist
					? fetchLastClassificationsByForgeIds(forgeObjectIds)
					: fetchClassificationsByForgeIds(
							selectedAnalysisView,
							forgeObjectIds,
							analysisVersionMap,
					  ).then(classifications =>
							Object.fromEntries(
								forgeObjectIds.map(id => [id, classifications.find(cl => cl.forgeObjectId === id) || null]),
							),
					  )
				: undefined,
		{keepPreviousData: true, enabled, staleTime: 60 * 60 * 1000},
	)
	return useMemo(() => (query.status === 'success' && enabled ? query.data : undefined), [query, enabled])
}

export function useSelectedClassifications() {
	const {forgeObjectIds} = useSelector(selectorViewerProperties)
	return useClassificationsByForgeIds(forgeObjectIds)
}

export function useLastSelectedClassification() {
	const {lastSelectedForgeId} = useSelector(selectorViewerProperties)
	const forgeObjectIds = lastSelectedForgeId ? [lastSelectedForgeId] : []
	const classifications = useClassificationsByForgeIds(forgeObjectIds)
	return classifications && !_.isEmpty(classifications)
		? classifications[Object.keys(classifications)[0]] || null
		: null
}
