import {useSelectedClassifications} from '../../../features/Classification/data-management/useSelectedClassification'
import HeatmapExtension, {HEATMAP_EXTENSION} from './Viewing.Extension.Heatmap'
import {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {selectorViewerInstance} from '../../../selectors/viewer-state.selector'
import {RootState} from '../../../reducers'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {useFeatureEnabled} from '../../../features/FeatureFlags/FeatureFlagsProvider'
import {ClassificationEntity} from '../../../features/Classification/classification.entities'

export function useHeatmapExtension() {
	const project = useCurrentProject()!
	const classifications = useSelectedClassifications()
	const extensionRef = useRef<HeatmapExtension>()
	const [extensionStatus, setExtensionStatus] = useState<'not loaded' | 'loading' | 'loaded'>('not loaded')
	const modelLoaded = useSelector((state: RootState) => state.viewerState.isReady.forgeMapping)
	const viewer = useSelector(selectorViewerInstance)
	const viewerRef = useRef<Autodesk.Viewing.GuiViewer3D | null>()
	const usePointCloudAsHeatmap = useFeatureEnabled('usePointCloudAsHeatmap')

	useEffect(() => {
		viewerRef.current = viewer || null
	}, [viewer])

	useEffect(() => {
		return () => {
			if (viewerRef.current && viewerRef.current.isExtensionLoaded(HEATMAP_EXTENSION)) {
				viewerRef.current.unloadExtension(HEATMAP_EXTENSION)
			}
		}
	}, [])

	useEffect(() => {
		if (modelLoaded === false) setExtensionStatus('not loaded')
	}, [modelLoaded])

	useEffect(() => {
		if (modelLoaded && viewer && viewer.impl && extensionStatus === 'not loaded') {
			setExtensionStatus('loading')
			viewer.loadExtension(HEATMAP_EXTENSION).then(extension => {
				extensionRef.current = extension as HeatmapExtension
				extensionRef.current?.setUsePointCloudAsHeatmap(usePointCloudAsHeatmap)
				setExtensionStatus('loaded')
			})
		}
	}, [extensionStatus, modelLoaded, viewer, project, usePointCloudAsHeatmap])

	useEffect(() => {
		if (extensionStatus === 'loaded') {
			extensionRef.current!.setClassifications(
				classifications &&
					Object.values(classifications).filter<ClassificationEntity>((cl): cl is ClassificationEntity => cl !== null),
			)
		}
	}, [classifications, extensionStatus, extensionRef])
}

export function UseHeatmapContainer() {
	useHeatmapExtension()
	return null
}
