import React, {useState} from 'react'
import {Box, Form, Layer} from 'grommet'
import {SRHeading, SRIconButton, SRPlainButton} from 'sr-react-commons'
import {Close} from 'grommet-icons'
import {ProgressAggregation} from '../aggregations/progress.aggregations'
import WatchListPDFButton from '../components/WatchListPDFButton'
import {fetchPdfByWatchList} from '../api/progress-monitoring.api'
import {SRCheckBoxWithDescription} from '../../../components/CommonsCandidate/CheckBox/SRCheckBoxWithDescription'

export function ExportWorkPackageAsPDFModal({
	onClose,
	progress,
	showElementTypeBreakdown,
	enableUnderConstruction = false,
}: {
	onClose: () => void
	progress: ProgressAggregation
	showElementTypeBreakdown: boolean
	enableUnderConstruction?: boolean
}) {
	const [showQuality, setShowQuality] = useState(false)
	const [elementTypeBreakdown, setElementTypeBreakdown] = useState(false)
	const handleShowQuality = () => {
		setShowQuality(!showQuality)
	}
	const handleShowElementTypeBreakdown = () => {
		setElementTypeBreakdown(!elementTypeBreakdown)
	}
	const reportOptions = {
		showQuality: showQuality,
		elementTypeBreakdown: elementTypeBreakdown,
		enableUnderConstruction: enableUnderConstruction,
	}
	const fetchPDFFile = async () => {
		return fetchPdfByWatchList(progress.watchlist._id, reportOptions).then((blob: Blob) => {
			onClose()
			return blob
		})
	}

	return (
		<Layer position={'top'} margin={{top: '100px'}}>
			<Form>
				<Box gap={'medium'} pad={'medium'} width={'525px'}>
					<Box justify={'between'} direction={'row'}>
						<SRHeading level={4}>Export progress report</SRHeading>
						<SRIconButton icon={<Close />} onClick={onClose} alignSelf={'center'} />
					</Box>
					<Box gap="medium">
						<SRHeading level={5}>Report options</SRHeading>
						<SRCheckBoxWithDescription
							label={'Show quality status information'}
							description={'Your report will display the quality status labels.'}
							onChange={handleShowQuality}
							value={showQuality}
						/>
						{showElementTypeBreakdown && (
							<SRCheckBoxWithDescription
								label={'Include breakdown by element type'}
								description={'Your report will include breakdown by element type.'}
								onChange={handleShowElementTypeBreakdown}
								value={elementTypeBreakdown}
							/>
						)}
					</Box>
					<Box direction="row" justify="between">
						<SRPlainButton onClick={onClose} label="Cancel" size={'medium'} />
						<WatchListPDFButton watchListName={progress.watchlist.name} fetchPDFFile={fetchPDFFile} />
					</Box>
				</Box>
			</Form>
		</Layer>
	)
}
