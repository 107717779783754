import {
	ManagementDashboardTable,
	ManagementDashboardTableCell,
	ManagementDashboardTableHeaderCell,
	ManagementDashboardTableRowBox,
} from '../../core/components/table/ManagementDashboardTable'
import {FILE_NAME_CELL_MIN_WIDTH} from '../../file/ManagementDashboardFileTable'
import {Text, Box} from 'grommet'
import {SRSecondaryChip} from 'sr-react-commons'
import {format} from 'date-fns'
import React from 'react'
import {CREATED_DATE_CELL_WIDTH, VERSION_CELL_WIDTH} from '../form/steps/ManagementDashboardAnalysisRequestForm.step1'
import {ModelEntity} from '../../../Models/model.entities'

export const ManagementDashboardAnalysisRequestActiveModelTable = ({
	model,
	rootModels = [],
}: {
	model: ModelEntity
	rootModels?: string[]
}) => {
	return (
		<Box pad={{left: 'medium'}}>
			<ManagementDashboardTable>
				<ManagementDashboardTableRowBox size={'small'}>
					<ManagementDashboardTableHeaderCell flexible width={{min: FILE_NAME_CELL_MIN_WIDTH}}>
						Model file name
					</ManagementDashboardTableHeaderCell>
					<ManagementDashboardTableHeaderCell width={{min: VERSION_CELL_WIDTH}}>
						Version
					</ManagementDashboardTableHeaderCell>
					<ManagementDashboardTableHeaderCell width={CREATED_DATE_CELL_WIDTH}>
						Date created
					</ManagementDashboardTableHeaderCell>
				</ManagementDashboardTableRowBox>
				<ManagementDashboardTableRowBox size={'small'}>
					<ManagementDashboardTableCell flexible width={{min: FILE_NAME_CELL_MIN_WIDTH}}>
						<Text alignSelf="center" title={model.name} truncate>
							{model.name}
						</Text>
					</ManagementDashboardTableCell>
					<ManagementDashboardTableCell width={{min: VERSION_CELL_WIDTH}}>
						<SRSecondaryChip text={`VERSION ${model.modelVersion.toString()}`} />
					</ManagementDashboardTableCell>
					<ManagementDashboardTableCell width={CREATED_DATE_CELL_WIDTH}>
						{format(new Date(model.createdDate), 'dd/MM/yyyy HH:mm')}
					</ManagementDashboardTableCell>
				</ManagementDashboardTableRowBox>

				{rootModels.map(name => (
					<ManagementDashboardTableRowBox key={name} size={'small'}>
						<ManagementDashboardTableCell flexible width={{min: FILE_NAME_CELL_MIN_WIDTH}}>
							<Text alignSelf="center" title={name} truncate size="12px">
								{name}
							</Text>
						</ManagementDashboardTableCell>
					</ManagementDashboardTableRowBox>
				))}
			</ManagementDashboardTable>
		</Box>
	)
}
