import {Filters} from './filters/Filters'
import React from 'react'
import {FilterPresetsMode} from './FilterPresets'
import {AccordionPanel, Box} from 'grommet'
import {BorderLessAccordion} from '../../../components/Accordions/BorderLessAccordion'
import {SRLoadingOverlay} from '../../../components/CommonsCandidate/Loading/SRLoadingOverlay'
import {useDispatch, useSelector} from 'react-redux'
import {selectorFilterPresets} from '../selectors/filter-preset.selectors'
import {SRSecondaryButton} from 'sr-react-commons'
import {Refresh} from 'grommet-icons'
import {watchlistFilterElementsSlice} from '../../Watchlist/slices/watchlist-filter-elements.slice'

export function WatchlistEditCreateFilter({mode, readonly}: {mode: FilterPresetsMode; readonly: boolean}) {
	const {loading} = useSelector(selectorFilterPresets)
	const dispatch = useDispatch()
	const resetFilters = () => dispatch(watchlistFilterElementsSlice.actions.resetFilter())
	return (
		<Box elevation="small" pad={{horizontal: 'xsmall'}} background={'light-3'} round="xsmall">
			<BorderLessAccordion>
				<AccordionPanel data-testid={'filter-presets-accordion'} label="Filters">
					<SRLoadingOverlay active={loading}>
						<Box fill gap="small" pad={{vertical: 'small', horizontal: 'xxsmall'}}>
							<Box direction="row">
								<SRSecondaryButton
									label="Reset all filters"
									data-testid="reset-button"
									onClick={() => {
										resetFilters()
									}}
									size={'medium'}
									icon={<Refresh size="small" />}
									reverse
									disabled={readonly}
								/>
							</Box>
							<Filters mode={mode} readonly={readonly} />
						</Box>
					</SRLoadingOverlay>
				</AccordionPanel>
			</BorderLessAccordion>
		</Box>
	)
}
