import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {useDispatch, useSelector} from 'react-redux'
import {Section} from '../../../../reducers/classification'
import {useMemo} from 'react'
import {RootState} from '../../../../reducers'
import {LineProps} from '../components/DistanceMeasureLine'

export type SectionSliceState = {
	sections: Section[] | null
	currentSection: Section | null
	currentSectionIndex: number
	isOpened: boolean
	measurementsBySectionId: {
		[Key: string]: LineProps[]
	}
}

const buildInitialState = (): SectionSliceState => ({
	sections: null,
	currentSection: null,
	currentSectionIndex: 0,
	isOpened: false,
	measurementsBySectionId: {},
})

const incrIndex = ({sections, currentSectionIndex}: SectionSliceState, amount: number) =>
	!sections ? 0 : (sections.length + currentSectionIndex + amount) % sections.length

const stateForIndex = (state: SectionSliceState, index: number) => ({
	...state,
	currentSection: state.sections?.[index] || null,
	currentSectionIndex: index,
})

function applyAddMeasurement(
	state: SectionSliceState,
	{payload: {sectionId, measurement}}: PayloadAction<{sectionId: string; measurement: LineProps}>,
) {
	let newMeasurements: LineProps[]
	let measurements = state.measurementsBySectionId[sectionId]
	if (measurements && measurements.length > 0) {
		measurement.id = Math.max(...measurements.map(el => el.id || 0)) + 1
		newMeasurements = [...measurements, measurement]
	} else {
		measurement.id = 0
		newMeasurements = [measurement]
	}
	return {
		...state,
		measurementsBySectionId: {
			...state.measurementsBySectionId,
			[sectionId]: newMeasurements,
		},
	}
}

function applyRemoveMeasurement(
	state: SectionSliceState,
	{payload: {sectionId, measurementId}}: PayloadAction<{sectionId: string; measurementId: number}>,
) {
	const oldMeasurements = state.measurementsBySectionId[sectionId]
	return {
		...state,
		measurementsBySectionId: {
			...state.measurementsBySectionId,
			[sectionId]: oldMeasurements ? oldMeasurements.filter(el => el.id !== measurementId) : [],
		},
	}
}

function applyClearMeasurements(state: SectionSliceState, {payload: {sectionId}}: PayloadAction<{sectionId: string}>) {
	return {
		...state,
		measurementsBySectionId: {
			...state.measurementsBySectionId,
			[sectionId]: [],
		},
	}
}

export const sectionSlice = createSlice({
	name: 'section',
	initialState: buildInitialState(),
	reducers: {
		setSections: (state, action: PayloadAction<Section[] | null>) => ({
			...state,
			sections: action.payload,
			currentSection: action.payload?.[0] || null,
			currentSectionIndex: 0,
		}),
		nextSection: state => stateForIndex(state, incrIndex(state, 1)),
		prevSection: state => stateForIndex(state, incrIndex(state, -1)),
		setIsOpened: (state, action: PayloadAction<boolean>) => ({...state, isOpened: action.payload}),
		resetSectionState: () => buildInitialState(),
		addMeasurement: applyAddMeasurement,
		removeMeasurement: applyRemoveMeasurement,
		clearMeasurements: applyClearMeasurements,
	},
})

export function useSectionState() {
	const dispatch = useDispatch()
	const state = useSelector((state: RootState) => state.section)
	const actions = useMemo(
		() => ({
			setSections: (sections: Section[] | null) => {
				dispatch(sectionSlice.actions.setSections(sections))
			},
			nextSection: () => {
				dispatch(sectionSlice.actions.nextSection())
			},
			prevSection: () => {
				dispatch(sectionSlice.actions.prevSection())
			},
			setIsOpened: (value: boolean) => {
				dispatch(sectionSlice.actions.setIsOpened(value))
			},
			resetSectionState: () => {
				dispatch(sectionSlice.actions.resetSectionState())
			},
		}),
		[dispatch],
	)
	return {...state, ...actions}
}
