import React, {ReactNode, useState} from 'react'
import {Box, ButtonProps, DropButton} from 'grommet'
import {CaretDownFill, CaretUpFill} from 'grommet-icons'
import styled from 'styled-components'
import {SRTextInput} from 'sr-react-commons'

const FilterDropDownTextInput = styled(SRTextInput)`
	width: 100%;
`

export const FilterDropdownButton = ({
	children,
	label,
	placeholder,
	disabled,
}: {
	children: JSX.Element
	placeholder: string
	label?: ReactNode
	disabled?: ButtonProps['disabled']
}) => {
	const [isOpen, setOpen] = useState<boolean>(false)
	return (
		<DropButton
			style={{boxShadow: 'initial', borderWidth: '1px', padding: '0'}}
			dropProps={{overflow: 'hidden'}}
			dropContent={children}
			dropAlign={{top: 'bottom'}}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			disabled={disabled}
			label={
				<Box direction={'row'} justify={'between'}>
					{typeof label === 'string' ? (
						<FilterDropDownTextInput
							readOnly
							placeholder={placeholder}
							value={label || ''}
							style={{border: 'none', pointerEvents: 'none'}}
						/>
					) : (
						<>{label}</>
					)}
					<Box alignSelf={'center'} margin={{horizontal: 'xsmall'}} style={{minWidth: 'auto'}}>
						{isOpen ? <CaretUpFill /> : <CaretDownFill />}
					</Box>
				</Box>
			}
		/>
	)
}
