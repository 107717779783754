import {Viewsphere} from '../../../reducers/classification'
import {coordinateFromIFCtoViewer, raycastMouseEvent, scaleFromIFCtoViewer} from '../../NaskaUtilities'
import {getVerticalToolBar, removeMeshAndDisposeMaterial} from '../../Viewer/Viewer-helper'
import {store} from '../../../store/store'
import {doToggleThreeSixtyOpened} from '../../../actions/viewer'
import {VERTICAL_CONTROL_GROUP} from '../Viewing.Extension.VerticalToolbar/Viewing.Extension.VerticalToolbar'
import PhotoSpherePanel from './PhotoSpherePanel'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import './Viewing.Extension.ThreeSixtyPhotos.scss'
import {normalizeExtremeLatitudeValue} from './PhotoSphere.utilities'
import {ClassificationEntity} from '../../../features/Classification/classification.entities'

export const PHOTO_SPHERE_EXTENSION_ID = 'Viewing.Extension.ThreeSixtyPhotos'

const Autodesk = window.Autodesk
const THREE = window.THREE

const VIEWSPHERE_RADIUS = 0.22
const VIEWSPHERE_MATERIAL_DEFAULT = new THREE.MeshBasicMaterial({
	color: 0x491734,
})
const VIEWSPHERE_MATERIAL_SELECTED = new THREE.MeshBasicMaterial({
	color: 0xff4040,
})
const VIEWSPHERE_MATERIAL_HOVERED = new THREE.MeshBasicMaterial({
	color: 0x00c781,
})
const VIEWSPHERE_SCENE_ID = 'viewSphereScene'

export type ViewSphereMeshWrapper = {imagePath: string; mesh: THREE.Mesh}

export type PhotoshpereExtensionOptions = {
	setActiveViewSphere: (viewsphere: Viewsphere) => void
	setCameraFov: React.Dispatch<React.SetStateAction<number>>
	setCameraOrientation: React.Dispatch<React.SetStateAction<[number, number]>>
}

export interface PhotoSphereExtensionI {
	setViewsLocked(newValue: boolean): void
	setSelectedClassification(classification: ClassificationEntity | null): void
	setSelectedViewSphere(viewSphere: Viewsphere | null): void
	setVisible(visible: boolean): void
}

export default class PhotoSphereExtension extends Autodesk.Viewing.Extension implements PhotoSphereExtensionI {
	private classification: ClassificationEntity | null = null
	private renderedViewSpheres: ViewSphereMeshWrapper[]
	private selectedViewSphere: Viewsphere | null = null
	private hoveredViewSphere: Viewsphere | null = null
	private readonly setActiveViewSphere: (viewsphere: Viewsphere) => void
	private readonly setCameraFov: React.Dispatch<React.SetStateAction<number>>
	private readonly setCameraOrientation: React.Dispatch<React.SetStateAction<[number, number]>>
	private viewsLocked: boolean = false
	private subToolbar: Autodesk.Viewing.UI.ControlGroup | undefined
	private toolbarButton: Autodesk.Viewing.UI.Button | null = null
	private panel: PhotoSpherePanel | null = null

	constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: PhotoshpereExtensionOptions) {
		super(viewer, options)
		this.setActiveViewSphere = options.setActiveViewSphere
		this.setCameraFov = options.setCameraFov
		this.setCameraOrientation = options.setCameraOrientation
		this.renderedViewSpheres = []
		this.onBuildingContextMenuItem = this.onBuildingContextMenuItem.bind(this)
	}

	get menuId() {
		return 'PhotoSphereContextMenu'
	}

	setVisible(visible: boolean) {
		this.panel?.setVisible(visible)
	}

	setViewsLocked = (newValue: boolean) => {
		this.viewsLocked = newValue
	}

	setSelectedClassification(classification: ClassificationEntity | null) {
		this.classification = classification
		this.renderViewSpheres()
	}

	setSelectedViewSphere(viewSphere: Viewsphere | null) {
		this.selectedViewSphere = viewSphere
		this.renderViewSpheres()
	}

	clearViewSpheres() {
		for (const viewSphere of this.renderedViewSpheres) {
			removeMeshAndDisposeMaterial(this.viewer, VIEWSPHERE_SCENE_ID, viewSphere.mesh)
		}
		this.renderedViewSpheres = []
		this.viewer.overlays.clearScene(VIEWSPHERE_SCENE_ID)
	}

	onToolbarCreated = () => {
		this.viewer.hasOwnProperty(Autodesk.Viewing.TOOLBAR_CREATED_EVENT) &&
			this.viewer.removeEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreated)
		this.createUI()
	}

	createUI() {
		// Toolbar button UI bindings
		this.toolbarButton = new Autodesk.Viewing.UI.Button('ThreeSixtyPhotoCode')
		this.toolbarButton.addClass('photoSphereToolbarButton')
		this.toolbarButton.setToolTip('Display 360 image for the selected element')
		this.toolbarButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE)
		this.toolbarButton.onClick = () => store.dispatch(doToggleThreeSixtyOpened())

		this.subToolbar =
			(getVerticalToolBar(this.viewer).getControl(VERTICAL_CONTROL_GROUP) as Autodesk.Viewing.UI.ControlGroup) ||
			new Autodesk.Viewing.UI.ControlGroup(VERTICAL_CONTROL_GROUP)
		this.subToolbar!.addControl(this.toolbarButton, {index: 0})
		this.initPanel()
	}

	initPanel() {
		this.panel = new PhotoSpherePanel(this.viewer)
		this.panel.addVisibilityListener(this.onPanelVisibilityChanges)
	}

	isActive = () => selectorViewerProperties(store.getState()).threeSixtyOpened

	popupMode = () => selectorViewerProperties(store.getState()).assetsMode === 'popup'

	lockedView = () => selectorViewerProperties(store.getState()).threeSixtyLockedView

	onPanelVisibilityChanges = () => {
		if (this.isActive() && this.popupMode() && !this.panel!.isVisible()) {
			store.dispatch(doToggleThreeSixtyOpened())
		}
		if (this.isActive()) {
			this.toolbarButton!.removeClass('photoSphereToolbarButton')
			this.toolbarButton!.addClass('photoSphereToolbarButtonBlue')
		} else {
			this.toolbarButton!.removeClass('photoSphereToolbarButtonBlue')
			this.toolbarButton!.addClass('photoSphereToolbarButton')
		}
		this.toggleViewspheresPosesVisibility(this.isActive())
	}

	private toggleViewspheresPosesVisibility(visibility: boolean) {
		this.renderedViewSpheres.map(poseIcon => (poseIcon.mesh.visible = visibility))
	}

	private renderViewSpheres() {
		if (!this.viewer.impl) return
		if (this.classification) {
			this.renderedViewSpheres = this.displayViewSpheresPoses()
		} else {
			this.clearViewSpheres()
		}
		this.viewer.impl.invalidate(false, false, true)
	}

	private displayViewSpheresPoses() {
		if (!this.viewer.model || !this.classification) {
			return []
		}
		const viewSphereMeshes = []
		for (const viewSphere of this.classification.viewspheres) {
			if (this.viewsLocked && viewSphere.imagePath === this.selectedViewSphere?.imagePath) continue
			let renderedViewSphere = this.renderedViewSpheres.find(v => v.imagePath === viewSphere.imagePath)
			if (!renderedViewSphere) {
				renderedViewSphere = this.addViewSphereToScene(viewSphere)
			}

			renderedViewSphere.mesh.material =
				viewSphere.imagePath === this.hoveredViewSphere?.imagePath
					? VIEWSPHERE_MATERIAL_HOVERED
					: viewSphere.imagePath === this.selectedViewSphere?.imagePath
					? VIEWSPHERE_MATERIAL_SELECTED
					: VIEWSPHERE_MATERIAL_DEFAULT
			viewSphereMeshes.push(renderedViewSphere)
		}

		// Remove viewspheres that no longer should be displayed
		for (const renderedViewSphere of this.renderedViewSpheres) {
			const shouldBeRendered =
				this.classification.viewspheres.some(viewSphere => viewSphere.imagePath === renderedViewSphere.imagePath) &&
				!(this.viewsLocked && this.selectedViewSphere?.imagePath === renderedViewSphere.imagePath)
			if (!shouldBeRendered) {
				removeMeshAndDisposeMaterial(this.viewer, VIEWSPHERE_SCENE_ID, renderedViewSphere.mesh)
			}
		}

		return viewSphereMeshes
	}

	private addViewSphereToScene(viewSphere: Viewsphere) {
		const coordinates = coordinateFromIFCtoViewer(
			this.viewer.model,
			viewSphere.poseCoordinates[0],
			viewSphere.poseCoordinates[1],
			viewSphere.poseCoordinates[2],
		)

		const viewSpherePosePosition = new THREE.Vector3(coordinates[0], coordinates[1], coordinates[2])

		// Orientation is not needed at the moment but we attach it for completeness
		const viewSpherePoseOrientation = new THREE.Quaternion(
			viewSphere.poseOrientation[0],
			viewSphere.poseOrientation[1],
			viewSphere.poseOrientation[2],
			viewSphere.poseOrientation[3],
		).normalize()

		const geometry = new THREE.SphereGeometry(VIEWSPHERE_RADIUS * scaleFromIFCtoViewer(this.viewer.model), 16, 16)
		const viewSpherePoseIcon = new THREE.Mesh(geometry, VIEWSPHERE_MATERIAL_DEFAULT)
		const viewSpherePoseIconMatrix = new THREE.Matrix4()
		viewSpherePoseIconMatrix.compose(viewSpherePosePosition, viewSpherePoseOrientation, new THREE.Vector3(1, 1, 1))
		viewSpherePoseIcon.applyMatrix(viewSpherePoseIconMatrix)
		viewSpherePoseIcon.visible = true
		viewSpherePoseIcon.userData['viewSphereImagePath'] = viewSphere.imagePath
		this.viewer.overlays.addMesh(viewSpherePoseIcon, VIEWSPHERE_SCENE_ID)
		return {imagePath: viewSphere.imagePath, mesh: viewSpherePoseIcon}
	}

	onBuildingContextMenuItem(menu: Autodesk.Viewing.ContextMenuItem[]) {
		const removeItemFromMenu = (itemToRemove: string) => {
			const itemIndex = menu.findIndex(menuItem => menuItem.title === itemToRemove)
			if (itemIndex > -1) {
				menu.splice(itemIndex, 1)
			}
		}
		if (this.viewsLocked) {
			removeItemFromMenu('Focus')
			removeItemFromMenu('Pivot')
			removeItemFromMenu('Section')
		}
	}

	// Overrides single click event when hovering a view sphere
	handleSingleClick() {
		let eventInterruption = false

		if (this.hoveredViewSphere) {
			const imagePathOfHoveredItem = this.hoveredViewSphere.imagePath
			const viewSphere = this.classification!.viewspheres.find(
				viewSphere => viewSphere.imagePath === imagePathOfHoveredItem,
			)!
			this.setActiveViewSphere(viewSphere)

			eventInterruption = true
		}

		return eventInterruption
	}

	// Avoids any interaction on double click
	handleDoubleClick() {
		let eventInterruption = false

		if (this.hoveredViewSphere) {
			eventInterruption = true
		}

		if (this.viewsLocked) {
			eventInterruption = true
		}

		return eventInterruption
	}

	handleKeyDown() {
		if (this.viewsLocked) {
			return true
		}
	}

	handleWheelInput(delta: number) {
		if (this.viewsLocked) {
			this.setCameraFov(current => Math.min(Math.max(current + delta * -1, 5), 120))
			return true
		} else {
			return false
		}
	}

	// Handles mouse hover effects on viewSphere pose icons
	handleMouseMove(event: MouseEvent) {
		let eventInterruption = false
		const hoveredItems = raycastMouseEvent(
			event,
			this.viewer,
			this.renderedViewSpheres.map(v => v.mesh),
			[],
		)
		let newHoveredViewSphere: Viewsphere | undefined
		if (hoveredItems.length) {
			eventInterruption = true
			newHoveredViewSphere = this.classification?.viewspheres.find(
				viewsphere => viewsphere.imagePath === hoveredItems[0].object.userData['viewSphereImagePath'],
			)
		} else {
			newHoveredViewSphere = undefined
		}

		if (newHoveredViewSphere?.imagePath !== this.hoveredViewSphere?.imagePath) {
			this.hoveredViewSphere = newHoveredViewSphere || null
			this.renderViewSpheres()
		}

		if (this.viewsLocked && event.buttons === 1) {
			//Calculate changes from new camera position
			this.setCameraOrientation(([currentLat, currentLon]) => {
				let lat = event.movementY * 0.1 + currentLat
				lat = normalizeExtremeLatitudeValue(lat)
				const lon = (event.movementX * -0.1 + currentLon) % 360
				return [lat, lon]
			})
			eventInterruption = true
		}

		return eventInterruption
	}

	handleButtonDown() {
		return this.viewsLocked
	}

	handleButtonUp() {
		return this.viewsLocked
	}

	getNames() {
		return [PHOTO_SPHERE_EXTENSION_ID]
	}

	load() {
		this.setupViewSphereScene()

		// this seems to be necessary because it seems that when the viewer is torn down the materials are disposed
		this.viewer.impl.matman().addMaterial('SR_VIEWSPHERE_MATERIAL_DEFAULT', VIEWSPHERE_MATERIAL_DEFAULT, true)
		this.viewer.impl.matman().addMaterial('SR_VIEWSPHERE_MATERIAL_HOVER', VIEWSPHERE_MATERIAL_HOVERED, true)
		this.viewer.impl.matman().addMaterial('SR_VIEWSPHERE_MATERIAL_SELECTED', VIEWSPHERE_MATERIAL_SELECTED, true)

		// Register and activate extension itself as a tool in order to handle mouse events
		this.viewer.toolController.registerTool(this)
		this.viewer.toolController.activateTool(PHOTO_SPHERE_EXTENSION_ID)

		this.viewer.registerContextMenuCallback(this.menuId, this.onBuildingContextMenuItem)

		return true
	}

	// This is important because otherwise it seems to happen that other custom tools (I assume the bimwalk extension) have
	// a higher priority and then the handleWheelInput() of this extension might not be called
	getPriority() {
		return 10000
	}

	unload() {
		this.teardownViewSphereScene()
		this.viewer.toolController.deactivateTool(PHOTO_SPHERE_EXTENSION_ID)
		this.viewer.toolController.deregisterTool(this)
		return true
	}

	private setupViewSphereScene() {
		if (!this.viewer.overlays.hasScene(VIEWSPHERE_SCENE_ID)) {
			this.viewer.overlays.addScene(VIEWSPHERE_SCENE_ID)
		}
	}

	private teardownViewSphereScene() {
		if (!this.viewer.overlays.hasScene(VIEWSPHERE_SCENE_ID)) {
			this.clearViewSpheres()
			this.viewer.overlays.removeScene(VIEWSPHERE_SCENE_ID)
		}
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(PHOTO_SPHERE_EXTENSION_ID, PhotoSphereExtension)
