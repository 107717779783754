import React, {useState} from 'react'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {Box, Layer, Text} from 'grommet'
import {Close, StatusWarning} from 'grommet-icons'
import {SR_COLOR_ACCENT_1, SRHeading, SRIconButton, SRPlainButton, SRPrimaryButton} from 'sr-react-commons'
import {deleteCurrentForgeIntegration} from '../forge-integration.api'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'
import {getIntegrationName} from '../forge-integration.utils'
import {ForgeIntegrationEntity} from '../forge-integration.entities'
import {config} from '../../../config'

export type ForgeIntegrationDisconnectIntegrationModalProps = {
	close: () => void
	onDisconnect: () => void
	integration?: ForgeIntegrationEntity | null
}

export const ForgeIntegrationDisconnectIntegrationModal = ({
	close,
	onDisconnect,
	integration,
}: ForgeIntegrationDisconnectIntegrationModalProps) => {
	const project = useCurrentProject()
	const [disconnectLoading, setDisconnectLoading] = useState(false)
	const onDisconnectClick = async () => {
		setDisconnectLoading(true)
		try {
			await deleteCurrentForgeIntegration(project!._id)
			onDisconnect()
		} finally {
			setDisconnectLoading(false)
		}
	}
	return (
		<Layer position={'top'} margin={{top: '100px'}}>
			<Box gap={'medium'} pad={'medium'} width={'525px'}>
				<Box justify={'between'} direction={'row'}>
					<SRHeading level={4}>Disconnect from Autodesk</SRHeading>
					<SRIconButton icon={<Close />} onClick={close} alignSelf={'center'} />
				</Box>
				<Box gap="medium" direction={'row'}>
					<StatusWarning size="56px" color={SR_COLOR_ACCENT_1} />
					<Box gap="xsmall">
						<SRHeading level={5}>Do you want to disconnect from Autodesk?</SRHeading>
						<Text>
							You won’t be able to see files hosted on {getIntegrationName(integration)} on the {config.sr.companyName}{' '}
							Platform anymore.
						</Text>
					</Box>
				</Box>
				<Box direction="row" justify="between">
					<SRPlainButton onClick={close} label="Cancel" size={'medium'} />
					<SRPrimaryButton
						label={'Disconnect from Autodesk'}
						disabled={disconnectLoading}
						icon={!disconnectLoading ? undefined : <Spinner size={'xsmall'} color={'darker'} />}
						onClick={onDisconnectClick}
					/>
				</Box>
			</Box>
		</Layer>
	)
}
