import {UserProject} from '../../../../reducers/userProfile'
import {getForgeFolderContents} from '../../forge-integration.api'
import {ForgeFileType, ForgeFolder, ForgeItem} from '../../forge-integration.entities'
import {
	SRFileExplorerBaseNode,
	SRFileExplorerFileNode,
	SRFileExplorerFolderNode,
} from '../../../../components/CommonsCandidate/FileExplorer/SRFileExplorer.logic'

export const getChildrenNodesFactory = (
	projectId: UserProject['_id'],
	fileType: ForgeFileType,
): ((folderNode?: ForgeFSFolderNode | null) => Promise<ForgeFSNode[]>) => {
	return async folderNode => {
		return (await getForgeFolderContents(projectId, folderNode?.folderId || 'root', fileType)).map(el =>
			el.type === 'item'
				? new ForgeFSFileNode(el, folderNode || null)
				: new ForgeFSFolderNode(el, folderNode || null, getChildrenNodesFactory(projectId, fileType).bind(undefined)),
		)
	}
}

export class ForgeFSBaseNode<ENTITY extends ForgeItem | ForgeFolder> implements SRFileExplorerBaseNode {
	constructor(protected entity: ENTITY, public readonly parent: ForgeFSFolderNode | null) {}
	get id() {
		return this.entity.id
	}
	get name() {
		return this.entity.displayName
	}
	get date() {
		return this.entity.lastModifiedTime
	}
}

export class ForgeFSFileNode extends ForgeFSBaseNode<ForgeItem> implements SRFileExplorerFileNode {
	leaf = true as const
	selected = false
	toggleSelected = () => {
		this.selected = !this.selected
	}
}

export class ForgeFSFolderNode extends ForgeFSBaseNode<ForgeFolder> implements SRFileExplorerFolderNode {
	constructor(
		entity: ForgeFolder,
		parent: ForgeFSFolderNode | null,
		protected getChildrenNodes: (folderNode: ForgeFSFolderNode) => Promise<ForgeFSNode[]>,
	) {
		super(entity, parent)
	}
	leaf = false as const
	children: ForgeFSNode[] | null = null
	expanded = false
	_loading = false
	get loading() {
		return this._loading
	}
	get folderId() {
		return this.entity.id
	}
	toggleExpanded = async () => {
		this.expanded = !this.expanded
		if (this.expanded && this.children === null && !this._loading) {
			this._loading = true
			this.children = await this.getChildrenNodes(this)
			this._loading = false
		}
	}
}

export type ForgeFSNode = ForgeFSFileNode | ForgeFSFolderNode
