import {Box} from 'grommet'
import React from 'react'
import {SRHeading, SRTextInput} from 'sr-react-commons'
import {FormSearch} from 'grommet-icons'

export function AnalysisDescriptionFilter({
	filters,
	setFilter,
}: {
	filters: {analysisName: string | null}
	setFilter: (key: 'analysisName', value: string | null) => void
}) {
	return (
		<Box>
			<SRHeading level={6}>Description</SRHeading>
			<SRTextInput
				icon={<FormSearch />}
				placeholder={'Type analysis description'}
				value={filters.analysisName || ''}
				onChange={event => setFilter('analysisName', event.target.value === '' ? null : event.target.value)}
			/>
		</Box>
	)
}
