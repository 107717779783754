import {SRDropButton, SRIconButton} from 'sr-react-commons'
import React from 'react'
import {MoreVertical} from 'grommet-icons'
import {WorkPackageDetailExportMenuActionList} from './WorkPackageDetailExportMenuActionList'

export function WorkPackageDetailExportMenu(props: {
	onExportAsPDF: () => void
	exportToCSVProps: {
		fetchFile: () => Promise<Blob>
		filename: string
	}
}) {
	const [menuOpen, setMenuOpen] = React.useState(false)
	return (
		<SRDropButton
			buttonClass={SRIconButton}
			size="large"
			icon={<MoreVertical size={'large'} />}
			dropAlign={{top: 'bottom', right: 'right'}}
			open={menuOpen}
			onOpen={() => setMenuOpen(true)}
			onClose={() => setMenuOpen(false)}
			dropContent={
				<WorkPackageDetailExportMenuActionList
					onExportAsPDF={props.onExportAsPDF}
					onExportToCSVProps={{
						filename: props.exportToCSVProps.filename,
						fetchFile: () =>
							props.exportToCSVProps.fetchFile().then(blob => {
								setMenuOpen(false)
								return blob
							}),
					}}
				/>
			}
		/>
	)
}
