import {VictoryAxis, VictoryChart, VictoryHistogram, VictoryLabel} from 'victory'
import React, {useCallback} from 'react'
import {useTheme} from 'styled-components'
import {normalizeColor} from 'grommet/utils'
import _ from 'lodash'
import {useFilterStateForMode} from '../../../FilterPresets/components/filters/Filters'
import {insightDashboardTheme} from './chartTheme'
import {
	abbrNumber,
	FILL_OPACITY,
	HOVER_FILL_OPACITY,
	INSIGHTS_CHART_WIDTH,
	setChartItemOpacity,
} from '../../InsightDashboard-helper'

export interface MagnitudeDistributionChartProps {
	magnitudes: number[]
	binSize: number
	maxDomain: number
}

type MagnitudeChartBin = {to: number; from: number}

export function MagnitudeDistributionChart({
	binSize = 10,
	magnitudes,
	maxDomain = 100,
}: MagnitudeDistributionChartProps): JSX.Element {
	const MAGNITUDE_HEIGHT = 250
	const FILTER_MODE = 'overview'
	const deviatedColor = normalizeColor('status-deviated', useTheme())
	const minDomain = Math.floor(Math.min(...magnitudes) / 10) * 10
	const maxData = Math.ceil(Math.max(...magnitudes) / 10) * 10
	maxDomain = maxDomain + binSize
	const dataWithinLimits = magnitudes.map(magnitude => ({x: _.clamp(magnitude, 0, maxDomain)}))
	const maxDataExceedsMaxDomain = maxData > maxDomain
	const binCount = Math.ceil((maxDomain - minDomain) / binSize) + (maxDataExceedsMaxDomain ? 1 : 0)
	const bins = _.range(binCount < 0 ? 0 : binCount).map(i => minDomain + i * binSize)
	const formatMagnitudeTicks = useCallback(
		(t: number) => (maxDataExceedsMaxDomain && t === bins[bins.length - 1] ? `${maxData} mm` : `${t} mm`),
		[maxDataExceedsMaxDomain, bins, maxData],
	)
	const {setPartialFilter} = useFilterStateForMode(FILTER_MODE)
	const getBinRange = (index: number): MagnitudeChartBin => {
		return {to: bins[index + 1], from: bins[index]}
	}
	const getIndexFromId = (targetId: string): number => Number.parseInt(_.last(targetId.split('-')) as string, 10)

	return (
		<svg viewBox={`15 25 410 210`} preserveAspectRatio="none">
			<VictoryChart
				theme={insightDashboardTheme}
				standalone={false}
				height={MAGNITUDE_HEIGHT}
				width={INSIGHTS_CHART_WIDTH}
				events={[
					{
						childName: 'all',
						target: 'data',
						eventHandlers: {
							onClick: (evt, data) => {
								const from: number = data.datum.x0
								const to: number = data.datum.x1
								setPartialFilter({magnitude: {from: from, to: to}})
							},
							onMouseOver: evt => {
								setChartItemOpacity(evt, HOVER_FILL_OPACITY)
								evt.currentTarget.style['fill-opacity'] = FILL_OPACITY
								evt.currentTarget.style['cursor'] = 'pointer'
							},
							onMouseOut: evt => {
								setChartItemOpacity(evt, FILL_OPACITY)
							},
						},
					},
				]}
			>
				<VictoryAxis
					style={{
						grid: {stroke: 'none'},
						ticks: {size: 5},
						tickLabels: {angle: -45},
					}}
					tickCount={10}
					tickFormat={formatMagnitudeTicks}
					crossAxis
					standalone={false}
				/>
				<VictoryAxis
					dependentAxis
					tickFormat={t => abbrNumber(t)}
					style={{
						tickLabels: {padding: 5},
					}}
					standalone={false}
					crossAxis={false}
				/>
				<VictoryHistogram
					data={dataWithinLimits}
					bins={bins}
					binSpacing={2}
					style={{
						data: {fill: deviatedColor, stroke: 'none'},
					}}
					labels={({datum}) => (datum.y > 0 ? abbrNumber(datum.y) : null)}
					labelComponent={
						<VictoryLabel
							angle={-90}
							dy={0}
							dx={3}
							style={{
								fontFamily: 'Inter, sans-serif',
								fontSize: '10px',
								letterSpacing: 'normal',
								fill: 'rgb(107, 113, 123)',
							}}
							textAnchor="start"
							verticalAnchor="middle"
							events={{
								onClick: evt => {
									setPartialFilter({magnitude: getBinRange(getIndexFromId(evt.currentTarget.id))})
								},
								onMouseEnter: evt => {
									const parentChildren = evt.currentTarget.parentNode.children
									setChartItemOpacity(evt, HOVER_FILL_OPACITY)
									evt.currentTarget.style['cursor'] = 'pointer'
									parentChildren[getIndexFromId(evt.currentTarget.id)].style['fill-opacity'] = FILL_OPACITY
								},
								onMouseOut: evt => {
									setChartItemOpacity(evt, FILL_OPACITY)
								},
							}}
						/>
					}
					standalone={false}
				/>
			</VictoryChart>
		</svg>
	)
}
