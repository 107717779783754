import {fetchClassifications} from '../api/classification.api'
import {
	ClassificationTableRow,
	MagnitudeDescription,
} from '../../../components/DataTableContainer/UseClassificationsTable'
import _ from 'lodash'
import {getMappedElementAttributes} from '../../../entities/element.entities'
import {FiltersEntity, toFiltersDTO} from '../../FilterPresets/entities/filter-preset.entities'
import {UserProject} from '../../../reducers/userProfile'
import {AnalysisVersionMap, ClassificationSortState} from '../../../reducers/classification.slice'
import {PageState} from '../../../reducers/base.pagination.state'
import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {PaginationResponse} from '../../../api/api.types'
import React from 'react'
import {AnalysisViewEntityPopulated} from '../../AnalysisView/analysis-view.entities'
import {
	getClassificationStatusWithoutUnderConstruction,
	getDisplayValueForClassificationStatus,
	translateSortingWhenUnderConstructionDisabled,
} from '../classification.helpers'
import {useSelector} from 'react-redux'
import {selectorApprovedFilter} from '../../../reducers/viewerReducer'
import {CLASSIFICATIONS_BASE_QUERY_KEY} from './index'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'
import {MagnitudeSign} from '../../../reducers/classification'
import {ClassificationEntity, ClassificationEntityWithElementRef} from '../classification.entities'

function computeMagnitudeDescription(magnitudePerAxis: ClassificationEntity['magnitudePerAxis']) {
	if (!magnitudePerAxis) return '-'
	const descriptors = [
		...(magnitudePerAxis.magnitudeXYDisplay > 0.01 ? [MagnitudeDescription.DISPLACED] : []),
		...(magnitudePerAxis.magnitudeZDisplay > 0.01
			? [
					magnitudePerAxis.magnitudeZSign === MagnitudeSign.POSITIVE
						? MagnitudeDescription.BUILT_TOO_HIGH
						: MagnitudeDescription.BUILT_TOO_LOW,
			  ]
			: []),
	]
	return descriptors.length ? descriptors.join(', ') : '-'
}

function prepareClassificationsForTable(
	classifications: ClassificationEntityWithElementRef[],
	elementTypeMapping: Record<string, string>,
	floorMapping: Record<string, string>,
	enableUnderConstruction: boolean,
	enableDisplayMagnitudeByAxis: boolean,
	enableEOI: boolean,
): ClassificationTableRow[] {
	return classifications.map(cl => {
		let status = cl.status
		if (!enableUnderConstruction) {
			status = getClassificationStatusWithoutUnderConstruction(status)
		}
		let magnitude = cl.magnitudeDisplay
		if (enableDisplayMagnitudeByAxis && cl.magnitudePerAxis) {
			magnitude = cl.magnitudePerAxis.magnitudeDisplay
		}
		const magnitudeDescription = enableDisplayMagnitudeByAxis ? computeMagnitudeDescription(cl.magnitudePerAxis) : ''
		return {
			status: getDisplayValueForClassificationStatus(status),
			magnitude,
			elementId: cl.element._id,
			magnitudeXY: cl.magnitudePerAxis ? cl.magnitudePerAxis.magnitudeXYDisplay : 0,
			magnitudeZ: cl.magnitudePerAxis ? cl.magnitudePerAxis.magnitudeZDisplay : 0,
			magnitudeZSign: cl.magnitudePerAxis ? cl.magnitudePerAxis.magnitudeZSign : MagnitudeSign.POSITIVE,
			description: magnitudeDescription,
			externalId: cl.element.externalId,
			forgeObjectId: cl.element.forgeObjectId,
			..._.pick(cl, !enableEOI ? ['approved'] : ['approved', 'isInteresting']),
			..._.pick(getMappedElementAttributes(cl.element, floorMapping, elementTypeMapping), [
				'name',
				'mappedElementType',
				'mappedFloors',
			]),
		}
	})
}

export function useQueryClassificationTableData(
	analysisView: AnalysisViewEntityPopulated,
	analysisVersionMap: AnalysisVersionMap,
	{pageSize, pageIndex, totalRecords, setTotalRecords}: PageState,
	{sortBy}: ClassificationSortState,
	filters: FiltersEntity,
	{floorMapping, elementTypeMapping}: UserProject,
) {
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const enableMagnitudeByAxis = useFeatureEnabled('displayMagnitudeByAxis')
	const enableEOI = useFeatureEnabled('elementsOfInterest')
	const approvedFilter = useSelector(selectorApprovedFilter)

	const result = useQuery<PaginationResponse<ClassificationEntityWithElementRef>, AxiosError>(
		[
			CLASSIFICATIONS_BASE_QUERY_KEY,
			'classifications-table-page',
			pageIndex,
			pageSize,
			sortBy,
			analysisView,
			filters,
			floorMapping,
			elementTypeMapping,
			approvedFilter,
		],
		async () => {
			const skip = pageSize * pageIndex
			const sorting: {id: string; desc: boolean}[] = sortBy
			let sort = undefined
			if (sorting.length) {
				let translatedSort = sorting.map(sortObject =>
					!enableUnderConstruction
						? {id: translateSortingWhenUnderConstructionDisabled(sortObject.id), desc: sortObject.desc}
						: sortObject,
				)
				if (enableMagnitudeByAxis) {
					translatedSort = translatedSort.map(sortObject =>
						['magnitudeDisplay', 'magnitudeXYDisplay', 'magnitudeZDisplay'].includes(sortObject.id)
							? {id: `magnitudePerAxis.${sortObject.id}`, desc: sortObject.desc}
							: sortObject,
					)
				}
				sort = translatedSort.map(sortObject => (!sortObject.desc ? sortObject.id : '-' + sortObject.id)).join(',')
			}

			return fetchClassifications({
				analysisViewId: analysisView._id,
				analysisVersionMap,
				limit: pageSize,
				skip,
				sort,
				filtersDTO: toFiltersDTO(filters, floorMapping, elementTypeMapping, enableUnderConstruction, approvedFilter),
				tail: enableEOI ? 'for-quality-table' : undefined,
			})
		},
		{staleTime: 60 * 1000, keepPreviousData: true},
	)

	// TODO: This is a side effect, we should change the state where the hook is called from with the response of the query
	React.useEffect(() => {
		if (result.data && result.data.count !== totalRecords) {
			setTotalRecords(result.data.count)
		}
	}, [result, totalRecords, setTotalRecords])

	return {
		...result,
		data: result.data
			? prepareClassificationsForTable(
					result.data.result,
					elementTypeMapping,
					floorMapping,
					enableUnderConstruction,
					enableMagnitudeByAxis,
					enableEOI,
			  )
			: undefined,
	}
}
