import {Box} from 'grommet'
import {SRTooltip} from 'sr-react-commons'
import {StatusWarning} from 'grommet-icons'
import React from 'react'

export function SRHasInvalidIdsIconTooltip() {
	return (
		<Box>
			<SRTooltip
				arrowPos="left"
				tooltipText={'The work package contains Global IDs that don’t exist in the current model'}
				width={175}
			>
				<StatusWarning a11yTitle="warningIcon" size="medium" color={'status-warning'} />
			</SRTooltip>
		</Box>
	)
}
