import {RootState} from '../reducers'

export function selectorForgeSelection(state: RootState) {
	return state.viewerState.properties.forgeObjectIds
}

export function selectorViewerProperties(state: RootState) {
	return state.viewerState.properties
}

export function selectorViewerInstance(state: RootState) {
	return state.viewerState.viewer
}

export function selectorViewerIsReady(state: RootState) {
	const {isReady} = state.viewerState
	return isReady.forgeMapping && isReady.forgeViewer && isReady.classificationsPainted
}
