import {ModelEntity} from '../../../Models/model.entities'
import {ProjectFileEntity} from '../../../../api/files.api'
import {useCurrentProject} from '../../../../hooks/useCurrentProject'
import React, {useCallback, useState} from 'react'
import {goToFn} from '../../../../router/history'
import {Box} from 'grommet'
import {ManagementDashboardAnalysisRequestFormStep0} from './steps/ManagementDashboardAnalysisRequestForm.step0'
import {ManagementDashboardAnalysisRequestFormNoAvailableActiveModelPage} from './ManagementDashboardAnalysisRequestFormNoAvailableActiveModelPage'
import {ManagementDashboardAnalysisRequestFormStep4} from './steps/ManagementDashboardAnalysisRequestForm.step4'
import {ManagementDashboardAnalysisRequestFormInProgress} from './ManagementDashboardAnalysisRequestFormInProgress'
import {AnalysisRequestEntity} from '../../../AnalysisRequest/analysis-request.entities'

export const ManagementDashboardAnalysisRequestForm = ({
	activeModel,
	rootModels,
	scans,
	documents,
}: {
	activeModel: ModelEntity | undefined | null
	rootModels: string[]
	scans: ProjectFileEntity[]
	documents: ProjectFileEntity[]
}) => {
	const [analysisRequest, setAnalysisRequest] = useState<AnalysisRequestEntity>()
	const currentProject = useCurrentProject()
	const [step, setStep] = useState<number>(0)
	const nextStep = useCallback(
		(newAnalysisRequest?: AnalysisRequestEntity) => {
			newAnalysisRequest && setAnalysisRequest(newAnalysisRequest)
			setStep(step + 1)
		},
		[step],
	)
	const exitForm = goToFn(`/manage/${currentProject?._id}/analysisrequest`)
	return (
		<Box flex={false}>
			{step === 0 ? (
				<ManagementDashboardAnalysisRequestFormStep0 nextStep={nextStep} exitForm={exitForm} />
			) : !activeModel ? (
				<ManagementDashboardAnalysisRequestFormNoAvailableActiveModelPage exitForm={exitForm} />
			) : step > 0 && step < 4 ? (
				<ManagementDashboardAnalysisRequestFormInProgress
					step={step}
					nextStep={nextStep}
					activeModel={activeModel}
					rootModels={rootModels}
					scans={scans}
					documents={documents}
					exitForm={exitForm}
				/>
			) : (
				<ManagementDashboardAnalysisRequestFormStep4 exitForm={exitForm} analysisRequest={analysisRequest!} />
			)}
		</Box>
	)
}
