import {Box, Grid} from 'grommet'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {doFetchSelectedClassifications} from '../../actions/classification'
import {doSetClassificationsPainted} from '../../actions/viewer'
import {config} from '../../config'
import {ClassificationEntity} from '../../features/Classification/classification.entities'
import {
	getSimplifiedLocation,
	useClassificationsForColorizeModel,
} from '../../features/Classification/data-management/useClassificationsForColorizeModel'
import {useSelectedClassifications} from '../../features/Classification/data-management/useSelectedClassification'
import {useFeatureEnabled} from '../../features/FeatureFlags/FeatureFlagsProvider'
import {selectorCurrentWatchlist} from '../../features/Watchlist/selectors/watchlist.selectors'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {RootState} from '../../reducers'
import {ClassificationStatus} from '../../reducers/classification'
import {UserProject} from '../../reducers/userProfile'
import {selectorClassificationState} from '../../selectors/classification.selectors'
import {selectorViewerInstance, selectorViewerProperties} from '../../selectors/viewer-state.selector'
import useDebounce from '../DataTableContainer/useDebounce'
import {ElementSectionControlsWrapper} from '../Viewer.Components/Viewing.Extension.ElementSections/components/ElementSectionControlsWrapper'
import {ElementSectionsSplitViewContainer} from '../Viewer.Components/Viewing.Extension.ElementSections/components/ElementSectionsSplitViewContainer'
import {UseHeatmapContainer} from '../Viewer.Components/Viewing.Extension.Heatmap/useHeatmapExtension'
import {PhotoSphereSplitViewContainer} from '../Viewer.Components/Viewing.Extension.ThreeSixtyPhotos/PhotoSphereSplitViewContainer'
import {ThreeSixtySplitControlsWrapper} from '../Viewer.Components/Viewing.Extension.ThreeSixtyPhotos/ThreeSixtyHeader'
import {ClassificationsLoadingSpinnerToast} from './ClassificationsLoadingSpinnerToast'
import {DockedViewsHeader} from './DockedViewsHeader'
import {Viewer} from './Viewer'
import {ViewerColor} from './ViewerColor'
import {ViewerElementIsolation} from './ViewerElementIsolation'

async function paintAndIsolateModel(
	viewer: Autodesk.Viewing.GuiViewer3D,
	data: {classifications: Record<string, number[]>; isolateAll: boolean},
	enableUnderConstruction: boolean,
	manualWorkflowActivated: boolean,
	displayAllElements: boolean,
	classificationsColoringEnabled: boolean,
) {
	const viewerColor = new ViewerColor(viewer)
	const viewerHelper = new ViewerElementIsolation(viewer)
	const {classifications, isolateAll} = data
	viewerColor.clear()
	const classificationEntries = Object.entries(classifications)
	if (classificationsColoringEnabled) {
		const draw = ([status, forgeObjectIds]: [string, number[]]) =>
			forgeObjectIds.map(id =>
				viewerColor.paintByForgeIdAndStatus(id, status as ClassificationStatus, enableUnderConstruction),
			)
		classificationEntries.filter(([status]) => status !== 'approved').forEach(draw)
		classificationEntries.filter(([status]) => status === 'approved' && manualWorkflowActivated).forEach(draw)
	}
	const forgeObjectIds = classificationEntries.flatMap(([, ids]) => ids)
	if (isolateAll || displayAllElements) {
		await viewerHelper.isolateModelRoot()
	} else {
		await viewerHelper.isolateOrHide(forgeObjectIds)
	}
	viewer.impl.sceneUpdated(true)
}

function useViewerElementColors() {
	const {
		viewer,
		displayAllElements,
		approvedFilter,
		isReady: {forgeMapping: modelLoaded},
		manualWorkflowActivated,
	} = useSelector((state: RootState) => state.viewerState)
	const classificationsFilter = useSelector((state: RootState) => state.classification).filter
	const watchlistElementsFilter = useSelector((state: RootState) => state.watchlistFilterElementsState.filter)
	const selectedProject = useCurrentProject()!
	const {selectedAnalysisView, analysisVersionMap} = useSelector(selectorClassificationState)
	const selectedWatchlist = useSelector(selectorCurrentWatchlist)
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const location = useLocation()
	const simplifiedLocation = getSimplifiedLocation(location.pathname)
	const classificationFiltersDebounced = useDebounce(classificationsFilter, config.sr.debouncingTime)
	const {data, status} = useClassificationsForColorizeModel(
		selectedProject,
		selectedWatchlist,
		watchlistElementsFilter,
		selectedAnalysisView,
		analysisVersionMap,
		classificationFiltersDebounced,
		enableUnderConstruction,
		approvedFilter,
		simplifiedLocation,
		classificationsFilter,
	)
	const {classificationsColoringEnabled} = useSelector(selectorViewerProperties)

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(doSetClassificationsPainted(status !== 'loading'))
	}, [dispatch, status])

	useEffect(() => {
		if (viewer && viewer.model && viewer.model.isLoadDone() && modelLoaded && data) {
			paintAndIsolateModel(
				viewer,
				data,
				enableUnderConstruction,
				manualWorkflowActivated,
				displayAllElements,
				classificationsColoringEnabled,
			).catch(e => {
				console.error('Error while painting the model: ', e.message)
			})
		}
	}, [
		data,
		displayAllElements,
		enableUnderConstruction,
		manualWorkflowActivated,
		modelLoaded,
		viewer,
		classificationsColoringEnabled,
	])
}

/**
 * Whenever we have fetched the classifications metadata for the selected elements in the viewer,
 * we sync this to the redux state so the viewer extensions (Heatmap, ContextMenu) can access it
 */
function useUpdateReduxStateOnSelectedClassifications() {
	const classifications = useSelectedClassifications()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			doFetchSelectedClassifications(
				(classifications &&
					Object.values(classifications).filter<ClassificationEntity>(
						(cl): cl is ClassificationEntity => cl !== null,
					)) ||
					[],
			),
		)
	}, [classifications, dispatch])
}

export function ViewerContainer(props: {selectedProject: UserProject; documentUrn: string}) {
	const photosphereEnabled = useFeatureEnabled('display360Photos')
	const sectionsEnabled = useFeatureEnabled('displayElementSections')
	const {threeSixtyOpened, assetsMode} = useSelector(selectorViewerProperties)
	const sectionsOpened = useSelector((state: RootState) => state.section.isOpened)
	const showSplitMode =
		((photosphereEnabled && threeSixtyOpened) || (sectionsEnabled && sectionsOpened)) && assetsMode === 'split'
	const displayHeatmaps = useFeatureEnabled('displayHeatmaps')
	const viewer = useSelector(selectorViewerInstance)

	useViewerElementColors()
	useUpdateReduxStateOnSelectedClassifications()

	const classificationsLoading = useSelector((state: RootState) => {
		const {db, forgeViewer, forgeMapping, classificationsPainted} = state.viewerState.isReady
		return db && forgeViewer && forgeMapping && !classificationsPainted
	})

	useEffect(() => {
		;(viewer as any)?.onResizeCallback?.()
	}, [viewer, showSplitMode])

	return (
		<Box id="viewer-container" fill>
			{showSplitMode && (
				<DockedViewsHeader>
					{threeSixtyOpened ? <ThreeSixtySplitControlsWrapper /> : <ElementSectionControlsWrapper />}
				</DockedViewsHeader>
			)}
			<Box className={showSplitMode ? 'split-mode' : ''} direction={'row'} fill>
				<Box fill>
					<Viewer selectedProject={props.selectedProject} documentUrn={props.documentUrn} />
					{displayHeatmaps && <UseHeatmapContainer />}
					{classificationsLoading && <ClassificationsLoadingSpinnerToast />}
				</Box>
				{(photosphereEnabled || sectionsEnabled) && (
					<Box width={showSplitMode ? {min: '50%', max: '50%'} : '0'}>
						<Grid fill rows={threeSixtyOpened && sectionsOpened ? ['50%', '50%'] : ['100%']}>
							{photosphereEnabled && <PhotoSphereSplitViewContainer />}
							{sectionsEnabled && <ElementSectionsSplitViewContainer />}
						</Grid>
					</Box>
				)}
			</Box>
		</Box>
	)
}
