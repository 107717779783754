import {useMemo, useState} from 'react'
import {getIdToken} from '../../../api/getIdToken.api'
import {
	buildFilesProjectApi,
	FileVisibility,
	initializeProjectFileUpload,
	ProjectFileEntity,
} from '../../../api/files.api'
import {FileUploaderWorker} from './FIleUploaderWorker.interface'
import {md5PartialHash, prettyDisplayFileSize} from '../../../utilities/fileUtilities'
import {trackLogRocketEvent} from '../../Tracking/logrocket'
import {createFileUploaderQueue, UploadFileDTO} from 'sr-file-uploader'
import {tap, mergeMap, map} from 'rxjs/operators'
import {from} from 'rxjs'
import {config} from '../../../config'
import {Observable} from 'rxjs'

export function useFileUploaderWorker(): FileUploaderWorker {
	const fileUploaderQueue = useMemo(() => createFileUploaderQueue(getIdToken), [])

	const cancelUpload = (filename: string): void => {
		return fileUploaderQueue.cancelFileByFilename(filename)
	}

	const uploadFile = (projectId: string, visibility: FileVisibility, file: File) => {
		const createFile = async () => {
			const fileHash = await md5PartialHash(file)
			const filesApi = buildFilesProjectApi(projectId, visibility, getIdToken)
			const entity = await initializeProjectFileUpload(filesApi, file.name, file.size, fileHash, visibility)
			return entity
		}
		const toUploadFileDTO = (fileEntity: ProjectFileEntity): UploadFileDTO => {
			return {
				file,
				fileId: fileEntity._id,
				nextUrl: `${config.sr.backendUrl}files/v3/projects/${projectId}/files/${visibility}s/${fileEntity._id}/next-pending-part`,
				numberOfParts: fileEntity.numberOfParts,
			}
		}

		trackLogRocketEvent('ClientFileUpload.started', {
			fileName: file.name,
			fileSize: file.size,
			fileSizeFormatted: prettyDisplayFileSize(file.size),
		})
		const start = Date.now()
		let fileProgress = 0

		const next = (progress: number) => {
			fileProgress = progress
		}
		const error = (err: Error) => {
			trackLogRocketEvent('ClientFileUpload.error', {
				fileName: file.name,
				fileSize: file.size,
				fileSizeFormatted: prettyDisplayFileSize(file.size),
				error: err.message,
			})
		}
		const complete = () => {
			if (fileProgress === 100) {
				trackLogRocketEvent('ClientFileUpload.finished', {
					fileName: file.name,
					fileSize: file.size,
					fileSizeFormatted: prettyDisplayFileSize(file.size),
					timeElapsed: Date.now() - start,
				})
			} else {
				trackLogRocketEvent('ClientFileUpload.canceled', {
					fileName: file.name,
					fileSize: file.size,
					fileSizeFormatted: prettyDisplayFileSize(file.size),
					timeElapsed: Date.now() - start,
					progress: fileProgress,
				})
			}
		}

		const uploadFilePartsInQueue = (dto: UploadFileDTO): Observable<number> => {
			return fileUploaderQueue.addFileToUploadQueue(dto).pipe(tap({next, error, complete}) as any)
		}

		return from(createFile()).pipe(
			map(toUploadFileDTO as any) as any,
			mergeMap(uploadFilePartsInQueue as any) as any,
		) as Observable<number>
	}

	return {
		uploadFile,
		cancelUpload,
	}
}
