import {MutableRefObject, useEffect, useRef, useState} from 'react'
import {loadS3Image, S3Operation} from '../utilities/storageUtilities'
import {useMounted} from './useMounted'

export type S3ImageLoaderStatus = 'not_loaded' | 'loading' | 'loaded'

export function useS3ImageLoader(
	src: string,
	publicPath?: boolean,
): {status: S3ImageLoaderStatus; imageSourceRef: MutableRefObject<string | undefined>} {
	const isMounted = useMounted()
	const [status, setStatus] = useState<S3ImageLoaderStatus>('not_loaded')
	const imageSourceRef = useRef<string>()
	const imagePathRef = useRef<string>(src)

	useEffect(() => {
		imagePathRef.current = src
		setStatus('not_loaded')
	}, [src])

	useEffect(() => {
		// load image
		if (status === 'not_loaded' && src) {
			setStatus('loading')
			loadS3Image(src, publicPath ? S3Operation.GET_PUBLIC : undefined).then(base64Image => {
				if (!isMounted()) return
				if (imagePathRef.current !== src) {
					return
				}
				imageSourceRef.current = base64Image
				setStatus('loaded')
			})
		}
	}, [status, imageSourceRef, src, publicPath, isMounted])

	return {status, imageSourceRef}
}
