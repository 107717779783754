import {useSelector} from 'react-redux'
import {RootState} from '../reducers'

export function useViewerReady() {
	return useSelector(
		(state: RootState) =>
			state.viewerState.isReady.db &&
			state.viewerState.isReady.forgeMapping &&
			state.viewerState.isReady.forgeViewer &&
			state.viewerState.isReady.classificationsPainted,
	)
}
