import {Box, Layer, Text} from 'grommet'
import {format} from 'date-fns'
import {Close, Download} from 'grommet-icons'
import React from 'react'
import {loadS3Image, S3Operation} from '../../../utilities/storageUtilities'
import {ProtectedImage} from '../../../components/ProtectedImage/ProtectedImage'
import {SRIconButton} from 'sr-react-commons'
import {Asset} from '../issue.entities'

type ViewAssetModalProps = {
	asset: Asset
	onLeave: () => void
}

export function ViewAssetModal({asset, onLeave}: ViewAssetModalProps) {
	function downloadAsset() {
		if (asset) {
			loadS3Image(asset.path, S3Operation.GET_PUBLIC).then(assetData => {
				// TODO I assume there is a better way to do this...
				const link = document.createElement('a')
				link.href = assetData
				link.download = 'asset.png'
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			})
		}
	}
	return (
		<Layer style={{background: 'transparent'}} full={true} onEsc={onLeave} onClickOutside={onLeave} margin={'small'}>
			<Box>
				<Box pad={{horizontal: 'small'}} justify={'between'} direction={'row'}>
					<Box>
						<Text color={'light-1'}>Analysis: {asset.analysis.name}</Text>
						<Text color={'light-1'}>
							Added: {format(new Date(asset.createdDate), 'E do, MMMM yyyy H:mm')}
							{asset.user?.hasOwnProperty('firstName') && ` by ${asset.user.firstName} ${asset.user.lastName}`}
						</Text>
					</Box>
					<Box direction={'row'}>
						<SRIconButton icon={<Download color={'light-1'} size="large" />} onClick={downloadAsset} />
						<SRIconButton icon={<Close color={'light-1'} size="large" />} onClick={onLeave} />
					</Box>
				</Box>
				<Box justify={'center'}>
					<Box align={'center'}>
						<ProtectedImage
							style={{maxHeight: '90vh', maxWidth: '90wv', height: '90vh'}}
							publicPath
							s3key={asset.path}
							alt={'Issue asset'}
						/>
					</Box>
				</Box>
			</Box>
		</Layer>
	)
}
