import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {IssueCard} from './IssueCard'
import pluralize from 'pluralize'
import {
	Box,
	Form,
	FormField,
	Grid,
	Image,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
	Text,
	ThemeContext,
	ThemeType,
} from 'grommet'
import {Alert, Close} from 'grommet-icons'
import {useDispatch, useSelector} from 'react-redux'
import {restoreViewerFromIssue} from '../issueUtils'
import {ClassificationElementRef, getMappedElementAttributes} from '../../../entities/element.entities'
import {RootState} from '../../../reducers'
import {
	CreateIssueDTO,
	fetchAssignableUsersForProject,
	fetchIssueConfig,
	IssueConfig,
	IssueCustomPropConfigField,
	IssueCustomProperties,
	IssueCustomPropertiesValue,
	UpdateIssueDTO,
} from '../api/issue-management.api'
import {translateViewportFromViewerToRealWorldCoordinates} from '../../../utilities/viewerUtilities'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {useSelectedClassifications} from '../../Classification/data-management/useSelectedClassification'
import {
	SRDeleteSecondaryIconButton,
	SRHeading,
	SRIconButton,
	SRPrimaryButton,
	SRSimpleSelect,
	SRTextArea,
	SRTextInput,
} from 'sr-react-commons'
import {useClassificationState} from '../../../reducers/classification.slice'
import {IssueEntity, PRIORITIES_FOR_FORM_AND_FILTERS, Priority} from '../issue.entities'
import {
	getClassificationStatusWithoutUnderConstruction,
	getDisplayValueForClassificationStatus,
} from '../../Classification/classification.helpers'
import _ from 'lodash'
import {useQuery} from 'react-query'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'
import {useGlobalNotifications} from '../../GlobalNotifications'
import {normalizeColor} from 'grommet/utils'
import {CardContainer} from '../../../components/CommonsCandidate/CardContainer/CardContainer'
import {SRDateInput} from '../../../components/CommonsCandidate/SRDateInput/SRDateInput'
import {issueSlice, useIssueSliceState, validateIssueFilterPriorityValue} from '../issue.slice'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'
import {ClassificationEntity} from '../../Classification/classification.entities'

const CUSTOM_FIELD_VALUE_MAX_LEN = 150

type EditIssueProps = {
	issue: IssueEntity | undefined
	isEdit: boolean
	create: (createDTO: CreateIssueDTO) => Promise<IssueEntity>
	update: (updateDTO: UpdateIssueDTO) => Promise<IssueEntity>
	remove: (id: IssueEntity['_id']) => Promise<void>
}

const ISSUE_TYPES = ['Open Issue', 'Under Inspection', 'Closed']

function assumeDiagnosis(
	selectedClassifications: ClassificationEntity[],
	selectedElements: ClassificationElementRef[],
	enableUnderConstruction: boolean,
) {
	const uniqueClassificationsStates = _.uniq(
		selectedClassifications.map(cl =>
			!enableUnderConstruction ? getClassificationStatusWithoutUnderConstruction(cl.status) : cl.status,
		),
	)
	const uniqueElementTypes = _.uniqBy(selectedElements, el => el.mappedElementType)
	if (uniqueClassificationsStates.length === 1) {
		const elementType = uniqueElementTypes.length === 1 ? uniqueElementTypes[0].mappedElementType : 'element'
		return (
			getDisplayValueForClassificationStatus(uniqueClassificationsStates[0]) +
			' ' +
			pluralize(elementType.toLowerCase(), selectedElements.length)
		)
	}
	return 'Describe issue diagnosis'
}

const THEME_EXTEND = {
	formField: {
		label: {
			margin: '0',
			weight: 'bold',
		},
		border: false,
		extend: 'input {font-weight: 400;}',
		margin: {bottom: 'xsmall'},
	},
	table: {
		header: {
			extend: ({theme}: {theme: ThemeType}) => {
				return `border-color: ${normalizeColor('neutral-2', theme)};`
			},
		},
	},
}

export function EditIssue({issue, isEdit, create, update, remove}: EditIssueProps) {
	const {threeSixtyLockedView} = useSelector(selectorViewerProperties)
	const [location, setLocation] = useState(issue?.location || '')
	const [diagnosis, setDiagnosis] = useState(issue?.diagnosis || '')
	const [issueStatus, setIssueStatus] = useState(issue?.issueStatus || 'Open Issue')
	const [locationPlaceholder, setLocationPlaceholder] = useState('')
	const [diagnosisPlaceholder, setDiagnosisPlaceholder] = useState('')
	const [assignedUser, setAssignedUser] = useState<string | null>(issue?.assignedUser?._id || null)
	const [deadline, setDeadline] = useState<Date | null>(issue?.deadline ? new Date(issue.deadline) : null)
	const [priority, setPriority] = useState<Priority | null>(issue?.priority ? issue?.priority : null)
	const project = useSelector((state: RootState) => state.userProfileState.selectedProject)
	const selectedAnalysisView = useClassificationState().selectedAnalysisView!
	const selectedClassifications = useSelectedClassifications()
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const globalNotifications = useGlobalNotifications()
	const assignableUsers = useQuery(
		['users', 'assignable-to-issues'],
		() => {
			return fetchAssignableUsersForProject()
		},
		{onError: () => globalNotifications.notifyError('Users to be assigned in the issue could not be loaded.')},
	)
	const {tempAssetForNewIssue} = useIssueSliceState()
	const dispatch = useDispatch()
	const removeTempAsset = useCallback(() => dispatch(issueSlice.actions.setTempAssetForNewIssue(null)), [dispatch])
	const [customProperties, setCutomProperties] = useState<IssueCustomProperties>(
		issue && issue.customProperties ? {...issue.customProperties} : {},
	)
	const {data: issueConfig} = useQuery<IssueConfig>(
		['issue', isEdit ? 'editIssue' : 'issueView', issue?._id],
		fetchIssueConfig,
		{
			staleTime: 0,
			cacheTime: 0,
		},
	)

	useEffect(() => {
		issueConfig?.customProps &&
			setCutomProperties(
				issueConfig.customProps.reduce(
					(acc: IssueCustomProperties, next: IssueCustomPropConfigField) => (
						(acc[next.uuid] = {
							name: next.name,
							value: (issue?.customProperties?.[next.uuid]?.value || '') as IssueCustomPropertiesValue,
						}),
						acc
					),
					{},
				),
			)
	}, [issue?.customProperties, issueConfig])

	useEffect(() => {
		return () => {
			removeTempAsset()
		}
	}, [removeTempAsset])

	const onCustomPropertyChange = (uuid: string, val: string) => {
		const newCustomProperties = {...customProperties}
		newCustomProperties[uuid].value = val as IssueCustomPropertiesValue
		setCutomProperties(newCustomProperties)
	}
	const selectedElements: ClassificationElementRef[] = useMemo(
		() =>
			selectedClassifications
				? Object.values(selectedClassifications)
						.filter<ClassificationEntity>((cl): cl is ClassificationEntity => cl !== null)
						.map(cl => ({
							...cl.element,
							...getMappedElementAttributes(cl.element, project?.floorMapping || {}, project?.elementTypeMapping || {}),
						}))
				: [],
		[selectedClassifications, project],
	)
	const isElementSelected = selectedElements.length > 0
	const editIssue =
		isEdit && issue
			? {...issue}
			: {
					issueStatus: 'Open Issue',
					classification: '',
					forgeObjectId: [],
					externalId: [],
					viewport: {},
					createdDate: new Date().toISOString(),
					deadline: deadline?.toISOString() ?? null,
					priority: priority,
					analysisView: selectedAnalysisView!._id,
			  }
	if (selectedElements.length > 0) {
		editIssue.forgeObjectId = selectedElements.map((el: any) => el.forgeObjectId)
		editIssue.externalId = selectedElements.map((el: any) => el.externalId)
	}
	useEffect(() => {
		setDiagnosis(issue?.diagnosis || '')
		setLocation(issue?.location || '')
		setIssueStatus(issue?.issueStatus || 'Open Issue')
		isEdit && issue && !threeSixtyLockedView && restoreViewerFromIssue(issue)
	}, [isEdit, issue, threeSixtyLockedView])
	useEffect(() => {
		const selectedElementFloors = _.uniq(selectedElements.map(el => el.mappedFloors))
			.map(floorNr => 'Floor ' + floorNr)
			.join(', ')
		if (selectedClassifications && selectedElements.length) {
			const assumedDiagnosis = assumeDiagnosis(
				Object.values(selectedClassifications).filter<ClassificationEntity>(
					(cl): cl is ClassificationEntity => cl !== null,
				),
				selectedElements,
				enableUnderConstruction,
			)
			if (assumedDiagnosis) {
				setDiagnosisPlaceholder(assumedDiagnosis)
			}
		}
		setLocationPlaceholder(selectedElementFloors)
	}, [selectedElements, selectedClassifications, enableUnderConstruction])
	const handleSubmit = async (event: React.MouseEvent) => {
		event.nativeEvent.preventDefault()
		const {viewport} = window.NOP_VIEWER.getState({
			seedUrn: false,
			objectSet: false,
			renderOption: false,
			viewport: true,
		})
		const base = {
			issueStatus,
			location: location || locationPlaceholder,
			diagnosis: diagnosis || diagnosisPlaceholder,
		}
		if (isEdit) {
			await update({
				_id: issue!._id!,
				body: {
					...base,
					forgeObjectId: selectedElements.map(e => e.forgeObjectId),
					externalId: selectedElements.map(e => e.externalId),
					assignedUser,
					deadline,
					priority,
					customProperties: customProperties,
				},
			})
		} else {
			await create({
				...base,
				classification: '',
				viewport: translateViewportFromViewerToRealWorldCoordinates(
					viewport,
					window.NOP_VIEWER.model.getGlobalOffset(),
					window.NOP_VIEWER.model.getUnitScale(),
				),
				forgeObjectId: selectedElements.map(e => e.forgeObjectId),
				externalId: selectedElements.map(e => e.externalId),
				analysisViewId: selectedAnalysisView!._id,
				assignedUser,
				deadline,
				priority,
				asset: tempAssetForNewIssue || undefined,
				customProperties: customProperties,
			})
		}
	}

	const customPropsArray = Object.keys(customProperties)

	return (
		<Box gap={'small'} pad={{right: 'xsmall'}} overflow={{vertical: 'auto'}}>
			<Form>
				<Box gap={'small'}>
					{!isElementSelected ? (
						<CardContainer>
							<Text style={{display: 'inline-flex', paddingRight: 4, margin: '0 auto'}}>
								<Alert color="orange" size="medium" /> Select an element in the Viewer
							</Text>
						</CardContainer>
					) : (
						<ThemeContext.Extend value={THEME_EXTEND}>
							<Box gap={'small'}>
								<Box flex={{shrink: 0}}>
									<CardContainer>
										<SRHeading level={'4'}>Detail</SRHeading>
										<FormField label="Name">
											<SRTextInput
												placeholder={diagnosisPlaceholder}
												value={diagnosis}
												onChange={event => setDiagnosis(event.target.value)}
											/>
										</FormField>
										<Grid columns={{count: 2, size: '50%'}} gap={'xsmall'}>
											<Box>
												<FormField className="status-select" label={'Status'}>
													<SRSimpleSelect
														size="small"
														value={issueStatus}
														options={ISSUE_TYPES}
														onChange={option => setIssueStatus(option)}
													/>
												</FormField>
											</Box>
											<Box>
												<FormField label={'Priority'}>
													<SRSimpleSelect
														size="small"
														value={priority ?? 'Unassigned'}
														options={[...PRIORITIES_FOR_FORM_AND_FILTERS]}
														onChange={newPriority =>
															validateIssueFilterPriorityValue(newPriority) &&
															setPriority(newPriority === 'Unassigned' ? null : newPriority)
														}
													/>
												</FormField>
											</Box>
										</Grid>
										<Grid columns={{count: 2, size: '50%'}} gap={'xsmall'}>
											<Box>
												<FormField label={'Assigned to'}>
													{assignableUsers.isLoading ? (
														<Spinner hideIfGlobalLoader={false} size="small" />
													) : (
														<SRSimpleSelect
															showSearchInput={true}
															value={assignedUser || ''}
															size="small"
															options={[{value: '', label: 'Unassigned'}].concat(
																assignableUsers.data?.map(user => ({
																	value: user._id,
																	label: `${user.firstName} ${user.lastName}`,
																})) || [],
															)}
															onChange={option => {
																setAssignedUser(option === '' ? null : option)
															}}
														/>
													)}
												</FormField>
											</Box>
											<Box>
												<FormField label={'Deadline'}>
													<Box direction={'row'} gap={'xxsmall'}>
														<SRDateInput value={deadline} onChange={date => setDeadline(date)} />
														<SRIconButton
															alignSelf={'center'}
															size="small"
															disabled={deadline === null}
															icon={<Close size="xsmall" />}
															onClick={() => setDeadline(null)}
														/>
													</Box>
												</FormField>
											</Box>
										</Grid>

										<FormField label="Location">
											<SRTextInput
												placeholder={locationPlaceholder}
												value={location}
												onChange={event => setLocation(event.target.value)}
											/>
										</FormField>
									</CardContainer>
								</Box>

								{customPropsArray.length ? (
									<Box flex={{shrink: 0}}>
										<CardContainer>
											<SRHeading level={'4'}>More information</SRHeading>
											{customPropsArray.map(key => (
												<FormField key={key} label={<Text size="6">{customProperties[key].name}</Text>}>
													<Box gap={'xxsmall'}>
														<SRTextArea
															placeholder={`Add ${customProperties[key].name}`}
															value={customProperties[key].value}
															onChange={event =>
																event.target.value.length <= CUSTOM_FIELD_VALUE_MAX_LEN &&
																onCustomPropertyChange(key, event.target.value)
															}
														/>
														<Text size={'small'}>
															{customProperties[key].value?.length || 0}/{CUSTOM_FIELD_VALUE_MAX_LEN}
														</Text>
													</Box>
												</FormField>
											))}
										</CardContainer>
									</Box>
								) : null}

								<CardContainer>
									<Box flex={{shrink: 0}}>
										<SRHeading level={4}>Elements</SRHeading>
										<Box margin={{bottom: 'xsmall'}}>
											<Table>
												<TableHeader>
													<TableRow>
														<TableCell pad={'none'}>
															<Text weight={600}>GlobalID</Text>
														</TableCell>
														<TableCell>
															<Text weight={600}>Type</Text>
														</TableCell>
														<TableCell>
															<Text weight={600}>Floor</Text>
														</TableCell>
													</TableRow>
												</TableHeader>
												<TableBody>
													{selectedElements.map(element => (
														<TableRow key={element._id}>
															<TableCell width={'200px'} pad={'none'}>
																<Text title={element.externalId} truncate>
																	{element.externalId}
																</Text>
															</TableCell>
															<TableCell width={'70px'}>
																<Text title={element.mappedElementType} truncate>
																	{element.mappedElementType}
																</Text>
															</TableCell>
															<TableCell width={'55px'}>
																<Text title={element.mappedFloors} truncate>
																	{element.mappedFloors}
																</Text>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</Box>
									</Box>
								</CardContainer>
							</Box>
						</ThemeContext.Extend>
					)}
				</Box>
			</Form>
			{tempAssetForNewIssue && !isEdit && (
				<Box height={{min: 'small', max: 'medium'}}>
					<CardContainer gap={'xsmall'}>
						<Box direction={'row'} align={'center'} justify={'between'}>
							<SRHeading level={4}>Attachments</SRHeading>
							<SRDeleteSecondaryIconButton size={'small'} onClick={removeTempAsset} />
						</Box>
						<Box pad={'xsmall'} border={{color: 'dark-3', size: 'small'}} background={'light-4'} fill>
							<Image src={tempAssetForNewIssue.img} alt={'Preview of the attachment'} fit={'contain'} />
						</Box>
					</CardContainer>
				</Box>
			)}
			<Box flex={{shrink: 0}} margin={{bottom: 'xxsmall'}}>
				{isElementSelected && (
					<IssueCard
						active={false}
						issue={{
							...editIssue,
							issueStatus,
							diagnosis: diagnosis || diagnosisPlaceholder,
							location: location || locationPlaceholder,
						}}
						handleDeleteClick={isEdit && issue?._id ? () => remove(issue._id) : undefined}
						showEdit={false}
						disableClick={true}
						issueAnalysisView={selectedAnalysisView!}
					/>
				)}
			</Box>

			<Box direction="row" justify="end" flex={false}>
				<SRPrimaryButton type="submit" label="Save" onClick={handleSubmit} size="medium" />
			</Box>
		</Box>
	)
}
