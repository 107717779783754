import React from 'react'
import {Table, TableBody, TableCell, TableHeader, TableRow, Text, ThemeContext, ThemeType} from 'grommet'
import _ from 'lodash'
import {WatchlistClassification, watchlistClassificationColor} from '../../watchlist.utils'
import {getDisplayValueForClassificationStatus} from '../../../Classification/classification.helpers'
import {SR_COLOR_NEUTRAL_1} from 'sr-react-commons'
import {BreakDownDonutDataProps} from './BreakDownDonutChart'
import {ClassificationEntity} from '../../../Classification/classification.entities'

export type ProgressMonitoringData = {
	verified: number
	deviated: number
	under_construction: number
	missing: number
	no_data: number
}

export function progressMonitoringDataLabels(enableUnderConstruction: boolean): ClassificationEntity['status'][] {
	return enableUnderConstruction
		? ['verified', 'deviated', 'under_construction', 'missing', 'no_data']
		: ['verified', 'deviated', 'missing', 'no_data']
}

export function progressMonitoringDataTotal(data: ProgressMonitoringData): number {
	return _.sum(Object.values(data))
}

function buildTableRowData(data: ProgressMonitoringData, enableUnderConstruction: boolean) {
	const total = progressMonitoringDataTotal(data)
	const labels = progressMonitoringDataLabels(enableUnderConstruction)
	const getPercentage = (elements: number) => total && _.round((elements / total) * 100, 0)

	return labels.map((label, index) => {
		const value = data[label]!
		return {
			id: index + 1,
			name: getDisplayValueForClassificationStatus(label),
			color: watchlistClassificationColor(
				getDisplayValueForClassificationStatus(label) as WatchlistClassification,
				'text',
			),
			elements: value,
			percentage: getPercentage(value),
		}
	})
}

export function BreakDownDataTable({data, enableUnderConstruction}: BreakDownDonutDataProps): JSX.Element {
	const tableData = buildTableRowData(data, enableUnderConstruction)
	const breakdownTheme: ThemeType = {
		table: {
			body: {
				extend: 'padding-top: 0px; padding-bottom: 0px;',
			},
			header: {
				extend: 'padding-top: 0px; padding-bottom: 3px; border: none;',
			},
		},
	}
	return (
		<ThemeContext.Extend value={breakdownTheme}>
			<Table margin={'none'}>
				<TableHeader>
					<TableRow>
						<TableCell pad={'none'} colSpan={2}>
							<Text weight={600} size={'small'} color={SR_COLOR_NEUTRAL_1}>
								Status
							</Text>
						</TableCell>
						<TableCell pad={'none'} scope="col">
							<Text weight={600} size={'small'} color={SR_COLOR_NEUTRAL_1}>
								Elements
							</Text>
						</TableCell>
					</TableRow>
				</TableHeader>
				<TableBody>
					{tableData.map(datum => (
						<TableRow key={datum.id} style={{paddingBottom: '0px'}}>
							<TableCell pad={{right: 'xsmall'}} width={'40px'}>
								<Text size={'medium'} weight={600} color={datum.color}>
									{datum.percentage}%
								</Text>
							</TableCell>
							<TableCell pad={{right: 'xsmall'}} width={'145px'}>
								<strong>{datum.name}</strong>
							</TableCell>
							<TableCell pad={{right: 'xsmall'}}>{datum.elements}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</ThemeContext.Extend>
	)
}
