import React from 'react'
import {useHistory} from 'react-router-dom'
import {Anchor, Text} from 'grommet'
import {useBasePath} from '../../hooks/useBasePath'
import {IssueEntity} from '../../features/IssueManagement/issue.entities'

type IssueIndicatorProps = {
	issues: IssueEntity[]
}

type IssueIndicatorButtonProps = {
	issue: IssueEntity
}

function IssueIndicatorButton({issue}: IssueIndicatorButtonProps) {
	const history = useHistory()
	const issuePath = `${useBasePath()}issues/${issue._id}/detail`
	return (
		<Anchor
			onClick={event => {
				event.preventDefault()
				history.push(issuePath)
			}}
			title={`#${issue.issueId} ${issue.diagnosis} (${issue.issueStatus})`}
			href={issuePath}
		>
			#{issue.issueId}
		</Anchor>
	)
}

export function IssueIndicator({issues}: IssueIndicatorProps) {
	return issues.length <= 2 ? (
		<Text size={'small'}>
			{issues.map((issue, index) => (
				<span key={index}>
					<IssueIndicatorButton issue={issue} />
					{index < issues.length - 1 && <span>, </span>}
				</span>
			))}
		</Text>
	) : (
		<Text title={issues.map(issue => `#${issue.issueId} ${issue.diagnosis} (${issue.issueStatus})`).join('\n')}>
			{issues.length}
		</Text>
	)
}
