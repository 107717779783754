import React, {useEffect} from 'react'
import {Box} from 'grommet'
import {useLastSelectedClassification} from '../../../features/Classification/data-management/useSelectedClassification'
import {useQuery} from 'react-query'
import {IssueEntity} from '../../../features/IssueManagement/issue.entities'
import {AxiosError} from 'axios'
import {fetchIssuesByForgeObjectIds} from '../../../features/IssueManagement/api/issue-management.api'
import {SRPropertiesTooltip} from '../../CommonsCandidate/SRPropertiesTooltip/SRPropertiesTooltip'
import {useBasePath} from '../../../hooks/useBasePath'
import {useHistory} from 'react-router-dom'
import PropertiesTooltipExtension, {PROPERTIES_TOOLTIP_EXTENSION} from './Viewing.Extension.PropertiesTooltip'
import {useSelector} from 'react-redux'
import {selectorViewerInstance} from '../../../selectors/viewer-state.selector'
import {trackLogRocketEvent} from '../../../features/Tracking/logrocket'
import {restoreViewerFromIssue} from '../../../features/IssueManagement/issueUtils'

export function PropertiesToolTipContainer() {
	const classification = useLastSelectedClassification()
	const basePath = useBasePath()
	const history = useHistory()
	const viewer = useSelector(selectorViewerInstance)
	const navigateToIssueDetail = (issue: Pick<IssueEntity, '_id' | 'issueId'>) => {
		trackLogRocketEvent('QuickProperties.issueVisited', {
			externalId: classification!.externalId,
			analysis: classification!.analysis.name,
			name: classification!.element.name || undefined,
			issueId: issue.issueId,
		})
		history.push(`${basePath}issues/${issue._id}/detail`)
		restoreViewerFromIssue(issue)
	}

	const extensionRef = viewer?.getExtension(PROPERTIES_TOOLTIP_EXTENSION) as PropertiesTooltipExtension | undefined
	const {data: issues} = useQuery<IssueEntity[], AxiosError>(
		['issues', 'issues-by-forge', classification?.forgeObjectId],
		() => fetchIssuesByForgeObjectIds(classification ? [classification.forgeObjectId] : false),
	)

	const displayMagnitudeByAxis = extensionRef?.featureEnabled('displayMagnitudeByAxis')

	if (extensionRef && classification) {
		extensionRef.hasClassification = true
	} else if (extensionRef) {
		extensionRef.hasClassification = false
	}

	const magnitude = classification
		? Math.round(
				(displayMagnitudeByAxis
					? classification.magnitudePerAxis?.magnitudeDisplay || classification.magnitudeDisplay
					: classification.magnitudeDisplay) * 1000,
		  )
		: 0
	const status = classification ? classification.status : 'no_classification'
	const name = classification ? classification.element.name : ''
	const updatedIssues = classification && issues ? issues : []

	useEffect(() => {
		if (classification) {
			trackLogRocketEvent('QuickProperties.classificationDisplayed', {
				externalId: classification.externalId,
				analysis: classification.analysis.name,
				name: classification.element.name || undefined,
			})
		}
	}, [classification])

	return (
		<Box id={'quickPropertiesLabel'}>
			<SRPropertiesTooltip
				status={status}
				issues={updatedIssues}
				magnitude={magnitude}
				name={name || ''}
				onClickIssue={navigateToIssueDetail}
			/>
		</Box>
	)
}
