import {Box, Text} from 'grommet'
import {format} from 'date-fns'
import React, {useEffect, useState} from 'react'
import {fetchS3FileRaw, S3Operation} from '../../../utilities/storageUtilities'
import {useGlobalNotifications} from '../../GlobalNotifications'
import {SRDeleteSecondaryIconButton} from 'sr-react-commons'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'
import {truncateString} from '../../../utilities/stringUtilities'
import {Asset} from '../issue.entities'

type IssueAssetBoxProps = {
	onDelete: (assetId: string) => void
	asset: Asset
	onAssetClick: (asset: Asset) => void
}

export function IssueAssetBox({onDelete, asset, onAssetClick}: IssueAssetBoxProps) {
	const [urlAsset, setURLAsset] = useState('')
	const [loading, setLoading] = useState(true)
	const {notifyAlert} = useGlobalNotifications()
	useEffect(() => {
		if (asset === null) return
		function fetchAssetAsBase64(asset: Asset) {
			function arrayBufferToBase64(buffer: ArrayBuffer) {
				let binary = ''
				const bytes = [].slice.call(new Uint8Array(buffer))
				bytes.forEach(b => (binary += String.fromCharCode(b)))
				return window.btoa(binary)
			}
			fetchS3FileRaw(asset.path, S3Operation.GET_PUBLIC)
				.then(response =>
					response
						.arrayBuffer()
						.then((buffer: ArrayBuffer) => `data:image/png;base64,${arrayBufferToBase64(buffer)}`)
						.then((base64EncodedImage: string) => {
							setURLAsset(base64EncodedImage)
							setLoading(false)
						}),
				)
				.catch(err => {
					console.error('Error in fetchS3FileRaw', err)
					notifyAlert("We couldn't get the asset, please retry.")
				})
		}
		fetchAssetAsBase64(asset)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Box gap={'xsmall'} key={asset._id}>
			<Box direction={'row'} pad={{horizontal: 'xxsmall'}} justify={'between'}>
				{asset.type !== 'attachment' ? (
					<Text alignSelf="end" size={'xsmall'}>
						Analysis: <span title={asset.analysis.name}>{truncateString(asset.analysis.name, 20)}</span>
					</Text>
				) : (
					<Text />
				)}
				<Box direction={'row'} gap={'xsmall'}>
					<Text size={'xsmall'} alignSelf="end">
						Added: {format(new Date(asset.createdDate), 'E do, MMMM yyyy H:mm')}
					</Text>
					<SRDeleteSecondaryIconButton onClick={() => onDelete(asset._id)} />
				</Box>
			</Box>
			<Box pad={'xsmall'} border={{color: 'dark-3', size: 'small'}} background={'light-4'} height={'200px'}>
				{loading ? (
					<Spinner size={'small'} />
				) : (
					<img
						src={urlAsset}
						alt={asset._id}
						onClick={() => onAssetClick(asset)}
						style={{maxWidth: '100%', maxHeight: '100%', cursor: 'pointer', margin: 'auto'}}
					/>
				)}

				{/* <S3Image
					onClick={() => onAssetClick(asset)}
					imgKey={asset.path}
					theme={{
						photoImg: {maxWidth: '100%', maxHeight: '200px', cursor: 'pointer'},
						photo: {
							minWidth: '300px',
							height: '100%',
							width: '100%',
							overflow: 'hidden',
							textAlign: 'center',
						},
					}}
				/> */}
			</Box>
		</Box>
	)
}
