import React from 'react'
import {Box, Form, Layer, Text} from 'grommet'
import {SRHeading, SRIconButton, SRPlainButton, SRPrimaryButton} from 'sr-react-commons'
import {Close} from 'grommet-icons'

export function UnapproveClassificationModal({
	onClose,
	onAction,
	elementCount,
}: {
	onClose: () => void
	onAction: () => Promise<void>
	elementCount: number
}) {
	return (
		<Layer position={'top'} margin={{top: '100px'}}>
			<Form>
				<Box gap={'medium'} pad={'medium'} width={'525px'}>
					<Box justify={'between'} direction={'row'}>
						<SRHeading level={4}>Unapprove elements</SRHeading>
						<SRIconButton icon={<Close />} onClick={onClose} alignSelf={'center'} />
					</Box>
					<Box gap="medium">
						<Box gap="xxsmall">
							<Text color="neutral-1">
								Do you want to mark {elementCount} {elementCount > 1 ? 'elements' : 'element'} as "Not approved"?
							</Text>
						</Box>
					</Box>
					<Box direction="row" justify="between">
						<SRPlainButton onClick={onClose} label="Cancel" size={'medium'} />
						<SRPrimaryButton onClick={() => onAction()} label="Yes, unapprove" size={'medium'} />
					</Box>
				</Box>
			</Form>
		</Layer>
	)
}
