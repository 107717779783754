import React from 'react'
import {Authenticator} from 'aws-amplify-react'
import {AppWithStore} from '../App'
import {history} from '../../router/history'
import {Router} from 'react-router-dom'
import {amplifyConfig} from '../../config'
import {SRSignIn} from '../CommonsCandidate/Authenticator/SRSignIn'
import {SRForgotPassword} from '../CommonsCandidate/Authenticator/SRForgotPassword'
import {Box, Grommet} from 'grommet'
import {theme} from '../../theme'
import {SRRequireNewPassword} from '../CommonsCandidate/Authenticator/SRRequireNewPassword'
import {ErrorBoundary} from '@sentry/react'
import {ErrorFallback} from '../ErrorBoundary/ErrorBoundaryFallback'
import LogRocket from 'logrocket'
import {logRocketInitialized} from '../../features/Tracking/logrocket'

export function AppWithAuth() {
	return (
		<Router history={history}>
			<Grommet theme={theme} full>
				<ErrorBoundary
					beforeCapture={(scope, error, componentStack) => {
						error && logRocketInitialized() && LogRocket.captureException(error)
					}}
					fallback={<ErrorFallback />}
				>
					<Authenticator
						hideDefault
						amplifyConfig={amplifyConfig}
						container={(props: object) => <Box fill overflow={'auto'} {...props} />}
					>
						<SRSignIn />
						<SRForgotPassword />
						<SRRequireNewPassword />
						<AppWithStore />
					</Authenticator>
				</ErrorBoundary>
			</Grommet>
		</Router>
	)
}
