import './Viewing.Extension.PropertiesTooltip.scss'
import {clearOverlay, drawLine, getCenterOfElementBounds} from '../../Viewer/Viewer-helper'
import PropertiesTooltipPanel from './Viewing.Extension.PropertiesTooltipPanel'
import {trackLogRocketEvent} from '../../../features/Tracking/logrocket'
import {SRExtension} from '../SRExtension'
import {store} from '../../../store/store'
import Dimensions = Autodesk.Viewing.Private.Dimensions

export const PROPERTIES_TOOLTIP_EXTENSION = 'Viewing.Extension.PropertiesTooltip'
export const PROPERTIES_TOOLTIP_CONTROL_GROUP = 'propertiesTooltipControlGroup'
const PROPERTIES_TOOLTIP_OVERLAY_SCENE = 'propertiesTooltipOverlayScene'

export default class PropertiesTooltipExtension extends SRExtension {
	private toolbarButton: Autodesk.Viewing.UI.Button | undefined
	private panel: PropertiesTooltipPanel | undefined
	private lineColor = 0xffffff
	private labelOffset = new THREE.Vector3(1, 0, 5)
	private camera = this.viewer.impl.camera
	private tooltipOffset = {left: 0.99, top: 0.97}
	active: boolean = false
	hideLine: boolean = false
	hasClassification: boolean = true
	selectedElement: number | null = null

	constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
		super(viewer, options)
		this.viewer = viewer
		this.labelOffset.divideScalar(viewer.model.getUnitScale())
		this.aggregateSelectionEvent = this.aggregateSelectionEvent.bind(this)
	}

	setActive = (active: boolean = false) => {
		this.active = active
		if (active) {
			this.toolbarButton!.removeClass('propertiesTooltipToolbarButton')
			this.toolbarButton!.addClass('propertiesTooltipToolbarButtonBlue')
			this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.aggregateSelectionEvent)
			this.setInitialSelectedElement()
			trackLogRocketEvent('QuickProperties.activated')
		} else {
			clearOverlay(this.viewer, PROPERTIES_TOOLTIP_OVERLAY_SCENE)
			this.selectedElement = null
			this.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.aggregateSelectionEvent)
			this.toolbarButton!.removeClass('propertiesTooltipToolbarButtonBlue')
			this.toolbarButton!.addClass('propertiesTooltipToolbarButton')
			trackLogRocketEvent('QuickProperties.deactivated')
		}
		this.panel?.setVisible(active)
		;(this.viewer.impl as any).sceneUpdated(true)
	}

	toggleDisableTooltipButton = (disabled: boolean = false) => {
		disabled
			? this.toolbarButton!.setState(Autodesk.Viewing.UI.Button.State.INACTIVE)
			: this.toolbarButton!.setState(Autodesk.Viewing.UI.Button.State.DISABLED)
	}

	setInitialSelectedElement = () => {
		if (this.validateSelected()) {
			this.selectedElement = this.viewer.getSelection()[0]
			this.updateToolTipLabel()
		} else {
			this.selectedElement = null
		}
	}

	validateSelected = () => {
		const selectedElements = this.viewer.getSelection()
		return selectedElements && selectedElements.length === 1 && this.hasClassification
	}

	onToolbarCreated(toolbar: Autodesk.Viewing.UI.ToolBar) {
		super.onToolbarCreated(toolbar)
		const quickPropertiesToolbarButton = new Autodesk.Viewing.UI.Button('quickPropertiesButton')
		quickPropertiesToolbarButton.addClass('propertiesTooltipToolbarButton')
		quickPropertiesToolbarButton.setToolTip('Quick Properties')
		quickPropertiesToolbarButton.onClick = () => {
			this.setActive(!this.active)
		}
		this.toolbarButton = quickPropertiesToolbarButton
		const ctrlGroup = new Autodesk.Viewing.UI.ControlGroup(PROPERTIES_TOOLTIP_CONTROL_GROUP)
		ctrlGroup.addControl(this.toolbarButton)
		toolbar.addControl(ctrlGroup)
	}

	updateToolTipLabel = () => {
		if (this.selectedElement) {
			const linePos = this.updateLine(this.selectedElement)
			const label = linePos.project(this.camera)
			this.moveToolTipLabel(label)
		}
	}

	calculateTooltipBounds = (canvas: Dimensions, position: {x: number; y: number}, elementDimensions: DOMRect) => {
		const isSplitView = store.getState().viewerState.properties.assetsMode === 'split'
		const sectionIsOpen = store.getState().section.isOpened
		const threeSixtyIsOpen = store.getState().viewerState.properties.threeSixtyOpened
		const styleTopZeroBound = isSplitView && (sectionIsOpen || threeSixtyIsOpen) ? 55 : 15
		const styleLeft = ((position.x + this.tooltipOffset.left) / 2) * canvas.width
		const styleTop = (-(position.y - this.tooltipOffset.top) / 2) * canvas.height
		const styleTopFullBound = canvas.height - this.panel!.getContentSize().height - styleTopZeroBound
		const styleLeftZeroBound = elementDimensions.width * 0.05
		const styleLeftFullBound = canvas.width - elementDimensions.width * 1.05
		let tooltipBounds = {left: styleLeft, top: styleTop}
		let isTopOut = true
		if (styleTop < styleTopZeroBound) {
			tooltipBounds.top = styleTopZeroBound
			this.hideLine = true
		} else if (styleTop > styleTopFullBound) {
			tooltipBounds.top = styleTopFullBound
			this.hideLine = true
		} else {
			isTopOut = false
			this.hideLine = false
		}

		if (styleLeft < styleLeftZeroBound) {
			tooltipBounds.left = styleLeftZeroBound
			this.hideLine = true
		} else if (styleLeft > styleLeftFullBound) {
			tooltipBounds.left = styleLeftFullBound
			this.hideLine = true
		} else if (!isTopOut) {
			this.hideLine = false
		}
		return tooltipBounds
	}

	moveToolTipLabel(position: {x: number; y: number}) {
		if (this.panel) {
			const elementDimensions = this.panel.container.getBoundingClientRect()
			const canvas = this.viewer.getDimensions()
			const updatedBounds = this.calculateTooltipBounds(canvas, position, elementDimensions)
			this.panel.container.style.left = updatedBounds.left + 'px'
			this.panel.container.style.top = updatedBounds.top + 'px'
		}
	}

	updateLine = (selectedElement: number) => {
		const position = getCenterOfElementBounds(selectedElement, this.viewer)
		const endOfTheLine = new THREE.Vector3(
			position.x + this.labelOffset.x * Math.sign(position.x),
			position.y + this.labelOffset.y,
			position.z + this.labelOffset.z,
		)

		this.hideLine || !this.hasClassification
			? clearOverlay(this.viewer, PROPERTIES_TOOLTIP_OVERLAY_SCENE)
			: drawLine(this.viewer, new THREE.Vector3(position.x, position.y, position.z), endOfTheLine.clone(), {
					overlay: PROPERTIES_TOOLTIP_OVERLAY_SCENE,
					color: this.lineColor,
					linewidth: 1.3,
					drawOnTop: true,
			  })

		return endOfTheLine
	}

	aggregateSelectionEvent(event: {selections: {dbIdArray: number[]}[]}) {
		clearOverlay(this.viewer, PROPERTIES_TOOLTIP_OVERLAY_SCENE)
		if (event.selections && event.selections[0]?.dbIdArray.length === 1 && this.active) {
			this.selectedElement = event.selections[0].dbIdArray[0]
			this.updateToolTipLabel()
			this.panel?.setVisible(true)
		} else {
			this.selectedElement = null
			this.panel?.setVisible(false)
		}
	}

	getNames() {
		return [PROPERTIES_TOOLTIP_EXTENSION]
	}

	update() {
		if (this.selectedElement) {
			this.updateToolTipLabel()
		}
		return false
	}

	async load() {
		// Register and activate extension itself as a tool in order to handle mouse events
		this.viewer.toolController.registerTool(this)
		this.viewer.toolController.activateTool(PROPERTIES_TOOLTIP_EXTENSION)
		// initialize panel
		this.panel = new PropertiesTooltipPanel(this.viewer.container as HTMLElement, 'PropertiesTooltipPanel')
		this.panel.container.style.borderRadius = '8px'
		return true
	}

	unload() {
		this.setActive(false)
		this.viewer.toolController.deactivateTool(PROPERTIES_TOOLTIP_EXTENSION)
		this.viewer.toolController.deregisterTool(this)
		return true
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(PROPERTIES_TOOLTIP_EXTENSION, PropertiesTooltipExtension)
