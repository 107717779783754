import {Box, Text} from 'grommet'
import {SRHeading} from 'sr-react-commons'
import React from 'react'
import {ManagementDashboardAnalysisRequestFormBox} from '../support/ManagementDashboardAnalysisRequestFormBox'

export const ManagementDashboardAnalysisRequestFormNoAvailableActiveModelPage = ({
	exitForm,
}: {
	exitForm: () => void
}) => {
	return (
		<ManagementDashboardAnalysisRequestFormBox
			confirmText={'Go back to analyses'}
			confirmIcon={null}
			onConfirm={exitForm}
		>
			<SRHeading level={3}>Analysis Request #1</SRHeading>
			<Box direction="row" justify={'between'} gap="large">
				<Box width="600px" gap={'xxsmall'}>
					<SRHeading level={6}>Unable to request an analysis</SRHeading>
					<Box>
						<Text>There are no active BIM models available to create a request.</Text>
						<Text>If you have requested an active model, it will appear here once it is ready.</Text>
					</Box>
				</Box>
			</Box>
		</ManagementDashboardAnalysisRequestFormBox>
	)
}
