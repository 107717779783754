import {flatten, pick} from 'lodash'

const BASE_PROPS: (keyof SRFileExplorerBaseNode)[] = ['id', 'date', 'name', 'parent']
const FILE_PROPS: (keyof SRFileExplorerFileNode)[] = [...BASE_PROPS, 'leaf', 'selected']
const FOLDER_PROPS: (keyof SRFileExplorerFolderNode)[] = [...BASE_PROPS, 'leaf', 'children', 'loading', 'expanded']

export interface SRFileExplorerBaseNode {
	id: string
	name: string
	date: Date
	parent: SRFileExplorerFolderNode | null
}

export interface SRFileExplorerFileNode extends SRFileExplorerBaseNode {
	leaf: true
	selected: boolean
}

export interface SRFileExplorerFolderNode extends SRFileExplorerBaseNode {
	leaf: false
	children: SRFileExplorerNode[] | null
	loading: boolean
	expanded: boolean
}

export type SRFileExplorerNode = SRFileExplorerFileNode | SRFileExplorerFolderNode

export const nodeDepth = (node: SRFileExplorerNode): number => (!node.parent ? 0 : nodeDepth(node.parent) + 1)

export const selectedFileIds = (nodes: SRFileExplorerNode[]): SRFileExplorerNode['id'][] => {
	const selectedFiles = nodes.filter(n => n.leaf && n.selected) as SRFileExplorerFileNode[]
	const folders = nodes.filter(n => !n.leaf && n.children) as SRFileExplorerFolderNode[]
	return [...selectedFiles.map(n => n.id), ...flatten(folders.map(n => selectedFileIds(n.children || [])))]
}

export const findNodeById = <T extends SRFileExplorerNode = SRFileExplorerNode>(
	nodes: SRFileExplorerNode[] | null,
	id: SRFileExplorerNode['id'],
): T | undefined => {
	for (const n of nodes || []) {
		const found = n.id === id ? n : !n.leaf && findNodeById(n.children, id)
		if (found) return found as T
	}
}

export const copyNodes = <T extends SRFileExplorerNode[]>(nodes: T): T =>
	nodes.map(n =>
		n.leaf ? pick(n, FILE_PROPS) : {...pick(n, FOLDER_PROPS), children: n.children && copyNodes(n.children)},
	) as T
