import {ActionCreatorWithPayload, Draft, PayloadAction} from '@reduxjs/toolkit'
import {useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {buildInitialPaginationState, buildPaginationStateReducer, PaginationState} from './base.pagination.state'

export type SortPaginationState<SortFields extends string> = PaginationState & {
	sorting: {
		field: SortFields | null
		ascending: boolean
	}
}

export function buildInitialSortPaginationState<State extends SortPaginationState<string>>(
	initialSorting: State['sorting'],
): Pick<State, keyof SortPaginationState<string>> {
	return {...buildInitialPaginationState<State>(), sorting: initialSorting}
}

export type SortingActions<State extends SortPaginationState<string>> = {
	setSorting: ActionCreatorWithPayload<{sorting: State['sorting']}>
}

export function buildSortPaginationStateReducer<State extends SortPaginationState<string>>() {
	return {
		...buildPaginationStateReducer<State>(),
		setSorting: (state: Draft<State>, action: PayloadAction<{sorting: State['sorting']}>) => {
			state.pagination.currentPage = 0
			state.sorting = action.payload.sorting
		},
	}
}

export type SortState<SortFields extends string> = {
	sortBy: [{id: SortFields; desc: boolean}] | []
	setSorting: (payload: ReturnType<SortingActions<SortPaginationState<SortFields>>['setSorting']>['payload']) => void
}

export function useSortState<SortFields extends string>(
	sorting: SortPaginationState<SortFields>['sorting'],
	actions: SortingActions<SortPaginationState<SortFields>>,
): SortState<SortFields> {
	const dispatch = useDispatch()
	return useMemo(
		(): SortState<SortFields> => ({
			sortBy: sorting.field ? [{id: sorting.field, desc: !sorting.ascending}] : [],
			setSorting: (payload: ReturnType<SortingActions<SortPaginationState<SortFields>>['setSorting']>['payload']) => {
				dispatch(actions.setSorting(payload))
			},
		}),
		[actions, dispatch, sorting],
	)
}
