import React, {ReactEventHandler, ReactNode} from 'react'
import {AccordionPanel, Box} from 'grommet'
import {BorderLessAccordion} from '../Accordions/BorderLessAccordion'
import {SRHeading, SRIconButton} from 'sr-react-commons'
import {RotateLeft} from 'grommet-icons'

export function FiltersContainer({
	canReset = true,
	children,
	onReset,
	title,
}: {
	children: ReactNode
	title: string
	onReset: ReactEventHandler
	canReset?: boolean
}) {
	return (
		<Box elevation="small" pad={{horizontal: 'xsmall'}} background="light-3" round="xsmall">
			<BorderLessAccordion>
				<AccordionPanel
					label={
						<Box
							fill
							align="center"
							direction="row"
							justify="between"
							pad={{horizontal: 'xxsmall', vertical: 'xsmall'}}
						>
							<SRHeading level={5}>{title}</SRHeading>
							<SRIconButton
								disabled={!canReset}
								onClick={event => {
									event.stopPropagation()
									onReset(event)
								}}
								icon={<RotateLeft size="small" aria-label={'Reset Filter'} />}
							/>
						</Box>
					}
				>
					<Box fill pad={{bottom: 'small', horizontal: 'xxsmall'}}>
						{children}
					</Box>
				</AccordionPanel>
			</BorderLessAccordion>
		</Box>
	)
}
