import {IssueEntity} from '../../features/IssueManagement/issue.entities'
import {Box, CheckBox, Table, TableBody, TableCell, TableHeader, TableRow, Text} from 'grommet'
import {IssueIndicator} from './IssueIndicator'
import React from 'react'
import {SRTooltip, SRWrapperButton} from 'sr-react-commons'
import {Ascend, CircleInformation, Descend} from 'grommet-icons'
import styled from 'styled-components'
import {
	ClassificationTableRow,
	isClassificationTableColumn,
	isNumericColumn,
	isSortableClassificationsColumn,
	TableColumns,
	useClassificationsTable,
	validateSortableClassificationsColumnKey,
} from './UseClassificationsTable'
import {HeaderGroup, Row} from 'react-table'
import {ClassificationSortState} from '../../reducers/classification.slice'
import {AddIssueButton} from './AddIssueButton'
import {normalizeColor} from 'grommet/utils'

const StyledTable = styled(Table)`
	tr:nth-child(even) {
		background-color: ${props => normalizeColor('light-3', props.theme)};
	}

	tr.selected {
		background-color: ${props => normalizeColor('neutral-1', props.theme)};
		color: ${props => normalizeColor('light-1', props.theme)};
		a {
			color: ${props => normalizeColor('light-1', props.theme)};
		}
		svg {
			stroke: ${props => normalizeColor('light-1', props.theme)};
		}
		.isInteresting {
			polygon {
				stroke: ${props => normalizeColor('light-1', props.theme)};
				fill: ${props => normalizeColor('light-1', props.theme)};
			}
		}
	}

	tbody td {
		overflow-wrap: break-word;
		padding: 4px 2px;
	}

	.sr-tooltip .sr-tooltiptext {
		left: -52px;
	}

	.sr-tooltip .sr-tooltiptext::after {
		left: 76%;
	}
`

export function ClassificationDataTable({
	table,
	handleRowClick,
	handleRowDoubleClick,
	handleAddIssue,
	selectedForgeObjectIds,
	getIssuesByGlobalId,
	sortState,
	tableColumns,
	handleApprove,
	handleUnapprove,
}: {
	table: ReturnType<typeof useClassificationsTable>
	handleRowClick: (
		selectedForgeObjectIds: number[],
		event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
		row: Row<ClassificationTableRow>,
	) => void
	handleRowDoubleClick: (
		selectedForgeObjectIds: number[],
		event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
		row: Row<ClassificationTableRow>,
	) => void
	handleAddIssue: (forgeObjectId: number) => void
	selectedForgeObjectIds: number[]
	getIssuesByGlobalId: (externalId: string) => IssueEntity[]
	sortState: ClassificationSortState
	tableColumns: TableColumns
	setColumns: (columns: string[]) => void
	manualWorkflowActivated: boolean
	displayMagnitudeByAxis: boolean
	handleApprove: (forgeObjectId: number) => Promise<void>
	handleUnapprove: (forgeObjectId: number) => Promise<void>
}) {
	return (
		<Box overflow={{horizontal: 'auto'}}>
			<StyledTable {...table.getTableProps()}>
				<TableHeader>
					{table.headerGroups.map(headerGroup => (
						<TableHeaderRowWrapper
							key={headerGroup.headers[0].id}
							headerGroup={headerGroup}
							tableColumns={tableColumns}
							sortState={sortState}
						/>
					))}
				</TableHeader>
				<TableBody {...table.getTableBodyProps()}>
					{table.page.map(row => {
						table.prepareRow(row)
						return (
							<TableRowWrapper
								key={row.id}
								handleRowClick={event => {
									handleRowClick(selectedForgeObjectIds, event, row)
								}}
								handleRowDoubleClick={event => {
									handleRowDoubleClick(selectedForgeObjectIds, event, row)
								}}
								row={row}
								getIssuesByGlobalId={() => getIssuesByGlobalId(row.original.externalId)}
								handleAddIssue={() => handleAddIssue(row.original.forgeObjectId)}
								handleApprove={() => handleApprove(row.original.forgeObjectId)}
								handleUnapprove={() => handleUnapprove(row.original.forgeObjectId)}
								selected={selectedForgeObjectIds.includes(row.original.forgeObjectId)}
								tableColumns={tableColumns}
							/>
						)
					})}
				</TableBody>
			</StyledTable>
		</Box>
	)
}

function TableHeaderRowWrapper({
	headerGroup,
	tableColumns,
	sortState,
}: {
	headerGroup: HeaderGroup<ClassificationTableRow>
	tableColumns: string[]
	sortState: ClassificationSortState
}) {
	return (
		<TableRow {...headerGroup.getHeaderGroupProps()}>
			{headerGroup.headers
				.filter(column => isClassificationTableColumn(column.id) && tableColumns.includes(column.id))
				.map(column => {
					return (
						<TableCell
							pad={{vertical: 'xsmall', horizontal: 'xxsmall'}}
							width={column.width + 'px'}
							key={column.getHeaderProps().key}
						>
							<Box
								align="center"
								justify={isNumericColumn(column.id) ? 'end' : undefined}
								width={column.width + 'px'}
								gap={'xsmall'}
								direction={'row'}
							>
								{column.id === 'magnitudeDisplay' && (
									<SRTooltip
										width={200}
										tooltipText={
											'Deviation magnitude is an indication of the maximum deviation of an element, remember this is only an indicator and results can vary, it should be used with caution. Be aware that the Deviation magnitude can also differ from allocated tolerance.'
										}
									>
										<CircleInformation size="xsmall" />
									</SRTooltip>
								)}
								<ColumnHeader column={column} sortState={sortState} />
							</Box>
						</TableCell>
					)
				})}
			{tableColumns.includes('issues') && (
				<TableCell>
					<Box justify={'end'} align={'center'} direction={'row'}>
						<Text weight={600}>Issues</Text>
					</Box>
				</TableCell>
			)}
		</TableRow>
	)
}

function TableRowWrapper({
	handleRowClick,
	handleRowDoubleClick,
	selected,
	row,
	tableColumns,
	handleApprove,
	handleUnapprove,
	handleAddIssue,
	getIssuesByGlobalId,
}: {
	handleRowClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
	handleRowDoubleClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
	row: Row<ClassificationTableRow>
	selected: boolean
	tableColumns: string[]
	handleApprove: () => void
	handleUnapprove: () => void
	handleAddIssue: () => void
	getIssuesByGlobalId: (globalId: string) => IssueEntity[]
}) {
	return (
		<TableRow
			onClick={handleRowClick}
			onDoubleClick={handleRowDoubleClick}
			{...row.getRowProps()}
			className={selected ? 'selected' : undefined}
		>
			{row.cells
				.filter(cell => isClassificationTableColumn(cell.column.id) && tableColumns.includes(cell.column.id))
				.map(cell => (
					<TableCell
						pad={{vertical: 'xsmall', horizontal: 'xsmall'}}
						size={cell.column.width + 'px'}
						data-column={cell.column.id}
						{...cell.getCellProps()}
					>
						<Box width={cell.column.width + 'px'}>
							{cell.column.id !== 'approved' ? (
								<Text
									title={
										cell.column.id === 'isInteresting' ? (cell.value ? 'Bookmarked' : 'Not bookmarked') : cell.value
									}
									size={'small'}
									style={{lineHeight: '16px'}}
									truncate={!selected}
									textAlign={isNumericColumn(cell.column.id) ? 'end' : undefined}
								>
									{cell.render('Cell')}
								</Text>
							) : (
								<CheckBox
									checked={cell.value}
									label="Approved"
									onChange={() => (cell.value ? handleUnapprove() : handleApprove())}
								/>
							)}
						</Box>
					</TableCell>
				))}
			{tableColumns.includes('issues') && (
				<TableCell>
					<Box direction={'row'} gap={'xsmall'} justify={'end'} align="center">
						<IssueDisplay issues={getIssuesByGlobalId(row.original.externalId)} />
						<AddIssueButton textColor={selected ? 'light-1' : undefined} onClick={handleAddIssue} />
					</Box>
				</TableCell>
			)}
		</TableRow>
	)
}

const IssueDisplay = ({issues}: {issues: IssueEntity[]}) => {
	return (
		<Box alignContent={'center'} direction={'row'}>
			{issues.length > 0 && <IssueIndicator issues={issues} />}
		</Box>
	)
}

function ColumnHeader({
	column,
	sortState,
}: {
	column: HeaderGroup<ClassificationTableRow>
	sortState: ClassificationSortState
}) {
	const isSortable = isSortableClassificationsColumn(column)
	if (isSortable) {
		const columnId = validateSortableClassificationsColumnKey(column)
		const isColumnSorted = sortState.sortBy.some(({id}) => id === columnId)
		const isColumnSortedDesc = sortState.sortBy.find(({id}) => id === columnId)?.desc
		const toggleSortingForColumn = () =>
			isColumnSorted
				? isColumnSortedDesc
					? sortState.setSorting({
							sorting: {field: columnId, ascending: true},
					  })
					: sortState.setSorting({sorting: {field: null, ascending: true}})
				: sortState.setSorting({sorting: {field: columnId, ascending: false}})
		return (
			<SRWrapperButton {...column.getHeaderProps()} onClick={toggleSortingForColumn} title={'Sort by ' + column.Header}>
				<Text size={'small'} weight={600}>
					{column.render('Header')}
				</Text>
				{isColumnSorted && (isColumnSortedDesc ? <Descend size={'xsmall'} /> : <Ascend size={'xsmall'} />)}
			</SRWrapperButton>
		)
	} else {
		return (
			<div {...column.getHeaderProps()}>
				<Text textAlign={isNumericColumn(column.id) ? 'end' : undefined} size="small" weight={600}>
					{column.render('Header')}
				</Text>
			</div>
		)
	}
}
