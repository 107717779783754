import React, {ReactNode} from 'react'
import {SRFloatingHeaderOverlay} from 'sr-react-commons'
import {Box} from 'grommet'
import {AddToIssueBox} from '../AddToIssueButton/AddToIssueBox'
import {useLastSelectedClassification} from '../../features/Classification/data-management/useSelectedClassification'
import {captureViewerContainerAsCanvas} from '../../utilities/captureViewerContainerAsCanvas'
import {AssetsModeSwitchButton} from '../Assets/AssetModeSwithButton'

export const CENTER_BUTTON_WIDTH = 210

export function DockedViewsHeader({children}: {children: ReactNode}) {
	const classification = useLastSelectedClassification()
	const captureScreenShot = async () => {
		const canvas = await captureViewerContainerAsCanvas()
		if (canvas) return canvas.toDataURL()
		else throw new Error('Error capturing viewer')
	}
	return (
		<SRFloatingHeaderOverlay zIndex={101} dataHideOnScreenshot={true}>
			<Box direction={'row'} align="center" gap={'xsmall'} width={`calc(50% - ${CENTER_BUTTON_WIDTH / 2}px)`}>
				<AssetsModeSwitchButton />
				{classification && (classification.viewspheres.length > 0 || classification.sections.length > 0) && (
					<AddToIssueBox
						label={'Add view to issue'}
						type={'viewer'}
						issueSelection={classification ? 'current' : 'all'}
						getAssetDataFn={captureScreenShot}
					/>
				)}
			</Box>
			<>{children}</>
		</SRFloatingHeaderOverlay>
	)
}
