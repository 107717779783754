import {ClassificationEntity} from 'features/Classification/classification.entities'
import {Viewsphere} from '../reducers/classification'
import * as types from './viewerTypes'

export interface SetUpdatedSelectionAction {
	type: typeof types.GET_AGGREGATE_PROPERTIES
	forgeObjectIds: number[]
}

export interface SetViewerAction {
	type: typeof types.SET_VIEWER
	value: Autodesk.Viewing.GuiViewer3D | null
}

export interface SetViewerInitializedAction {
	type: typeof types.SET_VIEWER_INITIALIZED
	value: boolean
}

export interface SetDBReadyAction {
	type: typeof types.SET_DB_READY
	value: boolean
}

export interface SetUserProfileReadyAction {
	type: typeof types.SET_USER_PROFILE_READY
	value: boolean
}

export interface SetForgeReadyAction {
	type: typeof types.SET_FORGE_READY
	value: {
		forgeViewer: boolean
	}
}

export interface SetForgeMappingReadyAction {
	type: typeof types.SET_FORGE_MAPPING_READY
	value: {
		forgeMapping: boolean
	}
}

export interface SetVisibleElementsAction {
	type: typeof types.SET_VISIBLE_ELEMENTS
	ids: number[]
}

export interface SetClassificationsPaintedAction {
	type: typeof types.SET_CLASSIFICATIONS_PAINTED
	value: boolean
}

export interface ResetViewerStateAction {
	type: typeof types.RESET_VIEWER_STATE
}

export interface SetMaintenanceModeAction {
	type: typeof types.SET_MAINTENANCE_MODE
	value: boolean
}

export interface ToggleAssetsModeAction {
	type: typeof types.TOGGLE_ASSETS_MODE
}

export interface ToggleThreeSixtyOpenedAction {
	type: typeof types.TOGGLE_THREESIXTY_OPENED
}

export interface ToggleThreeSixtyHighlightAction {
	type: typeof types.TOGGLE_THREESIXTY_HIGHLIGHT
}

export interface ToggleThreeSixtyLockedView {
	type: typeof types.TOGGLE_THREESIXTY_LOCKED_VIEW
}

export interface ToggleClassificationsColoringAction {
	type: typeof types.TOGGLE_CLASSIFICATIONS_COLORING
}

export interface SetManualWorkflowAction {
	type: typeof types.TOGGLE_MANUAL_WORKFLOW
	value: boolean
}

export interface SetApprovedFilterAction {
	type: typeof types.SET_APPROVED_FILTER
	value: boolean | null
}

export interface SetDisplayAllElementsAction {
	type: typeof types.SET_DISPLAY_ALL_ELEMENTS
	value: boolean
}

export const doSelectThreeSixtyViewsphere = (viewsphere: Viewsphere) => ({
	type: types.THREESIXTY_VIEWSPHERE_SELECT,
	viewsphere,
})

export const doSelectThreeSixtyClassification = (classification: ClassificationEntity | null) => ({
	type: types.THREESIXTY_CLASSIFICATION_SELECT,
	classification,
})

export type DoSelectThreeSixtyViewsphereAction = ReturnType<typeof doSelectThreeSixtyViewsphere>
export type DoSelectThreeSixtyClassificationAction = ReturnType<typeof doSelectThreeSixtyClassification>

export type ViewerActionTypes =
	| SetForgeReadyAction
	| SetForgeMappingReadyAction
	| SetDBReadyAction
	| SetUserProfileReadyAction
	| SetUpdatedSelectionAction
	| SetClassificationsPaintedAction
	| ResetViewerStateAction
	| SetVisibleElementsAction
	| SetMaintenanceModeAction
	| SetViewerAction
	| SetViewerInitializedAction
	| ToggleAssetsModeAction
	| ToggleThreeSixtyOpenedAction
	| ToggleThreeSixtyHighlightAction
	| ToggleThreeSixtyLockedView
	| DoSelectThreeSixtyClassificationAction
	| DoSelectThreeSixtyViewsphereAction
	| SetManualWorkflowAction
	| SetApprovedFilterAction
	| SetDisplayAllElementsAction
	| ToggleClassificationsColoringAction

export function doSetUpdatedSelection(selectedIds: number[]): SetUpdatedSelectionAction {
	return {
		type: types.GET_AGGREGATE_PROPERTIES,
		forgeObjectIds: selectedIds,
	}
}

export const doSetDBReady = (value: boolean): SetDBReadyAction => ({
	type: types.SET_DB_READY,
	value,
})

export const doSetUserProfileReady = (value: boolean): SetUserProfileReadyAction => ({
	type: types.SET_USER_PROFILE_READY,
	value,
})

export const doSetForgeReady = (value: {forgeViewer: boolean}): SetForgeReadyAction => ({
	type: types.SET_FORGE_READY,
	value,
})

export const doSetForgeMappingReady = (value: {forgeMapping: boolean}): SetForgeMappingReadyAction => ({
	type: types.SET_FORGE_MAPPING_READY,
	value,
})

export const doSetClassificationsPainted = (value: boolean): SetClassificationsPaintedAction => ({
	type: types.SET_CLASSIFICATIONS_PAINTED,
	value,
})

export const resetViewerState = (): ResetViewerStateAction => ({
	type: types.RESET_VIEWER_STATE,
})

export const doSetMaintenanceMode = (payload: boolean): SetMaintenanceModeAction => ({
	type: types.SET_MAINTENANCE_MODE,
	value: payload,
})

export const doToggleAssetsMode = (): ToggleAssetsModeAction => ({
	type: types.TOGGLE_ASSETS_MODE,
})

export const doToggleThreeSixtyOpened = (): ToggleThreeSixtyOpenedAction => ({
	type: types.TOGGLE_THREESIXTY_OPENED,
})

export const doToggleThreeSixtyHighlight = (): ToggleThreeSixtyHighlightAction => ({
	type: types.TOGGLE_THREESIXTY_HIGHLIGHT,
})

export const doSetViewer = (value: Autodesk.Viewing.GuiViewer3D | null): SetViewerAction => ({
	type: types.SET_VIEWER,
	value,
})

export const doSetViewerInitialized = (value: boolean): SetViewerInitializedAction => ({
	type: types.SET_VIEWER_INITIALIZED,
	value,
})

export const doToggleThreeSixtyLockedView = (): ToggleThreeSixtyLockedView => ({
	type: types.TOGGLE_THREESIXTY_LOCKED_VIEW,
})

export const doSetDisplayAllElements = (value: boolean): SetDisplayAllElementsAction => ({
	type: types.SET_DISPLAY_ALL_ELEMENTS,
	value,
})

export const doToggleClassificationsColoring = (): ToggleClassificationsColoringAction => ({
	type: types.TOGGLE_CLASSIFICATIONS_COLORING,
})
