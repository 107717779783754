import {RootState} from '../../../reducers'
import {WatchlistEntity} from '../entities/watchlist.entities'
import {WatchlistState} from '../slices/watchlist.slice'

export function selectorCurrentWatchlist(state: RootState): WatchlistEntity | null {
	return state.progressMonitoringState.current.watchlist
}

export function selectorProgressMonitoring(state: RootState): WatchlistState {
	return state.progressMonitoringState
}

export function selectorCurrentProgressMonitoring(state: RootState): WatchlistState['current'] {
	return state.progressMonitoringState.current
}
