import {PayloadError} from 'sr-request-commons'

export type RetryConfig = {
	maxFailures: number
	resetTimeoutInMillis: number
	doubleTime: boolean
}

export class StopRetryingException {
	constructor(public error: Error) {}
}

type ExtendedRequestInit = RequestInit & {
	timeout?: number
}
async function fetchWithTimeout(
	resource: RequestInfo,
	options: ExtendedRequestInit = {},
	onTimedOut?: (resource: RequestInfo) => void,
) {
	const {timeout = 60000} = options

	const controller = new AbortController()
	const id = setTimeout(() => {
		onTimedOut?.(resource)
		controller.abort()
	}, timeout)
	const response = await fetch(resource, {
		...options,
		signal: controller.signal,
	})
	clearTimeout(id)
	return response
}

export async function retry<T>(callable: () => Promise<T>, props: RetryConfig): Promise<T> {
	try {
		return await callable()
	} catch (e) {
		if (e instanceof StopRetryingException) {
			throw e.error
		}
		if (props.maxFailures <= 0) {
			throw e
		}
		console.warn(`Retrying again while ${e.message}, ${props.maxFailures} retries left.`)
	}
	await sleep(props.resetTimeoutInMillis)
	const resetTimeoutInMillis = props.doubleTime ? props.resetTimeoutInMillis * 2 : props.resetTimeoutInMillis
	return retry(callable, {
		maxFailures: props.maxFailures - 1,
		resetTimeoutInMillis,
		doubleTime: props.doubleTime,
	})
}

export function sleep(timeout: number): Promise<void> {
	return new Promise(resolve => {
		setTimeout(resolve, timeout)
	})
}

export function retryOnly400To500Errors(error: Error): never {
	if (error instanceof PayloadError) {
		if (error.status >= 400 && error.status < 500) {
			console.log('Stop retrying PayloadError', error.status, error.message)
			throw new StopRetryingException(error)
		}
	}
	throw error
}

export const fetchRetry = async (
	url: RequestInfo,
	options?: ExtendedRequestInit,
	retries: number = 5,
	onTimedOut?: (resource: RequestInfo) => void,
) => {
	return retry(
		() =>
			fetchWithTimeout(url, options, onTimedOut).then(response => {
				if (response.ok || (response.status >= 400 && response.status < 500)) return response

				throw new Error(`request failed with status ${response.status}.`)
			}),
		{maxFailures: retries, resetTimeoutInMillis: 5000, doubleTime: true},
	)
}
