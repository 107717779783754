import {QueryClient, useQueryClient} from 'react-query'
import {CLASSIFICATIONS_BASE_QUERY_KEY} from './index'
import {defaultQueryClient} from '../../../utilities/query-client.util'

export const useInvalidateClassifications = () => {
	const queryClient = useQueryClient()

	return invalidateClassifications.bind({}, queryClient)
}

async function invalidateClassifications(queryClient: QueryClient) {
	return queryClient.invalidateQueries([CLASSIFICATIONS_BASE_QUERY_KEY])
}

export async function invalidateClassificationQueryData() {
	return invalidateClassifications(defaultQueryClient)
}
