import {Box, Text} from 'grommet'
import React, {useCallback, useMemo} from 'react'
import {Selection, SRMultiSelect, SRMultiSelectValueFormatter} from 'sr-react-commons'
import {LoadingWrapper} from '../../../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {
	buildSelectOptionsWithUnavailableFiltersSection,
	formatValueForModelElementFilters,
	getUnavailableFilters,
} from '../filter.helpers'

export function RootModelsFilter({
	filters,
	setFilter,
	isLoading,
	isError,
	readonly,
	rootModelOptions,
	showTitle = true,
}: {
	filters: {rootModels: string[] | null}
	setFilter: (key: 'rootModels', value: string[] | null) => void
	readonly: boolean
	isLoading: boolean
	isError: boolean
	rootModelOptions: {value: string; label: string}[] | undefined
	showTitle?: boolean
}) {
	const unavailableFilters = useMemo(
		() => (filters.rootModels && rootModelOptions ? getUnavailableFilters(filters.rootModels, rootModelOptions) : []),
		[filters.rootModels, rootModelOptions],
	)
	const onRemoveOption = useCallback(
		(toBeRemoved: string) =>
			setFilter(
				'rootModels',
				filters.rootModels!.filter(activeFilter => activeFilter !== toBeRemoved),
			),
		[filters.rootModels, setFilter],
	)
	const defaultValueFormatter: SRMultiSelectValueFormatter = useCallback(
		(selections: Selection[]) => formatValueForModelElementFilters(selections, unavailableFilters, onRemoveOption),
		[onRemoveOption, unavailableFilters],
	)
	return (
		<Box fill>
			{showTitle && (
				<Text weight="bold" size="small">
					Root model
				</Text>
			)}
			<LoadingWrapper
				{...{isLoading, isError}}
				errorMsg="We couldn't display the root model selector"
				spinnerSize="small"
			>
				{!rootModelOptions ? (
					<Text>No root model</Text>
				) : (
					<SRMultiSelect
						options={buildSelectOptionsWithUnavailableFiltersSection(rootModelOptions, unavailableFilters)}
						placeholder="All root models displayed"
						size="medium"
						onChange={(selectedOptions: string[]) => setFilter('rootModels', selectedOptions)}
						value={filters.rootModels || []}
						searchPlaceholder="Search root model"
						emptySearchMessage="No root model Found"
						valueFormatter={defaultValueFormatter}
						showToggleAllButtons
						showSearchInput
						disabled={readonly}
					/>
				)}
			</LoadingWrapper>
		</Box>
	)
}
