import React from 'react'
import _ from 'lodash'
import {format, isAfter, isBefore} from 'date-fns'
import {
	VictoryArea,
	VictoryAxis,
	VictoryBrushLine,
	VictoryChart,
	VictoryGroup,
	VictoryLabel,
	VictoryLine,
	VictoryScatter,
	VictoryTheme,
	VictoryTooltip,
	VictoryZoomContainer,
} from 'victory'
import {Box, Text} from 'grommet'
import {useTheme} from 'styled-components'
import {normalizeColor} from 'grommet/utils'

const TICK_STYLE = {axis: {stroke: 'black '}, ticks: {stroke: 'black'}, tickLabels: {fontSize: 15, padding: 5}}

export function BurnUpChart({
	data,
	startDate,
	endDate,
	todayDate = new Date().getTime(),
}: {
	data: {x: number; y: number}[]
	startDate: number
	endDate: number
	todayDate?: number
}) {
	const latestDataDate = data.length > 0 ? new Date(_.maxBy(data, 'x')!.x).getTime() : endDate
	const firstDataDate = data.length > 0 ? new Date(_.minBy(data, 'x')!.x).getTime() : startDate

	const graphStartDate = isBefore(startDate, firstDataDate)
		? startDate - Math.round((endDate - startDate) * 0.2)
		: firstDataDate - Math.round((endDate - firstDataDate) * 0.2)

	const graphEndDate = isAfter(endDate, latestDataDate)
		? endDate + Math.round((endDate - startDate) * 0.2)
		: latestDataDate + Math.round((latestDataDate - startDate) * 0.2)
	// Using any since Victory types are not great
	const toggleDateLabelEventHandlers = (): any[] => {
		const toggle = (visible: boolean) => (e: React.SyntheticEvent) => {
			const label = e.currentTarget?.parentElement?.querySelector<SVGTSpanElement>('text tspan')
			if (label) label.style.display = visible ? 'initial' : 'none'
		}
		return [{eventHandlers: {onMouseEnter: toggle(true), onMouseOut: toggle(false)}}]
	}

	const theme = useTheme()
	const getThemeColor = (color: string) => normalizeColor(color, theme)

	return (
		<VictoryChart
			height={400}
			width={600}
			padding={{top: 40, bottom: 60, left: 50, right: 30}}
			theme={VictoryTheme.material}
			scale={{x: 'time'}}
			containerComponent={
				<VictoryZoomContainer
					zoomDimension="x"
					zoomDomain={{x: [graphStartDate, graphEndDate]}}
					minimumZoom={{x: Math.pow(10, 8.5)}}
				/>
			}
		>
			<VictoryAxis
				crossAxis
				domain={[graphStartDate, graphEndDate]}
				tickFormat={t => format(new Date(t), 'dd/MM/yy')}
				tickLabelComponent={<VictoryLabel angle={-45} dx={-30} dy={-5} />}
				style={TICK_STYLE}
			/>
			<VictoryAxis
				dependentAxis
				domain={[0, 1]}
				tickFormat={t => `${t * 100}%`}
				tickLabelComponent={<VictoryLabel dx={-2} dy={-2} />}
				style={TICK_STYLE}
			/>
			<VictoryAxis
				crossAxis
				tickFormat={(t: number) => (t === todayDate ? '' : format(t, 'dd/MM/yy'))}
				orientation="top"
				tickValues={[startDate, endDate]}
				events={toggleDateLabelEventHandlers()}
				style={{
					axis: {stroke: 'transparent'},
					ticks: {stroke: getThemeColor('neutral-1'), strokeDasharray: 'none'},
					tickLabels: {fill: getThemeColor('neutral-1'), fontSize: 15, padding: 0, display: 'none'},
					grid: {stroke: 'black', strokeDasharray: 'none'},
				}}
				gridComponent={
					<VictoryBrushLine
						brushAreaStyle={{cursor: 'auto', stroke: 'none', fill: getThemeColor('neutral-1')}}
						width={10}
					/>
				}
			/>
			<VictoryAxis
				crossAxis
				tickFormat={() => 'Today'}
				orientation="top"
				tickValues={[todayDate]}
				events={toggleDateLabelEventHandlers()}
				style={{
					axis: {stroke: 'transparent'},
					grid: {stroke: getThemeColor('burn-up-tick'), strokeDasharray: 'none'},
					ticks: {stroke: getThemeColor('burn-up-tick')},
					tickLabels: {
						fill: getThemeColor('burn-up-tick'),
						fontSize: 15,
						fontWeight: 'bold',
						padding: 0,
						display: 'none',
					},
				}}
				gridComponent={
					<VictoryBrushLine
						brushAreaStyle={{
							cursor: 'auto',
							stroke: 'none',
							fill: getThemeColor('burn-up-tick'),
						}}
						width={10}
					/>
				}
			/>
			<VictoryArea
				data={[
					{x: startDate, y: 1},
					{x: endDate, y: 1},
				]}
				style={{data: {fill: getThemeColor('neutral-1'), opacity: 0.1, pointerEvents: 'none'}}}
				range={{x: [graphStartDate, graphEndDate], y: [0, 1]}}
			/>
			<VictoryGroup data={data}>
				<VictoryLine style={{data: {stroke: getThemeColor('color-built')}, parent: {border: '1px solid #ccc'}}} />
				<VictoryScatter
					size={5}
					style={{
						data: {
							stroke: getThemeColor('color-built'),
							strokeWidth: 2,
							fill: ({datum}) => (datum.x === latestDataDate ? getThemeColor('color-built') : 'white'),
						},
					}}
					labels={() => ''}
					labelComponent={
						<VictoryTooltip
							flyoutWidth={90}
							pointerLength={20}
							constrainToVisibleArea
							flyoutComponent={<FlyOutComponent />}
						/>
					}
				/>
			</VictoryGroup>
		</VictoryChart>
	)
}

type FlyoutComponentProps = {
	datum?: {x: number; y: number}
	x?: number
	y?: number
	center?: {x: number; y: number}
}

function FlyOutComponent({datum = {x: 0, y: 0}, y = 0, center = {x: 0, y: 0}}: FlyoutComponentProps) {
	const updatedX = center.x - 90 < 0 ? center.x + 30 : center.x > 480 ? center.x - 60 : center.x
	return (
		<g style={{pointerEvents: 'none'}}>
			<foreignObject x={updatedX - 100} y={y - 100} width="200" height="100">
				<Box round={'small'} background={'neutral-2'}>
					<Box margin={'small'}>
						<Text weight={600} size={'xlarge'} margin={'none'}>
							{Math.round(datum.y * 100)}%
						</Text>
						<Text weight={600} margin={'none'} size={'large'}>
							Scan date: {format(datum.x, 'dd/MM/yy')}
						</Text>
					</Box>
				</Box>
			</foreignObject>
		</g>
	)
}
