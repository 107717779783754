export type Vector3 = [number, number, number]
export type ViewportDTO = {
	name: string
	distanceToOrbit: number
	aspectRatio: number
	projection: string
	isOrthographic: boolean
	fieldOfView: number
	eye: Vector3
	target: Vector3
	up: Vector3
	worldUpVector: Vector3
	pivotPoint: Vector3
}

export function scalarFromViewerToRealWorld(scalar: number, translation: number, unitScale: number): number {
	return (scalar + translation) * unitScale
}

export function scalarFromRealWorldToViewer(scalar: number, translation: number, unitScale: number): number {
	return scalar / unitScale - translation
}

export function vector3FromViewerToRealWorldCoordinates(
	vector: Vector3,
	globalOffset: THREE.Vector3,
	unitScale: number,
): Vector3 {
	return [
		scalarFromViewerToRealWorld(vector[0], globalOffset.x, unitScale),
		scalarFromViewerToRealWorld(vector[1], globalOffset.y, unitScale),
		scalarFromViewerToRealWorld(vector[2], globalOffset.z, unitScale),
	]
}

export function vector3FromRealWorldToViewerCoordinates(
	vector: Vector3,
	globalOffset: THREE.Vector3,
	unitScale: number,
): Vector3 {
	return [
		scalarFromRealWorldToViewer(vector[0], globalOffset.x, unitScale),
		scalarFromRealWorldToViewer(vector[1], globalOffset.y, unitScale),
		scalarFromRealWorldToViewer(vector[2], globalOffset.z, unitScale),
	]
}

const VIEWPORT_COORDINATE_ATTRS = ['eye', 'target', 'pivotPoint'] as const

export function translateViewportFromRealWordToViewerCoordinates(
	realWorldCoordinates: ViewportDTO,
	globalOffset: THREE.Vector3,
	unitScale: number,
): ViewportDTO {
	return {
		...realWorldCoordinates,
		...Object.fromEntries(
			VIEWPORT_COORDINATE_ATTRS.map(attr => [
				attr,
				vector3FromRealWorldToViewerCoordinates(realWorldCoordinates[attr], globalOffset, unitScale),
			]),
		),
	}
}

export function translateViewportFromViewerToRealWorldCoordinates(
	realWorldCoordinates: ViewportDTO,
	globalOffset: THREE.Vector3,
	unitScale: number,
): ViewportDTO {
	return {
		...realWorldCoordinates,
		...Object.fromEntries(
			VIEWPORT_COORDINATE_ATTRS.map(attr => [
				attr,
				vector3FromViewerToRealWorldCoordinates(realWorldCoordinates[attr], globalOffset, unitScale),
			]),
		),
	}
}
