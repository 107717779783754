import {TOGGLE_CLASSIFICATIONS_EXTENSION} from 'components/Viewer.Components/Viewing.Extension.ToggleClassifications/Viewing.Extension.ToggleClassifications'
import {NonUndefined} from 'grommet/utils'
import _, {debounce, isNumber} from 'lodash'
import {SRFeatureFlags} from 'sr-feature-flags'
import {doSetUpdatedSelection} from '../../actions/viewer'
import {config} from '../../config'
import {Section} from '../../reducers/classification'
import {UserProject} from '../../reducers/userProfile'
import {store} from '../../store/store'
import {waitUntil} from '../../utilities/async.util'
import {translateViewportFromRealWordToViewerCoordinates, ViewportDTO} from '../../utilities/viewerUtilities'
import {scaleFromIFCtoViewer} from '../NaskaUtilities'
import {SRExtension} from '../Viewer.Components/SRExtension'
import {CUSTOM_CONTEXT_MENU_EXTENSION} from '../Viewer.Components/Viewing.Extension.CustomContextMenu/Viewing.Extension.CustomContextMenu'
import {CUSTOM_PROPERTY_PANEL_EXTENSION} from '../Viewer.Components/Viewing.Extension.CustomPropertyPanel/Viewing.Extension.CustomPropertyPanel'
import {ELEMENT_SECTIONS_EXTENSION} from '../Viewer.Components/Viewing.Extension.ElementSections/Viewing.Extension.ElementSections'
import {HEATMAP_SCENE_ID} from '../Viewer.Components/Viewing.Extension.Heatmap/Viewing.Extension.Heatmap'
import {PROPERTIES_TOOLTIP_EXTENSION} from '../Viewer.Components/Viewing.Extension.PropertiesTooltip/Viewing.Extension.PropertiesTooltip'
import {PHOTO_SPHERE_EXTENSION_ID} from '../Viewer.Components/Viewing.Extension.ThreeSixtyPhotos/PhotoSphere.viewer.extension'
import VerticalToolbarExtension from '../Viewer.Components/Viewing.Extension.VerticalToolbar'
import VIEWER_SCREENSHOTS_EXTENSION from '../Viewer.Components/Viewing.Extension.ViewerScreenshots'
import {ViewerColor} from './ViewerColor'

// THREE Pollyfills
export namespace THREEPollyfills {
	export type Triangle = {a: THREE.Vector3; b: THREE.Vector3; c: THREE.Vector3}
	export const newLine3 = (a: THREE.Vector3, b: THREE.Vector3): THREE.Line3 => new (THREE.Line3 as any)(a, b)
}

const NASKA_AI_LIGHT_PRESET = {
	name: config.sr.companyName,
	ambientColor: [0.03125, 0.03125, 0.03125],
	bgColorGradient: [255, 255, 255, 240, 241, 242],
	darkerFade: false,
	directLightColor: [1, 1, 1],
	lightDirection: [0.1, -0.55, -1],
	lightMultiplier: 1.0,
	path: null,
	rotation: 0,
	tonemap: 1,
	type: 'logluv',
}
const RENDER_ORDER_TOP_MOST = 999
const BLACK_COLOR = 0x000000
const RED_COLOR = 0xff0000
const WHITE_COLOR = new THREE.Color(1, 1, 1)
const DEBUG_OVERLAY = 'debugOverlay'
export const SECTION_PLANE_OVERLAY = 'sectionPlaneOverlay'

export const initLayers = (viewer: Autodesk.Viewing.GuiViewer3D) => {
	// Order matters
	ensureOverlay(viewer, HEATMAP_SCENE_ID)
	ensureOverlay(viewer, SECTION_PLANE_OVERLAY)
}

const forceLeafObjectSelectionModeHack = (viewer: Autodesk.Viewing.GuiViewer3D) => {
	// Fix for local storage selection mode due to Autodesk bug.
	// If not config present in the local-storage for the selection mode,
	// it does not react to setting to leaf mode. So the hack is to set it
	// to something else and then setting it to the desired mode (leaf)
	viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.FIRST_OBJECT)
	viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.LEAF_OBJECT)
}

export function unloadLoadedNaskaExtensions(viewer: Autodesk.Viewing.GuiViewer3D) {
	unloadExtension(viewer, CUSTOM_PROPERTY_PANEL_EXTENSION)
	unloadExtension(viewer, PHOTO_SPHERE_EXTENSION_ID)
	unloadExtension(viewer, VIEWER_SCREENSHOTS_EXTENSION)
	unloadExtension(viewer, ELEMENT_SECTIONS_EXTENSION)
}

function unloadExtension(viewer: Autodesk.Viewing.GuiViewer3D, extensionName: string) {
	if (viewer.isExtensionLoaded(extensionName)) {
		viewer.unloadExtension(extensionName)
	}
}

export async function loadNaskaExtensions(viewer: Autodesk.Viewing.GuiViewer3D, featureFlags: SRFeatureFlags) {
	const selectedProject = store.getState().userProfileState.selectedProject
	if (selectedProject) {
		await loadDynamicExtension(viewer, CUSTOM_PROPERTY_PANEL_EXTENSION, featureFlags)
		const displayElementSections = featureFlags.featureEnabled('displayElementSections')
		if (displayElementSections) {
			await loadDynamicExtension(viewer, ELEMENT_SECTIONS_EXTENSION, featureFlags)
		}
		if (featureFlags.featureEnabled('displayQuickProperties')) {
			await loadDynamicExtension(viewer, PROPERTIES_TOOLTIP_EXTENSION, featureFlags)
		}
		await loadDynamicExtension(viewer, VIEWER_SCREENSHOTS_EXTENSION, featureFlags)
		await loadDynamicExtension(viewer, TOGGLE_CLASSIFICATIONS_EXTENSION, featureFlags)
		await loadDynamicExtension(viewer, CUSTOM_CONTEXT_MENU_EXTENSION, featureFlags)
	}
}

export function setViewerAppearance(viewer: Autodesk.Viewing.GuiViewer3D) {
	const lightPresets = Autodesk.Viewing.Private.LightPresets
	lightPresets[0]?.name !== NASKA_AI_LIGHT_PRESET.name && lightPresets.unshift(NASKA_AI_LIGHT_PRESET)
	viewer.setLightPreset(0)
	viewer.setEnvMapBackground(false)
	viewer.setQualityLevel(false, false)

	// disable "Esc" button behavior since it resets filters
	viewer.getHotkeyManager().popHotkeys('Autodesk.Escape')
}

export async function loadModel(
	urn: string,
	viewer: Autodesk.Viewing.GuiViewer3D | null,
	forceNearRadius: number | null,
) {
	return new Promise((resolve, reject) => {
		Autodesk.Viewing.Document.load(
			urn,
			document => {
				const viewables = document.getRoot().getDefaultGeometry()
				const loadOptions: {
					isAEC: boolean
					nearRadius?: number
				} = {
					isAEC: true,
					// unfortunately the forge viewer ignores 0 as it is evaluated to false,
					// so we need to set it to a negative value to get the same result
					...(isNumber(forceNearRadius) && {nearRadius: forceNearRadius || -1}),
				}
				viewer!.loadDocumentNode(document, viewables, loadOptions).then(model => {
					resolve(model.getData()?.instanceTree !== undefined)
				})
			},
			reject,
		)
	})
}

/*
 * Currently the main purpose of this function is to patch viewer behavior of `viewer.isolate` so that
 * hidden selected elements maintain hidden when isolating selected elements.
 * This is necessary for the heatmap to work correctly since while the heatmap is active
 * the selected elements are hidden so that the full point cloud can be seen.
 */
export async function isolateAndMaintainSelectionVisibility(
	viewer: Autodesk.Viewing.Viewer3D,
	nodes: number | number[],
) {
	const selectionVisibility = viewer.getSelectionVisibility()
	const hiddenNodes = []
	const selectedIds = viewer.getSelection()
	if (selectionVisibility.hasHidden) {
		for (const id of selectedIds) {
			if (!viewer.isNodeVisible(id)) {
				hiddenNodes.push(id)
			}
		}
	}
	await viewer.isolateAsync(nodes)
	if (hiddenNodes.length > 0) {
		hiddenNodes.forEach(id => viewer.impl.visibilityManager.setVisibilityOnNode(id, false))
	}
}

// Remove textures and materials
// https://forge.autodesk.com/blog/showhide-textures-object-forge-viewer
function hideTexture(viewer: Autodesk.Viewing.GuiViewer3D) {
	// @ts-ignore
	const materials = viewer.impl.matman()._materials
	for (let materialKey in materials) {
		if (materialKey.startsWith('SR')) continue
		//index is the material name (unique string in the list)
		if (materials.hasOwnProperty(materialKey)) {
			let m = materials[materialKey]
			m.color = WHITE_COLOR
			// necessary since some materials have shaders with vertex shading and the color from the model cannot be
			// overwritten
			m.vertexColors = THREE.NoColors
			//mark the material dirty. The viewer will refresh
			m.needsUpdate = true
		}
	}

	//refresh the scene
	viewer.impl?.invalidate(false, false, true)
}

export function drawSectionPlaneIntersection(
	viewer: Autodesk.Viewing.GuiViewer3D,
	dbId?: number,
	sectionPlaneCoefficients?: NonUndefined<Section['planeCoefficients']>,
): void {
	clearOverlay(viewer, SECTION_PLANE_OVERLAY)
	if (!dbId || !sectionPlaneCoefficients) {
		viewer.impl?.invalidate(false, false, true)
		return
	}
	const pl = planeFromCoefficients(viewer, sectionPlaneCoefficients)
	const triangles = getElementTriangles(viewer, dbId)
	const DEBUG_TRIANGLES = false // Set it to true to render the element's triangle
	if (DEBUG_TRIANGLES) {
		clearOverlay(viewer, DEBUG_OVERLAY)
		drawTriangles(viewer, triangles, DEBUG_OVERLAY)
	}
	const lineOpts = {overlay: SECTION_PLANE_OVERLAY, color: BLACK_COLOR, drawOnTop: true}
	triangles.forEach(t => {
		const intersections = intersectPlaneTriangle(pl, t)
		intersections && drawLine(viewer, intersections[0], intersections[1], lineOpts)
	})
}

const intersectPlaneTriangle = (pl: THREE.Plane, t: THREEPollyfills.Triangle): THREE.Vector3[] | undefined => {
	const points: THREE.Vector3[] = []
	const addPoint = (point: THREE.Vector3 | undefined) => point && points.push(point)
	addPoint(pl.intersectLine(THREEPollyfills.newLine3(t.a, t.b), new THREE.Vector3()))
	addPoint(pl.intersectLine(THREEPollyfills.newLine3(t.b, t.c), new THREE.Vector3()))
	addPoint(pl.intersectLine(THREEPollyfills.newLine3(t.c, t.a), new THREE.Vector3()))
	return points.length ? points : undefined
}

const planeFromCoefficients = (
	viewer: Autodesk.Viewing.GuiViewer3D,
	[a, b, c, d]: [number, number, number, number],
): THREE.Plane => {
	const normal = new THREE.Vector3(a, b, c)
	const projectedOffset = normal.dot(viewer.impl.model.getData().globalOffset)
	const correctedDistance = d * scaleFromIFCtoViewer(viewer.impl.model) + projectedOffset
	return new THREE.Plane(normal, correctedDistance)
}

export function fragmentIdsFromDbId(viewer: Autodesk.Viewing.GuiViewer3D, dbId: number): number[] {
	const fragIds: number[] = []
	viewer.model.getInstanceTree().enumNodeFragments(
		dbId,
		id => {
			fragIds.push(id)
		},
		true,
	)
	return fragIds
}

export function getElementTriangles(viewer: Autodesk.Viewing.GuiViewer3D, dbId: number): THREEPollyfills.Triangle[] {
	const fragIds = fragmentIdsFromDbId(viewer, dbId)
	const triangles: THREEPollyfills.Triangle[] = []
	for (const fragId of fragIds) {
		const rp = viewer.impl.getRenderProxy(viewer.model, fragId)
		if (!rp.geometry) continue
		const {vb: vertices, ib: vertexIndexes, vbstride: verticesItemSize} = rp.geometry as {
			vb: number[]
			ib: number[]
			vbstride: number
		}
		// The vertices array stores the x,y,z coordinates as consecutive numbers of all the vertices that
		// composes a fragment object. Items have to be taken by verticesItemSize that is usually 4 or 3, if
		// it is 4, the 4th coordinate is skipped.
		const vertexAt = (vertexIndex: number): THREE.Vector3 => {
			const i = vertexIndex * verticesItemSize
			return rp.localToWorld(new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]))
		}
		// The vertexIndexes array stores triangle vertex indexes.
		// Since triangles have 3 vertex, the iteration step is 3.
		for (let i = 0; i < vertexIndexes.length; i += 3) {
			const a = vertexAt(vertexIndexes[i])
			const b = vertexAt(vertexIndexes[i + 1])
			const c = vertexAt(vertexIndexes[i + 2])
			triangles.push({a, b, c})
		}
	}
	return triangles
}

export async function waitForFinalFrameRendered(viewer: Autodesk.Viewing.GuiViewer3D): Promise<void> {
	const condition = (e: {value: {finalFrame?: boolean}}) => !!e.value.finalFrame
	return waitForViewerEvent(viewer, Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT, condition, 10, false)
}

export async function waitForViewerEvent(
	viewer: Autodesk.Viewing.GuiViewer3D,
	name: string,
	condition: (e: any) => boolean = () => true,
	timeout: number = 60 * 5, // 5 minutes
	throwOnTimout: boolean = true,
): Promise<void> {
	await new Promise<void>((resolve, reject) => {
		const timeoutId = setTimeout(() => {
			const errorMessage = `Viewer did not fire ${name} event within ${timeout}s timeout.`
			viewer.removeEventListener(name, callback)
			if (throwOnTimout) {
				reject(new Error(errorMessage))
			} else {
				console.log(errorMessage)
				resolve()
			}
		}, timeout * 1000)
		const callback = (e: any) => {
			if (condition(e)) {
				viewer.removeEventListener(name, callback)
				clearTimeout(timeoutId)
				resolve()
			}
		}
		viewer.addEventListener(name, callback)
	})
}

export function ensureOverlay(viewer: Autodesk.Viewing.GuiViewer3D, overlay: string): void {
	if (!viewer.impl.overlayScenes.hasOwnProperty(overlay)) {
		viewer.impl.createOverlayScene(overlay)
	}
}

export function clearOverlay(viewer: Autodesk.Viewing.GuiViewer3D, overlay: string): void {
	const scene: THREE.Scene | undefined = viewer?.impl?.overlayScenes[overlay]?.scene
	if (!scene) return
	const children = [...scene.children]
	scene.remove.apply(scene, children as [THREE.Object3D])
	children.forEach(node => disposeThreeNode(node))
}

export function drawTriangles(
	viewer: Autodesk.Viewing.GuiViewer3D,
	triangles: THREEPollyfills.Triangle[],
	overlay: string = DEBUG_OVERLAY,
): void {
	const opts = {overlay, drawOnTop: false}
	triangles.forEach(t => {
		drawLine(viewer, t.a, t.b, opts)
		drawLine(viewer, t.b, t.c, opts)
		drawLine(viewer, t.c, t.a, opts)
	})
}

export function drawLine(
	viewer: Autodesk.Viewing.GuiViewer3D,
	start: THREE.Vector3,
	end: THREE.Vector3,
	opts: {
		overlay?: string
		color?: string | number
		linewidth?: number
		drawOnTop?: boolean
	},
) {
	const DEFAULTS = {overlay: DEBUG_OVERLAY, color: RED_COLOR, linewidth: 1, drawOnTop: false}
	const {linewidth, drawOnTop, color, overlay} = {...DEFAULTS, ...opts}
	const material = new THREE.LineBasicMaterial({
		color: color,
		linewidth,
		depthTest: !drawOnTop,
		depthWrite: !drawOnTop,
	})
	const geometry = new THREE.Geometry()
	geometry.vertices.unshift(start, end)
	const line = new THREE.Line(geometry, material)
	if (drawOnTop) {
		line.renderOrder = RENDER_ORDER_TOP_MOST
		;(line as any).onBeforeRender = (renderer: any) => renderer.clearDepth()
	}
	ensureOverlay(viewer, overlay)
	viewer.impl.addOverlay(overlay, line)
	viewer.impl.invalidate(false, false, true)
}

export function onModelLoaded(
	viewer: Autodesk.Viewing.GuiViewer3D,
	selectedProject: UserProject,
	viewport: ViewportDTO | undefined,
) {
	const {elementsToHide} = selectedProject

	const stateFilter = {
		cutplanes: false,
		objectSet: false,
		seedUrn: false,
		renderOptions: true,
		viewport: true,
	}

	waitForFinalFrameRendered(viewer).then(async () => {
		await waitUntil(async () => !!viewer.autocam)
		viewer.autocam.setCurrentViewAsHome(false)
	})

	viewer.restoreState(
		viewport
			? {
					viewport: translateViewportFromRealWordToViewerCoordinates(
						viewport,
						viewer.model.getGlobalOffset(),
						viewer.model.getUnitScale(),
					),
			  }
			: undefined,
		stateFilter,
	)
	hideTexture(viewer)
	setViewerAppearance(viewer)

	//Fix for selection mode
	forceLeafObjectSelectionModeHack(viewer)

	// Hide elements specified in config
	viewer.hide(elementsToHide)
	elementsToHide.map(el => viewer.impl.visibilityManager.setNodeOff(el, true))

	const onSelectionChange = (event: {target: Autodesk.Viewing.GuiViewer3D}) => selectElementsWithClassifications(event)

	const eventListener = debounce(onSelectionChange, 100)

	// The debounce is needed since the selection changed event is called twice
	viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, eventListener)

	// After toolbars are created
	viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, onToolbarCreated, {once: true})

	viewer.addEventListener(
		Autodesk.Viewing.MODEL_UNLOADED_EVENT,
		() => {
			viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, eventListener)
		},
		{once: true},
	)
}

// remove unwanted toolbar buttons
const onToolbarCreated = (event: any) => {
	const viewer = event.target
	const navTools = viewer.toolbar.getControl('navTools') as Autodesk.Viewing.UI.ControlGroup
	navTools.removeControl('toolbar-cameraSubmenuTool')
	const settingsTools = viewer.toolbar.getControl('settingsTools') as Autodesk.Viewing.UI.ControlGroup
	settingsTools.removeControl('toolbar-fullscreenTool')
	viewer.removeEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, onToolbarCreated)
}

async function loadDynamicExtension(
	viewer: Autodesk.Viewing.GuiViewer3D,
	extensionId: string,
	srFeatureFlags: SRFeatureFlags,
) {
	await import(`../Viewer.Components/${extensionId}`).then(function () {
		return viewer
			.loadExtension(extensionId)
			.then(extension => (extension as SRExtension).setSrFeatureFlags(srFeatureFlags))
	})
}

function selectionChanged(oldSelection: number[], newSelection: number[]) {
	return (
		oldSelection.some((id: number) => !newSelection.includes(id)) ||
		newSelection.some((id: number) => !oldSelection.includes(id))
	)
}

function getUpdatedSelection(viewer: Autodesk.Viewing.GuiViewer3D, selectedDbIds: number[]): number[] {
	const getParentId = (id: number): number => {
		const instanceTree = viewer.model.getData().instanceTree
		return instanceTree.getNodeParentId(id)
	}
	const newSelection = selectedDbIds.map((id: number) => {
		const viewerColor = new ViewerColor(viewer)
		const dbIds = []
		// try to find an element in the hierarchy that is classified
		for (let dbId = id; dbId; dbId = getParentId(dbId)) {
			dbIds.push(dbId)
		}
		return viewerColor.findPaintedElementByMinDepth(dbIds, viewer) || id
	})

	// When shift-clicking on an element that is already selected
	// the selectedDbIds array will contain both the parent dbId that was already selected
	// as also the leaf dbId from the last shift-click.
	// newSelection will therefore contain the parent dbId twice
	// which means that it should be removed from the selection.
	return Object.entries(_.countBy(newSelection))
		.filter(([, value]) => value === 1)
		.map(([key]) => parseInt(key, 10))
}

function selectElementsWithClassifications(event: any) {
	const viewer = event.target
	if (event.selections.length > 0) {
		const selectedDbIds = event.selections[0].dbIdArray
		const newSelection = getUpdatedSelection(viewer, selectedDbIds)
		if (selectionChanged(selectedDbIds, newSelection)) {
			// if getUpdatedSelection found any elements suited for selection
			// we need to programmatically tell the viewer to select this new selection.
			// this will result in firing the selection event again and therefore
			// this method will be called again, but then it should call the store dispatch only
			viewer.select(newSelection)
		} else {
			updateSelection(newSelection)
		}
	} else {
		updateSelection([])
	}
}

async function updateSelection(dbIds: number[]) {
	store.dispatch(doSetUpdatedSelection(dbIds))
}

export function getVerticalToolBar(viewer: Autodesk.Viewing.Viewer3D) {
	const verticalToolbarExt = viewer.getExtension('Viewing.Extension.VerticalToolbar') as VerticalToolbarExtension
	return verticalToolbarExt.SRVerticalToolbar
}

export function createBoundingBox(elementBoundaries: string[]) {
	// Prepare bounding box color according to element state
	const bBoxMaterial = new THREE.LineBasicMaterial({
		color: 0xfa00ff,
		linewidth: 3,
	})

	// Prepare bounding box vertices
	const bboxVertices = []
	for (let vertex = 0; vertex < elementBoundaries.length / 2; ++vertex) {
		const vertexPhi = THREE.Math.degToRad(90 - parseFloat(elementBoundaries[vertex * 2]))
		const vertexTheta = THREE.Math.degToRad(parseFloat(elementBoundaries[vertex * 2 + 1]))
		const vertexCoords = new THREE.Vector3(
			499 * Math.sin(vertexPhi) * Math.cos(vertexTheta),
			499 * Math.cos(vertexPhi),
			499 * Math.sin(vertexPhi) * Math.sin(vertexTheta),
		)
		bboxVertices.push(vertexCoords)
	}

	// In order to provide a fast visual representation on top of the 360 image, bounding boxes are rendered
	// as a line-based prism handcrafted from vertices in polar coordinates.
	// We "follow the dots" a bit here in order to build the topology in the correct order
	// as vertices are shared from the backend:
	//
	//    7----3
	//   /|    |\
	//  6--------2
	//  | |    | |
	//  | 5----1 |
	//  |/      \|
	//  4--------0
	//
	const bBoxGeometry = new THREE.Geometry()
	bBoxGeometry.vertices.push(
		bboxVertices[0],
		bboxVertices[4],
		bboxVertices[5],
		bboxVertices[1],
		bboxVertices[0],
		bboxVertices[2],
		bboxVertices[6],
		bboxVertices[7],
		bboxVertices[3],
		bboxVertices[2],
		bboxVertices[0],
		bboxVertices[1],
		bboxVertices[3],
		bboxVertices[7],
		bboxVertices[5],
		bboxVertices[4],
		bboxVertices[6],
	)

	return new THREE.Line(bBoxGeometry, bBoxMaterial)
}

export function getCameraTargetFromOrientation(lat: number, lon: number) {
	const phi = THREE.Math.degToRad(90 - lat)
	const theta = THREE.Math.degToRad(lon)
	return new THREE.Vector3(
		500 * Math.sin(phi) * Math.cos(theta),
		500 * Math.cos(phi),
		500 * Math.sin(phi) * Math.sin(theta),
	)
}

export function disposeThreeNode(node: any) {
	// https://stackoverflow.com/questions/33152132/three-js-collada-whats-the-proper-way-to-dispose-and-release-memory-garbag

	if (node instanceof THREE.Mesh) {
		if (node.geometry) {
			node.geometry.dispose()
		}

		if (node.material) {
			if (!(node.material instanceof THREE.MeshFaceMaterial)) {
				// @ts-ignore
				node.material.map?.dispose()

				// @ts-ignore
				node.material.dispose()
			}
		}
	}
}

export function removeMeshAndDisposeMaterial(viewer: Autodesk.Viewing.GuiViewer3D, sceneId: string, mesh: THREE.Mesh) {
	viewer.overlays.removeMesh(mesh, sceneId)
	mesh.geometry.dispose()
	if (Array.isArray(mesh.material)) {
		mesh.material.forEach(mat => mat.dispose())
	} else {
		mesh.material.dispose()
	}
}

export function getCenterOfElementBounds(dbId: number, viewer: Autodesk.Viewing.GuiViewer3D): THREE.Vector3 {
	const model = viewer.model
	const instanceTree = model.getData().instanceTree
	const fragList = model.getFragmentList()

	let bounds = new THREE.Box3()

	instanceTree.enumNodeFragments(
		dbId,
		(fragId: any) => {
			let box = new THREE.Box3()
			fragList.getWorldBounds(fragId, box)
			bounds.union(box)
		},
		true,
	)

	// @ts-ignore
	return bounds.getCenter()
}
