import {ProgressAggregation, progressPercentage} from './aggregations/progress.aggregations'
import {WatchlistEntity} from './entities/watchlist.entities'
import {
	SR_COLOR_BRAND_MAIN,
	SR_COLOR_DEVIATED,
	SR_COLOR_MISSING,
	SR_COLOR_NEUTRAL_1,
	SR_COLOR_NEUTRAL_2,
	SR_COLOR_NO_DATA,
	SR_COLOR_TEXT_LIGHT,
	SR_COLOR_UNDER_CONSTRUCTION,
	SR_COLOR_VERIFIED,
} from 'sr-react-commons'

export type WatchlistStatus = 'Not started' | 'In progress' | 'Completed' | 'Delayed'

export type WatchlistClassification = 'Verified' | 'Deviated' | 'Under construction' | 'Missing' | 'No data'

const COLOR_BY_STATUS = {
	Completed: {bg: SR_COLOR_VERIFIED, text: 'white'},
	'In progress': {bg: SR_COLOR_BRAND_MAIN, text: SR_COLOR_TEXT_LIGHT},
	Delayed: {bg: SR_COLOR_NEUTRAL_1, text: 'white'},
	'Not started': {bg: SR_COLOR_NEUTRAL_2, text: SR_COLOR_TEXT_LIGHT},
}

const COLOR_BY_CLASSIFICATION = {
	Verified: {bg: SR_COLOR_VERIFIED},
	Deviated: {bg: SR_COLOR_DEVIATED},
	'Under construction': {bg: SR_COLOR_UNDER_CONSTRUCTION},
	Missing: {bg: SR_COLOR_MISSING},
	'No data': {bg: SR_COLOR_NO_DATA, text: SR_COLOR_NEUTRAL_1},
} as const

export const watchListStatus = (progress: ProgressAggregation): WatchlistStatus => {
	const now = new Date().getTime()
	const start = new Date(progress.watchlist.startDate).getTime()
	const end = new Date(progress.watchlist.endDate).getTime()
	const completed = progressPercentage(progress) === 100
	return now < start ? 'Not started' : start < now && now < end ? 'In progress' : completed ? 'Completed' : 'Delayed'
}

export const watchlistStatusColor = (status: WatchlistStatus, type: 'bg' | 'text' = 'bg'): string =>
	COLOR_BY_STATUS[status][type]

export const watchlistClassificationColor = (c: WatchlistClassification, type: 'bg' | 'text' = 'bg'): string =>
	({text: COLOR_BY_CLASSIFICATION[c]['bg'], ...COLOR_BY_CLASSIFICATION[c]}[type])

export function workPackageHasInvalidIds(watchlist: WatchlistEntity, latestModelId: string) {
	const modelValid = watchlist.modelValid.find(m => m.model === latestModelId)
	if (!modelValid) {
		console.error(`Watchlist does not have a modelValid entry for model ${latestModelId}`)
		return false
	}
	return !modelValid.allExternalIdsAreInModel
}
