import React, {useEffect, useState} from 'react'

import {useBasePath} from '../../../../hooks/useBasePath'
import {WatchlistCreatePresenter} from './WatchlistCreatePresenter'
import {goTo} from '../../../../router/history'
import {createWatchlist, CreateWatchlistDTO} from '../../api/watchlist.api'
import {useGlobalNotifications} from '../../../GlobalNotifications'
import {PayloadError} from 'sr-request-commons'
import {useDisableVerticalToolbar} from '../../../../hooks/useDisableVerticalToolbarButtons'
import {useCurrentProject} from '../../../../hooks/useCurrentProject'
import {watchlistFilterElementsSlice} from '../../slices/watchlist-filter-elements.slice'
import {useDispatch} from 'react-redux'

export function WatchlistCreate() {
	const basePath = useBasePath()
	const dispatch = useDispatch()
	const project = useCurrentProject()
	const {notifyAlert} = useGlobalNotifications()
	const [missingIds, setMissingIds] = useState<string[] | undefined>(undefined)

	const submitWatchlist = async (dto: CreateWatchlistDTO) => {
		try {
			const result = await createWatchlist(dto)
			goTo(`${basePath}progress/work-packages/${result._id}/detail`)
		} catch (e) {
			if (e instanceof PayloadError && e.responseData.type === 'ProgressMonitoring.CreateWorkPackage.MissingIds') {
				setMissingIds(e.responseData.payload.ids as string[])
			} else {
				console.error('Error in submitWatchlist', e.message)
				notifyAlert(e.message)
			}
		}
	}
	useDisableVerticalToolbar()

	useEffect(() => {
		// reset filters when unmounting WatchlistCreate component
		return () => {
			dispatch(watchlistFilterElementsSlice.actions.resetFilter())
		}
	}, [dispatch])

	return project ? <WatchlistCreatePresenter {...{watchlist: null, project, submitWatchlist, missingIds}} /> : null
}
