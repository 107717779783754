import {
	ManagementDashboardTable,
	ManagementDashboardTableCell,
	ManagementDashboardTableHeaderCell,
	ManagementDashboardTableRowBox,
} from '../core/components/table/ManagementDashboardTable'
import {
	ManagementDashboardFilePage,
	ManagementDashboardPage,
	pageDisplayVersion,
	pageSingularName,
} from '../core/management-dashboard-page.utils'
import {format} from 'date-fns'
import React, {useState} from 'react'
import {ProjectFileEntity} from '../../../api/files.api'
import {SRChip, SRDeleteSecondaryIconButton} from 'sr-react-commons'
import {ConfirmFileDeleteModal} from './modals/ConfirmFileDeleteModal'
import {getIconByForgeFileSource} from '../../ForgeIntegration/forge-integration.utils'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'
import {Blank, StatusCritical} from 'grommet-icons'
import {Box, Text} from 'grommet'

export const FILE_NAME_CELL_MIN_WIDTH = '150px'
export const VERSION_CELL_WIDTH = '90px'
export const UPLOADED_CELL_WIDTH = '180px'
export const UPLOADED_BY_CELL_WIDTH = '280px'
export const DELETE_CELL_WIDTH = '32px'

type ManagementDashboardFileTableProps = {
	page: ManagementDashboardPage
	files: ProjectFileEntity[]
	isAdmin: boolean
	onDeleteFile: (deleteId: string) => Promise<void>
	className?: string
}

export const ManagementDashboardFileTable = ({className, ...props}: ManagementDashboardFileTableProps) => {
	const [deleteId, setDeleteId] = useState<string>()
	const onDeleteFile = () => {
		setDeleteId(undefined)
		if (deleteId) {
			return props.onDeleteFile(deleteId)
		}
	}
	return (
		<ManagementDashboardTable className={className}>
			<ManagementDashboardTableRowBox>
				<ManagementDashboardTableHeaderCell flexible width={{min: FILE_NAME_CELL_MIN_WIDTH}}>
					{pageSingularName(props.page)} files
				</ManagementDashboardTableHeaderCell>
				{!pageDisplayVersion(props.page as ManagementDashboardFilePage) ? null : (
					<ManagementDashboardTableHeaderCell width={VERSION_CELL_WIDTH}>Version</ManagementDashboardTableHeaderCell>
				)}
				<ManagementDashboardTableHeaderCell width={UPLOADED_CELL_WIDTH}>Uploaded</ManagementDashboardTableHeaderCell>
				<ManagementDashboardTableHeaderCell width={UPLOADED_BY_CELL_WIDTH}>
					Uploaded by
				</ManagementDashboardTableHeaderCell>
				{props.isAdmin && (
					<ManagementDashboardTableHeaderCell width={DELETE_CELL_WIDTH}></ManagementDashboardTableHeaderCell>
				)}
			</ManagementDashboardTableRowBox>
			{props.files.map(file => (
				<ManagementDashboardTableRowBox key={file._id}>
					<ManagementDashboardTableCell flexible width={{min: FILE_NAME_CELL_MIN_WIDTH}}>
						<Box flex={{grow: 0, shrink: 0}} alignSelf="center">
							{file.status === 'uploading' ? (
								<Box title="Uploading your file">
									<Spinner size="small" center={false} />
								</Box>
							) : file.status === 'error' ? (
								<Box title="Error uploading your file">
									<StatusCritical size="large" color={'accent-2'} />
								</Box>
							) : file.fileSource?.name === 'forge' ? (
								getIconByForgeFileSource(file.fileSource, 'large')
							) : (
								<Blank size={'large'} />
							)}
						</Box>
						<Text alignSelf="center" title={file.filename} truncate>
							{file.filename}
						</Text>
					</ManagementDashboardTableCell>
					{!pageDisplayVersion(props.page as ManagementDashboardFilePage) ? null : (
						<ManagementDashboardTableCell width={VERSION_CELL_WIDTH}>
							<SRChip
								background={'neutral-1'}
								width={{min: '0'}}
								text={file.fileSource?.name === 'forge' ? file.fileSource?.meta.forgeVersion : '-'}
							/>
						</ManagementDashboardTableCell>
					)}
					<ManagementDashboardTableCell width={UPLOADED_CELL_WIDTH}>
						{format(new Date(file.createdDate), 'dd/MM/yyyy HH:mm')}
					</ManagementDashboardTableCell>
					<ManagementDashboardTableCell width={UPLOADED_BY_CELL_WIDTH}>
						<Text alignSelf="center" title={file.createdByRef?.email} truncate>
							{file.fileSource?.name === 'masv' ? 'Uploaded from MASV' : file.createdByRef?.email}
						</Text>
					</ManagementDashboardTableCell>
					{props.isAdmin && (
						<ManagementDashboardTableCell width={DELETE_CELL_WIDTH}>
							<SRDeleteSecondaryIconButton onClick={() => setDeleteId(file._id)} />
						</ManagementDashboardTableCell>
					)}
				</ManagementDashboardTableRowBox>
			))}
			{deleteId && <ConfirmFileDeleteModal onDelete={onDeleteFile} onCancel={() => setDeleteId(undefined)} />}
		</ManagementDashboardTable>
	)
}
