import {AnalysisVersionMap} from '../../../reducers/classification.slice'
import {FiltersEntity} from '../../FilterPresets/entities/filter-preset.entities'
import {buildAxios} from '../../../api/api-factory'
import {config} from '../../../config'

export const insightsFeatureApiV3 = buildAxios(`${config.sr.backendUrl}insights/v3/`)

export async function fetchClassificationsForInsights(
	selectedAnalysisViewId: string,
	analysisVersionMap: AnalysisVersionMap,
	filtersDTO: FiltersEntity,
	projectId: string,
): Promise<Record<string, number[]>> {
	const url = `projects/${projectId}/analysis/${encodeURIComponent(selectedAnalysisViewId)}/classifications`
	const response = await insightsFeatureApiV3.get(url, {
		params: {filtersDTO, analysisVersionMap},
	})
	return response.data
}

export async function fetchMagnitudesForInsights(
	selectedAnalysisViewId: string,
	analysisVersionMap: AnalysisVersionMap,
	filtersDTO: FiltersEntity,
	projectId: string,
): Promise<number[]> {
	const url = `projects/${projectId}/analysis/${encodeURIComponent(selectedAnalysisViewId)}/magnitudes`
	const response = await insightsFeatureApiV3.get(url, {
		params: {filtersDTO, analysisVersionMap},
	})
	return response.data
}

export async function fetchElementTypesForInsights(
	selectedAnalysisViewId: string,
	analysisVersionMap: AnalysisVersionMap,
	filtersDTO: FiltersEntity,
	projectId: string,
): Promise<{label: string; total: number}[]> {
	const url = `projects/${projectId}/analysis/${encodeURIComponent(selectedAnalysisViewId)}/element-types`
	const response = await insightsFeatureApiV3.get(url, {
		params: {filtersDTO, analysisVersionMap},
	})
	return response.data
}
