import {buildAxios} from './api-factory'
import {config} from '../config'
import {Id} from '../entities/value-objects'
import {UserProject} from '../reducers/userProfile'
import {WatchlistElementsFilter} from '../features/Watchlist/slices/watchlist-filter-elements.slice'

export const srElementApiV3 = buildAxios(`${config.sr.backendUrl}element/v3`)

let currentProjectId: UserProject['_id']
export const setCurrentProjectId = (projectId: UserProject['_id']) => {
	currentProjectId = projectId
}

const projectPath = async (id?: Id, tail?: string, projectId: string = currentProjectId) =>
	`projects/${projectId}/elements/${id ? `${encodeURIComponent(id)}/` : ''}${tail ? `${tail}` : ''}`

export async function fetchElementTypesByModel(modelId: string): Promise<string[][]> {
	return (await srElementApiV3.get(await projectPath(undefined, `by-model/${modelId}/element-types`))).data
}

export async function fetchElementTypesFromLatestModel(): Promise<string[][]> {
	return (await srElementApiV3.get(await projectPath(undefined, 'latest-model/element-types'))).data
}

export async function fetchElementFloorsByModel(modelId: string): Promise<string[]> {
	return (await srElementApiV3.get(await projectPath(undefined, `by-model/${modelId}/floors`))).data
}

export async function fetchElementRootModelsByModel(
	modelId: string,
	projectId: string = currentProjectId,
): Promise<string[]> {
	return (await srElementApiV3.get(await projectPath(undefined, `by-model/${modelId}/root-models`, projectId))).data
}

export async function fetchElementFloorsFromLatestModels(): Promise<string[]> {
	return (await srElementApiV3.get(await projectPath(undefined, 'latest-model/floors'))).data
}

export async function fetchElementRootModelsFromLatestModel(): Promise<string[]> {
	return (await srElementApiV3.get(await projectPath(undefined, 'latest-model/rootModels'))).data
}

export async function fetchForgeObjectIdsByFilterFromLatestModel(filter: WatchlistElementsFilter): Promise<number[]> {
	return (
		await srElementApiV3.get(
			await projectPath(undefined, `latest-model/for-colorizing?filtersDTO=${JSON.stringify(filter)}`),
		)
	).data
}
