import {MODELS_PAGE_META} from '../models/management-dashboard-models-page-meta'
import {SCANS_PAGE_META} from '../scans/management-dashboard-scans-page-meta'
import {INTEGRATIONS_PAGE_META} from '../integrations/management-dashboard-integrations-page-meta'
import {PermissionsEnum} from '../../../entities/auth'
import {UserProject, userProjectHasPermission} from '../../../reducers/userProfile'
import {useManagementDashboardState} from './management-dashboad.slice'
import {DOCUMENT_PAGE_META} from '../documents/management-dashboard-documents-page-meta'
import {ANALYSIS_REQUEST_PAGE_META} from '../analysisRequest/management-dashboard-analysis-request-page-meta'
import {SrFeatureFlag} from 'sr-feature-flags'
import {useFeatureEnabledFunc} from '../../FeatureFlags/FeatureFlagsProvider'

export type ManagementDashboardPageMeta<
	SUBPAGE extends string | undefined = undefined,
	__SUBPAGE__ = SUBPAGE | undefined
> = {
	__SUBPAGE__?: __SUBPAGE__ // A type to assume valid subpages by page
	name: string | ((subpage: __SUBPAGE__) => string)
	permission: PermissionsEnum
	feature?: SrFeatureFlag
	singular?: string
	emptyView?: {
		title: string
		message: string
		actionText: string
	}
}

export type ManagementDashboardPageFileMeta = ManagementDashboardPageMeta & {
	displayVersion: boolean
}

const PAGE_META = {
	analysisrequest: ANALYSIS_REQUEST_PAGE_META,
	scans: SCANS_PAGE_META,
	models: MODELS_PAGE_META,
	documents: DOCUMENT_PAGE_META,
	integrations: INTEGRATIONS_PAGE_META,
} as const

export type ManagementDashboardPage = keyof typeof PAGE_META | null

export type ManagementDashboardFilePage = 'scans' | 'models' | 'documents'

export type ManagementDashboardSubPage<
	T extends NonNullable<ManagementDashboardPage> = NonNullable<ManagementDashboardPage>
> = typeof PAGE_META[T]['__SUBPAGE__']

export const isValidPage = (page?: string): boolean => !!page && Object.keys(PAGE_META).includes(page)

export const pageName = (page: ManagementDashboardPage, subpage: ManagementDashboardSubPage = undefined) => {
	const prop = page && PAGE_META[page]['name']
	return prop && (typeof prop === 'string' ? prop : prop(subpage as any))
}

export const pageSingularName = (page: ManagementDashboardPage) => page && PAGE_META[page]['singular']

export const pageDisplayVersion = (page: ManagementDashboardFilePage) => PAGE_META[page]['displayVersion']

export const pageEmptyViewParams = (page: ManagementDashboardPage) => page && PAGE_META[page]['emptyView']
export const pagePermission = (page: ManagementDashboardPage): PermissionsEnum | null =>
	(page && PAGE_META[page]['permission']) || null

export const pageFeature = (page: ManagementDashboardPage): SrFeatureFlag | undefined =>
	(page && PAGE_META[page]['feature']) || undefined

export const useIsManagementDashboardPageEnabled = () => {
	const {project} = useManagementDashboardState()
	const featureEnabled = useFeatureEnabledFunc()
	return (page: ManagementDashboardPage) => {
		const permission = pagePermission(page)
		const feature = pageFeature(page)
		return (!permission || userProjectHasPermission(project, permission)) && (!feature || featureEnabled(feature))
	}
}

export const isManagementDashboardAnyPageEnabled = (
	project: UserProject,
	featureEnabled: (id: SrFeatureFlag) => boolean,
) => {
	const isPageEnabled = (page: ManagementDashboardPage) => {
		const permission = pagePermission(page)
		const feature = pageFeature(page)
		return (!permission || userProjectHasPermission(project, permission)) && (!feature || featureEnabled(feature))
	}
	return Object.keys(PAGE_META).some(pageName => isPageEnabled(pageName as ManagementDashboardPage))
}
