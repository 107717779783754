import React from 'react'
import {Box, Text} from 'grommet'
import {Edit} from 'grommet-icons'
import {format} from 'date-fns'
import {ProgressDonutChart} from '../charts/ProgressDonutChart'
import {ProgressAggregation, progressPercentage} from '../../aggregations/progress.aggregations'
import {watchListStatus, watchlistStatusColor} from '../../watchlist.utils'
import {SRCard, SRChip, SRHeading, SRIconButton} from 'sr-react-commons'
import {SRHasInvalidIdsIconTooltip} from '../../../../components/HasInvalidIdsIconTooltip/HasInvalidIdsIconTooltip'

export function WatchlistListCardPresenter({
	progress,
	goToEdit,
	goToDetail,
	hasInvalidElements,
}: {
	progress: ProgressAggregation
	goToEdit: (id: string) => void
	goToDetail: (id: string) => void
	hasInvalidElements?: boolean
}) {
	return (
		<SRCard onClick={() => goToDetail(progress.watchlist._id)}>
			<Box direction={'row'} justify={'between'}>
				<Box direction={'column'} width={'calc(100% - 70px)'} justify={'between'} gap={'small'}>
					<SRHeading level={5}>
						<Box direction={'row'} justify={'between'}>
							<Box direction="row" gap="xxsmall" align="center" style={{wordBreak: 'break-word'}}>
								{hasInvalidElements ? <SRHasInvalidIdsIconTooltip /> : null}
								{progress.watchlist.name}
							</Box>
							<WatchlistEditBtnListCardPresenter {...{progress, goToEdit, goToDetail}} />
						</Box>
					</SRHeading>
					<Box direction={'row'} justify={'between'}>
						<WatchlistStatusPresenter {...{progress}} />
						<WatchlistDatePresenter {...{progress}} />
					</Box>
				</Box>
				<Box justify={'center'}>
					<WatchlistProgressPresenter {...{progress}} />
				</Box>
			</Box>
		</SRCard>
	)
}

export function WatchlistDatePresenter({progress}: {progress: ProgressAggregation}) {
	const startDateTransform = new Date(progress.watchlist.startDate)
	const endDateTransform = new Date(progress.watchlist.endDate)
	const startDate = format(new Date(startDateTransform), 'dd/MM/yyyy')
	const endDate = format(new Date(endDateTransform), 'dd/MM/yyyy')
	return (
		<Box alignSelf="end">
			<Text>
				{startDate} - {endDate}
			</Text>
		</Box>
	)
}

export function WatchlistStatusPresenter({progress}: {progress: ProgressAggregation}) {
	const status = watchListStatus(progress)
	return <SRChip text={status} background={watchlistStatusColor(status)} color={watchlistStatusColor(status, 'text')} />
}

export function WatchlistProgressPresenter({progress}: {progress: ProgressAggregation}) {
	return (
		<Box overflow={'hidden'} alignSelf="start">
			<ProgressDonutChart progress={progressPercentage(progress)} size={60} />
		</Box>
	)
}

export function WatchlistEditBtnListCardPresenter({
	progress,
	goToEdit,
}: {
	progress: ProgressAggregation
	goToEdit: (id: string) => void
}) {
	return (
		<Box justify={'start'}>
			<SRIconButton
				alignSelf={'end'}
				size={'small'}
				title={'Edit Work package'}
				icon={<Edit size={'14'} />}
				onClick={e => {
					e.stopPropagation()
					goToEdit(progress.watchlist._id)
				}}
			/>
		</Box>
	)
}
