import {Box, Layer, Text} from 'grommet'
import {SRHeading} from 'sr-react-commons'
import {ProjectFileUploader} from './ProjectFileUploader'
import React from 'react'
import {SRUl} from '../../../components/List/SRUl'
import {SupportLink} from './SupportLink'

export function DocumentFileUploadHelpText() {
	return (
		<Box gap="small">
			<Box>
				<SRHeading level="5">Upload documents</SRHeading>
				<Text>Please upload any supporting documentation for the survey scan files.</Text>
			</Box>
			<Box gap={'xsmall'}>
				<SRHeading level="6">Document requirements:</SRHeading>
				<SRUl>
					<li>Recommended formats: .pdf, .xls, .csv, .jpeg, .jpg, .png, .doc, .docx and .zip.</li>
				</SRUl>
				<SupportLink title={'If you have any doubts, please'} />
			</Box>
		</Box>
	)
}

export function DocumentFileUploadModal(props: {projectId: string; open: boolean; onClose: () => void}) {
	return props.open ? (
		// TODO refoctor for better code sharing
		<Layer modal animation="none">
			<Box pad="medium" width="416px" gap={'medium'}>
				<SRHeading level="4">Upload files</SRHeading>
				<ProjectFileUploader
					projectId={props.projectId}
					visibility={'document'}
					acceptedFileTypes={null}
					onCancel={props.onClose}
					onFinish={props.onClose}
					dropzoneText={
						<>
							Drop documents to upload or <br />
						</>
					}
					helpText={<DocumentFileUploadHelpText />}
				/>
			</Box>
		</Layer>
	) : null
}
