import React from 'react'
import {VictoryAxis, VictoryBar, VictoryChart, VictoryLabel} from 'victory'
import {insightDashboardTheme} from './chartTheme'
import {useFilterStateForMode} from '../../../FilterPresets/components/filters/Filters'
import {
	abbrNumber,
	FILL_OPACITY,
	HOVER_FILL_OPACITY,
	INSIGHTS_CHART_HEIGHT,
	INSIGHTS_CHART_WIDTH,
	setChartItemOpacity,
} from '../../InsightDashboard-helper'
import {SR_COLOR_ACCENT_3} from 'sr-react-commons'
import _ from 'lodash'

export interface ElementTypesChartProps {
	elementTypesData: {label: string; total: number}[]
}
export const ELEMENT_TYPE_LENGTH_LIMIT = 24

export function ElementTypeChart(props: ElementTypesChartProps): JSX.Element {
	const FILTER_MODE = 'overview'
	const {setPartialFilter} = useFilterStateForMode(FILTER_MODE)
	const data = props.elementTypesData.map(types => ({x: types.label, y: types.total}))
	const chartData = _.orderBy(_.take(data, ELEMENT_TYPE_LENGTH_LIMIT), ['y'], ['desc'])
	return (
		<svg viewBox={`15 40 410 210`}>
			<VictoryChart
				theme={insightDashboardTheme}
				standalone={false}
				height={INSIGHTS_CHART_HEIGHT}
				width={INSIGHTS_CHART_WIDTH}
				domainPadding={{x: [1, 13]}}
				events={[
					{
						childName: 'all',
						target: 'data',
						eventHandlers: {
							onClick: (event, data) => setPartialFilter({elementTypes: [data.datum.xName as string]}),
							onMouseOver: evt => {
								setChartItemOpacity(evt, HOVER_FILL_OPACITY)
								evt.currentTarget.style['fill-opacity'] = FILL_OPACITY
								evt.currentTarget.style['cursor'] = 'pointer'
							},
							onMouseOut: evt => {
								setChartItemOpacity(evt, FILL_OPACITY)
							},
						},
					},
				]}
			>
				<VictoryAxis
					style={{
						grid: {stroke: 'none'},
						tickLabels: {
							angle: -90,
							textAnchor: 'end',
						},
					}}
					tickLabelComponent={<VictoryLabel dy={2} dx={7} />}
					standalone={false}
					tickCount={20}
					tickFormat={t => _.truncate(t, {length: 19, omission: '...'})}
					crossAxis
				/>
				<VictoryAxis standalone={false} crossAxis={false} dependentAxis tickCount={5} tickFormat={t => abbrNumber(t)} />
				<VictoryBar
					style={{
						data: {fill: SR_COLOR_ACCENT_3, stroke: 'none'},
					}}
					barWidth={13}
					alignment="start"
					data={chartData}
				/>
			</VictoryChart>
		</svg>
	)
}
