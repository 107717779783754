import LogRocket from 'logrocket'
import {config} from '../../config'
import setupLogRocketReact from 'logrocket-react'

let logrocketInitialized = false

export function initializeLogRocket() {
	if (!logrocketInitialized) {
		LogRocket.init(config.sr.logRocketAppId)
		setupLogRocketReact(LogRocket)
		logrocketInitialized = true
	}
}

export function identifyUser(userName: string, userEmail: string) {
	LogRocket.identify(userEmail, {
		name: userName,
	})
}

export function shouldInitializeLogRocketAndTrackSession(userEmail: string) {
	return config.sr.activateLogRocket && !userEmail.endsWith('scaledrobotics.com')
}

export async function endCurrentSessionIfExists() {
	return new Promise<void>(resolve => {
		LogRocket.getSessionURL(sessionURL => {
			if (sessionURL) {
				LogRocket.startNewSession()
			}
			resolve()
		})
	})
}

export type TrackEventProperties = {
	revenue?: number
	[key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined
}

export function trackLogRocketEvent(eventName: string, eventProperties?: TrackEventProperties | undefined) {
	console.debug('Tracking LogRocket Event', eventName, eventProperties)
	if (logrocketInitialized) {
		LogRocket.track(eventName, eventProperties)
	}
}

export function logRocketInitialized(): boolean {
	return logrocketInitialized
}
