import React, {useRef, useState} from 'react'
import {Box, List, Text} from 'grommet'
import {AlignSelfType, normalizeColor} from 'grommet/utils'
import {Add, CaretDownFill, CaretUpFill, Checkmark} from 'grommet-icons'
import {SRButton, SRDropButton} from 'sr-react-commons'
import {IssueEntity} from '../../features/IssueManagement/issue.entities'
import styled from 'styled-components'
import {Spinner} from '../CommonsCandidate/Loading/Spinner'
import {SRDismissableTooltipRef} from '../CommonsCandidate/SRDismissableTooltip/SRDismissableTooltip'

export type AddToIssueButtonStateType = 'ready' | 'loading' | 'done' | 'error'

const MenuBox = styled(Box)`
	text-decoration: none;
	color: inherit;
	display: block;
	padding: 12px;
	&:hover {
		background-color: ${props => normalizeColor('light-2', props.theme)};
	}
`

export type AddToIssueButtonProps = {
	issues: IssueEntity[]
	onAdd: (issue: IssueEntity) => void
	onCreate: () => void
	alignSelf?: AlignSelfType
	buttonState?: AddToIssueButtonStateType
	isScreenShot?: boolean
	label?: string
}

export function AddToIssueButton({
	issues,
	onAdd,
	buttonState,
	onCreate,
	isScreenShot,
	label = 'Add to issue',
}: AddToIssueButtonProps) {
	const [open, setOpen] = useState(false)
	const disabled = buttonState === 'loading'

	const AddingSpinner = () => {
		return (
			<Box direction="row" align="center" gap="xsmall">
				<Spinner size={'xsmall'} hideIfGlobalLoader={false} color={'dark'} />
				<Text>Adding...</Text>
			</Box>
		)
	}

	const AddedCheckMark = () => {
		return (
			<Box direction="row" align="center" gap="xsmall">
				<Checkmark size="small" />
				<Text>Added</Text>
			</Box>
		)
	}

	const LABEL_BY_STATE = {
		loading: <AddingSpinner />,
		done: <AddedCheckMark />,
		error: 'Error while adding',
		ready: label,
	}
	buttonState = buttonState || 'ready'
	const displayLabel = LABEL_BY_STATE[buttonState]

	const DropContent = () => (
		<Box role="menu" aria-label="add to issue menu" background={'white'} flex={false} elevation={'large'}>
			<Box
				border={
					issues.length > 0
						? {
								color: 'light-4',
								size: 'xsmall',
								style: 'solid',
								side: 'bottom',
						  }
						: false
				}
				flex={false}
			>
				<SRButton
					pad={['5px', '11px']}
					hoverBgColor="light-2"
					onClick={() => {
						onCreate?.()
						setOpen(false)
					}}
				>
					<Box flex={false} pad={{vertical: '10px'}} direction="row" align="center" gap="xsmall">
						<Add size={'small'} />
						<Text>Create Issue</Text>
					</Box>
				</SRButton>
			</Box>
			<Box flex={false} overflow={'hidden'}>
				<List border={false} data={issues} pad="none">
					{(issue: IssueEntity) => (
						<MenuBox
							onClick={() => {
								onAdd(issue)
								setOpen(false)
							}}
						>
							{'#' + issue.issueId + ' ' + issue.diagnosis}
						</MenuBox>
					)}
				</List>
			</Box>
		</Box>
	)

	return (
		<SRDropButton
			buttonClass={SRButton}
			bgColor={'white'}
			hasBorder={isScreenShot}
			isSelected={open}
			open={open}
			dropProps={{plain: false}}
			dropAlign={{top: 'bottom', left: 'left'}}
			icon={open ? <CaretUpFill size={'small'} /> : <CaretDownFill size={'small'} />}
			reverse
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => setOpen(false)}
			dropContent={<DropContent />}
			label={displayLabel}
			disabled={disabled}
		/>
	)
}
