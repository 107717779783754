import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {UserProject} from '../../../reducers/userProfile'
import {getCurrentForgeIntegration} from '../forge-integration.api'
import {ForgeIntegrationEntity} from '../forge-integration.entities'
import {defaultQueryClient} from '../../../utilities/query-client.util'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'

export const invalidateUseCurrentForgeIntegration = (): Promise<void> => {
	return defaultQueryClient.invalidateQueries(['forge-integration', 'forge-integration.current'])
}

export function useCurrentForgeIntegration(project?: UserProject) {
	const forgeFeatureEnabled = useFeatureEnabled('forgeIntegration')
	return useQuery<ForgeIntegrationEntity | null, AxiosError>(
		['forge-integration', 'forge-integration.current', project?._id],
		() => getCurrentForgeIntegration(project!._id),
		{
			enabled: !!project && forgeFeatureEnabled,
		},
	)
}
