import SparkMD5 from 'spark-md5'

function roundToDigits(number: number, digits: number) {
	return Math.round((number + Number.EPSILON) * 10 ** digits) / 10 ** digits
}

export function prettyDisplayFileSize(size: number) {
	let [, exponentString] = size.toExponential().split('e')
	const exponent = parseInt(exponentString)
	const flooredNumber = Math.floor(exponent / 3)
	const unit =
		flooredNumber === 0
			? 'B'
			: flooredNumber === 1
			? 'KB'
			: flooredNumber === 2
			? 'MB'
			: flooredNumber === 3
			? 'GB'
			: 'TB'
	return `${roundToDigits(size / 10 ** (flooredNumber * 3), 1)} ${unit}`
}

export async function md5PartialHash(file: File): Promise<string> {
	//This function only hashes the first 100mb of an uploaded file
	const mb = 1024 ^ 2
	const scanSize = file.size > 100 * mb ? 100 * mb : file.size
	const chunkSize = 2 * mb,
		chunks = Math.ceil(scanSize / chunkSize),
		spark = new SparkMD5.ArrayBuffer(),
		fileReader = new FileReader()

	let currentChunk = 0
	return new Promise<string>((resolve, reject) => {
		fileReader.onload = function (e) {
			spark.append(e.target!.result as ArrayBuffer) // Append array buffer
			currentChunk++

			if (currentChunk < chunks) {
				loadNext()
			} else {
				resolve(spark.end())
			}
		}

		fileReader.onerror = reject

		function loadNext() {
			const start = currentChunk * chunkSize,
				end = start + chunkSize >= file.size ? file.size : start + chunkSize

			fileReader.readAsArrayBuffer(file.slice(start, end))
		}
		loadNext()
	})
}
