export type FeatureFlag<T extends string> = {
	id: T
	description: string
	defaultValue: boolean
}

export function createFeatureFlag<T extends string>(
	id: T,
	description: string = '',
	defaultValue = false,
): FeatureFlag<T> {
	return {
		id,
		description,
		defaultValue,
	}
}

export function validFeatureFlag(id: string, featureFlags: FeatureFlag<string>[]) {
	return featureFlags.some(flag => flag.id === id)
}
