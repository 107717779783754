import {useQuery} from 'react-query'
import {AnalysisRequestEntity} from './analysis-request.entities'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {fetchAnalysisRequests} from './analysis-request.api'

export const useAnalysisRequestList = (searchText?: string) => {
	const currentProject = useCurrentProject()
	return useQuery<AnalysisRequestEntity[] | undefined>(
		['analysis-request', 'analysis-request-list', currentProject?._id, searchText],
		() => currentProject && fetchAnalysisRequests(currentProject?._id, searchText),
		{enabled: !!currentProject},
	)
}
