import React, {useEffect, useRef} from 'react'
import * as d3 from 'd3'
import {watchlistClassificationColor} from '../../watchlist.utils'

export function ProgressDonutChart({progress, size}: {progress: number; size: number}) {
	const d3Container = useRef(null)

	useEffect(() => {
		const data = [progress]
		if (d3Container.current) {
			const svg = d3.select(d3Container.current)

			const backgroundArc = d3
				.arc()
				.innerRadius(21)
				.outerRadius(30)
				.startAngle(0)
				.endAngle(Math.PI * 2)

			const mainArc = d3
				.arc()
				.innerRadius(21)
				.outerRadius(30)
				.startAngle(0)
				.endAngle(((d: number) => (d / 100) * Math.PI * 2) as any)

			const charts = svg
				.selectAll('g')
				.data(data)
				.enter()
				.append('g')
				.attr('transform', () => `translate(${size / 2},${size / 2})`)

			charts
				.append('path')
				.attr('d', backgroundArc as any)
				.attr('fill', watchlistClassificationColor('No data'))

			charts
				.append('path')
				.attr('d', mainArc as any)
				.attr('fill', watchlistClassificationColor('Verified'))

			charts
				.append('text')
				.attr('text-anchor', 'middle')
				.attr('dominant-baseline', 'central')
				.text(() => `${progress}%`)
		}
	}, [progress, size])

	return <svg className="d3-component" width={size} height={size} ref={d3Container} />
}
