import {FileStatus, FileVisibility} from '../../../api/files.api'

const BASE_QUERY_KEY = ['project-files', 'project-files-by-visibility']

export function getProjectFilesByVisibilityFullQueryKey(
	projectId: string | undefined,
	fileVisibility: FileVisibility,
	searchText: string,
	statuses?: FileStatus[],
) {
	return [...getProjectFilesByVisibilityProjectQueryKey(projectId, fileVisibility), searchText, statuses]
}

export function getProjectFilesByVisibilityProjectQueryKey(
	projectId: string | undefined,
	fileVisibility: FileVisibility,
) {
	return [...BASE_QUERY_KEY, projectId, fileVisibility]
}
