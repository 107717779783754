import {AppThunk} from '../../../store/store'
import {watchlistSlice} from './watchlist.slice'
import {
	fechAllProgressMonitoringByWatchlist,
	fetchAllProgressMonitoringWithWatchlists,
} from '../api/progress-monitoring.api'
import {fetchOneWatchlist} from '../api/watchlist.api'

export const getAllProgressMonitoringWithWatchlists = (): AppThunk => async dispatch => {
	dispatch(watchlistSlice.actions.doWatchlistRemoveAll())
	dispatch(watchlistSlice.actions.doSetLoading(true))
	try {
		const {watchlists, progressMonitorings} = await fetchAllProgressMonitoringWithWatchlists()
		dispatch(watchlistSlice.actions.doWatchlistFetchAll({watchlists, progressMonitorings}))
	} catch (e) {
		console.log('Error loading work packages:', e)
		dispatch(watchlistSlice.actions.doSetError(true))
	}
	dispatch(watchlistSlice.actions.doSetLoading(false))
}

export const getCurrentProgressMonitoringAndWatchlist = (watchlistId: string): AppThunk => async dispatch => {
	dispatch(watchlistSlice.actions.doSetCurrentLoading(true))
	try {
		dispatch(
			watchlistSlice.actions.doFetchCurrent({
				progressMonitorings: await fechAllProgressMonitoringByWatchlist(watchlistId),
				watchlist: await fetchOneWatchlist(watchlistId),
			}),
		)
	} catch (e) {
		console.error('Error fetching current work package', e)
		dispatch(watchlistSlice.actions.doSetCurrentError(true))
	}
	dispatch(watchlistSlice.actions.doSetCurrentLoading(false))
}
