import {createFeatureFlag, FeatureFlagConfig, FeatureFlagReader, FeatureFlags} from 'sr-feature-flag-commons'

export const srFeatures = new FeatureFlagConfig([
	createFeatureFlag('display360Photos', undefined, true),
	createFeatureFlag('displayElementSections', undefined, true),
	createFeatureFlag('displayHeatmaps', undefined, true),
	createFeatureFlag('progressMonitoring'),
	createFeatureFlag(
		'enableUnderConstruction',
		'Under Construction is a label that should only be available for a restricted set of element type. \nIf this option is enabled, not all elements reclassified as under construction will be displayed in the client viewer, only the set of IDs specified as part of the model object.',
	),
	createFeatureFlag(
		'displayRootModelFilter',
		'Enable this flag to turn ON the root model selector in the viewer. Make sure that this project supports it.',
	),
	createFeatureFlag(
		'useSVF2ViewerOptions',
		'Usually we should use the SVF2 viewer options, even if we do not want to use SVF2, but SVF models. If models are failing to load in the viewer because of Autodesk Server Errors, we can try to switch this to false to use the SVF viewer options.',
		true,
	),
	createFeatureFlag(
		'displayMagnitudeByAxis',
		'Enable this flag to display magnitude as height and displacement deviations. The general magnitude will be the maximum deviation of height and displacement.',
	),
	createFeatureFlag('qualityReport', 'Enable this flag to enable quality pdf exporting.'),
	createFeatureFlag(
		'usePointCloudAsHeatmap',
		'Enable this flag to display the heatmap as point cloud if the analysis has the assets available.',
	),
	createFeatureFlag('manualApproval', undefined, true),
	createFeatureFlag('pmWpPDFElementTypeBreakdown'),
	createFeatureFlag('enableWorkPackageToPDFExport', undefined, true),
	createFeatureFlag('forgeIntegration', undefined, true),
	createFeatureFlag('displayQuickProperties', undefined, true),
	createFeatureFlag('analysisWizard', undefined, true),
	createFeatureFlag(
		'dynamicSections',
		'Enable this to replace the backend generated sections with dynamically generated sections on the frontend. This feature is not ready for usage yet.',
		false,
	),
	createFeatureFlag('insightsDashboard', 'Enable this to show the Insights Dashboard Button and Sidebar.', false),
	createFeatureFlag(
		'oneStepModelSetup',
		'Enables the new model setup section in which you can upload a model without manual intervention, the legacy model setup flow will be still available.',
		false,
	),
	createFeatureFlag(
		'reclassViewerManualRepaint',
		'This is a temporary hack so we can bypass slow reclassification queries, and instead click a button to refresh the viewer element painting when needed. Will be removed once a longer term solution is implemented.',
		false,
	),
	createFeatureFlag(
		'scanInputChecksDisabled',
		'Enable to select any scan when creating an analysis regardless of its status.',
		false,
	),
	createFeatureFlag('elementsOfInterest', 'Enable to display elements of interest', false),
	createFeatureFlag('labelTransfer', 'Enable the analysis label transfer functionality.', false),
	createFeatureFlag('issuesCustomFields', 'Enable the issues custom fields functionality.', false),
])

export function createSrFeaturesReader<L extends string>(layers: L[]) {
	return new FeatureFlagReader(srFeatures, layers)
}

export type SrFeatureFlag = ReturnType<typeof srFeatures.getFeatureFlags>[number]
export type SrFeaturesReader<L extends string> = FeatureFlagReader<SrFeatureFlag, L>
export type SRFeatureFlags = FeatureFlags<SrFeatureFlag>
