import {config} from '../../../config'
import {UserProject} from '../../../reducers/userProfile'
import {getHasValidAccess} from '../forge-integration.api'
import {ForgeOauthError, ForgeOauthErrorTypes} from './ForgeOauthError'

export type ForgeIntegrationScopes = ('data:read' | 'data:write' | 'user-profile:read' | 'bucket:read')[]

const DEFAULT_SCOPES: ForgeIntegrationScopes = ['data:read', 'data:write', 'user-profile:read', 'bucket:read']

export type ForgePopupMessage =
	| {
			type: 'forgeauth.success'
	  }
	| {
			type: 'forgeauth.error'
			data: string | ForgeOauthErrorTypes
			debugInfo?: string
	  }

export const forgeIntegrationEnsureUserIsAuthed = async (
	projectId: UserProject['_id'],
	scopes: ForgeIntegrationScopes = DEFAULT_SCOPES,
): Promise<void> => {
	const hasValidForgeAccess = await getHasValidAccess(projectId)
	if (!hasValidForgeAccess.hasAccess) {
		return forgeIntegrationOauth(projectId, scopes)
	}
}

const forgeIntegrationOauth = async (
	projectId: UserProject['_id'],
	scopes: ForgeIntegrationScopes = DEFAULT_SCOPES,
): Promise<void> => {
	const params = [
		'response_type=code',
		`client_id=${config.forge.integrationsClientId}`,
		`redirect_uri=${window.location.origin}/forgeauth`,
		`scope=${scopes.join(' ')}`,
		`state=${encodeURI(JSON.stringify({projectId}))}`,
	]
	const width = 400
	const height = 550
	const left = window.screen.width / 2 - width / 2
	const top = window.screen.height / 2 - height / 2
	const forgePopup = window.open(
		`https://developer.api.autodesk.com/authentication/v2/authorize?${params.join('&')}`,
		'forgePopup',
		`height=${height},width=${width},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no`,
	)
	return new Promise((resolve, reject) => {
		const onMessage = (e: WindowEventMap['message']) => {
			// eslint-disable-next-line no-restricted-globals
			if (e.origin !== window.location.origin || !`${e.data?.type}`.startsWith('forgeauth.')) return
			const message: ForgePopupMessage = e.data
			console.log('Forge popup message', JSON.stringify(e.data))
			removeListeners()
			forgePopup?.close()
			if (message.type === 'forgeauth.success') {
				resolve()
			} else {
				reject(new ForgeOauthError(message.data, message.debugInfo))
			}
		}
		window.addEventListener('message', onMessage, false)
		const closedCheckIntervalId = setInterval(() => {
			if (!forgePopup || forgePopup.closed) {
				removeListeners()
				reject(new ForgeOauthError(ForgeOauthErrorTypes.window_closed))
			}
		}, 250)
		const removeListeners = () => {
			window.removeEventListener('message', onMessage)
			clearInterval(closedCheckIntervalId)
		}
	})
}
