import React from 'react'
import {SRFileDownloadButton, SRPrimaryButton} from 'sr-react-commons'

function DownloadQualityReportPdfButton({
	fileName,
	fetchQualityReportFile,
}: {
	fileName: string
	fetchQualityReportFile: () => Promise<Blob | undefined>
}) {
	return (
		<SRFileDownloadButton
			buttonClass={SRPrimaryButton}
			label="Get PDF report"
			fileName={fileName}
			getFile={fetchQualityReportFile}
		/>
	)
}

export default React.memo(DownloadQualityReportPdfButton)
