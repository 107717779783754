import React, {ReactNode} from 'react'
import {Box} from 'grommet'
import NumberOfElements from '../DataTableContainer/NumberOfElements'
import {RightPanelHeader} from '../CommonsCandidate/Layout/RightPanel/RightPanelHeader'
import {SRRightPanelBody, SRRightPanelContainer} from 'sr-react-commons'

export function QualityLayerPresenter({
	numberOfElements,
	dataTableContainer,
}: {
	numberOfElements: number
	dataTableContainer: ReactNode
}) {
	return (
		<SRRightPanelContainer>
			<RightPanelHeader title="Quality">
				<Box alignSelf={'center'}>
					<NumberOfElements
						resourceName={'classification'}
						numberOfElements={numberOfElements}
						margin={{top: '9px', horizontal: 'xxsmall'}}
					/>
				</Box>
			</RightPanelHeader>
			<SRRightPanelBody>{dataTableContainer}</SRRightPanelBody>
		</SRRightPanelContainer>
	)
}
