import {Box, Layer} from 'grommet'
import React, {useEffect, useState} from 'react'
import {SRFileListExplorer, SRFileListExplorerProps} from '../FileListExplorer/SRFileListExplorer'
import {SRButton, SRHeading, SRIconButton, SRPrimaryButton} from 'sr-react-commons'
import {FormClose} from 'grommet-icons'
import {SRFileExplorerProps} from '../FileExplorer/SRFileExplorer'

export type SelectSRFileModalProps = {
	open: boolean
	srFiles: SRFileListExplorerProps['srFiles']
	initialSelectedIds?: SRFileListExplorerProps['selectedIds']
	title: string
	fileNameColumnTitle?: SRFileExplorerProps['fileNameColumnTitle']
	onClose: (selectedIds: SRFileListExplorerProps['selectedIds']) => void
}

export function SelectSRFileModal({
	initialSelectedIds,
	srFiles,
	open,
	title,
	onClose,
	fileNameColumnTitle,
}: SelectSRFileModalProps) {
	const [selectedIds, setSelectedIds] = useState<string[]>(!initialSelectedIds ? [] : [...initialSelectedIds])
	const toggleFileSelected = (srFileId: string) =>
		setSelectedIds(
			selectedIds.includes(srFileId) ? selectedIds.filter(id => id !== srFileId) : [...selectedIds, srFileId],
		)
	const onModalClose = () => onClose(initialSelectedIds || [])
	useEffect(() => {
		open && setSelectedIds(initialSelectedIds || [])
	}, [open, initialSelectedIds])
	return !open ? null : (
		<Layer modal position={'top'} margin={{top: '100px'}} onClickOutside={onModalClose} onEsc={onModalClose}>
			<Box pad="medium" gap="small" width={{min: '520px', max: '520px'}} height={{min: 'small'}} fill>
				<Box direction={'row'} justify={'between'}>
					<SRHeading level="4" margin="none">
						{title}
					</SRHeading>
					<SRIconButton icon={<FormClose size="large" />} onClick={onModalClose} />
				</Box>
				<Box height={'320px'} fill={'horizontal'}>
					<SRFileListExplorer {...{srFiles, selectedIds, toggleFileSelected, fileNameColumnTitle}} />
				</Box>
				<Box direction="row" justify={'between'}>
					<SRButton textColor="neutral-1" onClick={onModalClose} label="Cancel" size="large" />
					<SRPrimaryButton
						disabled={selectedIds.length === 0}
						onClick={() => onClose(selectedIds)}
						label="Select Files"
						size="large"
					/>
				</Box>
			</Box>
		</Layer>
	)
}
