import {AnalysisViewEntity, AnalysisViewEntityPopulated} from '../AnalysisView/analysis-view.entities'
import {useEffect, useState} from 'react'
import {User} from '../../types/user'
import {deepMerge} from 'grommet/utils'
import {useSelector} from 'react-redux'
import {selectorCurrentUser} from '../../selectors/current-user.selectors'

export type UserLocalState = {
	dismissedAnalysisReadyNotifications: AnalysisViewEntityPopulated['_id'][]
}

const userKey = (userId: User['_id']) => `user/${userId}/local-state`

const buildDefaultState = (): UserLocalState => ({
	dismissedAnalysisReadyNotifications: [],
})

const loadState = (userId?: User['_id']): UserLocalState =>
	deepMerge(buildDefaultState(), JSON.parse((userId && localStorage.getItem(userKey(userId))) || '{}'))

const saveState = (userId: User['_id'] | undefined, state: UserLocalState) =>
	userId && localStorage.setItem(userKey(userId), JSON.stringify(state))

export function useUserLocalState<KEY extends keyof UserLocalState>(
	key: KEY,
): [UserLocalState[KEY], (value: UserLocalState[KEY]) => void] {
	const userId = useSelector(selectorCurrentUser)?._id
	const [state, setState] = useState(loadState(userId))
	useEffect(() => {
		setState(loadState(userId))
	}, [userId])
	return [
		state[key],
		(value: UserLocalState[KEY]) => {
			const newState = {...state, [key]: value}
			saveState(userId, newState)
			setState(newState)
		},
	]
}

export const useDismissedAnalysisReadyNotifications = (): [
	UserLocalState['dismissedAnalysisReadyNotifications'],
	(analysisViewId: AnalysisViewEntity['_id']) => void,
] => {
	const [dismissed, setDismissed] = useUserLocalState('dismissedAnalysisReadyNotifications')
	return [dismissed, (analysisViewId: AnalysisViewEntity['_id']) => setDismissed([...dismissed, analysisViewId])]
}
