import {Box, Layer} from 'grommet'
import React from 'react'
import {SRUl} from '../../../components/List/SRUl'
import {SRButton, SRHeading, SRPrimaryButton} from 'sr-react-commons'
import {FileDropZone} from './FileDropZone'
import {DroppedFile} from './ProjectFileUploader'
import {FileUploadProgressTable} from './FileUploadProgressTable'
import {IssueEntity} from '../../IssueManagement/issue.entities'
import {postAssetToIssue} from '../../IssueManagement/api/issue-management.api'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {useClassificationState} from '../../../reducers/classification.slice'
import {Spinner} from '../../../components/CommonsCandidate/Loading/Spinner'
import {defaultQueryClient} from '../../../utilities/query-client.util'

const ACCEPTED_FILE_TYPES = ['.jpeg', '.jpg', '.png']

export function IssuesImageUploadHelpText() {
	return (
		<Box gap="small">
			<Box>
				<SRHeading level="5">Upload images</SRHeading>
			</Box>
			<Box gap={'xsmall'}>
				<SRHeading level="6">Image requirements:</SRHeading>
				<SRUl>
					<li> Can be .jpeg, .jpg, and .png</li>
				</SRUl>
			</Box>
		</Box>
	)
}

const dropZoneText = (
	<>
		Drop documents to upload or
		<br />
	</>
)

export function IssuesImageFileUploader(props: {
	selectedIssue: Pick<IssueEntity, '_id'>
	open: boolean
	onClose: () => void
}) {
	const currentProject = useCurrentProject()
	const {selectedAnalysisView} = useClassificationState()
	const [selectedFiles, setSelectedFiles] = React.useState<DroppedFile[]>([])
	const [uploading, setUploading] = React.useState<boolean>(false)
	const onFilesSelected = (files: File[]) =>
		setSelectedFiles(oldFiles => oldFiles.concat(files.map(file => ({file, accepted: true}))))
	const onFilesRejected = (files: File[]) =>
		setSelectedFiles(oldFiles => oldFiles.concat(files.map(file => ({file, accepted: false}))))
	const onRemove = (name: string) => setSelectedFiles(files => files.filter(f => f.file.name !== name))
	const onStartUpload = async () => {
		setUploading(true)
		const uploadPromises = selectedFiles
			.filter(fileDescriptor => fileDescriptor.accepted)
			.map(fileDescriptor => fileDescriptor.file)
			.map(async file => {
				const reader = new FileReader()
				return new Promise<IssueEntity>(resolve => {
					reader.onload = async () => {
						resolve(
							await postAssetToIssue(
								currentProject!,
								props.selectedIssue._id,
								reader.result as string,
								'attachment',
								selectedAnalysisView!._id,
							),
						)
					}
					reader.readAsDataURL(file)
				})
			})
		await Promise.all(uploadPromises)
		await defaultQueryClient.invalidateQueries(['issues', 'issue-by-id', props.selectedIssue._id])
		props.onClose()
	}

	return props.open ? (
		<IssuesImageFileUploaderPresenter
			onFilesSelected={onFilesSelected}
			onFilesRejected={onFilesRejected}
			selectedFiles={selectedFiles}
			uploading={uploading}
			onStartUpload={onStartUpload}
			onRemove={onRemove}
			onClose={props.onClose}
		/>
	) : null
}

export function IssuesImageFileUploaderPresenter(props: {
	onFilesSelected: (files: File[]) => void
	selectedFiles: DroppedFile[]
	onStartUpload: () => Promise<void>
	uploading: boolean
	onRemove: (name: string) => void
	onFilesRejected: (files: File[]) => void
	onClose: () => void
}) {
	return (
		<Layer modal animation="none">
			<Box pad="medium" width="416px" gap={'medium'}>
				<SRHeading level="4">Upload images</SRHeading>
				<Box height={'120px'}>
					<FileDropZone
						acceptedFileTypes={ACCEPTED_FILE_TYPES}
						text={dropZoneText}
						onDropAccepted={props.onFilesSelected}
						onDropRejected={props.onFilesRejected}
					/>
				</Box>
				<Box height={'320px'} overflow="auto">
					{props.selectedFiles.length > 0 ? (
						<FileUploadProgressTable
							files={props.selectedFiles}
							uploadingFiles={{}}
							onRemove={props.onRemove}
							canRemoveFiles={!props.uploading}
							onCancelUpload={() => {}}
						/>
					) : (
						<IssuesImageUploadHelpText />
					)}
				</Box>
				<Box>
					<Box direction="row" justify={'between'}>
						<SRButton disabled={props.uploading} label={'Cancel'} onClick={props.onClose} />
						<SRPrimaryButton
							disabled={!props.selectedFiles.length || props.uploading}
							size={'medium'}
							icon={props.uploading ? <Spinner size={'xsmall'} color={'darker'} /> : undefined}
							onClick={props.onStartUpload}
							label={'Send files'}
						/>
					</Box>
				</Box>
			</Box>
		</Layer>
	)
}
