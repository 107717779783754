import _ from 'lodash'
import {Id, Tenant} from '../../../entities/value-objects'
import {translateStatusFiltersWhenUnderConstructionDisabled} from '../../Classification/classification.helpers'
import {MagnitudeSign} from '../../../reducers/classification'

export type FilterPresetScope = 'user' | 'project'

export const NOT_PERISTED_ATTRS = ['ifcModels']

export type FilterProps =
	| 'statuses'
	| 'magnitude'
	| 'floors'
	| 'elementTypes'
	| 'rootModels'
	| 'elementName'
	| 'externalId'

export type FiltersEntity = {
	statuses: string[] | null
	magnitude: {
		from: number
		to: number
	} | null
	magnitudeXY: {
		from: number
		to: number
	} | null
	magnitudeZ: {
		from: number
		to: number
	} | null
	descriptions: string[] | null
	magnitudeZSign: MagnitudeSign | null
	floors: string[] | null
	elementTypes: string[] | null
	rootModels: string[] | null
	elementName: string | null
	externalId: string | null
	approved: boolean | null
	eoi: boolean | null
}

export type FilterPresetEntity = {
	_id: Id
	enabled: boolean
	createdDate: string
	createdBy: Id
	tenantId: Tenant
	name: string
	scope: FilterPresetScope
	filters: FiltersEntity
}

export type FilterPresetEntityOptionalId = Omit<FilterPresetEntity, '_id'> & {_id: string | undefined}

export const normalizeFilterPreset = (preset: Partial<FilterPresetEntity>): Partial<FilterPresetEntity> => {
	const filters = {...buildEmptyFilters(), ...preset.filters}
	return {
		...preset,
		filters: {
			...(_.omit(filters, NOT_PERISTED_ATTRS) as FiltersEntity),
			floors: filters.floors && [...filters.floors].sort(),
			elementTypes: filters.elementTypes && [...filters.elementTypes].sort(),
			statuses: filters.statuses && [...filters.statuses].sort(),
		},
	}
}

export const buildEmptyFilters = (): FiltersEntity => ({
	statuses: null,
	magnitude: null,
	magnitudeXY: null,
	magnitudeZ: null,
	magnitudeZSign: null,
	floors: null,
	elementTypes: null,
	rootModels: null,
	elementName: null,
	externalId: null,
	approved: null,
	eoi: null,
	descriptions: null,
})

export function mapDisplayedFloorsToDatabaseFloors(floors: string[], floorMapping: Record<string, string>) {
	return floors.flatMap(floor => {
		const mappedFloors = Object.entries(floorMapping).filter(([, value]) => value === floor)
		return mappedFloors.map(([key]) => key).concat([floor])
	})
}

export function mapDisplayedElementTypesToDatabaseElementTypes(
	elementTypes: string[],
	elementTypeMapping: Record<string, string>,
) {
	return elementTypes.flatMap(elementType => {
		const [ifcType, ...restTree] = elementType.split(':')
		const mappedTypes = Object.entries(elementTypeMapping).filter(([, value]) => value === ifcType)
		return mappedTypes.map(([key]) => [key, ...restTree].join(':')).concat(elementType)
	})
}

export function toFiltersDTO(
	filters: FiltersEntity,
	floorMapping: Record<string, string>,
	elementTypeMapping: Record<string, string>,
	enableUnderConstruction: boolean,
	approved: boolean | null,
): FiltersEntity {
	return {
		...filters,
		statuses:
			!enableUnderConstruction && filters.statuses
				? translateStatusFiltersWhenUnderConstructionDisabled(filters.statuses)
				: filters.statuses,
		magnitude: filters.magnitude
			? {
					from: filters.magnitude.from / 1000.0,
					to: filters.magnitude.to / 1000.0,
			  }
			: null,
		magnitudeXY: filters.magnitudeXY
			? {
					from: filters.magnitudeXY.from / 1000.0,
					to: filters.magnitudeXY.to / 1000.0,
			  }
			: null,
		magnitudeZ: filters.magnitudeZ
			? {
					from: filters.magnitudeZ.from / 1000.0,
					to: filters.magnitudeZ.to / 1000.0,
			  }
			: null,
		descriptions: filters.descriptions ? filters.descriptions : null,
		floors:
			filters.floors && filters.floors.length > 0
				? mapDisplayedFloorsToDatabaseFloors(filters.floors, floorMapping)
				: null,
		elementTypes:
			filters.elementTypes && filters.elementTypes.length > 0
				? mapDisplayedElementTypesToDatabaseElementTypes(filters.elementTypes, elementTypeMapping)
				: null,
		approved,
	}
}
