// @ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import React, {ReactNode} from 'react'
import {Spinner, SpinnerProps} from './Spinner'

type SRLoadingOverlayProps = {
	active: boolean
	children: ReactNode
	text?: string
	spinnerProps?: Omit<SpinnerProps, 'text'>
}

export const SRLoadingOverlay = ({text, spinnerProps, ...props}: SRLoadingOverlayProps) => (
	<LoadingOverlay
		spinner={<Spinner hideIfGlobalLoader={false} color={'light'} text={text} {...spinnerProps} />}
		{...props}
	/>
)
