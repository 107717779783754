import {Id, Tenant} from '../../../entities/value-objects'
import {WatchlistElementsFilter} from '../slices/watchlist-filter-elements.slice'
import {UserProject} from '../../../reducers/userProfile'
import {
	mapDisplayedElementTypesToDatabaseElementTypes,
	mapDisplayedFloorsToDatabaseFloors,
} from '../../FilterPresets/entities/filter-preset.entities'
import {Opaque} from '../../../types/base.types'

export type WatchlistModelValid = {
	model: string
	allExternalIdsAreInModel: boolean
}

export type WatchlistFilter = WatchlistElementsFilter

export type WatchlistNoDataSource = {
	mode: null
	filter: null
	file: null
}

export type WatchlistCsvSource = {
	mode: 'csv'
	filter: null
	file: ExtendedFileReference
}

export type WatchlistFilterSource = {
	mode: 'filter'
	filter: WatchlistFilter
	file: null
}

export type WatchlistDataSource = WatchlistNoDataSource | WatchlistCsvSource | WatchlistFilterSource

export type ExtendedFileReference = {
	filename: string
}

export type PrimaveraActivityId = Opaque<'PrimaveraActivityId', string>
export type PrimaveraWbs = Opaque<'PrimaveraWbs', string>

export type Primavera = {
	activityId: PrimaveraActivityId | null
	wbs: PrimaveraWbs | null
}

export type WatchlistEntity = {
	_id: Id
	tenantId: Tenant
	name: string
	description?: string
	source: WatchlistDataSource
	externalIds: string[]
	modelValid: WatchlistModelValid[]
	startDate: string
	endDate: string
	createdBy: Id
	createdDate: string
	enabled?: boolean
	primavera: Primavera
}

export function watchlistElementsFilterToDTO(
	filter: WatchlistElementsFilter,
	selectedProject: UserProject,
): WatchlistElementsFilter {
	return {
		floors: filter.floors ? mapDisplayedFloorsToDatabaseFloors(filter.floors, selectedProject.floorMapping) : null,
		elementTypes: filter.elementTypes
			? mapDisplayedElementTypesToDatabaseElementTypes(filter.elementTypes, selectedProject.elementTypeMapping)
			: null,
		rootModels: filter.rootModels,
	}
}
