import {Accordion, AccordionPanel, Anchor, Box, Image, Layer, Text} from 'grommet'
import {IssueAssetBox} from './IssueAssetBox'
import React, {useCallback, useState} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {deleteIssueAsset} from '../api/issue-management.api'
import {ViewAssetModal} from './ViewAssetModal'
import {ConfirmAssetDeleteModal} from './ConfirmAssetDeleteModal'
import {CircleInformation, Gallery, Upload} from 'grommet-icons'
import {useQueryClient} from 'react-query'
import {useGlobalNotifications} from '../../GlobalNotifications'
import {SRHeading, SRIconButton, SRPrimaryButton, SRSecondaryButton} from 'sr-react-commons'
import {Asset, IssueEntity} from '../issue.entities'
import {IssuesImageFileUploader} from '../../FileUpload/components/IssuesImageFileUploader'
import {CardContainer} from '../../../components/CommonsCandidate/CardContainer/CardContainer'

type IssueAssetsBoxProps = {
	issue: IssueEntity
}

export function IssueAssetsBox({issue}: IssueAssetsBoxProps) {
	const history = useHistory()
	const params = useParams<{
		assetId: string
	}>()
	const initAssetToOpen = issue.assets.find((asset: Asset) => asset._id === params.assetId) as Asset

	const [asset, setAsset] = useState<Asset | undefined>(initAssetToOpen)
	const [deleteId, setDeleteId] = useState<string>()
	const [showAddAssetHelpModal, setShowAddAssetHelpModal] = useState(false)
	const [showImageFileUploaderModal, setImageFileUploaderModal] = useState(false)
	const queryClient = useQueryClient()
	const {notifyAlert} = useGlobalNotifications()
	const startDeleteAsset = useCallback((assetId: string) => setDeleteId(assetId), [setDeleteId])

	const onAssetClick = useCallback(
		asset => {
			history.push({
				pathname: `./asset/${asset._id}`,
			})
			setAsset(asset)
		},
		[setAsset],
	)
	const deleteAsset = () => {
		if (deleteId) {
			deleteIssueAsset(issue._id, deleteId)
				.then(() => queryClient.invalidateQueries(['issues']))
				.catch(err => {
					console.error('Error in deleteIssueAsset', err)
					notifyAlert("We couldn't remove the asset, please retry.")
				})
				.finally(() => setDeleteId(undefined))
		}
	}
	const closeAddAssetHelpModal = () => setShowAddAssetHelpModal(false)

	const assetLeaveHandle = () => {
		const backRoute = history.location.pathname.split('asset')[0]
		history.push(backRoute)
		setAsset(undefined)
	}

	return (
		<CardContainer>
			<Box direction={'row'} justify={'between'}>
				<SRHeading level={4}>Attachments</SRHeading>
				<Box direction={'row'}>
					<SRIconButton icon={<Upload size={'large'} />} onClick={() => setImageFileUploaderModal(true)} />
					<SRIconButton icon={<CircleInformation size={'large'} />} onClick={() => setShowAddAssetHelpModal(true)} />
				</Box>
			</Box>
			{showAddAssetHelpModal && (
				<Layer
					position={'top'}
					margin={{top: '50px', bottom: '50px'}}
					onEsc={closeAddAssetHelpModal}
					onClickOutside={closeAddAssetHelpModal}
				>
					<Box pad={{vertical: 'small'}} width={'xlarge'}>
						<Box flex={false} pad={{horizontal: 'medium'}}>
							<SRHeading level={2}>Adding attachments to an issue</SRHeading>
						</Box>
						<Box overflow={{vertical: 'auto', horizontal: 'hidden'}} pad={{horizontal: 'medium'}}>
							You can add attachments to an issue in order to share the information you found in viewer with your team
							members.
							<br />
							Currently, the attachments you can add to an issue are:
							<ul>
								<li>360° photos</li>
								<li>Element section drawings</li>
								<li>Screenshots of the viewer</li>
							</ul>
							<SRHeading level={3}>Step by Step Tutorials</SRHeading>
							<Accordion multiple={true}>
								<AccordionPanel label="Adding 360º photos and sections">
									<Box pad={'xxsmall'}>
										<ol>
											<li>Select an element of the issue in the viewer.</li>
											<li>
												Open the asset window (360º images or sections) by clicking on the icon in the toolbar on the
												left.
											</li>
											<li>Explore the element’s assets using the arrow buttons.</li>
											<li>
												At the upper left corner of the image or section, you will see the “Add to issue“ selector.
											</li>
											<li>
												If the element is not associated with any issue, create a new one by clicking on “+ Create
												Issue“.
											</li>
											<li>
												Select an issue from the list to add the image or section as an attachment.
												<br />
												<Box margin={{vertical: 'small'}} pad={'xsmall'} alignSelf={'start'} elevation={'small'}>
													<Image fill src={'/images/tutorial/attachment_360.png'} alt="Explanatory screenshot" />
												</Box>
											</li>
										</ol>
									</Box>
								</AccordionPanel>
								<AccordionPanel label="Adding a screen capture">
									<Box pad={'xxsmall'}>
										<ol>
											<li>Open the viewer screen capture window by clicking the icon in the toolbar on the left.</li>
											<li>By clicking "Capture current viewer" you can take a screen capture of the 3D model.</li>
											<li>
												At the bottom right corner of the screen capture window, you will see the “Add to issue“
												selector.
											</li>
											<li>
												If the element is not associated with any issue, create a new one by clicking on “+ Create
												Issue“
											</li>
											<li>
												Select an issue from the list to add the screen capture as an attachment.
												<br />
												<Box margin={{vertical: 'small'}} pad={'xsmall'} alignSelf={'start'} elevation={'small'}>
													<Image fill src={'/images/tutorial/attachment_viewer.png'} alt="Explanatory screenshot" />
												</Box>
											</li>
										</ol>
									</Box>
								</AccordionPanel>
							</Accordion>
						</Box>
						<Box margin={{top: 'small'}} flex={false}>
							<SRPrimaryButton alignSelf={'center'} label={'Close'} onClick={closeAddAssetHelpModal} size={'large'} />
						</Box>
					</Box>
				</Layer>
			)}
			{showImageFileUploaderModal && (
				<IssuesImageFileUploader
					selectedIssue={issue}
					open={showImageFileUploaderModal}
					onClose={() => setImageFileUploaderModal(false)}
				/>
			)}
			{deleteId && <ConfirmAssetDeleteModal onDelete={deleteAsset} onCancel={() => setDeleteId(undefined)} />}
			{asset && <ViewAssetModal asset={asset} onLeave={assetLeaveHandle} />}
			{issue.assets && issue.assets.length > 0 ? (
				<Box gap={'small'}>
					{issue.assets.map(asset => (
						<Box key={asset._id}>
							<IssueAssetBox onAssetClick={onAssetClick} asset={asset} onDelete={startDeleteAsset} />
						</Box>
					))}
				</Box>
			) : (
				<Box align={'center'} gap={'small'}>
					<Gallery size={'large'} />
					<Box align={'center'}>
						<Text textAlign="center">Add 360° photos, sections or captures of the viewer.</Text>
						<Text textAlign="center">You can also upload images from you device.</Text>
					</Box>
					<Anchor label={'How to add attachments?'} onClick={() => setShowAddAssetHelpModal(true)} />
					<SRSecondaryButton
						alignSelf={'center'}
						label={'Upload images'}
						onClick={() => setImageFileUploaderModal(true)}
						size={'large'}
					/>
				</Box>
			)}
		</CardContainer>
	)
}
