import {UserProject} from '../../../../../reducers/userProfile'
import {useQuery, UseQueryOptions} from 'react-query'
import {AxiosError} from 'axios'
import {useGlobalNotifications} from '../../../../GlobalNotifications'
import {fetchElementFloorsByModel, fetchElementFloorsFromLatestModels} from '../../../../../api/elements.api.v3'

const floorLabel = (value: string, floorMapping?: {[name: string]: string}) => (floorMapping || {})[value] || value

export const useElementFloorsOptions = (
	selectedProject: UserProject | undefined,
	fromLatestModel: boolean = false,
	modelId?: string,
	options?: UseQueryOptions<{value: string; label: string}[], AxiosError>,
) => {
	const {notifyError} = useGlobalNotifications()
	return useQuery<{value: string; label: string}[], AxiosError>(
		[selectedProject!._id, 'floors', fromLatestModel, modelId],
		() =>
			(fromLatestModel ? fetchElementFloorsFromLatestModels() : fetchElementFloorsByModel(modelId!))
				.then(floors => {
					return ['Unknown', ...floors].map(value => {
						return {value, label: floorLabel(value, selectedProject?.floorMapping)}
					})
				})
				.catch(e => {
					notifyError(e.message)
					return []
				}),
		{staleTime: 60 * 60 * 1000, ...options, enabled: !!selectedProject},
	)
}
