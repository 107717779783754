import {WatchlistEntity} from '../entities/watchlist.entities'
import {ProgressMonitoringEntity} from '../entities/progress-monitoring.entities'

export type ProgressAggregation = {
	watchlist: WatchlistEntity
	progresses: ProgressMonitoringEntity[]
}

export function firstProgressMonitoring(
	progress: ProgressAggregation,
): Pick<ProgressMonitoringEntity, 'total' | 'progress' | 'deviated' | 'verified' | 'under_construction' | 'missing'> {
	return (
		progress.progresses[0] || {
			total: progress.watchlist.externalIds.length,
			progress: 0,
			deviated: 0,
			verified: 0,
			under_construction: 0,
			missing: 0,
		}
	)
}

export function progressQuantity(progress: ProgressAggregation): number {
	const progressMonitoring = firstProgressMonitoring(progress)
	return (progressMonitoring && progressMonitoring.progress) || 0
}

export function totalProgressItems(progress: ProgressAggregation): number {
	return firstProgressMonitoring(progress).total
}

export function progressPercentage(progress: ProgressAggregation): number {
	const total = totalProgressItems(progress)
	return total === 0 ? 0 : Math.round((100 * progressQuantity(progress)) / total)
}
