import {Box, Text} from 'grommet'
import React from 'react'
import {SRMultiSelect} from 'sr-react-commons'

export function StatusFilter({
	filters,
	setFilter,
	enableUnderConstruction,
}: {
	filters: {statuses: string[] | null}
	setFilter: (value: string[] | null) => void
	enableUnderConstruction?: boolean
}) {
	return (
		<Box>
			<Text weight="bold" size="small">
				Status
			</Text>
			<SRMultiSelect
				options={
					enableUnderConstruction
						? [
								{label: 'Verified', value: 'verified'},
								{label: 'Deviated', value: 'deviated'},
								{label: 'Under construction', value: 'under_construction'},
								{label: 'Missing', value: 'missing'},
								{label: 'No data', value: 'no_data'},
						  ]
						: [
								{label: 'Verified', value: 'verified'},
								{label: 'Deviated', value: 'deviated'},
								{label: 'Missing', value: 'missing'},
								{label: 'No data', value: 'no_data'},
						  ]
				}
				placeholder="All status displayed"
				size="medium"
				onChange={setFilter}
				value={filters.statuses || []}
			/>
		</Box>
	)
}
