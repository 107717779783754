import React, {useMemo, useRef, useState} from 'react'
import {Box} from 'grommet'
import {CheckBoxListItem} from './CheckBoxListItem'
import {SRSearchInput, SRToggleAllButtons} from 'sr-react-commons'
import _ from 'lodash'
import {useVirtual} from 'react-virtual'

const TREE_SELECT_LIST_ITEM_HEIGHT = 30
const TREE_SELECT_INDENTATION = 20

function getTreeSelectListItemHeight() {
	return TREE_SELECT_LIST_ITEM_HEIGHT
}

export const isElementNodeChecked = (selectedKeys: string[], id: string) =>
	!!selectedKeys.find(checkedId => checkedId === id || id.startsWith(`${checkedId}:`))

function getChildren(parent: string | null, childIndex: Map<string | null, string[]>): string[] {
	return childIndex.get(parent) || []
}

function keyLevel(key: string) {
	return key.split(':').length
}

function getParent(key: string) {
	return key.indexOf(':') !== -1 ? key.substring(0, key.lastIndexOf(':')) : undefined
}

function getParents(key: string): string[] {
	const parent = getParent(key)
	return parent ? [parent, ...getParents(parent)] : []
}

function isChildOf(possibleChild: string, possibleParent: string) {
	const childLabels = possibleChild.split(':')
	const parentLabels = possibleParent.split(':')
	return childLabels.length > parentLabels.length && parentLabels.every((child, i) => childLabels[i] === child)
}

function buildIndex(treeKeys: string[]) {
	const index: Map<string | null, string[]> = new Map()
	treeKeys.forEach(key => {
		const parents = [key].concat(getParents(key))
		for (let i = 0; i < parents.length; i++) {
			const parent = parents[i + 1] || null
			const child = parents[i]
			if (index.has(parent)) {
				const children = index.get(parent)!
				if (!children.includes(child)) {
					children.push(child)
				}
			} else {
				index.set(parent, [child])
			}
		}
	})
	return index
}

export function getLabel(key: string): string {
	return _.last(key.split(':'))!
}

function getUpdatedSelection(
	selectedKeys: string[],
	toggledKey: string,
	isNodeChecked: (key: string) => boolean,
	getNodeChildren: (parent: string | null) => string[],
	getNodeSiblings: (key: string) => string[],
) {
	if (!selectedKeys) {
		return [toggledKey]
	} else {
		const checked = isNodeChecked(toggledKey)
		if (!checked) {
			const parents = getParents(toggledKey)
			let possibleNewSelected = selectedKeys.concat(toggledKey).filter(selected => !isChildOf(selected, toggledKey))
			parents.forEach(parent => {
				const children = getNodeChildren(parent)
				if (children.every(child => isElementNodeChecked(possibleNewSelected, child))) {
					possibleNewSelected = possibleNewSelected.filter(key => !children.includes(key)).concat([parent])
				}
			})
			return possibleNewSelected
		} else {
			const parents = getParents(toggledKey)
			const selectedParent = selectedKeys.find(id => parents.includes(id))
			const selectedParents = selectedParent
				? parents.filter(parent => keyLevel(parent) > keyLevel(selectedParent)).concat([toggledKey])
				: []
			return selectedKeys
				.filter(selected => toggledKey !== selected)
				.filter(key => key !== selectedParent)
				.concat(selectedParents.flatMap(selectedParent => getNodeSiblings(selectedParent)))
		}
	}
}

/**
 *
 * @param treeKeys This is a list of the keys of all tree leaves. A key is a string in which the tree hierarchy is defined
 * by a list of ":" seperated node keys. For example, "Root1:Sub1:Leaf1" would describe a tree leaf Leaf1, which has the ancestors Sub1 and Root1.
 * This list is used to create a childIndex, which is used to build the tree recursively.
 * @param selectedKeys This is the list of selected nodes. If it for example contains "Root1:Sub1", and the tree has two leaves "Root1:Sub1:Leaf1", "Root1:Sub1:Leaf2", both of them would be checked.
 * @param onChange Whenever a node is toggled, it will recalculate the minimal list of selected nodes and call the passed callback with the new list of selected nodes.
 */
export function TreeSelect({
	treeKeys,
	selectedKeys,
	onChange,
}: {
	treeKeys: string[]
	selectedKeys: string[]
	onChange: (newSelected: string[]) => void
}) {
	const [expandedNodes, setExpandedNodes] = useState<string[]>([])
	const [searchValue, setSearchValue] = useState<string>('')
	const displayedKeys = useMemo(() => {
		if (searchValue === '') {
			return undefined
		}
		const regex = new RegExp(searchValue, 'i')
		const filteredKeys = treeKeys.filter(key => regex.test(key))
		return new Set(
			filteredKeys.flatMap(key => {
				const treeKeys = [key].concat(getParents(key))
				return treeKeys.slice(treeKeys.findIndex(key => regex.test(key)))
			}),
		)
	}, [treeKeys, searchValue])
	const childIndex = useMemo(() => {
		return buildIndex(_.sortBy(treeKeys))
	}, [treeKeys])
	const isNodeChecked = (key: string) => isElementNodeChecked(selectedKeys, key)
	const isExpanded = (key: string) => expandedNodes.includes(key)
	const isDisplayed = React.useCallback((key: string) => (displayedKeys ? displayedKeys.has(key) : true), [
		displayedKeys,
	])
	const isFound = (key: string) => (searchValue === '' ? false : new RegExp(searchValue, 'i').test(getLabel(key)))
	const getNodeChildren = React.useCallback(
		(parent: string | null) => {
			return getChildren(parent, childIndex)
		},
		[childIndex],
	)
	const setExpanded = (key: string) =>
		setExpandedNodes(nodes => (isExpanded(key) ? nodes.filter(node => node !== key) : nodes.concat([key])))
	const getNewSelectedElements = (toggledKey: string) => {
		const getNodeSiblings = (key: string) => {
			const parent = getParent(key)
			return parent ? getNodeChildren(parent).filter(child => child !== key) : []
		}
		return getUpdatedSelection(selectedKeys, toggledKey, isNodeChecked, getNodeChildren, getNodeSiblings)
	}

	const setChecked = (key: string) => onChange(getNewSelectedElements(key).sort())

	const onSearch = (event: {target: {value: string}}) => {
		const searchValue = event.target.value
		setSearchValue(searchValue)
		if (searchValue === undefined || searchValue === '') {
			return undefined
		}
		const regex = new RegExp(searchValue, 'i')
		const filteredKeys = treeKeys.filter(key => regex.test(key))
		const keysToDisplay = _.uniq(
			filteredKeys.flatMap(key => {
				const treeKeys = [key].concat(getParents(key))
				const treeLabels = key.split(':').reverse()
				return treeKeys.slice(treeLabels.findIndex(key => regex.test(key)) + 1)
			}),
		)
		setExpandedNodes(keysToDisplay)
	}
	const nodesToBeRendered: string[] = useMemo(() => {
		const getDisplayedNodes = (array: string[], nodes: string[]) =>
			nodes.forEach(node => {
				if (isDisplayed(node)) {
					array.push(node)
					getDisplayedNodes(array, (expandedNodes.includes(node) && getNodeChildren(node)) || [])
				}
			})
		const displayedNodes: string[] = []
		getDisplayedNodes(displayedNodes, getNodeChildren(null))
		return displayedNodes
	}, [expandedNodes, getNodeChildren, isDisplayed])

	const parentRef = useRef(null)

	const virtual = useVirtual({
		size: nodesToBeRendered.length,
		parentRef,
		estimateSize: getTreeSelectListItemHeight,
	})

	return (
		<Box>
			<Box flex={false}>
				<SRSearchInput value={searchValue || ''} onChange={onSearch} />
				<SRToggleAllButtons onAll={() => onChange(getNodeChildren(null))} onNone={() => onChange([])} />
			</Box>
			<Box pad={{vertical: 'xsmall'}}>
				{!nodesToBeRendered.length ? (
					<Box pad={{horizontal: 'small', vertical: 'xsmall'}}>No element types found</Box>
				) : (
					<Box
						pad={{horizontal: 'xxsmall'}}
						style={{display: 'block'}} // this is important so that react-virtual works
						overflow={{vertical: 'auto', horizontal: 'hidden'}}
						ref={parentRef}
					>
						<div
							data-testid="tree-select-container"
							style={{
								height: `${virtual.totalSize}px`,
								width: '100%',
								position: 'relative',
								overflowX: 'hidden',
							}}
						>
							{virtual.virtualItems.map(virtualRow => {
								const nodeKey = nodesToBeRendered[virtualRow.index]
								const marginLeft = (keyLevel(nodeKey) - 1) * TREE_SELECT_INDENTATION
								return (
									<div
										key={virtualRow.index}
										style={{
											position: 'absolute',
											top: 0,
											left: `${marginLeft}px`,
											width: `calc(100% - ${marginLeft}px)`,
											height: `${virtualRow.size}px`,
											transform: `translateY(${virtualRow.start}px)`,
										}}
									>
										<CheckBoxListItem
											label={getLabel(nodeKey)}
											expanded={expandedNodes.includes(nodeKey)}
											checked={isNodeChecked(nodeKey)}
											onChecked={() => setChecked(nodeKey)}
											onExpand={() => setExpanded(nodeKey)}
											boldLabel={isFound(nodeKey)}
											isLeaf={getNodeChildren(nodeKey).length === 0}
										/>
									</div>
								)
							})}
						</div>
					</Box>
				)}
			</Box>
		</Box>
	)
}
