import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import './index.scss'
import {PersistGate} from 'redux-persist/integration/react'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import {CaptureConsole} from '@sentry/integrations'
import {Integrations} from '@sentry/tracing'
import {config} from './config'
import {initStore} from './store'
import {AppWithAuth} from './components/AppWithAuth/AppWithAuth'
import {history} from './router/history'
import {CustomWindow} from './custom.window'
import posthog from 'posthog-js'

const {store, persistor} = initStore()

declare let window: CustomWindow

// Expose store on Window when testing e2e
if (window.Cypress) {
	window.store = store
}

if (config.sr.activatePosthog) {
	posthog.init(config.sr.posthogId, {api_host: 'https://eu.posthog.com'})
}

if (config.sr.activateSentry) {
	Sentry.init({
		dsn: config.sr.sentryDSN,
		integrations: [
			new CaptureConsole({levels: ['error']}),
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
				tracingOrigins: [config.sr.backendUrl],
			}),
		],
		tracesSampleRate: config.env === 'prod' ? 0.5 : 1.0,
		environment: config.env,
		beforeSend: config.sr.activateLogRocket
			? function (event, hint) {
					const logRocketSession = LogRocket.sessionURL
					if (logRocketSession !== null && event.extra) {
						event.extra['LogRocket'] = logRocketSession
						if (hint?.originalException && typeof hint.originalException === 'object') {
							LogRocket.captureException(hint.originalException as Error)
						}
					}
					return event
			  }
			: undefined,
	})
}

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			{/* <React.StrictMode> */}
			<AppWithAuth />
			{/* </React.StrictMode> */}
		</PersistGate>
	</Provider>,
	document.getElementById('root'),
)
