import React, {FunctionComponent, ReactNode} from 'react'
import {Box} from 'grommet'
import {FormNextLink} from 'grommet-icons'
import {SRButtonProps, SRPlainButton, SRPrimaryButton} from 'sr-react-commons'
import {ManagementDashboardAnalysisRequestSizedContentBox} from './ManagementDashboardAnalysisRequestSizedContentBox'

export const ManagementDashboardAnalysisRequestFormBox = ({
	children,
	confirmEnabled = true,
	confirmText,
	onCancel,
	onConfirm,
	confirmIcon = <FormNextLink size="small" />,
	confirmClass: ConfirmBTNClass = SRPrimaryButton,
}: {
	children: ReactNode
	onCancel?: () => void
	onConfirm: () => void
	confirmText: string
	confirmEnabled?: boolean
	confirmIcon?: SRButtonProps['icon'] | null
	confirmClass?: FunctionComponent<SRButtonProps>
}) => {
	return (
		<ManagementDashboardAnalysisRequestSizedContentBox>
			{children}
			<Box direction="row" justify="between" align={'end'}>
				{!onCancel ? <Box /> : <SRPlainButton size="large" label="Cancel" onClick={onCancel} />}
				<ConfirmBTNClass
					size="large"
					label={confirmText}
					reverse={!!confirmIcon}
					icon={confirmIcon || undefined}
					onClick={onConfirm}
					disabled={!confirmEnabled}
				/>
			</Box>
		</ManagementDashboardAnalysisRequestSizedContentBox>
	)
}
