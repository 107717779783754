import {LoadingWrapper} from '../../../components/CommonsCandidate/Loading/LoadingWrapper'
import {SRCard, SRIconButton, SROverline} from 'sr-react-commons'
import {Box} from 'grommet'
import {RotateLeft} from 'grommet-icons'
import React from 'react'
import {MagnitudeDistributionChart} from './charts/MagnitudeDistributionChart'
import {useQuery} from 'react-query'
import {CLASSIFICATIONS_BASE_QUERY_KEY} from '../../Classification/data-management'
import {fetchMagnitudesForInsights} from '../api/insight-dashboard.api'
import {FiltersEntity, toFiltersDTO} from '../../FilterPresets/entities/filter-preset.entities'
import {useSelector} from 'react-redux'
import {selectorClassificationState} from '../../../selectors/classification.selectors'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'
import {RootState} from '../../../reducers'
import {useCurrentProject} from '../../../hooks/useCurrentProject'
import {useFilterStateForMode} from '../../FilterPresets/components/filters/Filters'
import useDebounce from '../../../components/DataTableContainer/useDebounce'
import {config} from '../../../config'
import _ from 'lodash'

const isMagnitudeFiltered = (filter: FiltersEntity) => {
	return !filter.magnitude
}
export function MagnitudeDistributionBody({isClassifications}: {isClassifications: boolean}) {
	const CARD_HEIGHT = '265px'
	const {selectedAnalysisView, analysisVersionMap} = useSelector(selectorClassificationState)
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const {approvedFilter} = useSelector((state: RootState) => state.viewerState)
	const selectedProject = useCurrentProject()!
	const FILTER_MODE = 'overview'
	const {filter, setPartialFilter} = useFilterStateForMode(FILTER_MODE)
	const canResetMagnitude = isMagnitudeFiltered(filter as FiltersEntity)
	const resetMagnitudeFilter = () => {
		setPartialFilter({magnitude: null})
	}

	const {data: magnitudes, isLoading: magnitudesLoading, isError: isMagnitudeError} = useQuery(
		[
			CLASSIFICATIONS_BASE_QUERY_KEY,
			'for-insights-magnitudes',
			{
				selectedAnalysisView,
				analysisVersionMap,
				enableUnderConstruction,
				filter,
			},
		],
		async () => {
			return fetchMagnitudesForInsights(
				selectedAnalysisView!._id,
				analysisVersionMap,
				toFiltersDTO(
					filter as FiltersEntity,
					selectedProject.floorMapping,
					selectedProject.elementTypeMapping,
					enableUnderConstruction,
					approvedFilter,
				),
				selectedProject._id,
			)
		},
		{enabled: !isClassifications},
	)

	const debouncedLoading = useDebounce(magnitudesLoading, config.sr.debouncingTime)
	const magnitudesMM = magnitudes?.map(magnitude => magnitude * 1000) || []
	const maxDomain = Math.ceil(Math.max(...magnitudesMM))
	const binSize = maxDomain > 150 ? _.round(_.mean(magnitudesMM)) : 10
	return (
		<SRCard height={CARD_HEIGHT} gap={'none'} pad={'7px'}>
			<LoadingWrapper
				isLoading={debouncedLoading}
				errorMsg="There was an error loading the insights magnitude chart"
				isError={isMagnitudeError}
				fill
			>
				<Box direction="row" justify="between">
					<Box alignSelf="end">
						<SROverline label={'MAGNITUDE DISTRIBUTION'} />
					</Box>
					<SRIconButton
						icon={<RotateLeft size="small" />}
						onClick={resetMagnitudeFilter}
						disabled={canResetMagnitude}
					/>
				</Box>
				<MagnitudeDistributionChart magnitudes={magnitudesMM} binSize={binSize} maxDomain={maxDomain} />
			</LoadingWrapper>
		</SRCard>
	)
}
