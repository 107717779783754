import {Box, Text} from 'grommet'
import {SRHeading, SRPrimaryButton} from 'sr-react-commons'
import {history} from '../../../router/history'
import {LinkPrevious} from 'grommet-icons'
import React, {ReactNode} from 'react'

export type GoBackToSelectProjectComponentProps = {
	title: string
	reason: string
}

export type GoBackToSelectProjectWrapperProps = GoBackToSelectProjectComponentProps & {
	children: ReactNode
	show: boolean
}

export const GoBackToSelectProjectComponent = ({title, reason}: GoBackToSelectProjectComponentProps) => (
	<>
		<Box align="center" pad="medium" gap="small">
			<SRHeading level={3}>{title}</SRHeading>
			<Text> We're sorry, {reason}.</Text>
			<Text> Please go back to your projects. </Text>
		</Box>
		<Box align="center" pad="medium" gap="small">
			<SRPrimaryButton
				size={'large'}
				alignSelf={'center'}
				onClick={() => history.push('/select-project')}
				label={'Back to my projects'}
				icon={<LinkPrevious size={'medium'} />}
			/>
		</Box>
	</>
)

export const GoBackToSelectProjectWrapper = ({title, reason, children, show}: GoBackToSelectProjectWrapperProps) => (
	<>{!show ? children : <GoBackToSelectProjectComponent title={title} reason={reason} />}</>
)
