import {Section} from '../../../../reducers/classification'
import {Box, Text} from 'grommet'
import {SRCarrouselArrows} from 'sr-react-commons'
import {format} from 'date-fns'
import React from 'react'
import {useLastSelectedClassification} from '../../../../features/Classification/data-management/useSelectedClassification'
import {useSectionState} from '../StateManagement/section.slice'
import {ClassificationEntity} from '../../../../features/Classification/classification.entities'

const HEADER_EXTREME_ITEMS_WIDTH = '100px'

export function ElementSectionControlsWrapper() {
	const classification = useLastSelectedClassification()
	const {currentSection, currentSectionIndex, prevSection, nextSection} = useSectionState()
	return classification && currentSection ? (
		<ElementSectionControls
			currentSection={currentSection}
			classification={classification}
			index={currentSectionIndex}
			sections={classification.sections}
			prev={prevSection}
			next={nextSection}
		/>
	) : null
}

function ElementSectionControls(props: {
	currentSection: Section
	classification: ClassificationEntity
	index: number
	sections: Section[]
	prev: () => void
	next: () => void
}) {
	const currentSectionName =
		props.currentSection.name === 'WIP' ? `Section ${String.fromCharCode(65 + props.index)}` : props.currentSection.name
	return (
		<>
			{props.currentSection && props.classification && (
				<Box direction={'row'} gap={'xsmall'} align={'center'}>
					<Text color="white">{currentSectionName.toUpperCase()}</Text>
					<Text
						color="white"
						title={format(new Date(props.classification.scannedDate), 'yyyy-MM-dd HH:mmaaa')}
						alignSelf={'center'}
					>
						Scan date: {format(new Date(props.classification.scannedDate), 'yyyy-MM-dd')}
					</Text>
					<SRCarrouselArrows
						index={props.index}
						total={props.sections.length}
						prev={props.prev}
						next={props.next}
						width={HEADER_EXTREME_ITEMS_WIDTH}
					/>
				</Box>
			)}
		</>
	)
}
