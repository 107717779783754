import React from 'react'
import {
	Accordion,
	AccordionPanel,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
	Text,
	ThemeContext,
	ThemeType,
} from 'grommet'
import {Alert} from 'grommet-icons'
import {SR_COLOR_BRAND_MAIN, SR_COLOR_STATUS_WARNING_BACKGROUND, SRChip} from 'sr-react-commons'
import styled from 'styled-components'
import {TradeAndStatus} from '../analysis-view.helpers'

const inProgressTheme: ThemeType = {
	accordion: {
		panel: {border: undefined},
		border: undefined,
	},
	table: {
		body: {
			align: 'left',
			border: undefined,
			pad: 'none',
		},
		header: {
			align: 'left',
			border: undefined,
			pad: 'none',
		},
	},
}

const InProgressAccordion = styled(Accordion)`
	background: ${SR_COLOR_STATUS_WARNING_BACKGROUND};
	border-radius: 5px;
	border: solid 1px ${SR_COLOR_BRAND_MAIN};
`

const ProgressTableHeader = styled(TableHeader)`
	box-shadow: inset 0 -1px 0 0 rgba(167, 167, 167, 0.2);
`

const accordionPanelLabel = (icon: JSX.Element, text: string) => {
	return (
		<Box align="center" direction="row" gap="xsmall" pad={{horizontal: 'xsmall', vertical: 'xsmall'}}>
			{icon}
			<Text size={'large'} weight={'bold'}>
				{text}
			</Text>
		</Box>
	)
}

export const AnalysisInProgressAccordion = (props: {trades: TradeAndStatus[]}) => {
	const tableRows = props.trades.map(row => (
		<TableRow key={row.trade}>
			<TableCell scope="row">{row.trade}</TableCell>
			<TableCell scope="row">
				<Box direction="row">
					<SRChip
						text={row.status}
						color={row.status === 'In progress' ? undefined : 'white'}
						background={row.status === 'In progress' ? 'status-warning' : 'status-ok'}
					/>
				</Box>
			</TableCell>
		</TableRow>
	))

	return (
		<ThemeContext.Extend value={inProgressTheme}>
			<InProgressAccordion>
				<AccordionPanel
					label={accordionPanelLabel(<Alert color={SR_COLOR_BRAND_MAIN} size={'medium'} />, 'Processing analysis')}
				>
					<Box pad={'small'} style={{userSelect: 'none'}}>
						<Table>
							<ProgressTableHeader>
								<TableRow>
									<TableCell scope="col">
										<Text weight={'bold'}>Trades</Text>
									</TableCell>
									<TableCell scope="col">
										<Text weight={'bold'}>Analysis status</Text>
									</TableCell>
								</TableRow>
							</ProgressTableHeader>
							<TableBody>{tableRows}</TableBody>
						</Table>
					</Box>
				</AccordionPanel>
			</InProgressAccordion>
		</ThemeContext.Extend>
	)
}
