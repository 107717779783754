import {UserProject} from '../../reducers/userProfile'
import {format} from 'date-fns'
import {history} from '../../router/history'
import React from 'react'
import {Box, Text} from 'grommet'
import {routeEncodeTenantId} from '../../utilities/routeUtilities'
import {
	SR_COLOR_LIGHT_1,
	SR_COLOR_NEUTRAL_1,
	SR_COLOR_NEUTRAL_2,
	SR_COLOR_TEXT_LIGHT,
	SRCard,
	SRIconButton,
	SRTooltip,
} from 'sr-react-commons'
import {Document} from 'grommet-icons'
import {isManagementDashboardAnyPageEnabled} from '../../features/ManagementDashboard/core/management-dashboard-page.utils'
import {selectorCurrentUser} from '../../selectors/current-user.selectors'
import {useSelector} from 'react-redux'
import {useFeatureEnabledFunc} from '../../features/FeatureFlags/FeatureFlagsProvider'

export function ProjectCard({project, disabled}: {project: UserProject; disabled: boolean}) {
	const onOpen = () => {
		let newUrl = '/' + routeEncodeTenantId(project.tenantId) + '/'
		if (project.lastAnalysisView) {
			newUrl += encodeURIComponent(project.lastAnalysisView._id)
		}
		history.push(newUrl)
	}
	const featureEnabled = useFeatureEnabledFunc()
	const user = useSelector(selectorCurrentUser)
	const anyDashboardPageEnabled = !!user && isManagementDashboardAnyPageEnabled(project, featureEnabled)

	return (
		<SRCard
			key={project.tenantId}
			gap={'medium'}
			onClick={onOpen}
			background={SR_COLOR_LIGHT_1}
			height={'xsmall'}
			direction="row"
			disabled={disabled}
			border={SR_COLOR_NEUTRAL_2}
		>
			<Box direction="row" justify="between" align="center" gap="small" fill>
				<Box gap="xsmall" width={{max: '60%'}}>
					<Text
						truncate
						title={project.name}
						size="medium"
						weight={'bold'}
						color={disabled ? SR_COLOR_NEUTRAL_1 : SR_COLOR_TEXT_LIGHT}
					>
						{project.name}
					</Text>
					<Text
						title={project.company.name}
						truncate
						size="medium"
						color={disabled ? SR_COLOR_NEUTRAL_1 : SR_COLOR_TEXT_LIGHT}
					>
						{project.company.name}
					</Text>
				</Box>
				<Box direction={'row'} gap={'small'} justify={'end'} width={{max: '40%'}}>
					<Box>
						{project.lastAnalysisView ? (
							<Box gap="xsmall" align={'end'}>
								<Text
									truncate
									title={project.lastAnalysisView.name}
									size={'medium'}
									weight="bold"
									color={disabled ? SR_COLOR_NEUTRAL_1 : SR_COLOR_TEXT_LIGHT}
								>
									{project.lastAnalysisView.name}
								</Text>
								<Text
									truncate
									title={format(new Date(project.lastAnalysisView.scanDate), 'E do, MMMM yyyy')}
									size={'medium'}
									color={disabled ? SR_COLOR_NEUTRAL_1 : SR_COLOR_TEXT_LIGHT}
								>
									{format(new Date(project.lastAnalysisView.scanDate), 'E do, MMMM yyyy')}
								</Text>
							</Box>
						) : (
							<Text color={SR_COLOR_NEUTRAL_1}>No analysis available</Text>
						)}
					</Box>
					{anyDashboardPageEnabled ? (
						<Box alignSelf="center">
							<SRTooltip tooltipText={'Project management'} width={150}>
								<SRIconButton
									icon={<Document size="medium" />}
									onClick={event => {
										event.stopPropagation()
										history.push(`/manage/${project._id}/home`)
									}}
								/>
							</SRTooltip>
						</Box>
					) : null}
				</Box>
			</Box>
		</SRCard>
	)
}
