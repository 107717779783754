import {useQuery} from 'react-query'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {fetchActiveModel} from './model.api.v3'
import {ModelEntity} from './model.entities'
import {AxiosError} from 'axios'

export function useActiveModel() {
	const project = useCurrentProject()
	return useQuery<ModelEntity | null, AxiosError>(
		['models', 'active', project?._id],
		() => fetchActiveModel(project!._id),
		{
			enabled: !!project,
		},
	)
}
