import React, {useEffect} from 'react'
import {Box} from 'grommet'
import {useDispatch, useSelector} from 'react-redux'
import {getS3KeyForPath} from '../../../../utilities/storageUtilities'
import {ProtectedImage} from '../../../ProtectedImage/ProtectedImage'
import {RootState} from '../../../../reducers'
import {useLastSelectedClassification} from '../../../../features/Classification/data-management/useSelectedClassification'
import {useSectionState} from '../StateManagement/section.slice'
import {ElementSectionViewWithStore} from './ElementSectionView'
import {selectorViewerInstance, selectorViewerProperties} from '../../../../selectors/viewer-state.selector'
import {drawSectionPlaneIntersection} from '../../../Viewer/Viewer-helper'
import {doToggleAssetsMode} from 'actions/viewer'
import {ElementSectionsPanelHeader} from './ElementSectionsPanelHeader'
import {AssetContainer} from '../../../Assets/AssetContainer'
import {ClassificationEntity} from '../../../../features/Classification/classification.entities'

interface ElementSectionsPanelContentProps {
	classification: ClassificationEntity | null
	displayHeader: boolean
}

export const ElementSectionsPanelContent = ({classification, displayHeader}: ElementSectionsPanelContentProps) => {
	const dispatch = useDispatch()
	const project = useSelector((state: RootState) => state.userProfileState.selectedProject)
	const {currentSection, setSections, isOpened} = useSectionState()
	const viewer = useSelector(selectorViewerInstance)
	const {assetsMode} = useSelector(selectorViewerProperties)
	useEffect(() => {
		setSections(isOpened ? classification?.sections || null : null)
	}, [classification, setSections, isOpened])
	useEffect(() => {
		if (!viewer) return
		drawSectionPlaneIntersection(viewer, classification?.forgeObjectId, currentSection?.planeCoefficients)
		return () => drawSectionPlaneIntersection(viewer)
	}, [currentSection, classification, viewer])
	const perspectiveImagePath = currentSection && getS3KeyForPath(project!.tenantId, currentSection.perspectivePath)
	const perspectiveAxesPath = currentSection && getS3KeyForPath(project!.tenantId, currentSection.perspectiveAxesPath)
	const {forgeObjectIds} = useSelector(selectorViewerProperties)
	return (
		<Box fill id="element-sections-container" className={'element-sections-content'}>
			{displayHeader && (
				<ElementSectionsPanelHeader
					assetsMode={assetsMode}
					onToggleAssetsMode={() => dispatch(doToggleAssetsMode())}
					currentSection={currentSection}
					classification={classification}
				/>
			)}
			<AssetContainer
				display={!!(forgeObjectIds.length === 1 && classification?.sections?.length)}
				placeholderText={
					forgeObjectIds.length > 1
						? 'Please select only one element to see its sections'
						: classification === null && forgeObjectIds.length === 1
						? 'This element has no sections in the current analysis.'
						: 'Please select an element to see its sections.'
				}
				placeholderTitle={
					forgeObjectIds.length > 1
						? 'Multiple elements selected'
						: classification && classification.sections.length === 0
						? 'No sections available'
						: undefined
				}
			>
				{currentSection ? (
					<Box fill direction={'row'}>
						{currentSection.planeCoefficients ? null : (
							<Box width={{min: '33%', max: '33%'}} direction={'column'} justify={'center'}>
								<Box height={'300px'}>
									{perspectiveImagePath && (
										<ProtectedImage
											alt={'Element sections perspective view'}
											style={{objectPosition: 'top'}}
											fit="contain"
											crossOrigin={'anonymous'}
											s3key={perspectiveImagePath}
										/>
									)}
								</Box>
								{perspectiveAxesPath && (
									<ProtectedImage
										crossOrigin={'anonymous'}
										className="perspective-axes"
										plain
										s3key={perspectiveAxesPath}
										alt="Element sections perspective axis"
									/>
								)}
							</Box>
						)}
						<Box fill>
							<ElementSectionViewWithStore />
						</Box>
					</Box>
				) : null}
			</AssetContainer>
		</Box>
	)
}

export function ElementSectionsPanelWithStore() {
	const classification = useLastSelectedClassification()
	const {assetsMode, threeSixtyOpened} = useSelector(selectorViewerProperties)
	return (
		<Box fill background={'white'}>
			<ElementSectionsPanelContent
				displayHeader={assetsMode === 'popup' || threeSixtyOpened}
				classification={classification}
			/>
		</Box>
	)
}
