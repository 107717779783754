import {USER_PROFILE_ADD, USER_PROJECT_SELECTED} from '../constants/action-types'
import {createReducer} from '@reduxjs/toolkit'
import {User} from '../types/user'
import {Id} from '../entities/value-objects'
import {PermissionsEnum} from '../entities/auth'
import {AnalysisViewEntity} from '../features/AnalysisView/analysis-view.entities'
import {SrFeatureFlag} from '../../../../libs/sr-feature-flags/src'

export interface Company {
	_id: string
	name: string
}

export interface EnvironmentConfig {
	srbackendUrlV2: string
	forgebackendUrlV2: string
}

export type ClassificationCSVFormat = 'standard' | 'extended'

export type UserProjectModelType = 'IFC' | 'Revit' | 'Navisworks'

export type FeatureFlags = Partial<Record<SrFeatureFlag, boolean>> & {
	maxElementTypeTreeDepth: number | null
	csvExportFormat: ClassificationCSVFormat
	forceNearRadius: number | null
}

export interface UserProject {
	_id: Id
	lastAnalysisView: AnalysisViewEntity | null
	floorMapping: Record<string, string>
	elementTypeMapping: Record<string, string>
	currentUser: User
	staticAssetsUrl: string
	elementViewerTypesToHide: string[]
	env: Record<string, EnvironmentConfig>
	tenantId: string
	name: string
	company: Company
	documentUrn: string
	modelType: UserProjectModelType
	viewerState: any
	elementsToHide: number[]
	viewerFeatureFlags: FeatureFlags
	permissions: PermissionsEnum[]
}

export function userProjectHasPermission(project: UserProject | null, permission: PermissionsEnum): boolean {
	return (project && project.permissions.includes(permission)) || false
}

export function userHasAllPermissionsForProject(project: UserProject | null, permissions: PermissionsEnum[]): boolean {
	return !!project && permissions.every(permission => userProjectHasPermission(project, permission))
}

export function userProjectHasPermissionFromList(project: UserProject, permissions: PermissionsEnum[]): boolean {
	return permissions.some(permission => userProjectHasPermission(project, permission))
}

interface UserProfileState {
	userProjects?: UserProject[]
	selectedProject?: UserProject
	error: string | null
}

const INITIAL_STATE: UserProfileState = {
	error: null,
}

function applyUserProfile(state: UserProfileState, action: {userProfile: UserProject[]}) {
	state = {...state, userProjects: action.userProfile}
	return state
}

function applyUserProjectSelection(state: UserProfileState, action: {selectedProject: UserProject}) {
	state = {...state, selectedProject: action.selectedProject}
	return state
}

const reducer = createReducer(INITIAL_STATE, {
	[USER_PROFILE_ADD]: (state, action) => {
		return applyUserProfile(state, action)
	},
	[USER_PROJECT_SELECTED]: (state, action) => {
		return applyUserProjectSelection(state, action)
	},
})

export default reducer
