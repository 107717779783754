import {CheckBox} from 'grommet'
import React from 'react'

export function ElementsOfInterestFilter({
	filters,
	setFilter,
}: {
	filters: {eoi: boolean | null}
	setFilter: (key: 'eoi', value: boolean | null) => void
}) {
	return (
		<CheckBox
			checked={!!filters.eoi}
			label={'Filter bookmarked elements'}
			toggle
			onChange={event => setFilter('eoi', event.target.checked)}
		/>
	)
}
