import React, {useEffect} from 'react'
import {FileUploadState, UploadStatus, useFileUploader} from '../hooks/useFileUploader'
import {Box, Text} from 'grommet'
import {FileDropZone} from './FileDropZone'
import {FileUploadProgressTable} from './FileUploadProgressTable'
import {SRButton, SRNotificationCard, SRPrimaryButton} from 'sr-react-commons'
import {FileVisibility} from '../../../api/files.api'

export type DroppedFile = {
	file: File
	accepted: boolean
}

export function ProjectFileUploader(props: {
	projectId: string
	visibility: FileVisibility
	onFinish: () => void
	onCancel: () => void
	dropzoneText: JSX.Element
	helpText: JSX.Element
	acceptedFileTypes: string[] | null
}) {
	const closeTabListenerRef = React.useRef(function (e: BeforeUnloadEvent) {
		// this message will not be shown on chrome
		const confirmationMessage = 'Leave site? Changes you made may not be saved.'
		;(e || window.event).returnValue = confirmationMessage //Gecko + IE
		return confirmationMessage //Webkit, Safari, Chrome
	})
	const [selectedFiles, setSelectedFiles] = React.useState<DroppedFile[]>([])
	const {
		files: uploadingFiles,
		fileUploadStatus,
		uploadFiles,
		cancelFileUpload,
		reset,
		failedFileCount,
	} = useFileUploader(props.projectId, props.visibility)
	const [fileSelectionStatus, setFileSelectionStatus] = React.useState<'select-files' | 'files-committed'>(
		'select-files',
	)

	const onFilesSelected = (files: File[]) =>
		setSelectedFiles(oldFiles => oldFiles.concat(files.map(file => ({file, accepted: true}))))
	const onFilesRejected = (files: File[]) =>
		setSelectedFiles(oldFiles => oldFiles.concat(files.map(file => ({file, accepted: false}))))
	const onRemove = (name: string) => setSelectedFiles(files => files.filter(f => f.file.name !== name))
	const onStartUpload = () => {
		setFileSelectionStatus('files-committed')
		uploadFiles(selectedFiles.filter(file => file.accepted).map(file => file.file)).catch(error =>
			console.error('Error in onStartUpload', error),
		)
	}
	const onCancelUpload = () => {
		Object.entries(uploadingFiles)
			.filter(([, fileStatus]) => fileStatus?.status === 'uploading' || fileStatus?.status === 'pending')
			.forEach(([filename]) => {
				console.log('start cancelling ', filename)
				cancelFileUpload(filename)
			})
	}
	const onTryAgain = () => {
		setFileSelectionStatus('select-files')
		setSelectedFiles([])
		reset()
	}

	useEffect(() => {
		const listener = closeTabListenerRef.current
		if (fileUploadStatus === 'uploading') {
			window.addEventListener('beforeunload', listener)
		} else {
			window.removeEventListener('beforeunload', listener)
		}
		return () => {
			window.removeEventListener('beforeunload', listener)
		}
	}, [fileUploadStatus])

	return (
		<ProjectFileUploadPresenter
			acceptedFileTypes={props.acceptedFileTypes}
			fileSelectionStatus={fileSelectionStatus}
			text={props.dropzoneText}
			onFilesSelected={onFilesSelected}
			onFilesRejected={onFilesRejected}
			uploadStatus={fileUploadStatus}
			selectedFiles={selectedFiles}
			uploadingFiles={uploadingFiles}
			onRemove={onRemove}
			helpText={props.helpText}
			onCancel={props.onCancel}
			onStartUpload={onStartUpload}
			onCancelUpload={onCancelUpload}
			onCancelFileUpload={cancelFileUpload}
			onFinish={props.onFinish}
			onTryAgain={onTryAgain}
			failedFileCount={failedFileCount}
		/>
	)
}

function ProjectFileUploadPresenter(props: {
	acceptedFileTypes: string[] | null
	fileSelectionStatus: 'select-files' | 'files-committed'
	text: JSX.Element
	onFilesSelected: (files: File[]) => void
	onFilesRejected: (files: File[]) => void
	uploadStatus: UploadStatus
	selectedFiles: DroppedFile[]
	uploadingFiles: FileUploadState['files']
	onRemove: (name: string) => void
	helpText: JSX.Element
	onCancel: () => void
	onStartUpload: () => void
	onCancelUpload: () => void
	onCancelFileUpload: (filename: string) => Promise<void>
	onFinish: () => void
	onTryAgain: () => void
	failedFileCount: number
}) {
	return (
		<Box gap="medium">
			<Box height={'120px'}>
				{props.fileSelectionStatus === 'select-files' && (
					<FileDropZone
						acceptedFileTypes={props.acceptedFileTypes}
						text={props.text}
						onDropAccepted={props.onFilesSelected}
						onDropRejected={props.onFilesRejected}
					/>
				)}
				{props.fileSelectionStatus === 'files-committed' &&
					(props.uploadStatus === 'finished' ? (
						<SRNotificationCard type={'success'} text={<Text>Upload finished.</Text>} />
					) : props.uploadStatus === 'canceled' ? (
						<SRNotificationCard type={'warning'} text={<Text>Upload canceled. Please try again.</Text>} />
					) : props.uploadStatus === 'partial' ? (
						<SRNotificationCard
							type={'warning'}
							text={<Text>Upload finished. {props.failedFileCount} file(s) not uploaded.</Text>}
						/>
					) : props.uploadStatus === 'failed' ? (
						<SRNotificationCard type={'error'} text={<Text>Upload failed. Please try again.</Text>} />
					) : (
						<SRNotificationCard
							type={'warning'}
							text={
								<Text>
									Upload in progress.
									<br />
									Please don't close this tab while the files are uploading.
								</Text>
							}
						/>
					))}
			</Box>
			<Box height={'320px'} overflow="auto">
				{props.selectedFiles.length > 0 ? (
					<FileUploadProgressTable
						files={props.selectedFiles}
						uploadingFiles={props.uploadingFiles}
						onRemove={props.onRemove}
						canRemoveFiles={props.uploadStatus !== 'finished'}
						onCancelUpload={props.onCancelFileUpload}
					/>
				) : (
					props.helpText
				)}
			</Box>
			<Box>
				{props.fileSelectionStatus === 'select-files' && (
					<Box direction="row" justify={'between'}>
						<SRButton label={'Cancel'} onClick={props.onCancel} />
						<SRPrimaryButton
							disabled={props.selectedFiles.length === 0}
							onClick={props.onStartUpload}
							label={'Send files'}
						/>
					</Box>
				)}
				{props.fileSelectionStatus === 'files-committed' && (
					<Box direction="row" justify={props.uploadStatus === 'failed' ? 'between' : 'center'}>
						{props.uploadStatus === 'finished' ||
						props.uploadStatus === 'partial' ||
						props.uploadStatus === 'canceled' ? (
							<SRPrimaryButton label={'Back to files'} onClick={props.onFinish} />
						) : props.uploadStatus === 'failed' ? (
							<>
								<SRButton label="Try again" onClick={props.onTryAgain} />
								<SRPrimaryButton label={'Back to files'} onClick={props.onFinish} />
							</>
						) : (
							<SRButton label={'Cancel upload'} onClick={props.onCancelUpload} />
						)}
					</Box>
				)}
			</Box>
		</Box>
	)
}
