import {Box, CheckBox, Text} from 'grommet'
import React from 'react'

export type SRCheckBoxWithDescriptionProps = {
	label: string
	description: string
	value: boolean
	onChange: () => void
}

export const SRCheckBoxWithDescription = (props: SRCheckBoxWithDescriptionProps) => {
	return (
		<Box gap="xxsmall">
			<CheckBox
				label={<Text weight={'bold'}>{props.label}</Text>}
				type="checkbox"
				checked={props.value}
				onChange={props.onChange}
			/>
			<Box pad={{horizontal: '40px'}}>
				<Text color="neutral-1">{props.description}</Text>
			</Box>
		</Box>
	)
}
