import React, {useState} from 'react'
import {Box} from 'grommet'
import {MoreVertical} from 'grommet-icons'
import {SR_COLOR_NEUTRAL_2, SRDropButton, SRIconButton, SRPlainButton} from 'sr-react-commons'
import {ToggleApproveButton} from './ToggleApproveButton'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../reducers'
import {useClassificationState} from '../../reducers/classification.slice'
import {approveClassifications, unapproveClassifications} from '../../features/Classification/api/classification.api'
import {UnapproveClassificationModal} from './UnapproveClassificationModal'
import DownloadCsvButton from './DownloadCsvButton'
import {selectorApprovedFilter} from '../../reducers/viewerReducer'
import {SET_APPROVED_FILTER} from '../../actions/viewerTypes'
import {useInvalidateClassifications} from '../../features/Classification/data-management/useInvalidateClassification'
import {toFiltersDTO} from '../../features/FilterPresets/entities/filter-preset.entities'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {useFeatureEnabled} from '../../features/FeatureFlags/FeatureFlagsProvider'
import QualityPdfModalButton from '../../features/QualityReport/components/QualityPdfModalButton'

function QualitySelectButton({
	icon,
	label,
	onClick,
}: {
	icon?: JSX.Element | undefined
	label?: string
	onClick?: () => void
}) {
	return (
		<SRPlainButton
			alignSelf="stretch"
			size={'medium'}
			label={
				<Box direction={'row'} gap={'small'} align={'center'} pad={'xsmall'}>
					{icon || null}
					{label || null}
				</Box>
			}
			onClick={onClick}
		/>
	)
}

function DownloadCSVButtonWrapper() {
	const project = useSelector((state: RootState) => state.userProfileState.selectedProject!)
	const {selectedAnalysisView, analysisVersionMap} = useClassificationState()
	const filters = useSelector((state: RootState) => state.classification.filter)

	return (
		selectedAnalysisView && (
			<DownloadCsvButton {...{analysisView: selectedAnalysisView, analysisVersionMap, filters, project}} />
		)
	)
}

function DownloadQualityReportPDFButtonWrapper() {
	const project = useSelector((state: RootState) => state.userProfileState.selectedProject!)
	const {selectedAnalysisView} = useClassificationState()
	const filters = useSelector((state: RootState) => state.classification.filter)
	return selectedAnalysisView && <QualityPdfModalButton {...{selectedAnalysisView, filters, project}} />
}

function HideElementButton() {
	const isApprovedFilterActive = useSelector(selectorApprovedFilter)
	const dispatch = useDispatch()
	const invalidateClassification = useInvalidateClassifications()

	const setApprovedFilter = async () => {
		if (isApprovedFilterActive === false) {
			dispatch({type: SET_APPROVED_FILTER, value: null})
		} else {
			dispatch({type: SET_APPROVED_FILTER, value: false})
		}
		await invalidateClassification()
	}

	return (
		<QualitySelectButton
			label={isApprovedFilterActive === false ? 'Show approved elements' : 'Hide approved elements'}
			onClick={setApprovedFilter}
		/>
	)
}

function UnapproveAllDisplayButton() {
	const {selectedAnalysisView, filter} = useClassificationState()
	const [isModalOpen, setOpenModal] = useState(false)
	const invalidateClassifications = useInvalidateClassifications()
	const project = useCurrentProject()!
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const approvedFilter = useSelector(selectorApprovedFilter)

	const onClose = async () => {
		setOpenModal(false)
	}

	const unapproveAll = async () => {
		await unapproveClassifications(
			selectedAnalysisView!._id,
			toFiltersDTO(filter, project.floorMapping, project.elementTypeMapping, enableUnderConstruction, approvedFilter),
		)
		setOpenModal(false)
		await invalidateClassifications()
	}

	const confirmUnapproveAll = () => setOpenModal(true)

	const elementCount = useSelector<RootState, number>(state => state.classification.pagination.totalRecords)

	return (
		<>
			{isModalOpen ? (
				<UnapproveClassificationModal elementCount={elementCount} onClose={onClose} onAction={unapproveAll} />
			) : null}
			<QualitySelectButton label="Unapprove all displayed" onClick={confirmUnapproveAll} />
		</>
	)
}

function ApproveAllDisplayButton() {
	const {selectedAnalysisView, filter} = useClassificationState()
	const project = useCurrentProject()!
	const invalidateClassifications = useInvalidateClassifications()
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const approvedFilter = useSelector(selectorApprovedFilter)

	const approveAll = async () => {
		await approveClassifications(
			selectedAnalysisView!._id,
			toFiltersDTO(filter, project.floorMapping, project.elementTypeMapping, enableUnderConstruction, approvedFilter),
		)
		await invalidateClassifications()
	}

	return <QualitySelectButton label="Approve all displayed" onClick={approveAll} />
}

export function QualityMenuDropDown() {
	const viewerState = useSelector((state: RootState) => state.viewerState)
	const qualityReportEnabled = useFeatureEnabled('qualityReport')
	return (
		<Box flex={false} width={'250px'}>
			<Box fill>
				<ToggleApproveButton key={'check-workflow'} />
				{viewerState.manualWorkflowActivated ? (
					<>
						<HideElementButton />
						<ApproveAllDisplayButton />
						<UnapproveAllDisplayButton />
					</>
				) : null}
				<Box
					border={{
						color: SR_COLOR_NEUTRAL_2,
						size: 'xsmall',
						side: 'top',
					}}
				>
					{qualityReportEnabled ? <DownloadQualityReportPDFButtonWrapper /> : null}
					<DownloadCSVButtonWrapper />
				</Box>
			</Box>
		</Box>
	)
}

export function QualityMenu() {
	const [menuOpen, setMenuOpen] = React.useState(false)
	return (
		<SRDropButton
			buttonClass={SRIconButton}
			size="small"
			icon={<MoreVertical size={'small'} />}
			dropAlign={{top: 'bottom', right: 'right'}}
			open={menuOpen}
			onOpen={() => setMenuOpen(true)}
			onClose={() => setMenuOpen(false)}
			dropContent={<QualityMenuDropDown />}
		/>
	)
}
