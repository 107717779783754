import {useSelector} from 'react-redux'
import {selectorViewerProperties} from '../../../selectors/viewer-state.selector'
import {usePhotosphereExtension} from './usePhotoSphereExtension'
import React from 'react'
import {PhotoSphereRenderer} from './PhotoSphereRenderer'
import {selectorCurrentProject} from '../../../selectors/current-project.selectors'
import {useLastSelectedClassification} from '../../../features/Classification/data-management/useSelectedClassification'
import {AssetContainer} from '../../Assets/AssetContainer'

export function PhotoSphereSplitViewContainer() {
	const project = useSelector(selectorCurrentProject)
	const {
		threeSixtyOpened,
		assetsMode,
		threeSixtyViewsphere: viewsphere,
		threeSixtyClassification: classification,
		threeSixtyHighlight,
		forgeObjectIds,
	} = useSelector(selectorViewerProperties)
	const selectedClassification = useLastSelectedClassification()
	const photosphereExtension = usePhotosphereExtension(selectedClassification)
	const showSplitMode = threeSixtyOpened && assetsMode === 'split'

	return !showSplitMode ? null : (
		<AssetContainer
			display={!!(forgeObjectIds.length === 1 && classification?.viewspheres?.length)}
			placeholderTitle={
				forgeObjectIds.length > 1
					? 'Multiple elements selected'
					: classification !== null && !Boolean(classification.viewspheres?.length)
					? 'No 360° images available'
					: undefined
			}
			placeholderText={
				forgeObjectIds.length > 1
					? 'Please select only one element to see its 360º images'
					: classification !== null && !Boolean(classification.viewspheres?.length)
					? 'This element has no 360° images in the current analysis.'
					: 'Please select an element to see its 360º image.'
			}
		>
			<PhotoSphereRenderer
				cameraFov={photosphereExtension.cameraFov}
				cameraOrientation={photosphereExtension.cameraOrientation}
				setCameraFov={photosphereExtension.setCameraFov}
				setCameraOrientation={photosphereExtension.setCameraOrientation}
				highlightElement={threeSixtyHighlight}
				contextMode="split"
				viewsphere={viewsphere!}
				tenantId={project!.tenantId}
			/>
		</AssetContainer>
	)
}
