import React from 'react'
import {Image} from 'grommet'
import {RotateLeft} from 'grommet-icons'

import {SREmptyView} from 'sr-react-commons'

type EmptyViewProps = {
	onAction: () => void
}

export const WatchListEmptyView = ({onAction}: EmptyViewProps) => {
	return (
		<SREmptyView
			onAction={onAction}
			actionText="Reset filters"
			asset={<Image src="/images/empty-states/empty-progress.svg" />}
			title="There are no work packages to display"
			buttonIcon={<RotateLeft size="small" />}
			message="Keep track of your project's progress by creating a new work package today."
			buttonTextSize="large"
			buttonReverse={true}
		/>
	)
}
