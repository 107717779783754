import React, {useEffect, useRef} from 'react'
import {history} from '../router/history'
import {UnregisterCallback} from 'history'

export const useNavigationBlocker = (message: string) => {
	const unblockRef = useRef<UnregisterCallback>()
	const closeTabListenerRef = React.useRef(function (e: BeforeUnloadEvent) {
		// this message will not be shown on chrome
		;(e || window.event).returnValue = message //Gecko + IE
		return message //Webkit, Safari, Chrome
	})
	useEffect(() => {
		unblockRef.current = history.block(message)
		const listener = closeTabListenerRef.current
		window.addEventListener('beforeunload', listener)
		return () => {
			unblockRef.current?.()
			window.removeEventListener('beforeunload', listener)
		}
	}, [message])
	return {unblockRef}
}
