import {selectorViewerIsReady} from '../../selectors/viewer-state.selector'
import {store} from '../../store/store'
import {translateViewportFromRealWordToViewerCoordinates} from '../../utilities/viewerUtilities'
import _ from 'lodash'
import {IssueEntity} from './issue.entities'

export function restoreViewerFromIssue(issue: Partial<IssueEntity>): void {
	const restoreViewer = () => {
		if (issue.viewport && Object.entries(issue.viewport).length !== 0) {
			window.NOP_VIEWER.restoreState({
				viewport: translateViewportFromRealWordToViewerCoordinates(
					_.cloneDeep(issue.viewport),
					window.NOP_VIEWER.model.getGlobalOffset(),
					window.NOP_VIEWER.model.getUnitScale(),
				),
			})
		} else if (issue.forgeObjectId) {
			window.NOP_VIEWER.fitToView(issue.forgeObjectId)
		}
		issue.forgeObjectId && window.NOP_VIEWER.select(issue.forgeObjectId)
	}
	if (selectorViewerIsReady(store.getState())) {
		restoreViewer()
	} else {
		const unsubscribe = store.subscribe(() => {
			if (selectorViewerIsReady(store.getState())) {
				unsubscribe()
				restoreViewer()
			}
		})
	}
}

export function addDays(date: Date, days: number): Date {
	const newDate = new Date(date.valueOf())
	newDate.setDate(newDate.getDate() + days)
	return newDate
}
