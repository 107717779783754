import {Box, Layer, Text} from 'grommet'
import React from 'react'
import {Alert, FormClose} from 'grommet-icons'
import {SRButton, SRHeading, SRIconButton} from 'sr-react-commons'

export function WatchListModal(props: {open: boolean; onClose: () => void; fileInputElement: JSX.Element}) {
	return props.open ? (
		<Layer modal position={'top'} margin={{top: '100px'}} onClickOutside={props.onClose} onEsc={props.onClose}>
			<Box pad="medium" gap="small" width={{max: '520px'}} height={{min: 'small'}} fill>
				<Box direction={'row'} justify={'between'}>
					<SRHeading level="4" margin="none">
						Edit element selection
					</SRHeading>
					<SRIconButton icon={<FormClose size="large" />} onClick={props.onClose} />
				</Box>
				<Box direction="row" justify={'between'} gap={'medium'}>
					<Box pad={{right: 'medium', left: 'xsmall'}}>
						<Alert size="45px" color="brand-main" />
					</Box>
					<Box gap={'xsmall'}>
						<Text weight={'bold'}>Do you want to edit the element selection?</Text>
						<Text>
							If you update your element selection, progress from previous analyses will be recalculated using elements
							from the current displayed model.
						</Text>
						<Text>Information showed on the progress charts might change.</Text>
					</Box>
				</Box>
				<Box direction="row" justify={'between'}>
					<SRButton textColor="neutral-1" onClick={props.onClose} label="Cancel" size="large" />
					{props.fileInputElement}
				</Box>
			</Box>
		</Layer>
	) : null
}
