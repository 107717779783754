import {RootState} from '../../../reducers'
import {
	FilterPresetEntity,
	FilterPresetEntityOptionalId,
	normalizeFilterPreset,
} from '../entities/filter-preset.entities'
import {FilterPresetState} from '../slices/filter-preset.slice'
import _ from 'lodash'

export const selectorFilterPresets = (
	state: RootState,
): FilterPresetState & {
	displaying: FilterPresetEntity | null
	isEdited: boolean
	isNotFiltering: boolean
	canModify: (preset: FilterPresetEntityOptionalId) => boolean
} => {
	const {filter: filters} = state.classification
	const {current} = state.filterPresetState
	const displaying = {...current.selected!, filters}
	return {
		...state.filterPresetState,
		displaying,
		isEdited:
			!!current.selected && !_.isEqual(normalizeFilterPreset(current.selected), normalizeFilterPreset(displaying)),
		isNotFiltering: _.isEqual(normalizeFilterPreset({}), normalizeFilterPreset({filters})),
		canModify: preset =>
			!preset._id || (state.currentUser.currentUser && state.currentUser.currentUser._id === preset.createdBy),
	}
}
