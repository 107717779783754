import {useSelector, useDispatch} from 'react-redux'
import {selectorViewerProperties} from '../../selectors/viewer-state.selector'
import {doToggleAssetsMode} from '../../actions/viewer'
import {ThreeSplit} from '../CommonsCandidate/Icons/ThreeSplit'
import {SRBrandSelectableIconButton} from 'sr-react-commons'
import React from 'react'

export function AssetsModeSwitchButton() {
	const dispatch = useDispatch()
	const {assetsMode} = useSelector(selectorViewerProperties)
	const toggleAssetsMode = () => dispatch(doToggleAssetsMode())
	return (
		<SRBrandSelectableIconButton
			icon={<ThreeSplit />}
			isSelected={assetsMode === 'split'}
			onClick={toggleAssetsMode}
			title="Switch between popup and split view mode"
		/>
	)
}
