import {AuthenticatorForm} from './AuthenticatorForm'
import {Anchor, Box, Text} from 'grommet'
import React, {useState} from 'react'
import {PASSWORD_MIN_LENGTH} from './SRForgotPassword'
import {Auth} from 'aws-amplify'
import {SRBrandButton, SRFormErrorMessage, SRHeading, SRPasswordInput} from 'sr-react-commons'

export function SRRequireNewPassword(props: {
	authState?: string
	onStateChange?: (newState: string) => void
	authData?: any
}) {
	if (!props.authState || !props.onStateChange) throw new Error('Must be used inside an authenticator component')
	const {authState, authData, onStateChange} = props
	const [errorMessage, setErrorMessage] = useState('')
	const [inputPasswordError, setInputPasswordError] = useState('')
	const [inputConfirmPasswordError, setInputConfirmPasswordError] = useState('')

	const handleErrorMessage = (error: Error & {code: string}) => {
		setErrorMessage(error.message)
	}

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const user = authData
		const formElements = event.currentTarget.elements as typeof event.currentTarget.elements & {
			passwordInput: HTMLInputElement
			confirmPasswordInput: HTMLInputElement
		}

		const password = formElements.passwordInput.value.trim()
		const confirmPassword = formElements.confirmPasswordInput.value.trim()

		if (password.length < PASSWORD_MIN_LENGTH) {
			return setInputPasswordError('Your password should be at least 8 characters long')
		} else setInputPasswordError('')

		if (password === confirmPassword) {
			Auth.completeNewPassword(user, password)
				.then(() => {
					onStateChange('signedin')
				})
				.catch((err: Error & {code: string}) => {
					handleErrorMessage(err)
					setInputPasswordError('')
					setInputConfirmPasswordError('')
				})
		} else {
			setInputConfirmPasswordError("The passwords don't match.")
		}
	}

	const goToLogin = (
		<Anchor alignSelf="center" color={'accent-3'} onClick={() => onStateChange('signIn')}>
			Back to login
		</Anchor>
	)

	return authState === 'requireNewPassword' ? (
		<AuthenticatorForm onSubmit={onSubmit}>
			<SRHeading level="5">Change your password</SRHeading>
			{errorMessage ? SRFormErrorMessage(errorMessage) : null}
			<Box gap="medium" alignSelf={'stretch'}>
				<Box gap="small">
					<Box>
						<Text wordBreak="normal">Make sure your password is at least 8 characters long.</Text>
					</Box>
					<Box>
						<label htmlFor="passwordInput">New Password</label>
						<SRPasswordInput
							error={inputPasswordError}
							id="passwordInput"
							size="medium"
							autoComplete={'new-password'}
							placeholder={'Enter your new password'}
						/>
					</Box>
					<Box>
						<label htmlFor="confirmPasswordInput">Confirm your new password</label>
						<SRPasswordInput
							error={inputConfirmPasswordError}
							id="confirmPasswordInput"
							size="medium"
							autoComplete={'new-password'}
							placeholder={'Type again your password'}
						/>
					</Box>
				</Box>
				<SRBrandButton label={'Change'} size={'medium'} type="submit" />
			</Box>
			{goToLogin}
		</AuthenticatorForm>
	) : null
}
