import {IssueComment} from './issue-comment.entities'
import {User} from '../../types/user'
import {isBefore} from 'date-fns'
import {IssueCustomProperties} from './api/issue-management.api'

export type Asset = {
	_id: string
	path: string
	user: any
	createdDate: string
	type: 'section' | 'photosphere' | 'viewer' | 'attachment'
	analysis: {
		_id: string
		name: string
	}
}

export type Priority = 'Blocker' | 'Critical' | 'Major' | 'Minor'

export interface IssueEntity {
	_id: string
	issueId: number
	uuid: string
	forgeObjectId: number[]
	externalId: string[]
	tenantId: string
	classification: string
	createdDate: string
	diagnosis: string
	analysisView: string | null
	isFlagged: boolean
	issueStatus: string
	location: string
	viewport: any
	isLabelledNew: boolean
	comments: IssueComment[]
	numComments: number
	assets: Asset[]
	user?: User
	model: string
	assignedUser: User | null
	deadline: string | null
	priority: Priority | null
	customProperties: IssueCustomProperties
}

export function getIssueTitle(issue: Partial<Pick<IssueEntity, 'issueId' | 'diagnosis'>>): string {
	return `#${issue.issueId || ''} - ${issue.diagnosis}`
}

export function issueDelayed(issue: Pick<IssueEntity, 'deadline' | 'issueStatus'>): boolean {
	return !!issue.deadline && issue.issueStatus !== 'Closed' && isBefore(new Date(issue.deadline), new Date())
}

export const PRIORITIES_FOR_FORM_AND_FILTERS = ['Blocker', 'Critical', 'Major', 'Minor', 'Unassigned'] as const
