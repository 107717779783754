import React, {useState} from 'react'
import {DocumentCsv} from 'grommet-icons'
import {format} from 'date-fns'
import {fetchClassificationCsv} from '../../features/Classification/api/classification.api'
import {FiltersEntity, toFiltersDTO} from '../../features/FilterPresets/entities/filter-preset.entities'
import {UserProject} from '../../reducers/userProfile'
import {SRPlainButton, SRSpinnerIcon} from 'sr-react-commons'
import {AnalysisViewEntityPopulated} from '../../features/AnalysisView/analysis-view.entities'
import {AnalysisVersionMap} from '../../reducers/classification.slice'
import {saveAs} from 'file-saver'
import {Box, Text} from 'grommet'
import {useSelector} from 'react-redux'
import {selectorApprovedFilter} from '../../reducers/viewerReducer'
import {useFeatureEnabled} from '../../features/FeatureFlags/FeatureFlagsProvider'

export default function DownloadCsvButton({
	analysisView,
	analysisVersionMap,
	filters,
	project,
}: {
	analysisView: AnalysisViewEntityPopulated
	analysisVersionMap: AnalysisVersionMap
	filters: FiltersEntity | undefined
	project: UserProject
}) {
	const [isDownloading, setIsDownloading] = useState(false)
	const approvedFilter = useSelector(selectorApprovedFilter)
	const enableUnderConstruction = useFeatureEnabled('enableUnderConstruction')
	const fetchCSVFile = async () => {
		setIsDownloading(true)
		const fetchCSV = await fetchClassificationCsv(
			analysisView,
			analysisVersionMap,
			filters
				? toFiltersDTO(
						filters,
						project.floorMapping,
						project.elementTypeMapping,
						enableUnderConstruction,
						approvedFilter,
				  )
				: undefined,
			project.viewerFeatureFlags.csvExportFormat,
			enableUnderConstruction,
		)

		const file = new Blob([fetchCSV], {type: 'text/csv; charset=utf-8;'})
		setIsDownloading(false)
		if (file) saveAs(file, `Naska_AI_DataTable-${format(new Date(), 'yyyyMMdd-HHmm')}.csv`)
	}

	return (
		<SRPlainButton
			alignSelf="stretch"
			size={'medium'}
			label={
				<Box direction={'row'} gap={'small'} align={'center'} pad={'xsmall'}>
					{!isDownloading ? <DocumentCsv color="black" size={'medium'} /> : <SRSpinnerIcon size="medium" />}
					<Text>Export CSV data table</Text>
				</Box>
			}
			onClick={fetchCSVFile}
		/>
	)
}
