import {Box, Text} from 'grommet'
import React, {FunctionComponent} from 'react'
import {PageState} from '../../reducers/base.pagination.state'
import _ from 'lodash'
import {CaretNext, CaretPrevious, FastForward} from 'grommet-icons'
import {SRIconButton, SRIconButtonProps, SRPageSizeSelector} from 'sr-react-commons'

const PaginationButton: FunctionComponent<SRIconButtonProps> = props => <SRIconButton {...props} pad={'8px'} />

type PaginationProps = {
	gotoPage: (page: number) => void
	canPreviousPage: boolean
	previousPage: () => void
	pageIndex: number
	pageCount: number
	canNextPage: boolean
	nextPage: () => void
	totalRecords: number
	pageSize: number
	setPageSize: (pageSize: number) => void
}

const PAGINATION_COMPONENT_PROPS: (keyof PaginationProps)[] = [
	'gotoPage',
	'canPreviousPage',
	'previousPage',
	'pageIndex',
	'pageCount',
	'canNextPage',
	'nextPage',
	'totalRecords',
	'pageSize',
	'setPageSize',
]

export const PaginationFromPageState = ({pageState}: {pageState: PageState}) =>
	Pagination({
		..._.pick(pageState, PAGINATION_COMPONENT_PROPS),
		pageCount: pageState.pageCount || 1,
	})

export function Pagination({
	gotoPage,
	canPreviousPage,
	previousPage,
	pageIndex,
	pageCount,
	canNextPage,
	nextPage,
	totalRecords,
	pageSize,
	setPageSize,
}: PaginationProps) {
	const endItems = Math.min((pageIndex + 1) * pageSize, totalRecords)
	const startItems = pageIndex * pageSize + 1

	return (
		<Box direction={'row'} align={'center'} justify={'between'}>
			<Box direction={'row'}>
				<PaginationButton
					title={'Go to first page'}
					disabled={!canPreviousPage}
					icon={<FastForward style={{transform: 'rotate(180deg)'}} size={'small'} />}
					onClick={() => gotoPage(0)}
				/>
				<PaginationButton
					title={'Go to previous page'}
					disabled={!canPreviousPage}
					icon={<CaretPrevious size={'small'} />}
					onClick={previousPage}
				/>
			</Box>
			<Text size={'small'} alignSelf={'center'}>
				{startItems} - {endItems} of {totalRecords} - Page {pageIndex + 1} of {pageCount}
			</Text>
			<SRPageSizeSelector options={[10, 20, 50, 100]} value={pageSize} onChange={value => setPageSize(value)} />
			<Box direction={'row'}>
				<PaginationButton
					title={'Go to next page'}
					disabled={!canNextPage}
					icon={<CaretNext size={'small'} />}
					onClick={nextPage}
				/>
				<PaginationButton
					title={'Go to last page'}
					disabled={!canNextPage}
					icon={<FastForward size={'small'} />}
					onClick={() => gotoPage(pageCount - 1)}
				/>
			</Box>
		</Box>
	)
}
