import React, {ReactNode} from 'react'
import {Box, RangeSelector, Stack, Text} from 'grommet'
import {FilterDropdownButton} from '../FilterDropdownButton'
import {Refresh} from 'grommet-icons'
import {Selection, SRHeading, SRIconButton, SRSimpleSelect, SRTextInput} from 'sr-react-commons'
import {formatValueForMagnitudeFilters} from '../filter.helpers'
import _ from 'lodash'
import {MagnitudeSign} from '../../../../../reducers/classification'
import {FiltersEntity} from '../../../entities/filter-preset.entities'

const MIN_MAGNITUDE = 0
const MAX_MAGNITUDE = 10000

export type MagnitudeFilterType = 'magnitude' | 'magnitudeXY' | 'magnitudeZ' | 'magnitudeZSign'

export function MagnitudeSelectorFilter({
	filters,
	setFilter,
	numberOfSteps,
	displayMagnitudeByAxis,
}: {
	filters: {
		magnitude: {
			from: number
			to: number
		} | null
		magnitudeXY: {
			from: number
			to: number
		} | null
		magnitudeZ: {
			from: number
			to: number
		} | null
		magnitudeZSign: MagnitudeSign | null
	}
	setFilter: (filter: Partial<Pick<FiltersEntity, MagnitudeFilterType>>) => void
	numberOfSteps: number
	displayMagnitudeByAxis: boolean
}) {
	const selections: Selection[] = [
		...(filters.magnitude
			? [
					{
						label: `Overall: ${filters.magnitude.from} to ${filters.magnitude.to}`,
						value: `magnitude`,
					},
			  ]
			: []),
		...(filters.magnitudeXY
			? [
					{
						label: `D: ${filters.magnitudeXY.from} to ${filters.magnitudeXY.to}`,
						value: `magnitudeXY`,
					},
			  ]
			: []),
		...(filters.magnitudeZ
			? [
					{
						label: `H: ${filters.magnitudeZ.from} to ${filters.magnitudeZ.to}`,
						value: `magnitudeZ`,
					},
			  ]
			: []),
		...(filters.magnitudeZSign
			? [
					{
						label: `H: ${filters.magnitudeZSign === MagnitudeSign.POSITIVE ? 'Up' : 'Down'}`,
						value: `magnitudeZSign`,
					},
			  ]
			: []),
	]

	const removeMagnitude = (magnitudeType: MagnitudeFilterType) => {
		setFilter({[magnitudeType]: null})
	}

	const isFilteringByMagnitudeByAxis = !!(filters.magnitudeXY || filters.magnitudeZ || filters.magnitudeZSign)
	const isFilteringByMagnitude = !!filters.magnitude

	const magnitudeValueFormatter = (
		selections: Selection[],
		unavailableFilters: string[],
		removeElementType: (filter: MagnitudeFilterType) => void,
	) => {
		const value = formatValueForMagnitudeFilters(selections, removeElementType)
		return _.isEmpty(value) ? '' : value
	}

	const label = filters.magnitude ? `${filters.magnitude.from}mm - ${filters.magnitude.to}mm` : ''

	const magnitudeValuesToFilter = (values?: string[]) => {
		if (!values) return null
		const from = Number(values[0])
		const to = Number(values[1])
		if (from === MIN_MAGNITUDE && to === MAX_MAGNITUDE) return null
		return {from, to}
	}

	const onChangeMagnitude = (values?: string[]) =>
		!isFilteringByMagnitudeByAxis && setFilter({magnitude: magnitudeValuesToFilter(values)})
	const onChangeMagnitudeXY = (values?: string[]) =>
		!isFilteringByMagnitude && setFilter({magnitudeXY: magnitudeValuesToFilter(values)})
	const onChangeMagnitudeZ = (values?: string[]) =>
		!isFilteringByMagnitude && setFilter({magnitudeZ: magnitudeValuesToFilter(values)})
	const onChangeMagnitudeZSign = (value: FiltersEntity['magnitudeZSign'] | '') =>
		!isFilteringByMagnitude && setFilter({magnitudeZSign: value === '' ? null : value})
	return (
		<Box>
			<Text weight="bold" size="small">
				Magnitude
			</Text>
			<FilterDropdownButton
				label={displayMagnitudeByAxis ? magnitudeValueFormatter(selections, [], removeMagnitude) : label}
				placeholder={'All magnitudes displayed'}
			>
				<Box pad={'small'}>
					<MagnitudeFilterComponent
						disabled={isFilteringByMagnitudeByAxis}
						id={'magnitude'}
						heading={'Magnitude - Overall (mm)'}
						onChange={onChangeMagnitude}
						filters={filters.magnitude}
						numberOfSteps={numberOfSteps}
					/>
					{displayMagnitudeByAxis && (
						<>
							<MagnitudeFilterComponent
								disabled={isFilteringByMagnitude}
								id={'magnitudeXY'}
								heading={'Displacement - X,Y (mm)'}
								onChange={onChangeMagnitudeXY}
								filters={filters.magnitudeXY}
								numberOfSteps={numberOfSteps}
							/>
							<MagnitudeFilterComponent
								disabled={isFilteringByMagnitude}
								id={'magnitudeXY'}
								heading={'Height - Z (mm)'}
								onChange={onChangeMagnitudeZ}
								filters={filters.magnitudeZ}
								numberOfSteps={numberOfSteps}
								onReset={() => {
									setFilter({
										magnitudeZ: null,
										magnitudeZSign: null,
									})
								}}
								extraInputs={
									<Box width={'80px'}>
										<SRSimpleSelect
											options={[
												{value: '', label: 'Both'},
												{value: MagnitudeSign.POSITIVE, label: 'Up'},
												{value: MagnitudeSign.NEGATIVE, label: 'Down'},
											]}
											size={'small'}
											disabled={isFilteringByMagnitude}
											value={filters.magnitudeZSign ?? ''}
											onChange={value => onChangeMagnitudeZSign(value as MagnitudeSign | '')}
										/>
									</Box>
								}
							/>
						</>
					)}
				</Box>
			</FilterDropdownButton>
		</Box>
	)
}

function MagnitudeFilterComponent({
	id,
	heading,
	onChange,
	filters,
	numberOfSteps,
	disabled,
	extraInputs,
	onReset,
}: {
	id: string
	heading: string
	onChange: (values?: string[] | undefined) => void
	filters: any
	numberOfSteps: number
	disabled: boolean
	extraInputs?: ReactNode
	onReset?: () => void
}) {
	const boundaries = [MIN_MAGNITUDE, Math.max(MAX_MAGNITUDE, filters?.to || 0)]
	const rangeValues = [...new Array(numberOfSteps)].map((el, i) => Math.round((i * boundaries[1]) / numberOfSteps))
	const [min, max] = [(filters || {}).from || boundaries[0], (filters || {}).to || boundaries[1]]
	return (
		<Box id={id} gap={'xsmall'} fill>
			<SRHeading level={6}>{heading}</SRHeading>
			<Box direction="row" align={'center'} gap={'xsmall'}>
				<Box direction="column" width={'80px'}>
					<SRTextInput
						type="number"
						name={'magnitude-minimum'}
						value={min}
						disabled={disabled}
						onChange={values => onChange([values.target.value, max.toString()])}
					/>
				</Box>
				<Text>-</Text>
				<Box direction="column" width={'80px'}>
					<SRTextInput
						type="number"
						name={'magnitude-maximum'}
						value={max}
						disabled={disabled}
						onChange={values => onChange([min.toString(), values.target.value])}
					/>
				</Box>
				{extraInputs}
				<Box direction="column" alignSelf={'end'}>
					<SRIconButton
						title={'Reset magnitude filter'}
						icon={<Refresh />}
						onClick={onReset ?? (() => onChange(undefined))}
					/>
				</Box>
			</Box>
			<Stack>
				<Box direction="row" justify="between">
					{rangeValues.map(value => (
						<Box key={value} pad={{vertical: '10px', horizontal: 'xsmall'}} border={false}>
							<Text style={{fontFamily: 'monospace', fontSize: 12, padding: 0}}>{value}</Text>
						</Box>
					))}
				</Box>
				<Box pad={{horizontal: 'xsmall'}}>
					<RangeSelector
						className="range-selector"
						direction="horizontal"
						invert={false}
						step={1}
						min={boundaries[0]}
						max={boundaries[1]}
						color={disabled ? 'light-4' : undefined}
						size="xsmall"
						values={[min, max]}
						onChange={(values: any) => onChange(values)}
					/>
				</Box>
			</Stack>
		</Box>
	)
}
