import React from 'react'
import {ManagementDashboardPageLayout} from '../core/components/layout/ManagementDashboardPageLayout'
import {ManagementDashboardIntegrations} from './ManagementDashboardIntegrations'

export const ManagementDashboardIntegrationsPage = () => {
	return (
		<ManagementDashboardPageLayout>
			<ManagementDashboardIntegrations />
		</ManagementDashboardPageLayout>
	)
}
